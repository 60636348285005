<template>
  <v-container class="mx-0 px-0">
    <v-dialog v-model="createHotlistDialogue" width="450px">
      <v-card>
        <CreateHotlist
          v-on:close-dialog="createHotlistDialogue = false"
          :selectedCandidates="[]"
          @reload-table="init()"
        />
      </v-card>
    </v-dialog>
    <v-col cols="10">
      <v-col cols="6">
        <v-row class="pa-1 justify-space-between">
          <h2 class="">Hotlists</h2>
          <v-btn class="btnHover" color="primary" @click="openCreateHotlist()"
            ><v-icon> mdi-plus</v-icon>Add</v-btn
          >
          <v-btn class="btnHover" color="primary" @click="sendSurvey()"
            >Send Survey</v-btn
          >
        </v-row>
      </v-col>
      <v-row>
        <v-col cols="4">
          <v-list class="scrollableList px-1">
            <v-list-item
              :key="item.id"
              v-for="item in hotlists_getter"
              @click="setActiveHotlist(item)"
              :class="
                activeItem == null
                  ? ''
                  : activeItem.id == item.id
                  ? 'activeItemClass'
                  : ''
              "
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle class="mt-1">
                  {{ timePassed(item) }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon class="align-self-center">
                <h4 style="color: #757575">
                  {{ item.hotlist_members.length }}
                </h4>
                <v-icon color="#757575"> mdi-account-multiple </v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="7">
          <v-card elevation="0" v-if="activeItem != null">
            <UpdateHotlist
              v-on:close-dialog="updateHotlistDialogue = false"
              @reload-table="init()"
              :activeHotlist="activeItem"
            ></UpdateHotlist>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import {
  GET_ALL_HOTLISTS,
  SEND_WHATS_APP_SURVEY,
  GET_LEAD_USER_BY_ID,
} from "../../store/action.type";
import { mapActions, mapGetters } from "vuex";
import UpdateHotlist from "./dialogs/UpdateHotlist.vue";
import CreateHotlist from "./dialogs/CreateHotlist.vue";
export default {
  components: { UpdateHotlist, CreateHotlist },
  data: () => ({
    createHotlistDialogue: false,
    updateHotlistDialogue: false,
    selectedHotlist: null,
    hotlist: null,
    activeItem: null,
  }),

  computed: {
    ...mapGetters("hotlists", ["hotlists_getter", "hotlist_members_getter"]),
    headers() {
      return [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Members",
          value: "hotlist_members.length",
        },
        {
          text: "Actions",
          value: "actions",
        },
      ];
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions("hotlists", {
      getAllHotlists: GET_ALL_HOTLISTS,
    }),
    ...mapActions("whats_app", {
      sendWhatsAppSurvey: SEND_WHATS_APP_SURVEY,
    }),
    ...mapActions("lead_users", {
      getLeadUserById: GET_LEAD_USER_BY_ID,
    }),
    async init() {
      dayjs.extend(relativeTime);
      await this.getAllHotlists();
      this.activeItem = this.hotlists_getter[0];
    },
    openCreateHotlist() {
      this.createHotlistDialogue = true;
    },
    openUpdateHotlist(item) {
      this.updateHotlistDialogue = true;
      this.selectedHotlist = item;
    },
    setActiveHotlist(item) {
      this.activeItem = item;
    },
    timePassed(item) {
      let date = new Date(item.createdAt);
      return dayjs(date).fromNow();
    },
    latestLeadName(item) {
      if (item.lead_statuses.length > 0) {
        const createdDate = new Date(
          item.lead_statuses[item.lead_statuses.length - 1].createdAt
        );
        const currentDate = new Date();
        const diffInMs = currentDate.getTime() - createdDate.getTime();
        const diffInMonths = Math.floor(diffInMs / (1000 * 60 * 60 * 24 * 30)); // assuming 30 days in a month
        return diffInMonths + " months";
      } else {
        return "some time";
      }
    },
    async sendSurvey() {
      const { dispatch } = this.$store;
      let users = [];
      let someth;
      for (let i = 0; i < this.activeItem.hotlist_members.length; i++) {
        await this.getLeadUserById(
          this.activeItem.hotlist_members[i].lead_user.id
        ).then((res) => {
          someth = this.latestLeadName(res.lead_user);
          this.activeItem.hotlist_members[i].lead_user.applied_date = someth;
        });
        users.push(this.activeItem.hotlist_members[i].lead_user);
      }
      await this.sendWhatsAppSurvey({
        users: users,
      }).then(() => {
        dispatch("alerts/success", this.$t("send_email.successful_send"));
      });
    },
  },
};
</script>

<style scoped>
.scrollableList {
  max-height: 700px;
  overflow-y: auto;
}
</style>
