// Clients
export const GET_CLIENTS = "getClients";
export const GET_CLIENT_DATA = "getClientData";

// ! Authentication (unused)
// export const LOGIN = "login";
// export const LOGOUT = "logout";
// export const GET_SELF = "getSelf";

// Company
export const CREATE_COMPANY = "createCompany";
export const UPDATE_COMPANY = "updateCompany";
export const GET_COMPANIES = "getCompanies";
export const GET_COMPANIES_WITH_PLATFORM_NAME = "getCompaniesWithPlatformName";
export const GET_COMPANY = "getCompany";

// Contact moments
export const CREATE_CONTACT_MOMENT = "createContactMoment";
export const UPDATE_CONTACT_MOMENT = "updateContactMoment";
export const GET_CONTACT_MOMENT_FOR_LEAD_USER = "getContactMomentForLeadUser";
export const GET_CONTACT_MOMENTS_PER_PERIOD = "getContactMomentsPeriod";
export const DELETE_FIRST_CONTACT_MOMENT = "deleteFirstContactMoment";

// Contact
export const GET_CONTACTS = "getContacts";
export const CREATE_CONTACT = "createContact";
export const UPDATE_CONTACT = "updateContact";

// Dashboard
export const GET_GLOBAL_ANALYTICS = "getGlobalAnalytics";
export const GET_RECRUITER_ANALYTICS = "getRecruiterAnalytics";
export const GET_RECRUITMENT_ASSISTANT_ANALYTICS =
  "getRecruitmentAssistantAnalytics";

// File upload
export const UPLOAD_DOCUMENT = "uploadDocument";
export const DOWNLOAD_DOCUMENT = "downloadDocument";
export const DELETE_DOCUMENT = "deleteDocument";

// Filters
export const SYNC_LISTS = "syncLists";
export const FETCH_VACANCIES = "fetchVacancies";
export const FETCH_EMPLOYEES = "fetchEmployees";
export const FETCH_COMPANIES = "fetchCompanies";
export const FETCH_ROLES = "fetchRoles";

// Flow
export const UPDATE_FLOW = "updateFlow";
export const GET_DASHBOARD_FLOW_LEADS = "getDashboardFlowLeads";
export const GET_DASHBOARD_FLOW_LEADS_PER_YEAR = "getDashboardFlowLeadsPerYear";

// Intake data
export const CREATE_INTAKE = "createIntake";
export const GET_INTAKE_BY_USER = "getIntakeByUser";

// Interests
export const GET_INTERESTS = "getInterests";
export const GET_INTERESTS_FOR_LEAD_USER = "getInterestsForLeadUser";
export const GET_INTERESTS_FOR_JOB = "getInterestsForJob";
export const CREATE_INTEREST = "createInterest";
export const UPDATE_INTEREST = "updateInterest";

// Departments
export const GET_DEPARTMENTS = "getDepartments";
export const GET_DEPARTMENT_BY_ID = "getDepartmentById";
export const CREATE_DEPARTMENT = "createDepartment";
export const UPDATE_DEPARTMENT = "updateDepartment";

// Roles
export const GET_ROLES = "getRoles";
export const GET_ROLE_BY_ID = "getRoleById";
export const CREATE_ROLE = "createRole";
export const UPDATE_ROLE = "updateRole";

// Industries
export const GET_INDUSTRIES = "getIndustries";
export const GET_INDUSTRY_BY_ID = "getIndustryById";
export const CREATE_INDUSTRY = "createIndustry";
export const UPDATE_INDUSTRY = "updateIndustry";

// Jobs
export const GET_JOBS = "getJobs";
export const GET_JOB_BY_ID = "getJobById";
export const CREATE_JOB = "createJob";
export const UPDATE_JOB = "updateJob";

// Lead users
export const GET_LEAD_USERS = "getLeadUsers";
export const GET_LEAD_USERS_WITH_LEAD = "getLeadUsersWithLead";
export const SEARCH_LEAD_USERS_WITH_LEADS = "searchLeadUsersWithLeads";
export const GET_LEAD_USER_BY_ID = "getLeadUserById";
export const CREATE_LEAD_USER = "createLeadUser";
export const UPDATE_LEAD_USER = "updateLeadUser";
export const ADD_STATUS_TO_LEAD_USER = "addStatusToLeadUser";
export const CHECK_IF_USER_EXISTS = "checkIfUserExists";
export const DELETE_LEAD_USER = "deleteLeadUser";
export const GET_LEAD_USERS_BY_RECRUITER = "getLeadUsersByRecruiter";
export const SEARCH_LEAD_USERS_BY_NAME = "searchLeadUsersByName";
export const EMPTY_FOUND_LEAD_USERS = "emptyFoundLeadUsers";

// Leads
export const GET_LEADS = "getLeads";
export const GET_LEAD_BY_ID = "getLeadById";
export const GET_LEADS_BY_VACANCY_ID = "getLeadsByVacancyId";
export const ADD_STATUS_TO_LEAD = "addStatusToLead";
export const GET_ALL_LEADS_WITH_FLOW = "getAllLeadsWithFlow";
export const GET_LEADS_PER_PERIOD = "getLeadsPerPeriod";
export const GET_LEADS_BY_MONTH = "getLeadsByMonth";
export const CREATE_LEAD = "createLead";
export const UPDATE_LEAD = "updateLead";
export const DELETE_LEAD_BY_ID = "deleteLeadById";
export const GET_CHANNELS = "getChannels";
export const GET_LEAD_VACANCIES = "getLeadVacancies";
export const ACTIVATE_LEAD = "activateLead";

// Marketing spending
export const CREATE_SPENDING = "createSpending";
export const UPDATE_SPENDING = "updateSpending";
export const GET_SPENDING_BY_MONTH = "getSpendingByMonth";
export const GET_SPENDINGS = "getSpendings";

// Notifications
export const INCREMENT_NOTIF_COUNT = "incrementNotifCount";
export const DECREASE_NOTIF_COUNT = "decreaseNotifCount";
export const CLEAR_NOTIF_COUNT = "clearNotifCount";
export const ADD_NOTIFICATION = "addNotification";
export const SOCKET_AUTHENTICATE = "socketAuthenticate";
export const GET_NOTIFICATIONS = "getNotifications";
export const MARK_NOTIFICATION_READ = "markNotificationRead";
export const READ_ALL_NOTIFICATIONS = "readAllNotifications";
export const GET_ALL_NOTIFICATION_GROUPS = "getAllNotificationGroups";
export const UPDATE_EMPLOYEE_GROUPS = "updateEmployeeGroups";

// ! Positions (unused)
export const CREATE_POSITION = "createPosition";
export const GET_POSITIONS = "getPositions";
export const ADD_POSITION_TO_COMPANY = "addPositionToCompany";
export const REMOVE_POSITION_FROM_COMPANY = "removePositionFromCompany";

// Send grid
export const SEND_CONTACTS = "sendContacts";
export const GET_ALL_CONTACT_LISTS = "getAllContactLists";
export const CREATE_CONTACT_LIST = "createContactList";
export const SEND_ACQUISITION_MAIL = "sendAcquisitionMail";
export const NO_CONTACT_EMAIL = "noContactEmail";
export const SEND_RESCHEDULE_MAIL = "sendRescheduleMail";
export const CONTRACT_REMINDER_MAIL = "contractReminderMail";
export const END_OF_CONTACT_EMAIL = "endOfContactEmail";
export const GET_MAIL_TRIGGERS = "getMailTriggers";
export const UPDATE_EMAIL_TRIGGERS = "updateEmailTriggers";
export const SEND_EMAIL = "sendEmail";

// Statuses ->
// Contact moment status
export const CREATE_CONTACT_MOMENT_STATUS = "createContactMomentStatus";
export const UPDATE_CONTACT_MOMENT_STATUS = "updateContactMomentStatus";
export const GET_CONTACT_MOMENT_STATUSES = "getContactMomentStatus";
// Lead status
export const CREATE_LEAD_STATUS = "createLeadStatus";
export const UPDATE_LEAD_STATUS = "updateLeadStatus";
export const GET_LEAD_STATUSES = "getLeadStatuses";
// Sub status
export const CREATE_SUB_STATUS = "createSubStatus";
export const UPDATE_SUB_STATUS = "updateSubStatus";
// Vacancy status
export const CREATE_VACANCY_STATUS = "createVacancyStatus";
export const UPDATE_VACANCY_STATUS = "updateVacancyStatus";
export const GET_VACANCY_STATUSES = "getVacancyStatuses";

// Teamup
export const GET_ALL_SUB_CALENDARS = "getAllSubCalendars";
export const CREATE_EVENT = "createEvent";

// Users
export const GET_USERS = "getUsers";
export const GET_SELF = "getSelf";
export const CREATE_EMPLOYEE = "createEmployee";
export const UPDATE_EMPLOYEE = "updateEmployee";
export const GET_EMPLOYEE_BY_ID = "getEmployeeById";
export const GET_PERMISSIONS = "getPermissions";
export const GET_USER_PERMISSIONS = "getUserPermissions";
export const UPDATE_PERMISSIONS = "updatePermissions";
export const UPLOAD_PROFILE_PIC = "uploadProfilePic";
export const GET_EMPLOYEE_PROFILE_PIC = "getEmployeeProfilePic";

// Utility
export const REQUEST_FEATURE = "requestFeature";
export const REPORT_BUG = "reportBug";
export const PARSE_RESUME = "parseResume";
export const GET_DOCUMENT_BY_ID = "getDocumentById";
export const GET_DISTANCE_BY_CITIES_IN_BULK = "getDistanceByCitiesInBulk";
export const GET_LOGS = "getLogs";
export const DELETE_LOG = "deleteLog";
export const DELETE_ALL_LOGS = "deleteAllLogs";
export const UPDATE_LEAD_USER_FROM_CV = "updateLeadUserFromCv";

// Vacancy filters
export const CREATE_VACANCY_FILTER = "createVacancyFilter";
export const GET_VACANCY_FILTER_BY_ID = "getVacancyFilterById";
export const UPDATE_VACANCY_FILTER = "updateVacancyFilter";

// Vacancies
export const GET_VACANCIES = "getVacancies";
export const CREATE_VACANCY = "createVacancy";
export const GET_VACANCY_BY_ID = "getVacancyById";
// ! exists already => export const GET_VACANCY_STATUSES = "getVacancyStatuses";
export const GET_VACANCIES_BY_COMPANY_ID = "getVacanciesByCompanyId";
export const GET_VACANCIES_WITH_SEARCH_TERMS = "getVacanciesWithSearchTerms";
export const UPDATE_VACANCY = "updateVacancy";
export const ADD_APPLICATION_FORM_ID = "addAppFormId";
export const CREATE_VACANCY_LEAD_USER = "createVacancyLeadUser";
export const REMOVE_VACANCY_LEAD_USER = "removeVacancyLeadUser";

// External Api Keys
export const GENERATE_API_KEY = "generateApiKey";
export const GET_API_USAGE = "getApiUsage";

// Whats App
export const SEND_MESSAGE = "sendMessage";
export const GET_ALL_MESSAGES_FOR_USER = "getAllMessagesForUser";
export const CREATE_CONVERSATION = "createConversation";
export const REFETCH_MESSAGES = "refetchMessages";
export const GET_CONVERSATION_BY_ID = "getConversationById";
export const GET_ALL_WEBHOOKS = "getAllWebHooks";
export const CREATE_WEBHOOK = "createWebhook";
export const DELETE_WEBHOOK = "deleteWebHook";
export const GET_USERS_WITH_CONVERSATIONS = "getUsersWithConversations";
export const DELETE_ALL_NEW_MESSAGES = "deleteAllNewMessages";
export const UPDATE_UNREAD_MESSAGES = "updateUnreadMessages";
export const SEND_WHATS_APP_SURVEY = "sendWhatsAppSurvey";

// Lead Pipes
export const GET_LEAD_PIPES = "getLeadPipes";
export const DELETE_LEAD_PIPE = "deleteLeadPipe";

// Vacancy Flow
export const GET_VACANCIES_ALL = "getVacanciesAll";
export const GET_VACANCY_FLOW = "getVacancyFlow";
export const GET_VACANCY_NO_FLOW = "getVacancyNoFlow";
export const GET_VACANCY_FLOW_BY_ID = "getVacancyFlowById";
export const GET_VACANCY_FLOW_BY_COMPANY = "getVacancyFlowByCompany";
export const GET_VACANCIES_HOT = "getVacanciesHot";
export const UPDATE_VACANCY_FLOW = "updateVacancyFlow";
export const BULK_UPDATE_VACANCY_FLOW = "bulkUpdateVacancyFlow";
export const CREATE_VACANCY_FLOW = "createVacancyFlow";

//Vacancy Categories
export const CREATE_CATEGORY = "createCategory";
export const DELETE_CATEGORY = "deleteCategory";
export const UPDATE_CATEGORY = "updateCategory";

//Vacancy Template
export const GET_ALL_TEMPLATES = "getAllTemplates";
export const CREATE_TEMPLATE = "createTemplate";
export const UPDATE_TEMPLATE = "updateTemplate";
export const DELETE_TEMPLATE = "deleteTemplate";

//Email Template
export const GET_ALL_EMAIL_TEMPLATES = "getAllEmailTemplates";
export const CREATE_EMAIL_TEMPLATE = "createEmailTemplate";
export const UPDATE_EMAIL_TEMPLATE = "updateEmailTemplate";
export const DELETE_EMAIL_TEMPLATE = "deleteEmailTemplate";

//Hotlists
export const GET_ALL_HOTLISTS = "getAllHotlists";
export const GET_HOTLIST_BY_ID = "getHotlistById";
export const CREATE_HOTLIST = "createHotlist";
export const UPDATE_HOTLIST = "updateHotlist";
export const DELETE_HOTLIST = "deleteHotlist";

//Hotlist Members
export const ADD_HOTLIST_MEMBER = "addHotlistMember";
export const BULK_ADD_HOTLIST_MEMBERS = "bulkAddHotlistMembers";
export const REMOVE_HOTLIST_MEMBER = "removeHotlistMember";

//Email Activities
export const GET_EMAIL_ACTIVITY = "getEmailActivity";

//Whatsapp Survey Users
export const GET_ALL_WHATSAPP_SURVEY_USERS = "getAllWhatsappSurveyUsers";
export const DELETE_WHATSAPP_SURVEY_USER = "deleteWhatsAppSurveyUsers";
