<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <SubTable
          v-if="positionsLoaded"
          type="1"
          v-bind:data="positionsItems.positions.positions"
        ></SubTable>
        <SubTable
          v-if="leadStatusesLoaded && authorized"
          type="3"
          v-bind:data="statusItems.lead_statuses"
        ></SubTable>
        <SubTable
          v-if="getLoadingStates.roles && authorized"
          type="7"
          v-bind:data="roles"
        ></SubTable>
        <v-card elevation="4" class="mx-3">
          <v-card-title>Email Triggers</v-card-title>
          <v-divider class="mx-3"></v-divider>
          <EmailOptions
            v-if="getLoadingStates.roles && authorized"
          ></EmailOptions>
        </v-card>
        <v-card elevation="4" class="mx-3 mt-4">
          <v-card-title>MessageBird Webhooks</v-card-title>
          <WhatsAppWebhooks v-if="authorized"></WhatsAppWebhooks>
        </v-card>
        <VacancyCategories v-if="authorized"></VacancyCategories>
      </v-col>
      <v-col>
        <SubTable
          v-if="employeesLoaded && authorized"
          type="2"
          v-bind:data="employeeItems.all.items.employees"
        ></SubTable>
        <SubTable
          v-if="contactMomentStatusesLoaded && authorized"
          type="4"
          v-bind:data="statusItems.contact_moment_statuses"
        ></SubTable>
        <SubTable
          v-if="getLoadingStates.departments && authorized"
          type="8"
          v-bind:data="departments"
        ></SubTable>
        <v-card elevation="4" class="mx-3 mb-5 mt-2">
          <v-card-title>External API KEY</v-card-title>
          <v-divider class="mx-3"></v-divider>
          <ApiKeyTable v-if="authorized"></ApiKeyTable>
        </v-card>
        <v-card elevation="4" class="mx-3 mb-5 mt-2">
          <v-card-title>External API Usage</v-card-title>
          <ApiKeyUsageTable v-if="authorized"></ApiKeyUsageTable>
        </v-card>
        <VacancyTemplate v-if="authorized"></VacancyTemplate>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import VacancyTemplate from "@/components/vacancy_flow/components/VacancyTemplate.vue";
import VacancyCategories from "@/components/vacancy_flow/components/VacancyCategories.vue";
import SubTable from "@/components/Tables/SubTables.vue";
import EmailOptions from "@/components/Selectors/EmailOptions.vue";
import ApiKeyTable from "@/components/ApiKey/ApiKeyTable.vue";
import ApiKeyUsageTable from "@/components/ApiKey/ApiKeyUsageTable.vue";
import WhatsAppWebhooks from "@/components/WhatsApp/WhatsAppWebhooks.vue";
import EmailTemplates from "../components/emails/EmailTemplates.vue";
import Hotlists from "../components/hotlists/Hotlists.vue";
import { permission } from "@/utils/permissions";
import {
  GET_CONTACT_MOMENT_STATUSES,
  GET_DEPARTMENTS,
  GET_LEAD_STATUSES,
  GET_POSITIONS,
  GET_ROLES,
  GET_USERS,
} from "../store/action.type";
export default {
  components: {
    SubTable,
    EmailOptions,
    ApiKeyTable,
    ApiKeyUsageTable,
    WhatsAppWebhooks,
    VacancyTemplate,
    VacancyCategories,
  },
  data: () => ({
    adding: false,
    positionsLoaded: false,
    employeesLoaded: false,
    contactMomentStatusesLoaded: false,
    leadStatusesLoaded: false,
    interestsLoaded: false,
    valid: true,
    positionSelect: "",
  }),

  created() {
    this.initialize();
  },

  computed: {
    ...mapState({
      positionsItems: (state) => state.positions,

      employeeItems: (state) => state.users,

      statusItems: (state) => state.statuses,
    }),
    ...mapGetters("interests", ["getLoadingStates", "departments", "roles"]),
    authorized() {
      if (permission("isAdmin")) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    ...mapActions("interests", {
      getRoles: GET_ROLES,
      getDepartments: GET_DEPARTMENTS,
    }),
    ...mapActions("positions", { getPositions: GET_POSITIONS }),
    ...mapActions("statuses", {
      getContactMomentStatuses: GET_CONTACT_MOMENT_STATUSES,
      getLeadStatuses: GET_LEAD_STATUSES,
    }),
    ...mapActions("users", { getUsers: GET_USERS }),
    async initialize() {
      await this.getPositions().then(() => {
        this.positionsLoaded = true;
      });
      await this.getUsers().then(() => {
        this.employeesLoaded = true;
      });
      await this.getContactMomentStatuses().then(() => {
        this.contactMomentStatusesLoaded = true;
      });
      await this.getLeadStatuses().then(() => {
        this.leadStatusesLoaded = true;
      });
      await this.getRoles();
      await this.getDepartments();
    },
  },
};
</script>
