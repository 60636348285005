<template>
  <v-container>
    <v-dialog v-model="createCategoryDialog" width="450px">
      <v-card>
        <CreateCategory
          :type="categoryType"
          :activeCategory="selectedCategory"
          v-on:close-dialog="createCategoryDialog = false"
          @reload-table="init()"
        ></CreateCategory>
      </v-card>
    </v-dialog>
    <v-card elevation="4" class="pa-3">
      <v-row class="ma-0">
        <v-card-title class="pt-1">Vacancy categories</v-card-title>
        <v-spacer />
        <v-btn
          color="primary"
          @click="openCreateDialog(selectedCategory, 'create')"
          ><v-icon> mdi-plus</v-icon></v-btn
        >
      </v-row>
      <v-data-table
        :headers="headers"
        :items="vacancy_flow_getter"
        :items-per-page="5"
        class="elevation-1"
      >
        <template v-slot:[`item.name`]="{ item }">
          {{
            item.name.length >= 40 ? item.name.slice(0, 40) + "..." : item.name
          }}</template
        >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="openCreateDialog(item, 'update')">
            mdi-pencil
          </v-icon>
        </template></v-data-table
      >
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { GET_VACANCY_FLOW } from "../../../store/action.type";
import CreateCategory from "../dialogs/CreateCategory.vue";
export default {
  components: { CreateCategory },
  data: () => ({
    createCategoryDialog: false,
    selectedCategory: null,
    categoryType: null,
  }),

  computed: {
    ...mapGetters("vacancy_flow", ["vacancy_flow_getter"]),
    headers() {
      return [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions("vacancy_flow", {
      getVacancyFlow: GET_VACANCY_FLOW,
    }),
    async init() {
      await this.getVacancyFlow();
    },
    openCreateDialog(item, type) {
      this.categoryType = type;
      this.createCategoryDialog = true;
      if (type == "update") {
        this.selectedCategory = item;
      } else {
        this.selectedCategory = {
          name: "",
        };
      }
    },
  },
};
</script>
