<template>
  <v-card elevation="1">
    <v-data-table
      :headers="headers"
      :items="vacancies"
      :items-per-page="4"
      :footer-props="{
        'items-per-page-options': [4],
        'disable-items-per-page': true,
      }"
      class="elevation-1 pointerOnHover"
      width="100%"
      height="275"
      @click:row="goToVacancy"
    >
      <template v-slot:[`item.isHot`]>
        <v-icon color="red lighten-1">mdi-fire</v-icon>
      </template>
      <template v-slot:[`item.Vacancy_status`]="{ item }">
        <v-chip
          label
          class="ma-2 statusChip"
          :color="item.Vacancy_status.color"
          dark
          elevation="4"
        >
          {{ item.Vacancy_status.name }}
        </v-chip>
      </template>
      <template v-slot:[`item.Category`]="{ item }">
        {{ item.Category ? item.Category.name : "No Category" }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { GET_VACANCIES_HOT } from "../../store/action.type";
import { mapActions } from "vuex";
export default {
  data: () => ({
    isLoaded: false,
    hotVacancies: [],
  }),
  computed: {
    vacancies() {
      return this.hotVacancies;
    },
    headers() {
      return [
        {
          text: "",
          align: "start",
          value: "isHot",
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Status", sortable: false, value: "Vacancy_status" },
        { text: "Category", sortable: false, value: "Category" },
        { text: "Start Date", sortable: false, value: "startDate" },
      ];
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions("vacancy_flow", { getVacanciesHot: GET_VACANCIES_HOT }),
    async init() {
      await this.getVacancies();
      this.isLoaded = true;
    },
    async getVacancies() {
      const response = await this.getVacanciesHot();
      this.hotVacancies = response;
    },
    goToVacancy(item) {
      this.$router.push(`/vacature/${item.id}`);
    },
  },
};
</script>

<style>
.statusChip {
  font-weight: 500;
}
</style>
