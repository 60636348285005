<template wrapper>
  <v-col xl="4" lg="5" md="6" sm="12">
    <v-dialog
      v-model="intakeFormDialog"
      persistent
      :overlay-opacity="opacity"
      width="750"
      min-height="800"
    >
      <v-card style="width: 750px; height: 100%">
        <IntakeForm
          v-on:close-dialog="intakeFormDialog = false"
          :user="lead"
          :lead="mainLead"
        ></IntakeForm>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEditPerson" max-width="600px">
      <v-card>
        <CreateLeadUser
          v-on:close-dialog="dialogEditPerson = false"
          type="1"
          :parentID="lead.id"
          :formData="lead"
        ></CreateLeadUser>
      </v-card>
    </v-dialog>

    <v-container fluid class="pl-0 pr-0">
      <v-col class="pa-0">
        <v-card min-height="380">
          <v-row :style="cardHeaders" class="ma-0">
            <v-row class="pa-5">
              <v-col md="6">
                <v-row>
                  <v-icon medium>mdi-account</v-icon>
                  <h3>
                    <strong>{{
                      `${lead.first_name} ${lead.last_name}`
                    }}</strong>
                  </h3>

                  <v-chip
                    label
                    v-if="lead.perma_status"
                    :color="lead.perma_status.color"
                    class="ml-3"
                  >
                    <div
                      :style="`color: ${calculateTextColor(
                        lead.perma_status.color
                      )}`"
                    >
                      <strong>{{ lead.perma_status.name }}</strong>
                    </div></v-chip
                  >
                </v-row>
              </v-col>

              <v-col align="end" class="pa-0">
                <v-btn
                  height="20px"
                  depressed
                  color="primary"
                  dense
                  class="mr-2"
                  @click="openIntakeForm()"
                >
                  {{ $t("profile_page.intake") }}
                </v-btn>
                <span v-if="mainLead">
                  <span v-if="mainLead.flow && mainLead.flow.first_date">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="green" dark v-bind="attrs" v-on="on">
                          mdi-phone
                        </v-icon>
                      </template>
                      <span>{{ toLocalDate(mainLead.flow.first_date) }}</span>
                    </v-tooltip>
                  </span>

                  <v-badge
                    v-else-if="
                      mainLead.flow &&
                      mainLead.flow.vm_date &&
                      mainLead.flow.vm_count
                    "
                    bordered
                    color="error"
                    :content="mainLead.flow.vm_count"
                    overlap
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          elevation="0"
                          color="error"
                          small
                          @click="openFlowSpoken(lead, mainLead)"
                          class="pl-1 pr-3"
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-phone-missed</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ toLocalDate(mainLead.flow.vm_date) }}</span>
                    </v-tooltip>
                  </v-badge>

                  <v-btn
                    v-else-if="mainLead.flow && mainLead.flow.id"
                    elevation="0"
                    color="primary"
                    small
                    @click="openFlowSpoken(lead, mainLead)"
                    icon
                  >
                    <v-icon color="red" dark> mdi-phone </v-icon>
                  </v-btn>
                </span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      medium
                      @click="dialogEditPerson = true"
                      class="pl-2"
                      >mdi-pencil-box</v-icon
                    >
                  </template>
                  <span>{{ $t("profile_page.person_update") }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-row>
          <v-row class="mt-0">
            <v-col class="pt-0">
              <v-row class="pl-5 mt-0 pb-0">
                <v-col class="pb-0">
                  <span>
                    <v-icon medium class="pr-1">mdi-phone</v-icon
                    >{{ lead.tel_number }}
                    <br />
                    <v-icon medium class="pr-1">mdi-email</v-icon
                    ><a :href="'mailto:' + lead.email">{{ lead.email }}</a>
                    <br />

                    <p
                      class="mb-0"
                      v-if="
                        lead.address != undefined && lead.address.street != ''
                      "
                    >
                      <v-icon>mdi-home</v-icon>
                      {{ lead.address.street }},
                      {{ lead.address.postalcode }}
                      {{
                        lead.address.houseno +
                        (lead.address.addition
                          ? " " + lead.address.addition
                          : "")
                      }},
                      {{ lead.address.region }}
                    </p>
                    <p class="mb-0" v-else-if="lead.region != undefined">
                      <v-icon>mdi-home</v-icon> {{ lead.region }}
                    </p>
                    <p class="mb-0" v-else>
                      <v-icon>mdi-home</v-icon
                      >{{ $t("profile_page.adress_unknown") }}
                    </p>
                    <v-icon medium class="pr-0">mdi-calendar</v-icon>
                    <p style="display: inline" v-if="lead.dob != undefined">
                      {{ formatDate(lead.dob) }}
                    </p>
                    <p style="display: inline" v-else>
                      {{ $t("profile_page.dob_unknown") }}
                    </p>
                    <br />
                    <v-icon medium class="pr-0">mdi-account</v-icon>
                    <p style="display: inline" v-if="lead.gender != undefined">
                      {{ lead.gender }}
                    </p>
                    <p style="display: inline" v-else>
                      {{ $t("profile_page.gender_unknown") }}
                    </p>
                    <br />
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="pr-1" v-bind="attrs" v-on="on" medium
                          >mdi-currency-eur</v-icon
                        >
                      </template>
                      <span>{{ $t("profile_page.salary_indication") }}</span>
                    </v-tooltip>
                    <p
                      style="display: inline"
                      v-if="lead.sal_indication != undefined"
                    >
                      {{ lead.sal_indication }}
                    </p>
                    <p style="display: inline" v-else>
                      {{ $t("profile_page.unknown") }}
                    </p>
                    <br />
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="pr-1" v-bind="attrs" v-on="on" medium
                          >mdi-file-document-edit</v-icon
                        >
                      </template>
                      <span>{{ $t("profile_page.contract_term") }}</span>
                    </v-tooltip>
                    <p
                      style="display: inline"
                      v-if="lead.contract_term != undefined"
                    >
                      {{ lead.contract_term }}
                    </p>
                    <p style="display: inline" v-else>
                      {{ $t("profile_page.unknown") }}
                    </p>
                    <br />
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="pr-1" v-bind="attrs" v-on="on" medium
                          >mdi-clock-time-nine</v-icon
                        >
                      </template>
                      <span>{{ $t("profile_page.job_scope") }}</span>
                    </v-tooltip>
                    <p
                      style="display: inline"
                      v-if="lead.contract_type != undefined"
                    >
                      {{ lead.contract_type }}
                    </p>
                    <p style="display: inline" v-else>
                      {{ $t("profile_page.unknown") }}
                    </p>
                  </span>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row class="mt-0">
                <v-col class="pt-0">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="pr-3"
                        v-bind="attrs"
                        v-on="on"
                        :color="lead.car ? 'green' : 'red'"
                        medium
                        >mdi-car</v-icon
                      >
                    </template>
                    <span>{{
                      lead.car
                        ? $t("profile_page.has_car")
                        : $t("profile_page.no_car")
                    }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="pr-3"
                        v-bind="attrs"
                        v-on="on"
                        :color="lead.drivers_license ? 'green' : 'red'"
                        medium
                        >mdi-card-account-details</v-icon
                      >
                    </template>
                    <span>{{
                      lead.drivers_license
                        ? $t("profile_page.has_license")
                        : $t("profile_page.no_license")
                    }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="pr-3"
                        v-bind="attrs"
                        v-on="on"
                        :color="lead.contract_signed ? 'green' : 'red'"
                        medium
                        >mdi-file-document-edit</v-icon
                      >
                    </template>
                    <span>{{
                      lead.contract_signed
                        ? $t("profile_page.contract_signed_on") +
                          " " +
                          formatDate(lead.contract)
                        : $t("profile_page.contract_not_signed")
                    }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="pr-1"
                        v-bind="attrs"
                        v-on="on"
                        :color="lead.bullhorn ? 'green' : 'red'"
                        medium
                        >mdi-bullhorn</v-icon
                      >
                    </template>
                    <span>{{
                      lead.bullhorn
                        ? $t("profile_page.in_bullhorn")
                        : $t("profile_page.not_in_bullhorn")
                    }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <h4>
                <v-icon class="pr-1">mdi-earth</v-icon
                >{{ $t("profile_page.language") }}
              </h4>
              <p
                v-if="
                  lead.languages == undefined || lead.languages[0] == undefined
                "
              >
                {{ $t("profile_page.no_languages") }}
              </p>
              <v-card
                class="overflow-y-auto"
                max-height="100"
                max-width="200"
                elevation="0"
              >
                <v-list disabled dense>
                  <v-list-item
                    v-for="(item, i) in lead.languages"
                    :key="i"
                    class="pl-1 mb-1"
                    style="min-height: 0px"
                  >
                    <v-list-item-icon class="ma-0 mr-1">
                      <CountryFlag :country="item.flag" />
                    </v-list-item-icon>
                    <v-list-item-content class="pt-0 pb-0 mt-0">
                      <v-list-item-title v-text="item.name"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
              <h4><v-icon class="pr-1">mdi-email</v-icon>Email Talen</h4>
              <v-radio-group class="my-0" v-model="emailLanguage" row dense>
                <v-radio label="Dutch" value="nl"></v-radio>
                <v-radio label="English" value="en"></v-radio>
              </v-radio-group>
              <h4><v-icon class="pr-1">mdi-domain</v-icon>Company</h4>
              <v-radio-group class="my-0 pb-0" v-model="company" row dense>
                <v-radio label="Doen" value="Doen"></v-radio>
                <v-radio label="Zoomer" value="Zoomer"></v-radio>
              </v-radio-group>
              <v-col
                v-if="
                  this.lead.company !== company ||
                  this.lead.emailLanguage !== emailLanguage
                "
                class="text-right py-0 my-0"
              >
                <v-btn
                  style="height: 25px; font-size: 11pt"
                  dense
                  class="mr-2"
                  color="secondary"
                  @click="discardEmailPreferences()"
                  >discard</v-btn
                >
                <v-btn
                  style="height: 25px; font-size: 11pt"
                  dense
                  color="primary"
                  @click="saveEmailPreferences()"
                  >save</v-btn
                >
              </v-col>
            </v-col>
          </v-row>
          <v-row class="pl-3 pr-3 mt-0 pb-4" justify="center" align="center"
            ><v-col md="11"><v-divider></v-divider></v-col
          ></v-row>
          <v-row class="pl-5 pr-3 mt-0 pb-0">
            <v-col class="pt-0">
              <h4>
                <v-icon>mdi-clipboard</v-icon>
                {{ $t("profile_page.notes") }}
              </h4>
              <v-card
                class="overflow-y-auto pl-1"
                max-height="100"
                elevation="0"
              >
                {{ lead.notes }}
              </v-card>
            </v-col>
          </v-row>
          <v-bottom-sheet v-model="dialogSpeak" inset>
            <v-sheet class="text-center">
              <SpokenDatePicker
                :type="2"
                v-on:close-dialog="dialogSpeak = false"
                :leadID="leadId"
                :leadUserID="leadUserId"
                :leadName="leadName"
                :leadContactNo="leadContactNo"
                :vmCount="leadVmCount"
              />
            </v-sheet>
          </v-bottom-sheet>
        </v-card>
      </v-col>
    </v-container>
  </v-col>
</template>

<script>
import { mapActions } from "vuex";
import SpokenDatePicker from "../../dialogs/SpokenDatePicker";
import CountryFlag from "vue-country-flag";
import IntakeForm from "../../dialogs/IntakeForm.vue";
import CreateLeadUser from "../../dialogs/CreateLeadUser.vue";
import {
  UPDATE_LEAD_USER,
  GET_LEAD_USER_BY_ID,
} from "../../../store/action.type";

export default {
  props: ["lead", "mainLead", "cardHeaders"],
  components: {
    CountryFlag,
    SpokenDatePicker,
    IntakeForm,
    CreateLeadUser,
  },
  data: () => ({
    emailLanguage: null,
    company: null,
    intakeFormDialog: false,
    opacity: 0.9,
    dialogEditPerson: false,
    dialogSpeak: false,
    leadId: null,
    leadUserId: null,
    leadName: null,
    leadContactNo: null,
    leadVmCount: null,
  }),
  async created() {
    this.emailLanguage = this.lead.emailLanguage;
    this.company = this.lead.company;
  },
  methods: {
    ...mapActions("lead_users", {
      getLeadUserById: GET_LEAD_USER_BY_ID,
      updateLeadUser: UPDATE_LEAD_USER,
    }),
    calculateTextColor(bgColor) {
      let color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
      let r = parseInt(color.substring(0, 2), 16); // hexToR
      let g = parseInt(color.substring(2, 4), 16); // hexToG
      let b = parseInt(color.substring(4, 6), 16); // hexToB
      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#ffffff";
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    openIntakeForm() {
      this.intakeFormDialog = true;
    },
    toLocalDate(date) {
      var utcDate = new Date(date);
      const localeString = utcDate.toLocaleString("nl-NL");
      return localeString.substring(0, localeString.length - 3);
    },
    discardEmailPreferences() {
      this.emailLanguage = this.lead.emailLanguage;
      this.company = this.lead.company;
    },
    async saveEmailPreferences() {
      const { dispatch } = this.$store;
      let lead_user = {
        company: this.company,
        emailLanguage: this.emailLanguage,
      };
      let userId = this.lead.id;
      await this.updateLeadUser({
        lead_user: lead_user,
        userId: userId,
      }).then(() => {
        dispatch("alerts/success", "Persoon aangepast");
        this.getLeadUserById(userId);
      });
    },
    openFlowSpoken(lead, activeMainLead) {
      this.leadId = activeMainLead.flow.lead_id;
      this.leadUserId = lead.id;
      this.leadName = `${lead.first_name} ${lead.last_name}`;
      this.leadContactNo = lead.tel_number;
      this.leadVmCount = activeMainLead.flow.vm_count;
      this.dialogSpeak = true;
    },
  },
};
</script>
