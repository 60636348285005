import config from "../../../config";
import { authHeader } from "../../utils/auth-header";
import { handleResponse } from "../../utils/api_functions";
import { logger } from "../../utils/logger";

import { CREATE_INTAKE, GET_INTAKE_BY_USER } from "../action.type";

export const intake_data = {
  namespaced: true,
  state: {},
  actions: {
    async [CREATE_INTAKE](_, intakeData) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(intakeData),
        };
        return await fetch(`${config.apiUrl}/intake_data`, request)
          .then(handleResponse)
          .then((res) => {
            res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [GET_INTAKE_BY_USER](_, userId) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/intake_data/${userId}`, request)
          .then(handleResponse)
          .then((res) => {
            if (res.intake !== null) {
              res.intake.practicalMatters = JSON.parse(
                res.intake.practicalMatters
              );
              res.intake.summary = JSON.parse(res.intake.summary);
              res.intake.swot = JSON.parse(res.intake.swot);
              res.intake.education = JSON.parse(res.intake.education);
              res.intake.workExpectations = JSON.parse(
                res.intake.workExpectations
              );
              res.intake.workExperience = JSON.parse(res.intake.workExperience);
            }
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
  },
  mutations: {},
};
