<template>
  <v-row>
    <v-col class="pa-0 pt-1 pl-2">
      <v-list dense class="overflow-y-auto" max-height="300">
        <v-list-item-group color="primary">
          <v-list-item
            v-for="(item, i) in sollicitieperiodeItems"
            @click="
              setSollicitatieperiodeDate(item.id);
              toggleMenu();
            "
            :key="i"
          >
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-col>
    <v-col class="pl-0">
      <v-layout>
        <v-date-picker
          :key="picker"
          v-model="localDate"
          locale="nl-NL"
          color="green"
          header-color="primary"
          :landscape="true"
          no-title
          range
          fullHeight
          class="pr-1"
          @input="setDate($event)"
        >
        </v-date-picker>
      </v-layout> </v-col
  ></v-row>
</template>

<script>
import {
  subWeeks,
  startOfMonth,
  endOfMonth,
  subMonths,
  subYears,
  startOfYear,
  endOfYear,
  format,
  startOfWeek,
  endOfWeek,
  subDays,
} from "date-fns";

export default {
  data: () => ({
    localDate: [],
    picker: 0,
  }),

  props: {
    dates: Array,
  },

  computed: {
    datesVal: {
      get() {
        return this.dates;
      },
      set(newValue) {
        this.picker++;
        this.$emit("setDates", newValue);
      },
    },
    sollicitieperiodeItems() {
      return [
        { id: 0, text: this.$t("enhanced_date_picker.today") },
        { id: 1, text: this.$t("enhanced_date_picker.yesterday") },
        { id: 2, text: this.$t("enhanced_date_picker.this_week") },
        { id: 3, text: this.$t("enhanced_date_picker.last_week") },
        { id: 4, text: this.$t("enhanced_date_picker.last_two_weeks") },
        { id: 10, text: this.$t("enhanced_date_picker.last_thirty_one_days") },
        { id: 5, text: this.$t("enhanced_date_picker.this_month") },
        { id: 6, text: this.$t("enhanced_date_picker.last_month") },
        { id: 7, text: this.$t("enhanced_date_picker.this_year") },
        { id: 8, text: this.$t("enhanced_date_picker.last_year") },
        { id: 9, text: this.$t("enhanced_date_picker.two_years") },
      ];
    },
  },
  
  methods: {
    setDate(data) {
      this.picker++;
      this.$emit("setDates", data);
    },
    toggleMenu() {
      this.$emit("menuToggle");
    },
    getVandaagsDate() {
      let nowDate = new Date();
      let formatedDate = format(nowDate, "yyyy-MM-dd");
      return [formatedDate, formatedDate];
    },
    getGisterensDate() {
      let nowDate = new Date();
      let formatedDate = format(subDays(nowDate, 1), "yyyy-MM-dd");
      return [formatedDate, formatedDate];
    },
    getThisWeekDate() {
      let nowDate = new Date();
      let firstday = format(subDays(endOfWeek(nowDate), 2), "yyyy-MM-dd");
      let lastday = format(subDays(startOfWeek(nowDate), 2), "yyyy-MM-dd");
      return [firstday, lastday];
    },
    getLastWeekDates() {
      let nowDate = new Date();
      let lastWeekDate = subWeeks(nowDate, 1);
      let firstday = format(endOfWeek(lastWeekDate), "yyyy-MM-dd");
      let lastday = format(startOfWeek(lastWeekDate), "yyyy-MM-dd");
      return [firstday, lastday];
    },
    getPastTwoWeeksDates() {
      let nowDate = new Date();
      let lastWeekDate = subWeeks(nowDate, 1);
      let twoWeeksAgoDate = subWeeks(nowDate, 2);
      let firstday = format(subDays(endOfWeek(lastWeekDate), 2), "yyyy-MM-dd");
      let lastday = format(
        subDays(startOfWeek(twoWeeksAgoDate), 2),
        "yyyy-MM-dd"
      );
      return [firstday, lastday];
    },
    getPast31days() {
      let nowDate = new Date();
      let lastday = format(nowDate, "yyyy-MM-dd");
      let firstday = format(subDays(nowDate, 30), "yyyy-MM-dd");
      return [firstday, lastday];
    },
    getThisMonth() {
      let nowDate = new Date();
      let firstday = format(startOfMonth(nowDate), "yyyy-MM-dd");
      let lastday = format(endOfMonth(nowDate), "yyyy-MM-dd");
      return [firstday, lastday];
    },
    getLastMonthDates() {
      let nowDate = new Date();
      let lastMonth = subMonths(nowDate, 1);
      let firstday = format(startOfMonth(lastMonth), "yyyy-MM-dd");
      let lastday = format(endOfMonth(lastMonth), "yyyy-MM-dd");
      return [firstday, lastday];
    },
    getThisYear() {
      let nowDate = new Date();
      let firstday = format(startOfYear(nowDate), "yyyy-MM-dd");
      let lastday = format(endOfYear(nowDate), "yyyy-MM-dd");
      return [firstday, lastday];
    },
    getLastYearDates() {
      let nowDate = new Date();
      let lastMonth = subYears(nowDate, 1);
      let firstday = format(startOfYear(lastMonth), "yyyy-MM-dd");
      let lastday = format(endOfYear(lastMonth), "yyyy-MM-dd");
      return [firstday, lastday];
    },
    getTwoYears() {
      let nowDate = new Date();

      let twoYearsAgoDate = subYears(nowDate, 2);
      let firstday = format(startOfYear(twoYearsAgoDate), "yyyy-MM-dd");
      let lastday = format(endOfYear(nowDate), "yyyy-MM-dd");
      return [firstday, lastday];
    },

    setSollicitatieperiodeDate(id) {
      // Vandaag
      if (id == 0) {
        let dates = this.getVandaagsDate();
        this.localDate = dates;
        this.setDate(dates);
        return;
      }
      // Gisteren
      if (id == 1) {
        let dates = this.getGisterensDate();
        this.localDate = dates;
        this.setDate(dates);
        return;
      }
      // Deze Week
      if (id == 2) {
        let dates = this.getThisWeekDate();
        this.localDate = dates;
        this.setDate(dates);
        return;
      }
      // Vorige Week
      if (id == 3) {
        let dates = this.getLastWeekDates();
        this.localDate = dates;
        this.setDate(dates);
        return;
      }
      // Past two weeks
      if (id == 4) {
        let dates = this.getPastTwoWeeksDates();
        this.localDate = dates;
        this.setDate(dates);
        return;
      }
      // This Month
      if (id == 5) {
        let dates = this.getThisMonth();
        this.localDate = dates;
        this.setDate(dates);
        return;
      }
      // Last Month
      if (id == 6) {
        let dates = this.getLastMonthDates();
        this.localDate = dates;
        this.setDate(dates);
        return;
      }
      // This Year
      if (id == 7) {
        let dates = this.getThisYear();
        this.localDate = dates;
        this.setDate(dates);
        return;
      }
      // Last Year
      if (id == 8) {
        let dates = this.getLastYearDates();
        this.localDate = dates;
        this.setDate(dates);
        return;
      }
      // Two years
      if (id == 9) {
        let dates = this.getTwoYears();
        this.localDate = dates;
        this.setDate(dates);
        return;
      }
      // past 31 days
      if (id == 10) {
        let dates = this.getPast31days();
        this.localDate = dates;
        this.setDate(dates);
        return;
      }
    },
  },
};
</script>
<style scoped></style>