<template>
  <v-card elevation="0">
    <v-container>
      <v-text-field
        background-color="cardFields"
        outlined
        dense
        required
        :rules="requiredRules"
        label="Hotlist Name"
        v-model="name"
        class="mt-2"
      ></v-text-field>
      <v-autocomplete
        background-color="cardFields"
        dense
        outlined
        :items="customMembers"
        :item-text="fullName"
        item-value="id"
        return-object
        v-model="selectedMembers"
        hide-details
        hide-no-data
        multiple
        @input="search = ''"
        placeholder="Recipients"
        :search-input.sync="search"
        class="mb-2"
      >
        <template v-slot:selection="{ item, index }">
          <v-chip
            small
            label
            color="#E1F5FE"
            text-color="#0277BD"
            v-if="index < 4"
          >
            <span>{{ fullName(item) }}</span>
          </v-chip>
          <span v-if="index === 4" class="grey--text text-caption">
            (+{{ selectedMembers.length - 4 }} others)
          </span>
        </template>
      </v-autocomplete>
      <v-row class="ma-0">
        <v-spacer></v-spacer>
        <v-btn color="primary" class="animatedBtn" @click="create()"
          >Save</v-btn
        >
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  CREATE_HOTLIST,
  BULK_ADD_HOTLIST_MEMBERS,
  SEARCH_LEAD_USERS_BY_NAME,
  EMPTY_FOUND_LEAD_USERS,
  GET_ALL_HOTLISTS,
} from "../../../store/action.type";
export default {
  props: { selectedCandidates: Array },
  data: () => ({
    name: "",
    requiredRules: [(v) => !!v || "Field is required"],
    search: null,
    isLoading: false,
  }),
  computed: {
    ...mapGetters("lead_users", ["found_lead_users_getter"]),
    ...mapGetters("hotlists", ["hotlist_members_getter"]),
    selectedMembers: {
      get() {
        if (this.selectedCandidates) {
          return this.selectedCandidates;
        } else {
          return [];
        }
      },
      set(val) {
        if (val.length > 0) {
          val.map((item) => {
            if (!this.selectedMembers.includes(item)) {
              this.selectedMembers.push(item);
            }
          });
        }
      },
    },
    customMembers() {
      let arr = [];
      arr = [...this.found_lead_users_getter];
      this.selectedMembers.forEach((element) => {
        arr.push(element);
      });
      return arr;
    },
  },
  watch: {
    search: function (val) {
      if (val !== null && val !== "") {
        this.fetchLeadUsersByName(val);
      } else {
        this.emptyFoundLeadUsers();
      }
    },
  },
  methods: {
    ...mapActions("hotlists", {
      createHotlist: CREATE_HOTLIST,
      getAllHotlists: GET_ALL_HOTLISTS,
    }),
    ...mapActions("hotlist_members", {
      bulkAddHotlistMembers: BULK_ADD_HOTLIST_MEMBERS,
    }),
    ...mapActions("lead_users", {
      searchLeadUsersByName: SEARCH_LEAD_USERS_BY_NAME,
      emptyFoundLeadUsers: EMPTY_FOUND_LEAD_USERS,
    }),

    async create() {
      let hotlist = {
        name: this.name,
      };
      if (this.selectedMembers.length > 0) {
        await this.createHotlist({
          hotlist,
        }).then((res) => {
          let hotlist_members = [];
          this.selectedMembers.map((item) => {
            let hotlist_member = {
              hotlist_id: res.id,
              lead_user_id: item.id,
            };
            hotlist_members.push(hotlist_member);
          });
          this.bulkAddHotlistMembers({
            hotlist_members,
          });
        });
      } else {
        await this.createHotlist({
          hotlist,
        });
      }
      this.name = "";
      this.getAllHotlists();
      this.$emit("close-dialog");
      this.$emit("reload-table");
    },
    fullName(item) {
      return item.first_name + " " + item.last_name;
    },

    async fetchLeadUsersByName(search) {
      this.isLoading = true;
      await this.searchLeadUsersByName(search);
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.animatedBtn:hover {
  transform: scale(1.1);
}
</style>
