import config from "../../config";
import axios from "axios";
import gmapsInit from "../utils/gmaps";

export function incompleteAddress(address) {
  const { addition, ...rest } = address;
  const location = Object.values(rest);
  return location.some((e) => e === "");
}

export async function calculateLocation(address) {
  var useAddress = "";
  if (
    address.addition &&
    address.street &&
    address.houseno &&
    address.postalcode &&
    address.region
  ) {
    useAddress = `${address.street}+${address.houseno}${address.addition}+${address.postalcode}+${address.region}`;
  }

  if (
    address.street &&
    address.houseno &&
    address.postalcode &&
    address.region
  ) {
    useAddress = `${address.street}+${address.houseno}+${address.postalcode}+${address.region}`;
  }

  if (useAddress) {
    const { data } = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${useAddress}&key=${config.mapsKey}`
    );
    return {
      lat: data.results[0].geometry.location.lat,
      lng: data.results[0].geometry.location.lng,
    };
  }
  return { lat: 0, lng: 0 };
}
