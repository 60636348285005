import config from "../../../config";
import { authHeader } from "../../utils/auth-header";
import { handleResponse } from "../../utils/api_functions";
import { logger } from "../../utils/logger";

import { CREATE_CONTACT, GET_CONTACTS, UPDATE_CONTACT } from "../action.type";
import {
  SET_CONTACTS,
  SET_CONTACTS_FAILED,
  SET_CONTACT_REQUEST,
} from "../mutation.type";

export const contact = {
  namespaced: true,
  state: {},
  actions: {
    async [GET_CONTACTS]({ commit }, id) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        commit(SET_CONTACT_REQUEST);
        return await fetch(
          `${config.apiUrl}/contact-people/company/${id}`,
          request
        )
          .then(handleResponse)
          .then((res) => {
            commit(SET_CONTACTS, res.contacts);
            return res;
          });
      } catch (error) {
        commit(SET_CONTACTS_FAILED, error);
        logger.error(error);
      }
    },
    async [CREATE_CONTACT]({ commit }, contact) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(contact),
        };
        return await fetch(`${config.apiUrl}/contact-people`, request)
          .then(handleResponse)
          .then((contact) => {
            return contact;
          });
      } catch (error) {
        commit(SET_CONTACTS_FAILED, error);
        logger.error(error);
      }
    },
    async [UPDATE_CONTACT](_, { contact, id }) {
      try {
        const request = {
          method: "PUT",
          headers: authHeader(),
          body: JSON.stringify(contact),
        };
        return await fetch(`${config.apiUrl}/contact-people/${id}`, request)
          .then(handleResponse)
          .then((contact) => {
            return contact;
          });
      } catch (error) {
        logger.error(error);
      }
    },
  },
  mutations: {
    [SET_CONTACT_REQUEST](state) {
      state.status = { loading: true };
    },
    [SET_CONTACTS](state, contacts) {
      state.status = { loading: false };
      state.contacts = { contacts };
    },
    [SET_CONTACTS_FAILED](state, error) {
      state.status = { loading: false };
      state.all = { error };
    },
  },
};
