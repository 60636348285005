<template>
  <v-card elevation="1">
    <!-- Renders a chart using ApexCharts when `isLoaded` is true to avoid creating chart before the variables are ready-->
    <apexchart
      v-if="isLoaded"
      width="100%"
      height="310"
      type="line"
      :options="options"
      :series="series"
    ></apexchart>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import { GET_DASHBOARD_FLOW_LEADS } from "../../store/action.type";
import {
  extractWeeklyStats,
  getPreviousWeeks,
  getNewLeadsPerWeek,
  getContactedPerWeek,
  getCurrentWeekNum,
} from "../../helpers/statisticsDataGetter";
export default {
  data: () => ({
    isLoaded: false,
    weekNr: 0,
    newLeadsPerWeekCount: [],
    contactedPerWeekCount: [],
    previousWeeks: [],
    options: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false | '<img src="/static/icons/reset.png" width="20">',
            customIcons: [],
          },
        },
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        categories: [],
      },
    },
    colors: ["#008FFB", "#00E396", "#FEB019"],
    series: [],
  }),
  created() {
    this.init();
  },
  methods: {
    ...mapActions("flow", { getDashboardFlowLeads: GET_DASHBOARD_FLOW_LEADS }),
    async init() {
      const week = getCurrentWeekNum();
      const currentYear = new Date().getFullYear();
      const year = currentYear;
      // Gets flow leads for the current week and extracts weekly stats
      this.previousWeeks = getPreviousWeeks(week);
      await this.getDashboardFlowLeads({
        week,
        year,
      }).then((res) => {
        this.weeklyStats = extractWeeklyStats(res);
        // Calculates the new leads and contacted leads per week and assigns them to `series`
        this.newLeadsPerWeekCount = getNewLeadsPerWeek(this.weeklyStats);
        this.contactedPerWeekCount = getContactedPerWeek(this.weeklyStats);
        this.series = [
          {
            name: "New Leads",
            data: this.newLeadsPerWeekCount,
          },
          {
            name: "Contacted Leads",
            data: this.contactedPerWeekCount,
          },
        ];
        // Sets the categories of the x-axis to the previous weeks and assigns them to `options`
        this.options.xaxis.categories = this.previousWeeks;

        this.isLoaded = true;
      });
    },
  },
};
</script>

<style scoped></style>
