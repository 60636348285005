<template>
  <v-app :style="cssProps">
    <div v-if="!authLoading">
      <NavDrawer v-if="userHasBeenLoaded" />
    </div>
    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container class="mx-0 px-0" :fill-height="!isAuthenticated" fluid>
        <!-- Global Alerts -->
        <v-alert
          :value="alert.display"
          transition="slide-x-transition"
          border="left"
          dismissible
          :type="alert.type"
          max-width="30%"
          style="position: fixed; top: 15px; z-index: 20001"
          >{{ alert.message }}
        </v-alert>
        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavDrawer from "@/components/Nav/NavDrawer";
import { getInstance } from "./auth/authWrapper";
import { socketAuth } from "./utils/auth-header";
import { mapActions, mapGetters } from "vuex";
import Vue from "vue";
import { logger } from "./utils/logger";
import {
  ADD_NOTIFICATION,
  GET_NOTIFICATIONS,
  GET_SELF,
  GET_USERS,
  INCREMENT_NOTIF_COUNT,
  SOCKET_AUTHENTICATE,
  SYNC_LISTS,
  UPDATE_UNREAD_MESSAGES,
  GET_USERS_WITH_CONVERSATIONS,
} from "./store/action.type";
export default {
  name: "App",

  components: {
    NavDrawer,
  },
  sockets: {
    connect() {
      console.log("SOCKET CONNECTED");
      this.isConnected = true;
    },

    disconnect() {
      console.log("SOCKET DISCONNECTED");
      this.isConnected = false;
    },

    notification(data) {
      console.log("SOCKET NOTIFICATION", data);
      this.loadUnreadMessages();
      this.addNotification(data);
      this.incrementNotifCount();
    },
  },
  watch: {
    $route(to, from) {
      // clear alert on location change
      this.$store.dispatch("alerts/clear");
    },
  },
  created() {
    document.title = "CLLBR";
    this.init(this.loadTokenIntoStore);
  },
  computed: {
    ...mapGetters("notifications", ["getPanelState"]),
    authLoading: function () {
      return this.$auth.loading;
    },
    isAuthenticated: function () {
      return this.$auth.isAuthenticated;
    },
    userLoaded: function () {
      return this.$store.state.users.user;
    },
    alert() {
      return this.$store.state.alerts;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    cssProps() {
      var themeColors = {};
      Object.keys(this.$vuetify.theme.themes[this.theme]).forEach((color) => {
        themeColors[`--v-${color}`] =
          this.$vuetify.theme.themes[this.theme][color];
      });
      return themeColors;
    },
  },
  methods: {
    //This is used to get values for lists on launch
    ...mapActions("filters", { syncLists: SYNC_LISTS }),
    ...mapActions("notifications", {
      incrementNotifCount: INCREMENT_NOTIF_COUNT,
      addNotification: ADD_NOTIFICATION,
      socketAuthenticate: SOCKET_AUTHENTICATE,
      getNotifications: GET_NOTIFICATIONS,
    }),
    ...mapActions("whats_app", {
      getUsersWithConversations: GET_USERS_WITH_CONVERSATIONS,
      updateUnreadMessages: UPDATE_UNREAD_MESSAGES,
    }),
    ...mapActions("users", { getUsers: GET_USERS, getSelf: GET_SELF }),
    async self(oAuthID) {
      const self = await this.getSelf(oAuthID);
      if (self.oAuthID) {
        this.userHasBeenLoaded = true;
        console.log("SELF", self);
        const groups = self.Notification_Groups;
        console.log("GROUPS", groups);
        const socketId = this.$socket.id;
        console.log("SOCKET ID", socketId);
        // TODO CJECK THIS ONE
        this.socketAuthenticate({ groups, socketId });
        Vue.prototype.$socket.auth = socketAuth();
        console.log("Socket Auth =>", socketAuth());
        // TODO CJECK THIS ONE
        this.getNotifications();
        this.loggers();
      } else {
        this.$auth.logout({
          returnTo: window.location.origin,
        });
      }
    },
    init(fn) {
      // have to do this nonsense to make sure auth0Client is ready
      var instance = getInstance();
      instance.$watch("loading", (loading) => {
        if (loading === false) {
          if (this.$auth.isAuthenticated) {
            fn(instance);
          }
        }
      });
    },
    async loadTokenIntoStore(instance) {
      await instance.getTokenSilently().then((authToken) => {
        localStorage.setItem("user", this.$auth.user.sub);
        localStorage.setItem("token", JSON.stringify(authToken));
        this.self(this.$auth.user.sub);
        this.initialize();
      });
    },
    async initialize() {
      await this.syncLists(false);
    },
    loggers() {
      //var oldLog = console.log;
      //var oldInfo = console.info;
      var oldError = console.error;
      //var oldWarn = console.warn;
      // console.log = function (message) {
      //   logger.log(message)
      //   oldLog.apply(console, arguments);
      // };
      // console.info = function (message) {
      //   logger.info(message)
      //   oldInfo.apply(console, arguments);
      // };
      console.error = function (message) {
        logger.error(message);
        oldError.apply(console, arguments);
      };
      // console.warn = function (message) {
      //   logger.warn(message)
      //   oldWarn.apply(console, arguments);
      // };
    },
    async loadUnreadMessages() {
      await this.getUsersWithConversations().then((res) => {
        this.users = res.lead_users;

        // filter users with unread messages
        let new_messages_users = this.users.filter((user) => {
          return user.latest_messages.length > 0;
        });
        let twoWeeksAgo = new Date();
        twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
        let new_messages_last_two_weeks = new_messages_users.filter((user) => {
          let latest_message = new Date(user.latest_message);
          return latest_message > twoWeeksAgo;
        });
        this.updateUnreadMessages(new_messages_last_two_weeks.length);
      });
    },
  },
  mounted() {
    this.$vuetify.theme.themes.light = {
      primary: "#009EE3",
      secondary: "#383838",
      accent: "#A7E8E8",
      error: "#FF5252",
      info: "#2196F3",
      success: "#4CAF50",
      warning: "#FFC107",
      background: "#F5F5F5",
      text: "#000000",
      navBackground: "#457B9D", //f8f9fa
      tableRow: "#edf6f9",
      tableHover: "#EEEEEE",
      card: "#F5F5F5",
      cardFields: "#FFFFFF",
    };
    this.$vuetify.theme.themes.dark = {
      primary: "#009EE3",
      secondary: "#383838",
      accent: "#A7E8E8",
      error: "#FF5252",
      info: "#2196F3",
      success: "#4CAF50",
      warning: "#FFC107",
      background: "#121212",
      text: "#ffffff",
      navBackground: "#1E1E1E",
      tableRow: "#464646",
      tableHover: "#616161",
      card: "#1E1E1E",
      cardFields: "#1E1E1E",
    };

    var isTrueSet = localStorage.getItem("dark") == "true";
    isTrueSet
      ? (this.$vuetify.theme.dark = true)
      : (this.$vuetify.theme.dark = false);
  },
  data() {
    return {
      isConnected: false,
      drawer: true,
      userHasBeenLoaded: false,
      user: { first_name: null },
      items: [
        { title: "Home", icon: "mdi-home-city" },
        { title: "Vacatures", icon: "mdi-account" },
        { title: "Leads", icon: "mdi-account" },
        { title: "Bedrijven", icon: "mdi-account" },
        { title: "Admin", icon: "mdi-account-group-outline" },
      ],
      mini: true,
      oldLog: null,
    };
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Fugaz+One&display=swap");
.fugaz {
  font-family: "Fugaz One";
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
  -webkit-border-radius: 6px;
  border-radius: 6px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 6px;
  border-radius: 6px;
  background: rgba(33, 150, 243, 1);
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(33, 150, 243, 1);
}
.v-data-table__wrapper tbody tr:nth-of-type(even) {
  background-color: var(--v-tableRow);
}

.v-navigation-drawer .v-navigation-drawer__content {
  display: flex;
  flex-direction: column;
}
</style>
