<template>
  <v-container fluid v-if="!loading">
    <div><BarChart :data="chartData" /></div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BarChart from "../../components/analytics/BarChart.vue";
import { GET_API_USAGE } from "../../store/action.type";
export default {
  components: { BarChart },
  data: () => ({
    loading: true,
    colorArray: [
      "#264653",
      "#2a9d8f",
      "#e9c46a",
      "#f4a261",
      "#e76f51",
      "#52b69a",
      "#00afb9",
      "#ffbe0b",
      "#99FF99",
      "#ee6c4d",
      "#F94144",
      "#F3722C",
      "#F8961E",
      "#F9844A",
      "#F9C74F",
      "#277DA1",
      "#577590",
    ],
  }),
  computed: {
    ...mapGetters("external_api_keys", ["getApiUsageData"]),
    chartData() {
      let dataSet = [];
      let colorCounter = 4;
      for (let user = 0; user < this.usageData.length; user++) {
        let dataArr = [];
        let jan = [];
        let feb = [];
        let mar = [];
        let apr = [];
        let may = [];
        let jun = [];
        let jul = [];
        let aug = [];
        let sep = [];
        let oct = [];
        let nov = [];
        let dec = [];
        // initialize all arrays to empty array
        this.usageData[user].usage.forEach((item) => {
          if (/2022-01-(.*)/.test(item[0])) {
            for (let x = 0; x < item[1].length; x++) {
              jan.push(item[1].id);
            }
          } else if (/2022-02-(.*)/.test(item[0])) {
            for (let x = 0; x < item[1].length; x++) {
              feb.push(item[1].id);
            }
          } else if (/2022-03-(.*)/.test(item[0])) {
            for (let x = 0; x < item[1].length; x++) {
              mar.push(item[1].id);
            }
          } else if (/2022-04-(.*)/.test(item[0])) {
            for (let x = 0; x < item[1].length; x++) {
              apr.push(item[1].id);
            }
          } else if (/2022-05-(.*)/.test(item[0])) {
            for (let x = 0; x < item[1].length; x++) {
              may.push(item[1].id);
            }
          } else if (/2022-06-(.*)/.test(item[0])) {
            for (let x = 0; x < item[1].length; x++) {
              jun.push(item[1].id);
            }
          } else if (/2022-07-(.*)/.test(item[0])) {
            for (let x = 0; x < item[1].length; x++) {
              jul.push(item[1].id);
            }
          } else if (/2022-08-(.*)/.test(item[0])) {
            for (let x = 0; x < item[1].length; x++) {
              aug.push(item[1].id);
            }
          } else if (/2022-09-(.*)/.test(item[0])) {
            for (let x = 0; x < item[1].length; x++) {
              sep.push(item[1].id);
            }
          } else if (/2022-10-(.*)/.test(item[0])) {
            for (let x = 0; x < item[1].length; x++) {
              oct.push(item[1].id);
            }
          } else if (/2022-11-(.*)/.test(item[0])) {
            for (let x = 0; x < item[1].length; x++) {
              nov.push(item[1].id);
            }
          } else if (/2022-12-(.*)/.test(item[0])) {
            for (let x = 0; x < item[1].length; x++) {
              dec.push(item[1].id);
            }
          }
        });
        //*Push the lenght of each month to the dataset array
        dataArr.push(jan.length);
        dataArr.push(feb.length);
        dataArr.push(mar.length);
        dataArr.push(apr.length);
        dataArr.push(may.length);
        dataArr.push(jun.length);
        dataArr.push(jul.length);
        dataArr.push(aug.length);
        dataArr.push(sep.length);
        dataArr.push(oct.length);
        dataArr.push(nov.length);
        dataArr.push(dec.length);

        var randomColor = this.colorArray[colorCounter];
        colorCounter++;
        let obj = {
          label: this.usageData[user].name,
          data: dataArr,
          backgroundColor: randomColor,
        };
        dataSet.push(obj);
      }

      let chartData = {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        text: "API KEY USAGE",
        datasets: dataSet,
      };

      return chartData;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions("external_api_keys", {
      getApiUsage: GET_API_USAGE,
    }),

    async initialize() {
      await this.getApiUsage().then(() => {
        this.usageData = this.groupByDays(this.getApiUsageData);
      });
      this.loading = false;
    },
    groupByDays(data) {
      let a = [];
      for (let i = 0; i < data.length; i++) {
        let groupByDay = data[i].api_key.groupByToMap((day) => {
          return day.createdAt;
        });
        let arr = Array.from(groupByDay);
        let tempObj = {
          id: data[i].id,
          name: data[i].name,
          usage: arr.reverse(),
          total: arr.length,
        };
        a.push(tempObj);
      }
      return a;
    },
  },
};
</script>

<style></style>
