<template>
  <v-container fill-height class="ma-0">
    <v-dialog v-model="dialogParsedCv" width="700" min-height="700">
      <v-tabs>
        <v-tab> {{ $t("documents_section.parsed_cv") }} </v-tab>
        <v-tab> {{ $t("documents_section.text") }} </v-tab>
        <v-tab-item>
          <v-card style="width: 700px; min-height: 700px">
            <ParsedCvViewer
              v-on:close-dialog="dialogParsedCv = false"
              :parsedCv="parsedCv"
            ></ParsedCvViewer>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card style="width: 700px; min-height: 700px">
            <v-textarea
              class="pa-4"
              rows="25"
              filled
              name="input-7-1"
              :value="textCv"
              readonly
            ></v-textarea>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-dialog>
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="text-h6">
          {{ $t("documents_section.parse_resume") }}
        </v-card-title>
        <v-card-text> {{ $t("documents_section.parsing_info") }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">
            {{ $t("documents_section.no") }}
          </v-btn>
          <v-btn color="green darken-1" text @click="parseDocument()">
            {{ $t("documents_section.yes") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="cvAutofillDialog" max-width="400">
      <v-card>
        <v-card-title class="text-h6">
          {{ $t("documents_section.autofill_data_resume") }}
        </v-card-title>
        <v-card-text>
          {{ $t("documents_section.resume_autofill") }}</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="cvAutofillDialog = false">
            {{ $t("documents_section.no") }}
          </v-btn>
          <v-btn color="green darken-1" text @click="fillInUserDataFromCv()">
            {{ $t("documents_section.yes") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogFileUploader" max-width="600px">
      <v-card>
        <FileUploader
          v-on:close-dialog="dialogFileUploader = false"
          :parentID="parentID"
          :type="type"
        ></FileUploader>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteCv" max-width="600px">
      <v-card>
        <v-container class="pa-4">
          <v-card-title>
            <span class="text-h5">{{
              $t("documents_section.delete_document")
            }}</span>
          </v-card-title>
          <v-card-text>
            <p>{{ $t("documents_section.permanently_deleted") }}</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="ml-2 mr-2" depressed color="error" @click="deleteDoc">
              {{ $t("documents_section.delete") }}
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col class="pa-0">
        <v-card min-height="380">
          <v-row :style="cardHeaders" class="ma-0">
            <v-row class="pa-5">
              <v-col md="7">
                <v-row>
                  <v-icon medium>mdi-file-document</v-icon>
                  <h3>
                    <strong>{{ $t("documents_section.documents") }}</strong>
                  </h3>
                </v-row>
              </v-col>
              <v-col md="3" class="pa-0">
                <v-btn
                  depressed
                  small
                  color="primary"
                  @click="previewCv"
                  :disabled="parsedCv === null"
                >
                  {{ $t("documents_section.preview_cv") }}
                </v-btn>
              </v-col>
              <v-col md="2" align="end" class="pa-0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      medium
                      @click="uploadDocument()"
                      >mdi-plus-box</v-icon
                    >
                  </template>
                  <span>{{ $t("documents_section.upload_document") }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-row>
          <v-card class="pa-2 overflow-y-auto" max-height="335" elevation="0">
            <v-container class="pl-6 pt-6">
              <v-row
                class="pa-0"
                v-for="document in documents"
                :key="document.id"
              >
                <v-container class="mb-2 pb-0">
                  <v-row class="mb-5">
                    <v-col md="2" class="pa-0">
                      <p style="margin-bottom: 0">
                        {{ shortDate(document.createdAt) }}
                      </p>
                    </v-col>
                    <v-col md="5" class="pa-0 pr-2">
                      <h4>{{ document.title }}</h4>
                    </v-col>
                    <v-col md="3" class="pa-0">
                      <h4>{{ shortFileName(document.file) }}</h4>
                    </v-col>
                    <v-col md="2" class="pr-6 pb-0">
                      <v-row justify="end">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              medium
                              color="green"
                              @click="downloadDocument(document.id)"
                              >mdi-arrow-down-bold-box</v-icon
                            >
                          </template>
                          <span>{{ $t("documents_section.download") }}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              medium
                              color="red"
                              @click="selectCV(document)"
                              >mdi-trash-can</v-icon
                            >
                          </template>
                          <span>{{ $t("documents_section.delete") }}</span>
                        </v-tooltip>
                      </v-row>
                      <v-row justify="end">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              medium
                              :disabled="parsedCv === null"
                              color="blue"
                              @click="cvAutofillDialog = true"
                              >mdi-cloud-upload</v-icon
                            >
                          </template>
                          <span>{{
                            $t("documents_section.fill_in_data")
                          }}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              medium
                              :color="parsedCv !== null ? 'red' : 'blue'"
                              @click="openDialog(document)"
                              >mdi-file-replace</v-icon
                            >
                          </template>
                          <span>{{ $t("documents_section.parse") }}</span>
                        </v-tooltip>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-divider v-if="lastItem(document.id)"></v-divider>
                </v-container>
              </v-row>
            </v-container>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import FileUploader from "../../dialogs/FileUploader.vue";
import ParsedCvViewer from "../../dialogs/ParsedCvViewer.vue";
import {
  DOWNLOAD_DOCUMENT,
  DELETE_DOCUMENT,
  GET_LEAD_USER_BY_ID,
  PARSE_RESUME,
  UPDATE_LEAD_USER_FROM_CV,
} from "../../../store/action.type";
export default {
  components: {
    FileUploader,
    ParsedCvViewer,
  },
  props: {
    parentID: Number,
    type: Number, //1 = Lead User Upload | 2 = Company Upload
    documents: {},
    parsedCv: {},
    textCv: String,
  },

  data() {
    return {
      dialogFileUploader: false,
      dialogParsedCv: false,
      dialogDeleteCv: false,
      dialog: false,
      selectedDocument: null,
      leadUser: null,
      cvAutofillDialog: false,
    };
  },
  computed: {
    cardHeaders() {
      if (this.$vuetify.theme.dark) {
        return "background: #2a2a2a";
      } else {
        return "background: #b3b3b3";
      }
    },
  },
  created() {},
  methods: {
    ...mapActions("file_upload", {
      downloadDocument: DOWNLOAD_DOCUMENT,
      deleteDocument: DELETE_DOCUMENT,
    }),
    ...mapActions("lead_users", {
      getLeadUserById: GET_LEAD_USER_BY_ID,
    }),
    ...mapActions("utility", {
      parseResume: PARSE_RESUME,
      updateLeadUserFromCv: UPDATE_LEAD_USER_FROM_CV,
    }),
    shortDate(document) {
      return document.substring(0, 10);
    },
    shortFileName(document) {
      return document.substring(14);
    },
    lastItem(document) {
      if (this.documents[this.documents.length - 1].id == document) {
        return false;
      }
      return true;
    },
    uploadDocument() {
      this.dialogFileUploader = true;
    },
    previewCv() {
      this.dialogParsedCv = true;
    },
    async parseDocument() {
      this.parseResume({
        docId: this.selectedDocument.id,
        docUserId: this.selectedDocument.lead_user_id,
      }).then(() => {
        this.getLeadUserById(this.$route.params.id);
      });
      this.dialog = false;
    },
    openDialog(document) {
      this.selectedDocument = document;
      this.dialog = true;
    },

    selectCV(document) {
      this.selectedDocument = document;
      this.dialogDeleteCv = true;
    },

    async deleteDoc() {
      const { dispatch } = this.$store;
      await this.deleteDocument(this.selectedDocument.id).then(() => {
        this.dialogDeleteCv = false;
        this.getLeadUserById(this.$route.params.id);
        dispatch(
          "alerts/success",
          this.$t("documents_section.success_deletion")
        );
      });
    },
    async fillInUserDataFromCv() {
      await this.updateLeadUserFromCv(this.parentID);
      await this.getLeadUserById(this.parentID);
      this.cvAutofillDialog = false;
    },
  },
};
</script>
