import config from "../../../config";
import { authHeader } from "../../utils/auth-header";
import { handleResponse } from "../../utils/api_functions";
import { logger } from "../../utils/logger";
import {
  ADD_HOTLIST_MEMBER,
  BULK_ADD_HOTLIST_MEMBERS,
  REMOVE_HOTLIST_MEMBER,
} from "../action.type";
import { SET_HOTLIST_MEMBERS, SET_ACTIVE_HOTLIST } from "../mutation.type";
export const hotlist_members = {
  namespaced: true,
  state: { hotlist_members: [] },
  getters: {
    hotlist_members_getter: (state) => state.hotlist_members,
  },
  actions: {
    async [ADD_HOTLIST_MEMBER]({ commit }, { hotlist_member }) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(hotlist_member),
        };
        return await fetch(`${config.apiUrl}/hotlist_members`, request)
          .then(handleResponse)
          .then((res) => {
            commit(SET_ACTIVE_HOTLIST, res.hotlist);
          });
      } catch (error) {
        logger.error(error);
      }
    },
    //bulk insert hotlist members
    async [BULK_ADD_HOTLIST_MEMBERS]({ commit }, { hotlist_members }) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(hotlist_members),
        };
        return await fetch(`${config.apiUrl}/hotlist_members/bulk`, request)
          .then(handleResponse)
          .then((res) => {
            commit(SET_ACTIVE_HOTLIST, res.hotlist);
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [REMOVE_HOTLIST_MEMBER]({ commit }, { hotlistId, leadUserId }) {
      try {
        const request = {
          method: "DELETE",
          headers: authHeader(),
        };
        return await fetch(
          `${config.apiUrl}/hotlist_members/${hotlistId}/${leadUserId}`,
          request
        ).then(handleResponse);
      } catch (error) {
        logger.error(error);
      }
    },
  },
  mutations: {
    [SET_HOTLIST_MEMBERS](state, members) {
      state.hotlist_members = members;
    },
    [SET_ACTIVE_HOTLIST](state, hotlists) {
      state.activeHotlist = hotlists;
    },
  },
};
