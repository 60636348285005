<template>
  <v-card :color="editMode ? 'card' : ''" class="pa-3" fluid>
    <v-dialog v-model="confirmArchiveDialog" max-width="290">
      <v-card>
        <v-card-title class="text-h6">{{
          $t("vacancy_archive_dialog.title")
        }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="confirmArchiveDialog = false">{{
            $t("vacancy_archive_dialog.cancel")
          }}</v-btn>
          <v-btn color="red darken-1" text @click="archive()">{{
            $t("vacancy_archive_dialog.confirm")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="createTemplateDialog" width="650px">
      <v-card color="card">
        <CreateTemplate
          type="create"
          v-on:close-dialog="refreshTemplates()"
          :activeTemplate="template"
        >
        </CreateTemplate>
      </v-card>
    </v-dialog>
    <v-row align="center" class="pa-4" :class="editMode">
      <v-btn icon color="primary" @click="$router.go(-1)">
        <v-icon x-large dark>mdi-chevron-left-box</v-icon>
      </v-btn>
      <v-text-field
        class="pa-3"
        v-if="editMode"
        v-model="activeVacancy.name"
        :label="$t('create_vacancy_flow.name')"
        background-color="cardFields"
        outlined
        dense
      >
      </v-text-field>
      <v-card-title v-else class="ml-2" style="font-size: 1.5rem">
        {{ activeVacancy.name ? activeVacancy.name : "No name" }}
      </v-card-title>

      <v-select
        class="pa-3 select"
        v-if="editMode"
        v-model="activeVacancy.Vacancy_status"
        :items="vacancy_statuses_getter"
        item-text="name"
        item-value="id"
        return-object
        background-color="cardFields"
        outlined
        dense
      >
      </v-select>
      <v-chip
        label
        dark
        v-else
        class="statusChip"
        :color="activeVacancy.Vacancy_status.color"
      >
        {{ activeVacancy.Vacancy_status.name }}
      </v-chip>
      <v-spacer></v-spacer>
      <div class="ma-3">
        <v-btn @click="toggleEdit()" icon>
          <v-icon v-if="!editMode" size="25" class="pa-2" color="primary">
            mdi-pencil
          </v-icon>
          <v-icon v-else size="25" class="pa-2" color="primary">
            mdi-eye
          </v-icon>
        </v-btn>

        <v-btn @click="confirmArchiveDialog = true" v-if="!editMode" icon>
          <v-icon size="25" class="pa-2" color="red">mdi-delete</v-icon>
        </v-btn>
      </div>
    </v-row>

    <v-row class="pl-4 pt-6">
      <v-col class="ml-6 mb-6" v-if="!editMode">
        <v-row class="rowText">
          <div class="titleText">
            {{ $t("create_vacancy_flow.city") + ":&nbsp;" }}
          </div>
          <div>
            {{ activeVacancy.city ? activeVacancy.city : "No data" }}
          </div>
        </v-row>
        <v-row class="rowText">
          <div class="titleText">
            {{ $t("create_vacancy_flow.work_hours") + ":&nbsp;" }}
          </div>
          <div>
            {{
              activeVacancy.minimumHours == activeVacancy.maximumHours ||
              activeVacancy.maximumHours == 0
                ? activeVacancy.minimumHours
                : activeVacancy.minimumHours +
                  " - " +
                  activeVacancy.maximumHours
            }}
          </div>
        </v-row>
        <v-row class="rowText">
          <div class="titleText">
            {{ $t("create_vacancy_flow.start_date") + ":&nbsp;" }}
          </div>
          <div>
            {{ activeVacancy.startDate ? activeVacancy.startDate : "No data" }}
          </div>
        </v-row>
        <v-row class="rowText">
          <div class="titleText">
            {{ $t("create_vacancy_flow.company") + ":&nbsp;" }}
          </div>
          <div>
            {{ activeVacancy.Company ? activeVacancy.Company.name : "No data" }}
          </div>
        </v-row>
        <v-row class="rowText">
          <div class="titleText">
            {{ $t("create_vacancy_flow.recruiter") + ":&nbsp;" }}
          </div>
          <div>
            {{ getRecruiter }}
          </div>
        </v-row>
        <v-row class="rowText">
          <div class="titleText">
            {{ $t("create_vacancy_flow.hiring_type") + ":&nbsp;" }}
          </div>
          <div>
            {{
              activeVacancy.hiringType ? activeVacancy.hiringType : "No data"
            }}
          </div>
        </v-row>
        <v-row class="rowText">
          <div class="titleText">
            {{ $t("create_vacancy_flow.category") + ":&nbsp;" }}
          </div>
          <div>
            {{
              activeVacancy.category !== null
                ? activeVacancy.Category.name
                : "No data"
            }}
          </div>
        </v-row>
        <v-row class="rowText">
          <div class="titleText">
            {{ $t("create_vacancy_flow.language") + ":&nbsp;" }}
          </div>
          <div v-if="activeVacancy.language.length > 0">
            <v-list class="pt-0">
              <v-list-item
                v-for="(item, i) in activeVacancy.language"
                :key="i"
                class="pt-0"
                style="min-height: 0px"
              >
                {{ item }}
              </v-list-item>
            </v-list>
          </div>
          <div v-else>{{ "No data" }}</div>
        </v-row>
        <v-row class="rowText">
          <div class="titleText">
            {{ $t("create_vacancy_flow.requiredExperience") + ":&nbsp;" }}
          </div>
          <div>
            {{
              activeVacancy.requiredExperience !== null
                ? activeVacancy.requiredExperience === true
                  ? "Yes"
                  : "No"
                : "No Data"
            }}
          </div>
        </v-row>
        <v-row class="rowText">
          <div class="titleText">
            {{ $t("create_vacancy_flow.hourlyRate") + ":&nbsp;" }}
          </div>
          <div>
            {{
              activeVacancy.hourlyRate !== null
                ? activeVacancy.hourlyRate
                : "No data"
            }}
          </div>
        </v-row>
      </v-col>
      <v-col md="4" v-else>
        <v-autocomplete
          background-color="cardFields"
          outlined
          dense
          :label="$t('create_vacancy_flow.city')"
          required
          :items="cities"
          item-text="city"
          item-value="city"
          v-model="activeVacancy.city"
          clearable
        ></v-autocomplete>

        <v-text-field
          :label="$t('create_vacancy_flow.min_work_hours')"
          v-model="activeVacancy.minimumHours"
          background-color="cardFields"
          outlined
          dense
          type="number"
          required
          min="0"
        >
        </v-text-field>
        <v-text-field
          :label="$t('create_vacancy_flow.max_work_hours')"
          v-model="activeVacancy.maximumHours"
          background-color="cardFields"
          outlined
          dense
          type="number"
          required
          min="0"
        >
        </v-text-field>
        <v-menu
          v-model="datepickerMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="activeVacancy.startDate"
              label="Start Date"
              readonly
              background-color="cardFields"
              outlined
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="activeVacancy.startDate"
            @input="datepickerMenu = false"
          ></v-date-picker>
        </v-menu>
        <v-select
          :label="$t('create_vacancy_flow.company')"
          v-model="activeVacancy.Company"
          :items="companies_getter"
          item-text="name"
          item-value="id"
          return-object
          background-color="cardFields"
          outlined
          dense
          clearable
        >
        </v-select>
        <v-select
          :label="$t('create_vacancy_flow.recruiter')"
          v-model="activeVacancy.Recruiter"
          :items="employees_getter"
          item-text="name"
          item-value="id"
          return-object
          background-color="cardFields"
          outlined
          dense
          clearable
        ></v-select>

        <v-select
          :label="$t('create_vacancy_flow.hiring_type')"
          v-model="activeVacancy.hiringType"
          :items="availableTypes"
          item-text="name"
          item-value="id"
          background-color="cardFields"
          outlined
          dense
          clearable
        ></v-select>
        <v-select
          v-if="editMode"
          :label="$t('create_vacancy_flow.category')"
          v-model="activeVacancy.category"
          :items="categories_getter"
          item-text="name"
          item-value="id"
          background-color="cardFields"
          outlined
          dense
          clearable
        ></v-select>

        <div>
          <v-row>
            <v-autocomplete
              background-color="cardFields"
              outlined
              dense
              class="pa-3"
              v-model="activeVacancy.language"
              :items="availableLanguages"
              :label="$t('create_vacancy_flow.language')"
              multiple
            />
          </v-row>

          <v-select
            background-color="cardFields"
            outlined
            dense
            v-model="activeVacancy.requiredExperience"
            :items="requiredExperienceOptions"
            :label="$t('create_vacancy_flow.requiredExperience')"
          />
          <v-text-field
            background-color="cardFields"
            outlined
            dense
            v-model="activeVacancy.hourlyRate"
            :label="$t('create_vacancy_flow.hourlyRate')"
          />
        </div>
      </v-col>
      <v-col align="flex-start" class="mb-2 pr-4 pb-3">
        <v-row>
          <v-col>
            <h3>
              {{ $t("create_vacancy_flow.job_description") }}
            </h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6">
            <v-select
              v-if="editMode"
              class="mr-3"
              label="Choose a Template"
              :items="templates_getter"
              @change="selectTemplate"
              v-model="selectedTemplate"
              item-text="name"
              item-value="id"
              return-object
              background-color="cardFields"
              outlined
              dense
            >
            </v-select>
          </v-col>
          <v-col v-if="editMode" md="2" class="pa-0 my-4" justify="center">
            <v-btn
              class="formBtn mr-3"
              color="primary"
              icon
              small
              @click="resetSelection()"
            >
              <v-icon @click="resetSelection()"> mdi-selection-remove </v-icon>
            </v-btn>
            <v-btn
              class="formBtn"
              color="primary"
              icon
              small
              @click="createTemplate()"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-card
            v-if="!editMode"
            width="750"
            height="250"
            class="overflow-y-auto ma-4"
          >
            <v-card-text>{{ activeVacancy.jobDescription }}</v-card-text>
          </v-card>
          <v-textarea
            v-else
            v-model="activeVacancy.jobDescription"
            class="overflow-y-auto pl-3 pr-2"
            background-color="cardFields"
            outlined
            dense
            rows="10"
            >{{ activeVacancy.jobDescription }}</v-textarea
          >
        </v-row>
        <v-row v-if="editMode" class="pa-3 pb-8">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-fade-transition leave-absolute>
                <v-expansion-panel-header>
                  {{ $t("create_vacancy_flow.hoursPerDay") }}
                </v-expansion-panel-header>
              </v-fade-transition>
              <v-expansion-panel-content>
                <v-row
                  v-for="(value, key) in activeVacancy.hoursPerDay"
                  :key="key"
                >
                  <v-col> {{ key }}</v-col>
                  <v-text-field
                    background-color="cardFields"
                    outlined
                    dense
                    v-model="activeVacancy.hoursPerDay[key]"
                  ></v-text-field>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
        <v-row v-else class="pa-3 pb-8">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-fade-transition leave-absolute>
                <v-expansion-panel-header>
                  {{ $t("create_vacancy_flow.hoursPerDay") }}
                </v-expansion-panel-header>
              </v-fade-transition>
              <v-expansion-panel-content>
                <v-row
                  v-for="(value, key) in activeVacancy.hoursPerDay"
                  :key="key"
                >
                  <v-col> {{ key }}</v-col>
                  <v-col>{{ value }}</v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels></v-row
        >
      </v-col>
    </v-row>

    <v-form v-if="editMode">
      <v-container class="my-0">
        <v-row class="text-end">
          <v-col>
            <v-btn
              class="mr-4 formBtn"
              color="primary"
              @click="updateVacancy(activeVacancy, activeVacancy.id)"
            >
              {{ $t("create_vacancy_flow.submit") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  CREATE_VACANCY_FLOW,
  GET_VACANCY_FLOW,
  GET_VACANCY_NO_FLOW,
  GET_ALL_TEMPLATES,
  UPDATE_VACANCY_FLOW,
  GET_VACANCY_STATUSES,
} from "../../../store/action.type";
import CreateTemplate from "../dialogs/CreateTemplate";
import { cities } from "../../../utils/cities_nl";
export default {
  props: { activeVacancy: Object },
  data: () => ({
    hoursPerDay: {
      Monday: "",
      Tuesday: "",
      Wednesday: "",
      Thursday: "",
      Friday: "",
      Saturday: "",
      Sunday: "",
    },
    createTemplateDialog: false,
    selectedTemplate: null,
    editMode: false,
    template: null,
    availableTypes: ["Direct Hiring", "Outsourcing"],
    availableLanguages: ["English", "Dutch"],
    datepickerMenu: false,
    requiredExperienceOptions: [
      {
        text: "Yes",
        value: true,
      },
      {
        text: "No",
        value: false,
      },
      {
        text: "N/A",
        value: null,
      },
    ],
    confirmArchiveDialog: false,
  }),
  computed: {
    ...mapGetters("vacancy_flow", ["categories_getter"]),
    ...mapGetters("filters", ["employees_getter", "companies_getter"]),
    ...mapGetters("statuses", ["vacancy_statuses_getter"]),
    ...mapGetters("vacancy_template", ["templates_getter"]),
    cities() {
      return cities;
    },
    getRecruiter() {
      if (this.activeVacancy.Recruiter) {
        for (let i = 0; i < this.employees_getter.length; i++) {
          if (this.employees_getter[i].id == this.activeVacancy.Recruiter.id) {
            return this.employees_getter[i].name;
          }
        }
        return "No Data";
      } else {
        return "No Data";
      }
    },
  },

  created() {
    this.init();
  },
  methods: {
    ...mapActions("vacancy_flow", {
      createVacancyFlow: CREATE_VACANCY_FLOW,
      getVacancyFlow: GET_VACANCY_FLOW,
      getVacancyNoFlow: GET_VACANCY_NO_FLOW,
      updateVacancyFlow: UPDATE_VACANCY_FLOW,
    }),
    ...mapActions("vacancy_template", {
      getAllTemplates: GET_ALL_TEMPLATES,
    }),
    ...mapActions("statuses", {
      getVacancyStatuses: GET_VACANCY_STATUSES,
    }),
    async init() {
      await this.getAllTemplates();
      await this.getVacancyStatuses();
      await this.getVacancyFlow();
      await this.getHoursPerDay();
    },
    async updateVacancy(vacancyData, id) {
      const { dispatch } = this.$store;
      let vacancy = {
        name: vacancyData.name,
        status: vacancyData.Vacancy_status.id,
        company: vacancyData.Company ? vacancyData.Company.id : null,
        city: vacancyData.city,
        recruiter: vacancyData.Recruiter ? vacancyData.Recruiter.id : null,
        minimumHours: vacancyData.minimumHours,
        maximumHours: vacancyData.maximumHours,
        category: vacancyData.category,
        hiringType: vacancyData.hiringType,
        jobDescription: vacancyData.jobDescription,
        startDate: vacancyData.startDate,
        hoursPerDay: {},
        language: [],
        requiredExperience: vacancyData.requiredExperience,
        hourlyRate: vacancyData.hourlyRate,
      };
      if (vacancyData.hoursPerDay !== null) {
        vacancy.hoursPerDay = JSON.stringify(vacancyData.hoursPerDay);
      } else {
        vacancy.hoursPerDay = JSON.stringify(this.hoursPerDay);
      }
      vacancy.language = JSON.stringify(vacancyData.language);

      await this.updateVacancyFlow({ vacancy, id }).then(() => {
        //* Refreshing the list of vacancy flows
        this.getVacancyFlow();
        this.getVacancyNoFlow();
        this.editMode = false;
        dispatch("alerts/success", "Vacancy updated");
      });
    },
    async getHoursPerDay() {
      if (
        this.activeVacancy.hoursPerDay === null ||
        Object.keys(this.activeVacancy.hoursPerDay).length === 0
      ) {
        this.activeVacancy.hoursPerDay = {
          Monday: "",
          Tuesday: "",
          Wednesday: "",
          Thursday: "",
          Friday: "",
          Saturday: "",
          Sunday: "",
        };
      } else {
        this.activeVacancy.hoursPerDay = JSON.parse(
          this.activeVacancy.hoursPerDay
        );
      }
    },
    selectTemplate(item) {
      this.activeVacancy.jobDescription = item.description;
    },
    resetSelection() {
      this.activeVacancy.jobDescription = null;
      this.selectedTemplate = null;
    },
    createTemplate() {
      this.template = {
        name: "",
        description: "",
      };
      this.createTemplateDialog = true;
    },
    refreshTemplates() {
      this.createTemplateDialog = false;
      this.getAllTemplates();
    },
    toggleEdit() {
      this.editMode = !this.editMode;
    },
    async archive() {
      let id = this.activeVacancy.id;
      let vacancy = {
        archived: true,
      };
      await this.updateVacancyFlow({ vacancy, id });
      await this.getVacancyFlow();
      await this.getVacancyNoFlow();
      this.$router.push("/vacatures");
    },
  },
  components: { CreateTemplate },
};
</script>

<style>
.formBtn:hover {
  transform: scale(1.1);
}
.v-card__text {
  padding: 10px;
}
.titleText {
  font-weight: bold;
}
.rowText {
  font-size: 1.1rem;
  padding-top: 8px;
  margin-bottom: 16px;
}
.select {
  max-width: 250px;
}
.statusChip {
  font-weight: 500;
}
</style>
