<template>
  <v-container>
    <v-row class="px-2 align-center justify-center">
      <h5 class="mb-0">{{ previousWeeks[weekIndex] }}</h5>
    </v-row>
    <v-row class="px-2 align-center">
      <v-btn
        :disabled="weekIndex == 0"
        height="60%"
        text
        v-on:click="decrementIndex()"
        color="primary"
        ><v-icon large>mdi-chevron-left</v-icon></v-btn
      >
      <v-col>
        <v-card tile class="leadsCard text-center" height="100%">
          <v-card-title class="justify-center">
            <h1 class="mb-0">{{ newLeadsPerWeekCount[weekIndex] }}</h1>
          </v-card-title>
          <v-card-text>
            <h3>New Leads</h3>
          </v-card-text>
          <!-- <SparklineChart
            v-if="isLoaded"
            :newLeadsData="newLeadsPerWeekCount[weekIndex]"
          ></SparklineChart> -->
        </v-card>
      </v-col>
      <v-col>
        <v-card tile class="analyticsCard text-center mb-2">
          <v-card-title class="justify-center">
            <h1 class="mb-0">{{ contactedPerWeekCount[weekIndex] }}</h1>
          </v-card-title>
          <v-card-text>
            <h3>Contacted</h3>
          </v-card-text>
        </v-card>
        <v-card tile class="perentageCard">
          <v-row class="ma-0">
            <v-col>
              <v-card-title class="pa-0 ma-0 justify-center">
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <h3 v-bind="attrs" v-on="on">
                      {{
                        calculatePercentage(
                          newLeadsPerWeekCount[weekIndex],
                          contactedPerWeekCount[weekIndex]
                        )
                      }}<v-icon small> mdi-percent </v-icon>
                    </h3>
                  </template>
                  <span
                    >Percentage of <b>all</b> leads that have been
                    contacted</span
                  >
                </v-tooltip>
              </v-card-title>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col>
        <v-card tile class="analyticsCard text-center mb-2">
          <v-card-title class="justify-center">
            <h1 class="mb-0">{{ intakedPerWeekCount[weekIndex] }}</h1>
          </v-card-title>
          <v-card-text>
            <h3>Intaked</h3>
          </v-card-text>
        </v-card>
        <v-card tile class="perentageCard text-center pa-0">
          <v-row class="ma-0">
            <v-col
              ><v-card-title class="justify-center pa-0">
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <h3 v-bind="attrs" v-on="on">
                      {{
                        calculatePercentage(
                          newLeadsPerWeekCount[weekIndex],
                          intakedPerWeekCount[weekIndex]
                        )
                      }}<v-icon small> mdi-percent </v-icon>
                    </h3>
                  </template>
                  <span
                    >Percentage of <b>all</b> leads that have been intaked</span
                  >
                </v-tooltip>
              </v-card-title></v-col
            >
            <v-col
              ><v-card-title class="justify-center pa-0">
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <h3 v-bind="attrs" v-on="on">
                      {{
                        calculatePercentage(
                          contactedPerWeekCount[weekIndex],
                          intakedPerWeekCount[weekIndex]
                        )
                      }}<v-icon small> mdi-percent </v-icon>
                    </h3>
                  </template>
                  <span
                    >Percentage of <b>contacted</b> leads that have been
                    intaked</span
                  >
                </v-tooltip>
              </v-card-title></v-col
            >
          </v-row>
        </v-card>
      </v-col>
      <v-col>
        <v-card tile class="analyticsCard text-center mb-2">
          <v-card-title class="justify-center">
            <h1 class="mb-0">{{ placedPerWeekCount[weekIndex] }}</h1>
          </v-card-title>
          <v-card-text>
            <h3>Placed</h3>
          </v-card-text>
        </v-card>
        <v-card tile class="perentageCard text-center pa-0">
          <v-row class="ma-0">
            <v-col>
              <v-card-title class="justify-center pa-0">
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <h3 v-bind="attrs" v-on="on">
                      {{
                        calculatePercentage(
                          newLeadsPerWeekCount[weekIndex],
                          placedPerWeekCount[weekIndex]
                        )
                      }}<v-icon small> mdi-percent </v-icon>
                    </h3>
                  </template>
                  <span
                    >Percentage of <b>all</b> leads that have been placed at
                    work</span
                  >
                </v-tooltip>
              </v-card-title>
            </v-col>
            <v-col>
              <v-card-title class="justify-center pa-0">
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <h3 v-bind="attrs" v-on="on">
                      {{
                        calculatePercentage(
                          intakedPerWeekCount[weekIndex],
                          placedPerWeekCount[weekIndex]
                        )
                      }}<v-icon small> mdi-percent </v-icon>
                    </h3>
                  </template>
                  <span
                    >Percentage of <b>intaked</b> leads that have been placed at
                    work</span
                  >
                </v-tooltip>
              </v-card-title></v-col
            >
          </v-row>
        </v-card>
      </v-col>
      <v-col>
        <v-card tile class="analyticsCard text-center mb-2">
          <v-card-title class="justify-center">
            <h1 class="mb-0">{{ exitedPerWeekCount[weekIndex] }}</h1>
          </v-card-title>
          <v-card-text>
            <h3>Exited</h3>
          </v-card-text>
        </v-card>
        <v-card tile class="perentageCard text-center pa-0">
          <v-row class="ma-0">
            <v-col>
              <v-card-title class="justify-center pa-0">
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <h3 v-bind="attrs" v-on="on">
                      {{
                        calculatePercentage(
                          newLeadsPerWeekCount[weekIndex],
                          exitedPerWeekCount[weekIndex]
                        )
                      }}<v-icon small> mdi-percent </v-icon>
                    </h3>
                  </template>
                  <span
                    >Percentage of <b>all</b> leads that have exited the
                    program</span
                  >
                </v-tooltip>
              </v-card-title>
            </v-col>
            <v-col>
              <v-card-title class="justify-center pa-0">
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <h3 v-bind="attrs" v-on="on">
                      {{
                        calculatePercentage(
                          placedPerWeekCount[weekIndex],
                          exitedPerWeekCount[weekIndex]
                        )
                      }}<v-icon small> mdi-percent </v-icon>
                    </h3>
                  </template>
                  <span
                    >Percentage of <b>placed at work </b>leads that have exited
                    the program</span
                  >
                </v-tooltip>
              </v-card-title>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-btn
        :disabled="weekIndex == 3"
        height="60%"
        text
        v-on:click="incrementIndex()"
        color="primary"
        ><v-icon large>mdi-chevron-right</v-icon></v-btn
      >
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { GET_DASHBOARD_FLOW_LEADS } from "../../store/action.type";
import {
  extractWeeklyStats,
  getPreviousWeeks,
  getIntakedPerWeek,
  getPlacedPerWeek,
  getNewLeadsPerWeek,
  getContactedPerWeek,
  getExitedPerWeek,
  getCurrentWeekNum,
} from "../../helpers/statisticsDataGetter";
import SparklineChart from "./SparklineChart.vue";
export default {
  components: {
    // SparklineChart,
  },
  data: () => ({
    newLeadsPerWeekCount: [],
    contactedPerWeekCount: [],
    intakedPerWeekCount: [],
    placedPerWeekCount: [],
    exitedPerWeekCount: [],
    weekIndex: 3,
    isLoaded: false,
  }),
  created() {
    this.init();
  },
  methods: {
    ...mapActions("flow", { getDashboardFlowLeads: GET_DASHBOARD_FLOW_LEADS }),
    async init() {
      const week = getCurrentWeekNum();
      const currentYear = new Date().getFullYear();
      const year = currentYear;
      this.previousWeeks = getPreviousWeeks(week);
      await this.getDashboardFlowLeads({
        week,
        year,
      }).then((res) => {
        this.weeklyStats = extractWeeklyStats(res);
        this.newLeadsPerWeekCount = getNewLeadsPerWeek(this.weeklyStats);
        this.contactedPerWeekCount = getContactedPerWeek(this.weeklyStats);
        this.exitedPerWeekCount = getExitedPerWeek(this.weeklyStats);
        this.intakedPerWeekCount = getIntakedPerWeek(this.weeklyStats);
        this.placedPerWeekCount = getPlacedPerWeek(this.weeklyStats);
      });
      this.isLoaded = true;
    },
    decrementIndex() {
      if (this.weekIndex > 0) {
        this.weekIndex--;
      }
    },
    incrementIndex() {
      if (this.weekIndex < 3) {
        this.weekIndex++;
      }
    },
    calculatePercentage(firstValue, secondValue) {
      if (firstValue <= 0 || secondValue <= 0) return 0;
      return Math.round((secondValue / firstValue) * 100);
    },
  },
};
</script>

<style>
.analyticsCard {
  border-top: 2.5px solid #009ee3 !important;
}
.leadsCard {
  border-top: 2.5px solid #009ee3 !important;
  border-bottom: 2.5px solid #009ee3 !important;
}
.perentageCard {
  border-bottom: 2.5px solid #009ee3 !important;
}
</style>
