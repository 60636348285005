<template>
  <v-container fluid>
    <v-row>
      <v-col md="4" class="pb-0">
        <v-text-field
          class="ml-1 mt-1 pa-0"
          dense
          v-model="name"
          label="API KEY NAME"
          solo
        ></v-text-field>
      </v-col>
      <v-col class="pb-0">
        <v-text-field
          class="mr-1 mt-1 pa-0"
          dense
          :value="generatedApiKey"
          label="API KEY"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show1 ? 'text' : 'password'"
          solo
          readonly
          @click:append="show1 = !show1"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-alert dense dark type="warning">
      The API KEY will be displayed only once
    </v-alert>
    <v-row>
      <v-col class="text-right">
        <v-btn
          class="mx-1 mb-1"
          style="height: 39px"
          :disabled="nameIsEmpty"
          dense
          color="primary"
          @click="generateKey"
          >Generate Key</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { GENERATE_API_KEY } from "../../store/action.type";
export default {
  data: () => ({
    generatedApiKey: "",
    name: "",
    show1: true,
  }),
  computed: {
    ...mapGetters("external_api_keys", ["getApiKey"]),
    nameIsEmpty() {
      if (this.name !== "") {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions("external_api_keys", {
      generateApiKey: GENERATE_API_KEY,
    }),
    async initialize() {},
    async generateKey() {
      var body = {
        name: this.name,
      };
      await this.generateApiKey(body).then(() => {
        this.generatedApiKey = this.getApiKey;
      });

      this.name = "";
      //this.clearName();
    },
    clearName() {
      setTimeout(() => {
        this.name = "";
      }, 10000);
    },
  },
};
</script>
