<template>
  <v-container>
    <v-text-field
      background-color="cardFields"
      outlined
      dense
      required
      :rules="requiredRules"
      label="Name"
      v-model="activeCategory.name"
    ></v-text-field>
    <v-row class="ma-0">
      <v-spacer></v-spacer>
      <v-btn
        class="savebtn"
        color="primary"
        @click="saveButton()"
        :disabled="activeCategory.name == ''"
        >Save</v-btn
      >
      <v-spacer />
      <v-icon
        v-if="this.type == 'update'"
        @click="dialogDeleteCategory = true"
        medium
        color="red"
        class="deleteIcon"
        >mdi-trash-can</v-icon
      >
    </v-row>
    <!-- Confirm Delete Category -->
    <v-dialog v-model="dialogDeleteCategory" max-width="340">
      <v-card>
        <v-card-title class="text-h5">
          <p class="wordBreak">
            Are you sure you want to delete {{ activeCategory.name }} category?
          </p>
        </v-card-title>

        <v-card-text>
          <p class="wordBreak">
            If you delete {{ activeCategory.name }} category all vacancies from
            this category will be moved to a no category.
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed @click="dialogDeleteCategory = false">
            Cancel
          </v-btn>

          <v-btn @click="deleteEntry()" depressed color="error"> Delete </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import {
  UPDATE_CATEGORY,
  CREATE_CATEGORY,
  DELETE_CATEGORY,
} from "../../../store/action.type";
export default {
  props: { type: String, activeCategory: Object },
  data: () => ({
    name: "",
    dialogDeleteCategory: false,
    requiredRules: [(v) => !!v || "Field is required"],
  }),
  methods: {
    ...mapActions("vacancy_flow", {
      createCategory: CREATE_CATEGORY,
      deleteCategory: DELETE_CATEGORY,
      updateCategory: UPDATE_CATEGORY,
    }),
    saveButton() {
      if (this.type == "update") {
        this.update();
      } else {
        this.create();
      }
    },
    async update() {
      let category = {
        name: this.activeCategory.name,
      };
      let id = this.activeCategory.id;
      await this.updateCategory({
        category,
        id,
      });
      this.$emit("close-dialog");
    },
    async create() {
      let category = {
        name: this.activeCategory.name,
      };
      await this.createCategory({
        category,
      });
      this.$emit("reload-table");
      this.$emit("close-dialog");
    },
    async deleteEntry() {
      let id = this.activeCategory.id;
      await this.deleteCategory({
        id,
      });
      this.$emit("reload-table");
      this.$emit("close-dialog");
    },
  },
};
</script>
<style scoped>
.savebtn:hover {
  transform: scale(1.1);
}
.deleteIcon:hover {
  transform: scale(1.1);
}
.wordBreak {
  word-break: break-word;
}
</style>
