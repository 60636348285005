import config from "../../../config";
import { authHeader } from "../../utils/auth-header";
import { handleResponse } from "../../utils/api_functions";
import { logger } from "../../utils/logger";

import {
  CONTRACT_REMINDER_MAIL,
  CREATE_CONTACT_LIST,
  END_OF_CONTACT_EMAIL,
  GET_ALL_CONTACT_LISTS,
  GET_MAIL_TRIGGERS,
  NO_CONTACT_EMAIL,
  SEND_ACQUISITION_MAIL,
  SEND_CONTACTS,
  SEND_RESCHEDULE_MAIL,
  UPDATE_EMAIL_TRIGGERS,
  SEND_EMAIL,
} from "../action.type";
import {
  SET_CONTACT_LISTS,
  SET_EMAIL_TRIGGERS,
  SET_INVALID_USERS_RESPONSE,
} from "../mutation.type";

export const send_grid = {
  namespaced: true,
  state: {
    invalidUsers: [],
    contactLists: [],
    emailTriggers: [],
  },
  getters: {
    getInvalidUsers: (state) => state.invalidUsers,
    getContactLists: (state) => state.contactLists,
    getEmailTriggers: (state) => state.emailTriggers,
  },

  actions: {
    async [SEND_CONTACTS]({ dispatch, commit }, payload) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(payload),
        };
        await fetch(`${config.apiUrl}/sendContacts`, request)
          .then(handleResponse)
          .then((data) => {
            dispatch("alerts/success", "Successfully added to SendGrid", {
              root: true,
            });
            commit(SET_INVALID_USERS_RESPONSE, data.invalidUsers);
          });
      } catch (error) {
        dispatch("alerts/error", "Upload Failed", { root: true });
        logger.error(error);
      }
    },
    async [GET_ALL_CONTACT_LISTS]({ commit }) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
        };
        await fetch(`${config.apiUrl}/getAllContactLists`, request)
          .then(handleResponse)
          .then((data) => {
            commit(SET_CONTACT_LISTS, data.body.result);
          });
      } catch (error) {
        logger.error(error);
      }
    },

    async [CREATE_CONTACT_LIST](_, payload) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(payload),
        };
        await fetch(`${config.apiUrl}/createContactList`, request)
          .then(handleResponse)
          .then((data) => {});
      } catch (error) {
        logger.error(error);
      }
    },

    async [SEND_ACQUISITION_MAIL](_, payload) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(payload),
        };
        await fetch(`${config.apiUrl}/sendAcquisitionMail`, request)
          .then(handleResponse)
          .then((data) => {});
      } catch (error) {
        logger.log(error);
      }
    },

    async [NO_CONTACT_EMAIL](_, payload) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(payload),
        };
        await fetch(`${config.apiUrl}/noContactEmail`, request)
          .then(handleResponse)
          .then((data) => {});
      } catch (error) {
        logger.error(error);
      }
    },

    async [SEND_RESCHEDULE_MAIL](_, payload) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(payload),
        };
        await fetch(`${config.apiUrl}/sendRescheduleMail`, request)
          .then(handleResponse)
          .then((data) => {});
      } catch (error) {
        logger.error(error);
      }
    },

    async [CONTRACT_REMINDER_MAIL](_, payload) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(payload),
        };
        await fetch(`${config.apiUrl}/contractReminderMail`, request)
          .then(handleResponse)
          .then((data) => {});
      } catch (error) {
        logger.error(error);
      }
    },

    async [END_OF_CONTACT_EMAIL](_, payload) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(payload),
        };
        await fetch(`${config.apiUrl}/endOfContactEmail`, request)
          .then(handleResponse)
          .then((data) => {});
      } catch (error) {
        logger.error(error);
      }
    },

    async [GET_MAIL_TRIGGERS]({ commit }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        await fetch(`${config.apiUrl}/emailTriggers`, request)
          .then(handleResponse)
          .then((data) => {
            commit(SET_EMAIL_TRIGGERS, data.email_triggers);
          });
      } catch (error) {
        logger.error(error);
      }
    },

    async [UPDATE_EMAIL_TRIGGERS]({ commit }, payload) {
      try {
        const request = {
          method: "PUT",
          headers: authHeader(),
          body: JSON.stringify(payload),
        };
        await fetch(`${config.apiUrl}/updateEmailTriggers`, request)
          .then(handleResponse)
          .then((data) => {
            commit(SET_EMAIL_TRIGGERS, data.email_triggers);
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [SEND_EMAIL]({ commit }, payload) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(payload),
        };
        await fetch(`${config.apiUrl}/sendEmail`, request).then(handleResponse);
      } catch (error) {
        logger.error(error);
      }
    },
  },

  mutations: {
    [SET_INVALID_USERS_RESPONSE](state, payload) {
      state.invalidUsers = payload;
    },
    [SET_CONTACT_LISTS](state, payload) {
      state.contactLists = payload;
    },
    [SET_EMAIL_TRIGGERS](state, payload) {
      state.emailTriggers = payload;
    },
  },
};
