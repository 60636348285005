import config from "../../../config";
import { authHeader } from "../../utils/auth-header";
import { handleResponse } from "../../utils/api_functions";
import { logger } from "../../utils/logger";

import {
  GET_VACANCY_FLOW,
  GET_VACANCY_NO_FLOW,
  GET_VACANCY_FLOW_BY_ID,
  GET_VACANCY_FLOW_BY_COMPANY,
  GET_VACANCIES_HOT,
  UPDATE_VACANCY_FLOW,
  BULK_UPDATE_VACANCY_FLOW,
  CREATE_VACANCY_FLOW,
  CREATE_CATEGORY,
  DELETE_CATEGORY,
  UPDATE_CATEGORY,
  GET_VACANCIES_ALL,
} from "../action.type";
import {
  SET_VACANCY_FLOW,
  SET_VACANCY_NO_FLOW,
  SET_VACANCY_FLOW_BY_COMPANY,
  GET_VACANCY_FLOW_SUCCESS,
  SET_VACANCIES_ALL,
} from "../mutation.type";

export const vacancy_flow = {
  namespaced: true,
  state: {
    vacancies_all: [],
    vacancy_flow: [],
    vacancy_no_flow: [],
    vacancy_flow_by_company: [],
    active_vacancy_flow: null,
  },
  getters: {
    vacancies_all_getter: (state) => {
      return state.vacancies_all.filter(
        (vacancy) => !vacancy.archived || vacancy.archived == null
      );
    },
    archived_vacancies_getter: (state) => {
      return state.vacancies_all.filter((vacancy) => vacancy.archived);
    },
    vacancy_flow_getter: (state) => {
      let vacancies = state.vacancy_flow;

      for (let category = 0; category < state.vacancy_flow.length; category++) {
        for (
          let vacancy = 0;
          vacancy < vacancies[category].Vacancies_flow.length;
          vacancy++
        ) {
          vacancies[category].Vacancies_flow[vacancy].position = JSON.parse(
            vacancies[category].Vacancies_flow[vacancy].position
          );
          if (
            vacancies[category].Vacancies_flow[vacancy].hoursPerDay !== null
          ) {
            vacancies[category].Vacancies_flow[vacancy].hoursPerDay =
              JSON.parse(
                vacancies[category].Vacancies_flow[vacancy].hoursPerDay
              );
          }
          if (vacancies[category].Vacancies_flow[vacancy].language !== null) {
            try {
              vacancies[category].Vacancies_flow[vacancy].language = JSON.parse(
                vacancies[category].Vacancies_flow[vacancy].language
              );
            } catch {
              let array = [];
              array.push(vacancies[category].Vacancies_flow[vacancy].language);
              vacancies[category].Vacancies_flow[vacancy].language = array;
            }
          }
        }
      }
      return vacancies;
    },
    vacancy_no_flow_getter: (state) => {
      let vacancies = state.vacancy_no_flow;

      for (let vacancy = 0; vacancy < state.vacancy_no_flow.length; vacancy++) {
        if (vacancies[vacancy].hoursPerDay !== null) {
          vacancies[vacancy].hoursPerDay = JSON.parse(
            vacancies[vacancy].hoursPerDay
          );
        }
        if (vacancies[vacancy].language !== null) {
          try {
            vacancies[vacancy].language = JSON.parse(
              vacancies[vacancy].language
            );
          } catch {
            let array = [];
            array.push(vacancies[vacancy].language);
            vacancies[vacancy].language = array;
          }
        }
      }
      return vacancies;
    },
    categories_getter: (state) => {
      return state.vacancy_flow.map((e) => {
        return {
          id: e.id,
          name: e.name,
        };
      });
    },
    vacancy_flow_by_company_getter: (state) => state.vacancy_flow_by_company,
    active_vacancy_flow_getter: (state) => {
      let activeVacancy = state.active_vacancy_flow;
      if (activeVacancy !== null) {
        try {
          activeVacancy.language = JSON.parse(activeVacancy.language);
        } catch {
          if (activeVacancy.language === null) {
            activeVacancy.language = [];
          } else {
            let array = [];
            array.push(activeVacancy.language);
            activeVacancy.language = array;
          }
        }
      }
      return activeVacancy;
    },
  },
  actions: {
    async [GET_VACANCIES_ALL]({ commit }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/vacancies_flow/all`, request)
          .then(handleResponse)
          .then((res) => {
            commit(SET_VACANCIES_ALL, res.vacancies);
            return res.vacancies;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [GET_VACANCY_FLOW]({ commit }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/vacancy_flow`, request)
          .then(handleResponse)
          .then((res) => {
            commit(SET_VACANCY_FLOW, res.vacancies);
            return res.vacancies;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    // * Vacancies without assigned category
    async [GET_VACANCY_NO_FLOW]({ commit }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/vacancy_flow/no_category`, request)
          .then(handleResponse)
          .then((res) => {
            commit(SET_VACANCY_NO_FLOW, res.vacancies);
            return res.vacancies;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [GET_VACANCIES_HOT]({ commit }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/vacancy_flow/hot/all`, request)
          .then(handleResponse)
          .then((res) => {
            return res.vacancyFlow;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [UPDATE_VACANCY_FLOW](_, { vacancy, id }) {
      try {
        const request = {
          method: "PUT",
          headers: authHeader(),
          body: JSON.stringify(vacancy),
        };
        return await fetch(`${config.apiUrl}/vacancy_flow/${id}`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [BULK_UPDATE_VACANCY_FLOW](_, { arr }) {
      try {
        const request = {
          method: "PUT",
          headers: authHeader(),
          body: JSON.stringify(arr),
        };
        return await fetch(`${config.apiUrl}/bulkUpdate`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [CREATE_VACANCY_FLOW](_, { vacancy_flow }) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(vacancy_flow),
        };
        return await fetch(`${config.apiUrl}/vacancy_flow`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [GET_VACANCY_FLOW_BY_ID]({ commit }, id) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/vacancy_flow/${id}`, request)
          .then(handleResponse)
          .then((res) => {
            commit(GET_VACANCY_FLOW_SUCCESS, res.vacancyFlow);
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [GET_VACANCY_FLOW_BY_COMPANY]({ commit }, id) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(
          `${config.apiUrl}/vacancy_flow/company/${id}`,
          request
        )
          .then(handleResponse)
          .then((res) => {
            commit(SET_VACANCY_FLOW_BY_COMPANY, res.vacancies);
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [CREATE_CATEGORY]({ commit }, { category }) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(category),
        };
        return await fetch(`${config.apiUrl}/create_category/`, request).then(
          handleResponse
        );
      } catch (error) {
        logger.error(error);
      }
    },
    async [DELETE_CATEGORY]({ commit }, { id }) {
      try {
        const request = {
          method: "DELETE",
          headers: authHeader(),
        };
        return await fetch(
          `${config.apiUrl}/delete_category/${id}`,
          request
        ).then(handleResponse);
      } catch (error) {
        logger.error(error);
      }
    },
    async [UPDATE_CATEGORY]({ commit }, { category, id }) {
      try {
        const request = {
          method: "PUT",
          headers: authHeader(),
          body: JSON.stringify(category),
        };
        return await fetch(
          `${config.apiUrl}/update_category/${id}`,
          request
        ).then(handleResponse);
      } catch (error) {
        logger.error(error);
      }
    },
  },
  mutations: {
    [SET_VACANCIES_ALL](state, vacancy_flow) {
      state.vacancies_all = vacancy_flow;
    },
    [SET_VACANCY_FLOW](state, vacancy_flow) {
      state.vacancy_flow = vacancy_flow;
    },
    [SET_VACANCY_NO_FLOW](state, vacancy_flow) {
      state.vacancy_no_flow = vacancy_flow;
    },
    [GET_VACANCY_FLOW_SUCCESS](state, vacancy_flow) {
      state.active_vacancy_flow = vacancy_flow;
    },
    [SET_VACANCY_FLOW_BY_COMPANY](state, vacancy_flow) {
      state.vacancy_flow_by_company = vacancy_flow;
    },
  },
};
