<template>
  <v-container fluid><LeadPipes /> </v-container>
</template>
<script>
import LeadPipes from "@/components/Job/LeadPipes.vue";
export default {
  name: "NewLeads",
  components: {
    LeadPipes,
  },
};
</script>
