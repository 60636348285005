<template>
  <v-container class="pa-5">
    <v-row>
      <v-col>
        <h2>{{ $t("bug_and_feature_report.report_bug_feature") }}</h2>
      </v-col>
      <v-col class="pr-0 mr-0" align="end">
        <v-btn
          :disabled="!valid"
          color="primary"
          class="mr-4"
          @click="sendToTrello"
        >
          Trello
        </v-btn>
      </v-col>
    </v-row>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        v-model="data_fields.title"
        :label="$t('bug_and_feature_report.title')"
        required
        :rules="[(v) => !!v || $t('bug_and_feature_report.necessary_title')]"
      ></v-text-field>
      <v-textarea
        v-model="data_fields.description"
        filled
        :label="$t('bug_and_feature_report.description')"
        required
        :rules="[
          (v) => !!v || $t('bug_and_feature_report.necessary_description'),
        ]"
      ></v-textarea>
      <v-select
        :items="reportTypes"
        v-model="data_fields.reportType"
        required
        :rules="[(v) => !!v || $t('bug_and_feature_report.necessary_type')]"
        :label="$t('bug_and_feature_report.type')"
        solo
        style="padding-top: 15px"
      ></v-select>
      <v-row justify="center">
        <v-btn
          :disabled="!valid"
          color="success"
          class="mr-4"
          @click="buttonClick"
        >
          {{ $t("bug_and_feature_report.send") }}
        </v-btn>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { REQUEST_FEATURE, REPORT_BUG } from "@/store/action.type";
export default {
  props: {},
  data: () => ({
    valid: false,
    data_fields: {
      reportType: "",
      title: "",
      description: "",
    },
    reportTypes: ["Bug", "Feature"],
  }),

  methods: {
    ...mapActions("utility", {
      requestFeature: REQUEST_FEATURE,
      reportBug: REPORT_BUG,
    }),
    async buttonClick() {
      if (this.data_fields.reportType == "") {
        const { dispatch } = this.$store;
        dispatch("alerts/error", this.$t("bug_and_feature_report.error"));
      } else if (this.data_fields.reportType == "Bug") {
        this.createJiraReport();
      } else if (this.data_fields.reportType == "Feature") {
        this.sendEmailReport();
      }
    },

    async sendEmailReport() {
      var reportCreator =
        this.$store.state.users.user.first_name +
        " " +
        this.$store.state.users.user.last_name;
      let data = {
        title: this.data_fields.title,
        reportCreator: reportCreator,
        description: this.data_fields.description,
      };
      await this.requestFeature(data).then(() => {
        this.$store.dispatch(
          "alerts/success",
          this.$t("bug_and_feature_report.success")
        );
        this.$emit("close-dialog");
        this.clear();
      });
    },

    async createJiraReport() {
      var reportCreator =
        this.$store.state.users.user.first_name +
        " " +
        this.$store.state.users.user.last_name;
      try {
        await this.reportBug({
          data: {
            title: this.data_fields.title,
            description: this.data_fields.description,
            type: this.data_fields.reportType,
            submitter: reportCreator,
          },
        }).then(() => {
          this.$store.dispatch(
            "alerts/success",
            this.$t("bug_and_feature_report.success")
          );
          this.$emit("close-dialog");
          this.clear();
        });
      } catch (err) {
        console.log(err);
      }
    },
    clear() {
      this.$refs.form.reset();
    },
    sendToTrello() {
      window.open("https://trello.com/b/FGZIWUtD", "_blank").focus();
    },
  },
};
</script>

<style scoped></style>
