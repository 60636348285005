import config from "../../../config";
import { authHeader } from "../../utils/auth-header";
import { handleResponse } from "../../utils/api_functions";
import { logger } from "../../utils/logger";

import {
  FETCH_COMPANIES,
  FETCH_EMPLOYEES,
  FETCH_ROLES,
  FETCH_VACANCIES,
  SYNC_LISTS,
} from "../action.type";
import {
  SET_CANDIDATE_FILTER,
  SET_LEAD_FILTER,
  SET_LEAD_SORT_BY,
  SET_LEAD_SORT_DESC,
  SET_VACANCY_FILTER,
} from "../mutation.type";

export const filters = {
  namespaced: true,
  state: {
    vacancy_filter: {},
    lead_filter: {},
    candidate_filter: {},
    leadSortBy: "applied_date",
    leadDesc: true,
    lists: {
      sync: null,
      vacancies: [],
      employees: [],
      companies: [],
      roles: [],
    },
  },
  getters: {
    employees_getter: (state) => {
      return state.lists.employees.map((e) => {
        return {
          id: e.id,
          name: e.first_name + " " + e.last_name,
        };
      });
    },
    companies_getter: (state) => state.lists.companies,
  },

  actions: {
    [SYNC_LISTS]({ state, dispatch }, forceSync) {
      try {
        const now = Date.now();
        const localSync = localStorage.getItem("listsSync");
        var hours = 0;
        if (localSync != null) {
          hours = Math.abs(now - localSync) / 36e5;
        }
        if (localSync == null || hours > 1 || forceSync) {
          state.lists.sync = now;
          localStorage.setItem("listsSync", now);
          dispatch("fetchVacancies");
          dispatch("fetchEmployees");
          dispatch("fetchCompanies");
          dispatch("fetchRoles");
        } else {
          state.lists.vacancies = JSON.parse(
            localStorage.getItem("short-vacancies")
          );
          state.lists.employees = JSON.parse(
            localStorage.getItem("short-employees")
          );
          state.lists.companies = JSON.parse(
            localStorage.getItem("short-companies")
          );
          state.lists.roles = JSON.parse(localStorage.getItem("short-roles"));
        }
      } catch (error) {
        logger.error(error);
      }
    },
    async [FETCH_VACANCIES]({ state }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        await fetch(`${config.apiUrl}/vacancies-short`, request)
          .then(handleResponse)
          .then((data) => {
            state.lists.vacancies = data.vacancies;
            localStorage.setItem(
              "short-vacancies",
              JSON.stringify(data.vacancies)
            );
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [FETCH_EMPLOYEES]({ state }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        await fetch(`${config.apiUrl}/employees-short`, request)
          .then(handleResponse)
          .then((data) => {
            state.lists.employees = data.employees;
            localStorage.setItem(
              "short-employees",
              JSON.stringify(data.employees)
            );
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [FETCH_COMPANIES]({ state }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        await fetch(`${config.apiUrl}/companies-short`, request)
          .then(handleResponse)
          .then((data) => {
            state.lists.companies = data.companies;
            localStorage.setItem(
              "short-companies",
              JSON.stringify(data.companies)
            );
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [FETCH_ROLES]({ state }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        await fetch(`${config.apiUrl}/roles-short`, request)
          .then(handleResponse)
          .then((data) => {
            state.lists.roles = data.roles;
            localStorage.setItem("short-roles", JSON.stringify(data.roles));
          });
      } catch (error) {
        logger.error(error);
      }
    },
  },
  mutations: {
    [SET_VACANCY_FILTER](state, filter) {
      state.vacancy_filter = filter;
    },
    [SET_LEAD_FILTER](state, filter) {
      state.lead_filter = filter;
    },
    [SET_CANDIDATE_FILTER](state, filter) {
      state.candidate_filter = filter;
    },
    [SET_LEAD_SORT_BY](state, sortBy) {
      state.leadSortBy = sortBy;
    },
    [SET_LEAD_SORT_DESC](state, sortDesc) {
      state.leadSortDesc = sortDesc;
    },
  },
};
