import config from "../../../config";
import { authHeader } from "../../utils/auth-header";
import { handleResponse } from "../../utils/api_functions";
import { logger } from "../../utils/logger";

import {
  ADD_STATUS_TO_LEAD,
  CREATE_LEAD,
  DELETE_LEAD_BY_ID,
  GET_ALL_LEADS_WITH_FLOW,
  GET_CHANNELS,
  GET_LEADS,
  GET_LEADS_BY_MONTH,
  GET_LEADS_BY_VACANCY_ID,
  GET_LEADS_PER_PERIOD,
  GET_LEAD_BY_ID,
  GET_LEAD_VACANCIES,
  UPDATE_LEAD,
  ACTIVATE_LEAD,
} from "../action.type";
import {
  SET_CHANNELS,
  SET_LEAD,
  SET_LEADS,
  SET_LEADS_BY_MONTH,
  SET_LEADS_BY_VAC,
  SET_LEADS_FLOW,
  SET_LEADS_PAGINATION,
  SET_LEADS_PER_PERIOD,
  SET_LEADS_VACANCIES,
} from "../mutation.type";

export const leads = {
  namespaced: true,
  state: {
    leads: {},
    leadFlow: {},
    leadsForPeriod: {},
    leadsByVac: {},
    channels: {},
    vacancies: {},
    activeLead: {},
    filter: {},
    pagination: {},
    leadsByMonth: {},
  },
  getters: {
    getleadsByMonth: (state) => state.leadsByMonth,
    getLeadFlow: (state) => state.leadFlow,
    getLeadsForPeriod: (state) => state.leadsForPeriod,
  },
  actions: {
    async [GET_LEADS]({ commit }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/leads`, request)
          .then(handleResponse)
          .then((res) => {
            commit(SET_LEADS, res);
            return res;
          });
      } catch (error) {
        logger.error;
      }
    },
    async [GET_LEAD_BY_ID]({ commit }, leadId) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/leads/${leadId}`, request)
          .then(handleResponse)
          .then((res) => {
            commit(SET_LEAD, res);
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },

    async [GET_LEADS_BY_VACANCY_ID]({ commit }, vacancyId) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };

        return await fetch(
          `${config.apiUrl}/leads/vacancy/${vacancyId}`,
          request
        )
          .then(handleResponse)
          .then((res) => {
            commit(SET_LEADS_BY_VAC, res);
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },

    async [ADD_STATUS_TO_LEAD](_, leadStatus) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(leadStatus),
        };
        return await fetch(`${config.apiUrl}/leads/status`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },

    async [GET_ALL_LEADS_WITH_FLOW]({ commit }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/leadsWithFlow`, request)
          .then(handleResponse)
          .then((res) => {
            commit(SET_LEADS_FLOW, res);
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },

    async [GET_LEADS_PER_PERIOD]({ commit }, { params }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        await fetch(
          `${config.apiUrl}/leadsPerPeriod/?applicationStartDate=${params.applicationStartDate}&applicationEndDate=${params.applicationEndDate}&contactStartDate=${params.contactStartDate}&contactEndDate=${params.contactEndDate}&intakeStartDate=${params.intakeStartDate}&intakeEndDate=${params.intakeEndDate}`,
          request
        )
          .then(handleResponse)
          .then((res) => {
            commit(SET_LEADS_PER_PERIOD, res);
          });
      } catch (error) {
        logger.error(error);
      }
    },

    async [GET_LEADS_BY_MONTH]({ commit }, month) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        await fetch(`${config.apiUrl}/leads/leadsByMonth/${month}`, request)
          .then(handleResponse)
          .then((res) => {
            commit(SET_LEADS_BY_MONTH, res.leads);
          });
      } catch (error) {
        logger.error(error);
      }
    },

    async [CREATE_LEAD](_, lead) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(lead),
        };
        return await fetch(`${config.apiUrl}/leads`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [UPDATE_LEAD](_, { lead, leadId }) {
      try {
        const request = {
          method: "PUT",
          headers: authHeader(),
          body: JSON.stringify(lead),
        };
        return await fetch(`${config.apiUrl}/leads/${leadId}`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },

    async [ACTIVATE_LEAD](_, { leadId, lead_user }) {
      try {
        const request = {
          method: "PUT",
          headers: authHeader(),
        };
        return await fetch(
          `${config.apiUrl}/leads/activateLead/${leadId}/${lead_user}`,
          request
        )
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },

    async [DELETE_LEAD_BY_ID]({ dispatch }, leadId) {
      try {
        const request = {
          method: "DELETE",
          headers: authHeader(),
        };
        await fetch(
          `${config.apiUrl}/leads/deleteLead/${leadId}`,
          request
        ).then(handleResponse);
        // axios.defaults.headers.common["Authorization"] = `${
        //   authHeader().Authorization
        // }`;
        // const { data } = await axios.delete(
        //   `${config.apiUrl}/leads/deleteLead/${leadId}`,
        //   {}
        // );
        dispatch("alerts/success", "Lead Deleted", { root: true });
      } catch (error) {
        dispatch("alerts/error", "Lead cannot be deleted", { root: true });
        logger.error(error);
      }
    },

    async [GET_CHANNELS]({ commit }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/lead-channel`, request)
          .then(handleResponse)
          .then((res) => {
            commit(SET_CHANNELS, res);
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },

    async [GET_LEAD_VACANCIES]({ commit }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return fetch(`${config.apiUrl}/vacancies`, request)
          .then(handleResponse)
          .then((res) => {
            commit(SET_LEADS_VACANCIES, res);
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
  },
  mutations: {
    [SET_LEADS](state, items) {
      state.leads = items.leads;
    },
    [SET_LEAD](state, items) {
      state.activeLead = items.lead;
    },
    [SET_LEADS_BY_VAC](state, items) {
      state.leadsByVac = items.leads;
    },
    [SET_LEADS_FLOW](state, items) {
      state.leadFlow = items.leads;
    },
    [SET_LEADS_PER_PERIOD](state, items) {
      state.leadsForPeriod = items.leads;
    },
    [SET_LEADS_BY_MONTH](state, items) {
      state.leadsByMonth = items;
    },
    [SET_CHANNELS](state, items) {
      state.channels = items.lead_channels;
    },
    [SET_LEADS_VACANCIES](state, items) {
      state.vacancies = items.vacancies;
    },
    [SET_LEADS_PAGINATION](state, pagination) {
      state.pagination = pagination;
    },
  },
};
