<template>
  <v-card :loading="!loaded">
    <v-container class="pa-5">
      <v-form ref="form" v-model="valid" lazy-validation v-if="loaded">
        <v-row>
          <v-col md="6">
            <v-autocomplete
              :items="contactMomentStatusesList"
              item-text="name"
              item-value="id"
              required
              :rules="[(v) => !!v || $t('create_company_cm.required_type')]"
              v-model="formDataFields.type"
              :label="$t('create_company_cm.contact_moment_type')"
              persistent-hint
              :hint="$t('create_company_cm.type_hint')"
            ></v-autocomplete>
          </v-col>
          <v-col md="6">
            <v-autocomplete
              :items="vacanciesList"
              item-text="name"
              item-value="id"
              v-model="formDataFields.vacancy_id"
              :label="$t('create_company_cm.vacancy')"
              persistent-hint
              :hint="$t('create_company_cm.vacancy_hint')"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <v-select
              v-model="formDataFields.contact_people"
              :items="contactPeopleList"
              :item-text="fullName"
              item-value="id"
              required
              :rules="
                [
                  (v) => !!v || $t('create_company_cm.required_contact_person'),
                ] && [
                  (v) =>
                    v.length > 0 ||
                    $t('create_company_cm.required_contact_person'),
                ]
              "
              :label="$t('create_company_cm.contact_person')"
              persistent-hint
              :hint="$t('create_company_cm.contact_person_hint')"
              multiple
            >
              <template v-slot:selection="{ item, index }">
                <v-chip label v-if="index === 0">
                  <span>{{ fullName(item) }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text caption">
                  (+{{ formDataFields.contact_people.length - 1 }}
                  {{ $t("create_company_cm.others") }})
                </span>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <v-select
              v-model="formDataFields.employees"
              :items="employeesList"
              :item-text="fullName"
              item-value="id"
              :label="$t('create_company_cm.staff')"
              persistent-hint
              :hint="$t('create_company_cm.staff_hint')"
              multiple
            >
              <template v-slot:selection="{ item, index }">
                <v-chip label v-if="index === 0">
                  <span>{{ fullName(item) }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text caption">
                  (+{{ formDataFields.employees.length - 1 }}
                  {{ $t("create_company_cm.others") }})
                </span>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row class="ml-1 mr-1">
          <DateTimePicker v-model="data_fields.createdAt"></DateTimePicker>
        </v-row>
        <v-row class="pb-0 mb-n8">
          <v-col md="12">
            <h5>{{ $t("create_company_cm.notes") }}</h5>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <quillEditor v-model="data_fields.notes" :options="editorOption" />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-btn
            :disabled="!valid || adding"
            color="success"
            class="mr-4"
            @click="buttonClick"
          >
            {{ types[type].buttonText }}
          </v-btn>
        </v-row>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";
import DateTimePicker from "./DateTimePicker.vue";
import { permission } from "../../utils/permissions";

import {
  CREATE_CONTACT_MOMENT,
  GET_COMPANY,
  GET_CONTACTS,
  GET_CONTACT_MOMENT_STATUSES,
  GET_USERS,
  GET_VACANCIES_BY_COMPANY_ID,
  UPDATE_CONTACT_MOMENT,
} from "../../store/action.type";
import { mapActions } from "vuex";
export default {
  components: {
    quillEditor,
    DateTimePicker,
  },
  props: {
    type: String,
    formData: Object,
    parentID: Number,
  },
  data: () => ({
    loaded: false,
    contactMomentStatusesList: [],
    contactPeopleList: [],
    something: {},
    employeesList: [],
    vacanciesList: [],
    adding: false,
    valid: false,
    data_fields_status: {
      lead_status_id: null,
    },
    data_fields: {
      contact_people: [],
      employees: [],
      company_id: null,
      type: null,
      comunicator_id: null,
      user_status_id: null,
      notes: null,
      vacancy_id: null,
      createdAt: null,
    },
    content: "",
    editorOption: {
      modules: {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          ["blockquote", "code-block"],
          [{ header: 1 }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }],
          [{ color: [] }, { background: [] }],
        ],
      },
    },
  }),

  computed: {
    formDataFields() {
      this.updateFormFields();
      return this.data_fields;
    },
    types() {
      return [
        {
          buttonText: this.$t("create_company_cm.create_cm"),
        },
        {
          buttonText: this.$t("create_company_cm.customize_cm"),
        },
      ];
    },
  },

  created() {
    if (this.type == 1) {
      this.data_fields = this.formData;
      this.userSwitch = true;
      this.editMode = true;
    }
    this.initialize();
  },

  methods: {
    ...mapActions("company", { getCompany: GET_COMPANY }),
    ...mapActions("contact_moments", {
      createContactMoment: CREATE_CONTACT_MOMENT,
      updateContactMoment: UPDATE_CONTACT_MOMENT,
    }),
    ...mapActions("contact", { getContacts: GET_CONTACTS }),
    ...mapActions("statuses", {
      getContactMomentStatuses: GET_CONTACT_MOMENT_STATUSES,
    }),
    ...mapActions("users", { getUsers: GET_USERS }),
    ...mapActions("vacancy", {
      getVacanciesByCompanyId: GET_VACANCIES_BY_COMPANY_ID,
    }),
    async initialize() {
      await this.getVacanciesByCompanyId(this.parentID).then((res) => {
        this.vacanciesList = res.vacancies.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
      });
      await this.getContacts(this.parentID).then((res) => {
        this.contactPeopleList = res.contact_persons.sort((a, b) => {
          if (a.first_name < b.first_name) {
            return -1;
          }
          if (a.first_name > b.first_name) {
            return 1;
          }
          return 0;
        });
      });
      await this.getUsers().then((res) => {
        this.employeesList = res.employees.sort((a, b) => {
          if (a.first_name < b.first_name) {
            return -1;
          }
          if (a.first_name > b.first_name) {
            return 1;
          }
          return 0;
        });
      });
      await this.getContactMomentStatuses().then((res) => {
        this.contactMomentStatusesList = res.contact_moment_statuses;
      });
      this.loaded = true;
    },

    fullName(item) {
      return item.first_name + " " + item.last_name;
    },

    updateFormFields() {
      if (this.type == "1") {
        this.data_fields = this.formData;
        let employees = JSON.parse(JSON.stringify(this.formData.employees)); // Make copy of object
        this.data_fields.employees = [];
        for (let i = 0; i < employees.length; i++) {
          this.data_fields.employees.push(employees[i].id);
        }
      } else {
        this.data_fields = {
          contact_people: [],
          employees: [],
          company_id: null,
          type: null,
          comunicator_id: null,
          user_status_id: null,
          notes: null,
          vacancy_id: null,
          createdAt: null,
        };
      }
    },

    buttonClick() {
      if (this.type == 0) {
        this.createNewCompanyContactMoment();
      } else if (this.type == 1) {
        this.editCompanyContactMoment();
      }
    },

    async editCompanyContactMoment() {
      const { dispatch } = this.$store;
      if (this.$refs.form.validate()) {
        this.adding = true;
        let contact_moment = {
          type: this.data_fields.type,
          notes: this.data_fields.notes,
          vacancy_id: this.data_fields.vacancy_id,
          contact_people: this.data_fields.contact_people,
          employees: this.data_fields.employees,
          createdAt: this.data_fields.createdAt,
        };
        let contact_momentId = this.formData.id;
        if (permission("isCommunityManager") || permission("isAdmin")) {
          await this.updateContactMoment({
            contact_moment,
            contact_momentId,
          }).then(() => {
            dispatch("alerts/success", this.$t("create_company_cm.cm_saved"));
            this.getCompany(this.parentID);
          });
          this.$emit("close-dialog");
        } else {
          dispatch(
            "alerts/warn",
            this.$t("create_company_cm.not_authorized_msg")
          );
        }
      } else {
        dispatch(
          "alerts/warn",
          this.$t("create_company_cm.not_all_fields_completed")
        );
      }
      this.adding = false;
    },

    async createNewCompanyContactMoment() {
      const { dispatch } = this.$store;
      if (this.$refs.form.validate()) {
        if (permission("isCommunityManager") || permission("isAdmin")) {
          this.adding = true;
          let newContactMoment = {
            type: this.data_fields.type,
            company_id: this.parentID,
            comunicator_id: this.$store.state.users.user.id,
            user_status_id: null,
            notes: this.data_fields.notes,
            vacancy_id: this.data_fields.vacancy_id,
            contact_people: this.data_fields.contact_people,
            employees: this.data_fields.employees,
            createdAt: this.data_fields.createdAt,
          };

          await this.createContactMoment(newContactMoment).then(() => {
            dispatch("alerts/success", this.$t("create_company_cm.cm_created"));
            this.getCompany(newContactMoment.company_id);
            this.clear();
            this.$emit("close-dialog");
          });
        } else {
          dispatch(
            "alerts/warn",
            this.$t("create_company_cm.not_authorized_msg")
          );
        }
      } else {
        dispatch(
          "alerts/warn",
          this.$t("create_company_cm.not_all_fields_completed")
        );
      }
      this.adding = false;
    },
    clear() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style scoped></style>
