<template>
  <v-container fill-height class="ma-0 pa-0" v-if="getLoadingStates.interests">
    <v-dialog v-model="dialogInterest" max-width="600px">
      <v-card>
        <CreateInterest
          v-on:close-dialog="dialogInterest = false"
          type="1"
          :formData="selectedInterest"
          :leadUserId="leadUserId"
        ></CreateInterest>
      </v-card>
    </v-dialog>
    <v-data-table
      :headers="headers"
      :items="interests"
      sort-by="priority"
      :sort-desc="true"
      class="elevation-1 pointerOnHover"
      height="265"
      style="width: 100%"
      fixed-header
      @click:row="editInterest"
    >
      <template v-slot:[`item.role`]="{ item }">
        {{ item.job.role.name }}
      </template>
      <template v-slot:[`item.department`]="{ item }">
        {{ item.job.department.name }}
      </template>
      <template v-slot:[`item.industry`]="{ item }">
        {{ item.job.industry.name }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CreateInterest from "@/components/dialogs/CreateInterest";
import { GET_INTERESTS_FOR_LEAD_USER } from "@/store/action.type";
export default {
  components: {
    CreateInterest,
  },
  props: {
    leadUserId: Number,
    type: String
  },
  data: () => ({
    dialogInterest: false,
    selectedInterest: null,
    headers: [
      { text: "Prioriteit", align: "start", value: "priority" },
      { text: "Rol", sortable: false, value: "role" },
      { text: "Afdeling", sortable: false, value: "department" },
      { text: "Industrie", sortable: false, value: "industry" },
    ],
  }),
  computed: {
    ...mapGetters("interests", ["interests", "getLoadingStates"]),
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions("interests", {
      getInterestsForLeadUser: GET_INTERESTS_FOR_LEAD_USER,
    }),
    async initialize() {
      await this.getInterestsForLeadUser(this.leadUserId);
    },
    editInterest(interest) {
      if (this.type === "searchPage") return;
      this.dialogInterest = true;
      this.selectedInterest = interest;
    },
  },
};
</script>
