import config from "../../../config";
import { authHeader } from "../../utils/auth-header";
import { handleResponse } from "../../utils/api_functions";
import { logger } from "../../utils/logger";

import { CREATE_EVENT, GET_ALL_SUB_CALENDARS } from "../action.type";
import { SET_SUB_CALENDARS } from "../mutation.type";

export const teamup = {
  namespaced: true,
  state: {
    subCalendars: [],
  },
  getters: {
    getSubCalendars: (state) => state.subCalendars,
  },

  actions: {
    async [GET_ALL_SUB_CALENDARS]({ commit }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        await fetch(`${config.apiUrl}/getSubCalendars`, request)
          .then(handleResponse)
          .then((data) => {
            commit(SET_SUB_CALENDARS, data.subcalendars);
          });
        // axios.defaults.headers.common["Authorization"] = `${
        //   authHeader().Authorization
        // }`;
        // const { data } = await axios.get(`${config.apiUrl}/getSubCalendars`);
        // commit(SET_SUB_CALENDARS, data.subcalendars);
      } catch (error) {
        logger.error(error);
      }
    },
    async [CREATE_EVENT]({ dispatch }, payload) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(payload),
        };
        await fetch(`${config.apiUrl}/saveEvent`, request).then(handleResponse);
        // axios.defaults.headers.common["Authorization"] = `${
        //   authHeader().Authorization
        // }`;
        // const { data } = await axios.post(
        //   `${config.apiUrl}/saveEvent`,
        //   payload
        // );
        dispatch("alerts/success", "TeamUp event successfully created", {
          root: true,
        });
      } catch (error) {
        dispatch("alerts/warn", "TeamUp event failed to create", {
          root: true,
        });
        logger.log(error);
      }
    },
  },
  mutations: {
    [SET_SUB_CALENDARS](state, payload) {
      state.subCalendars = payload;
    },
  },
};
