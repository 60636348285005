export function permission(p) {
  let user = localStorage.getItem("userPerm");
  user = JSON.parse(user);
  for (let i = 0; i < user.employee.employee_permissions.length; i++) {
    if (user.employee.employee_permissions[i].permissions.name == p) {
      return true;
    }
  }
  return false;
}
