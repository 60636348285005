<template>
  <v-col xl="4" lg="4" md="6">
    <v-dialog v-model="dialogAddContactMoment" max-width="700px">
      <v-card>
        <CreateContactMoment
          v-on:close-dialog="dialogAddContactMoment = false"
          :type="contactMomentDialogType"
          :parentID="lead.id"
          :leadUserName="lead.first_name + ' ' + lead.last_name"
          :email="lead.email"
          :phone="lead.tel_number"
          :formData="contactMomentToEdit"
          :lead="mainLead"
          :latestStatus="latestStatus"
        >
        </CreateContactMoment>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogContractEmail" max-width="400">
      <v-card>
        <v-card-title class="text-h6">
          Send Complete Contract Email?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialogContractEmail = false"
          >
            Disagree
          </v-btn>
          <v-btn color="green darken-1" text @click="sendContractEmail">
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container fluid class="pl-0 pr-0">
      <v-card min-height="380">
        <v-row :style="cardHeaders" class="ma-0">
          <v-row class="pa-5">
            <v-col md="10">
              <v-row>
                <v-icon medium>mdi-book-open-blank-variant</v-icon>
                <h3>
                  <strong>{{ $t("profile_page.contact_moments") }}</strong>
                </h3>
              </v-row>
            </v-col>
            <v-col md="1" align="end" class="pa-0">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    medium
                    color="primary"
                    @click="dialogContractEmail = true"
                    >mdi-email</v-icon
                  >
                </template>
                <span>Send Complete Contract Reminder</span>
              </v-tooltip>
            </v-col>
            <v-col md="1" align="end" class="pa-0">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    medium
                    @click="createNewContactMoment"
                    >mdi-plus-box</v-icon
                  >
                </template>
                <span>{{ $t("profile_page.new_contact_moment") }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-row>
        <v-row>
          <v-col class="pt-0">
            <v-card class="overflow-y-auto" max-height="340" elevation="0">
              <v-container
                v-for="item in activeLeadContactMoments"
                :key="item.id"
                fluid
              >
                <v-row>
                  <v-col md="10" class="pb-0 pt-0">
                    <h4 class="pb-1">
                      {{ item.status.name }} |
                      {{ toLocalDate(item.createdAt) }}
                      |
                      {{
                        item.communicator.first_name +
                        " " +
                        item.communicator.last_name
                      }}
                      |
                      <h4 v-if="item.vacancy">
                        Vacture: {{ item.vacancy.name }}
                      </h4>
                      <span v-if="item.vacancy_id">
                        <a @click="openVacancy(item.vacancy_id)">{{
                          $t("profile_page.open_vacancy")
                        }}</a></span
                      >
                    </h4>
                  </v-col>
                  <v-col align="end" md="2" class="pb-0 pt-0 pr-0">
                    <v-btn
                      icon
                      color="primary"
                      @click="editContactMoment(item)"
                    >
                      <v-icon medium dark>mdi-pencil-box</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col
                    ><p>
                      <span v-if="item.user_status">
                        <h5>
                          {{ $t("profile_page.contact_moment_status") }}:
                          <v-chip label :color="item.user_status.status.color"
                            ><strong>{{
                              item.user_status.status.name
                            }}</strong></v-chip
                          >
                        </h5>
                        <span>
                          <h5>Phase: {{ item.user_status.status.to_phase }}</h5>
                        </span>
                        <span
                          v-if="
                            item.user_status.lead_status_has_sub_statuses
                              .length > 0
                          "
                        >
                          <h5>
                            {{ $t("profile_page.sub_statuses") }}:
                            <div
                              v-for="item in item.user_status
                                .lead_status_has_sub_statuses"
                              :key="item.id"
                            >
                              <v-chip
                                label
                                class="mt-1"
                                :color="item.sub_status.color"
                                ><strong>{{
                                  item.sub_status.name
                                }}</strong></v-chip
                              >
                            </div>
                          </h5>
                        </span>
                      </span>
                      <span
                        ><h5>{{ $t("profile_page.notes") }}:</h5>
                        {{ item.notes }}</span
                      >
                    </p></v-col
                  ></v-row
                >
                <v-divider></v-divider>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-col>
</template>

<script>
import { mapActions } from "vuex";
import CreateContactMoment from "../../dialogs/CreateContactMoment.vue";
import { CONTRACT_REMINDER_MAIL } from "../../../store/action.type";

export default {
  props: ["cardHeaders", "user", "lead", "mainLead", "latestStatus"],
  components: {
    CreateContactMoment,
  },
  data: () => ({
    contactMomentDialogType: "0",
    dialogAddContactMoment: false,
    contactMomentToEdit: {},
    dialogContractEmail: false,
  }),
  computed: {
    activeLeadContactMoments() {
      return this.sortContactMoments(
        this.$store.state.lead_users.active_lead_user.contact_moments
      );
    },
  },
  methods: {
    ...mapActions("send_grid", {
      contractReminderMail: CONTRACT_REMINDER_MAIL,
    }),
    toLocalDate(date) {
      var utcDate = new Date(date);
      const localeString = utcDate.toLocaleString("nl-NL");
      return localeString.substring(0, localeString.length - 3);
    },
    createNewContactMoment() {
      this.contactMomentDialogType = "0";
      this.dialogAddContactMoment = true;
    },
    editContactMoment(item) {
      this.contactMomentToEdit = item;
      this.contactMomentDialogType = "1";
      this.dialogAddContactMoment = true;
    },

    sendContractEmail() {
      let userId = this.lead.id;
      let recMail = this.user.email;
      this.contractReminderMail({
        userId: userId,
        recruiter_mail: recMail,
      });
      this.dialogContractEmail = false;
    },
    sortContactMoments(array) {
      let temp = array.sort(function (a, b) {
        return b.id - a.id;
      });
      return temp;
    },
    openVacancy(id) {
      this.$router.push(`/vacature/${id}`);
    },
  },
};
</script>
