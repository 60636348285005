var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"loading":!_vm.loaded}},[_c('v-container',{staticClass:"pa-5"},[(_vm.loaded)?_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.contactMomentStatusesList,"item-text":"name","item-value":"id","required":"","rules":[(v) => !!v || _vm.$t('create_contact_moment.required_type')],"label":_vm.$t('create_contact_moment.contact_moment_type'),"persistent-hint":"","hint":_vm.$t('create_contact_moment.type_hint')},model:{value:(_vm.formDataFields.type),callback:function ($$v) {_vm.$set(_vm.formDataFields, "type", $$v)},expression:"formDataFields.type"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.vacancies_all_getter,"item-text":"name","item-value":"id","label":_vm.$t('create_contact_moment.vacancy'),"persistent-hint":"","hint":_vm.$t('create_contact_moment.vacancy_hint')},model:{value:(_vm.formDataFields.vacancy_id),callback:function ($$v) {_vm.$set(_vm.formDataFields, "vacancy_id", $$v)},expression:"formDataFields.vacancy_id"}})],1),(_vm.noStatus || _vm.type === '0')?_c('v-col',{attrs:{"md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.computedStatusesByPhase,"item-text":"name","item-value":"id","label":_vm.$t('create_contact_moment.person_status'),"persistent-hint":"","hint":_vm.$t('create_contact_moment.person_status_hint')},on:{"change":function($event){return _vm.newStatusSelected()}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_vm._v(" "+_vm._s(item.name)+" "),(
                  !_vm.showAllStatuses &&
                  _vm.lead.phase !== null &&
                  item.to_phase > _vm.lead.phase &&
                  _vm.lead.phase !== 1
                )?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-arrow-up")]):_vm._e(),(
                  !_vm.showAllStatuses &&
                  _vm.lead.phase !== null &&
                  (item.id === 37 || item.id === 38)
                )?_c('span',{staticStyle:{"color":"green"}},[_vm._v("(phase 7)")]):_vm._e(),(
                  !_vm.showAllStatuses &&
                  _vm.lead.phase !== null &&
                  (item.id === 39 || item.id === 40)
                )?_c('span',{staticStyle:{"color":"red"}},[_vm._v("(phase 8)")]):_vm._e(),(
                  !_vm.showAllStatuses &&
                  _vm.lead.phase !== null &&
                  _vm.lead.phase === 1 &&
                  item.to_phase === 3
                )?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-arrow-up")]):_vm._e()]}}],null,false,1415492385),model:{value:(_vm.data_fields_status.lead_status_id),callback:function ($$v) {_vm.$set(_vm.data_fields_status, "lead_status_id", $$v)},expression:"data_fields_status.lead_status_id"}})],1):_vm._e(),_c('v-col',{attrs:{"md":"2"}},[(_vm.type == 0)?_c('v-checkbox',{attrs:{"label":"Show All Statuses"},model:{value:(_vm.showAllStatuses),callback:function ($$v) {_vm.showAllStatuses=$$v},expression:"showAllStatuses"}}):_vm._e()],1),_c('v-col',{attrs:{"md":"4"}},[_c('v-checkbox',{attrs:{"label":_vm.$t('create_contact_moment.old_leads_flow')},model:{value:(_vm.flowChanges.oldLead),callback:function ($$v) {_vm.$set(_vm.flowChanges, "oldLead", $$v)},expression:"flowChanges.oldLead"}})],1)],1),(_vm.data_fields_status.lead_status_id != null && _vm.type === '0')?_c('v-row',{staticClass:"px-4"},[_c('v-select',{attrs:{"items":_vm.computedSubStatuses,"rules":[_vm.subStatus],"item-text":"name","item-value":"id","label":_vm.$t('create_contact_moment.sub_status'),"multiple":"","hint":_vm.$t('create_contact_moment.sub_status_hint'),"persistent-hint":""},on:{"input":_vm.limiter},model:{value:(_vm.data_fields_status.sub_statuses_ids),callback:function ($$v) {_vm.$set(_vm.data_fields_status, "sub_statuses_ids", $$v)},expression:"data_fields_status.sub_statuses_ids"}})],1):_vm._e(),(this.type == 0 && !_vm.flowChanges.oldLead)?_c('div',[(_vm.data_fields_status.lead_status_id == 3)?_c('v-card',{staticClass:"ma-4"},[_c('h4',{staticClass:"pl-4 pt-4"},[_vm._v(" "+_vm._s(_vm.$t("create_contact_moment.plan_intake"))+" ")]),_c('v-card-text',[_c('v-row',{staticClass:"pt-0"},[_c('v-col',{staticClass:"pt-0"},[_c('v-select',{attrs:{"items":_vm.employees,"label":_vm.$t('create_contact_moment.recruiter'),"rules":[
                    (v) =>
                      !!v || _vm.$t('create_contact_moment.required_recruiter'),
                  ],"required":""},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_vm._v(" "+_vm._s(item.full_name)+" ")]}},{key:"selection",fn:function({ item }){return [_vm._v(" "+_vm._s(item.full_name)+" ")]}}],null,false,3186315484),model:{value:(_vm.flowChanges.intakeRecruiter),callback:function ($$v) {_vm.$set(_vm.flowChanges, "intakeRecruiter", $$v)},expression:"flowChanges.intakeRecruiter"}})],1),_c('v-col',{staticClass:"pt-0"},[_c('DateTimePicker',{model:{value:(_vm.flowChanges.intakeDateTime),callback:function ($$v) {_vm.$set(_vm.flowChanges, "intakeDateTime", $$v)},expression:"flowChanges.intakeDateTime"}})],1)],1),_c('v-row',{staticClass:"pt-0 mt-0"},[_c('v-col',{staticClass:"pt-0"},[_c('v-select',{attrs:{"items":_vm.channelItems,"label":"Channel","required":""},model:{value:(_vm.selectedChannel),callback:function ($$v) {_vm.selectedChannel=$$v},expression:"selectedChannel"}})],1),_c('v-col',{staticClass:"pt-0"},[_c('v-checkbox',{attrs:{"label":"Add to calendar"},model:{value:(_vm.flowChanges.addToTeamUp),callback:function ($$v) {_vm.$set(_vm.flowChanges, "addToTeamUp", $$v)},expression:"flowChanges.addToTeamUp"}})],1)],1)],1)],1):_vm._e(),(!_vm.flowHasIntake && _vm.data_fields_status.lead_status_id > 3)?_c('v-card',{staticClass:"ma-4"},[_c('v-banner',{attrs:{"elevation":"3","icon":"mdi-alert","color":"warning"}},[_vm._v(_vm._s(_vm.$t("create_contact_moment.no_intake_msg")))])],1):_vm._e(),(
            _vm.data_fields_status.lead_status_id > 3 &&
            _vm.flowHasIntake &&
            !_vm.flowIntaked
          )?_c('v-card',{staticClass:"ma-4"},[_c('h4',{staticClass:"pl-4 pt-4"},[_vm._v(" "+_vm._s(_vm.$t("create_contact_moment.handle_intake"))+" ")]),_c('v-card-text',[_c('v-row',{staticClass:"pt-0"},[_c('v-col',{staticClass:"pt-0"},[_c('v-checkbox',{attrs:{"label":_vm.$t('create_contact_moment.intake_completed')},model:{value:(_vm.flowChanges.intaked),callback:function ($$v) {_vm.$set(_vm.flowChanges, "intaked", $$v)},expression:"flowChanges.intaked"}})],1)],1)],1)],1):_vm._e(),(
            _vm.data_fields_status.lead_status_id == 5 ||
            _vm.data_fields_status.lead_status_id == 16 ||
            _vm.data_fields_status.lead_status_id == 17
          )?_c('v-card',{staticClass:"ma-4"},[(!_vm.flowIntaked)?_c('v-banner',{attrs:{"elevation":"3","icon":"mdi-alert","color":"error"}},[_vm._v(_vm._s(_vm.$t("create_contact_moment.intake_not_completed_msg")))]):_vm._e(),_c('h4',{staticClass:"pl-4 pt-4"},[_vm._v(_vm._s(_vm.$t("create_contact_moment.place")))]),_c('v-card-text',[_c('v-row',{staticClass:"pt-0"},[_c('v-col',{staticClass:"pt-0"},[_c('v-select',{attrs:{"items":_vm.communityManagers,"item-text":"full_name","item-value":"id","label":_vm.$t('create_contact_moment.com_manager'),"rules":[
                    (v) =>
                      !!v || _vm.$t('create_contact_moment.required_com_manager'),
                  ],"required":""},model:{value:(_vm.flowChanges.placedCm),callback:function ($$v) {_vm.$set(_vm.flowChanges, "placedCm", $$v)},expression:"flowChanges.placedCm"}})],1),_c('v-col',{staticClass:"pt-0"},[_c('DatePicker',{model:{value:(_vm.flowChanges.placedDate),callback:function ($$v) {_vm.$set(_vm.flowChanges, "placedDate", $$v)},expression:"flowChanges.placedDate"}})],1)],1)],1)],1):_vm._e()],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-textarea',{attrs:{"filled":"","label":_vm.$t('create_contact_moment.notes')},model:{value:(_vm.data_fields.notes),callback:function ($$v) {_vm.$set(_vm.data_fields, "notes", $$v)},expression:"data_fields.notes"}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"mr-4",attrs:{"disabled":!_vm.valid || _vm.adding,"color":"success"},on:{"click":_vm.buttonClick}},[_vm._v(" "+_vm._s(_vm.types[_vm.type].buttonText)+" ")])],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }