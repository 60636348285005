<template>
  <v-container fill-width class="ma-0 pa-0" v-if="loaded">
    <v-row class="pl-4">
      <v-col md="10">
        <v-text-field
          v-model="url"
          solo
          dense
          label="ex: https://v2.cllbr.nl"
          clearable
        ></v-text-field>
      </v-col>
      <v-col md="2">
        <v-btn color="primary" dense @click="createNewWebhook">Save</v-btn>
      </v-col>
    </v-row>
    <v-data-table
      v-if="loaded"
      :headers="headers"
      :items="validItems"
      :items-per-page="5"
      class="elevation-1"
    >
      <template v-slot:[`item.status`]="{ item }">
        <v-icon v-if="item.status === 'enabled'" color="green">
          mdi-circle-medium
        </v-icon>
        <v-icon v-if="item.status === 'disabled'" color="red">
          mdi-circle-medium
        </v-icon>
      </template>
      <template v-slot:[`item.delete`]="{ item }">
        <v-icon color="red" @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  GET_ALL_WEBHOOKS,
  CREATE_WEBHOOK,
  DELETE_WEBHOOK,
} from "@/store/action.type";
export default {
  data: () => ({
    loaded: false,
    webhooks: [],
    url: "",
  }),
  computed: {
    headers() {
      let headers = [
        {
          text: "",
          value: "status",
          sortable: false,
          align: "start",
          width: "5%",
        },
        {
          text: "URL",
          value: "url",
          sortable: false,
        },
        { text: "", value: "delete", sortable: false, align: "right" },
      ];
      return headers;
    },
    validItems() {
      return this.webhooks.filter((item) => !item["deleted"]);
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions("whats_app", {
      getAllWebHooks: GET_ALL_WEBHOOKS,
      createWebhook: CREATE_WEBHOOK,
      deleteWebHook: DELETE_WEBHOOK,
    }),
    async initialize() {
      this.getAllWebHooks().then((res) => {
        this.webhooks = res.response.items;
      });
      this.loaded = true;
    },
    async deleteItem(item) {
      let params = {
        id: item.id,
      };
      await this.deleteWebHook(params);
      this.$set(item, "deleted", true);
    },

    async createNewWebhook() {
      let params = {
        url: this.url,
      };
      await this.createWebhook(params);
      this.getAllWebHooks().then((res) => {
        this.webhooks = res.response.items;
      });
    },
  },
};
</script>
