<template>
  <div v-if="activeCompany">
    <v-dialog v-model="dialogShowCompany" max-width="600px">
      <v-card>
        <CreateCompany
          v-on:close-dialog="dialogShowCompany = false"
          type="1"
          :formData="activeCompany.company"
        ></CreateCompany>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAddContactMoment" max-width="600px">
      <v-card>
        <CreateCompanyContactMoment
          v-on:close-dialog="dialogAddContactMoment = false"
          :type="contactMomentDialogType"
          :parentID="activeCompany.company.id"
          :formData="contactMomentToEdit"
        ></CreateCompanyContactMoment>
      </v-card>
    </v-dialog>
    <div class="pl-5 pr-5">
      <v-row>
        <v-col md="10">
          <h1 class="pb-1">
            <v-btn
              class="mb-2 mr-2"
              icon
              color="primary"
              @click="$router.go(-1)"
            >
              <v-icon x-large dark>mdi-chevron-left-box</v-icon> </v-btn
            >{{ activeCompany.company.name }}
          </h1>
          <p class="pb-1">
            {{
              $t("company_page.kvk_number") + ": " + activeCompany.company.kvk
            }}
          </p>
        </v-col>
        <v-col align="end" md="2">
          <v-btn icon color="primary" @click="dialogShowCompany = true">
            <v-icon large dark>mdi-pencil-box</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-card class="pa-4 mb-5" elevation="4">
        <v-row>
          <v-col>
            <v-img
              v-if="activeCompany.company.platform_name === 'DOEN'"
              max-width="100"
              src="../assets/doen.png"
            ></v-img>
            <v-img
              class="mb-1"
              v-if="activeCompany.company.platform_name === 'Zoomer'"
              max-width="100"
              src="../assets/zoomer.png"
            ></v-img>
            <h3>{{ $t("company_page.contact_info") }}</h3>
            <a :href="activeCompany.company.site_url">{{
              activeCompany.company.site_url
            }}</a>
            <p>
              {{ $t("company_page.tel") + ": " + activeCompany.company.phone }}
            </p>
            <h3>{{ $t("company_page.adress") }}</h3>
            <p>
              {{ activeCompany.company.address.street }},
              {{ activeCompany.company.address.houseno
              }}{{ activeCompany.company.address.addition }}
              <br />
              {{ activeCompany.company.address.postalcode }}
              <br />
              {{ activeCompany.company.address.region }}
            </p>
            <h3>{{ $t("company_page.description") }}</h3>
            <p>{{ activeCompany.company.description }}</p>
          </v-col>
          <v-col>
            <v-col>
              <v-row>
                <h3>{{ $t("company_page.rec_use") }}</h3>
              </v-row>
              <v-row>
                <v-checkbox
                  class="mr-2"
                  disabled
                  :input-value="activeCompany.company.name_use"
                  :label="$t('company_page.company_name')"
                ></v-checkbox>
                <v-checkbox
                  class="mr-2"
                  disabled
                  :input-value="activeCompany.company.logo_use"
                  :label="$t('company_page.logo')"
                ></v-checkbox>
                <v-checkbox
                  disabled
                  :input-value="activeCompany.company.photo_use"
                  :label="$t('company_page.photo')"
                ></v-checkbox>
              </v-row>
              <v-row style="overflow: hidden; height: 336px">
                <GoogleMap
                  :address="activeCompany.company.address"
                  @latlng-calculated="onLatlngCalculated"
                ></GoogleMap>
              </v-row>
            </v-col>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <v-divider></v-divider>
    <VacancyFlowTable></VacancyFlowTable>
    <v-divider></v-divider>
    <SubTable
      class="pa-5"
      type="0"
      v-bind:data="activeCompany.company.contact_people"
      :parentID="activeCompany.company.id"
    ></SubTable>
    <v-divider></v-divider>
    <div class="pa-5">
      <v-card elevation="4">
        <!--Contact Moments Section-->
        <v-container fluid>
          <v-expansion-panels focusable flat>
            <v-expansion-panel>
              <v-expansion-panel-header class="px-4 pt-4">
                <v-row>
                  <v-col md="10">
                    <h2>{{ $t("company_page.contact_moments") }}</h2>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="pa-3 pb-0">
                  <v-col align="end" md="12">
                    <v-btn
                      icon
                      color="primary"
                      @click="createNewCompanyContactMoment"
                    >
                      <v-icon color="primary" medium blue>mdi-plus-box</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row class="pa-3 pt-0">
                  <v-container
                    v-for="item in activeCompany.company.contact_moments
                      .slice()
                      .reverse()"
                    :key="item.id"
                    fluid
                  >
                    <v-card class="pl-6 pt-8 pr-6" elevation="4">
                      <v-row class="pb-5">
                        <v-col md="10" class="pb-0 pt-0">
                          <h3 class="pb-1">
                            <i>
                              {{ item.status.name }}
                              |
                              {{ fullName(item.communicator) }}
                              |
                              {{ toLocalDate(item.createdAt) }}
                            </i>
                          </h3>
                        </v-col>
                        <v-col align="end" md="2" class="pb-0 pt-0">
                          <v-btn
                            icon
                            color="primary"
                            @click="
                              editCompanyContactMoment(Object.assign({}, item))
                            "
                          >
                            <v-icon large dark>mdi-pencil-box</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <v-row class="pt-4">
                        <v-col md="3" lg="2">
                          <p>
                            <strong>{{
                              $t("company_page.contact_person") + ":"
                            }}</strong>
                          </p>
                        </v-col>
                        <v-col md="3" lg="4">
                          <p v-for="con in item.contact_people" :key="con.id">
                            {{ fullName(con) }}
                          </p>
                        </v-col>
                        <v-col md="3" lg="2" v-if="item.employees.length != 0">
                          <p>
                            <strong>{{
                              $t("company_page.staff") + ":"
                            }}</strong>
                          </p>
                        </v-col>
                        <v-col md="3" lg="4">
                          <p v-for="emp in item.employees" :key="emp.id">
                            {{ fullName(emp) }}
                          </p>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <div class="pt-4">
                        <v-row class="mb-n8" v-if="item.vacancy != null">
                          <v-col md="3" lg="2">
                            <p>
                              <strong>{{
                                $t("company_page.vacancy") + ":"
                              }}</strong>
                            </p>
                          </v-col>
                          <v-col md="9" lg="10">
                            <p>
                              {{ item.vacancy.name }}
                            </p>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col md="3" lg="2">
                            <p>
                              <strong>{{
                                $t("company_page.notes") + ":"
                              }}</strong>
                            </p>
                          </v-col>
                          <v-col md="9" lg="10">
                            <div v-html="item.notes" />
                          </v-col>
                        </v-row>
                      </div>
                    </v-card>
                    <v-divider></v-divider>
                  </v-container>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-container>
      </v-card>
    </div>
    <v-divider></v-divider>
    <v-container fluid class="pl-5">
      <v-col md="6">
        <v-row>
          <DocumentsSection
            :documents="activeCompany.company.documents"
            :parentID="activeCompany.company.id"
            :type="2"
          />
        </v-row>
      </v-col>
    </v-container>
  </div>
</template>

<script>
import CreateCompany from "../components/dialogs/CreateCompany.vue";
import CreateCompanyContactMoment from "../components/dialogs/CreateCompanyContactMoment.vue";
import SubTable from "../components/Tables/SubTables.vue";
import GoogleMap from "../components/Google/GoogleMap.vue";
import VacancyFlowTable from "../components/vacancy_flow/components/VacancyFlowTable.vue";
import DocumentsSection from "../components/profile/components/DocumentsSection.vue";
import { mapState } from "vuex";
import {
  GET_COMPANY,
  GET_VACANCY_FLOW_BY_COMPANY,
  UPDATE_COMPANY,
} from "../store/action.type";
import { mapActions } from "vuex";
export default {
  components: {
    SubTable,
    GoogleMap,
    CreateCompany,
    CreateCompanyContactMoment,
    DocumentsSection,
    VacancyFlowTable,
  },
  data: () => ({
    dialogShowCompany: false,
    vacanciesLoaded: false,
    contactMomentDialogType: "0",
    dialogAddContactMoment: false,
    contactMomentToEdit: {},
  }),
  computed: {
    ...mapState({
      activeCompany: (state) => state.company.activeCompany,
    }),
  },
  async created() {
    let id = this.$route.params.id;
    await this.getCompany(id);
    await this.getVacancyFlowByCompany(id);
    this.vacanciesLoaded = true;
  },
  methods: {
    ...mapActions("company", {
      updateCompany: UPDATE_COMPANY,
      getCompany: GET_COMPANY,
    }),
    ...mapActions("vacancy_flow", {
      getVacancyFlowByCompany: GET_VACANCY_FLOW_BY_COMPANY,
    }),

    async onLatlngCalculated(event) {
      let company = JSON.parse(JSON.stringify(this.activeCompany.company)); // Make copy of object
      company.address.lat = event.lat;
      company.address.lng = event.lng;
      company.address = JSON.stringify(company.address);
      await this.updateCompany({
        company,
        compId: company.id,
      });
    },

    fullName(item) {
      if (item == null || item == "") return "Niet toegewezen";
      return item.first_name + " " + item.last_name;
    },
    getContactPeopleIds(arr) {
      let ids = [];

      arr.forEach((element) => {
        ids.push(element.id);
      });

      return ids;
    },
    editCompanyContactMoment(item) {
      this.contactMomentToEdit = item;
      this.contactMomentToEdit.contact_people = this.getContactPeopleIds(
        item.contact_people
      );
      this.contactMomentDialogType = "1";
      this.dialogAddContactMoment = true;
    },
    createNewCompanyContactMoment() {
      this.contactMomentDialogType = "0";
      this.dialogAddContactMoment = true;
    },

    toLocalDate(date) {
      var utcDate = new Date(date);
      const localeString = utcDate.toLocaleString("nl-NL");
      return localeString.substring(0, localeString.length - 3);
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      if (day) return `${day}-${month}-${year}`;
    },
  },
};
</script>
