<template>
  <v-container>
    <v-form ref="form" class="pa-3">
      <v-row>
        <h3>{{ $t("file_uploader.upload_a_file") }}</h3>
      </v-row>

      <v-row align="center" justify="center">
        <v-text-field
          v-model="title"
          :label="$t('file_uploader.title')"
          :rules="[(v) => !!v || $t('file_uploader.required_title')]"
          required
        ></v-text-field>
      </v-row>
      <v-row align="center" justify="center">
        <v-file-input
          show-size
          :label="$t('file_uploader.choose_a_file')"
          @change="selectFile"
          :key="componentKey"
        ></v-file-input>
      </v-row>
      <v-row align="center" justify="center">
        <v-checkbox
          v-model="parseFile"
          :label="$t('file_uploader.parse')"
        ></v-checkbox>
      </v-row>
      <v-row align="center" justify="center">
        <v-btn
          style="margin-top: 0px; padding-top: 0px"
          color="success"
          class="mr-4"
          @click="upload"
          :disabled="disableButton"
          >{{ $t("file_uploader.upload") }}</v-btn
        >
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import {
  GET_COMPANY,
  GET_LEAD_USER_BY_ID,
  PARSE_RESUME,
} from "../../store/action.type";
export default {
  props: {
    parentID: Number,
    type: Number, //1 = Lead User Upload | 2 = Company Upload
  },
  data: () => ({
    currentFile: undefined,
    message: "",
    fileInfos: [],
    title: "",
    disableButton: false,
    parseFile: false,
    componentKey: 0,
  }),

  computed: {},

  created() {},

  methods: {
    ...mapActions("company", { getCompany: GET_COMPANY }),
    ...mapActions("lead_users", {
      getLeadUserById: GET_LEAD_USER_BY_ID,
    }),
    ...mapActions("utility", { parseResume: PARSE_RESUME }),
    selectFile(file) {
      this.currentFile = file;
    },
    async upload() {
      this.disableButton = true;
      const { dispatch } = this.$store;
      if (!this.currentFile) {
        dispatch("alerts/warn", this.$t("file_uploader.choose_a_file_warn"));
        return;
      }
      var fileToUpload = this.currentFile;

      let fileId = null;

      let uploadData = {
        lead_user_id: null,
        comp_id: null,
        title: this.title,
      };
      if (this.type == 1) {
        uploadData.lead_user_id = this.parentID;
      } else {
        uploadData.comp_id = this.parentID;
      }
      const formData = new FormData();
      formData.append("file", fileToUpload);
      await dispatch("file_upload/uploadDocument", {
        fileToUpload,
        uploadData,
      }).then((file) => {
        dispatch("alerts/success", this.$t("file_uploader.document_uploaded"));
        if (this.parseFile) {
          this.parseResume({
            docId: file.document.id,
            docUserId: this.parentID,
          }).then(() => {
            this.getLeadUserById(this.parentID);
          });
        }
        if (this.type == 1) {
          this.getLeadUserById(this.parentID).then(this.$emit("close-dialog"));
        } else {
          this.getCompany(this.parentID).then(this.$emit("close-dialog"));
        }
        this.disableButton = false;
        this.clear();
      });
    },
    clear() {
      this.componentKey++;
      this.$refs.form.reset();
    },
  },
};
</script>
