<template>
  <v-container class="pa-5">
    <v-dialog v-model="permissionsDialog" max-width="700px">
      <v-card v-if="permissionsDialog">
        <EmployeePermissions
          v-if="type === '0'"
          v-on:close-dialog="onCloseDialog"
          type="0"
          v-bind:formData="this.formData"
        ></EmployeePermissions>
        <EmployeePermissions
          v-if="type === '1'"
          v-on:close-dialog="onCloseDialog"
          type="1"
          v-bind:formData="this.formData"
        ></EmployeePermissions>
      </v-card>
    </v-dialog>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col>
          <v-text-field
            v-model="formDataFields.first_name"
            :rules="[(v) => !!v || $t('create_employee.required_first_name')]"
            :label="$t('create_employee.first_name')"
            required
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="formDataFields.last_name"
            :rules="[(v) => !!v || $t('create_employee.required_last_name')]"
            :label="$t('create_employee.last_name')"
            required
          ></v-text-field>
        </v-col>
      </v-row>

      <v-btn color="primary" @click="permissionsDialog = true"
        >Manage Permissions</v-btn
      >

      <v-text-field
        v-model="formDataFields.email"
        :rules="[(v) => !!v || $t('create_employee.required_email')]"
        :label="$t('create_employee.email')"
        :error-messages="validateEmailOrPhone()"
        :loading="adding"
      ></v-text-field>
      <v-text-field
        class="mb-2"
        v-model="formDataFields.phone"
        :label="$t('create_employee.tel')"
        :rules="[rules.phone]"
        :error-messages="validateEmailOrPhone()"
      ></v-text-field>
      <v-select
        :items="this.getSubCalendars"
        v-model="formDataFields.cal_id"
        :label="$t('create_employee.calendar_name')"
        required
        item-text="name"
        item-value="id"
        solo
      ></v-select>
      <v-autocomplete
        dense
        class="pt-1"
        :items="notifGroups"
        v-model="data_fields.Notification_Groups"
        chips
        deletable-chips
        multiple
        item-text="name"
        return-object
        :label="$t('create_employee.notif_groups')"
        ><template v-slot:selection="{ attr, on, item, selected }">
          <v-chip
            label
            v-bind="attr"
            :input-value="selected"
            v-on="on"
            close
            @click:close="remove(item)"
            ><div style="color: black">
              <strong>{{ item.name }}</strong>
            </div></v-chip
          >
        </template></v-autocomplete
      >
      <v-select
        :items="roles"
        v-model="data_fields.role"
        required
        item-text="name"
        item-value="id"
        :label="$t('create_employee.role')"
        solo
      ></v-select>
      <!-- Checkbox for deactivating an employee -->
      <v-checkbox
        class="mb-5"
        v-model="data_fields.deactivated"
        color="red"
        label="Deactivate"
      ></v-checkbox>
      <v-row justify="center">
        <v-btn
          :disabled="!valid || adding"
          color="success"
          class="mr-4"
          @click="buttonClick"
        >
          {{ types[type].buttonText }}
        </v-btn>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import EmployeePermissions from "../dialogs/EmployeePermissions.vue";
import { permission } from "../../utils/permissions";

import {
  CREATE_EMPLOYEE,
  GET_ALL_NOTIFICATION_GROUPS,
  GET_ALL_SUB_CALENDARS,
  GET_USERS,
  UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_GROUPS,
} from "../../store/action.type";
export default {
  components: { EmployeePermissions },
  props: {
    type: String,
    formData: Object,
    parentID: Number,
  },
  data: () => ({
    adding: false,
    valid: false,

    verified: false,

    permissionsDialog: false,

    data_fields: {
      role: 5,
      first_name: "",
      last_name: "",
      cal_id: 0,
      Notification_Groups: [],
      email: "",
      phone: "",
      deactivated: false,
    },
    loaded: false,
    roles: [
      { id: 0, name: "Admin" },
      { id: 1, name: "Manager" },
      { id: 2, name: "Community Manager" },
      { id: 3, name: "Recruiter" },
      { id: 4, name: "Recruiter Assistant" },
      { id: 5, name: "Geen" },
    ],
    rules: {
      email: (value) => {
        if (value != "" && value != undefined && value != null) {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Ongeldig e-mail.";
        } else return true;
      },

      phone: (value) => {
        if (value != "" && value != undefined && value != null) {
          const pattern = /^\+(?:[0-9]●?){6,14}[0-9]$/;
          return pattern.test(value) || "Moet beginnen met + en land code";
        } else return true;
      },
    },
  }),

  computed: {
    ...mapGetters("teamup", ["getSubCalendars"]),
    ...mapGetters("notifications", ["notifGroups"]),
    formDataFields() {
      this.updateFormFields();
      return this.data_fields;
    },
    types() {
      return [
        {
          buttonText: this.$t("create_employee.create_employee"),
        },
        {
          buttonText: this.$t("create_employee.customize_employee"),
        },
      ];
    },
  },

  created() {
    if (this.type == 1) {
      this.data_fields = this.formData;
      this.callGetAll();
    }
  },

  methods: {
    ...mapActions("notifications", {
      getAllNotificationGroups: GET_ALL_NOTIFICATION_GROUPS,
      updateEmployeeGroups: UPDATE_EMPLOYEE_GROUPS,
    }),
    ...mapActions("teamup", {
      getAllSubCalendars: GET_ALL_SUB_CALENDARS,
    }),
    ...mapActions("users", {
      getUsers: GET_USERS,
      createEmployee: CREATE_EMPLOYEE,
      updateEmployee: UPDATE_EMPLOYEE,
    }),
    async callGetAll() {
      await this.getAllSubCalendars();
      await this.getAllNotificationGroups();
      this.loaded = true;
    },
    updateFormFields() {
      1;
      if (this.type == 1) {
        this.data_fields = this.formData;
      }
    },

    buttonClick() {
      if (this.type == 0) {
        // Create employee
        this.create();
      } else if (this.type == 1) {
        // Edit employee
        this.editEmployee();
      }
    },

    remove(item) {
      let index = -1;
      this.data_fields.Notification_Groups.find(function (status, i) {
        if (status.name === item.name) {
          index = i;
        }
      });
      if (index >= 0) {
        this.data_fields.Notification_Groups.splice(index, 1);
      }
    },

    async editEmployee() {
      const { dispatch } = this.$store; //Const fot later use
      if (this.$refs.form.validate()) {
        //Validate form fields
        this.adding = true; //Prevent add button from being used
        let employee = {
          //Map data_fiels to json object for employee creation, this object needs to have the same names as what the API expects
          first_name: this.data_fields.first_name,
          last_name: this.data_fields.last_name,
          cal_id: this.data_fields.cal_id,
          role: this.data_fields.role,
          phone: this.data_fields.phone,
          email: this.data_fields.email,
          deactivated: this.data_fields.deactivated,
        };
        let empId = this.formData.id;
        if (permission("canCreateEmployees") || permission("isAdmin")) {
          //Only admins can add employees
          await this.updateEmployee({ employee, empId }).then(async () => {
            //Dispatch and await user creation
            const groups = this.data_fields.Notification_Groups;
            await this.updateEmployeeGroups({ empId, groups });
            dispatch(
              "alerts/success",
              this.$t("create_employee.employee_saved")
            ); //Success notification
            this.getUsers(); // Get all users, this should update the employees once user is created
            this.$emit("close-dialog");
          });
        } else {
          dispatch(
            "alerts/warn",
            this.$t("create_employee.not_authorized_msg")
          ); //Notification to say that user does not have right role (user should never get to this page but incase they do somehow)
        }
      } else {
        dispatch(
          "alerts/warn",
          this.$t("create_employee.not_all_fields_completed")
        ); //Notification that not all fields are filled in
      }
      this.adding = false;
    },

    async create() {
      const { dispatch } = this.$store; // Const for later use
      if (this.$refs.form.validate()) {
        // Validate form fields
        this.adding = true; // Prevent add button from being used
        let newEmployee = {
          // Map data_fiels to json object for employee creation, this object needs to have the same names as what the API expects
          first_name: this.data_fields.first_name,
          last_name: this.data_fields.last_name,
          cal_id: this.data_fields.cal_id,
          role: this.data_fields.role,
          phone: this.data_fields.phone,
          email: this.data_fields.email,
        };
        if (permission("canCreateEmployees") || permission("isAdmin")) {
          // Only admins can add employees
          await this.createEmployee(newEmployee).then(() => {
            dispatch(
              "alerts/success",
              this.$t("create_employee.employee_created")
            ); // Success notification
            this.getUsers(); // Get all users, this should update the employees once user is created
            this.clear(); // Clear the input fields
            this.$emit("close-dialog");
          });
        } else {
          dispatch(
            "alerts/warn",
            this.$t("create_employee.not_authorized_msg")
          ); // Notification to say that user does not have right role (user should never get to this page but incase they do somehow)
        }
      } else {
        dispatch(
          "alerts/warn",
          this.$t("create_employee.not_all_fields_completed")
        ); // Notification that not all fields are filled in
      }
      this.adding = false;
    },
    clear() {
      this.$refs.form.reset();
    },
    validateEmailOrPhone() {
      return this.formDataFields.email || this.formDataFields.phone
        ? ""
        : "Vul je telefoonnummer of email in";
    },
    onCloseDialog() {
  
      this.permissionsDialog = false;
    },
  },
};
</script>

<style scoped></style>
