<template>
  <v-card elevation="1">
    <!-- Renders a chart using ApexCharts when `isLoaded` is true to avoid creating chart before the variables are ready-->
    <apexchart
      v-if="isLoaded"
      width="100%"
      height="310"
      type="pie"
      :options="options"
      :series="series"
    ></apexchart>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import {
  GET_DASHBOARD_FLOW_LEADS,
  GET_CHANNELS,
} from "../../store/action.type";
import {
  getCurrentWeekNum,
  getSocialMediaStats,
} from "../../helpers/statisticsDataGetter";
export default {
  data: () => ({
    isLoaded: false,
    weekNr: 0,
    leadChannelsNamesWithAmountPerWeek: new Map(),
    options: {
      chart: {
        id: "basic-bar",
      },
      title: {
        text: "Social Media Channels",
        align: "center",
        margin: 30,
        offsetX: -50,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "16px",
          fontWeight: "bold",
          fontFamily: undefined,
          color: "#263238",
        },
      },

      labels: [],
    },
    colors: [
      "#008FFB",
      "#00E396",
      "#FEB019",
      "#FF0066",
      "#6B3FA0",
      "#FFD800",
      "#4DC0B5",
      "#FF4E00",
      "#2E294E",
      "#FF7F50",
      "#228B22",
    ],
    series: [],
  }),
  created() {
    this.init();
  },
  methods: {
    ...mapActions("flow", { getDashboardFlowLeads: GET_DASHBOARD_FLOW_LEADS }),
    ...mapActions("leads", { getChannels: GET_CHANNELS }),

    async init() {
      const week = getCurrentWeekNum();
      const year = new Date().getFullYear();
      const channels = await this.getChannels();
      await this.getDashboardFlowLeads({
        week,
        year,
      }).then((res) => {
        let socialMediaStats = getSocialMediaStats(channels.lead_channels, res);
        socialMediaStats.forEach((key, value) => {
          this.options.labels.push(value);
          this.series.push(key);
        });
        this.isLoaded = true;
      });
    },
  },
};
</script>
