<template>
  <v-container fluid>
    <v-card class="pa-8">
      <v-row class="pa-3">
        <v-col md="4">
          <v-row>
            <h2>{{ $t("error_panel_page.errors") }}</h2>
            <v-divider
              vertical
              inset
              style="padding-left: 10px; padding-left: 10px"
            ></v-divider>
            <v-text-field
              style="padding-top: 0px; padding-left: 10px"
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-row>
        </v-col>
        <v-col align="end">
          <v-btn color="error" @click="deleteAllClientLogs()">
            {{ $t("error_panel_page.delete_all") }}
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        style="white-space: pre-line"
        :headers="headers"
        :items="logList"
        :search="search"
        :sort-by="['updatedAt']"
        :sort-desc="[true]"
        :items-per-page="5"
        class="elevation-1"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn color="error" @click="deleteClientLog(item.id)">
            {{ $t("error_panel_page.delete") }}
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { GET_LOGS, DELETE_LOG, DELETE_ALL_LOGS } from "../store/action.type";
import { log } from "../utils/logger";
export default {
  data() {
    return {
      isLoading: true,
      search: "",
      dialog: false,
    };
  },
  computed: {
    headers() {
      let headers = [
        {
          text: this.$t("error_panel_page.info"),
          value: "info",
        },
        {
          text: this.$t("error_panel_page.user"),
          value: "owner",
        },
        {
          text: this.$t("error_panel_page.date"),
          value: "updatedAt",
        },
        {
          text: this.$t("error_panel_page.actions"),
          value: "actions",
        },
      ];
      return headers;
    },
    logList() {
      return this.errorLogs.map((e) => {
        var mapped = {
          id: e.id,
          type: e.type,
          info: "\n" + e.data + "\n\n",
          owner: e.employee.first_name + " " + e.employee.last_name,
          updatedAt: this.formatDate(e.updatedAt),
        };

        return mapped;
      });
    },
    ...mapState({
      errorLogs: (state) => state.utility.errorLogs,
    }),
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions("utility", {
      getLogs: GET_LOGS,
      deleteLog: DELETE_LOG,
      deleteAllLogs: DELETE_ALL_LOGS,
    }),
    async initialize() {
      await this.getLogs();
    },
    formatDate(date) {
      return date.slice(0, 10);
    },
    // Delete client log
    async deleteClientLog(logId) {
      const { dispatch } = this.$store;
      await this.deleteLog(logId).then(() => {
        dispatch(
          "alerts/success",
          this.$t("error_panel_page.success_deletion")
        );
        this.dialog = false;
      });
    },
    async deleteAllClientLogs() {
      const { dispatch } = this.$store;
      await this.deleteAllLogs().then(() => {
        dispatch(
          "alerts/success",
          this.$t("error_panel_page.success_deletion_all")
        );
        this.dialog = false;
      });
    },
  },
};
</script>
