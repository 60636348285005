<template>
  <v-container fluid class="pa-5">
    <v-bottom-sheet v-model="flowSpeak.dialogSpeak" inset>
      <v-sheet class="text-center">
        <SpokenDatePicker
          v-on:close-dialog="flowSpeak.dialogSpeak = false"
          :type="flowSpeak.type"
          :leadID="flowSpeak.activeLeadId"
          :leadUserID="flowSpeak.activeLeadUserId"
          :leadName="flowSpeak.activeLeadName"
          :leadContactNo="flowSpeak.activeLeadContactNo"
          :vmCount="flowSpeak.activeLeadVmCount"
          :additional="flowSpeak.params"
        />
      </v-sheet>
    </v-bottom-sheet>
    <v-dialog v-model="dialogFilters" max-width="900px">
      <v-card>
        <VacancySearchTerms
          v-on:close-dialog="dialogFilters = false"
          :parentID="parentID"
          :searchFilters="filters"
          :leadStatuses="this.lead_statuses"
          :vacancy_job="this.vacancy_job"
        ></VacancySearchTerms>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogColumns" max-width="600px">
      <SelectColumns
        :headers="headers"
        type="Vacancy"
        v-on:headers-changed="setHeaders($event)"
        v-on:close-dialog="dialogColumns = false"
      />
    </v-dialog>
    <v-card class="pa-4" elevation="4">
      <v-row class="pb-0">
        <v-col md="6">
          <v-row class="pa-3">
            <h2>{{ $t("vacancy_recommend.matching") }}</h2>
            <v-divider
              vertical
              inset
              style="padding-left: 10px; padding-left: 10px"
            ></v-divider>
            <v-text-field
              style="padding-top: 0px; padding-left: 10px"
              v-model="basicSearch"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-row>
        </v-col>
        <v-col md="2" class="mt-1 pt-0">
          <v-checkbox
            v-model="distanceFilter"
            label="Filter op radius (duurt even)"
            color="primary"
            hide-details
          ></v-checkbox>
        </v-col>

        <v-col align="end" md="4">
          <v-btn
            icon
            color="primary"
            class="mb-2"
            @click="dialogColumns = true"
          >
            <v-icon dark>mdi-bookmark</v-icon>
          </v-btn>
          <v-btn
            :loading="!getLoading"
            @click="openFilterDialog()"
            color="primary"
            class="ml-4"
            >{{ $t("vacancy_recommend.matching_filters") }}</v-btn
          >
        </v-col>
      </v-row>

      <v-row>
        <v-col align="center" md="5">
          <v-slider
            label="Min. Matching Score"
            :max="matchPointMax"
            :min="matchPointMin"
            v-model="matchPointVal"
            thumb-label
          ></v-slider>
        </v-col>
      </v-row>

      <v-progress-linear
        v-if="!getLoading"
        color="primary"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>

      <v-data-table
        v-if="getLoading === true && getDisplayData === true"
        :headers="headers"
        :search="basicSearch"
        :items="leadsUserFilteredList"
        multi-sort
        :items-per-page="5"
        :sort-by="['matchingScore']"
        :sort-desc="[true]"
        class="elevation-1"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="columnSelectOpen(item)">
            mdi-open-in-new
          </v-icon>
        </template>
        <template v-slot:[`item.lead_status_name`]="{ item }">
          <v-chip label :color="item.lead_status_color"
            ><div style="color: black">
              <strong>{{ item.lead_status_name }}</strong>
            </div></v-chip
          >
        </template>
        <template v-slot:[`item.matchingScore`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                label
                class="ma-2"
                v-bind="attrs"
                v-on="on"
                :color="
                  item.matchingScore > 8
                    ? 'green'
                    : item.matchingScore > 4
                    ? 'orange'
                    : 'red'
                "
                text-color="white"
              >
                {{ calculateMatchingScore(item) }}
              </v-chip>
            </template>
            <li v-for="item in item.matchOn" :key="item">
              {{ item }}
            </li>
          </v-tooltip>
        </template>
        <template v-slot:[`item.has_driverslicense`]="{ item }">
          <v-checkbox
            :input-value="item.has_driverslicense"
            value
            disabled
          ></v-checkbox>
        </template>
        <template v-slot:[`item.has_car`]="{ item }">
          <v-checkbox :input-value="item.has_car" value disabled></v-checkbox>
        </template>
        <template v-slot:[`item.distanceFromVacancy`]="{ item }">
          <v-chip
            label
            class="ma-2"
            :color="
              item.distanceFromVacancy == 499999 || !distanceFilter
                ? 'transparent'
                : item.distanceFromVacancy == 0 || item.distanceFromVacancy == 1
                ? 'orange'
                : 'secondary'
            "
          >
            {{ convertMetersToKm(item.distanceFromVacancy) }}
          </v-chip>
        </template>
        <template v-slot:[`item.currentJob`]="{ item }">
          <v-checkbox
            :input-value="filterCurrentJob(item.currentJob)"
            color="success"
            value
            @click.stop
            readonly
          ></v-checkbox>
        </template>
        <template v-slot:[`item.previousJob`]="{ item }">
          <v-checkbox
            :input-value="filterPreviousJob(item.previousJob)"
            color="success"
            value
            @click.stop
            readonly
          ></v-checkbox>
        </template>
        <template v-slot:[`item.educationCv`]="{ item }">
          <v-checkbox
            :input-value="filterEducation(item.educationCv)"
            color="success"
            value
            @click.stop
            readonly
          ></v-checkbox>
        </template>
        <template v-slot:[`item.restCv`]="{ item }">
          <v-checkbox
            :input-value="filterRestOfCv(item.restCv)"
            color="success"
            value
            @click.stop
            readonly
          ></v-checkbox>
        </template>
        <template v-slot:[`item.notes`]="{ item }">
          <v-checkbox
            :input-value="filterNotes(item.notes)"
            color="success"
            value
            @click.stop
            readonly
          ></v-checkbox>
        </template>
        <template v-slot:[`item.interestsRoles`]="{ item }">
          <v-checkbox
            :input-value="filterInterestsRoles(item.interestsRoles)"
            color="success"
            value
            @click.stop
            readonly
          ></v-checkbox>
        </template>
        <template v-slot:[`item.interestsIndustries`]="{ item }">
          <v-checkbox
            :input-value="filterInterestsIndustries(item.interestsIndustries)"
            color="success"
            value
            @click.stop
            readonly
          ></v-checkbox>
        </template>
        <template v-slot:[`item.interestsDepratments`]="{ item }">
          <v-checkbox
            :input-value="filterInterestsDepratments(item.interestsDepratments)"
            color="success"
            value
            @click.stop
            readonly
          ></v-checkbox>
        </template>
        <template v-slot:[`item.favorite`]="{ item }">
          <v-icon v-if="item.favorite" color="yellow" large>mdi-star</v-icon>
          <v-icon v-else large>mdi-star-outline</v-icon>
        </template>
        <template v-slot:[`item.first_date`]="{ item }">
          <span v-if="item.first_date != null">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="green" dark v-bind="attrs" v-on="on">
                  mdi-phone
                </v-icon>
              </template>
              <span>{{ item.first_date }}</span>
            </v-tooltip>
          </span>
          <v-badge
            v-else-if="item.vm_date != null"
            bordered
            color="error"
            :content="item.vm_count"
            overlap
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  elevation="0"
                  color="error"
                  small
                  @click="openFlowSpoken(item)"
                  class="pl-1 pr-3"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-phone-missed</v-icon>
                </v-btn>
              </template>
              <span>{{ item.vm_date }}</span>
            </v-tooltip>
          </v-badge>
          <v-btn
            v-else
            elevation="0"
            color="primary"
            small
            @click="openFlowSpoken(item)"
            icon
          >
            <v-icon color="red" dark> mdi-phone </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import VacancySearchTerms from "../../components/dialogs/VacancySearchTerms.vue";
import SpokenDatePicker from "../../components/dialogs/SpokenDatePicker";
import SelectColumns from "../../components/dialogs/SelectColumns.vue";
import { log } from "../../utils/logger";
import {
  SET_LEAD_USERS_DISPLAY_DATA,
  SET_LEAD_USERS_LOADING,
  SET_LEAD_USERS_RELOAD,
} from "../../store/mutation.type";
import {
  GET_CHANNELS,
  GET_DISTANCE_BY_CITIES_IN_BULK,
  GET_LEAD_STATUSES,
  GET_VACANCY_FILTER_BY_ID,
  UPDATE_EMPLOYEE,
  UPDATE_VACANCY_FILTER,
} from "../../store/action.type";
export default {
  components: {
    SpokenDatePicker,
    VacancySearchTerms,
    SelectColumns,
  },
  props: {
    parentID: Number,
    vacancy_filters: Object,
    vacancy_address: Object,
    vacancy_job: Object,
  },
  data: () => ({
    basicSearch: "",
    dialogFilters: false,
    matchPointMax: 31,
    matchPointMin: 0,
    matchPointVal: 1,
    distanceFilter: false,
    dialogColumns: false,
    selectedHeaders: [],
    filters: {
      statuses: [],
      id: null,
      has_car: null,
      has_license: null,
      gender: [],
      range: [10, 70],
      search: "",
      selectedRadius: 500000,
    },
    genderSelect: ["Man", "Vrouw", "Transgender", "Non-binair"],
    companies: [],
    lead_statuses: [],
    flowSpeak: {
      activeLeadId: null,
      activeLeadUserId: null,
      activeLeadName: null,
      activeLeadContactNo: null,
      activeLeadVmCount: null,
      type: null,
      searchTerm: null,
      dialogSpeak: false,
    },
  }),

  computed: {
    headers() {
      let headers = [
        {
          id: 1,
          text: this.$t("vacancy_recommend.action"),
          value: "actions",
          sortable: false,
          align: "start",
          perma: true,
        },
        {
          id: 2,
          text: this.$t("vacancy_recommend.match"),
          align: "start",
          sortable: true,
          value: "matchingScore",
          perma: true,
          filter: (value) => {
            return value >= this.matchPointVal;
          },
        },
        {
          id: 3,
          text: this.$t("vacancy_recommend.favorite"),
          value: "favorite",
          perma: true,
        },
        {
          id: 4,
          text: this.$t("vacancy_recommend.name"),
          align: "start",
          sortable: true,
          value: "name",
          perma: true,
        },
        {
          id: 5,
          text: this.$t("vacancy_recommend.status"),
          sortable: true,
          value: "lead_status_name",
          align: " d-none",
        },
        {
          id: 6,
          text: this.$t("vacancy_recommend.gender"),
          sortable: true,
          value: "gender",
          align: " d-none",
        },
        {
          id: 7,
          text: this.$t("vacancy_recommend.region"),
          sortable: true,
          value: "region",
          align: " d-none",
        },
        {
          id: 8,
          text: this.$t("vacancy_recommend.drivers_license"),
          sortable: true,
          value: "has_driverslicense",
          align: " d-none",
        },
        {
          id: 9,
          text: this.$t("vacancy_recommend.car"),
          sortable: true,
          value: "has_car",
          align: " d-none",
        },
        {
          id: 10,
          text: this.$t("vacancy_recommend.dob"),
          value: "dob",
          sortable: true,
          align: " d-none",
        },
        {
          id: 11,
          text: this.$t("vacancy_recommend.user_region"),
          sortable: true,
          value: "user_region",
          align: " d-none",
        },
        {
          id: 12,
          text: this.$t("vacancy_recommend.contract_term"),
          sortable: true,
          value: "contract_term",
          align: " d-none",
        },
        {
          id: 13,
          text: this.$t("vacancy_recommend.rec"),
          value: "recruiter_name",
          sortable: true,
          align: " d-none",
        },
        {
          id: 14,
          text: this.$t("vacancy_recommend.rec_ass"),
          value: "recruitment_assistant_name",
          sortable: true,
          align: " d-none",
        },
        {
          id: 15,
          text: this.$t("vacancy_recommend.channel"),
          value: "channel",
          align: " d-none",
        },
        {
          id: 16,
          text: this.$t("vacancy_recommend.gender"),
          value: "gender",
          align: " d-none",
        },
        {
          id: 17,
          text: this.$t("vacancy_recommend.curent_job"),
          value: "currentJob",
          align: " d-none",
        },
        {
          id: 18,
          text: this.$t("vacancy_recommend.previous_job"),
          value: "previousJob",
          align: " d-none",
        },
        {
          id: 19,
          text: this.$t("vacancy_recommend.education"),
          value: "educationCv",
          align: " d-none",
        },
        {
          id: 20,
          text: this.$t("vacancy_recommend.rest"),
          value: "restCv",
          align: " d-none",
        },
        {
          id: 21,
          text: this.$t("vacancy_recommend.notes"),
          value: "notes",
          align: " d-none",
        },
        {
          id: 22,
          text: this.$t("vacancy_recommend.roles"),
          value: "interestsRoles",
          align: " d-none",
        },
        {
          id: 23,
          text: this.$t("vacancy_recommend.industries"),
          value: "interestsIndustries",
          align: " d-none",
        },
        {
          id: 24,
          text: this.$t("vacancy_recommend.departments"),
          value: "interestsDepratments",
          align: " d-none",
        },
        {
          id: 25,
          text: this.$t("vacancy_recommend.distance"),
          value: "distanceFromVacancy",
          align: " d-none",
          filter: (value) => {
            if (!this.distanceFilter) return true;
            else {
              return value <= this.filters.selectedRadius;
            }
          },
        },
        {
          id: 26,
          text: this.$t("vacancy_recommend.bellen"),
          value: "first_date",
          sortable: false,
          perma: true,
        },
      ];
      for (let i = 0; i < headers.length; i++) {
        if (this.selectedHeaders.some((elem) => headers[i].id === elem)) {
          headers[i].align = "";
        }
      }
      return headers;
    },
    leadsUserFilteredList() {
      return this.lead_users.map((e) => {
        var mapped = {
          id: e.id,
          address: e.address,
          user_region: e.region,
          name: `${e.first_name} ${e.last_name}`,
          gender: this.itemExists(e.gender),
          dob: this.itemExists(this.formatDate(e.dob)),
          age: this.getAge(this.itemExists(e.dob)),
          tel_number: this.itemExists(e.tel_number),
          region: this.itemExists(e.region),
          has_car: e.car,
          channel: this.itemExists(e.leads[e.leads.length - 1].channel).name,
          has_driverslicense: e.drivers_license,
          rec_id: this.itemExists(e.leads[e.leads.length - 1].recruiter).id,
          recruiter_name: this.fullName(e.leads[e.leads.length - 1].recruiter),
          rec_ass_id: this.itemExists(
            e.leads[e.leads.length - 1].recruitment_assistant
          ).id,
          recruitment_assistant_name: this.fullName(
            e.leads[e.leads.length - 1].recruitment_assistant
          ),
          active_lead_id: this.itemExists(e.leads[e.leads.length - 1].id),
          lead_status_name: this.latestStatus(e).name,
          lead_status_color: this.latestStatus(e).color,
          contract_term: this.itemExists(e.contract_term),
          contract_type: this.itemExists(e.contract_type),
          sal_indication: this.itemExists(e.sal_indication),
          email: this.itemExists(e.email),
          applied_date: this.formatDate(
            e.leads[e.leads.length - 1].applied_date
          ),
          flow: false,
          distanceFromVacancy: 499999,
          matchingScore: e.matchingScore,
          favorite: this.favoriteExists(e.favorite),
          matchOn: e.matchOn,
          //Values for checkboxes
          currentJob: e.matchOn,
          previousJob: e.matchOn,
          educationCv: e.matchOn,
          restCv: e.matchOn,
          notes: e.matchOn,
          interestsRoles: e.matchOn,
          interestsIndustries: e.matchOn,
          interestsDepratments: e.matchOn,
        };

        if (e.leads[e.leads.length - 1].flow != null) {
          mapped.first_date = this.toLocalDate(
            e.leads[e.leads.length - 1].flow.first_date
          );
          mapped.vm_date = this.toLocalDate(
            e.leads[e.leads.length - 1].flow.vm_date
          );
          mapped.intake_date = this.toLocalDate(
            e.leads[e.leads.length - 1].flow.intake_date
          );
          mapped.intake_completed =
            e.leads[e.leads.length - 1].flow.intake_completed;
          mapped.placed_date = this.toLocalDate(
            e.leads[e.leads.length - 1].flow.placed_date
          );
          mapped.rec = e.leads[e.leads.length - 1].flow.rec;
          mapped.rec_full_name = this.fullName(
            e.leads[e.leads.length - 1].flow.rec
          );
          mapped.rec_ass_full_name = this.fullName(
            e.leads[e.leads.length - 1].flow.rec_ass
          );
          mapped.placed_cm = e.leads[e.leads.length - 1].flow.placed_cm;
          mapped.vm_count = e.leads[e.leads.length - 1].flow.vm_count;
          mapped.flow = true;
        }

        return mapped;
      });
    },
    ...mapState({
      lead_users: (state) => state.lead_users.recommended_lead_users,
      stored_filters: (state) => state.leads.filter,

      employees: (state) =>
        state.filters.lists.employees
          .filter((e) => e.first_name != null)
          .sort((a, b) => {
            if (a.first_name < b.first_name) {
              return -1;
            }
            if (a.first_name > b.first_name) {
              return 1;
            }
            return 0;
          })
          .map((e) => {
            return {
              id: e.id,
              full_name: e.first_name + " " + e.last_name,
            };
          }),
      currentUser: (state) => state.users.user,
    }),
    ...mapGetters("lead_users", [
      "getLoading",
      "getDisplayData",
      "getReloadDistanceFilter",
    ]),
    ...mapGetters("utility", ["getDistanceBetweenCitiesBulk"]),
  },

  created() {
    this.filters.search = this.vacancy_filters.search_term;
    this.filters.id = this.vacancy_filters.id;
    this.filters.statuses = JSON.parse(this.vacancy_filters.statuses);
    this.filters.has_car = this.vacancy_filters.has_car;
    this.filters.has_license = this.vacancy_filters.has_license;
    this.filters.gender = JSON.parse(this.vacancy_filters.genders);
    this.filters.range[0] = this.vacancy_filters.min_age;
    this.filters.range[1] = this.vacancy_filters.max_age;
    this.filters.selectedRadius = this.vacancy_filters.radius;
    this.initialize();
  },

  watch: {
    getReloadDistanceFilter: function () {
      if (this.distanceFilter) {
        this.bindDistance();
      } else {
        this.setDisplayData(true);
        this.setLoading(true);
      }
    },
    distanceFilter: function () {
      if (this.distanceFilter) {
        this.bindDistance();
      }
    },
  },

  methods: {
    ...mapActions("leads", { getChannels: GET_CHANNELS }),
    ...mapActions("statuses", {
      getLeadStatuses: GET_LEAD_STATUSES,
    }),
    ...mapActions("users", {
      updateEmployee: UPDATE_EMPLOYEE,
    }),
    ...mapActions("utility", {
      getDistanceByCitiesInBulk: GET_DISTANCE_BY_CITIES_IN_BULK,
    }),
    ...mapActions("vacancy_filters", {
      getVacancyFilterById: GET_VACANCY_FILTER_BY_ID,
      updateVacancyFilter: UPDATE_VACANCY_FILTER,
    }),
    ...mapMutations("lead_users", {
      setLeadUsersLoading: SET_LEAD_USERS_LOADING,
      setLeadUsersDisplayData: SET_LEAD_USERS_DISPLAY_DATA,
      setLeadUsersReload: SET_LEAD_USERS_RELOAD,
    }),
    async initialize() {
      this.setDisplayData(false);
      this.setLoading(false);
      if (this.currentUser.vacancy_columns !== null) {
        this.selectedHeaders = JSON.parse(this.currentUser.vacancy_columns);
      } else {
        let employee = this.currentUser;
        let empId = this.currentUser.id;
        employee.vacancy_columns = JSON.stringify(this.selectedHeaders);
        await this.updateEmployee({ employee, empId });
      }

      await this.getChannels().then((res) => {
        this.channels = res.lead_channels;
      });

      await this.getLeadStatuses().then((res) => {
        this.lead_statuses = res.lead_statuses;
      });

      this.fetchUsers();
    },
    async fetchUsers() {
      const { dispatch } = this.$store;
      let search = this.filters.search;
      let status = [];
      for (let i = 0; i < this.filters.statuses.length; i++) {
        status.push(this.filters.statuses[i].name);
      }

      let params = {
        searchData: search,
        status: JSON.stringify(status),
        car: this.getCar(this.filters.has_car),
        license: this.getLicense(this.filters.has_license),
        gender: JSON.stringify(this.filters.gender),
        age: JSON.stringify(this.filters.range),
        job: JSON.stringify(this.vacancy_job),
      };
      this.setDisplayData(true);
      this.setLoading(true);
    },
    async bindDistance() {
      this.setDisplayData(false);
      this.setLoading(false);
      let payload = [];
      for (let i = 0; i < this.leadsUserFilteredList.length; i++) {
        let temp = {
          id: this.leadsUserFilteredList[i].id,
          origin: this.vacancy_address.region.trim(),
          destination: this.getRegion(this.leadsUserFilteredList[i].address),
          distanceFromCity: this.leadsUserFilteredList[i].distanceFromVacancy,
        };
        payload.push(temp);
      }
      await this.getDistanceByCitiesInBulk(payload);
      for (let i = 0; i < this.leadsUserFilteredList.length; i++) {
        for (let x = 0; x < this.getDistanceBetweenCitiesBulk.length; x++) {
          if (
            this.leadsUserFilteredList[i].id ==
            this.getDistanceBetweenCitiesBulk[x].id
          ) {
            this.leadsUserFilteredList[i].distanceFromVacancy =
              this.getDistanceBetweenCitiesBulk[x].distanceFromCity;
          }
        }
      }
      this.dialogFilters = false;
      this.setDisplayData(true);
      this.setLoading(true);
    },

    getRegion(e) {
      if (e !== null) {
        if (
          typeof JSON.parse(e).region !== "undefined" &&
          JSON.parse(e).region !== null
        ) {
          return JSON.parse(e).region.trim();
        }
      }
      return "";
    },
    getCar(car) {
      if (car === null) {
        return 2;
      }
      if (car) {
        return 1;
      } else {
        return 0;
      }
    },
    getLicense(license) {
      if (license === null) {
        return 2;
      }
      if (license) {
        return 1;
      } else {
        return 0;
      }
    },
    async setLoading(bool) {
      this.setLeadUsersLoading(bool);
    },
    async setDisplayData(bool) {
      this.setLeadUsersDisplayData(bool);
    },
    async setReloadFilter(bool) {
      this.setLeadUsersReload(bool);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    toLocalDate(date) {
      if (!date) return null;
      var utcDate = new Date(date);
      const localeString = utcDate.toLocaleString("nl-NL");
      return localeString.substring(0, localeString.length - 3);
    },
    getAge(dateString) {
      if (dateString === "Onbekend") {
        return 0;
      }
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    fullName(item) {
      if (item == null || item == "") return "Niet toegewezen";
      return item.first_name + " " + item.last_name;
    },
    //Calculates matching score based on distance
    calculateMatchingScore(item) {
      if (this.distanceFilter) {
        if (item.distanceFromVacancy <= 20000) {
          return item.matchingScore;
        }
        if (
          item.distanceFromVacancy >= 20000 &&
          item.distanceFromVacancy <= 25000
        ) {
          return Math.floor(item.matchingScore * 0.9);
        }
        if (
          item.distanceFromVacancy >= 25000 &&
          item.distanceFromVacancy <= 30000
        ) {
          return Math.floor(item.matchingScore * 0.8);
        }
        if (
          item.distanceFromVacancy >= 30000 &&
          item.distanceFromVacancy <= 35000
        ) {
          return Math.floor(item.matchingScore * 0.7);
        }
        if (
          item.distanceFromVacancy >= 35000 &&
          item.distanceFromVacancy <= 40000
        ) {
          return Math.floor(item.matchingScore * 0.6);
        }
        if (item.distanceFromVacancy >= 40000) {
          return Math.floor(item.matchingScore * 0.5);
        }
      } else {
        return item.matchingScore;
      }
    },
    columnSelectOpen(item) {
      let routeData = this.$router.resolve({
        path: `/profiel/${item.id}`,
      });
      window.open(routeData.href, "_blank");
    },
    openFlowSpoken(item) {
      this.flowSpeak.activeLeadId = item.active_lead_id;
      this.flowSpeak.activeLeadUserId = item.id;
      this.flowSpeak.activeLeadName = item.name;
      this.flowSpeak.activeLeadContactNo = item.tel_number;
      this.flowSpeak.activeLeadVmCount = item.vm_count;
      let search = this.filters.search;
      let status = [];
      for (let i = 0; i < this.filters.statuses.length; i++) {
        status.push(this.filters.statuses[i].name);
      }
      let params = {
        searchData: search,
        status: JSON.stringify(status),
        car: this.getCar(this.filters.has_car),
        license: this.getLicense(this.filters.has_license),
        gender: JSON.stringify(this.filters.gender),
        age: JSON.stringify(this.filters.range),
        job: JSON.stringify(this.vacancy_job),
      };
      this.flowSpeak.type = 3;
      this.flowSpeak.params = JSON.stringify(params);

      this.flowSpeak.dialogSpeak = true;
    },
    itemExists(item) {
      if (item == null || item == "") return "Onbekend";
      return item;
    },
    favoriteExists(item) {
      if (item == null || item == "") return 0;
      return item;
    },

    latestStatus(item) {
      if (item.lead_statuses.length > 0) {
        item.lead_statuses.sort(function (a, b) {
          return a.id - b.id;
        });
        return item.lead_statuses[item.lead_statuses.length - 1].status;
      } else {
        return { name: "Geen", color: "#FFD700" };
      }
    },

    convertMetersToKm(meters) {
      if (!this.distanceFilter) {
        return "-";
      }
      if (meters == 0) {
        return "Same City";
      } else if (meters == 1) {
        return "Same City";
      } else if (meters == 499999) {
        return "-";
      }
      var km = meters / 1000;
      return km.toFixed(1) + " km";
    },
    openFilterDialog() {
      this.dialogFilters = true;
    },
    setHeaders(e) {
      this.selectedHeaders = e;
    },
    filterCurrentJob(item) {
      if (item.includes("CV - Current Job/s")) {
        return true;
      } else {
        return false;
      }
    },
    filterPreviousJob(item) {
      if (item.includes("CV - Previous Job/s")) {
        return true;
      } else {
        return false;
      }
    },
    filterEducation(item) {
      if (item.includes("CV - Education")) {
        return true;
      } else {
        return false;
      }
    },
    filterRestOfCv(item) {
      if (item.includes("CV - Rest")) {
        return true;
      } else {
        return false;
      }
    },
    filterNotes(item) {
      if (item.includes("Notes")) {
        return true;
      } else {
        return false;
      }
    },
    filterInterestsRoles(item) {
      if (item.includes("Interests - Roles")) {
        return true;
      } else {
        return false;
      }
    },
    filterInterestsIndustries(item) {
      if (item.includes("Interests - Industries")) {
        return true;
      } else {
        return false;
      }
    },
    filterInterestsDepratments(item) {
      if (item.includes("Interests - Depratments")) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
