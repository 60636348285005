<template>
  <v-card>
    <v-container>
      <v-text-field
        background-color="cardFields"
        outlined
        dense
        required
        :rules="requiredRules"
        label="Email Template Name"
        v-model="emailTemplateName"
        class="mt-4"
      ></v-text-field>

      <tiptap-vuetify v-model="content" :extensions="extensions" />
      <v-row class="ma-0 mt-4">
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="create()">Save</v-btn>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import { CREATE_EMAIL_TEMPLATE } from "../../../store/action.type";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";
export default {
  props: { activeTemplate: Object },
  data: () => ({
    emailTemplateName: "",
    content: "",
    requiredRules: [(v) => !!v || "Field is required"],
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
    ],
  }),
  components: { TiptapVuetify },
  methods: {
    ...mapActions("email_template", {
      createEmailTemplate: CREATE_EMAIL_TEMPLATE,
    }),
    async create() {
      let email_template = {
        title: this.emailTemplateName,
        body: this.content,
      };
      await this.createEmailTemplate({
        email_template,
      });
      this.emailTemplateName = "";
      this.content = "";
      this.$emit("close-dialog");
      this.$emit("reload-table");
    },
  },
};
</script>
<style>
.ProseMirror {
  min-height: 200px;
  overflow-y: auto;
}
</style>
