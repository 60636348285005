<template>
  <v-card :loading="!loaded">
    <v-dialog v-model="userExistsDialog" max-width="550">
      <v-card>
        <v-card-title class="text-h6">
          {{ $t("create_lead.user_exists") }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="userExistsDialog = false">{{
            $t("create_lead.cancel")
          }}</v-btn>
          <v-btn color="primary" text @click="createLead()">{{
            $t("create_lead.create_lead")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container class="pa-5">
      <v-form ref="form" v-model="valid" lazy-validation v-if="loaded">
        <v-container>
          <v-row>
            <v-col v-if="!editMode">
              <v-switch
                v-model="userSwitch"
                :label="$t('create_lead.use_existing')"
                @change="useExistingLeads"
              ></v-switch>
            </v-col>
            <v-col>
              <v-autocomplete
                v-if="userSwitch && loadedUsers"
                :loading="!loadedUsers"
                :items="lead_users"
                :rules="[(v) => !!v || $t('create_lead.required_person')]"
                v-model="formDataFields.lead_user"
                required
                :item-text="fullName"
                return-object
                :label="$t('create_lead.person')"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row v-if="!userSwitch" class="mt-0">
            <v-container>
              <h2>{{ $t("create_lead.new_person") }}</h2>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="data_fields_user.first_name"
                    :rules="[
                      (v) => !!v || $t('create_lead.required_first_name'),
                    ]"
                    :label="$t('create_lead.first_name')"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="data_fields_user.last_name"
                    :rules="[
                      (v) => !!v || $t('create_lead.required_last_name'),
                    ]"
                    :label="$t('create_lead.last_name')"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col>
                  <v-text-field
                    v-model="data_fields_user.email"
                    :label="$t('create_lead.email')"
                    :error-messages="validateEmailOrPhone()"
                    :rules="[rules.email]"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="data_fields_user.tel_number"
                    :label="$t('create_lead.tel')"
                    :error-messages="validateEmailOrPhone()"
                    :rules="[rules.phone]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-row>
          <v-divider />
        </v-container>
        <v-row>
          <v-col>
            <v-autocomplete
              :items="vacancies_all_getter"
              :rules="[(v) => !!v || $t('create_lead.required_vacancy')]"
              v-model="formDataFields.vacancy_id"
              required
              :item-text="vacanyWithCompanyName"
              item-value="id"
              :label="$t('create_lead.vacancy')"
              @change="updateRecruiter(formDataFields.vacancy_id)"
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              :items="channels"
              :rules="[(v) => !!v || $t('create_lead.required_source')]"
              v-model="formDataFields.channel_id"
              required
              item-text="name"
              item-value="id"
              :label="$t('create_lead.source')"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="formDataFields.campaign_id"
              :label="$t('create_lead.campaign_id')"
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-menu
              v-model="appliedMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formDataFields.applied_date"
                  :label="$t('create_lead.application_date')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[
                    (v) => !!v || $t('create_lead.required_application_date'),
                  ]"
                  requiered
                ></v-text-field>
              </template>
              <v-date-picker
                locale="nl-NL"
                v-model="formDataFields.applied_date"
                @input="appliedMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6">
            <v-autocomplete
              :items="employees"
              :item-text="fullName"
              item-value="id"
              v-model="formDataFields.rec_id"
              :label="$t('create_lead.recruiter')"
            ></v-autocomplete>
          </v-col>
          <v-col md="6">
            <v-autocomplete
              v-if="!editMode"
              :label="$t('create_lead.region')"
              :items="cities"
              item-text="city"
              item-value="city"
              v-model="data_fields_user.city"
              clearable
            ></v-autocomplete>
            <v-autocomplete
              v-if="editMode"
              :items="vacancies_all_getter"
              v-model="formDataFields.exit_vacancy_id"
              item-text="name"
              item-value="id"
              :label="$t('create_lead.exit_vacancy')"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <!--<v-row>
          <v-col>
            <v-textarea
              v-model="data_fields.notes"
              filled
              label="Notities"
            ></v-textarea>
          </v-col>
        </v-row>-->
        <v-card color="#385F73" dark class="pa-3">
          <v-text-field
            v-model="data_fields_file.title"
            :label="$t('create_lead.title')"
          ></v-text-field>

          <v-file-input
            show-size
            :label="$t('create_lead.choose_file')"
            @change="selectFile"
          ></v-file-input>
        </v-card>
        <v-row justify="center" class="mt-3">
          <v-btn
            :disabled="!valid || adding"
            color="success"
            class="mr-4"
            @click="buttonClick"
          >
            {{ types[type].buttonText }}
          </v-btn>
        </v-row>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import { permission } from "../../utils/permissions";
import { mapState, mapActions, mapGetters } from "vuex";
import { cities } from "../../utils/cities_nl";
import {
  ADD_STATUS_TO_LEAD_USER,
  CREATE_LEAD_USER,
  GET_CHANNELS,
  GET_LEADS_PER_PERIOD,
  GET_LEAD_USERS,
  GET_LEAD_USERS_WITH_LEAD,
  GET_LEAD_USER_BY_ID,
  CHECK_IF_USER_EXISTS,
} from "../../store/action.type";
export default {
  props: {
    type: String,
    formData: Object,
    parentID: Number,
    period: Array,
  },
  components: {},
  data: () => ({
    loaded: false,
    loadedUsers: false,
    appliedMenu: false,
    adding: false,
    valid: false,
    userSwitch: false,
    editMode: false,
    userExistsDialog: false,
    data_fields_user: {
      first_name: "",
      last_name: "",
      email: "",
      tel_number: "",
      city: null,
    },
    data_fields: {
      lead_user: 0,
      vacancy_id: 0,
      channel_id: 0,
      campaign_id: "",
      ra_id: null,
      rec_id: null,
      exit_vacancy_id: 0,
      applied_date: "",
      notes: "",
    },
    data_fields_file: {
      currentFile: undefined,
      message: "",
      fileInfos: [],
      title: "",
    },
    rules: {
      email: (value) => {
        if (value != "" && value != undefined && value != null) {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Ongeldig e-mail";
        } else return true;
      },
      phone: (value) => {
        if (value != "" && value != undefined && value != null) {
          const pattern = /^\+(?:[0-9]●?){6,14}[0-9]$/;
          return pattern.test(value) || "Moet beginnen met + en land code";
        } else return true;
      },
    },
  }),

  computed: {
    ...mapGetters("vacancy_flow", ["vacancies_all_getter"]),
    cities() {
      return cities;
    },
    types() {
      return [
        {
          buttonText: this.$t("create_lead.create_lead"),
        },
        {
          buttonText: this.$t("create_lead.customize_lead"),
        },
      ];
    },
    formDataFields() {
      this.updateFormFields();
      return this.data_fields;
    },
    ...mapState({
      channels: (state) => state.leads.channels,

      lead_users: (state) =>
        state.lead_users.lead_users.sort((a, b) => {
          if (a.first_name < b.first_name) {
            return -1;
          }
          if (a.first_name > b.first_name) {
            return 1;
          }
          return 0;
        }),

      employees: (state) =>
        state.filters.lists.employees
          .filter((e) => e.first_name != null)
          .sort((a, b) => {
            if (a.first_name < b.first_name) {
              return -1;
            }
            if (a.first_name > b.first_name) {
              return 1;
            }
            return 0;
          }),
    }),
  },

  created() {
    if (this.type == 1) {
      this.data_fields = this.formData;
      this.userSwitch = true;
      this.editMode = true;
    }
    this.initialize();
  },

  methods: {
    ...mapActions("lead_users", {
      getLeadUsers: GET_LEAD_USERS,
      getLeadUsersWithLead: GET_LEAD_USERS_WITH_LEAD,
      getLeadUserById: GET_LEAD_USER_BY_ID,
      createLeadUser: CREATE_LEAD_USER,
      addStatusToLeadUser: ADD_STATUS_TO_LEAD_USER,
      checkIfUserExists: CHECK_IF_USER_EXISTS,
    }),
    ...mapActions("leads", {
      getLeadsPerPeriod: GET_LEADS_PER_PERIOD,
      getChannels: GET_CHANNELS,
    }),
    validateEmailOrPhone() {
      return this.data_fields_user.email || this.data_fields_user.tel_number
        ? ""
        : this.$t("create_lead.phone_or_email");
    },

    async useExistingLeads() {
      if (!this.userSwitch) return;
      if (this.loadedUsers) return;
      await this.getLeadUsers();
      this.loadedUsers = true;
    },

    fullName(item) {
      return item.first_name + " " + item.last_name;
    },

    vacanyWithCompanyName(item) {
      if (item.Company) {
        return item.name + " - " + item.Company.name;
      } else {
        return item.name;
      }
    },

    selectFile(file) {
      this.data_fields_file.currentFile = file;
    },

    async initialize() {
      await this.getChannels();
      if (this.parentID) this.data_fields.vacancy_id = this.parentID;
      this.loaded = true;
    },

    updateFormFields() {
      if (this.type == 1) {
        this.data_fields = this.formData;
      }
    },

    updateRecruiter(vacancy_id) {
      for (var i = 0; i < this.vacancies_all_getter.length; i++) {
        if (vacancy_id == this.vacancies_all_getter[i].id) {
          this.data_fields.rec_id = this.vacancies_all_getter[i].recruiter;
          return;
        }
      }
    },

    buttonClick() {
      if (this.type == 0) {
        this.checkIfExists();
      } else if (this.type == 1) {
        this.editLead();
      }
    },

    async editLead() {
      const { dispatch } = this.$store;

      if (!this.$refs.form.validate())
        return dispatch(
          "alerts/warn",
          this.$t("create_lead.not_all_fields_completed")
        );

      this.adding = true;
      let lead = {
        lead_user: this.data_fields.lead_user,
        vacancy_id: this.data_fields.vacancy_id,
        channel_id: this.data_fields.channel_id,
        campaign_id: this.data_fields.campaign_id,
        applied_date: this.data_fields.applied_date,
        // notes: this.data_fields.notes,
        ra_id: this.data_fields.ra_id,
        rec_id: this.data_fields.rec_id,
        exit_vacancy_id: this.data_fields.exit_vacancy_id,
      };
      let leadId = this.formData.id;
      if (!permission("canCreateLead") && !permission("isAdmin")) {
        return dispatch(
          "alerts/warn",
          this.$t("create_lead.not_authorized_msg")
        );
      }

      await dispatch("leads/updateLead", { lead, leadId }).then(() => {
        dispatch("alerts/success", this.$t("create_lead.lead_saved"));
        this.getLeadUserById(this.formData.lead_user);
      });
      this.$emit("close-dialog");

      this.adding = false;
    },

    async checkIfExists() {
      // FIND
      const { dispatch } = this.$store;
      if (!this.$refs.form.validate())
        return dispatch(
          "alerts/warn",
          this.$t("create_lead.not_all_fields_completed")
        );

      if (!permission("canCreateLead") && !permission("isAdmin")) {
        return dispatch(
          "alerts/warn",
          this.$t("create_lead.not_authorized_msg")
        );
      }

      if (!this.userSwitch) {
        let email = this.data_fields_user.email;
        await this.checkIfUserExists({ email }).then((res) => {
          if (res.showPrompt) {
            this.userExistsDialog = true;
          } else {
            this.createLead();
          }
        });
      } else {
        this.createLead();
      }
    },

    async createLead() {
      const { dispatch } = this.$store;

      //! his.data_fields.lead_user is null when creating a new user and will cause errors, when implemented must be put somewhere else
      //if (this.data_fields.lead_user.blacklisted)
      // Selected existing user
      //this.$t("create_lead.lead_user_blacklisted")
      //if user is blacklisted don't allow to create a lead and show warning
      //uncomment following line to implement this functionality

      this.adding = true;
      let newLead = {
        vacancy_id: this.data_fields.vacancy_id,
        channel_id: this.data_fields.channel_id,
        campaign_id: this.data_fields.campaign_id,
        applied_date: this.data_fields.applied_date,
        phase: 1,
        // notes: this.data_fields.notes,
        ra_id: this.data_fields.ra_id,
        rec_id: this.data_fields.rec_id,
      };

      let blacklisted = false;

      if (!this.userSwitch) {
        let newLeadUser = {
          first_name: this.data_fields_user.first_name
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, ""),
          last_name: this.data_fields_user.last_name
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, ""),
          email: this.data_fields_user.email,
          tel_number: this.data_fields_user.tel_number,
        };
        if (this.data_fields_user.city) {
          newLeadUser.region = this.data_fields_user.city;
        }

        await this.createLeadUser(newLeadUser).then((res) => {
          if (res.blacklisted) return (blacklisted = res.blacklisted);
          newLead.lead_user = res.lead_user.id;
        });
      } else {
        newLead.lead_user = this.data_fields.lead_user.id;
      }

      if (blacklisted)
        return dispatch(
          "alerts/warn",
          this.$t("create_lead.lead_user_blacklisted")
        );

      let leadUserStatus = {
        lead_user_id: newLead.lead_user,
        lead_status_id: 1,
        sub_statuses: JSON.stringify([]),
      };

      await this.addStatusToLeadUser(leadUserStatus).then(
        dispatch("alerts/success", this.$t("create_lead.status_added"))
      );

      let params = {
        applicationStartDate: null,
        applicationEndDate: null,
        contactStartDate: null,
        contactEndDate: null,
        intakeStartDate: null,
        intakeEndDate: null,
      };

      if (this.period[0] > this.period[1]) {
        params = {
          applicationStartDate: this.period[1],
          applicationEndDate: this.period[0],
          contactStartDate: null,
          contactEndDate: null,
          intakeStartDate: null,
          intakeEndDate: null,
        };
      } else {
        params = {
          applicationStartDate: this.period[0],
          applicationEndDate: this.period[1],
          contactStartDate: null,
          contactEndDate: null,
          intakeStartDate: null,
          intakeEndDate: null,
        };
      }

      await dispatch("leads/createLead", newLead).then(() => {
        dispatch("alerts/success", this.$t("create_lead.lead_added"));
        this.getLeadsPerPeriod({ params });
      });

      if (this.data_fields_file.currentFile) {
        var fileToUpload = this.data_fields_file.currentFile;
        let uploadData = {
          lead_user_id: newLead.lead_user,
          title: this.data_fields_file.title,
        };
        const formData = new FormData();
        formData.append("file", fileToUpload);
        dispatch("file_upload/uploadDocument", {
          fileToUpload,
          uploadData,
        });
      }

      this.clear();
      this.$emit("close-dialog");

      this.adding = false;
    },
    clear() {
      this.$refs.form.reset();
    },
  },
};
</script>
