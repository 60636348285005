<template>
  <v-container>
    <v-dialog v-model="createEmailTemplateDialog" width="850px"
      ><CreateEmailTemplate
        v-on:close-dialog="createEmailTemplateDialog = false"
        @reload-table="init()"
      ></CreateEmailTemplate>
    </v-dialog>
    <v-dialog v-model="createHotlistDialogue" width="450px">
      <v-card>
        <CreateHotlist
          v-on:close-dialog="createHotlistDialogue = false"
          @reload-table="init()"
        />
      </v-card>
    </v-dialog>
    <v-card-title>{{ $t("email_templates.header") }}</v-card-title>
    <v-row align="center">
      <v-col md="10"
        ><v-autocomplete
          background-color="cardFields"
          dense
          outlined
          :items="hotlists_getter"
          item-text="name"
          id="id"
          return-object
          v-model="selectedHotlist"
          hide-details
          @change="selectHotlist"
          label="Hotlist"
      /></v-col>

      <v-col>
        <v-btn
          class="formBtn mr-2"
          color="primary"
          icon
          small
          @click="resetHotlist()"
        >
          <v-icon @click="resetHotlist()"> mdi-selection-remove </v-icon>
        </v-btn>

        <!-- //! Removed until option to search for users is added -->
        <!-- <v-btn
          class="formBtn mr-2"
          color="primary"
          icon
          small
          @click="openCreateHotlist()"
        >
          <v-icon dark> mdi-plus </v-icon>
        </v-btn> -->
      </v-col></v-row
    >
    <v-row align="center" class="pa-3">
      <v-autocomplete
        background-color="cardFields"
        dense
        outlined
        :items="customMembers"
        :item-text="fullName"
        item-value="id"
        return-object
        v-model="hotlistMembers"
        hide-details
        hide-no-data
        multiple
        @input="search = ''"
        placeholder="Recipients"
        :search-input.sync="search"
        ><template v-slot:selection="{ item, index }">
          <v-chip
            small
            label
            color="#E1F5FE"
            text-color="#0277BD"
            v-if="index < 5"
          >
            <span>{{ fullName(item) }}</span>
          </v-chip>
          <span v-if="index === 5" class="grey--text text-caption">
            (+{{ hotlistMembers.length - 5 }} others)
          </span>
        </template>
      </v-autocomplete>
    </v-row>
    <v-row align="center" class="py-0 my-0 mt-1">
      <v-col md="10"
        ><v-autocomplete
          background-color="cardFields"
          dense
          outlined
          :items="email_templates_getter"
          item-text="title"
          id="id"
          return-object
          v-model="template"
          hide-details
          @change="selectEmailTemplate"
          label="Email Template"
        >
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-btn
          class="formBtn mr-2"
          color="primary"
          icon
          small
          @click="resetSelection()"
        >
          <v-icon @click="resetSelection()"> mdi-selection-remove </v-icon>
        </v-btn>

        <v-btn
          class="formBtn mr-2"
          color="primary"
          icon
          small
          @click="createTemplate()"
        >
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-text-field
      background-color="cardFields"
      outlined
      dense
      label="Subject"
      v-model="data_fields.emailTemplateName"
    ></v-text-field>

    <tiptap-vuetify
      v-model="content"
      :extensions="extensions"
      :toolbar-attributes="{ color: '#adcfe6' }"
    />
    <v-row class="text-center pa-3">
      <v-col>
        <v-btn color="primary" class="animatedBtn" @click="sendEmailAction">
          {{ $t("email_templates.send") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import CreateEmailTemplate from "./CreateEmailTemplate.vue";
import CreateHotlist from "../../hotlists/dialogs/CreateHotlist.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import {
  GET_ALL_EMAIL_TEMPLATES,
  CREATE_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE,
  DELETE_EMAIL_TEMPLATE,
  SEND_EMAIL,
  GET_ALL_HOTLISTS,
  GET_HOTLIST_BY_ID,
  SEARCH_LEAD_USERS_BY_NAME,
  EMPTY_FOUND_LEAD_USERS,
} from "../../../store/action.type";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";
export default {
  props: { selectedCandidates: Array },
  data: () => ({
    data_fields: {
      emailTemplateName: null,
    },
    createEmailTemplateDialog: null,
    createHotlistDialogue: false,
    template: null,
    selectedHotlist: null,
    hotlistMembers: [],
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Code,
      HorizontalRule,
      HardBreak,
    ],
    // starting editor's content
    content: "",
    //* Fetching Recipients
    search: null,
    isLoading: false,
  }),
  computed: {
    ...mapGetters("email_template", ["email_templates_getter"]),
    ...mapGetters("hotlists", ["hotlists_getter"]),
    ...mapGetters("lead_users", ["found_lead_users_getter"]),
    customMembers() {
      let arr = [];
      arr = [...this.found_lead_users_getter];
      this.hotlistMembers.forEach((element) => {
        arr.push(element);
      });
      return arr;
    },
  },
  created() {
    this.init();
  },
  watch: {
    search: function (val) {
      if (val !== null && val !== "") {
        this.fetchLeadUsersByName(val);
      } else {
        this.emptyFoundLeadUsers();
      }
    },
    selectedCandidates: function () {
      this.checkSelectedCandidates();
    },
  },
  methods: {
    ...mapActions("email_template", {
      getAllEmailTemplates: GET_ALL_EMAIL_TEMPLATES,
      createEmailTemplate: CREATE_EMAIL_TEMPLATE,
      updateEmailTemplate: UPDATE_EMAIL_TEMPLATE,
      deleteEmailTemplate: DELETE_EMAIL_TEMPLATE,
    }),
    ...mapActions("send_grid", {
      sendEmail: SEND_EMAIL,
    }),
    ...mapActions("hotlists", {
      getAllHotlists: GET_ALL_HOTLISTS,
      getHotlistById: GET_HOTLIST_BY_ID,
    }),
    ...mapActions("lead_users", {
      searchLeadUsersByName: SEARCH_LEAD_USERS_BY_NAME,
      emptyFoundLeadUsers: EMPTY_FOUND_LEAD_USERS,
    }),
    async init() {
      await this.getAllEmailTemplates();
      await this.getAllHotlists();
      this.checkSelectedCandidates();
    },
    openCreateHotlist() {
      this.createHotlistDialogue = true;
    },
    resetSelection() {
      this.content = "";
      this.data_fields.emailTemplateName = null;
      this.template = null;
    },
    resetHotlist() {
      this.selectedHotlist = null;
      this.hotlistMembers = [];
      this.emptyFoundLeadUsers();
    },
    createTemplate() {
      this.template = {
        name: "",
        description: "",
      };
      this.createEmailTemplateDialog = true;
    },
    selectEmailTemplate(item) {
      this.data_fields.emailTemplateName = item.title;
      this.content = item.body;
    },
    async sendEmailAction() {
      const { dispatch } = this.$store;
      let emails = [];
      for (let i = 0; i < this.hotlistMembers.length; i++) {
        let email = {
          email: this.hotlistMembers[i].email,
          first_name: this.hotlistMembers[i].first_name,
        };
        emails.push(email);
      }
      await this.sendEmail({
        emails: emails,
        description: this.content,
        subject: this.data_fields.emailTemplateName,
      }).then(() => {
        this.resetSelection();
        this.resetHotlist();
        this.$emit("close-dialog");
        dispatch("alerts/success", this.$t("send_email.successful_send"));
      });
    },
    selectHotlist() {
      this.hotlistMembers = [];
      this.getHotlistById(this.selectedHotlist.id).then((res) => {
        for (let i = 0; i < res.hotlist_members.length; i++) {
          this.hotlistMembers.push(res.hotlist_members[i].lead_user);
        }
      });
    },
    fullName(item) {
      return item.first_name + " " + item.last_name;
    },
    checkSelectedCandidates() {
      if (
        this.selectedCandidates != null &&
        this.selectedCandidates.length > 0
      ) {
        this.hotlistMembers = this.selectedCandidates;
      } else {
        this.hotlistMembers = [];
      }
    },
    async fetchLeadUsersByName(search) {
      this.isLoading = true;
      await this.searchLeadUsersByName(search);
      this.isLoading = false;
    },
  },
  components: { CreateEmailTemplate, CreateHotlist, TiptapVuetify },
};
</script>
<style>
.ProseMirror {
  min-height: 200px;
  overflow-y: auto;
}
.animatedBtn:hover {
  transform: scale(1.1);
}
</style>
