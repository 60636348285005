<template>
  <v-container>
    <v-text-field
      background-color="cardFields"
      outlined
      dense
      required
      :rules="requiredRules"
      label="Name"
      v-model="activeTemplate.name"
    ></v-text-field>
    <v-textarea
      background-color="cardFields"
      outlined
      dense
      label="Description"
      v-model="activeTemplate.description"
      rows="8"
      class="overflow-y-auto"
    ></v-textarea>
    <v-row class="ma-0">
      <v-spacer></v-spacer>
      <v-btn
        class="savebtn"
        color="primary"
        @click="saveButton()"
        :disabled="activeTemplate.name == ''"
        >Save</v-btn
      >
      <v-spacer />
      <v-icon
        v-if="this.type == 'update'"
        @click="deleteEntry()"
        medium
        color="red"
        class="deleteIcon"
        >mdi-trash-can</v-icon
      >
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import {
  UPDATE_TEMPLATE,
  CREATE_TEMPLATE,
  DELETE_TEMPLATE,
} from "../../../store/action.type";
export default {
  props: { type: String, activeTemplate: Object },
  data: () => ({
    name: "",
    description: "",
    requiredRules: [(v) => !!v || "Field is required"],
  }),
  methods: {
    ...mapActions("vacancy_template", {
      updateTemplate: UPDATE_TEMPLATE,
      createTemplate: CREATE_TEMPLATE,
      deleteTemplate: DELETE_TEMPLATE,
    }),
    saveButton() {
      if (this.type == "update") {
        this.update();
      } else {
        this.create();
      }
    },
    async update() {
      let template = {
        name: this.activeTemplate.name,
        description: this.activeTemplate.description,
      };
      let id = this.activeTemplate.id;
      await this.updateTemplate({
        template,
        id,
      });
      this.$emit("close-dialog");
    },
    async create() {
      let template = {
        name: this.activeTemplate.name,
        description: this.activeTemplate.description,
      };
      await this.createTemplate({
        template,
      });
      this.$emit("reload-table");
      this.$emit("close-dialog");
    },
    async deleteEntry() {
      let id = this.activeTemplate.id;
      await this.deleteTemplate({
        id,
      });
      this.$emit("reload-table");
      this.$emit("close-dialog");
    },
  },
};
</script>
<style scoped>
.savebtn:hover {
  transform: scale(1.1);
}
.deleteIcon:hover {
  transform: scale(1.1);
}
</style>
