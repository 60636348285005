<template>
  <v-container class="pa-5">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-autocomplete
        required
        :rules="[(v) => !!v || $t('create_interest.required_role')]"
        :label="$t('create_interest.role')"
        v-model="data_fields.role"
        v-if="getLoadingStates.roles"
        :items="roles"
        item-text="name"
        item-value="id"
      ></v-autocomplete>
      <v-autocomplete
        required
        :rules="[(v) => !!v || $t('create_interest.required_department')]"
        :label="$t('create_interest.department')"
        v-model="data_fields.department"
        v-if="getLoadingStates.departments"
        :items="departments"
        item-text="name"
        item-value="id"
      ></v-autocomplete>
      <v-autocomplete
        required
        :rules="[(v) => !!v || $t('create_interest.required_industry')]"
        :label="$t('create_interest.industry')"
        v-model="data_fields.industry"
        v-if="getLoadingStates.industries"
        :items="industries"
        item-text="name"
        item-value="id"
      ></v-autocomplete>
      <v-slider
        :label="$t('create_interest.priority')"
        v-model="data_fields.priority"
        step="1"
        thumb-label
        ticks
        max="10"
      ></v-slider>
      <v-row justify="center">
        <v-btn
          :disabled="!valid || adding"
          color="success"
          class="mr-4"
          @click="buttonClick"
        >
          {{ types[type].buttonText }}
        </v-btn>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  CREATE_INTEREST,
  CREATE_JOB,
  GET_DEPARTMENTS,
  GET_INDUSTRIES,
  GET_INTERESTS_FOR_LEAD_USER,
  GET_ROLES,
  UPDATE_INTEREST,
} from "../../store/action.type";
export default {
  props: {
    type: String,
    formData: Object,
    leadUserId: Number,
  },
  data: () => ({
    adding: false,
    valid: false,
    data_fields: {
      job_id: null,
      role: null,
      department: null,
      industry: null,
      priority: 1,
    },
  }),
  computed: {
    ...mapGetters("interests", [
      "getLoadingStates",
      "departments",
      "roles",
      "industries",
    ]),
    types() {
      return [
        {
          buttonText: this.$t("create_interest.create_interest"),
        },
        {
          buttonText: this.$t("create_interest.customize_interest"),
        },
      ];
    },
  },
  created() {
    this.initialize();
    if (this.type == 1) {
      this.data_fields.job_id = this.formData.job_id;
      this.data_fields.role = this.formData.job.role.id;
      this.data_fields.department = this.formData.job.department.id;
      this.data_fields.industry = this.formData.job.industry.id;
      this.data_fields.priority = this.formData.priority;
    }
  },
  methods: {
    ...mapActions("interests", {
      getDepartments: GET_DEPARTMENTS,
      getRoles: GET_ROLES,
      getIndustries: GET_INDUSTRIES,
      createJob: CREATE_JOB,
      createInterest: CREATE_INTEREST,
      updateInterest: UPDATE_INTEREST,
      getInterestsForLeadUser: GET_INTERESTS_FOR_LEAD_USER,
    }),
    buttonClick() {
      if (this.type == 0) {
        this.addInterest();
      } else if (this.type == 1) {
        this.editInterest();
      }
    },

    clearFields() {
      this.data_fields = {
        role: null,
        department: null,
        industry: null,
        priority: 1,
      };
    },

    async initialize() {
      await this.getDepartments();
      await this.getRoles();
      await this.getIndustries();
    },

    async addInterest() {
      const { dispatch } = this.$store;
      if (this.$refs.form.validate()) {
        this.adding = true;

        const job = {
          role_id: this.data_fields.role,
          department_id: this.data_fields.department,
          industry_id: this.data_fields.industry,
        };

        let interest = {
          lead_user_id: this.leadUserId,
          job_id: null,
          priority: this.data_fields.priority,
        };

        await this.createJob(job).then((res) => {
          interest.job_id = res.id;
        });

        await this.createInterest(interest);
        await this.getInterestsForLeadUser(this.leadUserId);
        this.clearFields();
        this.adding = false;
        dispatch("alerts/success", this.$t("create_interest.interest_added"));
        this.$emit("close-dialog");
      } else {
        dispatch(
          "alerts/warn",
          this.$t("create_interest.not_all_fields_completed")
        );
      }
    },

    async editInterest() {
      const { dispatch } = this.$store;
      if (this.$refs.form.validate()) {
        this.adding = true;

        let interest = {
          lead_user_id: this.leadUserId,
          job_id: this.data_fields.job_id,
          priority: this.data_fields.priority,
        };

        if (
          this.data_fields.role != this.formData.job.role.id ||
          this.data_fields.department != this.formData.job.department.id ||
          this.data_fields.industry != this.formData.job.industry.id
        ) {
          const job = {
            role_id: this.data_fields.role,
            department_id: this.data_fields.department,
            industry_id: this.data_fields.industry,
          };
          await this.createJob(job).then((res) => (interest.job_id = res.id));
        }

        const interestId = this.formData.id;

        await this.updateInterest({ interest, interestId }).then((res) => {
          dispatch("alerts/success", this.$t("create_interest.interest_saved"));
          this.getInterestsForLeadUser(this.leadUserId);
          this.adding = false;
          this.$emit("close-dialog");
        });
      } else {
        dispatch(
          "alerts/warn",
          this.$t("create_interest.not_all_fields_completed")
        );
      }
    },
  },
};
</script>
