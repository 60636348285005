import config from "../../../config";
import { authHeader } from "../../utils/auth-header";
import { handleResponse } from "../../utils/api_functions";
import { logger } from "../../utils/logger";

import {
  SEND_MESSAGE,
  GET_ALL_MESSAGES_FOR_USER,
  CREATE_CONVERSATION,
  REFETCH_MESSAGES,
  GET_CONVERSATION_BY_ID,
  GET_ALL_WEBHOOKS,
  CREATE_WEBHOOK,
  DELETE_WEBHOOK,
  GET_USERS_WITH_CONVERSATIONS,
  DELETE_ALL_NEW_MESSAGES,
  UPDATE_UNREAD_MESSAGES,
  SEND_WHATS_APP_SURVEY,
} from "../action.type";
import { SET_REFETCH, SET_UNREAD_MESSAGES } from "../mutation.type";

export const whats_app = {
  namespaced: true,
  state: {
    refetch: false,
    unread_messages: 0,
  },
  getters: {
    getRefetch: (state) => state.refetch,
    unread_messages_getter: (state) => state.unread_messages,
  },

  actions: {
    async [SEND_MESSAGE]({ dispatch, commit }, payload) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(payload),
        };
        return await fetch(`${config.apiUrl}/sendMessage`, request)
          .then(handleResponse)
          .then((data) => {
            return data;
          });
      } catch (error) {
        logger.error(error);
      }
    },

    async [GET_ALL_MESSAGES_FOR_USER]({ commit }, { params }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(
          `${config.apiUrl}/getAllMessages/?conversation_id=${params.con_id}&offset=${params.offset}`,
          request
        )
          .then(handleResponse)
          .then((data) => {
            return data;
          });
      } catch (error) {
        logger.error(error);
      }
    },

    async [GET_CONVERSATION_BY_ID]({ commit }, { params }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(
          `${config.apiUrl}/getConversationById/?conversation_id=${params.con_id}`,
          request
        )
          .then(handleResponse)
          .then((data) => {
            return data;
          });
      } catch (error) {
        logger.error(error);
      }
    },

    async [CREATE_CONVERSATION]({ dispatch, commit }, payload) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(payload),
        };
        return await fetch(`${config.apiUrl}/createConversation`, request)
          .then(handleResponse)
          .then((data) => {
            return data;
          });
      } catch (error) {
        logger.error(error);
      }
    },

    [REFETCH_MESSAGES]({ commit }) {
      try {
        commit(SET_REFETCH);
      } catch (error) {
        logger.error(error);
      }
    },

    async [GET_ALL_WEBHOOKS]({ commit }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/getAllWebHooks`, request)
          .then(handleResponse)
          .then((data) => {
            return data;
          });
      } catch (error) {
        logger.error(error);
      }
    },

    async [CREATE_WEBHOOK]({ commit, dispatch }, payload) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(payload),
        };
        return await fetch(`${config.apiUrl}/createWebhook`, request)
          .then(handleResponse)
          .then((data) => {
            dispatch("alerts/success", "Webhook Created", { root: true });
            return data;
          });
      } catch (error) {
        logger.error(error);
      }
    },

    async [DELETE_WEBHOOK]({ commit, dispatch }, payload) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(payload),
        };
        return await fetch(`${config.apiUrl}/deleteWebHook`, request)
          .then(handleResponse)
          .then((data) => {
            dispatch("alerts/success", "Webhook Deleted", { root: true });
            return data;
          });
      } catch (error) {
        logger.error(error);
      }
    },

    async [GET_USERS_WITH_CONVERSATIONS]({ commit }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/conversations`, request)
          .then(handleResponse)
          .then((data) => {
            return data;
          });
      } catch (error) {
        logger.error(error);
      }
    },

    async [DELETE_ALL_NEW_MESSAGES]({ dispatch }, userId) {
      try {
        const request = {
          method: "DELETE",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/messages/${userId}`, request)
          .then(handleResponse)
          .then((res) => {});
      } catch (error) {
        logger.error(error);
      }
    },

    async [SEND_WHATS_APP_SURVEY]({ dispatch, commit }, payload) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(payload),
        };
        return await fetch(`${config.apiUrl}/sendWhatsAppSurvey`, request)
          .then(handleResponse)
          .then((data) => {
            return data;
          });
      } catch (error) {
        logger.error(error);
      }
    },

    //set unread messages
    [UPDATE_UNREAD_MESSAGES]({ commit }, payload) {
      commit(SET_UNREAD_MESSAGES, payload);
    },
  },

  mutations: {
    [SET_REFETCH](state) {
      state.refetch = !state.refetch;
    },
    [SET_UNREAD_MESSAGES](state, payload) {
      state.unread_messages = payload;
    },
  },
};
