export const cities = [
  {
    city: "The Hague",
    lat: "52.0767",
    lng: "4.2986",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "primary",
    population: "1406000",
    population_proper: "501725",
  },
  {
    city: "Amsterdam",
    lat: "52.3667",
    lng: "4.8833",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "primary",
    population: "862965",
    population_proper: "862965",
  },
  {
    city: "Utrecht",
    lat: "52.0908",
    lng: "5.1222",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "admin",
    population: "640000",
    population_proper: "316448",
  },
  {
    city: "Rotterdam",
    lat: "51.9225",
    lng: "4.4792",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "631155",
    population_proper: "631155",
  },
  {
    city: "Eindhoven",
    lat: "51.4408",
    lng: "5.4778",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "398053",
    population_proper: "209620",
  },
  {
    city: "Almere",
    lat: "52.3758",
    lng: "5.2256",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Flevoland",
    capital: "minor",
    population: "207904",
    population_proper: "207904",
  },
  {
    city: "Groningen",
    lat: "53.2167",
    lng: "6.5667",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "admin",
    population: "201462",
    population_proper: "201462",
  },
  {
    city: "Breda",
    lat: "51.5875",
    lng: "4.7750",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "183873",
    population_proper: "183873",
  },
  {
    city: "Apeldoorn",
    lat: "52.2100",
    lng: "5.9700",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "161156",
    population_proper: "161156",
  },
  {
    city: "Haarlem",
    lat: "52.3803",
    lng: "4.6406",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "admin",
    population: "159134",
    population_proper: "159134",
  },
  {
    city: "Zaanstad",
    lat: "52.4697",
    lng: "4.7767",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "154037",
    population_proper: "154037",
  },
  {
    city: "Arnhem",
    lat: "51.9833",
    lng: "5.9167",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "admin",
    population: "148070",
    population_proper: "148070",
  },
  {
    city: "’s-Hertogenbosch",
    lat: "51.6833",
    lng: "5.3167",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "admin",
    population: "134520",
    population_proper: "134520",
  },
  {
    city: "Leeuwarden",
    lat: "53.2000",
    lng: "5.7833",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Fryslân",
    capital: "admin",
    population: "124084",
    population_proper: "124084",
  },
  {
    city: "Maastricht",
    lat: "50.8483",
    lng: "5.6889",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "admin",
    population: "122378",
    population_proper: "122378",
  },
  {
    city: "Zwolle",
    lat: "52.5125",
    lng: "6.0944",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "admin",
    population: "111805",
    population_proper: "111805",
  },
  {
    city: "Alphen aan den Rijn",
    lat: "52.1333",
    lng: "4.6500",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "110986",
    population_proper: "110986",
  },
  {
    city: "Emmen",
    lat: "52.7833",
    lng: "6.9000",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Drenthe",
    capital: "minor",
    population: "107471",
    population_proper: "107471",
  },
  {
    city: "Delft",
    lat: "52.0119",
    lng: "4.3594",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "100011",
    population_proper: "100011",
  },
  {
    city: "Deventer",
    lat: "52.2500",
    lng: "6.2000",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "minor",
    population: "99166",
    population_proper: "99166",
  },
  {
    city: "Hilversum",
    lat: "52.2300",
    lng: "5.1800",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "87817",
    population_proper: "87817",
  },
  {
    city: "Heerlen",
    lat: "50.8833",
    lng: "5.9833",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "minor",
    population: "86833",
    population_proper: "86833",
  },
  {
    city: "Purmerend",
    lat: "52.5050",
    lng: "4.9639",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "80117",
    population_proper: "80117",
  },
  {
    city: "Lelystad",
    lat: "52.5167",
    lng: "5.4833",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Flevoland",
    capital: "admin",
    population: "77893",
    population_proper: "77893",
  },
  {
    city: "Roosendaal",
    lat: "51.5314",
    lng: "4.4556",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "77032",
    population_proper: "77032",
  },
  {
    city: "Spijkenisse",
    lat: "51.8333",
    lng: "4.3167",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "72740",
    population_proper: "72740",
  },
  {
    city: "Ede",
    lat: "52.0436",
    lng: "5.6667",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "72460",
    population_proper: "72460",
  },
  {
    city: "Gouda",
    lat: "52.0181",
    lng: "4.7056",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "71449",
    population_proper: "71449",
  },
  {
    city: "Zaandam",
    lat: "52.4417",
    lng: "4.8422",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "68695",
    population_proper: "68695",
  },
  {
    city: "Bergen op Zoom",
    lat: "51.5000",
    lng: "4.3000",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "66811",
    population_proper: "66811",
  },
  {
    city: "Capelle aan den IJssel",
    lat: "51.9357",
    lng: "4.5782",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "66514",
    population_proper: "66514",
  },
  {
    city: "Veenendaal",
    lat: "52.0250",
    lng: "5.5550",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "minor",
    population: "65589",
    population_proper: "65589",
  },
  {
    city: "Katwijk",
    lat: "52.2008",
    lng: "4.4153",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "65302",
    population_proper: "65302",
  },
  {
    city: "Zeist",
    lat: "52.0833",
    lng: "5.2333",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "minor",
    population: "63934",
    population_proper: "63934",
  },
  {
    city: "Nieuwegein",
    lat: "52.0314",
    lng: "5.0919",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "minor",
    population: "63036",
    population_proper: "63036",
  },
  {
    city: "Assen",
    lat: "52.9967",
    lng: "6.5625",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Drenthe",
    capital: "admin",
    population: "62237",
    population_proper: "62237",
  },
  {
    city: "Hardenberg",
    lat: "52.5758",
    lng: "6.6194",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "minor",
    population: "59689",
    population_proper: "59689",
  },
  {
    city: "Barneveld",
    lat: "52.1333",
    lng: "5.5833",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "57971",
    population_proper: "57971",
  },
  {
    city: "Roermond",
    lat: "51.1933",
    lng: "5.9872",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "minor",
    population: "57308",
    population_proper: "57308",
  },
  {
    city: "Heerhugowaard",
    lat: "52.6680",
    lng: "4.8410",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "56742",
    population_proper: "56742",
  },
  {
    city: "Oosterhout",
    lat: "51.6431",
    lng: "4.8569",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "55616",
    population_proper: "55616",
  },
  {
    city: "Den Helder",
    lat: "52.9583",
    lng: "4.7589",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "55604",
    population_proper: "55604",
  },
  {
    city: "Hoogeveen",
    lat: "52.7167",
    lng: "6.4667",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Drenthe",
    capital: "minor",
    population: "55224",
    population_proper: "55224",
  },
  {
    city: "Kampen",
    lat: "52.5500",
    lng: "5.9000",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "minor",
    population: "53779",
    population_proper: "53779",
  },
  {
    city: "Woerden",
    lat: "52.0858",
    lng: "4.8833",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "minor",
    population: "51156",
    population_proper: "51156",
  },
  {
    city: "Houten",
    lat: "52.0261",
    lng: "5.1728",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "minor",
    population: "49911",
    population_proper: "49911",
  },
  {
    city: "Sittard",
    lat: "51.0000",
    lng: "5.8667",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "minor",
    population: "49483",
    population_proper: "49483",
  },
  {
    city: "IJmuiden",
    lat: "52.4586",
    lng: "4.6194",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "48320",
    population_proper: "48320",
  },
  {
    city: "Middelburg",
    lat: "51.4997",
    lng: "3.6136",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "admin",
    population: "47939",
    population_proper: "47939",
  },
  {
    city: "Harderwijk",
    lat: "52.3506",
    lng: "5.6172",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "47581",
    population_proper: "47581",
  },
  {
    city: "Zutphen",
    lat: "52.1400",
    lng: "6.1950",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "47423",
    population_proper: "47423",
  },
  {
    city: "Ridderkerk",
    lat: "51.8667",
    lng: "4.6000",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "46241",
    population_proper: "46241",
  },
  {
    city: "Kerkrade",
    lat: "50.8667",
    lng: "6.0667",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "minor",
    population: "45642",
    population_proper: "45642",
  },
  {
    city: "Veldhoven",
    lat: "51.4197",
    lng: "5.3992",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "45337",
    population_proper: "45337",
  },
  {
    city: "Medemblik",
    lat: "52.7674",
    lng: "5.1037",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "44809",
    population_proper: "44809",
  },
  {
    city: "Zwijndrecht",
    lat: "51.8240",
    lng: "4.6126",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "44454",
    population_proper: "44454",
  },
  {
    city: "Vlissingen",
    lat: "51.4500",
    lng: "3.5833",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "minor",
    population: "44417",
    population_proper: "44417",
  },
  {
    city: "Rheden",
    lat: "52.0000",
    lng: "6.0167",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "43816",
    population_proper: "43816",
  },
  {
    city: "Etten-Leur",
    lat: "51.5706",
    lng: "4.6356",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "43774",
    population_proper: "43774",
  },
  {
    city: "Zevenaar",
    lat: "51.9167",
    lng: "6.0833",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "43488",
    population_proper: "43488",
  },
  {
    city: "Venray",
    lat: "51.5258",
    lng: "5.9747",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "minor",
    population: "43326",
    population_proper: "43326",
  },
  {
    city: "Noordwijk",
    lat: "52.2333",
    lng: "4.4333",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "42859",
    population_proper: "42859",
  },
  {
    city: "Tiel",
    lat: "51.8833",
    lng: "5.4333",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "42015",
    population_proper: "42015",
  },
  {
    city: "Uden",
    lat: "51.6667",
    lng: "5.6167",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "41782",
    population_proper: "41782",
  },
  {
    city: "Huizen",
    lat: "52.2994",
    lng: "5.2531",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "41273",
    population_proper: "41273",
  },
  {
    city: "Beverwijk",
    lat: "52.4864",
    lng: "4.6572",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "41176",
    population_proper: "41176",
  },
  {
    city: "Wijchen",
    lat: "51.8067",
    lng: "5.7211",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "40951",
    population_proper: "40951",
  },
  {
    city: "Dronten",
    lat: "52.5242",
    lng: "5.7125",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Flevoland",
    capital: "minor",
    population: "40815",
    population_proper: "40815",
  },
  {
    city: "Hellevoetsluis",
    lat: "51.8333",
    lng: "4.1333",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "40049",
    population_proper: "40049",
  },
  {
    city: "Maarssen",
    lat: "52.1408",
    lng: "5.0394",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "minor",
    population: "39752",
    population_proper: "39752",
  },
  {
    city: "Leidschendam",
    lat: "52.0833",
    lng: "4.4000",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "39567",
    population_proper: "39567",
  },
  {
    city: "Heemskerk",
    lat: "52.5108",
    lng: "4.6728",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "39164",
    population_proper: "39164",
  },
  {
    city: "Veghel",
    lat: "51.6169",
    lng: "5.5481",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "38077",
    population_proper: "38077",
  },
  {
    city: "Goes",
    lat: "51.5000",
    lng: "3.8833",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "minor",
    population: "37739",
    population_proper: "37739",
  },
  {
    city: "Venlo",
    lat: "51.3700",
    lng: "6.1681",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "minor",
    population: "37663",
    population_proper: "37663",
  },
  {
    city: "Landgraaf",
    lat: "50.9083",
    lng: "6.0297",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "minor",
    population: "37591",
    population_proper: "37591",
  },
  {
    city: "Teijlingen",
    lat: "52.2150",
    lng: "4.5103",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "37061",
    population_proper: "37061",
  },
  {
    city: "Geleen",
    lat: "50.9667",
    lng: "5.8333",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "36958",
    population_proper: "36958",
  },
  {
    city: "Hellendoorn",
    lat: "52.3885",
    lng: "6.4497",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "",
    population: "35808",
    population_proper: "35808",
  },
  {
    city: "Castricum",
    lat: "52.5517",
    lng: "4.6583",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "35772",
    population_proper: "35772",
  },
  {
    city: "Gorinchem",
    lat: "51.8306",
    lng: "4.9742",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "35255",
    population_proper: "35255",
  },
  {
    city: "IJsselstein",
    lat: "52.0200",
    lng: "5.0422",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "minor",
    population: "34160",
    population_proper: "34160",
  },
  {
    city: "Sneek",
    lat: "53.0325",
    lng: "5.6600",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Fryslân",
    capital: "minor",
    population: "33855",
    population_proper: "33855",
  },
  {
    city: "Tynaarlo",
    lat: "53.0833",
    lng: "6.6000",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Drenthe",
    capital: "",
    population: "33698",
    population_proper: "33698",
  },
  {
    city: "Maassluis",
    lat: "51.9189",
    lng: "4.2567",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "32768",
    population_proper: "32768",
  },
  {
    city: "Bussum",
    lat: "52.2733",
    lng: "5.1611",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "32410",
    population_proper: "32410",
  },
  {
    city: "Deurne",
    lat: "51.4639",
    lng: "5.7947",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "32362",
    population_proper: "32362",
  },
  {
    city: "Oldenzaal",
    lat: "52.3125",
    lng: "6.9292",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "minor",
    population: "31840",
    population_proper: "31840",
  },
  {
    city: "Aalsmeer",
    lat: "52.2639",
    lng: "4.7625",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "31728",
    population_proper: "31728",
  },
  {
    city: "Rosmalen",
    lat: "51.7167",
    lng: "5.3667",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "31219",
    population_proper: "31219",
  },
  {
    city: "Lonneker",
    lat: "52.2506",
    lng: "6.9119",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "",
    population: "31129",
    population_proper: "31129",
  },
  {
    city: "Hendrik-Ido-Ambacht",
    lat: "51.8500",
    lng: "4.6300",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "30966",
    population_proper: "30966",
  },
  {
    city: "Valkenswaard",
    lat: "51.3500",
    lng: "5.4592",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "30910",
    population_proper: "30910",
  },
  {
    city: "Boxtel",
    lat: "51.5911",
    lng: "5.3275",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "30747",
    population_proper: "30747",
  },
  {
    city: "Leusden",
    lat: "52.1331",
    lng: "5.4297",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "minor",
    population: "30030",
    population_proper: "30030",
  },
  {
    city: "Bergen",
    lat: "52.6703",
    lng: "4.7054",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "29974",
    population_proper: "29974",
  },
  {
    city: "Heesch",
    lat: "51.7314",
    lng: "5.5300",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "29728",
    population_proper: "29728",
  },
  {
    city: "Oosterend",
    lat: "53.0036",
    lng: "6.0664",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Fryslân",
    capital: "",
    population: "29723",
    population_proper: "29723",
  },
  {
    city: "Krimpen aan den IJssel",
    lat: "51.9200",
    lng: "4.6000",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "29376",
    population_proper: "29376",
  },
  {
    city: "Diemen",
    lat: "52.3439",
    lng: "4.9625",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "29196",
    population_proper: "29196",
  },
  {
    city: "Boxmeer",
    lat: "51.6483",
    lng: "5.9444",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "29065",
    population_proper: "29065",
  },
  {
    city: "Sint-Michielsgestel",
    lat: "51.6433",
    lng: "5.3586",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "28991",
    population_proper: "28991",
  },
  {
    city: "Winterswijk",
    lat: "51.9667",
    lng: "6.7167",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "28903",
    population_proper: "28903",
  },
  {
    city: "Culemborg",
    lat: "51.9500",
    lng: "5.2333",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "28555",
    population_proper: "28555",
  },
  {
    city: "Heerenveen",
    lat: "52.9500",
    lng: "5.9333",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Fryslân",
    capital: "minor",
    population: "28497",
    population_proper: "28497",
  },
  {
    city: "Geldrop",
    lat: "51.4222",
    lng: "5.5578",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "28222",
    population_proper: "28222",
  },
  {
    city: "Brunssum",
    lat: "50.9500",
    lng: "5.9667",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "minor",
    population: "28103",
    population_proper: "28103",
  },
  {
    city: "Langedijk",
    lat: "52.6936",
    lng: "4.7944",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "27992",
    population_proper: "27992",
  },
  {
    city: "Zaltbommel",
    lat: "51.8000",
    lng: "5.2500",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "27549",
    population_proper: "27549",
  },
  {
    city: "Heemstede",
    lat: "52.3528",
    lng: "4.6200",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "27286",
    population_proper: "27286",
  },
  {
    city: "Drimmelen",
    lat: "51.6944",
    lng: "4.7972",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "27150",
    population_proper: "27150",
  },
  {
    city: "Leiderdorp",
    lat: "52.1617",
    lng: "4.5283",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "27109",
    population_proper: "27109",
  },
  {
    city: "Aalten",
    lat: "51.9250",
    lng: "6.5808",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "27011",
    population_proper: "27011",
  },
  {
    city: "Werkendam",
    lat: "51.8097",
    lng: "4.8928",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "26940",
    population_proper: "26940",
  },
  {
    city: "Ermelo",
    lat: "52.3000",
    lng: "5.6331",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "26858",
    population_proper: "26858",
  },
  {
    city: "Rijssen",
    lat: "52.3000",
    lng: "6.5167",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "minor",
    population: "26428",
    population_proper: "26428",
  },
  {
    city: "Wassenaar",
    lat: "52.1453",
    lng: "4.4006",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "26211",
    population_proper: "26211",
  },
  {
    city: "Oisterwijk",
    lat: "51.5833",
    lng: "5.2000",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "26140",
    population_proper: "26140",
  },
  {
    city: "Dongen",
    lat: "51.6258",
    lng: "4.9433",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "26051",
    population_proper: "26051",
  },
  {
    city: "Beuningen",
    lat: "51.8667",
    lng: "5.7667",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "25882",
    population_proper: "25882",
  },
  {
    city: "Veenoord",
    lat: "52.9875",
    lng: "6.2914",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Fryslân",
    capital: "",
    population: "25531",
    population_proper: "25531",
  },
  {
    city: "Duiven",
    lat: "51.9472",
    lng: "6.0211",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "25332",
    population_proper: "25332",
  },
  {
    city: "Sliedrecht",
    lat: "51.8222",
    lng: "4.7744",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "25026",
    population_proper: "25026",
  },
  {
    city: "Stein",
    lat: "50.9679",
    lng: "5.7652",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "minor",
    population: "24961",
    population_proper: "24961",
  },
  {
    city: "Cuijk",
    lat: "51.7269",
    lng: "5.8794",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "24931",
    population_proper: "24931",
  },
  {
    city: "Oegstgeest",
    lat: "52.1667",
    lng: "4.4667",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "24426",
    population_proper: "24426",
  },
  {
    city: "Oud-Beijerland",
    lat: "51.8200",
    lng: "4.4200",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "24301",
    population_proper: "24301",
  },
  {
    city: "Putten",
    lat: "52.2579",
    lng: "5.6080",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "24198",
    population_proper: "24198",
  },
  {
    city: "Goirle",
    lat: "51.5203",
    lng: "5.0671",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "23793",
    population_proper: "23793",
  },
  {
    city: "Schijndel",
    lat: "51.6183",
    lng: "5.4364",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "23618",
    population_proper: "23618",
  },
  {
    city: "Oldebroek",
    lat: "52.4667",
    lng: "5.9167",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "23598",
    population_proper: "23598",
  },
  {
    city: "Heiloo",
    lat: "52.6011",
    lng: "4.7019",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "23464",
    population_proper: "23464",
  },
  {
    city: "Loon op Zand",
    lat: "51.6275",
    lng: "5.0758",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "23327",
    population_proper: "23327",
  },
  {
    city: "Borne",
    lat: "52.3000",
    lng: "6.7500",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "minor",
    population: "23210",
    population_proper: "23210",
  },
  {
    city: "Steenwijk",
    lat: "52.7833",
    lng: "6.1167",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "minor",
    population: "22843",
    population_proper: "22843",
  },
  {
    city: "Hoensbroek",
    lat: "50.9167",
    lng: "5.9333",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "22740",
    population_proper: "22740",
  },
  {
    city: "Losser",
    lat: "52.2617",
    lng: "7.0044",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "minor",
    population: "22622",
    population_proper: "22622",
  },
  {
    city: "Beek en Donk",
    lat: "51.5161",
    lng: "5.6208",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "22333",
    population_proper: "22333",
  },
  {
    city: "Zeewolde",
    lat: "52.3333",
    lng: "5.5167",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Flevoland",
    capital: "minor",
    population: "22309",
    population_proper: "22309",
  },
  {
    city: "Oud-Zuilen",
    lat: "52.1272",
    lng: "5.0694",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "22257",
    population_proper: "22257",
  },
  {
    city: "Woensdrecht",
    lat: "51.4300",
    lng: "4.3050",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "21866",
    population_proper: "21866",
  },
  {
    city: "Nieuwerkerk aan den IJssel",
    lat: "51.9656",
    lng: "4.6083",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "21649",
    population_proper: "21649",
  },
  {
    city: "Zundert",
    lat: "51.4703",
    lng: "4.6600",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "21612",
    population_proper: "21612",
  },
  {
    city: "Geertruidenberg",
    lat: "51.7008",
    lng: "4.8603",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "21515",
    population_proper: "21515",
  },
  {
    city: "Tubbergen",
    lat: "52.4000",
    lng: "6.7667",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "minor",
    population: "21276",
    population_proper: "21276",
  },
  {
    city: "Leerdam",
    lat: "51.8939",
    lng: "5.0914",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "21030",
    population_proper: "21030",
  },
  {
    city: "Winschoten",
    lat: "53.1500",
    lng: "7.0333",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "minor",
    population: "20913",
    population_proper: "20913",
  },
  {
    city: "Urk",
    lat: "52.6653",
    lng: "5.6058",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Flevoland",
    capital: "minor",
    population: "20776",
    population_proper: "20776",
  },
  {
    city: "Poortugaal",
    lat: "51.8553",
    lng: "4.3967",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "20746",
    population_proper: "20746",
  },
  {
    city: "Cranendonck",
    lat: "51.2853",
    lng: "5.5881",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "20440",
    population_proper: "20440",
  },
  {
    city: "Bladel",
    lat: "51.3667",
    lng: "5.2167",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "20175",
    population_proper: "20175",
  },
  {
    city: "Rhenen",
    lat: "51.9597",
    lng: "5.5689",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "minor",
    population: "20004",
    population_proper: "20004",
  },
  {
    city: "Wisch",
    lat: "51.9333",
    lng: "6.3167",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "19911",
    population_proper: "19911",
  },
  {
    city: "Vriezenveen",
    lat: "52.4117",
    lng: "6.6256",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "minor",
    population: "19875",
    population_proper: "19875",
  },
  {
    city: "Helden",
    lat: "51.3167",
    lng: "6.0000",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "19502",
    population_proper: "19502",
  },
  {
    city: "Tegelen",
    lat: "51.3422",
    lng: "6.1364",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "19337",
    population_proper: "19337",
  },
  {
    city: "Someren",
    lat: "51.3847",
    lng: "5.7119",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "19322",
    population_proper: "19322",
  },
  {
    city: "Echt",
    lat: "51.1058",
    lng: "5.8706",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "minor",
    population: "19237",
    population_proper: "19237",
  },
  {
    city: "Lichtenvoorde",
    lat: "51.9833",
    lng: "6.5667",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "19224",
    population_proper: "19224",
  },
  {
    city: "Eersel",
    lat: "51.3572",
    lng: "5.3147",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "19110",
    population_proper: "19110",
  },
  {
    city: "Meerssen",
    lat: "50.8858",
    lng: "5.7519",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "minor",
    population: "18923",
    population_proper: "18923",
  },
  {
    city: "Zuidhorn",
    lat: "53.2468",
    lng: "6.4077",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "minor",
    population: "18917",
    population_proper: "18917",
  },
  {
    city: "Groesbeek",
    lat: "51.7833",
    lng: "5.9333",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "18870",
    population_proper: "18870",
  },
  {
    city: "Edam",
    lat: "52.5167",
    lng: "5.0500",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "18828",
    population_proper: "18828",
  },
  {
    city: "Druten",
    lat: "51.8889",
    lng: "5.6044",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "18797",
    population_proper: "18797",
  },
  {
    city: "Oirschot",
    lat: "51.5047",
    lng: "5.3128",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "18623",
    population_proper: "18623",
  },
  {
    city: "Bergeijk",
    lat: "51.3203",
    lng: "5.3592",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "18491",
    population_proper: "18491",
  },
  {
    city: "Drunen",
    lat: "51.6833",
    lng: "5.1667",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "18355",
    population_proper: "18355",
  },
  {
    city: "Bemmel",
    lat: "51.8917",
    lng: "5.8958",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "18104",
    population_proper: "18104",
  },
  {
    city: "Elst",
    lat: "51.9156",
    lng: "5.8378",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "18056",
    population_proper: "18056",
  },
  {
    city: "Hardinxveld-Giessendam",
    lat: "51.8300",
    lng: "4.8700",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "18051",
    population_proper: "18051",
  },
  {
    city: "Middelharnis",
    lat: "51.7535",
    lng: "4.1647",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "18050",
    population_proper: "18050",
  },
  {
    city: "Bodegraven",
    lat: "52.0808",
    lng: "4.7486",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "17954",
    population_proper: "17954",
  },
  {
    city: "Sint-Oedenrode",
    lat: "51.5636",
    lng: "5.4608",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "17893",
    population_proper: "17893",
  },
  {
    city: "Ommen",
    lat: "52.5167",
    lng: "6.4167",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "minor",
    population: "17813",
    population_proper: "17813",
  },
  {
    city: "Naarden",
    lat: "52.2953",
    lng: "5.1622",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "17301",
    population_proper: "17301",
  },
  {
    city: "Waalre",
    lat: "51.3867",
    lng: "5.4456",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "17247",
    population_proper: "17247",
  },
  {
    city: "Gennep",
    lat: "51.6942",
    lng: "5.9733",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "minor",
    population: "17071",
    population_proper: "17071",
  },
  {
    city: "Zandvoort",
    lat: "52.3667",
    lng: "4.5333",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "17011",
    population_proper: "17011",
  },
  {
    city: "Staphorst",
    lat: "52.6333",
    lng: "6.2000",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "minor",
    population: "17003",
    population_proper: "17003",
  },
  {
    city: "Son en Breugel",
    lat: "51.5158",
    lng: "5.5022",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "16904",
    population_proper: "16904",
  },
  {
    city: "Asten",
    lat: "51.4031",
    lng: "5.7472",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "16710",
    population_proper: "16710",
  },
  {
    city: "Kralingse Veer",
    lat: "51.9249",
    lng: "4.5071",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "16673",
    population_proper: "16673",
  },
  {
    city: "Didam",
    lat: "51.9333",
    lng: "6.1333",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "16603",
    population_proper: "16603",
  },
  {
    city: "Heumen",
    lat: "51.7656",
    lng: "5.8433",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "16486",
    population_proper: "16486",
  },
  {
    city: "Eibergen",
    lat: "52.1000",
    lng: "6.6500",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "16272",
    population_proper: "16272",
  },
  {
    city: "Harenkarspel",
    lat: "52.7344",
    lng: "4.7706",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "16179",
    population_proper: "16179",
  },
  {
    city: "Beek",
    lat: "50.9394",
    lng: "5.7961",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "minor",
    population: "15929",
    population_proper: "15929",
  },
  {
    city: "Vlijmen",
    lat: "51.6953",
    lng: "5.2119",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "15899",
    population_proper: "15899",
  },
  {
    city: "Zevenbergen",
    lat: "51.6453",
    lng: "4.5997",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "15859",
    population_proper: "15859",
  },
  {
    city: "Harlingen",
    lat: "53.1736",
    lng: "5.4272",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Fryslân",
    capital: "minor",
    population: "15822",
    population_proper: "15822",
  },
  {
    city: "Huissen",
    lat: "51.9333",
    lng: "5.9333",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "15782",
    population_proper: "15782",
  },
  {
    city: "Hilvarenbeek",
    lat: "51.4861",
    lng: "5.1367",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "15334",
    population_proper: "15334",
  },
  {
    city: "Bunnik",
    lat: "52.0672",
    lng: "5.1969",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "15192",
    population_proper: "15192",
  },
  {
    city: "Den Ham",
    lat: "52.4650",
    lng: "6.4942",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "",
    population: "15051",
    population_proper: "15051",
  },
  {
    city: "Boskoop",
    lat: "52.0667",
    lng: "4.6333",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "15045",
    population_proper: "15045",
  },
  {
    city: "Westervoort",
    lat: "51.9667",
    lng: "5.9667",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "14944",
    population_proper: "14944",
  },
  {
    city: "IJsselmuiden",
    lat: "52.5647",
    lng: "5.9275",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "",
    population: "14926",
    population_proper: "14926",
  },
  {
    city: "Sassenheim",
    lat: "52.2258",
    lng: "4.5225",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "14886",
    population_proper: "14886",
  },
  {
    city: "Rijnsburg",
    lat: "52.1897",
    lng: "4.4408",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "14843",
    population_proper: "14843",
  },
  {
    city: "Rozenburg",
    lat: "51.9058",
    lng: "4.2486",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "14741",
    population_proper: "14741",
  },
  {
    city: "Breukelen",
    lat: "52.1725",
    lng: "4.9986",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "14736",
    population_proper: "14736",
  },
  {
    city: "Lopik",
    lat: "51.9722",
    lng: "4.9464",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "minor",
    population: "14473",
    population_proper: "14473",
  },
  {
    city: "Scheemda",
    lat: "53.1667",
    lng: "6.9667",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "",
    population: "14368",
    population_proper: "14368",
  },
  {
    city: "Gorssel",
    lat: "52.2000",
    lng: "6.2000",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "14281",
    population_proper: "14281",
  },
  {
    city: "Haaren",
    lat: "51.6017",
    lng: "5.2228",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "14195",
    population_proper: "14195",
  },
  {
    city: "Beilen",
    lat: "52.8567",
    lng: "6.5111",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Drenthe",
    capital: "minor",
    population: "14089",
    population_proper: "14089",
  },
  {
    city: "Maasbracht",
    lat: "51.1500",
    lng: "5.8833",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "minor",
    population: "13700",
    population_proper: "13700",
  },
  {
    city: "Raamsdonk",
    lat: "51.6878",
    lng: "4.9081",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "13658",
    population_proper: "13658",
  },
  {
    city: "Winsum",
    lat: "53.3312",
    lng: "6.5157",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "minor",
    population: "13560",
    population_proper: "13560",
  },
  {
    city: "Uitgeest",
    lat: "52.5292",
    lng: "4.7103",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "13528",
    population_proper: "13528",
  },
  {
    city: "Beesel",
    lat: "51.2669",
    lng: "6.0392",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "13519",
    population_proper: "13519",
  },
  {
    city: "Valburg",
    lat: "51.9111",
    lng: "5.7892",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "13449",
    population_proper: "13449",
  },
  {
    city: "Margraten",
    lat: "50.8225",
    lng: "5.8203",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "13370",
    population_proper: "13370",
  },
  {
    city: "Reeuwijk",
    lat: "52.0481",
    lng: "4.7203",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "13296",
    population_proper: "13296",
  },
  {
    city: "Maasbree",
    lat: "51.3500",
    lng: "6.0500",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "13216",
    population_proper: "13216",
  },
  {
    city: "Bergschenhoek",
    lat: "51.9800",
    lng: "4.5000",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "13206",
    population_proper: "13206",
  },
  {
    city: "Woudenberg",
    lat: "52.0806",
    lng: "5.4164",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "minor",
    population: "13166",
    population_proper: "13166",
  },
  {
    city: "Bergen",
    lat: "51.5992",
    lng: "6.0336",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "minor",
    population: "13140",
    population_proper: "13140",
  },
  {
    city: "Odoorn",
    lat: "52.8508",
    lng: "6.8481",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Drenthe",
    capital: "",
    population: "13126",
    population_proper: "13126",
  },
  {
    city: "Mijdrecht",
    lat: "52.2072",
    lng: "4.8594",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "minor",
    population: "13009",
    population_proper: "13009",
  },
  {
    city: "Schinnen",
    lat: "50.9428",
    lng: "5.8894",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "minor",
    population: "12911",
    population_proper: "12911",
  },
  {
    city: "Borger",
    lat: "52.9236",
    lng: "6.7922",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Drenthe",
    capital: "",
    population: "12871",
    population_proper: "12871",
  },
  {
    city: "Kapelle",
    lat: "51.5000",
    lng: "3.9500",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "minor",
    population: "12785",
    population_proper: "12785",
  },
  {
    city: "Franeker",
    lat: "53.1875",
    lng: "5.5400",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Fryslân",
    capital: "minor",
    population: "12781",
    population_proper: "12781",
  },
  {
    city: "Halsteren",
    lat: "51.5256",
    lng: "4.2747",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "12725",
    population_proper: "12725",
  },
  {
    city: "Wieringerwerf",
    lat: "52.8500",
    lng: "5.0300",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "12576",
    population_proper: "12576",
  },
  {
    city: "Grave",
    lat: "51.7592",
    lng: "5.7383",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "12483",
    population_proper: "12483",
  },
  {
    city: "Neerijnen",
    lat: "51.8333",
    lng: "5.2833",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "12397",
    population_proper: "12397",
  },
  {
    city: "Dokkum",
    lat: "53.3253",
    lng: "5.9989",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Fryslân",
    capital: "minor",
    population: "12365",
    population_proper: "12365",
  },
  {
    city: "Goor",
    lat: "52.2333",
    lng: "6.5861",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "minor",
    population: "12349",
    population_proper: "12349",
  },
  {
    city: "Axel",
    lat: "51.2656",
    lng: "3.9089",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "12280",
    population_proper: "12280",
  },
  {
    city: "Hattem",
    lat: "52.4744",
    lng: "6.0697",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "12173",
    population_proper: "12173",
  },
  {
    city: "Heythuysen",
    lat: "51.2496",
    lng: "5.8983",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "minor",
    population: "12106",
    population_proper: "12106",
  },
  {
    city: "Oosterbroek",
    lat: "53.1299",
    lng: "6.8738",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Drenthe",
    capital: "",
    population: "12076",
    population_proper: "12076",
  },
  {
    city: "Budel",
    lat: "51.2725",
    lng: "5.5744",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "11926",
    population_proper: "11926",
  },
  {
    city: "Schoonhoven",
    lat: "51.9475",
    lng: "4.8486",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "11922",
    population_proper: "11922",
  },
  {
    city: "Opmeer",
    lat: "52.7033",
    lng: "4.9444",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "11779",
    population_proper: "11779",
  },
  {
    city: "Ubach over Worms",
    lat: "50.9158",
    lng: "6.0517",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "11761",
    population_proper: "11761",
  },
  {
    city: "Sint Anthonis",
    lat: "51.6258",
    lng: "5.8811",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "11606",
    population_proper: "11606",
  },
  {
    city: "Eijsden",
    lat: "50.7778",
    lng: "5.7108",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "minor",
    population: "11565",
    population_proper: "11565",
  },
  {
    city: "Wormer",
    lat: "52.4972",
    lng: "4.8089",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "11470",
    population_proper: "11470",
  },
  {
    city: "Hazerswoude-Dorp",
    lat: "52.1000",
    lng: "4.5833",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "11366",
    population_proper: "11366",
  },
  {
    city: "Zierikzee",
    lat: "51.6497",
    lng: "3.9164",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "minor",
    population: "11360",
    population_proper: "11360",
  },
  {
    city: "Ulrum",
    lat: "53.3589",
    lng: "6.3331",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "",
    population: "11212",
    population_proper: "11212",
  },
  {
    city: "Zelhem",
    lat: "52.0067",
    lng: "6.3486",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "11203",
    population_proper: "11203",
  },
  {
    city: "Blaricum",
    lat: "52.2728",
    lng: "5.2422",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "11202",
    population_proper: "11202",
  },
  {
    city: "Laren",
    lat: "52.2544",
    lng: "5.2317",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "11195",
    population_proper: "11195",
  },
  {
    city: "Doesburg",
    lat: "52.0167",
    lng: "6.1333",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "11148",
    population_proper: "11148",
  },
  {
    city: "Doornspijk",
    lat: "52.4192",
    lng: "5.8161",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "11116",
    population_proper: "11116",
  },
  {
    city: "Schipluiden",
    lat: "51.9833",
    lng: "4.3167",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "11113",
    population_proper: "11113",
  },
  {
    city: "Neede",
    lat: "52.1333",
    lng: "6.6167",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "11102",
    population_proper: "11102",
  },
  {
    city: "Diepenveen",
    lat: "52.2894",
    lng: "6.1500",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "",
    population: "10838",
    population_proper: "10838",
  },
  {
    city: "Boekel",
    lat: "51.6011",
    lng: "5.6742",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "10588",
    population_proper: "10588",
  },
  {
    city: "Borculo",
    lat: "52.1167",
    lng: "6.5167",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "10498",
    population_proper: "10498",
  },
  {
    city: "Monnickendam",
    lat: "52.4547",
    lng: "5.0353",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "10316",
    population_proper: "10316",
  },
  {
    city: "Bleiswijk",
    lat: "52.0100",
    lng: "4.5400",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "10258",
    population_proper: "10258",
  },
  {
    city: "Prinsenbeek",
    lat: "51.5981",
    lng: "4.7136",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "10168",
    population_proper: "10168",
  },
  {
    city: "Doorn",
    lat: "52.0333",
    lng: "5.3500",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "minor",
    population: "10124",
    population_proper: "10124",
  },
  {
    city: "Vaals",
    lat: "50.7694",
    lng: "6.0181",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "minor",
    population: "10092",
    population_proper: "10092",
  },
  {
    city: "Haelen",
    lat: "51.2358",
    lng: "5.9547",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "10051",
    population_proper: "10051",
  },
  {
    city: "Nieuwenhagen",
    lat: "50.9039",
    lng: "6.0400",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "10022",
    population_proper: "10022",
  },
  {
    city: "Bolsward",
    lat: "53.0667",
    lng: "5.5333",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Fryslân",
    capital: "",
    population: "9974",
    population_proper: "9974",
  },
  {
    city: "Scherpenzeel",
    lat: "52.0667",
    lng: "5.4833",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "9873",
    population_proper: "9873",
  },
  {
    city: "Vlist",
    lat: "51.9867",
    lng: "4.7889",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "9806",
    population_proper: "9806",
  },
  {
    city: "Oostzaan",
    lat: "52.4406",
    lng: "4.8789",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "9757",
    population_proper: "9757",
  },
  {
    city: "Liesveld",
    lat: "51.9156",
    lng: "4.8456",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "9746",
    population_proper: "9746",
  },
  {
    city: "Vries",
    lat: "53.0733",
    lng: "6.5769",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Drenthe",
    capital: "minor",
    population: "9646",
    population_proper: "9646",
  },
  {
    city: "Heteren",
    lat: "51.9575",
    lng: "5.7550",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "9593",
    population_proper: "9593",
  },
  {
    city: "Wamel",
    lat: "51.8800",
    lng: "5.4692",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "9585",
    population_proper: "9585",
  },
  {
    city: "Nieuw-Lekkerland",
    lat: "51.8833",
    lng: "4.6833",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "9541",
    population_proper: "9541",
  },
  {
    city: "Hoek van Holland",
    lat: "51.9763",
    lng: "4.1323",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "9382",
    population_proper: "9382",
  },
  {
    city: "Ubbergen",
    lat: "51.8333",
    lng: "5.9167",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "9358",
    population_proper: "9358",
  },
  {
    city: "Olst",
    lat: "52.3378",
    lng: "6.1108",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "",
    population: "9329",
    population_proper: "9329",
  },
  {
    city: "Weerselo",
    lat: "52.3519",
    lng: "6.8572",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "",
    population: "9294",
    population_proper: "9294",
  },
  {
    city: "Smilde",
    lat: "52.9486",
    lng: "6.4483",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Drenthe",
    capital: "",
    population: "9222",
    population_proper: "9222",
  },
  {
    city: "Nieuwleusen",
    lat: "52.5833",
    lng: "6.2833",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "",
    population: "9190",
    population_proper: "9190",
  },
  {
    city: "Ter Aar",
    lat: "52.1667",
    lng: "4.7167",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "9137",
    population_proper: "9137",
  },
  {
    city: "Groenlo",
    lat: "52.0500",
    lng: "6.6167",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "9119",
    population_proper: "9119",
  },
  {
    city: "Eemnes",
    lat: "52.2539",
    lng: "5.2572",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "minor",
    population: "9113",
    population_proper: "9113",
  },
  {
    city: "Heer",
    lat: "50.8406",
    lng: "5.7269",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "8985",
    population_proper: "8985",
  },
  {
    city: "Holten",
    lat: "52.2814",
    lng: "6.4186",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "",
    population: "8896",
    population_proper: "8896",
  },
  {
    city: "Abcoude",
    lat: "52.2719",
    lng: "4.9703",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "8791",
    population_proper: "8791",
  },
  {
    city: "Wervershoof",
    lat: "52.7347",
    lng: "5.1492",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "8746",
    population_proper: "8746",
  },
  {
    city: "Oudover",
    lat: "52.2000",
    lng: "5.1333",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "8737",
    population_proper: "8737",
  },
  {
    city: "Swalmen",
    lat: "51.2333",
    lng: "6.0333",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "8690",
    population_proper: "8690",
  },
  {
    city: "Dinxperlo",
    lat: "51.8667",
    lng: "6.4833",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "8565",
    population_proper: "8565",
  },
  {
    city: "Loenen",
    lat: "52.2419",
    lng: "5.0275",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "8562",
    population_proper: "8562",
  },
  {
    city: "Vorden",
    lat: "52.1000",
    lng: "6.3167",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "8491",
    population_proper: "8491",
  },
  {
    city: "Hoevelaken",
    lat: "52.1750",
    lng: "5.4583",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "8461",
    population_proper: "8461",
  },
  {
    city: "Hengelo",
    lat: "52.0500",
    lng: "6.3000",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "8457",
    population_proper: "8457",
  },
  {
    city: "Zoeterwoude",
    lat: "52.1136",
    lng: "4.5058",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "8450",
    population_proper: "8450",
  },
  {
    city: "Dirksland",
    lat: "51.7500",
    lng: "4.1000",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "8408",
    population_proper: "8408",
  },
  {
    city: "Assendelft",
    lat: "52.4667",
    lng: "4.7500",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "8335",
    population_proper: "8335",
  },
  {
    city: "Steenwijkerwold",
    lat: "52.8036",
    lng: "6.0617",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "",
    population: "8331",
    population_proper: "8331",
  },
  {
    city: "Heel",
    lat: "51.1806",
    lng: "5.8964",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "8295",
    population_proper: "8295",
  },
  {
    city: "Hoeven",
    lat: "51.5775",
    lng: "4.5808",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "8235",
    population_proper: "8235",
  },
  {
    city: "Oude Pekela",
    lat: "53.1000",
    lng: "7.0167",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "minor",
    population: "8214",
    population_proper: "8214",
  },
  {
    city: "Ouderkerk aan de Amstel",
    lat: "52.2972",
    lng: "4.9117",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "8200",
    population_proper: "8200",
  },
  {
    city: "Elsloo",
    lat: "50.9710",
    lng: "5.7541",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "8191",
    population_proper: "8191",
  },
  {
    city: "Ruurlo",
    lat: "52.0833",
    lng: "6.4500",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "8083",
    population_proper: "8083",
  },
  {
    city: "Oostvoorne",
    lat: "51.9119",
    lng: "4.1008",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "8055",
    population_proper: "8055",
  },
  {
    city: "Reusel",
    lat: "51.3617",
    lng: "5.1631",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "8030",
    population_proper: "8030",
  },
  {
    city: "Wognum",
    lat: "52.6819",
    lng: "5.0228",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "8006",
    population_proper: "8006",
  },
  {
    city: "Hasselt",
    lat: "52.5908",
    lng: "6.0911",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "minor",
    population: "7960",
    population_proper: "7960",
  },
  {
    city: "Moordrecht",
    lat: "51.9861",
    lng: "4.6681",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "7932",
    population_proper: "7932",
  },
  {
    city: "Heino",
    lat: "52.4358",
    lng: "6.2339",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "",
    population: "7930",
    population_proper: "7930",
  },
  {
    city: "Harmelen",
    lat: "52.0894",
    lng: "4.9608",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "7884",
    population_proper: "7884",
  },
  {
    city: "Venhuizen",
    lat: "52.6624",
    lng: "5.2000",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "7828",
    population_proper: "7828",
  },
  {
    city: "Wittem",
    lat: "50.8133",
    lng: "5.9122",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "7769",
    population_proper: "7769",
  },
  {
    city: "Numansdorp",
    lat: "51.7331",
    lng: "4.4381",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "7761",
    population_proper: "7761",
  },
  {
    city: "Schoonebeek",
    lat: "52.6617",
    lng: "6.8831",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Drenthe",
    capital: "",
    population: "7747",
    population_proper: "7747",
  },
  {
    city: "Anna Paulowna",
    lat: "52.8500",
    lng: "4.8167",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "7380",
    population_proper: "7380",
  },
  {
    city: "Ten Boer",
    lat: "53.2833",
    lng: "6.6833",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "minor",
    population: "7292",
    population_proper: "7292",
  },
  {
    city: "Lekkerkerk",
    lat: "51.8972",
    lng: "4.6828",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "7291",
    population_proper: "7291",
  },
  {
    city: "Gendt",
    lat: "51.8769",
    lng: "5.9725",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "7286",
    population_proper: "7286",
  },
  {
    city: "Markelo",
    lat: "52.2342",
    lng: "6.4953",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "",
    population: "7272",
    population_proper: "7272",
  },
  {
    city: "Norg",
    lat: "53.0661",
    lng: "6.4594",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Drenthe",
    capital: "",
    population: "7270",
    population_proper: "7270",
  },
  {
    city: "Sevenum",
    lat: "51.4167",
    lng: "6.0333",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "7234",
    population_proper: "7234",
  },
  {
    city: "Amerongen",
    lat: "52.0025",
    lng: "5.4608",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "7222",
    population_proper: "7222",
  },
  {
    city: "Sappemeer",
    lat: "53.1633",
    lng: "6.7767",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "",
    population: "7126",
    population_proper: "7126",
  },
  {
    city: "Rhoon",
    lat: "51.8667",
    lng: "4.4333",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "7004",
    population_proper: "7004",
  },
  {
    city: "Ruinen",
    lat: "52.7633",
    lng: "6.3564",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Drenthe",
    capital: "",
    population: "6974",
    population_proper: "6974",
  },
  {
    city: "Grubbenvorst",
    lat: "51.4189",
    lng: "6.1431",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "6960",
    population_proper: "6960",
  },
  {
    city: "Lienden",
    lat: "51.9467",
    lng: "5.5189",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "6880",
    population_proper: "6880",
  },
  {
    city: "Baarle-Nassau",
    lat: "51.4500",
    lng: "4.9333",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "6847",
    population_proper: "6847",
  },
  {
    city: "Maasland",
    lat: "51.9333",
    lng: "4.2667",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "6821",
    population_proper: "6821",
  },
  {
    city: "Teteringen",
    lat: "51.6086",
    lng: "4.8206",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "6695",
    population_proper: "6695",
  },
  {
    city: "Lith",
    lat: "51.8042",
    lng: "5.4408",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "6685",
    population_proper: "6685",
  },
  {
    city: "Oudorp",
    lat: "52.6331",
    lng: "4.7736",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "6666",
    population_proper: "6666",
  },
  {
    city: "Klundert",
    lat: "51.6628",
    lng: "4.5289",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "6601",
    population_proper: "6601",
  },
  {
    city: "Schoorl",
    lat: "52.7014",
    lng: "4.6944",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "6586",
    population_proper: "6586",
  },
  {
    city: "Bellingwolde",
    lat: "53.1203",
    lng: "7.1719",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "",
    population: "6578",
    population_proper: "6578",
  },
  {
    city: "Gramsbergen",
    lat: "52.6103",
    lng: "6.6728",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "",
    population: "6500",
    population_proper: "6500",
  },
  {
    city: "Obdam",
    lat: "52.6743",
    lng: "4.8995",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "6448",
    population_proper: "6448",
  },
  {
    city: "Andijk",
    lat: "52.7500",
    lng: "5.2200",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "6423",
    population_proper: "6423",
  },
  {
    city: "Erp",
    lat: "51.6003",
    lng: "5.6053",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "6422",
    population_proper: "6422",
  },
  {
    city: "Zevenhuizen",
    lat: "52.0108",
    lng: "4.5800",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "6414",
    population_proper: "6414",
  },
  {
    city: "Kerkwijk",
    lat: "51.7744",
    lng: "5.2194",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "6269",
    population_proper: "6269",
  },
  {
    city: "Groot-Valkenisse",
    lat: "51.4889",
    lng: "3.5156",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "6168",
    population_proper: "6168",
  },
  {
    city: "Rolde",
    lat: "52.9869",
    lng: "6.6461",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Drenthe",
    capital: "",
    population: "6131",
    population_proper: "6131",
  },
  {
    city: "Dussen",
    lat: "51.7317",
    lng: "4.9633",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "6127",
    population_proper: "6127",
  },
  {
    city: "Krimpen aan de Lek",
    lat: "51.8931",
    lng: "4.6275",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "6114",
    population_proper: "6114",
  },
  {
    city: "IJsselmonde",
    lat: "51.9000",
    lng: "4.5500",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "6100",
    population_proper: "6100",
  },
  {
    city: "Hooge Zwaluwe",
    lat: "51.6989",
    lng: "4.7245",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "6014",
    population_proper: "6014",
  },
  {
    city: "Maarn",
    lat: "52.0642",
    lng: "5.3731",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "5975",
    population_proper: "5975",
  },
  {
    city: "Eygelshoven",
    lat: "50.8925",
    lng: "6.0592",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "5954",
    population_proper: "5954",
  },
  {
    city: "Millingen aan de Rijn",
    lat: "51.8667",
    lng: "6.0500",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "5921",
    population_proper: "5921",
  },
  {
    city: "Gieten",
    lat: "53.0053",
    lng: "6.7631",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Drenthe",
    capital: "minor",
    population: "5877",
    population_proper: "5877",
  },
  {
    city: "Maurik",
    lat: "51.9600",
    lng: "5.4236",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "5865",
    population_proper: "5865",
  },
  {
    city: "Havelte",
    lat: "52.7739",
    lng: "6.2383",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Drenthe",
    capital: "",
    population: "5858",
    population_proper: "5858",
  },
  {
    city: "Bennebroek",
    lat: "52.3214",
    lng: "4.5967",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "5842",
    population_proper: "5842",
  },
  {
    city: "Moergestel",
    lat: "51.5500",
    lng: "5.1833",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "5835",
    population_proper: "5835",
  },
  {
    city: "Meijel",
    lat: "51.3500",
    lng: "5.8833",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "5789",
    population_proper: "5789",
  },
  {
    city: "Zeeland",
    lat: "51.6967",
    lng: "5.6731",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "5788",
    population_proper: "5788",
  },
  {
    city: "Berghem",
    lat: "51.7700",
    lng: "5.5747",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "5700",
    population_proper: "5700",
  },
  {
    city: "Puttershoek",
    lat: "51.8044",
    lng: "4.5681",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "5688",
    population_proper: "5688",
  },
  {
    city: "Rijsbergen",
    lat: "51.5167",
    lng: "4.7000",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "5665",
    population_proper: "5665",
  },
  {
    city: "Nieuwe Pekela",
    lat: "53.0786",
    lng: "6.9642",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "",
    population: "5604",
    population_proper: "5604",
  },
  {
    city: "Dalen",
    lat: "52.7000",
    lng: "6.7500",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Drenthe",
    capital: "",
    population: "5564",
    population_proper: "5564",
  },
  {
    city: "Westenschouwen",
    lat: "51.7050",
    lng: "3.7403",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "5540",
    population_proper: "5540",
  },
  {
    city: "Ouderkerk aan den IJssel",
    lat: "51.9322",
    lng: "4.6361",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "5516",
    population_proper: "5516",
  },
  {
    city: "Wilnis",
    lat: "52.1978",
    lng: "4.9000",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "5513",
    population_proper: "5513",
  },
  {
    city: "Limbricht",
    lat: "51.0117",
    lng: "5.8369",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "5471",
    population_proper: "5471",
  },
  {
    city: "Belfeld",
    lat: "51.3100",
    lng: "6.1144",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "5449",
    population_proper: "5449",
  },
  {
    city: "Bovenkarspel",
    lat: "52.7000",
    lng: "5.2500",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "5439",
    population_proper: "5439",
  },
  {
    city: "Schermerhorn",
    lat: "52.6067",
    lng: "4.8511",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "5359",
    population_proper: "5359",
  },
  {
    city: "Hoogkerk",
    lat: "53.2167",
    lng: "6.5000",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "",
    population: "5307",
    population_proper: "5307",
  },
  {
    city: "Renswoude",
    lat: "52.0739",
    lng: "5.5406",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "minor",
    population: "5259",
    population_proper: "5259",
  },
  {
    city: "Nederhorst den Berg",
    lat: "52.2567",
    lng: "5.0458",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "5241",
    population_proper: "5241",
  },
  {
    city: "Bathmen",
    lat: "52.2500",
    lng: "6.2869",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "",
    population: "5224",
    population_proper: "5224",
  },
  {
    city: "Warmond",
    lat: "52.1972",
    lng: "4.5017",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "5159",
    population_proper: "5159",
  },
  {
    city: "Stompwijk",
    lat: "52.0944",
    lng: "4.4697",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "5112",
    population_proper: "5112",
  },
  {
    city: "Velden",
    lat: "51.4117",
    lng: "6.1678",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "5085",
    population_proper: "5085",
  },
  {
    city: "Sint Pancras",
    lat: "52.6631",
    lng: "4.7853",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "5068",
    population_proper: "5068",
  },
  {
    city: "Arnemuiden",
    lat: "51.5000",
    lng: "3.6667",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "5055",
    population_proper: "5055",
  },
  {
    city: "Wanroij",
    lat: "51.6561",
    lng: "5.8200",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "5044",
    population_proper: "5044",
  },
  {
    city: "Peize",
    lat: "53.1478",
    lng: "6.4961",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Drenthe",
    capital: "",
    population: "5005",
    population_proper: "5005",
  },
  {
    city: "Steenderen",
    lat: "52.0667",
    lng: "6.1833",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "4990",
    population_proper: "4990",
  },
  {
    city: "Stramproy",
    lat: "51.1933",
    lng: "5.7192",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "4916",
    population_proper: "4916",
  },
  {
    city: "Uithuizen",
    lat: "53.4067",
    lng: "6.6722",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "minor",
    population: "4914",
    population_proper: "4914",
  },
  {
    city: "Urmond",
    lat: "50.9906",
    lng: "5.7711",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "4913",
    population_proper: "4913",
  },
  {
    city: "Waspik",
    lat: "51.6867",
    lng: "4.9444",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "4871",
    population_proper: "4871",
  },
  {
    city: "Uithuizermeeden",
    lat: "53.4144",
    lng: "6.7264",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "",
    population: "4859",
    population_proper: "4859",
  },
  {
    city: "Schinveld",
    lat: "50.9678",
    lng: "5.9803",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "minor",
    population: "4856",
    population_proper: "4856",
  },
  {
    city: "Bunde",
    lat: "50.8958",
    lng: "5.7331",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "4798",
    population_proper: "4798",
  },
  {
    city: "Muntendam",
    lat: "53.1336",
    lng: "6.8686",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "minor",
    population: "4748",
    population_proper: "4748",
  },
  {
    city: "Midwolda",
    lat: "53.1928",
    lng: "7.0161",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "",
    population: "4719",
    population_proper: "4719",
  },
  {
    city: "Oirsbeek",
    lat: "50.9489",
    lng: "5.9089",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "4717",
    population_proper: "4717",
  },
  {
    city: "Leimuiden",
    lat: "52.2247",
    lng: "4.6717",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "4711",
    population_proper: "4711",
  },
  {
    city: "Termunten",
    lat: "53.2961",
    lng: "7.0436",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "",
    population: "4694",
    population_proper: "4694",
  },
  {
    city: "Zwartsluis",
    lat: "52.6419",
    lng: "6.0703",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "",
    population: "4688",
    population_proper: "4688",
  },
  {
    city: "Koudekerk aan den Rijn",
    lat: "52.1347",
    lng: "4.6019",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "4671",
    population_proper: "4671",
  },
  {
    city: "Linschoten",
    lat: "52.0628",
    lng: "4.9144",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "4669",
    population_proper: "4669",
  },
  {
    city: "Helvoirt",
    lat: "51.6308",
    lng: "5.2297",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "4619",
    population_proper: "4619",
  },
  {
    city: "Hulsberg",
    lat: "50.8883",
    lng: "5.8553",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "4578",
    population_proper: "4578",
  },
  {
    city: "Giessenburg",
    lat: "51.8500",
    lng: "4.8897",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "4576",
    population_proper: "4576",
  },
  {
    city: "Bierum",
    lat: "53.3814",
    lng: "6.8594",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "",
    population: "4559",
    population_proper: "4559",
  },
  {
    city: "Ootmarsum",
    lat: "52.4072",
    lng: "6.8994",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "",
    population: "4530",
    population_proper: "4530",
  },
  {
    city: "Dodewaard",
    lat: "51.9103",
    lng: "5.6568",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "4528",
    population_proper: "4528",
  },
  {
    city: "Roggel",
    lat: "51.2625",
    lng: "5.9269",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "4507",
    population_proper: "4507",
  },
  {
    city: "Haastrecht",
    lat: "52.0003",
    lng: "4.7765",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "4418",
    population_proper: "4418",
  },
  {
    city: "Stolwijk",
    lat: "51.9736",
    lng: "4.7711",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "4344",
    population_proper: "4344",
  },
  {
    city: "Driel",
    lat: "51.9592",
    lng: "5.8131",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "4339",
    population_proper: "4339",
  },
  {
    city: "Driebruggen",
    lat: "52.0433",
    lng: "4.7986",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "4328",
    population_proper: "4328",
  },
  {
    city: "Berkhout",
    lat: "52.6419",
    lng: "4.9969",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "4321",
    population_proper: "4321",
  },
  {
    city: "Ammerzoden",
    lat: "51.7500",
    lng: "5.2200",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "4318",
    population_proper: "4318",
  },
  {
    city: "Den Dungen",
    lat: "51.6653",
    lng: "5.3714",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "4297",
    population_proper: "4297",
  },
  {
    city: "Ewijk",
    lat: "51.8833",
    lng: "5.7000",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "4258",
    population_proper: "4258",
  },
  {
    city: "Gasselte",
    lat: "52.9719",
    lng: "6.7922",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Drenthe",
    capital: "",
    population: "4227",
    population_proper: "4227",
  },
  {
    city: "Klimmen",
    lat: "50.8764",
    lng: "5.8819",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "4208",
    population_proper: "4208",
  },
  {
    city: "Kessel",
    lat: "51.2919",
    lng: "6.0533",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "4189",
    population_proper: "4189",
  },
  {
    city: "Mijnsheerenland",
    lat: "51.7964",
    lng: "4.4831",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "4185",
    population_proper: "4185",
  },
  {
    city: "Warmenhuizen",
    lat: "52.7225",
    lng: "4.7397",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "4140",
    population_proper: "4140",
  },
  {
    city: "Vleuten",
    lat: "52.1081",
    lng: "5.0150",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "4076",
    population_proper: "4076",
  },
  {
    city: "Voorhout",
    lat: "52.2231",
    lng: "4.4864",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "4060",
    population_proper: "4060",
  },
  {
    city: "Wedde",
    lat: "53.0667",
    lng: "7.0833",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "minor",
    population: "4057",
    population_proper: "4057",
  },
  {
    city: "Leende",
    lat: "51.3497",
    lng: "5.5525",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "4051",
    population_proper: "4051",
  },
  {
    city: "Leens",
    lat: "53.3609",
    lng: "6.3878",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "minor",
    population: "4048",
    population_proper: "4048",
  },
  {
    city: "Hedel",
    lat: "51.7467",
    lng: "5.2592",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "4042",
    population_proper: "4042",
  },
  {
    city: "Hoogkarspel",
    lat: "52.6947",
    lng: "5.1778",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "4034",
    population_proper: "4034",
  },
  {
    city: "Diessen",
    lat: "51.4739",
    lng: "5.1747",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "4020",
    population_proper: "4020",
  },
  {
    city: "Kortgene",
    lat: "51.5583",
    lng: "3.8000",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "3977",
    population_proper: "3977",
  },
  {
    city: "Cadier en Keer",
    lat: "50.8283",
    lng: "5.7678",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "3923",
    population_proper: "3923",
  },
  {
    city: "Delden",
    lat: "52.2625",
    lng: "6.7111",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "",
    population: "3857",
    population_proper: "3857",
  },
  {
    city: "Clinge",
    lat: "51.2706",
    lng: "4.0881",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "3815",
    population_proper: "3815",
  },
  {
    city: "Linne",
    lat: "51.1542",
    lng: "5.9392",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "3813",
    population_proper: "3813",
  },
  {
    city: "Wissekerke",
    lat: "51.5014",
    lng: "3.8454",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "3778",
    population_proper: "3778",
  },
  {
    city: "Barsingerhorn",
    lat: "52.7853",
    lng: "4.8417",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "3764",
    population_proper: "3764",
  },
  {
    city: "Putte",
    lat: "51.3506",
    lng: "4.3978",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "3758",
    population_proper: "3758",
  },
  {
    city: "Spaubeek",
    lat: "50.9378",
    lng: "5.8414",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "3718",
    population_proper: "3718",
  },
  {
    city: "Sommelsdijk",
    lat: "51.7547",
    lng: "4.1531",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "3715",
    population_proper: "3715",
  },
  {
    city: "Klein Mariekerke",
    lat: "51.5281",
    lng: "3.5314",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "3681",
    population_proper: "3681",
  },
  {
    city: "Klaaswaal",
    lat: "51.7694",
    lng: "4.4453",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "3647",
    population_proper: "3647",
  },
  {
    city: "Westerblokker",
    lat: "52.6644",
    lng: "5.1061",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "3641",
    population_proper: "3641",
  },
  {
    city: "Benthuizen",
    lat: "52.0761",
    lng: "4.5447",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "3639",
    population_proper: "3639",
  },
  {
    city: "Oud-Vroenhoven",
    lat: "50.8478",
    lng: "5.6514",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "3638",
    population_proper: "3638",
  },
  {
    city: "Zaamslag",
    lat: "51.3125",
    lng: "3.9131",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "3631",
    population_proper: "3631",
  },
  {
    city: "Wijk en Aalburg",
    lat: "51.7542",
    lng: "5.1306",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "3630",
    population_proper: "3630",
  },
  {
    city: "Chaam",
    lat: "51.5044",
    lng: "4.8614",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "3630",
    population_proper: "3630",
  },
  {
    city: "Beesd",
    lat: "51.8875",
    lng: "5.1911",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "3611",
    population_proper: "3611",
  },
  {
    city: "Neer",
    lat: "51.2614",
    lng: "5.9917",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "3591",
    population_proper: "3591",
  },
  {
    city: "Veur",
    lat: "52.0833",
    lng: "4.4333",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "3590",
    population_proper: "3590",
  },
  {
    city: "Vledder",
    lat: "52.8556",
    lng: "6.2103",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Drenthe",
    capital: "",
    population: "3590",
    population_proper: "3590",
  },
  {
    city: "Diever",
    lat: "52.8556",
    lng: "6.3175",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Drenthe",
    capital: "minor",
    population: "3571",
    population_proper: "3571",
  },
  {
    city: "Sint Odiliënberg",
    lat: "51.1425",
    lng: "6.0017",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "minor",
    population: "3565",
    population_proper: "3565",
  },
  {
    city: "Muiden",
    lat: "52.3292",
    lng: "5.0714",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "3535",
    population_proper: "3535",
  },
  {
    city: "Herten",
    lat: "51.1803",
    lng: "5.9633",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "3516",
    population_proper: "3516",
  },
  {
    city: "Zuid-Beijerland",
    lat: "51.7514",
    lng: "4.3678",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "3445",
    population_proper: "3445",
  },
  {
    city: "Moerkapelle",
    lat: "52.0456",
    lng: "4.5753",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "3444",
    population_proper: "3444",
  },
  {
    city: "Koudekerke",
    lat: "51.4808",
    lng: "3.5528",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "3419",
    population_proper: "3419",
  },
  {
    city: "Willemstad",
    lat: "51.6919",
    lng: "4.4378",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "3370",
    population_proper: "3370",
  },
  {
    city: "Amstenrade",
    lat: "50.9383",
    lng: "5.9222",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "3350",
    population_proper: "3350",
  },
  {
    city: "Meerlo",
    lat: "51.5119",
    lng: "6.0856",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "3317",
    population_proper: "3317",
  },
  {
    city: "Hoogwoud",
    lat: "52.7153",
    lng: "4.9367",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "3258",
    population_proper: "3258",
  },
  {
    city: "Ooltgensplaat",
    lat: "51.6825",
    lng: "4.3492",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "3230",
    population_proper: "3230",
  },
  {
    city: "Dreumel",
    lat: "51.8492",
    lng: "5.4325",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "3150",
    population_proper: "3150",
  },
  {
    city: "Overasselt",
    lat: "51.7597",
    lng: "5.7875",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "3124",
    population_proper: "3124",
  },
  {
    city: "Rossum",
    lat: "51.8008",
    lng: "5.3356",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "3102",
    population_proper: "3102",
  },
  {
    city: "Oude-Tonge",
    lat: "51.6914",
    lng: "4.2128",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "3101",
    population_proper: "3101",
  },
  {
    city: "Maasdam",
    lat: "51.7881",
    lng: "4.5561",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "3087",
    population_proper: "3087",
  },
  {
    city: "Kortenhoef",
    lat: "52.2397",
    lng: "5.1147",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "3085",
    population_proper: "3085",
  },
  {
    city: "Amby",
    lat: "50.8667",
    lng: "5.7333",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "3038",
    population_proper: "3038",
  },
  {
    city: "Dinther",
    lat: "51.6450",
    lng: "5.4864",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "3014",
    population_proper: "3014",
  },
  {
    city: "Meerkerk",
    lat: "51.9200",
    lng: "4.9942",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "3007",
    population_proper: "3007",
  },
  {
    city: "IJzendijke",
    lat: "51.3214",
    lng: "3.6164",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "3006",
    population_proper: "3006",
  },
  {
    city: "Eenrum",
    lat: "53.3625",
    lng: "6.4606",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "",
    population: "3001",
    population_proper: "3001",
  },
  {
    city: "Oudshoorn",
    lat: "52.1333",
    lng: "4.6667",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "2994",
    population_proper: "2994",
  },
  {
    city: "Geulle",
    lat: "50.9225",
    lng: "5.7503",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "2991",
    population_proper: "2991",
  },
  {
    city: "Deil",
    lat: "51.8842",
    lng: "5.2442",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "2955",
    population_proper: "2955",
  },
  {
    city: "Haps",
    lat: "51.6886",
    lng: "5.8608",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "2940",
    population_proper: "2940",
  },
  {
    city: "Kockengen",
    lat: "52.1517",
    lng: "4.9522",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "2935",
    population_proper: "2935",
  },
  {
    city: "Koedijk",
    lat: "52.6750",
    lng: "4.7483",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "2931",
    population_proper: "2931",
  },
  {
    city: "Zwammerdam",
    lat: "52.1058",
    lng: "4.7272",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "2922",
    population_proper: "2922",
  },
  {
    city: "Nieuw-Beijerland",
    lat: "51.8119",
    lng: "4.3425",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "2905",
    population_proper: "2905",
  },
  {
    city: "Luyksgestel",
    lat: "51.2900",
    lng: "5.3239",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "2872",
    population_proper: "2872",
  },
  {
    city: "Zevenhoven",
    lat: "52.1825",
    lng: "4.7789",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "2864",
    population_proper: "2864",
  },
  {
    city: "Dieren",
    lat: "52.0521",
    lng: "6.0974",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "2864",
    population_proper: "2864",
  },
  {
    city: "Grathem",
    lat: "51.1925",
    lng: "5.8578",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "2860",
    population_proper: "2860",
  },
  {
    city: "Nijeveen",
    lat: "52.7328",
    lng: "6.1675",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Drenthe",
    capital: "",
    population: "2860",
    population_proper: "2860",
  },
  {
    city: "Standdaarbuiten",
    lat: "51.6122",
    lng: "4.5144",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "2839",
    population_proper: "2839",
  },
  {
    city: "Zweeloo",
    lat: "52.7936",
    lng: "6.7303",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Drenthe",
    capital: "",
    population: "2834",
    population_proper: "2834",
  },
  {
    city: "Middelstum",
    lat: "53.3500",
    lng: "6.6500",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "",
    population: "2831",
    population_proper: "2831",
  },
  {
    city: "Ameide",
    lat: "51.9539",
    lng: "4.9625",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "2808",
    population_proper: "2808",
  },
  {
    city: "Buurmalsen",
    lat: "51.8928",
    lng: "5.2925",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "2785",
    population_proper: "2785",
  },
  {
    city: "Koewacht",
    lat: "51.2333",
    lng: "3.9667",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "2757",
    population_proper: "2757",
  },
  {
    city: "Broek in Waterland",
    lat: "52.4342",
    lng: "4.9958",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "2756",
    population_proper: "2756",
  },
  {
    city: "Hallum",
    lat: "53.3064",
    lng: "5.7856",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Fryslân",
    capital: "",
    population: "2751",
    population_proper: "2751",
  },
  {
    city: "Berlicum",
    lat: "51.6772",
    lng: "5.4000",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "2715",
    population_proper: "2715",
  },
  {
    city: "Gouderak",
    lat: "51.9842",
    lng: "4.6767",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "2704",
    population_proper: "2704",
  },
  {
    city: "Buren",
    lat: "51.9108",
    lng: "5.3336",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "2701",
    population_proper: "2701",
  },
  {
    city: "Asperen",
    lat: "51.8814",
    lng: "5.1067",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "2695",
    population_proper: "2695",
  },
  {
    city: "Callantsoog",
    lat: "52.8372",
    lng: "4.6967",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "2680",
    population_proper: "2680",
  },
  {
    city: "Grevenbicht",
    lat: "51.0417",
    lng: "5.7728",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "2626",
    population_proper: "2626",
  },
  {
    city: "Giethoorn",
    lat: "52.7397",
    lng: "6.0775",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "",
    population: "2620",
    population_proper: "2620",
  },
  {
    city: "Udenhout",
    lat: "51.6094",
    lng: "5.1403",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "2604",
    population_proper: "2604",
  },
  {
    city: "Thorn",
    lat: "51.1667",
    lng: "5.8333",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "2602",
    population_proper: "2602",
  },
  {
    city: "Oldehove",
    lat: "53.3025",
    lng: "6.3928",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "",
    population: "2600",
    population_proper: "2600",
  },
  {
    city: "Nieuwendam",
    lat: "52.3915",
    lng: "4.9341",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "2587",
    population_proper: "2587",
  },
  {
    city: "Vlodrop",
    lat: "51.1333",
    lng: "6.0739",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "2578",
    population_proper: "2578",
  },
  {
    city: "Capelle",
    lat: "51.6922",
    lng: "4.9853",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "2551",
    population_proper: "2551",
  },
  {
    city: "Varsseveld",
    lat: "51.9433",
    lng: "6.4594",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "2545",
    population_proper: "2545",
  },
  {
    city: "Wemeldinge",
    lat: "51.5175",
    lng: "3.9956",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "2537",
    population_proper: "2537",
  },
  {
    city: "Groede",
    lat: "51.3789",
    lng: "3.5069",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "2537",
    population_proper: "2537",
  },
  {
    city: "Nieuw-Helvoet",
    lat: "51.8381",
    lng: "4.1297",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "2507",
    population_proper: "2507",
  },
  {
    city: "Maarsseveen",
    lat: "52.1408",
    lng: "5.0736",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "2506",
    population_proper: "2506",
  },
  {
    city: "Bergharen",
    lat: "51.8500",
    lng: "5.6689",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "2482",
    population_proper: "2482",
  },
  {
    city: "Heeswijk",
    lat: "51.6514",
    lng: "5.4672",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "2476",
    population_proper: "2476",
  },
  {
    city: "Baexem",
    lat: "51.2244",
    lng: "5.8800",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "2469",
    population_proper: "2469",
  },
  {
    city: "Stedum",
    lat: "53.3225",
    lng: "6.6958",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "",
    population: "2466",
    population_proper: "2466",
  },
  {
    city: "Cothen",
    lat: "51.9961",
    lng: "5.3097",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "2458",
    population_proper: "2458",
  },
  {
    city: "Streefkerk",
    lat: "51.8994",
    lng: "4.7419",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "2438",
    population_proper: "2438",
  },
  {
    city: "Loppersum",
    lat: "53.3317",
    lng: "6.7469",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "minor",
    population: "2435",
    population_proper: "2435",
  },
  {
    city: "Stoutenburg",
    lat: "52.1539",
    lng: "5.4325",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "2433",
    population_proper: "2433",
  },
  {
    city: "Herwijnen",
    lat: "51.8253",
    lng: "5.1303",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "2418",
    population_proper: "2418",
  },
  {
    city: "Broek op Langedijk",
    lat: "52.6725",
    lng: "4.8056",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "2408",
    population_proper: "2408",
  },
  {
    city: "Geervliet",
    lat: "51.8611",
    lng: "4.2622",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "2393",
    population_proper: "2393",
  },
  {
    city: "Avenhorn",
    lat: "52.6200",
    lng: "4.9542",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "2330",
    population_proper: "2330",
  },
  {
    city: "Ursem",
    lat: "52.6272",
    lng: "4.8917",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "2323",
    population_proper: "2323",
  },
  {
    city: "Sambeek",
    lat: "51.6361",
    lng: "5.9661",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "2295",
    population_proper: "2295",
  },
  {
    city: "Oldekerk",
    lat: "53.2194",
    lng: "6.3389",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "",
    population: "2282",
    population_proper: "2282",
  },
  {
    city: "Nieuwolda",
    lat: "53.2447",
    lng: "6.9758",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "",
    population: "2282",
    population_proper: "2282",
  },
  {
    city: "Kantens",
    lat: "53.3656",
    lng: "6.6339",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "",
    population: "2248",
    population_proper: "2248",
  },
  {
    city: "Pannerden",
    lat: "51.8900",
    lng: "6.0378",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "2244",
    population_proper: "2244",
  },
  {
    city: "Spanbroek",
    lat: "52.6981",
    lng: "4.9608",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "2232",
    population_proper: "2232",
  },
  {
    city: "Nieuw-Vossemeer",
    lat: "51.5897",
    lng: "4.2181",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "2219",
    population_proper: "2219",
  },
  {
    city: "Winkel",
    lat: "52.7519",
    lng: "4.9036",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "2217",
    population_proper: "2217",
  },
  {
    city: "Nieuwe-Tonge",
    lat: "51.7150",
    lng: "4.1653",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "2190",
    population_proper: "2190",
  },
  {
    city: "Ezinge",
    lat: "53.3092",
    lng: "6.4431",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "",
    population: "2152",
    population_proper: "2152",
  },
  {
    city: "Sint Maarten",
    lat: "52.7717",
    lng: "4.7475",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "2139",
    population_proper: "2139",
  },
  {
    city: "Wadenoijen",
    lat: "51.8736",
    lng: "5.3722",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "2136",
    population_proper: "2136",
  },
  {
    city: "Huijbergen",
    lat: "51.4325",
    lng: "4.3744",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "2133",
    population_proper: "2133",
  },
  {
    city: "Westmaas",
    lat: "51.7833",
    lng: "4.4667",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "2126",
    population_proper: "2126",
  },
  {
    city: "Geesteren",
    lat: "52.1389",
    lng: "6.5264",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "2116",
    population_proper: "2116",
  },
  {
    city: "Tricht",
    lat: "51.8900",
    lng: "5.2725",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "2112",
    population_proper: "2112",
  },
  {
    city: "Waardenburg",
    lat: "51.8322",
    lng: "5.2558",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "2106",
    population_proper: "2106",
  },
  {
    city: "Vaassen",
    lat: "52.2881",
    lng: "5.9694",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "2087",
    population_proper: "2087",
  },
  {
    city: "Nederlangbroek",
    lat: "52.0122",
    lng: "5.3269",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "2075",
    population_proper: "2075",
  },
  {
    city: "Nieuwe-Niedorp",
    lat: "52.7380",
    lng: "4.8962",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "2049",
    population_proper: "2049",
  },
  {
    city: "Melissant",
    lat: "51.7681",
    lng: "4.0761",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "2035",
    population_proper: "2035",
  },
  {
    city: "Ransdorp",
    lat: "52.3931",
    lng: "4.9942",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "2013",
    population_proper: "2013",
  },
  {
    city: "Riethoven",
    lat: "51.3536",
    lng: "5.3864",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "2003",
    population_proper: "2003",
  },
  {
    city: "De Rijp",
    lat: "52.5569",
    lng: "4.8453",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "1998",
    population_proper: "1998",
  },
  {
    city: "Meeden",
    lat: "53.1389",
    lng: "6.9275",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "",
    population: "1989",
    population_proper: "1989",
  },
  {
    city: "Noord-Scharwoude",
    lat: "52.7006",
    lng: "4.8103",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "1977",
    population_proper: "1977",
  },
  {
    city: "Op-Andel",
    lat: "51.7839",
    lng: "5.0539",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "1977",
    population_proper: "1977",
  },
  {
    city: "Schalkwijk",
    lat: "51.9939",
    lng: "5.1872",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "1971",
    population_proper: "1971",
  },
  {
    city: "Oud-Alblas",
    lat: "51.8569",
    lng: "4.7047",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "1970",
    population_proper: "1970",
  },
  {
    city: "Usquert",
    lat: "53.4028",
    lng: "6.6128",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "",
    population: "1967",
    population_proper: "1967",
  },
  {
    city: "Neeritter",
    lat: "51.1644",
    lng: "5.8036",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "1950",
    population_proper: "1950",
  },
  {
    city: "Stellendam",
    lat: "51.8047",
    lng: "4.0242",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "1950",
    population_proper: "1950",
  },
  {
    city: "Stavenisse",
    lat: "51.5861",
    lng: "4.0122",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "1945",
    population_proper: "1945",
  },
  {
    city: "Esch",
    lat: "51.6103",
    lng: "5.2906",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "1937",
    population_proper: "1937",
  },
  {
    city: "Den Bommel",
    lat: "51.7153",
    lng: "4.2850",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "1937",
    population_proper: "1937",
  },
  {
    city: "Zegveld",
    lat: "52.1161",
    lng: "4.8347",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "1909",
    population_proper: "1909",
  },
  {
    city: "Hegelsom",
    lat: "51.4400",
    lng: "6.0375",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "1907",
    population_proper: "1907",
  },
  {
    city: "Broekhuizen",
    lat: "51.4853",
    lng: "6.1633",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "1906",
    population_proper: "1906",
  },
  {
    city: "Everdingen",
    lat: "51.9647",
    lng: "5.1564",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "1894",
    population_proper: "1894",
  },
  {
    city: "Merkelbeek",
    lat: "50.9542",
    lng: "5.9403",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "1874",
    population_proper: "1874",
  },
  {
    city: "Oudkarspel",
    lat: "52.7114",
    lng: "4.8017",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "1873",
    population_proper: "1873",
  },
  {
    city: "Vierpolders",
    lat: "51.8783",
    lng: "4.1794",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "1872",
    population_proper: "1872",
  },
  {
    city: "Nieuwenhoorn",
    lat: "51.8542",
    lng: "4.1453",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "1866",
    population_proper: "1866",
  },
  {
    city: "Mheer",
    lat: "50.7828",
    lng: "5.7931",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "1851",
    population_proper: "1851",
  },
  {
    city: "Stoppeldijkveer",
    lat: "51.3219",
    lng: "3.9856",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "1822",
    population_proper: "1822",
  },
  {
    city: "Zuidland",
    lat: "51.8219",
    lng: "4.2581",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "1808",
    population_proper: "1808",
  },
  {
    city: "Buiksloot",
    lat: "52.3989",
    lng: "4.9221",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "1801",
    population_proper: "1801",
  },
  {
    city: "Herpen",
    lat: "51.7717",
    lng: "5.6417",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "1801",
    population_proper: "1801",
  },
  {
    city: "Grootebroek",
    lat: "52.6989",
    lng: "5.2189",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "1796",
    population_proper: "1796",
  },
  {
    city: "Wanneperveen",
    lat: "52.7044",
    lng: "6.1206",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "",
    population: "1788",
    population_proper: "1788",
  },
  {
    city: "Westwoud",
    lat: "52.6864",
    lng: "5.1333",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "1743",
    population_proper: "1743",
  },
  {
    city: "Zuid-Scharwoude",
    lat: "52.6842",
    lng: "4.8081",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "1732",
    population_proper: "1732",
  },
  {
    city: "Beltrum",
    lat: "52.0689",
    lng: "6.5658",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "1725",
    population_proper: "1725",
  },
  {
    city: "Goudswaard",
    lat: "51.7936",
    lng: "4.2783",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "1723",
    population_proper: "1723",
  },
  {
    city: "Besoijen",
    lat: "51.6833",
    lng: "5.0500",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "1705",
    population_proper: "1705",
  },
  {
    city: "Baardwijk",
    lat: "51.6919",
    lng: "5.0956",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "1705",
    population_proper: "1705",
  },
  {
    city: "Rozendaal",
    lat: "52.0000",
    lng: "5.9667",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "1704",
    population_proper: "1704",
  },
  {
    city: "Harfsen",
    lat: "52.2075",
    lng: "6.2972",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "1678",
    population_proper: "1678",
  },
  {
    city: "Jaarsveld",
    lat: "51.9694",
    lng: "4.9772",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "1672",
    population_proper: "1672",
  },
  {
    city: "Blokzijl",
    lat: "52.7264",
    lng: "5.9617",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "",
    population: "1666",
    population_proper: "1666",
  },
  {
    city: "Graft",
    lat: "52.5608",
    lng: "4.8339",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "1651",
    population_proper: "1651",
  },
  {
    city: "Veere",
    lat: "51.5500",
    lng: "3.6667",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "1650",
    population_proper: "1650",
  },
  {
    city: "Terborg",
    lat: "51.9203",
    lng: "6.3575",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "1643",
    population_proper: "1643",
  },
  {
    city: "Engelen",
    lat: "51.7208",
    lng: "5.2658",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "1617",
    population_proper: "1617",
  },
  {
    city: "Borssele",
    lat: "51.4231",
    lng: "3.7353",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "1615",
    population_proper: "1615",
  },
  {
    city: "Oude-Niedorp",
    lat: "52.7178",
    lng: "4.8719",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "1609",
    population_proper: "1609",
  },
  {
    city: "Stevensweert",
    lat: "51.1292",
    lng: "5.8483",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "1588",
    population_proper: "1588",
  },
  {
    city: "Werkhoven",
    lat: "52.0239",
    lng: "5.2431",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "1580",
    population_proper: "1580",
  },
  {
    city: "Schin op Geul",
    lat: "50.8542",
    lng: "5.8694",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "1579",
    population_proper: "1579",
  },
  {
    city: "Veen",
    lat: "51.7769",
    lng: "5.1097",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "1579",
    population_proper: "1579",
  },
  {
    city: "Boven-Leeuwen",
    lat: "51.8842",
    lng: "5.5294",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "1565",
    population_proper: "1565",
  },
  {
    city: "Colijnsplaat",
    lat: "51.5986",
    lng: "3.8481",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "1563",
    population_proper: "1563",
  },
  {
    city: "Aagtekerke",
    lat: "51.5464",
    lng: "3.5094",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "1556",
    population_proper: "1556",
  },
  {
    city: "Domburg",
    lat: "51.5625",
    lng: "3.4964",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "minor",
    population: "1553",
    population_proper: "1553",
  },
  {
    city: "Noordeloos",
    lat: "51.9042",
    lng: "4.9417",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "1536",
    population_proper: "1536",
  },
  {
    city: "Oosterbeek",
    lat: "51.9847",
    lng: "5.8444",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "1533",
    population_proper: "1533",
  },
  {
    city: "Oudenhoorn",
    lat: "51.8269",
    lng: "4.1917",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "1530",
    population_proper: "1530",
  },
  {
    city: "Bredevoort",
    lat: "51.9500",
    lng: "6.6167",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "1525",
    population_proper: "1525",
  },
  {
    city: "Ochten",
    lat: "51.9078",
    lng: "5.5678",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "1522",
    population_proper: "1522",
  },
  {
    city: "Gronsveld",
    lat: "50.8086",
    lng: "5.7300",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "1517",
    population_proper: "1517",
  },
  {
    city: "Opperdoes",
    lat: "52.7597",
    lng: "5.0781",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "1512",
    population_proper: "1512",
  },
  {
    city: "Abbekerk",
    lat: "52.7308",
    lng: "5.0175",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "1495",
    population_proper: "1495",
  },
  {
    city: "Sint Geertruid",
    lat: "50.7964",
    lng: "5.7656",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "1482",
    population_proper: "1482",
  },
  {
    city: "Wieringerwaard",
    lat: "52.8400",
    lng: "4.8678",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "1479",
    population_proper: "1479",
  },
  {
    city: "Nieuwkuijk",
    lat: "51.6936",
    lng: "5.1806",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "1472",
    population_proper: "1472",
  },
  {
    city: "Hoornaar",
    lat: "51.8767",
    lng: "4.9469",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "1468",
    population_proper: "1468",
  },
  {
    city: "Nijbroek",
    lat: "52.2928",
    lng: "6.0625",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "1455",
    population_proper: "1455",
  },
  {
    city: "Berkenwoude",
    lat: "51.9453",
    lng: "4.7089",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "1449",
    population_proper: "1449",
  },
  {
    city: "Schoonrewoerd",
    lat: "51.9206",
    lng: "5.1158",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "1448",
    population_proper: "1448",
  },
  {
    city: "Midwoud",
    lat: "52.7178",
    lng: "5.0736",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "1440",
    population_proper: "1440",
  },
  {
    city: "Velp",
    lat: "51.9947",
    lng: "5.9758",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "1437",
    population_proper: "1437",
  },
  {
    city: "Horssen",
    lat: "51.8556",
    lng: "5.6086",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "1418",
    population_proper: "1418",
  },
  {
    city: "Wanssum",
    lat: "51.5358",
    lng: "6.0753",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "1414",
    population_proper: "1414",
  },
  {
    city: "Piershil",
    lat: "51.7933",
    lng: "4.3147",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "1406",
    population_proper: "1406",
  },
  {
    city: "Hoogblokland",
    lat: "51.8742",
    lng: "4.9750",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "1392",
    population_proper: "1392",
  },
  {
    city: "Hedikhuizen",
    lat: "51.7356",
    lng: "5.1839",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "1387",
    population_proper: "1387",
  },
  {
    city: "Herveld",
    lat: "51.8989",
    lng: "5.7475",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "1383",
    population_proper: "1383",
  },
  {
    city: "Vreeland",
    lat: "52.2297",
    lng: "5.0311",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "1373",
    population_proper: "1373",
  },
  {
    city: "Zwartewaal",
    lat: "51.8833",
    lng: "4.2208",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "1366",
    population_proper: "1366",
  },
  {
    city: "Varik",
    lat: "51.8233",
    lng: "5.3692",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "1363",
    population_proper: "1363",
  },
  {
    city: "Garderen",
    lat: "52.2331",
    lng: "5.7139",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "1361",
    population_proper: "1361",
  },
  {
    city: "Schimmert",
    lat: "50.9056",
    lng: "5.8219",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "1356",
    population_proper: "1356",
  },
  {
    city: "Brouwershaven",
    lat: "51.7264",
    lng: "3.9142",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "1352",
    population_proper: "1352",
  },
  {
    city: "Lunteren",
    lat: "52.0872",
    lng: "5.6219",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "1328",
    population_proper: "1328",
  },
  {
    city: "Stad aan ’t Haringvliet",
    lat: "51.7392",
    lng: "4.2419",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "1323",
    population_proper: "1323",
  },
  {
    city: "Wijdewormer",
    lat: "52.5002",
    lng: "4.8924",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "1321",
    population_proper: "1321",
  },
  {
    city: "Vinkeveen",
    lat: "52.2128",
    lng: "4.9272",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "1316",
    population_proper: "1316",
  },
  {
    city: "Oudenrijn",
    lat: "52.0819",
    lng: "5.0600",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "1312",
    population_proper: "1312",
  },
  {
    city: "Rijswijk",
    lat: "51.7975",
    lng: "5.0236",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "1310",
    population_proper: "1310",
  },
  {
    city: "Zes Gehuchten",
    lat: "51.4211",
    lng: "5.5378",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "1302",
    population_proper: "1302",
  },
  {
    city: "Rijnsaterwoude",
    lat: "52.1986",
    lng: "4.6694",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "1300",
    population_proper: "1300",
  },
  {
    city: "Netterden",
    lat: "51.8544",
    lng: "6.3142",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "1300",
    population_proper: "1300",
  },
  {
    city: "Etten",
    lat: "51.9167",
    lng: "6.3364",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "1281",
    population_proper: "1281",
  },
  {
    city: "Eede",
    lat: "51.2467",
    lng: "3.4436",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "1275",
    population_proper: "1275",
  },
  {
    city: "Kattendijke",
    lat: "51.5228",
    lng: "3.9433",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "1274",
    population_proper: "1274",
  },
  {
    city: "Posterholt",
    lat: "51.1219",
    lng: "6.0378",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "1270",
    population_proper: "1270",
  },
  {
    city: "Leerbroek",
    lat: "51.9089",
    lng: "5.0514",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "1263",
    population_proper: "1263",
  },
  {
    city: "Oosthuizen",
    lat: "52.5708",
    lng: "4.9958",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "1262",
    population_proper: "1262",
  },
  {
    city: "Rijsenburg",
    lat: "52.0436",
    lng: "5.2531",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "1251",
    population_proper: "1251",
  },
  {
    city: "Nigtevecht",
    lat: "52.2742",
    lng: "5.0278",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "1245",
    population_proper: "1245",
  },
  {
    city: "Hoogvliet",
    lat: "51.8667",
    lng: "4.3500",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "1227",
    population_proper: "1227",
  },
  {
    city: "Wilp",
    lat: "52.2186",
    lng: "6.1497",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "1221",
    population_proper: "1221",
  },
  {
    city: "Kedichem",
    lat: "51.8600",
    lng: "5.0503",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "1217",
    population_proper: "1217",
  },
  {
    city: "Nieuwpoort",
    lat: "51.9347",
    lng: "4.8681",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "1213",
    population_proper: "1213",
  },
  {
    city: "Giessen",
    lat: "51.7886",
    lng: "5.0328",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "1209",
    population_proper: "1209",
  },
  {
    city: "Zuilichem",
    lat: "51.8086",
    lng: "5.1344",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "1199",
    population_proper: "1199",
  },
  {
    city: "Ophemert",
    lat: "51.8453",
    lng: "5.3842",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "1187",
    population_proper: "1187",
  },
  {
    city: "Voorthuizen",
    lat: "52.1861",
    lng: "5.6067",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "1185",
    population_proper: "1185",
  },
  {
    city: "Groot-Ammers",
    lat: "51.9236",
    lng: "4.8206",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "1173",
    population_proper: "1173",
  },
  {
    city: "Staveren",
    lat: "52.8833",
    lng: "5.3583",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Fryslân",
    capital: "",
    population: "1172",
    population_proper: "1172",
  },
  {
    city: "Bleskensgraaf",
    lat: "51.8719",
    lng: "4.7828",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "1167",
    population_proper: "1167",
  },
  {
    city: "Hooge Mierde",
    lat: "51.3986",
    lng: "5.1409",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "1164",
    population_proper: "1164",
  },
  {
    city: "Heerewaarden",
    lat: "51.8178",
    lng: "5.3917",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "1149",
    population_proper: "1149",
  },
  {
    city: "Genderen",
    lat: "51.7353",
    lng: "5.0878",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "1149",
    population_proper: "1149",
  },
  {
    city: "Noorbeek",
    lat: "50.7681",
    lng: "5.8131",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "1147",
    population_proper: "1147",
  },
  {
    city: "Polsbroek",
    lat: "51.9781",
    lng: "4.8519",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "1139",
    population_proper: "1139",
  },
  {
    city: "Brandwijk",
    lat: "51.8886",
    lng: "4.8136",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "1139",
    population_proper: "1139",
  },
  {
    city: "Hekelingen",
    lat: "51.8267",
    lng: "4.3422",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "1125",
    population_proper: "1125",
  },
  {
    city: "Westkapelle",
    lat: "51.5293",
    lng: "3.4409",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "1115",
    population_proper: "1115",
  },
  {
    city: "Nieuwerkerke",
    lat: "51.7000",
    lng: "3.8903",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "1108",
    population_proper: "1108",
  },
  {
    city: "Schelluinen",
    lat: "51.8425",
    lng: "4.9258",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "1103",
    population_proper: "1103",
  },
  {
    city: "Zuidzande",
    lat: "51.3411",
    lng: "3.4519",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "1098",
    population_proper: "1098",
  },
  {
    city: "Odijk",
    lat: "52.0503",
    lng: "5.2333",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "minor",
    population: "1097",
    population_proper: "1097",
  },
  {
    city: "Oud-Heusden",
    lat: "51.7231",
    lng: "5.1361",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "1082",
    population_proper: "1082",
  },
  {
    city: "Ankeveen",
    lat: "52.2653",
    lng: "5.0989",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "1073",
    population_proper: "1073",
  },
  {
    city: "Willeskop",
    lat: "52.0242",
    lng: "4.9014",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "1070",
    population_proper: "1070",
  },
  {
    city: "Herkingen",
    lat: "51.7100",
    lng: "4.0872",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "1066",
    population_proper: "1066",
  },
  {
    city: "Zonnemaire",
    lat: "51.7125",
    lng: "3.9508",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "1060",
    population_proper: "1060",
  },
  {
    city: "Nederhemert",
    lat: "51.7652",
    lng: "5.1682",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "1058",
    population_proper: "1058",
  },
  {
    city: "Lent",
    lat: "51.8631",
    lng: "5.8667",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "1053",
    population_proper: "1053",
  },
  {
    city: "Twello",
    lat: "52.2333",
    lng: "6.1022",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "1051",
    population_proper: "1051",
  },
  {
    city: "Kerkbuurt",
    lat: "52.4581",
    lng: "5.1067",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "1036",
    population_proper: "1036",
  },
  {
    city: "Barwoutswaarder",
    lat: "52.0825",
    lng: "4.8636",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "1035",
    population_proper: "1035",
  },
  {
    city: "Goudriaan",
    lat: "51.9028",
    lng: "4.8881",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "1033",
    population_proper: "1033",
  },
  {
    city: "Oranjestad",
    lat: "17.4833",
    lng: "-62.9833",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "",
    capital: "admin",
    population: "1021",
    population_proper: "1021",
  },
  {
    city: "Waarder",
    lat: "52.0614",
    lng: "4.8225",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "1014",
    population_proper: "1014",
  },
  {
    city: "Doorwerth",
    lat: "51.9781",
    lng: "5.7953",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "1009",
    population_proper: "1009",
  },
  {
    city: "Reek",
    lat: "51.7447",
    lng: "5.6831",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "1007",
    population_proper: "1007",
  },
  {
    city: "Jisp",
    lat: "52.5000",
    lng: "4.8333",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "1001",
    population_proper: "1001",
  },
  {
    city: "Twisk",
    lat: "52.7403",
    lng: "5.0517",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "992",
    population_proper: "992",
  },
  {
    city: "Zuiddorpe",
    lat: "51.2342",
    lng: "3.9017",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "983",
    population_proper: "983",
  },
  {
    city: "Groote Lindt",
    lat: "51.8106",
    lng: "4.6289",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "981",
    population_proper: "981",
  },
  {
    city: "Schore",
    lat: "51.4625",
    lng: "3.9989",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "978",
    population_proper: "978",
  },
  {
    city: "Molenaarsgraaf",
    lat: "51.8767",
    lng: "4.8231",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "970",
    population_proper: "970",
  },
  {
    city: "Oterleek",
    lat: "52.6333",
    lng: "4.8417",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "969",
    population_proper: "969",
  },
  {
    city: "Lierop",
    lat: "51.4178",
    lng: "5.6819",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "944",
    population_proper: "944",
  },
  {
    city: "Dalem",
    lat: "51.8278",
    lng: "5.0094",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "937",
    population_proper: "937",
  },
  {
    city: "Schiermonnikoog",
    lat: "53.4892",
    lng: "6.2022",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Fryslân",
    capital: "minor",
    population: "936",
    population_proper: "936",
  },
  {
    city: "Veessen",
    lat: "52.3761",
    lng: "6.0892",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "928",
    population_proper: "928",
  },
  {
    city: "Middelie",
    lat: "52.5322",
    lng: "5.0172",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "921",
    population_proper: "921",
  },
  {
    city: "Grijpskerke",
    lat: "51.5339",
    lng: "3.5617",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "920",
    population_proper: "920",
  },
  {
    city: "Rilland",
    lat: "51.4164",
    lng: "4.1808",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "917",
    population_proper: "917",
  },
  {
    city: "Ossenisse",
    lat: "51.3883",
    lng: "3.9778",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "904",
    population_proper: "904",
  },
  {
    city: "Broeksittard",
    lat: "51.0000",
    lng: "5.8833",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "899",
    population_proper: "899",
  },
  {
    city: "Sloten",
    lat: "52.8944",
    lng: "5.6453",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Fryslân",
    capital: "",
    population: "899",
    population_proper: "899",
  },
  {
    city: "Achttienhoven",
    lat: "52.1483",
    lng: "5.1389",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "898",
    population_proper: "898",
  },
  {
    city: "Ottoland",
    lat: "51.8931",
    lng: "4.8756",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "895",
    population_proper: "895",
  },
  {
    city: "Bennekom",
    lat: "51.9994",
    lng: "5.6756",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "885",
    population_proper: "885",
  },
  {
    city: "Noordgouwe",
    lat: "51.6936",
    lng: "3.9386",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "882",
    population_proper: "882",
  },
  {
    city: "Veldhuizen",
    lat: "52.0764",
    lng: "5.0075",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "869",
    population_proper: "869",
  },
  {
    city: "Hall",
    lat: "52.1100",
    lng: "6.1014",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "850",
    population_proper: "850",
  },
  {
    city: "Hekendorp",
    lat: "52.0153",
    lng: "4.8092",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "843",
    population_proper: "843",
  },
  {
    city: "Sluipwijk",
    lat: "52.0553",
    lng: "4.7419",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "840",
    population_proper: "840",
  },
  {
    city: "Snelrewaard",
    lat: "52.0331",
    lng: "4.9169",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "799",
    population_proper: "799",
  },
  {
    city: "Schellinkhout",
    lat: "52.6342",
    lng: "5.1203",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "792",
    population_proper: "792",
  },
  {
    city: "Oudelande",
    lat: "51.4083",
    lng: "3.8536",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "779",
    population_proper: "779",
  },
  {
    city: "Rietveld",
    lat: "52.0897",
    lng: "4.8511",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "767",
    population_proper: "767",
  },
  {
    city: "Ellewoutsdijk",
    lat: "51.3903",
    lng: "3.8158",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "761",
    population_proper: "761",
  },
  {
    city: "Buggenum",
    lat: "51.2314",
    lng: "5.9825",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "755",
    population_proper: "755",
  },
  {
    city: "Jabeek",
    lat: "50.9808",
    lng: "5.9417",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "754",
    population_proper: "754",
  },
  {
    city: "Meliskerke",
    lat: "51.5153",
    lng: "3.5119",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "749",
    population_proper: "749",
  },
  {
    city: "Lithoijen",
    lat: "51.8025",
    lng: "5.4633",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "746",
    population_proper: "746",
  },
  {
    city: "Grafhorst",
    lat: "52.5825",
    lng: "5.9339",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "",
    population: "739",
    population_proper: "739",
  },
  {
    city: "Hoenkoop",
    lat: "52.0153",
    lng: "4.8092",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "724",
    population_proper: "724",
  },
  {
    city: "Sint Kruis",
    lat: "51.2731",
    lng: "3.4997",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "721",
    population_proper: "721",
  },
  {
    city: "Winssen",
    lat: "51.8833",
    lng: "5.7000",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "708",
    population_proper: "708",
  },
  {
    city: "Batenburg",
    lat: "51.8233",
    lng: "5.6278",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "700",
    population_proper: "700",
  },
  {
    city: "Loenersloot",
    lat: "52.2297",
    lng: "5.0014",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "700",
    population_proper: "700",
  },
  {
    city: "Vlierden",
    lat: "51.4456",
    lng: "5.7581",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "699",
    population_proper: "699",
  },
  {
    city: "Opijnen",
    lat: "51.8278",
    lng: "5.2983",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "698",
    population_proper: "698",
  },
  {
    city: "Waterlandkerkje",
    lat: "51.3175",
    lng: "3.5539",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "689",
    population_proper: "689",
  },
  {
    city: "Slenaken",
    lat: "50.7678",
    lng: "5.8622",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "684",
    population_proper: "684",
  },
  {
    city: "Wijngaarden",
    lat: "51.8444",
    lng: "4.7611",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "684",
    population_proper: "684",
  },
  {
    city: "Gassel",
    lat: "51.7403",
    lng: "5.7803",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "653",
    population_proper: "653",
  },
  {
    city: "Maasbommel",
    lat: "51.8208",
    lng: "5.5364",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "648",
    population_proper: "648",
  },
  {
    city: "Borgharen",
    lat: "50.8781",
    lng: "5.6883",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "645",
    population_proper: "645",
  },
  {
    city: "Spaarnwoude",
    lat: "52.4039",
    lng: "4.6942",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "644",
    population_proper: "644",
  },
  {
    city: "Heukelum",
    lat: "51.8731",
    lng: "5.0767",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "642",
    population_proper: "642",
  },
  {
    city: "Dommelen",
    lat: "51.3569",
    lng: "5.4306",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "640",
    population_proper: "640",
  },
  {
    city: "Otterlo",
    lat: "52.1000",
    lng: "5.7833",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "628",
    population_proper: "628",
  },
  {
    city: "Bruchem",
    lat: "51.7853",
    lng: "5.2367",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "620",
    population_proper: "620",
  },
  {
    city: "Hurwenen",
    lat: "51.8106",
    lng: "5.3178",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "618",
    population_proper: "618",
  },
  {
    city: "Elkerzee",
    lat: "51.7250",
    lng: "3.8436",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "615",
    population_proper: "615",
  },
  {
    city: "Zuidveen",
    lat: "52.7769",
    lng: "6.1083",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "",
    population: "610",
    population_proper: "610",
  },
  {
    city: "IJzendoorn",
    lat: "51.9064",
    lng: "5.5331",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "604",
    population_proper: "604",
  },
  {
    city: "Hernen",
    lat: "51.8331",
    lng: "5.6806",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "600",
    population_proper: "600",
  },
  {
    city: "Herpt",
    lat: "51.7333",
    lng: "5.1500",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "593",
    population_proper: "593",
  },
  {
    city: "Verwolde",
    lat: "52.2006",
    lng: "6.3919",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "583",
    population_proper: "583",
  },
  {
    city: "Ellemeet",
    lat: "51.7297",
    lng: "3.8164",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "582",
    population_proper: "582",
  },
  {
    city: "Haarzuilens",
    lat: "52.1214",
    lng: "4.9964",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "572",
    population_proper: "572",
  },
  {
    city: "Montfort",
    lat: "51.1261",
    lng: "5.9486",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "562",
    population_proper: "562",
  },
  {
    city: "Blankenham",
    lat: "52.7647",
    lng: "5.8964",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "",
    population: "561",
    population_proper: "561",
  },
  {
    city: "Bommenede",
    lat: "51.7344",
    lng: "3.9789",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "545",
    population_proper: "545",
  },
  {
    city: "Vrijhoeve-Capelle",
    lat: "51.6667",
    lng: "5.0333",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "543",
    population_proper: "543",
  },
  {
    city: "Oost-Souburg",
    lat: "51.4636",
    lng: "3.6008",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "541",
    population_proper: "541",
  },
  {
    city: "Warder",
    lat: "52.5641",
    lng: "5.0311",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "538",
    population_proper: "538",
  },
  {
    city: "Tempel",
    lat: "51.9656",
    lng: "4.4461",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "537",
    population_proper: "537",
  },
  {
    city: "Waverveen",
    lat: "52.2206",
    lng: "4.8961",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "537",
    population_proper: "537",
  },
  {
    city: "Balgoij",
    lat: "51.7803",
    lng: "5.7139",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "510",
    population_proper: "510",
  },
  {
    city: "Stein",
    lat: "52.0000",
    lng: "4.7833",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "496",
    population_proper: "496",
  },
  {
    city: "The Bottom",
    lat: "17.6261",
    lng: "-63.2492",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "",
    capital: "admin",
    population: "495",
    population_proper: "495",
  },
  {
    city: "Deursen",
    lat: "51.8020",
    lng: "5.6250",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "494",
    population_proper: "494",
  },
  {
    city: "Oost-Knollendam",
    lat: "52.5172",
    lng: "4.7903",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "492",
    population_proper: "492",
  },
  {
    city: "Laagnieuwkoop",
    lat: "52.1322",
    lng: "4.9836",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "489",
    population_proper: "489",
  },
  {
    city: "Kwadijk",
    lat: "52.5275",
    lng: "4.9811",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "487",
    population_proper: "487",
  },
  {
    city: "Pieterburen",
    lat: "53.4000",
    lng: "6.4531",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "",
    population: "455",
    population_proper: "455",
  },
  {
    city: "Velp",
    lat: "51.7478",
    lng: "5.7172",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "452",
    population_proper: "452",
  },
  {
    city: "Wieldrecht",
    lat: "51.7833",
    lng: "4.6500",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "442",
    population_proper: "442",
  },
  {
    city: "Sint Anthoniepolder",
    lat: "51.7972",
    lng: "4.5333",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "433",
    population_proper: "433",
  },
  {
    city: "Sandelingen-Ambacht",
    lat: "51.8458",
    lng: "4.6167",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "425",
    population_proper: "425",
  },
  {
    city: "Oudhuizen",
    lat: "52.2000",
    lng: "4.9333",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "413",
    population_proper: "413",
  },
  {
    city: "Petten",
    lat: "52.7678",
    lng: "4.6583",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "411",
    population_proper: "411",
  },
  {
    city: "Spijk",
    lat: "51.8550",
    lng: "5.0078",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "409",
    population_proper: "409",
  },
  {
    city: "Puiflijk",
    lat: "51.8792",
    lng: "5.5903",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "401",
    population_proper: "401",
  },
  {
    city: "Drongelen",
    lat: "51.7150",
    lng: "5.0542",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "398",
    population_proper: "398",
  },
  {
    city: "Peursum",
    lat: "51.8500",
    lng: "4.9167",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "393",
    population_proper: "393",
  },
  {
    city: "Rijckholt",
    lat: "50.7994",
    lng: "5.7311",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "375",
    population_proper: "375",
  },
  {
    city: "Kleine-Lindt",
    lat: "51.8294",
    lng: "4.5750",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "364",
    population_proper: "364",
  },
  {
    city: "Rimburg",
    lat: "50.9169",
    lng: "6.0867",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "352",
    population_proper: "352",
  },
  {
    city: "Grosthuizen",
    lat: "52.6167",
    lng: "4.9833",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "337",
    population_proper: "337",
  },
  {
    city: "Nunhem",
    lat: "51.2447",
    lng: "5.9611",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "337",
    population_proper: "337",
  },
  {
    city: "Strucht",
    lat: "50.8500",
    lng: "5.8667",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "333",
    population_proper: "333",
  },
  {
    city: "Ittervoort",
    lat: "51.1719",
    lng: "5.8211",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "330",
    population_proper: "330",
  },
  {
    city: "Cillaarshoek",
    lat: "51.7706",
    lng: "4.5461",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "312",
    population_proper: "312",
  },
  {
    city: "Strijensas",
    lat: "51.7158",
    lng: "4.5861",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "306",
    population_proper: "306",
  },
  {
    city: "Mesch",
    lat: "50.7631",
    lng: "5.7328",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "",
    population: "302",
    population_proper: "302",
  },
  {
    city: "Hoogmade",
    lat: "52.1692",
    lng: "4.5819",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "291",
    population_proper: "291",
  },
  {
    city: "Haarlemmerliede",
    lat: "52.3881",
    lng: "4.6875",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "290",
    population_proper: "290",
  },
  {
    city: "Oud-Wulven",
    lat: "52.0444",
    lng: "5.1550",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "286",
    population_proper: "286",
  },
  {
    city: "Weurt",
    lat: "51.8833",
    lng: "5.7000",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "283",
    population_proper: "283",
  },
  {
    city: "Hoenzadriel",
    lat: "51.7500",
    lng: "5.3333",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "",
    population: "278",
    population_proper: "278",
  },
  {
    city: "Sterkenburg",
    lat: "52.0223",
    lng: "5.2826",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "275",
    population_proper: "275",
  },
  {
    city: "Zouteveen",
    lat: "51.9500",
    lng: "4.3339",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "274",
    population_proper: "274",
  },
  {
    city: "Katwoude",
    lat: "52.4694",
    lng: "5.0506",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "",
    population: "274",
    population_proper: "274",
  },
  {
    city: "Bokhoven",
    lat: "51.7372",
    lng: "5.2319",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "",
    population: "260",
    population_proper: "260",
  },
  {
    city: "Kats",
    lat: "51.5672",
    lng: "3.8856",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "",
    population: "256",
    population_proper: "256",
  },
  {
    city: "Darthuizen",
    lat: "52.0125",
    lng: "5.3981",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "",
    population: "251",
    population_proper: "251",
  },
  {
    city: "Stormpolder",
    lat: "51.9117",
    lng: "4.5744",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "",
    population: "248",
    population_proper: "248",
  },
  {
    city: "Schiedam",
    lat: "51.9192",
    lng: "4.3889",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Zoetermeer",
    lat: "52.0575",
    lng: "4.4931",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hoorn",
    lat: "52.6425",
    lng: "5.0597",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Rijswijk",
    lat: "52.0363",
    lng: "4.3250",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Nijmegen",
    lat: "51.8425",
    lng: "5.8528",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Papendrecht",
    lat: "51.8317",
    lng: "4.6875",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Alkmaar",
    lat: "52.6320",
    lng: "4.7510",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Vlaardingen",
    lat: "51.9125",
    lng: "4.3417",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Barendrecht",
    lat: "51.8567",
    lng: "4.5347",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Amersfoort",
    lat: "52.1550",
    lng: "5.3875",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Voorschoten",
    lat: "52.1275",
    lng: "4.4486",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Kralendijk",
    lat: "12.1517",
    lng: "-68.2761",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "",
    capital: "admin",
    population: "",
    population_proper: "",
  },
  {
    city: "Alblasserdam",
    lat: "51.8658",
    lng: "4.6611",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Amstelveen",
    lat: "52.3008",
    lng: "4.8639",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Leiden",
    lat: "52.1583",
    lng: "4.4931",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Tilburg",
    lat: "51.5572",
    lng: "5.0911",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Helmond",
    lat: "51.4817",
    lng: "5.6611",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Volendam",
    lat: "52.4994",
    lng: "5.0675",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Pijnacker",
    lat: "52.0183",
    lng: "4.4361",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Lisse",
    lat: "52.2600",
    lng: "4.5569",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hillegom",
    lat: "52.2908",
    lng: "4.5833",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hengelo",
    lat: "52.2658",
    lng: "6.7931",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Dordrecht",
    lat: "51.8100",
    lng: "4.6736",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Enkhuizen",
    lat: "52.7033",
    lng: "5.2917",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Naaldwijk",
    lat: "51.9936",
    lng: "4.2133",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Wageningen",
    lat: "51.9700",
    lng: "5.6667",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Enschede",
    lat: "52.2183",
    lng: "6.8958",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Almelo",
    lat: "52.3567",
    lng: "6.6625",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Schagen",
    lat: "52.7886",
    lng: "4.8019",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Soest",
    lat: "52.1733",
    lng: "5.2917",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Uithoorn",
    lat: "52.2375",
    lng: "4.8264",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Best",
    lat: "51.5075",
    lng: "5.3903",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hoofddorp",
    lat: "52.3000",
    lng: "4.6667",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Waddinxveen",
    lat: "52.0450",
    lng: "4.6514",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Weesp",
    lat: "52.3075",
    lng: "5.0417",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Vught",
    lat: "51.6558",
    lng: "5.2936",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Halfweg",
    lat: "52.3828",
    lng: "4.7550",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Baarn",
    lat: "52.2117",
    lng: "5.2875",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Raamsdonksveer",
    lat: "51.6993",
    lng: "4.8792",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Doetinchem",
    lat: "51.9656",
    lng: "6.2889",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Waalwijk",
    lat: "51.6825",
    lng: "5.0694",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Noordwijkerhout",
    lat: "52.2617",
    lng: "4.4931",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Simpelveld",
    lat: "50.8339",
    lng: "5.9814",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Bunschoten-Spakenburg",
    lat: "52.2417",
    lng: "5.3748",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Nuenen",
    lat: "51.4667",
    lng: "5.5500",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Bilthoven",
    lat: "52.1283",
    lng: "5.1986",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Nijkerk",
    lat: "52.2214",
    lng: "5.4842",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Meppel",
    lat: "52.6958",
    lng: "6.1944",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Drenthe",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Kaatsheuvel",
    lat: "51.6667",
    lng: "5.0667",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Oss",
    lat: "51.7650",
    lng: "5.5181",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Overveen",
    lat: "52.4000",
    lng: "4.6000",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "De Steeg",
    lat: "52.0197",
    lng: "6.0608",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Brielle",
    lat: "51.9017",
    lng: "4.1625",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hoogezand",
    lat: "53.1572",
    lng: "6.7533",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Vianen",
    lat: "51.9889",
    lng: "5.0917",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Reuver",
    lat: "51.2850",
    lng: "6.0792",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Appingedam",
    lat: "53.3217",
    lng: "6.8583",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Wijk bij Duurstede",
    lat: "51.9742",
    lng: "5.3417",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Drachten",
    lat: "53.1000",
    lng: "6.1000",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Fryslân",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Weert",
    lat: "51.2510",
    lng: "5.7090",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Nuth",
    lat: "50.9175",
    lng: "5.8861",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Valkenburg",
    lat: "50.8652",
    lng: "5.8320",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Mook",
    lat: "51.7525",
    lng: "5.8817",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Nieuw-Loosdrecht",
    lat: "52.2019",
    lng: "5.1406",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Malden",
    lat: "51.7808",
    lng: "5.8544",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Rijen",
    lat: "51.5920",
    lng: "4.9168",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Voerendaal",
    lat: "50.8825",
    lng: "5.9272",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Landsmeer",
    lat: "52.4308",
    lng: "4.9153",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Haren",
    lat: "53.1721",
    lng: "6.6093",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Roelofarendsveen",
    lat: "52.2061",
    lng: "4.6361",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Oudenbosch",
    lat: "51.5892",
    lng: "4.5239",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Elburg",
    lat: "52.4500",
    lng: "5.8333",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Opheusden",
    lat: "51.9333",
    lng: "5.6297",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Kerkdriel",
    lat: "51.7711",
    lng: "5.3367",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Veendam",
    lat: "53.1061",
    lng: "6.8719",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Montfoort",
    lat: "52.0458",
    lng: "4.9528",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Rucphen",
    lat: "51.5325",
    lng: "4.5583",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Nieuwveen",
    lat: "52.1944",
    lng: "4.7569",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hazerswoude-Rijndijk",
    lat: "52.1292",
    lng: "4.5931",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Leek",
    lat: "53.1616",
    lng: "6.3847",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Geffen",
    lat: "51.7392",
    lng: "5.4639",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Gendringen",
    lat: "51.8733",
    lng: "6.3764",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Woudrichem",
    lat: "51.8128",
    lng: "5.0003",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "De Goorn",
    lat: "52.6303",
    lng: "4.9444",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Panningen",
    lat: "51.3275",
    lng: "5.9817",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Buitenpost",
    lat: "53.2500",
    lng: "6.1500",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Fryslân",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Bergambacht",
    lat: "51.9328",
    lng: "4.7936",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Lobith",
    lat: "51.8617",
    lng: "6.1181",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Geldermalsen",
    lat: "51.8789",
    lng: "5.2897",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Rockanje",
    lat: "51.8706",
    lng: "4.0689",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Nijverdal",
    lat: "52.3631",
    lng: "6.4617",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Wierden",
    lat: "52.3592",
    lng: "6.5931",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Made",
    lat: "51.6764",
    lng: "4.7925",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Brummen",
    lat: "52.0833",
    lng: "6.1500",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Stiens",
    lat: "53.2608",
    lng: "5.7575",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Fryslân",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hoogerheide",
    lat: "51.4239",
    lng: "4.3239",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Oudewater",
    lat: "52.0250",
    lng: "4.8681",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Utrecht",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Beek-Ubbergen",
    lat: "51.8292",
    lng: "5.9258",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Gemert",
    lat: "51.5558",
    lng: "5.6867",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Beneden-Leeuwen",
    lat: "51.8811",
    lng: "5.5178",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Bedum",
    lat: "53.3003",
    lng: "6.6025",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Heerde",
    lat: "52.3833",
    lng: "6.0333",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Haaksbergen",
    lat: "52.1567",
    lng: "6.7389",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Damwâld",
    lat: "53.2900",
    lng: "5.9957",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Fryslân",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Horst",
    lat: "51.4514",
    lng: "6.0536",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Raalte",
    lat: "52.3828",
    lng: "6.2828",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Stadskanaal",
    lat: "52.9895",
    lng: "6.9504",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Terneuzen",
    lat: "51.3333",
    lng: "3.8333",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Burgum",
    lat: "53.1922",
    lng: "5.9942",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Fryslân",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Nunspeet",
    lat: "52.3667",
    lng: "5.7833",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Epe",
    lat: "52.3464",
    lng: "5.9842",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Mill",
    lat: "51.6850",
    lng: "5.7836",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Abbenbroek",
    lat: "51.8486",
    lng: "4.2428",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Kruiningen",
    lat: "51.4481",
    lng: "4.0342",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Gulpen",
    lat: "50.8156",
    lng: "5.8886",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Menaam",
    lat: "53.2158",
    lng: "5.6606",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Fryslân",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Delfzijl",
    lat: "53.3300",
    lng: "6.9181",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Lemmer",
    lat: "52.8437",
    lng: "5.7093",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Fryslân",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Tholen",
    lat: "51.5322",
    lng: "4.2206",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Dalfsen",
    lat: "52.5064",
    lng: "6.2606",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Strijen",
    lat: "51.7452",
    lng: "4.5508",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zuid-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Nederweert",
    lat: "51.2858",
    lng: "5.7486",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Limburg",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Marum",
    lat: "53.1442",
    lng: "6.2678",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Heinkenszand",
    lat: "51.4730",
    lng: "3.8128",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Lochem",
    lat: "52.1606",
    lng: "6.4158",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Gelderland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Steenbergen",
    lat: "51.5841",
    lng: "4.3177",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Roden",
    lat: "53.1400",
    lng: "6.4262",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Drenthe",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Wijhe",
    lat: "52.3864",
    lng: "6.1333",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Heeze",
    lat: "51.3828",
    lng: "5.5715",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Joure",
    lat: "52.9661",
    lng: "5.7997",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Fryslân",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Denekamp",
    lat: "52.3794",
    lng: "7.0089",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Overijssel",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Grootegast",
    lat: "53.2093",
    lng: "6.2711",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hulst",
    lat: "51.2800",
    lng: "4.0528",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Beetsterzwaag",
    lat: "53.0611",
    lng: "6.0775",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Fryslân",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Grou",
    lat: "53.1000",
    lng: "5.8300",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Fryslân",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Coevorden",
    lat: "52.6610",
    lng: "6.7405",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Drenthe",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Sint Annaparochie",
    lat: "53.2764",
    lng: "5.6572",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Fryslân",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Middenbeemster",
    lat: "52.5483",
    lng: "4.9197",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Wolvega",
    lat: "52.8755",
    lng: "5.9969",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Fryslân",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Oosterwolde",
    lat: "52.9903",
    lng: "6.2914",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Fryslân",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Kollum",
    lat: "53.2792",
    lng: "6.1508",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Fryslân",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Balk",
    lat: "52.8983",
    lng: "5.5783",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Fryslân",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Zuidwolde",
    lat: "52.6746",
    lng: "6.4247",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Drenthe",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Emmeloord",
    lat: "52.7097",
    lng: "5.7508",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Flevoland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Slochteren",
    lat: "53.2208",
    lng: "6.8055",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Alphen",
    lat: "51.4830",
    lng: "4.9562",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Brabant",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Wissenkerke",
    lat: "51.5855",
    lng: "3.7472",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Exloo",
    lat: "52.8802",
    lng: "6.8611",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Drenthe",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Ferwert",
    lat: "53.3378",
    lng: "5.8247",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Fryslân",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Oostburg",
    lat: "51.3333",
    lng: "3.5000",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Zeeland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Stompetoren",
    lat: "52.6153",
    lng: "4.8228",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Den Burg",
    lat: "53.0558",
    lng: "4.7961",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Noord-Holland",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Wommels",
    lat: "53.1088",
    lng: "5.5875",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Fryslân",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Sellingen",
    lat: "52.9464",
    lng: "7.1514",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Groningen",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Ballum",
    lat: "53.4441",
    lng: "5.6876",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Fryslân",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "West-Terschelling",
    lat: "53.3608",
    lng: "5.2156",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Fryslân",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Oost-Vlieland",
    lat: "53.2959",
    lng: "5.0665",
    country: "Netherlands",
    iso2: "NL",
    admin_name: "Fryslân",
    capital: "minor",
    population: "",
    population_proper: "",
  },
];
