<template>
  <div style="margin-top: 5%">
    <v-card>
      <v-container>
        <v-row>
          <v-col md="5" lg="3" class="pb-0">
            <v-sheet :style="iconStyle" rounded class="pa-1">
              <v-icon style="font-size: 400%; min-width: 100%;">{{ icon }}</v-icon>
            </v-sheet>
            <h4 :style="iconStyleText" class="pb-0">{{count}}</h4>
          </v-col>
          <v-col md="7" lg="9">
            <h3>{{ title }}</h3>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <star-rating
                    :increment="0.01"
                    :fixed-points="2"
                    read-only
                    :rating="rating"
                    :show-rating="false"
                    text-class="custom-text"
                    :star-size="starSize"
                  ></star-rating>
                </div>
              </template>
              <span>{{ rating }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import StarRating from "vue-star-rating";
export default {
  components: {
    StarRating,
  },
  props: {
    title: String,
    rating: Number,
    icon: String,
    color: String,
    count: Number,
  },
  data: () => ({}),
  created() {},
  computed: {
    iconStyle() {
      return {
        "z-index": 1,
        "position": "relative",
        "top": "-30px",
        "background": this.color,
      };
    },
    iconStyleText(){
       return {
        "z-index": 1,
        "position": "relative",
        "top": "-30px",
        "min-width": "100%",
        "text-align": "center"
      }; 
    },
    starSize(){
        let value = 0;
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 20
          case 'sm': return 20
          case 'md': return 25
          case 'lg': return 30
          case 'xl': return 30
        }
        return value
    },
  },
  methods: {},
};
</script>