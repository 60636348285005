<template>
  <v-card class="pa-5">
    <v-container class="pa-5">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                v-model="formDataFields.first_name"
                :rules="[
                  (v) => !!v || $t('create_lead_user.required_first_name'),
                ]"
                :label="$t('create_lead_user.first_name')"
                required
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="formDataFields.last_name"
                :rules="[
                  (v) => !!v || $t('create_lead_user.required_last_name'),
                ]"
                :label="$t('create_lead_user.last_name')"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col>
              <v-text-field
                v-model="formDataFields.email"
                :rules="[rules.email]"
                :label="$t('create_lead_user.email')"
                :error-messages="validateEmailOrPhone()"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="formDataFields.tel_number"
                :label="$t('create_lead_user.tel')"
                :rules="[rules.phone]"
                :error-messages="validateEmailOrPhone()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-select
            class="mb-2"
            :items="genders"
            v-model="formDataFields.gender"
            required
            :label="$t('create_lead_user.gender')"
          ></v-select>
          <v-row>
            <v-col>
              <v-menu
                v-model="dobMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedDOB"
                    :label="$t('create_lead_user.dob')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="nl-NL"
                  v-model="formDataFields.dob"
                  @input="dobMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col>
              <v-text-field
                v-model="formDataFields.address.street"
                :label="$t('create_lead_user.street_name')"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="formDataFields.address.houseno"
                :label="$t('create_lead_user.house_number')"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="formDataFields.address.addition"
                :label="$t('create_lead_user.addition')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col>
              <v-text-field
                v-model="formDataFields.region"
                :label="$t('create_lead_user.place')"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="formDataFields.address.postalcode"
                :label="$t('create_lead_user.postcode')"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="mt-0">
            <LanguageSelector v-model="formDataFields.languages" />
            <v-col>
              <v-row>
                <v-checkbox
                  v-model="formDataFields.car"
                  style="padding-left: 10px"
                  :label="$t('create_lead_user.car')"
                ></v-checkbox>
                <v-checkbox
                  v-model="formDataFields.drivers_license"
                  style="padding-left: 10px"
                  :label="$t('create_lead_user.drivers_license')"
                ></v-checkbox>
                <v-checkbox
                  v-model="formDataFields.bullhorn"
                  style="padding-left: 10px"
                  :label="$t('create_lead_user.bullhorn')"
                ></v-checkbox>
                <v-checkbox
                  v-model="formDataFields.contract_signed"
                  style="padding-left: 10px"
                  :label="$t('create_lead_user.contract_signed')"
                ></v-checkbox>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="formDataFields.contract_signed">
            <v-col>
              <v-menu
                v-model="contractMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedContract"
                    :label="$t('create_lead_user.signature_date')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="formDataFields.contract"
                  @input="contractMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-select
            :items="platforms"
            v-model="formDataFields.platform"
            required
            :label="$t('create_lead_user.platform')"
          ></v-select>
          <v-row class="mt-0">
            <v-col>
              <v-select
                :items="contract_terms"
                v-model="formDataFields.contract_term"
                :label="$t('create_lead_user.contract_term')"
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                :items="contract_types"
                v-model="formDataFields.contract_type"
                :label="$t('create_lead_user.job_scope')"
              ></v-select>
            </v-col>
          </v-row>
          <!-- select perma lead status -->
          <v-select
            :items="lead_statuses"
            item-text="name"
            item-value="id"
            v-model="formDataFields.perma_status_id"
            :label="$t('create_lead_user.perma_status')"
          ></v-select>
          <v-text-field
            v-model="formDataFields.sal_indication"
            :label="$t('create_lead_user.salary_indication')"
          ></v-text-field>
          <v-textarea
            v-model="formDataFields.notes"
            outlined
            :label="$t('create_lead_user.notes')"
          ></v-textarea>
        </v-container>
        <v-row justify="center">
          <v-btn
            :disabled="!valid || adding"
            color="success"
            class="mr-4"
            @click="buttonClick"
          >
            {{ types[type].buttonText }}
          </v-btn>
        </v-row>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import LanguageSelector from "../Selectors/LanguageSelector";
import { calculateLocation } from "../../helpers/google";
import { permission } from "../../utils/permissions";
import { mapActions } from "vuex";
import {
  CREATE_LEAD_USER,
  GET_LEAD_USERS,
  GET_LEAD_USER_BY_ID,
  UPDATE_LEAD_USER,
  GET_LEAD_STATUSES,
} from "../../store/action.type";
export default {
  components: {
    LanguageSelector,
  },
  props: {
    type: String,
    formData: Object,
    parentID: Number,
  },
  data: () => ({
    dobMenu: false,
    contractMenu: false,
    adding: false,
    valid: false,
    genders: ["Man", "Vrouw", "Transgender", "Non-binair"],
    platforms: ["Doen", "Zoomer"],
    contract_terms: ["Flexibel", "Vast"],
    contract_types: ["Fulltime", "Parttime", "Fulltime/Parttime", "Bijbaan"],
    data_fields: {
      first_name: "",
      last_name: "",
      email: "",
      tel_number: "",
      address: {
        street: "",
        postalcode: "",
        houseno: "",
        addition: "",
        region: "",
      },
      region: "",
      languages: [],
      platform: "",
      bullhorn: false,
      drivers_license: false,
      car: false,
      contract_signed: false,
      contract: "",
      dob: null,
      notes: "",
      contract_term: "",
      contract_type: "",
      favorite: false,
      blacklisted: false,
      perma_status_id: 0,
    },
    lead_statuses: [],
    rules: {
      email: (value) => {
        if (value != "" && value != undefined && value != null) {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Ongeldig e-mail";
        } else return true;
      },
      phone: (value) => {
        if (value != "" && value != undefined && value != null) {
          const pattern = /^\+(?:[0-9]●?){6,14}[0-9]$/;
          return pattern.test(value) || "Moet beginnen met + en land code";
        } else return true;
      },
    },
  }),

  computed: {
    formDataFields() {
      this.updateFormFields();
      return this.data_fields;
    },
    leadState() {
      return this.$store.state.leads;
    },
    leadUsersState() {
      return this.$store.state.lead_users;
    },
    computedDOB: {
      get() {
        return this.formatDate(this.data_fields.dob);
      },
      set() {
        return null;
      },
    },
    computedContract: {
      get() {
        return this.formatDate(this.data_fields.contract);
      },
      set() {
        return null;
      },
    },
    types() {
      return [
        {
          buttonText: this.$t("create_lead_user.create_lu"),
        },
        {
          buttonText: this.$t("create_lead_user.customize_lu"),
        },
      ];
    },
  },

  async created() {
    if (this.type == 1) {
      this.data_fields = this.formData;
      // sort ids
      const compareIds = (a, b) => {
        if (a.id < b.id) {
          return -1;
        }
        if (a.id > b.id) {
          return 1;
        }
        return 0;
      };
      // get lead statuses
      await this.getLeadStatuses().then((res) => {
        this.lead_statuses = res.lead_statuses.sort(compareIds);
      });
      if (this.data_fields.languages == null) {
        this.data_fields.languages = [];
      }
    }
  },

  methods: {
    ...mapActions("lead_users", {
      getLeadUsers: GET_LEAD_USERS,
      getLeadUserById: GET_LEAD_USER_BY_ID,
      createLeadUser: CREATE_LEAD_USER,
      updateLeadUser: UPDATE_LEAD_USER,
    }),
    ...mapActions("statuses", {
      getLeadStatuses: GET_LEAD_STATUSES,
    }),
    fullName(item) {
      return item.first_name + " " + item.last_name;
    },

    updateFormFields() {
      if (this.type == 1) {
        this.data_fields = this.formData;
        if (this.data_fields.address == null) {
          this.data_fields.address = {
            street: "",
            postalcode: "",
            houseno: "",
            addition: "",
            region: "",
          };
        }
      }
    },

    buttonClick() {
      if (this.type == 0) {
        this.create();
      } else if (this.type == 1) {
        this.editLeadUser();
      }
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },

    async editLeadUser() {
      const { dispatch } = this.$store;
      if (this.$refs.form.validate()) {
        this.adding = true;
        let lead_user = {
          first_name: this.data_fields.first_name,
          last_name: this.data_fields.last_name,
          dob: this.data_fields.dob,
          gender: this.data_fields.gender,
          address: this.data_fields.address,
          tel_number: this.data_fields.tel_number,
          languages: this.data_fields.languages,
          region: this.data_fields.region,
          bullhorn: this.data_fields.bullhorn,
          email: this.data_fields.email,
          drivers_license: this.data_fields.drivers_license,
          car: this.data_fields.car,
          platform: this.data_fields.platform,
          sal_indication: this.data_fields.sal_indication,
          contract: this.data_fields.contract,
          contract_signed: this.data_fields.contract_signed,
          notes: this.data_fields.notes,
          contract_term: this.data_fields.contract_term,
          contract_type: this.data_fields.contract_type,
          favorite: this.data_fields.favorite,
          blacklisted: this.data_fields.blacklisted,
          perma_status_id: this.data_fields.perma_status_id,
        };
        lead_user.address.region = lead_user.region;

        if (!lead_user.contract_signed) lead_user.contract = null;

        await calculateLocation(lead_user.address).then((res) => {
          lead_user.address.lat = res.lat;
          lead_user.address.lng = res.lng;
        });

        lead_user.address = JSON.stringify(lead_user.address);
        lead_user.languages = JSON.stringify(lead_user.languages);

        let userId = this.formData.id;

        if (permission("canCreateLeadUsers") || permission("isAdmin")) {
          await this.updateLeadUser({
            lead_user,
            userId,
          }).then(() => {
            dispatch("alerts/success", this.$t("create_lead_user.lu_saved"));
            this.getLeadUserById(userId);
          });
          this.$emit("close-dialog");
        } else {
          dispatch(
            "alerts/warn",
            this.$t("create_lead_user.not_authorized_msg")
          );
        }
      } else {
        dispatch(
          "alerts/warn",
          this.$t("create_lead_user.not_all_fields_completed")
        );
      }
      this.adding = false;
    },

    async create() {
      const { dispatch } = this.$store;
      if (this.$refs.form.validate()) {
        this.adding = true;
        let lead_user = {
          first_name: this.data_fields.first_name,
          last_name: this.data_fields.last_name,
          dob: this.data_fields.dob,
          gender: this.data_fields.gender,
          address: this.data_fields.address,
          tel_number: this.data_fields.tel_number,
          languages: this.data_fields.languages,
          region: this.data_fields.region,
          bullhorn: this.data_fields.bullhorn,
          email: this.data_fields.email,
          drivers_license: this.data_fields.drivers_license,
          car: this.data_fields.car,
          platform: this.data_fields.platform,
          sal_indication: this.data_fields.sal_indication,
          contract: this.data_fields.contract,
          contract_signed: this.data_fields.contract_signed,
          notes: this.data_fields.notes,
          contract_term: this.data_fields.contract_term,
          contract_type: this.data_fields.contract_type,
          perma_status_id: this.data_fields.perma_status_id,
        };
        if (!lead_user.contract_signed) lead_user.contract = null;

        await calculateLocation(lead_user.address).then((res) => {
          lead_user.address.lat = res.lat;
          lead_user.address.lng = res.lng;
        });

        lead_user.address = JSON.stringify(lead_user.address);
        lead_user.languages = JSON.stringify(lead_user.languages);
        if (permission("canCreateLeadUsers") || permission("isAdmin")) {
          await this.createLeadUser(lead_user).then(() => {
            this.getLeadUsers();
            dispatch("alerts/success", this.$t("create_lead_user.lu_added"));
          });
          this.clear();
          this.$emit("close-dialog");
        } else {
          dispatch(
            "alerts/warn",
            this.$t("create_lead_user.not_authorized_msg")
          );
        }
      } else {
        dispatch(
          "alerts/warn",
          this.$t("create_lead_user.not_all_fields_completed")
        );
      }
      this.adding = false;
    },
    clear() {
      this.$refs.form.reset();
    },
    validateEmailOrPhone() {
      return this.formDataFields.email || this.formDataFields.tel_number
        ? ""
        : this.$t("create_lead_user.phone_or_email");
    },
  },
};
</script>
