<template>
  <v-container fluid>
    <v-tabs color="primary">
      <v-tab>{{ $t("dashboard_page.lead_flow_dashboard") }}</v-tab>
      <v-tab>{{ $t("dashboard_page.contact_moment") }}</v-tab>
      <v-tab-item>
        <v-card>
          <v-card-title class="pt-0 mt-0 pb-0">
            <v-container class="pt-0 mt-0 ml-0 pb-0 mr-0" fluid>
              <v-row class="pr-1">
                <v-col class="mt-6 pl-0 pb-0">
                  <h3>{{ $t("dashboard_page.lead_flow_dashboard") }}</h3>
                </v-col>
                <v-spacer />
                <v-col class="pb-0" align-self="end">
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="selection.year"
                        :label="$t('dashboard_page.year')"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="selection.week"
                        :label="$t('dashboard_page.week')"
                      ></v-text-field>
                    </v-col>
                    <v-btn
                      class="pl-2 pr-2 mt-3 mt-6"
                      color="blue"
                      @click="getData()"
                    >
                      {{ $t("dashboard_page.get_data") }}
                      <v-icon>mdi-text-box-search</v-icon>
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-title>
          <v-container fluid v-if="filterDataLoaded">
            <v-row dense class="mr-1">
              <v-col>
                <v-autocomplete
                  :items="vacancies_all_getter"
                  v-model="filters.vacancy"
                  clearable
                  item-text="name"
                  item-value="id"
                  :label="$t('dashboard_page.vacancy')"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-autocomplete
                  :items="channels"
                  v-model="filters.source"
                  clearable
                  item-text="name"
                  item-value="id"
                  :label="$t('dashboard_page.channel')"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-autocomplete
                  :items="employees"
                  v-model="filters.rec"
                  clearable
                  item-text="full_name"
                  item-value="id"
                  :label="$t('dashboard_page.recruiter')"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-autocomplete
                  :items="employees"
                  v-model="filters.recAss"
                  clearable
                  item-text="full_name"
                  item-value="id"
                  :label="$t('dashboard_page.assistant')"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-autocomplete
                  :items="employees"
                  v-model="filters.cm"
                  clearable
                  item-text="full_name"
                  item-value="id"
                  :label="$t('dashboard_page.com_manager')"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row dense class="mt-0">
              <v-col align="end" class="mr-2 mt-0 mb-0">
                <v-btn
                  color="blue"
                  @click="finalCalculations()"
                  class="pr-2 pl-2 mr-2"
                  ><span class="mr-1">{{
                    $t("dashboard_page.apply_filters")
                  }}</span
                  ><v-icon dark>mdi-magnify</v-icon></v-btn
                >
                <v-btn
                  color="red"
                  @click="clearFilters()"
                  class="pr-2 pl-2 ml-2"
                  ><span class="mr-1">{{
                    $t("dashboard_page.clear_filters")
                  }}</span
                  ><v-icon dark>mdi-backspace</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-container>
          <v-container v-if="computedWeekData.length != 0">
            <div v-for="item in computedWeekData" :key="item.weekNo">
              <h2>{{ $t("dashboard_page.week") + ": " + item.weekNo }}</h2>
              <v-row class="pb-4">
                <v-col md="2">
                  <v-card elevation="5" height="100%">
                    <v-card-title>{{
                      $t("dashboard_page.leads")
                    }}</v-card-title>
                    <v-card-subtitle>{{
                      $t("dashboard_page.leads_text")
                    }}</v-card-subtitle>
                    <v-container class="pt-0 ml-4">
                      <v-row class="pb-0 pr-4">
                        <v-col class="pl-0 pb-0 pt-0">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on">
                                mdi-equal-box
                              </v-icon>
                            </template>
                            <span>{{ $t("dashboard_page.how_much") }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-spacer />
                        <v-col
                          align-self="end"
                          class="pb-0 pt-0"
                          style="text-align: right"
                        >
                          <h3>{{ item.leadsCount }}</h3>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
                <v-col md="2">
                  <v-card elevation="5" height="100%">
                    <v-card-title>{{
                      $t("dashboard_page.contact")
                    }}</v-card-title>
                    <v-card-subtitle>{{
                      $t("dashboard_page.contact_text")
                    }}</v-card-subtitle>
                    <v-container class="pt-0 ml-4">
                      <v-row class="pb-0 pr-4">
                        <v-col class="pl-0 pb-0 pt-0">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on">
                                mdi-equal-box
                              </v-icon>
                            </template>
                            <span>{{ $t("dashboard_page.how_much") }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-spacer />
                        <v-col
                          align-self="end"
                          class="pb-0 pt-0"
                          style="text-align: right"
                        >
                          <h3>{{ item.contactCount }}</h3>
                        </v-col>
                      </v-row>
                      <v-row class="mt-0 pb-0 pr-4">
                        <v-col class="pl-0 pb-0">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on">
                                mdi-percent
                              </v-icon>
                            </template>
                            <span>{{ $t("dashboard_page.percentage") }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-spacer />
                        <v-col
                          align-self="end"
                          class="pb-0"
                          style="text-align: right"
                        >
                          <h3>
                            {{
                              phasePercentage(
                                item.leadsCount,
                                item.contactCount
                              )
                            }}
                          </h3>
                        </v-col>
                      </v-row>
                      <v-row class="mt-0 pb-0 pr-4">
                        <v-col class="pl-0">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on">
                                mdi-calendar-clock
                              </v-icon>
                            </template>
                            <span>{{ $t("dashboard_page.days") }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-spacer />
                        <v-col align-self="end" style="text-align: right">
                          <h3>{{ phaseNeatDayDif(item.toContactDays) }}</h3>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
                <v-col md="2">
                  <v-card elevation="5" height="100%">
                    <v-card-title>{{
                      $t("dashboard_page.intake")
                    }}</v-card-title>
                    <v-card-subtitle>{{
                      $t("dashboard_page.intake_text")
                    }}</v-card-subtitle>
                    <v-container class="pt-0 ml-4">
                      <v-row class="pb-0 pr-4">
                        <v-col class="pl-0 pb-0 pt-0">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on">
                                mdi-equal-box
                              </v-icon>
                            </template>
                            <span>{{ $t("dashboard_page.how_much") }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-spacer />
                        <v-col
                          align-self="end"
                          class="pb-0 pt-0"
                          style="text-align: right"
                        >
                          <h3>{{ item.intakeCount }}</h3>
                        </v-col>
                      </v-row>
                      <v-row class="mt-0 pb-0 pr-4">
                        <v-col class="pl-0 pb-0">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on">
                                mdi-percent
                              </v-icon>
                            </template>
                            <span>{{ $t("dashboard_page.percentage") }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-spacer />
                        <v-col
                          align-self="end"
                          class="pb-0"
                          style="text-align: right"
                        >
                          <h3>
                            {{
                              phasePercentage(
                                item.contactCount,
                                item.intakeCount
                              )
                            }}
                          </h3>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
                <v-col md="2">
                  <v-card elevation="5" height="100%">
                    <v-card-title>{{
                      $t("dashboard_page.intaked")
                    }}</v-card-title>
                    <v-card-subtitle>{{
                      $t("dashboard_page.intaked_text")
                    }}</v-card-subtitle>
                    <v-container class="pt-0 ml-4">
                      <v-row class="pb-0 pr-4">
                        <v-col class="pl-0 pb-0 pt-0">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on">
                                mdi-equal-box
                              </v-icon>
                            </template>
                            <span>{{ $t("dashboard_page.how_much") }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-spacer />
                        <v-col
                          align-self="end"
                          class="pb-0 pt-0"
                          style="text-align: right"
                        >
                          <h3>{{ item.intakedCount }}</h3>
                        </v-col>
                      </v-row>
                      <v-row class="mt-0 pb-0 pr-4">
                        <v-col class="pl-0 pb-0">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on">
                                mdi-percent
                              </v-icon>
                            </template>
                            <span>{{ $t("dashboard_page.percentage") }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-spacer />
                        <v-col
                          align-self="end"
                          class="pb-0"
                          style="text-align: right"
                        >
                          <h3>
                            {{
                              phasePercentage(
                                item.intakeCount,
                                item.intakedCount
                              )
                            }}
                          </h3>
                        </v-col>
                      </v-row>
                      <v-row class="mt-0 pb-0 pr-4">
                        <v-col class="pl-0">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on">
                                mdi-calendar-clock
                              </v-icon>
                            </template>
                            <span>{{ $t("dashboard_page.days") }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-spacer />
                        <v-col align-self="end" style="text-align: right">
                          <h3>{{ phaseNeatDayDif(item.toIntakedDays) }}</h3>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
                <v-col md="2">
                  <v-card elevation="5" height="100%">
                    <v-card-title>{{
                      $t("dashboard_page.posted")
                    }}</v-card-title>
                    <v-card-subtitle>{{
                      $t("dashboard_page.posted_text")
                    }}</v-card-subtitle>
                    <v-container class="pt-0 ml-4">
                      <v-row class="pb-0 pr-4">
                        <v-col class="pl-0 pb-0 pt-0">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on">
                                mdi-equal-box
                              </v-icon>
                            </template>
                            <span>{{ $t("dashboard_page.how_much") }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-spacer />
                        <v-col
                          align-self="end"
                          class="pb-0 pt-0"
                          style="text-align: right"
                        >
                          <h3>{{ item.placedCount }}</h3>
                        </v-col>
                      </v-row>
                      <v-row class="mt-0 pb-0 pr-4">
                        <v-col class="pl-0 pb-0">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on">
                                mdi-percent
                              </v-icon>
                            </template>
                            <span>{{ $t("dashboard_page.percentage") }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-spacer />
                        <v-col
                          align-self="end"
                          class="pb-0"
                          style="text-align: right"
                        >
                          <h3>
                            {{
                              phasePercentage(
                                item.intakedCount,
                                item.placedCount
                              )
                            }}
                          </h3>
                        </v-col>
                      </v-row>
                      <v-row class="mt-0 pb-0 pr-4">
                        <v-col class="pl-0">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on">
                                mdi-calendar-clock
                              </v-icon>
                            </template>
                            <span>{{ $t("dashboard_page.days") }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-spacer />
                        <v-col align-self="end" style="text-align: right">
                          <h3>{{ phaseNeatDayDif(item.toPlacedDays) }}</h3>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
                <v-col md="2">
                  <v-card elevation="5" height="100%">
                    <v-card-title>{{ $t("dashboard_page.exit") }}</v-card-title>
                    <v-card-subtitle>{{
                      $t("dashboard_page.exit_text")
                    }}</v-card-subtitle>
                    <v-container class="pt-0 ml-4">
                      <v-row class="pb-0 pr-4">
                        <v-col class="pl-0 pb-0 pt-0">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on">
                                mdi-equal-box
                              </v-icon>
                            </template>
                            <span>{{ $t("dashboard_page.how_much") }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-spacer />
                        <v-col
                          align-self="end"
                          class="pb-0 pt-0"
                          style="text-align: right"
                        >
                          <h3>{{ item.exitCount }}</h3>
                        </v-col>
                      </v-row>
                      <v-row class="mt-0 pb-0 pr-4">
                        <v-col class="pl-0 pb-0">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on">
                                mdi-percent
                              </v-icon>
                            </template>
                            <span>{{ $t("dashboard_page.percentage") }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-spacer />
                        <v-col
                          align-self="end"
                          class="pb-0"
                          style="text-align: right"
                        >
                          <h3>
                            {{
                              phasePercentage(item.placedCount, item.exitCount)
                            }}
                          </h3>
                        </v-col>
                      </v-row>
                      <v-row class="mt-0 pb-0 pr-4">
                        <v-col class="pl-0">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on">
                                mdi-calendar-clock
                              </v-icon>
                            </template>
                            <span>{{ $t("dashboard_page.days") }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-spacer />
                        <v-col align-self="end" style="text-align: right">
                          <h3>{{ phaseNeatDayDif(item.toExitDays) }}</h3>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-container>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card class="mb-4 elevation-0">
          <v-card-title class="pt-0 mt-0 pb-0">
            <v-container class="pt-0 mt-0 ml-0 pb-0 mr-0" fluid>
              <v-row class="pr-1">
                <v-col class="mt-6 pl-0 pb-0">
                  <h3>{{ $t("dashboard_page.contact_moment") }}</h3>
                </v-col>
                <v-spacer />
                <v-col class="pb-0" align-self="end">
                  <v-row>
                    <v-col>
                      <v-menu
                        v-model="contactPeriodMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        content-class="hide-y-scroll"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="computedContactDateRange"
                            :label="$t('dashboard_page.date_range')"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            clearable
                          ></v-text-field>
                        </template>

                        <EnhancedDatePicker
                          @setDates="setContactDates"
                          @menuToggle="contactPeriodMenu = !contactPeriodMenu"
                        />
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-title>
        </v-card>
        <div v-if="filterDataLoaded">
          <v-data-table
            style="white-space: pre-line"
            :headers="headers"
            :items="contactMomentsList"
            :search="search"
            :items-per-page="10"
            class="elevation-0"
          >
            <template v-slot:[`item.app`]="{ item }">
              <v-chip label color="green" text-color="white">
                {{ item.app }}
              </v-chip>
            </template>
            <template v-slot:[`item.contact`]="{ item }">
              <v-chip label color="blue" text-color="white">
                {{ item.contact }}
              </v-chip>
            </template>
            <template v-slot:[`item.vm`]="{ item }">
              <v-chip label color="orange" text-color="white">
                {{ item.vm }}
              </v-chip>
            </template>
            <template v-slot:[`item.totalIntakes`]="{ item }">
              <v-chip label color="red" text-color="white">
                {{ item.totalIntakes }}
              </v-chip>
            </template>
            <template v-slot:[`item.totalCompletedIntakes`]="{ item }">
              <v-chip label color="pink" text-color="white">
                {{ item.totalCompletedIntakes }}
              </v-chip>
            </template>
            <template v-slot:[`item.total`]="{ item }">
              <v-chip label color="grey" text-color="white">
                {{ item.total }}
              </v-chip>
            </template>
          </v-data-table>
        </div>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
//import Global from "../components/dashboard/Global.vue";
//import DoughnutChart from "../components/dashboard/DoughnutChart.vue";
//import BarChart from "../components/dashboard/BarChart.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import EnhancedDatePicker from "../components/dialogs/EnhancedDatePicker.vue";
import {
  GET_CHANNELS,
  GET_DASHBOARD_FLOW_LEADS,
  GET_GLOBAL_ANALYTICS,
  GET_RECRUITER_ANALYTICS,
  GET_RECRUITMENT_ASSISTANT_ANALYTICS,
  GET_USERS,
  GET_VACANCIES_ALL,
  GET_CONTACT_MOMENTS_PER_PERIOD,
} from "../store/action.type";
export default {
  components: {
    EnhancedDatePicker,
  },
  data: () => ({
    selection: {
      year: new Date().getFullYear(),
      week: null,
    },
    weekData: [],
    computedWeekData: [],
    filters: {
      source: null,
      vacancy: null,
      rec: null,
      recAss: null,
      cm: null,
    },
    filterDataLoaded: false,

    tab: null,
    loaded: false,
    dialogDate: false,
    periodSelected: false,
    recruitment_assistent: null,
    recruiter: null,
    date: [],
    contact_moments: [],
    lead_users: [],
    leads: [],
    sollicitants: [],
    intakes: [],
    noshows: [],
    intakes_occured: [],
    contracts: [],
    placed: [],
    rejected: [],
    no_av_vacancy: [],
    contactPeriodMenu: false,
    contact_dates: [],
    search: "",
  }),
  computed: {
    ...mapGetters("vacancy_flow", ["vacancies_all_getter"]),
    ...mapGetters("contact_moments", ["getContactMomentsPerPeriod"]),
    ...mapState({
      channels: (state) => state.leads.channels,
      contactMomentsPerPeriod: (state) =>
        state.contact_moments.contactMomentsPerPeriod,
      employees: (state) =>
        state.users.all.items.employees
          .filter((e) => e.role != null)
          .sort((a, b) => {
            if (a.first_name < b.first_name) {
              return -1;
            }
            if (a.first_name > b.first_name) {
              return 1;
            }
            return 0;
          })
          .map((e) => {
            return {
              id: e.id,
              full_name: e.first_name + " " + e.last_name,
            };
          }),
    }),
    headers() {
      let headers = [
        {
          text: this.$t("dashboard_page.name"),
          value: "name",
          width: "20%",
        },
        {
          text: this.$t("dashboard_page.app"),
          value: "app",
          width: "15%",
        },
        {
          text: this.$t("dashboard_page.cmContact"),
          value: "contact",
          width: "15%",
        },
        {
          text: this.$t("dashboard_page.vm"),
          value: "vm",
          width: "15%",
        },
        {
          text: this.$t("dashboard_page.totalIntakes"),
          value: "totalIntakes",
          width: "15%",
        },
        {
          text: this.$t("dashboard_page.totalCompletedIntakes"),
          value: "totalCompletedIntakes",
          width: "15%",
        },
        {
          text: this.$t("dashboard_page.total"),
          value: "total",
          width: "15%",
        },
        {
          text: "",
          value: "",
          width: "20%",
        },
      ];
      return headers;
    },
    contactMomentsList() {
      return this.contactMomentsPerPeriod.map((e) => {
        var mapped = {
          id: e.id,
          name: e.name,
          app: e.app,
          contact: e.contact,
          vm: e.vm,
          total: e.total,
          totalIntakes: e.totalIntakes,
          totalCompletedIntakes: e.completedIntakes,
        };

        return mapped;
      });
    },
    computedDateRange: {
      get() {
        return this.splitDateRange(this.date);
      },
      set() {
        return null;
      },
    },
    computedContactDateRange: {
      get() {
        return this.splitContactDateRange(this.contact_dates);
      },
      set() {
        this.contact_dates = [];
        this.fetchLeadsForPeriod();
      },
    },
    intakesOccured() {
      let occurances = 0;
      for (let i = 0; i < this.intakes_occured.length; i++) {
        for (let k = 0; k < this.intakes_occured[i].lead_statuses.length; k++) {
          if (
            this.intakes_occured[i].lead_statuses[k].lead_status_id ===
              3 /* Intake */ &&
            k + 1 < this.intakes_occured[i].lead_statuses.length
          ) {
            occurances++;
          }
        }
      }
      return occurances;
    },
    // chartData() {
    //   return {
    //     hoverBackgroundColor: "red",
    //     hoverBorderWidth: 10,
    //     labels: [
    //       "Intakes",
    //       "Contract",
    //       "Geplaatst",
    //       "Afgewezen",
    //       "No-Show",
    //       "Geen geschikte vac.",
    //     ],
    //     datasets: [
    //       {
    //         backgroundColor: [
    //           "#007BFF",
    //           "#0F03FF",
    //           "#139100",
    //           "#A60202",
    //           "#FF0000",
    //           "#FF6F00",
    //         ],
    //         data: [
    //           this.intakesOccured,
    //           this.contracts.length,
    //           this.placed.length,
    //           this.rejected.length,
    //           this.noshows.length,
    //           this.no_av_vacancy.length,
    //         ],
    //       },
    //     ],
    //   };
    // },
    chartData() {
      return {
        labels: [""],
        datasets: [
          {
            label: "Intakes",
            backgroundColor: "#007BFF",
            data: [this.intakesOccured],
          },
          {
            label: "Contract",
            backgroundColor: "#0F03FF",
            data: [this.contracts.length],
          },
          {
            label: "Geplaatst",
            backgroundColor: "#139100",

            data: [this.placed.length],
          },
          {
            label: "Afgewezen",
            backgroundColor: "#A60202",

            data: [this.rejected.length],
          },
          {
            label: "No-Show",
            backgroundColor: "#FF0000",
            data: [this.noshows.length],
          },
          {
            label: "Geen geschikte vac.",
            backgroundColor: "#FF6F00",

            data: [this.no_av_vacancy.length],
          },
        ],
      };
    },
  },
  async created() {
    await this.getUsers();
    await this.getVacanciesAll();
    await this.getChannels();
    this.getContactMoments();
    this.filterDataLoaded = true;
  },
  watch: {
    recruiter() {
      if (!this.recruiter) this.globalAnalytics();
    },
    recruitment_assistent() {
      if (!this.recruitment_assistent) this.globalAnalytics();
    },
  },
  methods: {
    ...mapActions("dashboard", {
      getGlobalAnalytics: GET_GLOBAL_ANALYTICS,
      getRecruiterAnalytics: GET_RECRUITER_ANALYTICS,
      getRecruitmentAssistantAnalytics: GET_RECRUITMENT_ASSISTANT_ANALYTICS,
    }),
    ...mapActions("flow", { getDashboardFlowLeads: GET_DASHBOARD_FLOW_LEADS }),
    ...mapActions("leads", { getChannels: GET_CHANNELS }),
    ...mapActions("users", { getUsers: GET_USERS }),
    ...mapActions("vacancy_flow", {
      getVacanciesAll: GET_VACANCIES_ALL,
    }),
    ...mapActions("contact_moments", {
      getContactMomentsPeriod: GET_CONTACT_MOMENTS_PER_PERIOD,
    }),
    async getData() {
      const week = this.selection.week;
      const year = this.selection.year;
      this.computedWeekData = [];
      this.weekData = [];
      await this.getDashboardFlowLeads({
        week,
        year,
      }).then((res) => {
        this.processData(res);
      });
    },

    async getContactMoments() {
      let now = new Date();
      now = now.toISOString().substring(0, 10);
      let past = new Date();
      past.setDate(past.getDate() - 30);
      past = past.toISOString().substring(0, 10);

      let params = {
        contactStartDate: past,
        contactEndDate: now,
      };

      await this.getContactMomentsPeriod({ params });
    },

    async setContactDates(dates) {
      this.contact_dates = dates;
      if (this.contact_dates.length === 2) {
        let params = {
          contactStartDate: this.contact_dates[0],
          contactEndDate: this.contact_dates[1],
        };
        if (this.contact_dates[0] > this.contact_dates[1]) {
          params.contactStartDate = this.contact_dates[1];
          params.contactEndDate = this.contact_dates[0];
        }
        await this.getContactMomentsPeriod({ params });
      }
    },

    splitContactDateRange(date) {
      if (!date) return null;
      if (date.length === 0) return [];
      const date1 = this.formatDate(date[0]);
      const date2 = this.formatDate(date[1]);
      return `${date1}~${date2}`;
    },

    processData(weeks) {
      for (const [key, value] of Object.entries(weeks)) {
        var newWeekData = {
          weekNo: value.weekNo,
          flows: [],
          leadsCount: value.leads.length,
          vmCount: 0,
          contactCount: 0,
          toContactDays: 0,
          intakeCount: 0,
          toIntakeDays: 0,
          intakedCount: 0,
          toIntakedDays: 0,
          placedCount: 0,
          toPlacedDays: 0,
          exitCount: 0,
          toExitDays: 0,
        };

        value.leads.forEach((lead) => {
          const flow = lead.flow;
          const appDate = lead.applied_date;
          var cleanFlow = {
            ra_id: lead.ra_id,
            rec_id: lead.rec_id,
            intake_plan_ass: null,
            intake_rec: null,
            placed_cm: null,
            channel_id: lead.channel_id,
            vacancy_id: lead.vacancy_id,
            contacted: false,
            intakePlanned: false,
            intaked: false,
            placed: false,
            exit: false,
            dayDif: {
              appliedToContact: null,
              contactToIntake: null,
              intakeToPlace: null,
              intakeToExit: null,
            },
          };
          if (flow != null) {
            if (flow.first_date != null) {
              cleanFlow.dayDif.appliedToContact = Math.round(
                this.dateDifference(appDate, flow.first_date)
              );
              cleanFlow.contacted = true;
            }
            if (flow.intake_date != null) {
              cleanFlow.intakePlanned = true;
              cleanFlow.intake_plan_ass = flow.intake_plan_ass;
            }
            if (
              flow.intake_completed &&
              flow.intake_date != null &&
              flow.first_date != null
            ) {
              cleanFlow.dayDif.contactToIntake = Math.round(
                this.dateDifference(flow.first_date, flow.intake_date)
              );
              cleanFlow.intaked = true;
              cleanFlow.intake_rec = flow.intake_rec;
            }
            // Geplaatst/Posted
            if (flow.placed_date != null && flow.intake_date != null) {
              cleanFlow.dayDif.intakeToPlace = Math.round(
                this.dateDifference(flow.intake_date, flow.placed_date)
              );
              cleanFlow.placed = true;
              cleanFlow.placed_cm = flow.placed_cm;
            }
            if (flow.placed_date != null && flow.exit_date != null) {
              cleanFlow.dayDif.intakeToExit = Math.round(
                this.dateDifference(flow.placed_date, flow.exit_date)
              );
              cleanFlow.exit = true;
            }
          }
          newWeekData.flows.push(cleanFlow);
        });
        this.weekData.push(newWeekData);
      }
      this.finalCalculations();
    },

    dateDifference(firstDate, secondDate) {
      var date1 = new Date(firstDate);
      var date2 = new Date(secondDate);
      if (date2 < date1) return 0;
      var Difference_In_Time = date2.getTime() - date1.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      return Difference_In_Days;
    },

    finalCalculations() {
      this.computedWeekData = [];
      this.weekData.forEach((weekOG) => {
        //Filtering the array, this can probably be done in a better way
        var week = Object.assign({}, weekOG);
        var tempArray = week.flows;
        tempArray = tempArray.filter(this.filterFlow);
        week.flows = tempArray;
        week.leadsCount = week.flows.length;
        week.flows.forEach((flow) => {
          if (flow.contacted) {
            week.contactCount++;
            week.toContactDays += flow.dayDif.appliedToContact;
          }
          if (flow.intakePlanned) {
            week.intakeCount++;
          }
          if (flow.intaked) {
            week.intakedCount++;
            week.toIntakedDays += flow.dayDif.contactToIntake;
          }
          if (flow.placed) {
            week.placedCount++;
            week.toPlacedDays += flow.dayDif.intakeToPlace;
          }
          if (flow.exit) {
            week.exitCount++;
            week.toExitDays += flow.dayDif.intakeToExit;
          }
        });
        week.toContactDays =
          Math.round((week.toContactDays / week.contactCount) * 100) / 100;
        week.toIntakedDays =
          Math.round((week.toIntakedDays / week.intakedCount) * 100) / 100;
        week.toPlacedDays =
          Math.round((week.toPlacedDays / week.placedCount) * 100) / 100;
        week.toExitDays =
          Math.round((week.toExitDays / week.exitCount) * 100) / 100;
        this.computedWeekData.push(week);
      });
    },

    filterFlow(flow) {
      var pass = true;
      if (this.filters.source != null) {
        if (flow.channel_id != this.filters.source) {
          pass = false;
        }
      }
      if (this.filters.vacancy != null) {
        if (flow.vacancy_id != this.filters.vacancy) {
          pass = false;
        }
      }
      if (this.filters.rec != null) {
        if (flow.rec_id != this.filters.rec) {
          pass = false;
        }
      }
      if (this.filters.recAss != null) {
        if (flow.ra_id != this.filters.recAss) {
          pass = false;
        }
      }
      if (this.filters.cm != null) {
        if (flow.placed_cm != this.filters.cm) {
          pass = false;
        }
      }
      return pass;
    },

    phasePercentage(count1, count2) {
      const result = Math.round((count2 / count1) * 10000) / 100;
      if (!isNaN(result)) {
        return result;
      } else {
        return "n.v.t.";
      }
    },

    phaseNeatDayDif(dayCount) {
      if (!isNaN(dayCount)) {
        return dayCount;
      } else {
        return "n.v.t.";
      }
    },

    clearFilters() {
      this.filters = {
        source: null,
        vacancy: null,
        rec: null,
        recAss: null,
        cm: null,
      };
      this.finalCalculations();
    },

    //OLD CODE NEEDS CLEANUP
    async setDate(date) {
      this.$refs.dialog.save(date);
      this.periodSelected = true;
      if (this.recruitment_assistants) this.recruitmentAssistantAnalytics();
      if (this.recruiter) this.recruiterAnalytics();
      this.globalAnalytics();
    },

    async globalAnalytics() {
      const startDate = this.date[0];
      const endDate = this.date[1];

      await this.getGlobalAnalytics({
        startDate,
        endDate,
      }).then((res) => {
        this.contact_moments = res.contact_moments;
        this.lead_users = res.lead_users;
        this.leads = res.leads;
        this.sollicitants = res.sollicitants;
        this.intakes = res.intakes;
        this.noshows = res.noshows;
        this.intakes_occured = res.intakes_occured;
        this.contracts = res.contracts;
        this.placed = res.placed;
        this.rejected = res.rejected;
        this.no_av_vacancy = res.no_av_vacancy;

        this.loaded = true;
      });
    },

    async recruiterAnalytics(recId) {
      if (recId === null) {
        this.globalAnalytics();
      } else {
        const startDate = this.date[0];
        const endDate = this.date[1];

        await this.getRecruiterAnalytics({
          recId,
          startDate,
          endDate,
        }).then((res) => {
          this.contact_moments = res.contact_moments;
          this.lead_users = res.lead_users;
          this.sollicitants = res.sollicitants;
          this.intakes = res.intakes;
          this.noshows = res.noshows;
          this.intakes_occured = res.intakes_occured;
          this.contracts = res.contracts;
          this.placed = res.placed;
          this.rejected = res.rejected;
          this.no_av_vacancy = res.no_av_vacancy;

          this.loaded = true;
        });
      }
    },

    async recruitmentAssistantAnalytics(raId) {
      if (raId === null) {
        this.globalAnalytics();
      } else {
        const startDate = this.date[0];
        const endDate = this.date[1];
        await this.getRecruitmentAssistantAnalytics({
          raId,
          startDate,
          endDate,
        }).then((res) => {
          this.contact_moments = res.contact_moments;
          this.lead_users = res.lead_users;
          this.sollicitants = res.sollicitants;
          this.intakes = res.intakes;
          this.noshows = res.noshows;

          this.loaded = true;
        });
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    splitDateRange(date) {
      if (!date) return null;
      if (date.length === 0) return this.$t("dashboard_page.select_period");
      const date1 = this.formatDate(date[0]);
      const date2 = this.formatDate(date[1]);
      return `${date1}~${date2}`;
    },
  },
};
</script>
