export function getTemplate(name, params, lang) {
  let arr = [];
  for (let i = 0; i < params.length; i++) {
    arr.push(params[i].default);
  }

  let templates_en = [
    {
      name: "werkzoeken_application",
      text: `Hello ${arr[0]},
      We have received an application from you via werkzoeken.nl for the vacancy ${arr[1]}
      I would like to get in touch. I couldn't reach you by phone, when would be a good moment to call?
      Greetings,
      ${arr[2]}`,
    },
    {
      name: "site_application",
      text: `Hello ${arr[0]},
      We have received an application from you for the vacancy ${arr[1]} via our DOEN website. I would like to get in touch. I couldn't reach you by phone, when would be a good moment to call?
      Greetings,
      ${arr[2]}`,
    },
    {
      name: "social_application",
      text: `Hello ${arr[0]},
      We have received your details via Facebook/Instagram in response to the vacancy ${arr[1]}. I would like to get in touch. I couldn't reach you by phone, when would be a good moment to call?
      Greetings,
      ${arr[2]}`,
    },
    {
      name: "indeed_application",
      text: `Hello ${arr[0]},
      We have received an application from you for the vacancy ${arr[1]} through Indeed. I would like to get in touch. I couldn't reach you by phone, when would be a good moment to call?
      Greetings,
      ${arr[2]}
      `,
    },
    {
      name: "general_application_3",
      text: `Hi!
      Thank you for your application and we are happy to hear that you are interested to work in the hospitality industry. When is an available time to call you? We are looking forward to get to know you better! 
      Kind regards, Esmee Janssen, DOEN Horeca Uitzendbureau
      `,
    },
    {
      name: "no_contact_3",
      text: `Hi, we would have liked to talk to you about your application at DOEN Horeca. Unfortunately we were unable to reach you. If you are still interested, please let us know through hr@doenhoreca.nl. 
      Kind regards, Esmee Janssen, DOEN Horeca Uitzendbureau
      `,
    },
    {
      name: "general_application_2",
      text: `Hi!
      Thank you for your application and we are happy to hear that you are interested to work in the hospitality industry. When is an available time to call you? We are looking forward to get to know you better! 
      Kind regards,
      ${arr[0]}
      `,
    },
    {
      name: "general_application",
      text: `Hello,
      I see you have applied for a job with us, when is the best time to reach you?
      Greetings,
      ${arr[0]}
      `,
    },
    {
      name: "job_follow_up_2",
      text: `${arr[0]}`,
    },
    {
      name: "open_to_work_test",
      text: `Are you still looking for a job?

      You applied ${arr[0]} ago. Are you still interested?`,
    },
  ];

  let templates_nl = [
    {
      name: "werkzoeken_application",
      text: `Hoi ${arr[0]},
      Via werkzoeken.nl hebben wij een sollicitatie van je ontvangen voor de vacature ${arr[1]}
      Hartstikke leuk! Ik kom graag in contact. Telefonisch kon ik je niet bereiken, wanneer komt het beter uit? 
      Groetjes, 
      ${arr[2]}`,
    },
    {
      name: "site_application",
      text: `Hoi ${arr[0]},
      Via onze DOEN website hebben wij een sollicitatie van je ontvangen voor de vacature ${arr[1]}. Hartstikke leuk! Ik kom graag in contact. Telefonisch kon ik je niet bereiken, wanneer komt het beter uit? 
      Groetjes, 
      ${arr[2]}`,
    },
    {
      name: "social_application",
      text: `Hoi ${arr[0]},
      Via Facebook/Instagram hebben wij jouw gegevens ontvangen als reactie op de vacature ${arr[1]}. Hartstikke leuk! Ik kom graag in contact. Telefonisch kon ik je niet bereiken, wanneer komt het beter uit? 
      Groetjes, 
      ${arr[2]}`,
    },
    {
      name: "indeed_application",
      text: `Hoi ${arr[0]},
      Via indeed hebben wij een sollicitatie van je ontvangen voor de vacature ${arr[1]}. 
      Hartstikke leuk! Ik kom graag in contact. Telefonisch kon ik je niet bereiken, wanneer komt het beter uit? 
      Groetjes, 
      ${arr[2]}
      `,
    },
    {
      name: "general_application",
      text: `Hoi,
      Je hebt bij ons gesolliciteerd, wanneer kan ik je het beste bereiken? 
      Groetjes, 
      ${arr[0]}
      `,
    },
    {
      name: "general_application_2",
      text: `Hoi! 
      Dank je wel voor je sollicitatie en leuk om te horen dat je interesse hebt om te werken in de horeca. Wanneer zouden wij je het beste kunnen bereiken? We kijken ernaar uit om meer over je te weten te komen! 
      Groet, 
      ${arr[0]}
      `,
    },
    {
      name: "general_application_3",
      text: `Hoi! 
      Dank je wel voor je sollicitatie en leuk om te horen dat je interesse hebt om te werken in de horeca. Wanneer zouden wij je het beste kunnen bereiken? We kijken ernaar uit om meer over je te weten te komen! 
      Groet, Esmee Janssen, DOEN Horeca Uitzendbureau
      `,
    },
    {
      name: "no_contact_3",
      text: `Hoi, we hadden je graag gesproken over je sollicitatie bij DOEN horeca. Helaas is het ons niet gelukt om je te bereiken. Mocht je toch nog geïnteresseerd zijn, laat het ons dan weten via hr@doenhoreca.nl. 
      Met vriendelijke groet, Esmee Janssen, DOEN Horeca Uitzendbureau
      `,
    },
    {
      name: "job_follow_up_2",
      text: `${arr[0]}`,
    },
  ];

  let useTemp = templates_nl;
  if (lang === "en") {
    useTemp = templates_en;
  }

  let templateText = "";
  for (let t = 0; t < useTemp.length; t++) {
    if (useTemp[t].name === name) {
      templateText = useTemp[t].text;
    }
  }
  return templateText;
}
