<template>
  <v-container fluid class="pl-2 pr-2">
    <v-card min-height="380">
      <v-row :style="cardHeaders" class="ma-0">
        <v-row class="pa-5">
          <v-col md="10">
            <v-row>
              <v-icon medium>mdi-book-open-blank-variant</v-icon>
              <h3><strong>Contactmomenten</strong></h3>
            </v-row>
          </v-col>
        </v-row>
      </v-row>
      <v-row>
        <v-col class="pt-0">
          <v-card class="overflow-y-auto" max-height="340" elevation="0">
            <v-container
              v-for="item in activeLeadContactMoments"
              :key="item.id"
              fluid
            >
              <v-row>
                <v-col md="10" class="pb-0 pt-0">
                  <h4 class="pb-1">
                    {{ item.status.name }} |
                    {{ toLocalDate(item.createdAt) }}
                    |
                    {{
                      item.communicator.first_name +
                      " " +
                      item.communicator.last_name
                    }}
                  </h4>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col
                  ><p>
                    <span v-if="item.user_status">
                      <h5>
                        Status na contact moment:
                        <v-chip label :color="item.user_status.status.color"
                          ><strong>{{
                            item.user_status.status.name
                          }}</strong></v-chip
                        >
                      </h5>
                      <span
                        v-if="
                          item.user_status.lead_status_has_sub_statuses.length >
                          0
                        "
                      >
                        <h5>
                          Sub statuses:
                          <div
                            v-for="item in item.user_status
                              .lead_status_has_sub_statuses"
                            :key="item.id"
                          >
                            <v-chip
                              label
                              class="mt-1"
                              :color="item.sub_status.color"
                              ><strong>{{
                                item.sub_status.name
                              }}</strong></v-chip
                            >
                          </div>
                        </h5>
                      </span>
                    </span>
                    <span
                      ><h5>Notities:</h5>
                      {{ item.notes }}</span
                    >
                  </p></v-col
                ></v-row
              >
              <v-divider></v-divider>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: {
    activeLead: Object,
  },
  computed: {
    activeLeadContactMoments() {
      return this.sortContactMoments(this.activeLead.contact_moments);
    },
    cardHeaders() {
      if (this.$vuetify.theme.dark) {
        return "background: #2a2a2a";
      } else {
        return "background: #b3b3b3";
      }
    },
  },
  methods: {
    sortContactMoments(array) {
      let temp = array.sort(function (a, b) {
        return b.id - a.id;
      });
      return temp;
    },
    toLocalDate(date) {
      var utcDate = new Date(date);
      const localeString = utcDate.toLocaleString("nl-NL");
      return localeString.substring(0, localeString.length - 3);
    },
  },
};
</script>
