<template>
  <v-container class="pa-5">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col>
          <v-text-field
            v-model="formDataFields.name"
            :label="$t('sub_statuses.lead_status_name')"
            required
            :loading="adding"
          ></v-text-field>
        </v-col>
        <v-col class="mt-0 pt-0">
          <v-color-picker
            dot-size="25"
            hide-canvas
            hide-mode-switch
            hide-sliders
            mode="hexa"
            v-model="formDataFields.color"
            show-swatches
            swatches-max-height="250"
            class="mt-0"
          ></v-color-picker>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-btn
          :disabled="!valid || adding"
          color="success"
          class="mr-4"
          @click="buttonClick"
        >
          {{ $t("sub_statuses.save") }}
        </v-btn>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { permission } from "@/utils/permissions";
import { mapActions } from "vuex";
import {
  CREATE_SUB_STATUS,
  GET_LEAD_STATUSES,
  UPDATE_SUB_STATUS,
} from "../../store/action.type";
export default {
  props: {
    formData: Object,
    parentID: Number,
    type: String,
  },
  data: () => ({
    adding: false,
    valid: false,
    data_fields: {
      name: "",
      color: "",
    },
  }),

  computed: {
    formDataFields() {
      this.updateFormFields();
      return this.data_fields;
    },
  },

  created() {
    if (this.type == 1) {
      this.data_fields = this.formData;
    }
  },

  methods: {
    ...mapActions("statuses", {
      getLeadStatuses: GET_LEAD_STATUSES,
      createSubStatus: CREATE_SUB_STATUS,
      updateSubStatus: UPDATE_SUB_STATUS,
    }),
    updateFormFields() {
      if (this.type == 1) {
        this.data_fields = this.formData;
      }
    },

    buttonClick() {
      if (this.type == 0) {
        this.create();
      } else if (this.type == 1) {
        this.editSubStatus();
      }
    },

    async editSubStatus() {
      const { dispatch } = this.$store;
      if (this.$refs.form.validate()) {
        this.adding = true;
        let sub_status = {
          name: this.data_fields.name,
          color: this.data_fields.color,
        };
        let statusId = this.formData.id;
        if (permission("canCreateSubStatus") || permission("isAdmin")) {
          await this.updateSubStatus({
            sub_status,
            statusId,
          }).then(() => {
            dispatch(
              "alerts/success",
              this.$t("sub_statuses.statuses_adjusted")
            );
            this.getLeadStatuses();
            this.$emit("close-dialog");
          });
        } else {
          dispatch("alerts/warn", this.$t("sub_statuses.not_authorised"));
        }
      } else {
        dispatch("alerts/warn", this.$t("sub_statuses.empty_fields"));
      }
      this.adding = false;
    },
    async create() {
      const { dispatch } = this.$store;
      if (this.$refs.form.validate()) {
        this.adding = true;
        let lead_status = {
          name: this.data_fields.name,
          color: this.data_fields.color,
          parent_id: this.parentID,
        };
        if (permission("canCreateSubStatus") || permission("isAdmin")) {
          await this.createSubStatus(lead_status).then(() => {
            dispatch(
              "alerts/success",
              this.$t("sub_statuses.sub_status_submitted")
            );
            this.getLeadStatuses();
            this.clear();
            this.$emit("close-dialog");
          });
        } else {
          dispatch("alerts/warn", this.$t("sub_statuses.not_authorised"));
        }
      } else {
        dispatch("alerts/warn", this.$t("sub_statuses.empty_fields"));
      }
      this.adding = false;
    },

    clear() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style scoped></style>
