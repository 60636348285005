<template>
  <v-col xl="3" lg="4" md="6" sm="12">
    <v-container fluid class="pl-0 pr-0">
      <v-card min-height="380">
        <v-row :style="cardHeaders" class="ma-0">
          <v-row>
            <v-col md="10">
              <v-row class="pa-5">
                <v-icon medium>mdi-format-list-bulleted</v-icon>
                <h3>
                  <strong>{{ $t("profile_page.status_history") }}</strong>
                </h3>
              </v-row>
            </v-col>
            <v-col md="1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div class="pa-0 ma-0" v-on="on" v-bind="attrs">
                    <v-switch
                      class="pt-3 my-0"
                      v-model="statusHistorySwitch"
                      color="primary"
                    ></v-switch>
                  </div>
                </template>
                <span>{{
                  $t("profile_page.display__statuses_current_lead")
                }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-row>
        <v-row>
          <v-col class="pt-0">
            <v-card
              v-if="statusHistory.length > 0"
              elevation="0"
              class="pa-0 pr-3 overflow-y-auto"
              max-height="300"
            >
              <v-timeline dense>
                <v-timeline-item
                  v-for="item in statusHistory.slice().reverse()"
                  :key="item.id"
                  :color="item.status.color"
                  name="status"
                  small
                >
                  <v-row>
                    <v-col>
                      <h4>
                        {{ toLocalDate(item.createdAt) }} -
                        {{ item.status.name }} - Phase
                        {{ item.status.to_phase }}
                      </h4>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </v-card>
            <v-banner
              v-else-if="statusHistory.length == 0"
              color="grey"
              elevation="0"
              dense
              style="color: white"
              >{{ $t("profile_page.no_status_history") }}</v-banner
            >
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-col>
</template>
<script>
export default {
  props: ["cardHeaders", "mainLead"],
  data() {
    return {
      statusHistorySwitch: false,
    };
  },
  computed: {
    statusHistory() {
      let statuses =
        this.$store.state.lead_users.active_lead_user.lead_statuses;
      if (this.statusHistorySwitch) {
        let arr = [];
        for (let i = 0; i < statuses.length; i++) {
          if (statuses[i].lead_id == this.mainLead.id) {
            arr.push(statuses[i]);
          }
        }
        return arr;
      }
      return statuses;
    },
  },
  methods: {
    toLocalDate(date) {
      var utcDate = new Date(date);
      const localeString = utcDate.toLocaleString("nl-NL");
      return localeString.substring(0, localeString.length - 3);
    },
  },
};
</script>
