<template>
  <v-container class="px-9">
    <v-form ref="form" lazy-validation>
      <v-row
        v-if="type === '0'"
        class="justify-center pb-5"
        style="font-size: 1.5rem"
      ></v-row>
      <v-row
        v-if="type === '1'"
        class="justify-center pb-5"
        style="font-size: 1.5rem"
      >
        {{ this.formData.first_name + " " + this.formData.last_name }}
      </v-row>

      <v-row>
        <v-combobox
          v-model="employeePermissions"
          :items="items"
          item-text="name"
          item-value="id"
          chips
          clearable
          label="Permissions"
          multiple
          prepend-icon="mdi-filter-variant"
          solo
          return-object
        >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
              label
              v-bind="attrs"
              :input-value="selected"
              close
              @click="select"
              @click:close="remove(item)"
            >
              <strong>{{ item.name }}</strong
              >&nbsp;
              <span></span>
            </v-chip>
          </template>
        </v-combobox>
      </v-row>
      <v-row justify="center" class="pb-4">
        <v-btn color="success" class="mr-4" @click="submit"> save </v-btn>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import {
  GET_PERMISSIONS,
  GET_USER_PERMISSIONS,
  UPDATE_PERMISSIONS,
  GET_SELF,
} from "../../store/action.type";
export default {
  props: {
    type: String,
    formData: Object,
  },
  data: () => ({
    employeePermissions: [],
    items: [],
  }),

  computed: {},

  created() {
    this.populateAllPermissions();
    if (this.type === "1") {
      this.populateUserPermissions();
    }
  },

  methods: {
    ...mapActions("users", {
      getPermissions: GET_PERMISSIONS,
      getUserPermissions: GET_USER_PERMISSIONS,
      updatePermissions: UPDATE_PERMISSIONS,
      getSelf: GET_SELF,
    }),

    async populateUserPermissions() {
      let empId = this.formData.id;
      await this.getUserPermissions({ empId }).then((res) => {
        for (let i = 0; i < res.permissions.length; i++) {
          for (let j = 0; j < this.items.length; j++) {
            if (res.permissions[i].permission_id === this.items[j].id) {
              this.employeePermissions.push(this.items[j]);
            }
          }
        }
      });
    },

    async populateAllPermissions() {
      await this.getPermissions().then((res) => {
        this.items = res.permissions;
      });
    },

    remove(item) {
      this.employeePermissions.splice(
        this.employeePermissions.indexOf(item),
        1
      );
      this.employeePermissions = [...this.employeePermissions];
    },

    async submit() {
      let permArr = [];
      for (let i = 0; i < this.employeePermissions.length; i++) {
        permArr.push(this.employeePermissions[i].id);
      }
      let empId = this.formData.id;
      await this.updatePermissions({ permArr, empId });
      //Get Self in case the user changed his own permissions
      this.populateUserPermissions();
      await this.getSelf(this.$auth.user.sub);
      this.$emit("close-dialog");
    },
  },
};
</script>

<style scoped></style>
