<template>
  <v-container fluid>
    <v-dialog v-model="dialogCreate" max-width="600px">
      <CreateLead v-on:close-dialog="dialogCreate = false" type="0" />
    </v-dialog>
    <v-dialog v-model="dialogAddContactMoment" max-width="600px">
      <v-card>
        <CreateContactMoment
          v-if="selectedRow"
          v-on:close-dialog="dialogAddContactMoment = false"
          v-on:reload="reload"
          type="0"
          :parentID="selectedRow.id"
        ></CreateContactMoment>
      </v-card>
    </v-dialog>
    <v-card class="pa-4" :loading="!loaded">
      <v-row>
        <v-col md="8">
          <v-row class="pa-3">
            <h2>Leads</h2>
            <v-divider
              vertical
              inset
              style="padding-left: 10px; padding-left: 10px"
            ></v-divider>
            <v-text-field
              style="padding-top: 0px; padding-left: 10px"
              v-model="filters.search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @change="storeFilterToState"
            ></v-text-field>
          </v-row>
        </v-col>
        <v-col align="end" md="4">
          <v-btn icon color="primary" class="mb-2" @click="reload">
            <v-icon dark>mdi-reload</v-icon>
          </v-btn>
          <v-btn icon color="primary" class="mb-2" @click="dialogCreate = true">
            <v-icon dark>mdi-plus-box</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-menu
        v-model="showMenu"
        :position-x="x"
        :position-y="y"
        absolute
        offset-y
      >
        <v-list dense>
          <v-list-item @click="open" dense>
            <v-list-item-title>Open</v-list-item-title>
          </v-list-item>
          <v-list-item @click="openNewTab" dense>
            <v-list-item-title>Nieuw tabblad</v-list-item-title>
          </v-list-item>
          <v-list-item @click="dialogAddContactMoment = true" dense>
            <v-list-item-title>Voeg contactmoment toe</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-data-table
        v-if="loaded"
        :page.sync="pagination.page"
        :items-per-page="pagination.itemsPerPage"
        :footer-props="{ itemsPerPageOptions: [20, 35, 50, -1] }"
        :headers="headers"
        :items="leadsAdjustedList"
        :search="filters.search"
        sort-by="name"
        class="elevation-1 pointerOnHover"
        @click:row="editItem"
        @contextmenu:row="show"
        @pagination="storePaginationToState"
      >
        <template v-slot:top>
          <v-expansion-panels focusable flat :value="collapsed">
            <v-expansion-panel>
              <v-expansion-panel-header class="px-4 pt-4"
                ><h2>Filter</h2>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container fluid>
                  <v-row dense>
                    <v-col align="end" class="mr-2 mt-2 mb-0">
                      <v-btn icon color="red" @click="clearFilters()"
                        ><span class="mr-1">Clear</span
                        ><v-icon dark>mdi-backspace</v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-autocomplete
                        dense
                        class="pt-1"
                        :items="lead_statuses"
                        v-model="filters.statuses"
                        chips
                        deletable-chips
                        multiple
                        item-text="name"
                        return-object
                        label="Status"
                        @change="storeFilterToState"
                        ><template
                          v-slot:selection="{ attr, on, item, selected }"
                        >
                          <v-chip
                            label
                            v-bind="attr"
                            :input-value="selected"
                            v-on="on"
                            close
                            :color="item.color"
                            @click:close="remove(item)"
                            ><div style="color: black">
                              <strong>{{ item.name }}</strong>
                            </div></v-chip
                          >
                        </template></v-autocomplete
                      >
                    </v-col>
                    <v-col>
                      <v-autocomplete
                        :items="companies"
                        v-model="filters.company"
                        clearable
                        item-text="name"
                        item-value="name"
                        label="Bedrijf"
                        @change="storeFilterToState"
                      ></v-autocomplete>
                    </v-col>
                    <v-col>
                      <v-autocomplete
                        :items="vacancies"
                        v-model="filters.vacancy"
                        clearable
                        item-text="name"
                        item-value="name"
                        label="Vacature"
                        @change="storeFilterToState"
                      ></v-autocomplete>
                    </v-col>
                    <v-col>
                      <v-autocomplete
                        :items="channels"
                        v-model="filters.channels"
                        clearable
                        multiple
                        item-text="name"
                        return-object
                        label="Kanaal"
                        @change="storeFilterToState"
                      ></v-autocomplete>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="filters.region"
                        append-icon="mdi-magnify"
                        label="Woonplaats"
                        single-line
                        hide-details
                        @change="storeFilterToState"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-autocomplete
                        :items="employees"
                        v-model="filters.recruiter"
                        clearable
                        item-text="full_name"
                        item-value="full_name"
                        label="Recruiter"
                      ></v-autocomplete>
                    </v-col>
                    <v-col>
                      <v-autocomplete
                        :items="employees"
                        v-model="filters.recruiter_assistent"
                        clearable
                        item-text="full_name"
                        item-value="full_name"
                        label="Assistent"
                      ></v-autocomplete>
                    </v-col>
                    <v-col>
                      <v-menu
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="computedDateRangeApplication"
                            label="Sollicitatieperiode"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            @change="storeFilterToState"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="filters.application_dates"
                          locale="nl-NL"
                          color="green"
                          header-color="primary"
                          range
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col>
                      <v-menu
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="computedDateRangeContact"
                            label="Contactperiode"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            @change="storeFilterToState"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="filters.contact_dates"
                          locale="nl-NL"
                          color="green"
                          header-color="primary"
                          range
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>

        <template v-slot:[`item.status_name`]="{ item }">
          <v-chip label :color="item.status_color"
            ><div style="color: black">
              <strong>{{ item.status_name }}</strong>
            </div></v-chip
          >
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import CreateLead from "../components/dialogs/CreateLead";
import CreateContactMoment from "../components/dialogs/CreateContactMoment.vue";
import {
  GET_CHANNELS,
  GET_COMPANIES,
  GET_LEAD_STATUSES,
  GET_LEAD_USERS_WITH_LEAD,
  GET_USERS,
  GET_VACANCIES,
} from "../store/action.type";
import { mapState, mapActions, mapMutations } from "vuex";
import {
  SET_LEADS_PAGINATION,
  SET_LEAD_USERS_PAGINATION,
} from "../store/mutation.type";
export default {
  components: { CreateLead, CreateContactMoment },
  data: () => ({
    loaded: false,
    dialogCreate: false,
    dialogAddContactMoment: false,
    appliedMenu: false,
    collapsed: 0,
    filters: {},
    selectedRow: null,
    showMenu: false,
    x: 0,
    y: 0,
  }),

  computed: {
    computedDateRangeApplication: {
      get() {
        return this.splitDateRange(this.filters.application_dates);
      },
      set() {
        return null;
      },
    },
    computedDateRangeContact: {
      get() {
        return this.splitDateRange(this.filters.contact_dates);
      },
      set() {
        return null;
      },
    },
    headers() {
      return [
        {
          text: "Status",
          value: "status_name",
          align: "start",
          filter: (value) => {
            if (!this.filters.statuses) return true;
            if (this.filters.statuses.length <= 0) return true;
            return this.filters.statuses.some((elem) => elem.name === value);
          },
        },
        { text: "Naam", value: "user_name" },
        {
          text: "Vacature",
          value: "vacancy",
          filter: (value) => {
            if (!this.filters.vacancy) return true;
            return value === this.filters.vacancy;
          },
        },
        {
          text: "Bedrijf",
          value: "company",
          filter: (value) => {
            if (!this.filters.company) return true;
            return value === this.filters.company;
          },
        },
        {
          text: "Kanaal",
          value: "channel.name",
          filter: (value) => {
            if (!this.filters.channels) return true;
            if (this.filters.channels.length <= 0) return true;
            return this.filters.channels.some((elem) => elem.name === value);
          },
        },
        {
          text: "Woonplaats",
          value: "userRegion",
          filter: (value) => {
            if (!this.filters.region) return true;
            return value
              .toLowerCase()
              .includes(this.filters.region.toLowerCase());
          },
        },
        {
          text: "Solliciatie",
          value: "applied_date",
          descending: false,
          filter: (value) => {
            if (!this.filters.application_dates) return true;
            if (this.filters.application_dates.length <= 1) return true;
            const dbFormatDate = this.dbFormat(value);
            return (
              dbFormatDate >= this.filters.application_dates[0] &&
              dbFormatDate <= this.filters.application_dates[1]
            );
          },
        },
        {
          text: "Recruitment Assistent",
          value: "recruitment_assistant",
          filter: (value) => {
            if (!this.filters.recruiter_assistent) return true;
            return value === this.filters.recruiter_assistent;
          },
        },
        {
          text: "Recruiter",
          value: "recruiter",
          filter: (value) => {
            if (!this.filters.recruiter) return true;
            return value === this.filters.recruiter;
          },
        },
        {
          text: "Laatste Contact Moment",
          value: "contact_moment",
          filter: (value) => {
            if (!this.filters.contact_dates) return true;
            if (this.filters.contact_dates.length <= 1) return true;
            const dbFormatDate = this.dbFormat(value);
            return (
              dbFormatDate >= this.filters.contact_dates[0] &&
              dbFormatDate <= this.filters.contact_dates[1]
            );
          },
        },
      ];
    },
    leadsAdjustedList() {
      return this.leads.map((e) => {
        return {
          id: e.id,
          user_name: `${e.first_name.trim()} ${e.last_name.trim()}`,
          recruiter: this.fullName(this.latestLead(e).recruiter),
          recruitment_assistant: this.fullName(
            this.latestLead(e).recruitment_assistant
          ),
          status_name: this.latestStatus(e).name,
          status_color: this.latestStatus(e).color,
          contact_moment: this.latestContact(e),
          vacancy: this.latestLead(e).vacancy.name,
          company: this.latestLead(e).vacancy.company.name,
          userRegion: this.itemExists(e.region),
          channel: this.latestLead(e).channel,
          applied_date: this.formatDate(this.latestLead(e).applied_date),
        };
      });
    },
    ...mapState({
      stored_filters: (state) => state.filters.lead_filter,

      leads: (state) => state.lead_users.lead_users_with_lead,

      lead_statuses: (state) => state.statuses.lead_statuses,

      vacancies: (state) =>
        state.vacancy.vacancies.vacancies.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        }),

      channels: (state) => state.leads.channels,

      companies: (state) =>
        state.company.companies.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        }),

      employees: (state) =>
        state.users.all.items.employees
          .filter((e) => e.role <= 4)
          .sort((a, b) => {
            if (a.first_name < b.first_name) {
              return -1;
            }
            if (a.first_name > b.first_name) {
              return 1;
            }
            return 0;
          })
          .map((e) => {
            return {
              id: e.id,
              full_name: e.first_name + " " + e.last_name,
            };
          }),

      pagination: (state) => state.leads.pagination,
    }),
  },

  created() {
    this.initialize();
    this.filters = this.stored_filters;
  },

  methods: {
    ...mapActions("company", { getCompanies: GET_COMPANIES }),
    ...mapActions("lead_users", {
      getLeadUsersWithLead: GET_LEAD_USERS_WITH_LEAD,
    }),
    ...mapActions("leads", { getChannels: GET_CHANNELS }),
    ...mapActions("statuses", {
      getLeadStatuses: GET_LEAD_STATUSES,
    }),
    ...mapActions("users", { getUsers: GET_USERS }),
    ...mapActions("vacancy", { getVacancies: GET_VACANCIES }),
    ...mapMutations("leads", { setLeadsPagination: SET_LEADS_PAGINATION }),
    async initialize() {
      if (Object.keys(this.leads).length === 0) {
        await this.getLeadUsersWithLead();
        await this.getVacancies();
        await this.getChannels();
        await this.getCompanies();
        await this.getLeadStatuses();
        await this.getUsers();
      }
      this.loaded = true;
    },

    async reload() {
      this.loaded = false;

      await this.getLeadUsersWithLead();
      await this.getVacancies();
      await this.getCompanies();
      await this.getLeadStatuses();
      await this.getUsers();

      this.loaded = true;
    },

    async storeFilterToState() {
      const { dispatch } = this.$store;
      await dispatch("filters/storeLeadFilter", this.filters);
    },

    storePaginationToState(val) {
      this.setLeadsPagination(val);
    },

    clearFilters() {
      this.filters = {};
      this.storeFilterToState();
    },

    fullName(item) {
      if (item != undefined) {
        return item.first_name + " " + item.last_name;
      }
      return "Niet toegewezen";
    },

    remove(item) {
      let index = -1;
      this.filters.statuses.find(function (status, i) {
        if (status.name === item.name) {
          index = i;
        }
      });
      if (index >= 0) {
        this.filters.statuses.splice(index, 1);
      }
    },

    dbFormat(date) {
      if (!date) return null;

      const [day, month, year] = date.split("-");
      return `${year}-${month}-${day}`;
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },

    latestLead(item) {
      if (item.leads.length > 0) {
        return item.leads[item.leads.length - 1];
      } else {
        return undefined;
      }
    },

    splitDateRange(date) {
      if (!date) return null;
      if (date.length === 0) return [];
      const date1 = this.formatDate(date[0]);
      const date2 = this.formatDate(date[1]);
      return `${date1}~${date2}`;
    },

    latestStatus(item) {
      if (item.lead_statuses.length > 0) {
        return item.lead_statuses[item.lead_statuses.length - 1].status;
      } else {
        return { name: "Geen", color: "#FFD700" };
      }
    },
    itemExists(item) {
      if (item == null || item == "") return "Onbekend";
      return item;
    },
    latestContact(item) {
      if (item.contact_moments.length > 0) {
        return this.formatDate(
          item.contact_moments[
            item.contact_moments.length - 1
          ].createdAt.substring(0, 10)
        );
      } else {
        return "Geen";
      }
    },

    positions() {
      this.activeCompanyPositions = [];
      for (var position in this.activeCompany.company.positions) {
        this.activeCompanyPositions.push(
          this.activeCompany.company.positions[position].position
        );
      }
    },

    editItem(item) {
      this.$router.push(`/profiel/${item.id}`);
    },

    open() {
      this.$router.push(`/profiel/${this.selectedRow.id}`);
    },

    openNewTab() {
      let routeData = this.$router.resolve({
        path: `/profiel/${this.selectedRow.id}`,
      });
      window.open(routeData.href, "_blank");
    },

    show(event, row) {
      event.preventDefault();
      this.showMenu = false;
      this.x = event.clientX;
      this.y = event.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
      this.selectedRow = row.item;
    },
  },
};
</script>
