<template>
  <v-card elevation="1">
    <!-- Renders a chart using ApexCharts when `isLoaded` is true to avoid creating chart before the variables are ready-->
    <apexchart
      v-if="isLoaded"
      width="100%"
      height="120"
      :options="options"
      :series="series"
    ></apexchart>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    newLeadsData: Object,
  },
  data: () => ({
    isLoaded: false,
  }),
  computed: {
    computedSeries() {
      return this.newLeadsData.leadsCountPerDay.map((item) => item.count);
    },
    computedCategories() {
      return this.newLeadsData.leadsCountPerDay.map((item) => item.day);
    },
    options() {
      return {
        series: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        chart: {
          type: "area",
          height: 160,
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          curve: "straight",
        },
        fill: {
          opacity: 0.3,
        },
        yaxis: {
          min: 0,
        },
        colors: ["#00b4d8"],
        title: {
          text: this.newLeadsData.leadsCount,
          offsetX: 0,
          style: {
            fontSize: "24px",
          },
        },
        xaxis: {
          categories: this.computedCategories,
        },
        subtitle: {
          text: "New Leads",
          offsetX: 0,
          style: {
            fontWeight: "bold",
            fontSize: "14px",
          },
        },
      };
    },
    series() {
      return [
        {
          name: "Leads",
          data: this.computedSeries,
        },
      ];
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.isLoaded = true;
    },
  },
};
</script>

<style scoped></style>
