<template>
  <v-container class="pa-5">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-select
        :items="platforms"
        :label="$t('create_company.doen_zoomer')"
        v-model="data_fields.platform_name"
        :rules="[(v) => !!v || $t('create_company.required_field')]"
        required
      ></v-select>
      <v-row>
        <v-col>
          <v-text-field
            v-model="data_fields.name"
            :label="$t('create_company.name')"
          ></v-text-field>
        </v-col>

        <v-col>
          <v-text-field
            v-model="data_fields.kvk"
            :rules="[(v) => !!v || $t('create_company.required_kvk')]"
            :label="$t('create_company.kvk')"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-text-field
        v-model="data_fields.phone"
        :rules="[(v) => !!v || $t('create_company.required_tel')]"
        :label="$t('create_company.tel')"
        required
      ></v-text-field>

      <v-text-field
        v-model="data_fields.street"
        :rules="[(v) => !!v || $t('create_company.required_street_name')]"
        :label="$t('create_company.street_name')"
        required
      ></v-text-field>

      <v-row>
        <v-col>
          <v-text-field
            v-model="data_fields.postalcode"
            :rules="[(v) => !!v || $t('create_company.required_postcode')]"
            :label="$t('create_company.postcode')"
            required
          ></v-text-field>
        </v-col>

        <v-col>
          <v-text-field
            v-model="data_fields.houseno"
            :rules="[(v) => !!v || $t('create_company.required_house_number')]"
            :label="$t('create_company.house_number')"
            required
          ></v-text-field>
        </v-col>

        <v-col>
          <v-text-field
            v-model="data_fields.addition"
            :label="$t('create_company.addition')"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-text-field
        v-model="data_fields.region"
        :rules="[(v) => !!v || $t('create_company.required_place')]"
        :label="$t('create_company.place')"
        required
      ></v-text-field>

      <v-text-field
        v-model="data_fields.site_url"
        :label="$t('create_company.website')"
      ></v-text-field>

      <v-textarea
        outlined
        v-model="data_fields.description"
        :label="$t('create_company.description')"
        auto-grow
      ></v-textarea>
      <v-row>
        <h3 style="padding-left: 10px">
          {{ $t("create_company.used_for_reqr") }}
        </h3>
      </v-row>
      <v-row>
        <v-switch
          v-model="data_fields.name_use"
          style="padding-left: 10px"
          :label="$t('create_company.company_name')"
        ></v-switch>
        <v-switch
          v-model="data_fields.logo_use"
          style="padding-left: 10px"
          :label="$t('create_company.logo')"
        ></v-switch>
        <v-switch
          v-model="data_fields.photo_use"
          style="padding-left: 10px"
          :label="$t('create_company.photo')"
        ></v-switch>
      </v-row>
      <v-row justify="center">
        <v-btn
          :disabled="!valid && !adding"
          color="success"
          class="mr-4"
          @click="buttonClick"
        >
          {{ types[type].buttonText }}
        </v-btn>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { calculateLocation } from "../../helpers/google";
import {
  CREATE_COMPANY,
  GET_COMPANIES,
  GET_COMPANY,
  UPDATE_COMPANY,
} from "../../store/action.type";
import { mapActions } from "vuex";
import { permission } from "../../utils/permissions";
export default {
  props: {
    type: String,
    formData: Object,
  },
  data: () => ({
    platforms: ["DOEN", "Zoomer"],
    adding: false,
    valid: true,
    data_fields: {
      platform_name: "",
      name: "",
      kvk: "",
      phone: "",
      street: "",
      postalcode: "",
      houseno: "",
      addition: "",
      region: "",
      site_url: "",
      description: "",
      name_use: false,
      logo_use: false,
      photo_use: false,
    },
  }),

  computed: {
    types() {
      return [
        {
          buttonText: this.$t("create_company.create_company"),
        },
        {
          buttonText: this.$t("create_company.customize_company"),
        },
      ];
    },
  },

  created() {
    if (this.type == 1) {
      this.data_fields = this.formData;
      this.data_fields.street = this.formData.address.street;
      this.data_fields.postalcode = this.formData.address.postalcode;
      this.data_fields.houseno = this.formData.address.houseno;
      this.data_fields.addition = this.formData.address.addition;
    }
  },

  methods: {
    ...mapActions("company", {
      createCompany: CREATE_COMPANY,
      updateCompany: UPDATE_COMPANY,
      getCompany: GET_COMPANY,
      getCompanies: GET_COMPANIES,
    }),

    buttonClick() {
      if (this.type == 0) {
        this.addCompany();
      } else if (this.type == 1) {
        this.editCompany();
      }
    },

    async editCompany() {
      const { dispatch } = this.$store;
      if (this.$refs.form.validate()) {
        if (permission("canCreateCompanies") || permission("isAdmin")) {
          this.adding = true;

          let company = {
            platform_name: this.data_fields.platform_name,
            kvk: this.data_fields.kvk,
            name: this.data_fields.name,
            phone: this.data_fields.phone,
            address: {
              street: this.data_fields.street,
              postalcode: this.data_fields.postalcode,
              houseno: this.data_fields.houseno,
              addition: this.data_fields.addition,
              region: this.data_fields.region,
            },
            region: this.data_fields.region,
            site_url: this.data_fields.site_url,
            name_use: this.data_fields.name_use,
            logo_use: this.data_fields.logo_use,
            photo_use: this.data_fields.photo_use,
            description: this.data_fields.description,
          };

          if (company.kvk == "") {
            console.log(123);
            company.kvk = "UNKNOWN " + company.name;
          }

          await calculateLocation(company.address).then((res) => {
            company.address.lat = res.lat;
            company.address.lng = res.lng;
          });

          const compId = this.data_fields.id;

          company.address = JSON.stringify(company.address);
          await this.updateCompany({ company, compId }).then(() => {
            dispatch("alerts/success", this.$t("create_company.company_saved"));
            this.getCompany(compId);
            this.adding = false;
            this.$emit("close-dialog");
          });
        } else {
          dispatch("alerts/warn", this.$t("create_company.not_authorized_msg"));
        }
      } else {
        dispatch(
          "alerts/warn",
          this.$t("create_company.not_all_fields_completed")
        );
      }
    },

    async addCompany() {
      const { dispatch } = this.$store;
      if (this.$refs.form.validate()) {
        if (permission("canCreateCompanies") || permission("isAdmin")) {
          this.adding = true;

          let company = {
            platform_name: this.data_fields.platform_name,
            kvk: this.data_fields.kvk,
            name: this.data_fields.name,
            phone: this.data_fields.phone,
            address: {
              street: this.data_fields.street,
              postalcode: this.data_fields.postalcode,
              houseno: this.data_fields.houseno,
              addition: this.data_fields.addition,
              region: this.data_fields.region,
            },
            region: this.data_fields.region,
            site_url: this.data_fields.site_url,
            name_use: this.data_fields.name_use,
            logo_use: this.data_fields.logo_use,
            photo_use: this.data_fields.photo_use,
            description: this.data_fields.description,
          };

          await calculateLocation(company.address).then((res) => {
            company.address.lat = res.lat;
            company.address.lng = res.lng;
          });

          company.address = JSON.stringify(company.address);
          await this.createCompany(company).then(() => {
            this.getCompanies();
            dispatch(
              "alerts/success",
              this.$t("create_company.company_created")
            );
          });
          this.adding = false;
          this.clear();
          this.$emit("close-dialog");
        } else {
          dispatch("alerts/warn", this.$t("create_company.not_authorized_msg"));
        }
      } else {
        dispatch(
          "alerts/warn",
          this.$t("create_company.not_all_fields_completed")
        );
      }
    },
    clear() {
      this.$refs.form.reset();
    },
  },
};
</script>
