import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";

Vue.use(Vuetify);

let vuetify = new Vuetify({
  theme: {
    options: { customProperties: true },
  },
});
Vue.use(TiptapVuetifyPlugin, { vuetify, iconsGroup: "mdi" });

export default vuetify;
