import config from "../../../config";
import { authHeader } from "../../utils/auth-header";
import { handleResponse } from "../../utils/api_functions";
import { logger } from "../../utils/logger";

import {
  ADD_POSITION_TO_COMPANY,
  CREATE_POSITION,
  GET_POSITIONS,
  REMOVE_POSITION_FROM_COMPANY,
} from "../action.type";
import {
  GET_POSITION_FAILED,
  GET_POSITION_REQUEST,
  GET_POSITION_SUCCESS,
} from "../mutation.type";

export const positions = {
  namespaced: true,
  state: {
    status: {},
    positions: {},
  },
  actions: {
    async [CREATE_POSITION](_, position) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(position),
        };
        return await fetch(`${config.apiUrl}/postitions`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [GET_POSITIONS]({ commit }) {
      try {
        commit(GET_POSITION_REQUEST);
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/postitions`, request)
          .then(handleResponse)
          .then((res) => {
            commit(GET_POSITION_SUCCESS, res);
            return res;
          });
      } catch (error) {
        commit(GET_POSITION_FAILED, error);
        logger.error(error);
      }
    },
    async [ADD_POSITION_TO_COMPANY]({ commit, dispatch }, compPosition) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(compPosition),
        };
        return await fetch(`${config.apiUrl}/companies/position`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        commit(GET_POSITION_FAILED, error);
        dispatch("alerts/error", error, { root: true });
        logger.error(error);
      }
    },
    async [REMOVE_POSITION_FROM_COMPANY]({ commit }, { comp_id, pos_id }) {
      try {
        commit(GET_POSITION_REQUEST);
        const request = {
          method: "DELETE",
          headers: authHeader(),
        };
        return fetch(
          `${config.apiUrl}/companies/position/${comp_id}/${pos_id}`,
          request
        )
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        commit(GET_POSITION_FAILED, error);
        logger.error(error);
      }
    },
  },
  mutations: {
    [GET_POSITION_REQUEST](state) {
      state.status = { loading: true };
    },
    [GET_POSITION_SUCCESS](state, positions) {
      state.status = { loading: false };
      state.positions = positions;
    },
    [GET_POSITION_FAILED](state, error) {
      state.status = { loading: false };
      state.all = { error };
    },
  },
};
