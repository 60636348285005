import config from "../../../config";
import { authHeader } from "../../utils/auth-header";
import { handleResponse } from "../../utils/api_functions";
import { logger } from "../../utils/logger";

import {
  DELETE_DOCUMENT,
  DOWNLOAD_DOCUMENT,
  UPLOAD_DOCUMENT,
} from "../action.type";

export const file_upload = {
  namespaced: true,
  state: {},
  actions: {
    async [UPLOAD_DOCUMENT](_, { fileToUpload, uploadData }) {
      try {
        let alteredHeader = authHeader();
        delete alteredHeader["Content-Type"];
        const formData = new FormData();
        formData.append("file", fileToUpload);
        formData.append("data", JSON.stringify(uploadData));
        const request = {
          method: "POST",
          headers: alteredHeader,
          body: formData,
        };
        return await fetch(`${config.apiUrl}/file-upload`, request)
          .then(handleResponse)
          .then((response) => {
            return response;
          });
      } catch (error) {
        logger.error(error);
      }
    },

    async [DOWNLOAD_DOCUMENT](_, docID) {
      try {
        let headersCustom = authHeader();
        headersCustom.responseType = "blob";
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return fetch(`${config.apiUrl}/file-upload/${docID}`, request).then(
          (response) => {
            const contentDip = response.headers.get("Content-Disposition");
            const filename = contentDip.substring(
              contentDip.indexOf("filename=") + 10,
              contentDip.length - 1
            );
            response.blob().then((blob) => download(blob, filename));
            return;
          }
        );
      } catch (error) {
        logger.log(error);
      }
    },

    async [DELETE_DOCUMENT](_, docID) {
      try {
        const request = {
          method: "DELETE",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/file-uploader/${docID}`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
  },
  mutations: {},
};

function download(blob, filename) {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  // the filename you want
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}
