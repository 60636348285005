import config from "../../../config";
import { authHeader } from "../../utils/auth-header";
import { handleResponse } from "../../utils/api_functions";
import { logger } from "../../utils/logger";
import {
  GET_ALL_HOTLISTS,
  GET_HOTLIST_BY_ID,
  UPDATE_HOTLIST,
  CREATE_HOTLIST,
  DELETE_HOTLIST,
} from "../action.type";
import { SET_HOTLISTS, SET_ACTIVE_HOTLIST } from "../mutation.type";

export const hotlists = {
  namespaced: true,
  state: { hotlists: [], activeHotlist: {} },
  getters: {
    hotlists_getter: (state) => state.hotlists,
    hotlist_members_getter: (state) => (id) => {
      let members = [];
      state.hotlists.map((hotlist) => {
        if (hotlist.id === id) {
          members = hotlist.hotlist_members;
        }
      });
      return members;
    },
  },
  actions: {
    async [GET_ALL_HOTLISTS]({ commit }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/hotlists`, request)
          .then(handleResponse)
          .then((res) => {
            commit(SET_HOTLISTS, res.hotlists);
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [GET_HOTLIST_BY_ID]({ commit }, id) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/hotlists/${id}`, request)
          .then(handleResponse)
          .then((res) => {
            commit(SET_ACTIVE_HOTLIST, res.hotlist);
            return res.hotlist;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [UPDATE_HOTLIST]({ commit }, { hotlist, id }) {
      try {
        const request = {
          method: "PUT",
          headers: authHeader(),
          body: JSON.stringify(hotlist),
        };
        return await fetch(`${config.apiUrl}/hotlists/${id}`, request).then(
          handleResponse
        );
      } catch (error) {
        logger.error(error);
      }
    },
    async [CREATE_HOTLIST]({ commit }, { hotlist }) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(hotlist),
        };
        return await fetch(`${config.apiUrl}/hotlists/`, request).then(
          handleResponse
        );
      } catch (error) {
        logger.error(error);
      }
    },
    async [DELETE_HOTLIST]({ commit }, { id }) {
      try {
        const request = {
          method: "DELETE",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/hotlists/${id}`, request).then(
          handleResponse
        );
      } catch (error) {
        logger.error(error);
      }
    },
  },

  mutations: {
    [SET_HOTLISTS](state, hotlists) {
      state.hotlists = hotlists;
    },
    [SET_ACTIVE_HOTLIST](state, hotlists) {
      state.activeHotlist = hotlists;
    },
  },
};
