import config from "../../../config";
import { authHeader } from "../../utils/auth-header";
import { handleResponse } from "../../utils/api_functions";
import { logger } from "../../utils/logger";

import {
  CREATE_COMPANY,
  UPDATE_COMPANY,
  GET_COMPANIES,
  GET_COMPANIES_WITH_PLATFORM_NAME,
  GET_COMPANY,
} from "../action.type";
import {
  SET_COMPANIES_REQUEST,
  SET_COMPANIES,
  SET_COMPANIES_FAILED,
  SET_COMPANY,
  SET_COMPANY_PAGINATION,
} from "../mutation.type";

export const company = {
  namespaced: true,
  state: {
    status: {},
    activeCompany: null,
    companies: [],
    pagination: {},
  },
  actions: {
    async [CREATE_COMPANY](_, company) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(company),
        };
        return await fetch(`${config.apiUrl}/companies`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [UPDATE_COMPANY](_, { company, compId }) {
      try {
        const request = {
          method: "PUT",
          headers: authHeader(),
          body: JSON.stringify(company),
        };
        return await fetch(`${config.apiUrl}/companies/${compId}`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [GET_COMPANIES]({ commit }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        commit(SET_COMPANIES_REQUEST);
        return await fetch(`${config.apiUrl}/companies`, request)
          .then(handleResponse)
          .then((res) => {
            commit(SET_COMPANIES, res.companies);
            return res;
          });
      } catch (error) {
        commit(SET_COMPANIES_FAILED, error);
        logger.error(error);
      }
    },

    async [GET_COMPANIES_WITH_PLATFORM_NAME]({ commit }, platform_name) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        commit(SET_COMPANIES_REQUEST);
        return await fetch(
          `${config.apiUrl}/companies/platform/${platform_name}`,
          request
        )
          .then(handleResponse)
          .then((res) => {
            commit(SET_COMPANIES, res);
            return res;
          });
      } catch (error) {
        commit(SET_COMPANIES_FAILED, error);
      }
    },

    async [GET_COMPANY]({ commit }, id) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        commit(SET_COMPANIES_REQUEST);

        return await fetch(`${config.apiUrl}/companies/${id}`, request)
          .then(handleResponse)
          .then((res) => {
            res.company.address = JSON.parse(res.company.address);
            commit(SET_COMPANY, res);
            return res;
          });
      } catch (error) {
        commit(SET_COMPANIES_FAILED, error);
        logger.error(error);
      }
    },
  },
  mutations: {
    [SET_COMPANIES_REQUEST](state) {
      state.status = { loading: true };
    },
    [SET_COMPANIES](state, companies) {
      state.status = { loading: false };
      state.companies = companies;
    },
    [SET_COMPANIES_FAILED](state, error) {
      state.status = { loading: false };
      state.all = { error };
    },
    [SET_COMPANY](state, company) {
      state.status = { loading: false };
      state.activeCompany = company;
    },
    [SET_COMPANY_PAGINATION](state, pagination) {
      state.pagination = pagination;
    },
  },
};
