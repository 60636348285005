<template>
  <v-container class="pa-5">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        v-model="formDataFields.department"
        :rules="[(v) => !!v || $t('create_contact.required_department')]"
        :label="$t('create_contact.department')"
        required
      ></v-text-field>
      <v-row>
        <v-col>
          <v-text-field
            v-model="formDataFields.first_name"
            :rules="[(v) => !!v || $t('create_contact.required_first_name')]"
            :label="$t('create_contact.first_name')"
            required
          ></v-text-field>
        </v-col>

        <v-col>
          <v-text-field
            v-model="formDataFields.last_name"
            :rules="[(v) => !!v || $t('create_contact.required_last_name')]"
            :label="$t('create_contact.last_name')"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-text-field
        v-model="formDataFields.mail"
        :rules="[(v) => !!v || $t('create_contact.required_email')]"
        :label="$t('create_contact.email')"
        required
      ></v-text-field>

      <v-text-field
        v-model="formDataFields.phone"
        :rules="[(v) => !!v || $t('create_contact.required_tel')]"
        :label="$t('create_contact.tel')"
        required
      ></v-text-field>
      <v-row justify="center">
        <v-btn
          :disabled="!valid && !adding"
          color="success"
          class="mr-4"
          @click="buttonClick"
        >
          {{ types[type].buttonText }}
        </v-btn>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { permission } from "../../utils/permissions";
import {
  CREATE_CONTACT,
  GET_COMPANY,
  UPDATE_CONTACT,
} from "../../store/action.type";
import { mapActions } from "vuex";
export default {
  props: {
    type: String,
    formData: Object,
    parentID: Number,
  },
  data: () => ({
    adding: false,
    valid: true,
    data_fields: {
      department: "",
      first_name: "",
      last_name: "",
      mail: "",
      phone: "",
    },
  }),

  computed: {
    formDataFields() {
      this.updateFormFields();
      return this.data_fields;
    },
    types() {
      return [
        {
          buttonText: this.$t("create_contact.create_contact"),
        },
        {
          buttonText: this.$t("create_contact.customize_contact"),
        },
      ];
    },
  },

  created() {
    if (this.type == 1) {
      this.data_fields = this.formData;
    }
  },

  methods: {
    ...mapActions("company", { getCompany: GET_COMPANY }),
    ...mapActions("contact", {
      createContact: CREATE_CONTACT,
      updateContact: UPDATE_CONTACT,
    }),
    updateFormFields() {
      if (this.type == 1) {
        this.data_fields = this.formData;
      }
    },

    buttonClick() {
      if (this.type == 0) {
        this.addContact();
      } else if (this.type == 1) {
        this.editContact();
      }
    },

    async editContact() {
      const { dispatch } = this.$store;
      if (this.$refs.form.validate()) {
        if (permission("isCommunityManager") || permission("isAdmin")) {
          this.adding = true;

          let contact = {
            department: this.data_fields.department,
            first_name: this.data_fields.first_name,
            last_name: this.data_fields.last_name,
            mail: this.data_fields.mail,
            phone: this.data_fields.phone,
            comp_id: this.parentID,
          };
          const id = this.data_fields.id;
          await this.updateContact({ contact, id }).then(
            dispatch("alerts/success", this.$t("create_contact.contact_saved"))
          );
          this.getCompany(this.$store.state.company.activeCompany.company.id);
          this.adding = false;
          this.$emit("close-dialog");
        } else {
          dispatch("alerts/warn", this.$t("create_contact.not_authorized_msg"));
        }
      } else {
        dispatch(
          "alerts/warn",
          this.$t("create_contact.not_all_fields_completed")
        );
      }
    },

    async addContact() {
      const { dispatch } = this.$store;
      if (this.$refs.form.validate()) {
        if (permission("isCommunityManager") || permission("isAdmin")) {
          this.adding = true;

          let contact = {
            department: this.data_fields.department,
            first_name: this.data_fields.first_name,
            last_name: this.data_fields.last_name,
            mail: this.data_fields.mail,
            phone: this.data_fields.phone,
            comp_id: this.parentID,
          };

          await this.createContact(contact).then(() => {
            dispatch(
              "alerts/success",
              this.$t("create_contact.contact_created")
            );
          });
          this.getCompany(contact.comp_id);
          this.adding = false;
          this.clear();
          this.$emit("close-dialog");
        } else {
          dispatch("alerts/warn", this.$t("create_contact.not_authorized_msg"));
        }
      } else {
        dispatch(
          "alerts/warn",
          this.$t("create_contact.not_all_fields_completed")
        );
      }
    },
    clear() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style scoped></style>
