<template>
  <v-container>
    <v-form ref="form" v-model="valid" class="pa-3">
      <v-row>
        <h3>{{ $t("create_department.add_department") }}</h3>
      </v-row>
      <v-row align="center" justify="center">
        <v-text-field
          v-model="department_name"
          :label="$t('create_department.department_name')"
          required
        ></v-text-field>
        <v-col style="margin-top: 0px; padding-top: 0px" align="end">
          <v-btn
            style="margin-top: 0px; padding-top: 0px"
            :disabled="!valid && !adding"
            color="success"
            class="mr-4"
            @click="createDep"
          >
            {{ $t("create_department.department_btn") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { CREATE_DEPARTMENT, GET_DEPARTMENTS } from "../../store/action.type";
export default {
  data: () => ({
    adding: false,
    valid: true,
    department_name: "",
  }),

  methods: {
    ...mapActions("interests", {
      getDepartments: GET_DEPARTMENTS,
      createDepartment: CREATE_DEPARTMENT,
    }),
    async createDep() {
      const { dispatch } = this.$store;
      if (this.department_name != "") {
        this.adding = true;
        let department = {
          name: this.department_name,
        };
        await this.createDepartment(department).then(() => {
          dispatch(
            "alerts/success",
            this.$t("create_department.department_added")
          );
          this.getDepartments();
          this.adding = false;
          this.clear();
          this.$emit("close-dialog");
        });
      } else {
        this.adding = false;
        dispatch(
          "alerts/warn",
          this.$t("create_department.department_name_missing")
        );
      }
    },
    clear() {
      this.$refs.form.reset();
    },
  },
};
</script>
