<template>
  <div v-if="employee.id">
    <!-- Edit Employee Dialog -->
    <v-dialog v-model="dialogEditEmployee" max-width="600px">
      <v-card>
        <CreateEmployee
          v-on:close-dialog="dialogEditEmployee = false"
          type="1"
          :parentID="employee.id"
          :formData="employee"
        ></CreateEmployee>
      </v-card>
    </v-dialog>

    <!--Header Section-->
    <v-container fluid>
      <v-row>
        <v-col>
          <h1>
            <v-btn class="mb-1" icon color="primary" @click="$router.go(-1)">
              <v-icon x-large dark>mdi-chevron-left-box</v-icon>
            </v-btn>
            {{ employee.first_name + " " + employee.last_name }}
          </h1>
        </v-col>
        <v-col justify="end" align="end">
          <h1>ID: {{ employee.id }}</h1>
        </v-col>
      </v-row>
    </v-container>

    <!-- New layout -->
    <v-container fluid class="pt-0">
      <v-row>
        <!-- Persoon -->
        <v-col xl="4" lg="5" md="6" sm="12">
          <v-container fluid class="pl-0 pr-0">
            <v-col class="pa-0">
              <v-card min-height="380">
                <v-row :style="cardHeaders" class="ma-0">
                  <v-row class="pa-5">
                    <v-col md="6">
                      <v-row>
                        <v-icon medium>mdi-account</v-icon>
                        <h3>
                          <strong>{{
                            `${employee.first_name} ${employee.last_name}`
                          }}</strong>
                        </h3>
                      </v-row>
                    </v-col>
                    <v-col align="end" class="pa-0">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            medium
                            @click="dialogEditEmployee = true"
                            class="pl-2"
                            >mdi-pencil-box</v-icon
                          >
                        </template>
                        <span>{{ $t("employee_page.employee_update") }}</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-row>
                <v-row class="mt-0">
                  <v-col class="pt-0">
                    <v-row class="pl-5 mt-0 pb-0">
                      <v-col class="pb-0">
                        <span>
                          <v-icon medium class="pr-0">mdi-phone</v-icon>
                          <p v-if="employee.phone" style="display: inline">
                            {{ employee.phone }}
                          </p>
                          <p v-else style="display: inline">
                            {{ $t("employee_page.unknown_phone_number") }}
                          </p>
                          <br />
                          <v-icon medium class="pr-0">mdi-account</v-icon>
                          <p style="display: inline">
                            {{ roleStrings[employee.role] }}
                          </p>
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import CreateEmployee from "../components/dialogs/CreateEmployee.vue";
import { GET_EMPLOYEE_BY_ID } from "../store/action.type";
export default {
  components: {
    CreateEmployee,
  },
  data: () => ({
    dialogEditEmployee: false,
    roleStrings: [
      "Admin",
      "Manager",
      "Community Manager",
      "Recruiter",
      "Recruiter Assistant",
      "Geen",
    ],
  }),

  async created() {
    await this.getEmployeeById(this.$route.params.id);
  },
  computed: {
    employee() {
      return this.$store.state.users.employee;
    },
    cardHeaders() {
      if (this.$vuetify.theme.dark) {
        return "background: #2a2a2a";
      } else {
        return "background: #b3b3b3";
      }
    },
  },
  methods: {
    ...mapActions("users", { getEmployeeById: GET_EMPLOYEE_BY_ID }),
  },
};
</script>
<style></style>
