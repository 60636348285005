import config from "../../../config";
import { authHeader } from "../../utils/auth-header";
import { handleResponse } from "../../utils/api_functions";
import { logger } from "../../utils/logger";

import {
  CREATE_CONTACT_MOMENT_STATUS,
  CREATE_LEAD_STATUS,
  CREATE_SUB_STATUS,
  CREATE_VACANCY_STATUS,
  GET_CONTACT_MOMENT_STATUSES,
  GET_LEAD_STATUSES,
  GET_VACANCY_STATUSES,
  UPDATE_CONTACT_MOMENT_STATUS,
  UPDATE_LEAD_STATUS,
  UPDATE_SUB_STATUS,
  UPDATE_VACANCY_STATUS,
} from "../action.type";
import {
  SET_CONTACT_MOMENT_STATUSES,
  SET_LEAD_STATUSES,
  SET_VACANCY_STATUSES,
} from "../mutation.type";

export const statuses = {
  namespaced: true,
  state: {
    lead_statuses: {},
    contact_moment_statuses: {},
    vacancy_statuses: [],
  },
  getters: {
    getleadStatuses: (state) => state.lead_statuses,
    getLeadStatusById: (state) => (id) => {
      return state.lead_statuses.find((leadStatus) => leadStatus.id === id);
    },
    vacancy_statuses_getter: (state) => state.vacancy_statuses,
  },
  actions: {
    async [CREATE_CONTACT_MOMENT_STATUS](_, contact_moment_status) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(contact_moment_status),
        };
        return await fetch(`${config.apiUrl}/contact-moment-status`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [UPDATE_CONTACT_MOMENT_STATUS](
      _,
      { contact_moment_status, contact_moment_status_id }
    ) {
      try {
        const request = {
          method: "PUT",
          headers: authHeader(),
          body: JSON.stringify(contact_moment_status),
        };
        return await fetch(
          `${config.apiUrl}/contact-moment-status/${contact_moment_status_id}`,
          request
        )
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [GET_CONTACT_MOMENT_STATUSES]({ commit }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/contact-moment-status`, request)
          .then(handleResponse)
          .then((res) => {
            commit(SET_CONTACT_MOMENT_STATUSES, res.contact_moment_statuses);
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },

    // --------------------------------------------------------------------------------

    async [CREATE_LEAD_STATUS](_, lead_status) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(lead_status),
        };
        return await fetch(`${config.apiUrl}/lead-status`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [UPDATE_LEAD_STATUS](_, { lead_status, statusId }) {
      try {
        const request = {
          method: "PUT",
          headers: authHeader(),
          body: JSON.stringify(lead_status),
        };
        return await fetch(`${config.apiUrl}/lead-status/${statusId}`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [GET_LEAD_STATUSES]({ commit }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return fetch(`${config.apiUrl}/lead-status`, request)
          .then(handleResponse)
          .then((res) => {
            commit(SET_LEAD_STATUSES, res.lead_statuses);
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [CREATE_SUB_STATUS](_, sub_status) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(sub_status),
        };
        return await fetch(`${config.apiUrl}/sub-status`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.log("error");
      }
    },
    async [UPDATE_SUB_STATUS](_, { sub_status, statusId }) {
      try {
        const request = {
          method: "PUT",
          headers: authHeader(),
          body: JSON.stringify(sub_status),
        };
        return await fetch(`${config.apiUrl}/sub-status/${statusId}`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.log("error");
      }
    },

    // --------------------------------------------------------------------------------

    async [CREATE_VACANCY_STATUS](_, vacancy_status) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(vacancy_status),
        };
        return await fetch(`${config.apiUrl}/vacancy-status`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [UPDATE_VACANCY_STATUS](_, { vacancy_status, statusId }) {
      try {
        const request = {
          method: "PUT",
          headers: authHeader(),
          body: JSON.stringify(vacancy_status),
        };
        return await fetch(
          `${config.apiUrl}/vacancy-status/${statusId}`,
          request
        )
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [GET_VACANCY_STATUSES]({ commit }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/vacancy-status`, request)
          .then(handleResponse)
          .then((res) => {
            commit(SET_VACANCY_STATUSES, res.vacancy_statuses);
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
  },
  mutations: {
    [SET_CONTACT_MOMENT_STATUSES](state, contact_moment_statuses) {
      state.contact_moment_statuses = contact_moment_statuses;
    },
    [SET_LEAD_STATUSES](state, lead_statuses) {
      state.lead_statuses = lead_statuses;
    },
    [SET_VACANCY_STATUSES](state, vacancy_statuses) {
      state.vacancy_statuses = vacancy_statuses;
    },
  },
};
