<template>
  <v-container fluid class="pa-5">
    <v-dialog v-model="dialogColumns" max-width="600px">
      <SelectColumns
        :headers="headers"
        type="VacancyShortList"
        v-on:headers-changed="setHeaders($event)"
        v-on:close-dialog="dialogColumns = false"
      />
    </v-dialog>
    <v-card class="pa-4" elevation="4">
      <v-row class="pb-4">
        <v-col md="8">
          <v-row class="pa-3">
            <h2>{{ $t("vacancy_lead_users.lead_users") }}</h2>
            <v-divider
              vertical
              inset
              style="padding-left: 10px; padding-left: 10px"
            ></v-divider>
            <v-text-field
              style="padding-top: 0px; padding-left: 10px"
              v-model="basicSearch"
              append-icon="mdi-magnify"
              :label="$t('vacancy_lead_users.search')"
              single-line
              hide-details
            ></v-text-field>
          </v-row>
        </v-col>
        <v-col align="end" md="4">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                class="mb-2"
                @click="dialogColumns = true"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon dark>mdi-bookmark</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("common_phrases.columns") }}</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-data-table
        v-if="loaded"
        :headers="headers"
        :search="basicSearch"
        :items="validItems"
        :items-per-page="5"
        class="elevation-1"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="columnSelectOpen(item)">
            mdi-open-in-new
          </v-icon>
          <v-icon v-if="item.blacklisted" color="red" small class="mr-2">
            mdi-close-thick
          </v-icon>
          <v-icon v-if="item.favorite" color="yellow" small class="mr-2">
            mdi-star
          </v-icon>
        </template>
        <template v-slot:[`item.delete`]="{ item }">
          <v-icon color="red" @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
        <template v-slot:[`item.car`]="{ item }">
          <v-checkbox :input-value="item.car" value disabled></v-checkbox>
        </template>
        <template v-slot:[`item.latest_status_name`]="{ item }">
          <v-chip label :color="item.latest_status_color"
            ><div
              :style="`color: ${calculateTextColor(item.latest_status_color)}`"
            >
              <strong>{{ item.latest_status_name }}</strong>
            </div></v-chip
          >
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import SelectColumns from "../dialogs/SelectColumns.vue";
import { UPDATE_EMPLOYEE } from "../../store/action.type";
import { mapActions, mapState } from "vuex";
import {
  GET_VACANCY_BY_ID,
  REMOVE_VACANCY_LEAD_USER,
} from "../../store/action.type";
export default {
  components: {
    SelectColumns,
  },
  props: {
    data: Array,
  },
  data: () => ({
    basicSearch: "",
    loaded: false,
    dialogColumns: false,
    selectedHeaders: [],
  }),
  computed: {
    headers() {
      let headers = [
        {
          id: 1,
          text: "",
          value: "actions",
          sortable: false,
          align: "start",
          width: "10%",
          hide: true,
        },
        {
          id: 2,
          text: "",
          value: "blacklisted",
          align: " d-none",
          hide: true,
        },
        {
          id: 3,
          text: "",
          value: "favorite",
          align: " d-none",
          hide: true,
        },
        {
          id: 4,
          text: "Name",
          value: "fullName",
          sortable: true,
          perma: true,
        },
        {
          id: 5,
          text: "Status",
          value: "latest_status_name",
          sortable: true,
          align: " d-none",
        },
        {
          id: 6,
          text: "Gender",
          value: "gender",
          sortable: true,
          perma: true,
        },
        {
          id: 7,
          text: "Car",
          value: "car",
          sortable: true,
          align: " d-none",
        },
        {
          id: 8,
          text: "Email",
          value: "email",
          sortable: true,
          align: " d-none",
        },
        {
          id: 9,
          text: "Platform",
          value: "platform",
          sortable: true,
          align: " d-none",
        },
        {
          id: 10,
          text: "Region",
          value: "region",
          sortable: true,
          align: " d-none",
        },
        {
          id: 11,
          text: "Phone",
          value: "tel_number",
          sortable: true,
          perma: true,
        },
        {
          id: 12,
          text: "",
          value: "delete",
          sortable: false,
          align: "right",
          perma: true,
          hide: true,
        },
      ];
      for (let i = 0; i < headers.length; i++) {
        if (this.selectedHeaders.some((elem) => headers[i].id === elem)) {
          headers[i].align = "";
        }
      }
      return headers;
    },
    validItems() {
      return this.data.filter((item) => !item["deleted"]);
    },
    ...mapState({
      currentUser: (state) => state.users.user,
    }),
  },

  created() {
    this.initialize();
  },
  methods: {
    ...mapActions("vacancy", {
      getVacancyById: GET_VACANCY_BY_ID,
      removeVacancyLeadUser: REMOVE_VACANCY_LEAD_USER,
    }),
    ...mapActions("users", {
      updateEmployee: UPDATE_EMPLOYEE,
    }),
    async initialize() {
      if (this.currentUser.vacancy_shortlist_columns != null) {
        this.selectedHeaders = JSON.parse(
          this.currentUser.vacancy_shortlist_columns
        );
      } else {
        let employee = this.currentUser;
        let empId = this.currentUser.id;
        employee.vacancy_shortlist_columns = JSON.stringify(
          this.selectedHeaders
        );
        await this.updateEmployee({ employee, empId });
      }
      this.loaded = true;
    },
    async deleteItem(item) {
      await this.removeVacancyLeadUser(item.vac_has_user_id);
      this.$set(item, "deleted", true);
    },
    columnSelectOpen(item) {
      let routeData = this.$router.resolve({
        path: `/profiel/${item.id}`,
      });
      window.open(routeData.href, "_blank");
    },
    setHeaders(e) {
      this.selectedHeaders = e;
    },
    calculateTextColor(bgColor) {
      let color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
      let r = parseInt(color.substring(0, 2), 16); // hexToR
      let g = parseInt(color.substring(2, 4), 16); // hexToG
      let b = parseInt(color.substring(4, 6), 16); // hexToB
      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#ffffff";
    },
  },
};
</script>
