<template>
  <div class="mr-2 ml-2 mt-0">
    <v-row>
      <v-btn icon v-on:click="cycle()">
        <v-icon color="red" v-if="numVal == -1">mdi-close-box</v-icon>
        <v-icon color="grey" v-if="numVal == 0">mdi-minus-box</v-icon>
        <v-icon color="green" v-if="numVal == 1">mdi-checkbox-marked</v-icon>
      </v-btn>
      <p class="mt-2">{{ label }}</p>
    </v-row>
  </div>
</template>
<script>
export default {
  props: ["value", "label"],

  data() {
    return {
      curValue: false,
      oldValue: null,
      indeterminate: false,
      numVal: 0,
    };
  },

  created() {
    this.setCheckBox(this.value);
  },

  watch: {
    curValue() {},
  },

  methods: {
    changed() {
      this.$emit("input", this.numVal);
      this.$emit("change");
    },
    cycle() {
      this.numVal++;
      if (this.numVal > 1) {
        this.numVal = -1;
      }
      this.setCheckBox(this.numVal);
    },
    setCheckBox(value) {
      if (value >= -1 && value <= 1) {
        switch (value) {
          case -1:
            this.indeterminate = false;
            this.curValue = true;
            break;
          case 0:
            this.indeterminate = true;
            break;
          case 1:
            this.indeterminate = false;
            this.curValue = false;
            break;
        }
        this.numVal = value;
      } else {
        this.numVal = 0;
        this.indeterminate = true;
      }
      this.changed();
    },
  },
};
</script>
