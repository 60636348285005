<template>
  <div>
    <v-dialog v-model="deleteUserDialog" max-width="477">
      <DeleteUser v-on:close-dialog="deleteUserDialog = false" :lead="lead" />
    </v-dialog>
    <v-dialog max-width="300" v-model="generateQrDialog" class="justify-center">
      <VirtualCard v-on:close-dialog="deleteUserDialog = false" :lead="lead" />
    </v-dialog>
    <v-row>
      <v-col>
        <h1>
          <v-btn class="mb-1" icon color="primary" @click="$router.go(-1)">
            <v-icon x-large dark>mdi-chevron-left-box</v-icon>
          </v-btn>
          {{ lead.first_name + " " + lead.last_name }}
          <v-chip label :color="latestStatus.color"
            ><strong>{{ latestStatus.name }}</strong></v-chip
          >
        </h1>
      </v-col>

      <v-col justify="end" align="end">
        <v-row justify="end" align="center">
          <v-col align="center" class="pt-6">
            <v-autocomplete
              v-model="lead.recruiter_id"
              :items="employees_getter"
              @change="assignRecruiter"
              item-text="name"
              item-value="id"
              label="Assign Recruiter"
              dense
              clearable
            ></v-autocomplete>
          </v-col>
          <v-btn @click="displayQRCode" icon>
            <v-icon>mdi-qrcode-scan</v-icon>
          </v-btn>

          <v-btn
            v-if="lead.blacklisted"
            @click="onBlacklistToggle"
            icon
            color="red"
          >
            <v-icon large>mdi-close-thick</v-icon>
          </v-btn>
          <v-btn v-else @click="onBlacklistToggle" icon>
            <v-icon large>mdi-close-outline</v-icon>
          </v-btn>
          <v-btn
            v-if="lead.favorite"
            @click="onFavoriteToggle"
            icon
            color="yellow"
          >
            <v-icon large>mdi-star</v-icon>
          </v-btn>
          <v-btn v-else @click="onFavoriteToggle" icon>
            <v-icon large>mdi-star-outline</v-icon>
          </v-btn>
          <v-btn v-if="lead.liked" @click="onLikedToggle" icon color="red">
            <v-icon large>mdi-cards-heart</v-icon>
          </v-btn>
          <v-btn v-else @click="onLikedToggle" icon>
            <v-icon large>mdi-cards-heart-outline</v-icon>
          </v-btn>
          <h1>ID: {{ lead.id }}</h1>
          <v-btn
            class="ml-2"
            depressed
            color="error"
            @click="openDeleteDialog()"
            >{{ $t("delete_user.delete") }}</v-btn
          >
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DeleteUser from "../dialogs/DeleteUser.vue";
import VirtualCard from "./VirtualCard.vue";
import { mapGetters, mapActions } from "vuex";
import { DELETE_LEAD_USER, UPDATE_LEAD_USER } from "../../../store/action.type";
export default {
  components: {
    DeleteUser,
    VirtualCard,
  },
  props: ["lead", "latestStatus"],
  data: () => ({
    deleteUserDialog: false,
    generateQrDialog: false,
  }),

  methods: {
    ...mapActions("lead_users", {
      deleteLeadUser: DELETE_LEAD_USER,
      updateLeadUser: UPDATE_LEAD_USER,
    }),

    displayQRCode() {
      this.generateQrDialog = true;
    },

    openDeleteDialog() {
      this.deleteUserDialog = true;
    },

    async assignRecruiter() {
      let lead_user = JSON.parse(JSON.stringify(this.lead)); // Make copy of object
      let user = {
        recruiter_id: lead_user.recruiter_id,
      };
      await this.updateLeadUser({
        lead_user: user,
        userId: lead_user.id,
      });
    },
    async onBlacklistToggle() {
      let lead_user = JSON.parse(JSON.stringify(this.lead)); // Make copy of object
      let blackListValue = !lead_user.blacklisted;
      let user = {
        blacklisted: blackListValue,
      };
      this.lead.blacklisted = !this.lead.blacklisted;
      await this.updateLeadUser({
        lead_user: user,
        userId: lead_user.id,
      });
    },

    async onLikedToggle() {
      let lead_user = JSON.parse(JSON.stringify(this.lead)); // Make copy of object
      let likedValue = !lead_user.liked;
      let user = {
        liked: likedValue,
      };
      this.lead.liked = !this.lead.liked;
      await this.updateLeadUser({
        lead_user: user,
        userId: lead_user.id,
      });
    },

    async onFavoriteToggle() {
      let lead_user = JSON.parse(JSON.stringify(this.lead)); // Make copy of object
      let favoriteValue = !lead_user.favorite;
      let user = {
        favorite: favoriteValue,
      };
      this.lead.favorite = !this.lead.favorite;
      await this.updateLeadUser({
        lead_user: user,
        userId: lead_user.id,
      });
    },
  },
  computed: { ...mapGetters("filters", ["employees_getter"]) },
};
</script>
