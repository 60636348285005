<template>
  <v-container fluid>
    <div v-if="!$auth.loading">
      <div v-if="!$auth.isAuthenticated">
        <Login />
      </div>
      <div v-if="$auth.isAuthenticated">
        <v-container fluid v-if="userHasBeenLoaded">
          <v-row>
            <WeekAnalytics />
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <BarChart />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <LineChart />
            </v-col>
            <v-col cols="12" md="6">
              <HotVacancies />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <PieChart />
            </v-col>
            <v-col cols="12" md="6">
              <BarChartYearOverview />
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </v-container>
</template>

<script>
// @ is an alias to /src
import Login from "@/components/Authentication/Login";
import NotificationPanel from "@/components/Notification/NotificationPanel.vue";
import LeadPipes from "@/components/Job/LeadPipes.vue";
import BarChart from "../components/main_dashboard/BarChart.vue";
import PieChart from "../components/main_dashboard/PieChart.vue";
import LineChart from "../components/main_dashboard/LineChart.vue";
import HotVacancies from "../components/main_dashboard/HotVacancies.vue";
import WeekAnalytics from "../components/main_dashboard/WeekAnalytics.vue";
import HotVacanciesVue from "../components/main_dashboard/HotVacancies.vue";
import BarChartYearOverview from "../components/main_dashboard/BarChartYearOverview.vue";
export default {
  name: "Home",
  components: {
    Login,
    // LeadPipes,
    // NotificationPanel,
    PieChart,
    LineChart,
    BarChart,
    WeekAnalytics,
    HotVacancies,
    BarChartYearOverview,
  },
  methods: {},
  computed: {
    user() {
      return this.$store.state.users.user;
    },
    userHasBeenLoaded() {
      return this.$store.state.users.status.loggedIn;
    },
  },
};
</script>

<style lang="scss" scoped></style>
