<template>
  <div style="margin-top: 5%">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <v-card>
            <v-container>
              <v-row align="center" justify="center">
                <v-col md="10" class="pb-0 mb-0">
                  <v-sheet :style="iconStyle" rounded class="pa-1">
                    <v-icon style="font-size: 400%; min-width: 100%">{{
                      icon
                    }}</v-icon>
                  </v-sheet>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col class="pt-0 pb-0">
                  <p :style="titleStyleText" class="mb-2">
                    <strong>{{ title }}</strong>
                  </p>
                  <br />
                  <p :style="iconStyleText" class="pb-0 mb-0">
                    {{ count }}{{ afterValueText }}
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </div>
      </template>
      <span>{{toolTipText}}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    title: String,
    icon: String,
    color: String,
    count: Number,
    afterValueText: String,
    toolTipText: String
  },
  data: () => ({}),
  created() {},
  computed: {
    iconStyle() {
      return {
        "z-index": 1,
        position: "relative",
        top: "-30px",
        background: this.color,
      };
    },
    iconStyleText() {
      return {
        "z-index": 1,
        position: "relative",
        top: "-30px",
        "min-width": "100%",
        "text-align": "center",
      };
    },
    titleStyleText() {
      return {
        "text-align": "center",
      };
    },
  },
  methods: {},
};
</script>