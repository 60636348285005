<template>
  <v-container>
    <div class="ma-0 pa-0" align="center">
      <v-btn dense text small @click="fetchMessages()">Fetch Older</v-btn>
    </div>
    <div>
      <div id="container" class="conversation-container">
        <div
          v-for="computedFeed in computedFeed"
          :key="computedFeed.id"
          class="bubble-container"
          :class="{
            myMessage: computedFeed.direction === 'received',
            myMessageFailed:
              computedFeed.status === 'failed' ||
              computedFeed.status === 'rejected',
          }"
        >
          <div v-if="darkTheme" class="bubble_dark">
            <div class="name_dark">{{ computedFeed.date }}</div>
            <div class="message_dark">{{ computedFeed.text }}</div>
            <div class="status_dark">{{ computedFeed.status }}</div>
          </div>
          <div v-else-if="!darkTheme" class="bubble">
            <div class="name">{{ computedFeed.date }}</div>
            <div class="message">{{ computedFeed.text }}</div>
            <div class="status">{{ computedFeed.status }}</div>
          </div>
        </div>
      </div>
      <v-divider color="#bdbdbd" class="mb-2"></v-divider>
      <div
        v-if="status !== 'active' || isExpired || isAwaitingResponse"
        :class="darkTheme ? 'divDark' : 'divLight'"
        class="d-flex align-center"
      >
        <v-row class="mx-2 d-flex align-center">
          <v-menu
            v-model="emojiDialog"
            top
            :offset-y="true"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }"
              ><v-btn icon dense v-bind="attrs" v-on="on">
                <v-icon dark>mdi-emoticon</v-icon>
              </v-btn>
            </template>
            <v-card><VEmojiPicker @select="selectEmoji" /></v-card
          ></v-menu>
          <v-text-field
            clear-icon="mdi-close-circle"
            filled
            hide-details
            single-line
            solo
            flat
            dense
            :background-color="darkTheme ? 'grey darken-3' : 'grey lighten-2'"
            append-outer-icon="mdi-send"
            color="#009EE3"
            v-model="messageText"
            class="my-3"
            placeholder="Enter your message"
            @click:append-outer="createNewConversation"
            @keyup.enter="createNewConversation"
          />
        </v-row>
      </div>

      <div
        v-if="status === 'active' && !isExpired && !isAwaitingResponse"
        :class="darkTheme ? 'divDark' : 'divLight'"
        class="d-flex align-center"
      >
        <v-row class="mx-2 d-flex align-center">
          <v-menu
            v-model="emojiDialog"
            top
            :offset-y="true"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }"
              ><v-btn icon dense v-bind="attrs" v-on="on">
                <v-icon dark>mdi-emoticon</v-icon>
              </v-btn>
            </template>
            <v-card><VEmojiPicker @select="selectEmoji" /></v-card
          ></v-menu>
          <v-text-field
            clear-icon="mdi-close-circle"
            clearable
            filled
            hide-details
            single-line
            solo
            flat
            dense
            :background-color="darkTheme ? 'grey darken-3' : 'grey lighten-2'"
            append-outer-icon="mdi-send"
            color="#009EE3"
            v-model="messageText"
            class="my-3"
            placeholder="Enter your message"
            @click:append-outer="onMessage"
            @keyup.enter="onMessage"
          />
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import {
  SEND_MESSAGE,
  GET_ALL_MESSAGES_FOR_USER,
  GET_CONVERSATION_BY_ID,
  CREATE_CONVERSATION,
} from "@/store/action.type";
import { VEmojiPicker } from "v-emoji-picker";
import { getTemplate } from "@/utils/whats_app_templates";
export default {
  props: {
    conversation_id: String,
    phone: String,
  },
  components: {
    VEmojiPicker,
  },
  data: () => ({
    feed: [],
    arr: [],
    status: "",
    params: [],
    languages: [
      {
        name: "English",
        value: "en",
      },
      {
        name: "Dutch",
        value: "nl",
      },
    ],
    temp_lang: "en",
    temp_name: "",
    offset: 0,
    emojiDialog: false,
    fetchingInProgress: false,

    messages: [],
    messageText: "",
  }),
  computed: {
    ...mapGetters("whats_app", ["getRefetch"]),
    ...mapGetters("notifications", ["getNotifCount"]),
    computedFeed: {
      get() {
        return this.feed;
      },
      set() {
        return null;
      },
    },
    darkTheme() {
      return this.$vuetify.theme.dark;
    },

    isExpired() {
      //* Checking if the 24 hour time window has passed
      if (this.feed.length > 0) {
        let last_message_date = new Date(
          this.feed[this.feed.length - 1].raw_date
        );
        let last_message_status = this.feed[this.feed.length - 1].status;
        let then = new Date(last_message_date);
        let now = new Date();
        let msBetweenDates = Math.abs(then.getTime() - now.getTime());
        // 👇️ convert ms to hours                  min  sec   ms
        let hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);

        if (hoursBetweenDates >= 24 || last_message_status == "failed") {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    isAwaitingResponse() {
      //* Checking if the chat is awaiting user response
      if (this.feed.length > 0) {
        let last_message_date = new Date(
          this.feed[this.feed.length - 1].raw_date
        );
        let last_message_type = this.feed[this.feed.length - 1].type;
        let last_message_direction = this.feed[this.feed.length - 1].direction;
        let then = new Date(last_message_date);
        let now = new Date();
        let msBetweenDates = Math.abs(then.getTime() - now.getTime());
        // 👇️ convert ms to hours                  min  sec   ms
        let hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);

        if (
          hoursBetweenDates < 24 &&
          last_message_type == "template" &&
          last_message_direction == "sent"
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },

  watch: {
    async getRefetch() {
      this.offset = 0;
      this.arr = [];
      await this.fetchMessages();
      this.scrollToBottom();
    },
    async getNotifCount() {
      this.offset = 0;
      this.arr = [];
      await this.fetchMessages();
      this.scrollToBottom();
    },
  },

  created() {
    this.initialize();
  },
  methods: {
    ...mapActions("whats_app", {
      sendMessage: SEND_MESSAGE,
      getAllMessagesForUser: GET_ALL_MESSAGES_FOR_USER,
      getConversationById: GET_CONVERSATION_BY_ID,
      createConversation: CREATE_CONVERSATION,
    }),

    async initialize() {
      await this.fetchMessages();
      await this.getConversation();
      //* Scrolls to Bottom of chat
      this.scrollToBottom();
    },

    async getConversation() {
      let params = {
        con_id: this.conversation_id,
      };
      await this.getConversationById({ params }).then((res) => {
        this.status = res.response.status;
      });
    },

    async onMessage() {
      if (this.messageText == "") {
        return;
      }
      let temp = [];

      let body = {
        message: this.messageText,
        conversation_id: this.conversation_id,
      };
      await this.sendMessage(body);
      this.offset += 1;

      let params = {
        con_id: this.conversation_id,
        offset: 0,
      };
      await this.getAllMessagesForUser({ params }).then((res) => {
        temp = res.response.items;
      });
      let arr = [];
      //* Breaking down the JSON to get desired data
      for (let i = 0; i < temp.length; i++) {
        let tempDate = new Date(temp[i].createdDatetime);
        let time = tempDate.toLocaleTimeString();
        let date = tempDate.toLocaleDateString();
        let text = "";
        let type = "text";
        if (temp[i].content.hsm) {
          type = "template";
          let params = [];
          if (temp[i].content.hsm.params) {
            params = temp[i].content.hsm.params;
          }
          text = getTemplate(temp[i].content.hsm.templateName, params);
        } else {
          text = temp[i].content.text;
        }
        let obj = {
          id: temp[i].id,
          direction: temp[i].direction,
          text: text,
          date: date + "   " + time.substring(0, 5),
          raw_date: temp[i].createdDatetime,
          type: type,
          status: temp[i].status,
        };
        arr.push(obj);
      }
      this.feed = arr.reverse();
      this.messageText = "";
    },

    async fetchMessages() {
      if (this.fetchingInProgress) return;
      this.fetchingInProgress = true;
      let temp = [];
      let params = {
        con_id: this.conversation_id,
        offset: this.offset,
      };
      await this.getAllMessagesForUser({ params }).then((res) => {
        temp = res.response.items;
      });

      //* Breaking down the JSON to get desired data
      for (let i = 0; i < temp.length; i++) {
        let tempDate = new Date(temp[i].createdDatetime);
        let time = tempDate.toLocaleTimeString();
        let date = tempDate.toLocaleDateString();
        let text = "";
        let type = "text";
        if (temp[i].content.hsm) {
          type = "template";
          let params = [];
          if (temp[i].content.hsm.params) {
            params = temp[i].content.hsm.params;
          }
          text = getTemplate(
            temp[i].content.hsm.templateName,
            params,
            temp[i].content.hsm.language.code
          );
          if (text == "") {
            text = "Template not found, please contact Floris";
          }
        } else {
          text = temp[i].content.text;
        }
        let obj = {
          id: temp[i].id,
          direction: temp[i].direction,
          text: text,
          date: date + "   " + time.substring(0, 5),
          raw_date: temp[i].createdDatetime,
          type: type,
          status: temp[i].status,
        };
        this.arr.push(obj);
      }
      let newArr = [...this.arr];
      this.feed = newArr.reverse();
      //* Updating the offset
      this.offset += 20;
      this.fetchingInProgress = false;
    },

    async createNewConversation() {
      if (this.messageText == "") {
        return;
      }
      let params = [];
      let obj = {
        default: this.messageText,
      };

      params.push(obj);
      let data = {
        message: {
          namespace: "6bf1cd4f_1084_4310_b5de_3c6533b9c158",
          templateName: "job_follow_up_2",
          language: {
            policy: "deterministic",
            code: "en",
          },
          params: params,
        },
        number: this.phone,
      };
      await this.createConversation(data);

      await this.getConversation();
      this.offset = 0;
      this.arr = [];
      await this.fetchMessages();
      this.scrollToBottom();
      this.messageText = "";
    },

    scrollToBottom() {
      var container = this.$el.querySelector("#container");
      container.scrollTop = container.scrollHeight;
    },
    selectEmoji(emoji) {
      this.messageText += emoji.data;
    },
  },
};
</script>

<style scoped>
.conversation-container {
  margin: 0 auto;
  height: 400px;
  padding: 0 05px;
  border: 0px !important;

  overflow: scroll;
}

.bubble-container {
  text-align: left;
}

.bubble {
  background-color: #abf1ea;
  border: 2px solid #87e0d7;
  border-radius: 10px;
  padding: 05px;
  margin: 05px 0;
  width: 230px;
  float: right;
  border-radius: 13px;
  border-top-right-radius: 0px;
}

.bubble_dark {
  background-color: #006466;
  border: 2px solid #006466;
  border-radius: 10px;
  padding: 05px;
  margin: 05px 0;
  width: 230px;
  float: right;
  border-radius: 13px;
  border-top-right-radius: 0px;
}

.myMessage .bubble_dark {
  border: 2px solid #464646;
  background-color: #464646;
  float: left;
  border-top-right-radius: 13px;
  border-top-left-radius: 0px;
}

.myMessage .bubble {
  border: 2px solid #f1f1f1;
  background-color: #fdfbfa;
  float: left;
  border-top-right-radius: 13px;
  border-top-left-radius: 0px;
}

.myMessageFailed .bubble {
  border: 2px solid #ff2e2e;
  background-color: #ff8a8a;
}

.myMessageFailed .bubble_dark {
  border: 2px solid #7c162e;
  background-color: #7c162e;
}

.status {
  float: right;
  font-size: 11px;
  color: rgb(0, 0, 0);
}

.name {
  padding-right: 8px;
  font-size: 11px;
}

.status_dark {
  float: right;
  font-size: 11px;
}

.name_dark {
  padding-right: 8px;
  font-size: 11px;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
