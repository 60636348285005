<template>
  <v-card :loading="!loaded">
    <v-container class="pa-5" v-if="loaded">
      <v-form ref="form">
        <v-alert v-model="showAlert" type="warning">
          {{ warningText }}
        </v-alert>
        <v-card-title>
          <span class="text-h5">{{ $t(place_lead.place_lead) }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-select
                  :items="employees"
                  v-model="dataFields.selectedManagerId"
                  item-text="full_name"
                  item-value="id"
                  :label="$t('place_lead.placed_by')"
                  required
                ></v-select>
              </v-col>
              <v-col>
                <DatePicker v-model="dataFields.time" />
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col class="pt-0">
                <v-select
                  :items="vacancies"
                  v-model="dataFields.exitVacacny"
                  item-text="text"
                  item-value="id"
                  :label="$t('place_lead.exit_vacancy')"
                  required
                ></v-select>
                <v-textarea
                  outlined
                  name="input-7-1"
                  :label="$t('place_lead.contact_notes')"
                  auto-grow
                  v-model="data_fields.contactMomentText"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="save()" :disabled="saving">
            {{ $t(place_lead.save) }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import {
  ADD_STATUS_TO_LEAD_USER,
  CREATE_CONTACT_MOMENT,
  GET_ALL_LEADS_WITH_FLOW,
  UPDATE_FLOW,
} from "../../store/action.type";
import DatePicker from "./DatePicker";
export default {
  components: { DatePicker },
  props: {
    leadID: Number,
    leadUserID: Number,
    cmID: Number,
  },
  data: () => ({
    showAlert: false,
    warningText: "",
    saving: false,
    data_fields: {
      time: null,
      selectedManagerId: null,
      contactMomentText: null,
      exitVacacny: null,
    },
  }),
  computed: {
    loaded() {
      if (this.employees !== null && this.vacancies !== null) {
        return true;
      } else {
        return false;
      }
    },

    ...mapState({
      employees: (state) =>
        state.users.all.items.employees
          .filter((e) => e.role !== null) //Removed this as roles are a bit odd at the momment
          .sort((a, b) => {
            if (a.first_name < b.first_name) {
              return -1;
            }
            if (a.first_name > b.first_name) {
              return 1;
            }
            return 0;
          })
          .map((e) => {
            return {
              id: e.id,
              full_name: e.first_name + " " + e.last_name,
            };
          }),

      vacancies: (state) =>
        state.vacancy.vacancies.vacancies
          .filter((e) => e.name !== null)
          .sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
          .map((e) => {
            return {
              id: e.id,
              text: e.name + " [" + e.id + "]",
            };
          }),
    }),
    dataFields() {
      this.updateDataFields();
      return this.data_fields;
    },
  },
  created() {},
  methods: {
    ...mapActions("contact_moment", {
      createContactMoment: CREATE_CONTACT_MOMENT,
    }),
    ...mapActions("flow", { updateFlow: UPDATE_FLOW }),
    ...mapActions("lead_users", {
      addStatusToLeadUser: ADD_STATUS_TO_LEAD_USER,
    }),
    ...mapActions("leads", { getAllLeadsWithFlow: GET_ALL_LEADS_WITH_FLOW }),
    async save() {
      this.saving = true;
      const leadId = this.leadID;
      const { dispatch } = this.$store;
      var flow = {
        lead_id: leadId,
      };
      if (
        this.data_fields.time !== null &&
        this.data_fields.selectedManagerId !== null &&
        this.data_fields.contactMomentText !== null &&
        this.data_fields.exitVacacny !== null
      ) {
        flow.placed_cm = this.data_fields.selectedManagerId;
        flow.placed_date = this.data_fields.time;
        await this.updateFlow({ flow, leadId }).then(() => {
          dispatch("alerts/success", this.$t("place_lead.posted"));
        });
        await this.addStatusToUser(this.data_fields.contactMomentText, 2, 5);
        const exitVacacny = this.data_fields.exitVacacny;
        var lead = { exit_vacancy_id: exitVacacny };
        await dispatch("leads/updateLead", { lead, leadId });
        await this.getAllLeadsWithFlow();
        this.resetDialog();
        this.$emit("close-dialog");
      } else {
        this.warningText = this.$t("place_lead.warning_text");
        this.showAlert = true;
        this.saving = false;
      }
    },

    updateDataFields() {
      if (this.cmID != null) {
        this.data_fields.selectedManagerId = this.cmID;
      } else {
        this.data_fields.selectedManagerId = this.$store.state.users.user.id;
      }
    },

    resetDialog() {
      this.showAlert = false;
      this.data_fields.time = null;
      this.data_fields.selectedManagerId = null;
      this.saving = false;
      this.data_fields.contactMomentText = null;
      this.data_fields.exitVacacny = null;
      this.warningText = "";
    },

    async addStatusToUser(cm_notes, cm_type, leadStatusId) {
      const leadUserId = this.leadUserID;
      let newContactMoment = {
        lead_user_id: leadUserId,
        type: cm_type,
        comunicator_id: this.$store.state.users.user.id,
        user_status_id: null,
        notes: cm_notes,
      };
      if (leadStatusId != null) {
        let leadUserStatus = {
          lead_user_id: leadUserId,
          lead_status_id: leadStatusId,
        };
        await this.addStatusToLeadUser(leadUserStatus).then((res) => {
          newContactMoment.user_status_id = res.newLeadUserStatus.id;
        });
      }
      await this.createContactMoment(newContactMoment);

      return;
    },
  },
};
</script>

<style scoped></style>
