<template>
  <v-card elevation="1">
    <apexchart
      v-if="isLoaded"
      width="100%"
      height="400"
      type="bar"
      :options="options"
      :series="series"
    ></apexchart>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import { GET_CONTACT_MOMENTS_PER_PERIOD } from "../../store/action.type";
import { getCurrentPeriodDates } from "../../helpers/statisticsDataGetter";
export default {
  data: () => ({
    isLoaded: false,
    contact_moments: [],
    options: {
      chart: {
        id: "basic-bar",
        stacked: true,
        stackType: "80%",

        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: true,
            customIcons: [],
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          borderRadius: 0,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "15px",
                fontWeight: 700,
              },
            },
          },
        },
      },

      xaxis: {
        categories: [],
      },
    },
    colors: ["#008FFB", "#00E396", "#FEB019"],
    series: [],
  }),
  created() {
    this.init();
  },
  methods: {
    ...mapActions("contact_moments", {
      getContactMomentsPeriod: GET_CONTACT_MOMENTS_PER_PERIOD,
    }),

    async init() {
      const params = {
        contactStartDate: getCurrentPeriodDates().contactStartDate,
        contactEndDate: getCurrentPeriodDates().contactEndDate,
      };
      await this.getContactMomentsPeriod({ params }).then((res) => {
        let recruiters = res.map((recruiter) => {
          return {
            name: recruiter.name,
            contact: recruiter.contact,
            totalIntakes: recruiter.totalIntakes,
            completedIntakes: recruiter.completedIntakes,
          };
        });

        this.series = [
          {
            name: "Spoke with leads",
            data: recruiters.map((recruiter) => recruiter.contact),
          },
          {
            name: "Intakes",
            data: recruiters.map((recruiter) => recruiter.totalIntakes),
          },
          {
            name: "Completed Intakes",
            data: recruiters.map((recruiter) => recruiter.completedIntakes),
          },
        ];

        recruiters.forEach((recruiter) => {
          this.options.xaxis.categories.push(recruiter.name);
        });

        this.isLoaded = true;
      });
    },
  },
};
</script>
