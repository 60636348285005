import config from "../../../config";
import { authHeader } from "../../utils/auth-header";
import { handleResponse } from "../../utils/api_functions";
import { logger } from "../../utils/logger";

import {
  GET_GLOBAL_ANALYTICS,
  GET_RECRUITER_ANALYTICS,
  GET_RECRUITMENT_ASSISTANT_ANALYTICS,
} from "../action.type";

export const dashboard = {
  namespaced: true,
  state: {},
  actions: {
    async [GET_GLOBAL_ANALYTICS](_, { startDate, endDate }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(
          `${config.apiUrl}/dashboard/${startDate}/${endDate}`,
          request
        )
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.log(error);
      }
    },
    async [GET_RECRUITER_ANALYTICS](_, { recId, startDate, endDate }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return fetch(
          `${config.apiUrl}/dashboard/recruiter/${recId}/${startDate}/${endDate}`,
          request
        )
          .then(handleResponse)
          .then((dashboard) => {
            return dashboard;
          });
      } catch (error) {
        logger.log(error);
      }
    },
    async [GET_RECRUITMENT_ASSISTANT_ANALYTICS](
      _,
      { raId, startDate, endDate }
    ) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return fetch(
          `${config.apiUrl}/dashboard/ra/${raId}/${startDate}/${endDate}`,
          request
        )
          .then(handleResponse)
          .then((dashboard) => {
            return dashboard;
          });
      } catch (error) {
        logger.log(error);
      }
    },
  },
};
