<template>
  <v-container class="pa-5">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        type="string"
        v-model="data_fields.name"
        :rules="[(v) => !!v || $t('create_vacancy.necessary_title')]"
        :label="$t('create_vacancy.vacancy_title')"
        required
      ></v-text-field>

      <v-row>
        <v-col>
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedStartDate"
                :label="$t('create_vacancy.start_date')"
                :hint="$t('create_vacancy.hint_start_date')"
                persistent-hint
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                :rules="[
                  (v) => !!v || $t('create_vacancy.necessary_start_date'),
                ]"
                readonly
                required
              ></v-text-field>
            </template>
            <v-date-picker
              locale="nl-NL"
              v-model="data_fields.start_date"
              no-title
              @input="menu1 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col>
          <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedVacancyDate"
                :label="$t('create_vacancy.end_date')"
                :hint="$t('create_vacancy.hint_end_date')"
                persistent-hint
                readonly
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              locale="nl-NL"
              v-model="data_fields.vacancy_close_date"
              no-title
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col>
          <v-autocomplete
            placeholder="1"
            min="0"
            :items="companies_list"
            item-text="name"
            item-value="id"
            v-model="data_fields.company_id"
            @change="setContacts"
            :label="$t('create_vacancy.company_name')"
            :rules="[(v) => !!v || $t('create_vacancy.necessary_company_name')]"
            required
          ></v-autocomplete>
        </v-col>
      </v-row>

      <div v-if="type === '0'">
        <v-switch
          v-model="use_existing_contact"
          :label="$t('create_vacancy.use_existing_contact')"
        ></v-switch>
      </div>
      <v-autocomplete
        v-if="use_existing_contact || type === '1'"
        :items="contacts_list"
        :item-text="fullName"
        item-value="id"
        v-model="data_fields.cp"
        :label="$t('create_vacancy.contact_person')"
      ></v-autocomplete>

      <v-divider v-if="!use_existing_contact"></v-divider>
      <div v-if="type === '0' && !use_existing_contact">
        <h2 class="mt-2">{{ $t("create_vacancy.new_contact_person") }}</h2>
        <v-text-field
          :rules="[(v) => !!v || $t('create_vacancy.necessary_department')]"
          v-model="contact_fields.department"
          :label="$t('create_vacancy.department')"
          required
        ></v-text-field>
        <v-row>
          <v-col>
            <v-text-field
              :rules="[(v) => !!v || $t('create_vacancy.necessary_first_name')]"
              v-model="contact_fields.first_name"
              :label="$t('create_vacancy.first_name')"
              required
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              :rules="[(v) => !!v || $t('create_vacancy.necessary_last_name')]"
              v-model="contact_fields.last_name"
              :label="$t('create_vacancy.last_name')"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-text-field
          :rules="[
            (v) => !!v || $t('create_vacancy.necessary_email'),
            rules.email,
          ]"
          v-model="contact_fields.mail"
          :label="$t('create_vacancy.email')"
          required
        ></v-text-field>

        <v-text-field
          :rules="[
            (v) => !!v || $t('create_vacancy.necessary_phone_number'),
            rules.phone,
          ]"
          v-model="contact_fields.phone"
          :label="$t('create_vacancy.phone_number')"
          required
        ></v-text-field>
      </div>
      <v-divider v-if="!use_existing_contact"></v-divider>

      <v-row>
        <v-col>
          <v-select
            :items="emp_type"
            :rules="[(v) => !!v || $t('create_vacancy.necessary_job_scope')]"
            v-model="data_fields.employment_type"
            :label="$t('create_vacancy.job_scope')"
            required
          ></v-select>
        </v-col>
        <v-col>
          <v-text-field
            :rules="[
              (v) => !!v || $t('create_vacancy.necessary_contract_duration'),
            ]"
            v-model="data_fields.employment_length"
            :label="$t('create_vacancy.contract_duration')"
            required
          ></v-text-field>
        </v-col>
      </v-row>

      <v-autocomplete
        :items="getManagers"
        :item-text="fullName"
        item-value="id"
        :rules="[
          (v) => !!v || $t('create_vacancy.necessary_community_manager'),
        ]"
        v-model="data_fields.cm_id"
        :label="$t('create_vacancy.community_manager')"
        required
      ></v-autocomplete>

      <v-autocomplete
        :items="getRecruiters"
        :item-text="fullName"
        item-value="id"
        v-model="data_fields.rec_id"
        label="Recruiter"
      ></v-autocomplete>

      <v-row class="mt-0">
        <v-col>
          <v-text-field
            type="number"
            placeholder="1"
            min="0"
            step="1"
            :rules="[
              (v) => !!v || $t('create_vacancy.necessary_needed_people'),
            ]"
            v-model="data_fields.people_needed"
            :label="$t('create_vacancy.needed_people')"
            required
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            type="number"
            placeholder="100.00"
            min="0"
            v-model="data_fields.sal_indication"
            :label="$t('create_vacancy.salary_indication')"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="data_fields.tariff"
            :label="$t('create_vacancy.rate')"
            type="number"
            placeholder="0.00"
            step="0.01"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-select
        :items="vacancy_statuses"
        item-text="name"
        item-value="id"
        :rules="[(v) => !!v || $t('create_vacancy.necessary_status')]"
        v-model="data_fields.status"
        :label="$t('create_vacancy.status')"
        required
      ></v-select>

      <v-switch
        v-model="use_existing_location"
        v-if="type === '0'"
        :label="$t('create_vacancy.use_existing_number')"
      ></v-switch>
      <div v-if="!use_existing_location">
        <v-text-field
          :rules="[(v) => !!v || $t('create_vacancy.necessary_street_name')]"
          v-model="data_fields.street"
          :label="$t('create_vacancy.street_name')"
          required
        ></v-text-field>
        <v-row>
          <v-col>
            <v-text-field
              :rules="[
                (v) => !!v || $t('create_vacancy.necessary_postal_code'),
              ]"
              v-model="data_fields.postalcode"
              :label="$t('create_vacancy.postal_code')"
              required
            ></v-text-field>
          </v-col>

          <v-col>
            <v-text-field
              v-model="data_fields.houseno"
              :rules="[
                (v) => !!v || $t('create_vacancy.necessary_house_number'),
              ]"
              :label="$t('create_vacancy.house_number')"
              required
            ></v-text-field>
          </v-col>

          <v-col>
            <v-text-field
              v-model="data_fields.addition"
              :label="$t('create_vacancy.addition')"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-text-field
          :rules="[(v) => !!v || $t('create_vacancy.necessary_place')]"
          v-model="data_fields.region"
          :label="$t('create_vacancy.place')"
          required
        ></v-text-field>
      </div>

      <v-textarea
        :rules="[(v) => !!v || $t('create_vacancy.necessary_description')]"
        v-model="data_fields.desc"
        :label="$t('create_vacancy.description')"
        auto-grow
        outlined
        required
      ></v-textarea>
      <v-divider />
      <h2 class="mt-2">{{ $t("create_vacancy.track") }}</h2>
      <v-autocomplete
        :items="roles"
        item-text="name"
        item-value="id"
        :rules="[(v) => !!v || $t('create_vacancy.necessary_role')]"
        v-model="data_fields.role"
        v-if="getLoadingStates.roles"
        :label="$t('create_vacancy.role')"
        required
      ></v-autocomplete>
      <v-autocomplete
        item-value="id"
        :items="departments"
        item-text="name"
        :rules="[(v) => !!v || $t('create_vacancy.necessary_department')]"
        v-model="data_fields.department"
        v-if="getLoadingStates.departments"
        :label="$t('create_vacancy.department')"
        required
      ></v-autocomplete>
      <v-autocomplete
        :items="industries"
        item-value="id"
        item-text="name"
        :rules="[(v) => !!v || $t('create_vacancy.necessary_industry')]"
        v-model="data_fields.industry"
        v-if="getLoadingStates.industries"
        :label="$t('create_vacancy.industry')"
        required
      ></v-autocomplete>

      <v-divider />
      <h2 class="mt-2">{{ $t("create_vacancy.optional") }}</h2>

      <v-text-field
        v-model="data_fields.vacancy_link"
        :label="$t('create_vacancy.vacancy_link')"
      ></v-text-field>

      <v-row>
        <v-col>
          <v-text-field
            v-model="data_fields.min_days"
            :label="$t('create_vacancy.minimum')"
            placeholder="32-40 uur"
          ></v-text-field>
        </v-col>

        <v-col>
          <v-text-field v-model="data_fields.cao" label="CAO"></v-text-field>
        </v-col>
      </v-row>

      <v-text-field
        v-model="data_fields.dresscode"
        :label="$t('create_vacancy.dresscode')"
      ></v-text-field>

      <v-row
        ><v-col>
          <v-text-field
            v-model="data_fields.initial_scale"
            :label="$t('create_vacancy.initial_scale')"
          ></v-text-field>
        </v-col>
        <v-col
          ><v-text-field
            v-model="data_fields.surcharches"
            :label="$t('create_vacancy.surcharges')"
          ></v-text-field> </v-col
      ></v-row>

      <v-text-field
        v-model="data_fields.extra_remarks"
        :label="$t('create_vacancy.extra_comments')"
      ></v-text-field>

      <v-text-field
        class="mb-6"
        v-model="data_fields.extra_requirements"
        :label="$t('create_vacancy.extra_requirements')"
      ></v-text-field>

      <v-textarea
        outlined
        v-model="data_fields.other_info"
        :label="$t('create_vacancy.other_info')"
        auto-grow
      ></v-textarea>

      <v-row justify="center">
        <v-btn
          :disabled="!valid && !adding"
          color="success"
          class="mr-4"
          @click="buttonClick"
        >
          {{ types[type].buttonText }}
        </v-btn>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { calculateLocation } from "../../helpers/google";
import { permission } from "../../utils/permissions";
import {
  CREATE_CONTACT,
  CREATE_JOB,
  CREATE_VACANCY,
  GET_COMPANIES,
  GET_CONTACTS,
  GET_DEPARTMENTS,
  GET_INDUSTRIES,
  GET_POSITIONS,
  GET_ROLES,
  GET_USERS,
  GET_VACANCIES,
  GET_VACANCIES_BY_COMPANY_ID,
  GET_VACANCY_BY_ID,
  GET_VACANCY_STATUSES,
  UPDATE_VACANCY,
} from "../../store/action.type";
export default {
  props: {
    type: String,
    formData: Object,
  },
  data: () => ({
    menu1: false,
    menu2: false,
    adding: false,
    valid: true,
    use_existing_contact: false,
    use_existing_location: false,
    companies_list: [],
    positions_list: [],
    users_list: [],
    contacts_list: [],
    emp_type: ["Fulltime", "Parttime", "Fulltime/Parttime", "Bijbaan"],
    vacancy_statuses: [],
    contact_fields: {
      first_name: "",
      last_name: "",
      department: "",
      mail: "",
      phone: "",
    },
    data_fields: {
      name: "",
      position_id: null,
      company_id: "",
      start_date: "",
      vacancy_close_date: null,
      desc: "",
      people_needed: "",
      sal_indication: null,
      tariff: null,
      employment_type: "",
      employment_length: "",

      //Job
      job_id: null,
      role: null,
      department: null,
      industry: null,

      // Terms
      min_days: "",
      extra_remarks: "",
      dresscode: "",
      extra_requirements: "",
      cao: "",
      initial_scale: "",
      surcharches: "",

      other_info: "",
      cm_id: "",
      rec_id: null,
      street: "",
      postalcode: "",
      houseno: "",
      addition: "",
      region: "",

      status: "",
      vacancy_link: "",
      cp: "",
    },
    rules: {
      email: (value) => {
        if (value != "" && value != undefined && value != null) {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Ongeldig e-mail";
        } else return true;
      },
      phone: (value) => {
        if (value != "" && value != undefined && value != null) {
          const pattern = /^\+(?:[0-9]●?){6,14}[0-9]$/;
          return pattern.test(value) || "Moet beginnen met + en land code";
        } else return true;
      },
    },
  }),
  computed: {
    ...mapGetters("interests", [
      "getLoadingStates",
      "departments",
      "roles",
      "industries",
    ]),
    types() {
      return [
        {
          buttonText: this.$t("create_vacancy.create_vacancy"),
        },
        {
          buttonText: this.$t("create_vacancy.edit_vacancy"),
        },
        {
          buttonText: this.$t("create_vacancy.copy_vacancy"),
        },
      ];
    },
    computedStartDate: {
      get() {
        return this.formatDate(this.data_fields.start_date);
      },
      set() {
        return null;
      },
    },
    computedVacancyDate: {
      get() {
        return this.formatDate(this.data_fields.vacancy_close_date);
      },
      set() {
        return null;
      },
    },
    activeCompany() {
      return this.$store.state.company.activeCompany;
    },
    getManagers() {
      let managers = [];
      for (let i = 0; i < this.users_list.length; i++) {
        if (this.users_list[i].role <= 2) {
          managers.push(this.users_list[i]);
        }
      }
      return managers;
    },
    getRecruiters() {
      return [...this.users_list];
    },
    computedLanguages: {
      get() {
        return this.chosenLanguages;
      },
      set(updatedChosenLanguages) {
        this.updateLanguages(updatedChosenLanguages);
        return this.chosenLanguages;
      },
    },
  },

  methods: {
    ...mapActions("company", { getCompanies: GET_COMPANIES }),
    ...mapActions("contact", {
      getContact: GET_CONTACTS,
      createContact: CREATE_CONTACT,
    }),
    ...mapActions("interests", {
      getDepartments: GET_DEPARTMENTS,
      getRoles: GET_ROLES,
      getIndustries: GET_INDUSTRIES,
      createJob: CREATE_JOB,
    }),
    ...mapActions("positions", { getPositions: GET_POSITIONS }),
    ...mapActions("statuses", {
      getVacancyStatuses: GET_VACANCY_STATUSES,
    }),
    ...mapActions("users", { getUsers: GET_USERS }),
    ...mapActions("vacancy", {
      getVacancies: GET_VACANCIES,
      createVacancy: CREATE_VACANCY,
      getVacancyById: GET_VACANCY_BY_ID,
      getVacanciesByCompanyId: GET_VACANCIES_BY_COMPANY_ID,
      updateVacancy: UPDATE_VACANCY,
    }),
    buttonClick() {
      if (this.type === "0" || this.type === "2") {
        if (this.use_existing_location) {
          this.setExistingLocation();
        }
        this.addVacancy();
      } else if (this.type === "1") {
        this.editVacancy();
      }
    },
    async initialize() {
      const { dispatch } = this.$store;
      const compareNames = (a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      };
      await this.getCompanies().then((res) => {
        this.companies_list = res.companies.sort(compareNames);
      });
      await this.getPositions().then((res) => {
        this.positions_list = res.positions.sort(compareNames);
      });
      await this.getUsers().then((res) => {
        this.users_list = res.employees.sort((a, b) => {
          if (a.first_name < b.first_name) {
            return -1;
          }
          if (a.first_name > b.first_name) {
            return 1;
          }
          return 0;
        });
      });
      await this.getVacancyStatuses().then((res) => {
        this.vacancy_statuses = res.vacancy_statuses.sort(compareNames);
      });
      await this.getDepartments();
      await this.getRoles();
      await this.getIndustries();
    },
    async setContacts() {
      let id = this.data_fields.company_id;
      if (id === "") return;

      await this.getContact(id).then((contacts) => {
        this.contacts_list = contacts.contact_persons.sort((a, b) => {
          if (a.first_name < b.first_name) {
            return -1;
          }
          if (a.first_name > b.first_name) {
            return 1;
          }
          return 0;
        });
      });
    },
    fullName(item) {
      return item.first_name + " " + item.last_name;
    },
    setExistingLocation() {
      if (this.companies_list != null) {
        for (let i = 0; i < this.companies_list.length; i++) {
          if (this.companies_list[i].id == this.data_fields.company_id) {
            const address = JSON.parse(this.companies_list[i].address);
            this.data_fields.street = address.street;
            this.data_fields.postalcode = address.postalcode;
            this.data_fields.houseno = address.houseno;
            this.data_fields.region = address.region;
          }
        }
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    async editVacancy() {
      const { dispatch } = this.$store;
      if (this.$refs.form.validate()) {
        if (permission("canCreateVacancy") || permission("isAdmin")) {
          this.adding = true;

          let tariff = null;
          let sal_indication = null;
          if (this.data_fields.tariff !== "") {
            tariff = this.data_fields.tariff;
          }
          if (this.data_fields.sal_indication !== "") {
            sal_indication = this.data_fields.tariff;
          }

          let vacancy = {
            name: this.data_fields.name,
            position_id: this.data_fields.position_id,
            company_id: this.data_fields.company_id,
            start_date: this.data_fields.start_date,
            vacancy_close_date: this.data_fields.vacancy_close_date,
            desc: this.data_fields.desc,
            people_needed: this.data_fields.people_needed,
            sal_indication: sal_indication,
            tariff: tariff,
            employment_type: this.data_fields.employment_type,
            employment_length: this.data_fields.employment_length,
            terms: {
              min_days: this.data_fields.min_days,
              extra_remarks: this.data_fields.extra_remarks,
              dresscode: this.data_fields.dresscode,
              extra_requirements: this.data_fields.extra_requirements,
              cao: this.data_fields.cao,
              initial_scale: this.data_fields.initial_scale,
              surcharches: this.data_fields.surcharches,
            },
            other_info: this.data_fields.other_info,
            cm_id: this.data_fields.cm_id,
            rec_id: this.data_fields.rec_id,
            address: {
              region: this.data_fields.region,
              street: this.data_fields.street,
              houseno: this.data_fields.houseno,
              addition: this.data_fields.addition,
              postalcode: this.data_fields.postalcode,
            },
            region: this.data_fields.region,
            status: this.data_fields.status,
            vacancy_link: this.data_fields.vacancy_link,
            cp: this.data_fields.cp,
            job_id: this.data_fields.job_id,
          };

          await calculateLocation(vacancy.address).then((res) => {
            vacancy.address.lat = res.lat;
            vacancy.address.lng = res.lng;
          });

          vacancy.terms = JSON.stringify(vacancy.terms);
          vacancy.address = JSON.stringify(vacancy.address);
          let id = this.data_fields.id;

          if (this.formData.job !== null) {
            if (
              this.data_fields.role != this.formData.job.role.id ||
              this.data_fields.department != this.formData.job.department.id ||
              this.data_fields.industry != this.formData.job.industry.id
            ) {
              const job = {
                role_id: this.data_fields.role,
                department_id: this.data_fields.department,
                industry_id: this.data_fields.industry,
              };
              await this.createJob(job).then((res) => {
                vacancy.job_id = res.id;
              });
            }
          } else {
            const job = {
              role_id: this.data_fields.role,
              department_id: this.data_fields.department,
              industry_id: this.data_fields.industry,
            };
            await this.createJob(job).then((res) => {
              vacancy.job_id = res.id;
            });
          }

          await this.updateVacancy({ vacancy, id }).then(() => {
            dispatch(
              "alerts/success",
              this.$t("create_vacancy.vacancy_adjusted")
            );
            this.adding = false;
            this.getVacancyById(id);
            this.$emit("close-dialog");
          });
        } else {
          // Add notification
          dispatch("alerts/warn", this.$t("create_vacancy.not_authorised"));
        }
      } else {
        dispatch("alerts/warn", this.$t("create_vacancy.warning"));
      }
      this.adding = false;
    },

    async addVacancy() {
      const { dispatch } = this.$store;
      if (this.$refs.form.validate()) {
        if (permission("canCreateVacancy") || permission("isAdmin")) {
          if (!this.use_existing_contact) {
            this.adding = true;

            let contact = {
              first_name: this.contact_fields.first_name,
              last_name: this.contact_fields.last_name,
              department: this.contact_fields.department,
              mail: this.contact_fields.mail,
              phone: this.contact_fields.phone,
              comp_id: this.data_fields.company_id,
            };
            await this.createContact(contact).then((contact) => {
              this.data_fields.cp = contact.contact_person.id;
            });
          }
          let tariff = null;
          let sal_indication = null;
          if (this.data_fields.tariff !== "") {
            tariff = this.data_fields.tariff;
          }
          if (this.data_fields.sal_indication !== "") {
            sal_indication = this.data_fields.tariff;
          }
          let vacancy = {
            name: this.data_fields.name,
            position_id: this.data_fields.position_id,
            company_id: this.data_fields.company_id,
            start_date: this.data_fields.start_date,
            vacancy_close_date: this.data_fields.vacancy_close_date,
            desc: this.data_fields.desc,
            people_needed: this.data_fields.people_needed,
            sal_indication: sal_indication,
            tariff: tariff,
            employment_type: this.data_fields.employment_type,
            employment_length: this.data_fields.employment_length,
            terms: {
              min_days: this.data_fields.min_days,
              extra_remarks: this.data_fields.extra_remarks,
              cao: this.data_fields.cao,
              initial_scale: this.data_fields.initial_scale,
              surcharches: this.data_fields.surcharches,
            },
            other_info: this.data_fields.other_info,
            cm_id: this.data_fields.cm_id,
            rec_id: this.data_fields.rec_id,
            address: {
              region: this.data_fields.region,
              street: this.data_fields.street,
              houseno: this.data_fields.houseno,
              addition: this.data_fields.addition,
              postalcode: this.data_fields.postalcode,
            },
            region: this.data_fields.region,
            status: this.data_fields.status,
            vacancy_link: this.data_fields.vacancy_link,
            cp: this.data_fields.cp,
            job_id: this.data_fields.job_id,
          };

          await calculateLocation(vacancy.address).then((res) => {
            vacancy.address.lat = res.lat;
            vacancy.address.lng = res.lng;
          });

          vacancy.address = JSON.stringify(vacancy.address);
          vacancy.terms = JSON.stringify(vacancy.terms);

          const job = {
            role_id: this.data_fields.role,
            department_id: this.data_fields.department,
            industry_id: this.data_fields.industry,
          };
          await this.createJob(job).then((res) => {
            vacancy.job_id = res.id;
          });

          await this.createVacancy(vacancy).then(() => {
            //dispatch("alerts/success", "Vacature aangemaakt");
            if (this.activeCompany) {
              this.getVacanciesByCompanyId(this.activeCompany.company.id);
            }
            this.getVacancies();
            this.clear();
            this.adding = false;
            this.$emit("close-dialog");
          });
        } else {
          // Add notification
          dispatch("alerts/warn", this.$t("create_vacancy.not_authorised"));
        }
      } else {
        dispatch("alerts/warn", this.$t("create_vacancy.warning"));
      }
      this.adding = false;
    },
    clear() {
      this.$refs.form.reset();
    },
    loadComponent() {
      this.initialize();
      if (this.type === "1" || this.type === "2") {
        this.use_existing_contact = true;
        this.data_fields = this.formData;
        if (this.type === "2") {
          this.data_fields.name = "";
        }
        this.data_fields.min_days = this.formData.terms.min_days;
        this.data_fields.extra_remarks = this.formData.terms.extra_remarks;
        this.data_fields.dresscode = this.formData.terms.dresscode;
        this.data_fields.extra_requirements =
          this.formData.terms.extra_requirements;
        this.data_fields.cao = this.formData.terms.cao;
        this.data_fields.initial_scale = this.formData.terms.initial_scale;
        this.data_fields.surcharches = this.formData.terms.surcharches;
        this.data_fields.street = this.data_fields.address.street;
        this.data_fields.postalcode = this.formData.address.postalcode;
        this.data_fields.houseno = this.formData.address.houseno;
        this.data_fields.addition = this.formData.address.addition;
        if (this.formData.job_id !== null) {
          this.data_fields.job_id = this.formData.job_id;
        }
        if (this.formData.job !== null) {
          this.data_fields.role = this.formData.job.role.id;
          this.data_fields.department = this.formData.job.department.id;
          this.data_fields.industry = this.formData.job.industry.id;
        }
        this.setContacts();
      }
      if (this.activeCompany) {
        this.data_fields.company_id = this.activeCompany.company.id;
        this.setContacts();
      }
    },
  },
  created() {
    this.loadComponent();
  },
};
</script>
