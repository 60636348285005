<template>
  <v-container fill-height class="ma-0 pa-0" v-if="mailTriggersLoaded">
    <v-row class="mx-1 mt-1" justify="space-between">
      <v-col>Acquisition Email</v-col>
      <v-switch
        v-model="mailTriggers.acquisitionTrigger"
        color="red"
        hide-details
        @change="showBtn()"
      ></v-switch>
    </v-row>
    <v-row class="mx-1" justify="space-between">
      <v-col>Reschedule Intake Emails</v-col>
      <v-switch
        v-model="mailTriggers.rescheduleIntakeTrigger"
        color="red"
        hide-details
        @change="showBtn()"
      ></v-switch>
    </v-row>
    <v-row class="mx-1" justify="space-between">
      <v-col>Complete Contract Emails</v-col>
      <v-switch
        v-model="mailTriggers.contractReminderTrigger"
        color="red"
        hide-details
        @change="showBtn()"
      ></v-switch>
    </v-row>
    <v-row class="mx-1" justify="space-between">
      <v-col>End of Contact Emails</v-col>
      <v-switch
        v-model="mailTriggers.endOfContactTrigger"
        color="red"
        hide-details
        @change="showBtn()"
      ></v-switch>
    </v-row>
    <v-row class="mx-1" justify="space-between">
      <v-col>New Vacancies Emails</v-col>
      <v-switch
        v-model="mailTriggers.newVacanciesTrigger"
        color="red"
        hide-details
        @change="showBtn()"
      ></v-switch>
    </v-row>
    <v-row class="mx-1" justify="space-between">
      <v-col>No Contact Emails</v-col>
      <v-switch
        v-model="mailTriggers.noContactTrigger"
        color="red"
        hide-details
        @change="showBtn()"
      ></v-switch>
    </v-row>
    <v-row class="mx-1 mb-2" justify="space-between">
      <v-col>Survey Emails</v-col>
      <v-switch
        v-model="mailTriggers.surveyTrigger"
        color="red"
        hide-details
        @change="showBtn()"
      ></v-switch>
    </v-row>
    <v-layout align-end justify-end>
      <v-btn
        :disabled="disableBtn"
        class="mr-2 mb-2"
        color="primary"
        @click="updateTriggers()"
        >Apply</v-btn
      >
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { GET_MAIL_TRIGGERS, UPDATE_EMAIL_TRIGGERS } from "@/store/action.type";
export default {
  data: () => ({
    mailTriggers: {},
    mailTriggersLoaded: false,
    disableBtn: true,
  }),
  computed: {
    ...mapGetters("send_grid", ["getEmailTriggers"]),
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions("send_grid", {
      updateEmailTriggers: UPDATE_EMAIL_TRIGGERS,
      getMailTriggers: GET_MAIL_TRIGGERS,
    }),
    async initialize() {
      await this.getMailTriggers().then(() => {
        this.mailTriggersLoaded = true;
      });
      this.mailTriggers = this.getEmailTriggers[0];
    },
    showBtn() {
      if (this.disableBtn) {
        this.disableBtn = false;
      }
    },
    async updateTriggers() {
      let emailTriggers = {
        acquisitionTrigger: this.mailTriggers.acquisitionTrigger,
        contractReminderTrigger: this.mailTriggers.contractReminderTrigger,
        endOfContactTrigger: this.mailTriggers.endOfContactTrigger,
        newVacanciesTrigger: this.mailTriggers.newVacanciesTrigger,
        noContactTrigger: this.mailTriggers.noContactTrigger,
        rescheduleIntakeTrigger: this.mailTriggers.rescheduleIntakeTrigger,
        surveyTrigger: this.mailTriggers.surveyTrigger,
      };
      let id = this.mailTriggers.id;

      await this.updateEmailTriggers({
        emailTriggers: JSON.stringify(emailTriggers),
        id: id,
      });

      this.disableBtn = true;
    },
  },
};
</script>
