// Clients
export const SET_CLIENTS = "setClients";

// ! Authentication (unused)
// export const LOGIN_SUCCESS = "loginSuccess";
// export const LOGIN_FAILURE = "loginFailure";
// export const LOGOUT_MUTATION = "logout";
// export const SET_SELF = "setSelf";

// Company
export const SET_COMPANIES_REQUEST = "setCompaniesRequest";
export const SET_COMPANIES = "setCompanies";
export const SET_COMPANIES_FAILED = "setCompaniesFailed";
export const SET_COMPANY = "setCompany";
export const SET_COMPANY_PAGINATION = "setCompanyPagination";

// Contact moments
export const SET_CONTACT_MOMENTS_PERIOD = "setContactMomentsPeriod";

// Contact
export const SET_CONTACT_REQUEST = "setContactRequest";
export const SET_CONTACTS = "setContacts";
export const SET_CONTACTS_FAILED = "setContactsFailed";

// Dashboard

// File upload

// Filters
export const SET_VACANCY_FILTER = "setVacancyFilter";
export const SET_LEAD_FILTER = "setLeadFilter";
export const SET_CANDIDATE_FILTER = "setCandidateFilter";
export const SET_LEAD_SORT_BY = "setLeadSortBy";
export const SET_LEAD_SORT_DESC = "setLeadSortDesc";

// Flow

// Intake data

// Interests

// Departments

// Roles

// Industries

// Jobs

// Lead users
export const SET_LEAD_USERS = "setLeadUsers";
export const SET_LEAD_USER = "setLeadUser";
export const SET_LEAD_USERS_WITH_LEAD = "setLeadUsersWithLead";
export const SET_FILTERED_LEAD_USER = "setFilteredLeadUser";

export const SET_LEAD_USERS_PAGINATION = "setLeadUsersPagination";
export const SET_LEAD_USERS_LOADING = "setLeadUsersLoading";
export const SET_LEAD_USERS_DISPLAY_DATA = "setLeadUsersDisplayData";
export const SET_LEAD_USERS_RELOAD = "setLeadUsersReload";
export const SET_LEAD_USERS_BY_RECRUITER = "setLeadUsersByRecruiter";
export const SET_FOUND_LEAD_USERS = "setFoundLeadUsers";

// Leads
export const SET_LEADS = "setLeads";
export const SET_LEAD = "setLead";
export const SET_LEADS_BY_VAC = "setLeadsByVac";
export const SET_LEADS_FLOW = "setLeadsFlow";
export const SET_LEADS_PER_PERIOD = "setLeadsPerPeriod";
export const SET_LEADS_BY_MONTH = "setLeadByMonth";
export const SET_CHANNELS = "setChannels";
export const SET_LEADS_VACANCIES = "setLeadsVacancies";
export const SET_LEADS_PAGINATION = "setLeadsPagination";

// Marketing spending
export const SET_SPENDING = "setSpending";
export const SET_SPENDINGS = "setSpendings";

// Notifications
export const SET_NOTIFICATIONS = "setNotifications";
export const SET_NOTIFICATION_GROUPS = "setNotificationGroups";

// ! Positions (unused)
export const GET_POSITION_REQUEST = "getPositionRequest";
export const GET_POSITION_SUCCESS = "getPositionSuccess";
export const GET_POSITION_FAILED = "getPositionFailed";

// Send grid
export const SET_INVALID_USERS_RESPONSE = "setInvalidUsersResponse";
export const SET_CONTACT_LISTS = "setContactLists";
export const SET_EMAIL_TRIGGERS = "setEmailTriggers";

// Statuses
export const SET_CONTACT_MOMENT_STATUSES = "setContactMomentStatuses";
export const SET_LEAD_STATUSES = "setLeadStatuses";
export const SET_VACANCY_STATUSES = "setVacancyStatuses";

// Teamup
export const SET_SUB_CALENDARS = "setSubCalendars";

// Users
export const GET_USERS_REQUEST = "getUsersRequest";
export const GET_USERS_SUCCESS = "getUsersSuccess";
export const GET_USERS_FAILED = "getUsersFailed";
export const SET_SELF = "setSelf";
export const SET_EMPLOYEE = "setEmployee";

// Utility
export const SAVE_DOCUMENT = "saveDocument";
export const SET_DISTANCE_BETWEEN_CITIES = "setDistanceBetweenCities";
export const SET_ERROR_LOGS = "setErrorLogs";
export const RESET_ERROR_LOGS = "resetErrorLogs";

// Vacancy filters
export const SET_VACANCY_FILTERS = "setVacancyFilters";

// Vacancies
export const GET_VACANCIES_REQUEST = "getVacanciesRequest";
export const GET_VACANCIES_SUCCESS = "getVacanciesSuccess";
export const GET_VACANCIES_FAILED = "getVacanciesFailed";
export const GET_VACANCY_SUCCESS = "getVacancySuccess";
export const GET_VACANCY_STATUSES_SUCCESS = "getVacancyStatusesSuccess";
export const GET_VACANCIES_WITH_SEARCH_TERMS_SUCCESS =
  "getVacanciesWithSearchTermsSuccess";
export const SET_VACANCY_PAGINATION = "setVacancyPagination";

// External Api Keys
export const SET_API_KEY = "setApiKey";
export const SET_API_USAGE = "setApiUsage";

// Whats App
export const SET_REFETCH = "setRefetch";
export const SET_UNREAD_MESSAGES = "setUnreadMessages";

// Lead Pipes
export const SET_LEAD_PIPES = "setLeadPipes";
export const REMOVE_PIPE = "removeLeadPipe";

// Vacancy Flow
export const SET_VACANCIES_ALL = "setVacanciesAll";
export const SET_VACANCY_FLOW = "setVacancyFlow";
export const SET_VACANCY_NO_FLOW = "setVacancyNoFlow";
export const SET_VACANCY_FLOW_BY_COMPANY = "setVacancyFlowByCompany";
export const GET_VACANCY_FLOW_SUCCESS = "getVacancyFlowSuccess";

//Vacancy Templates
export const SET_TEMPLATES = "setTemplates";

//Email Templates
export const SET_EMAIL_TEMPLATES = "setEmailTemplates";

//Hotlists
export const SET_HOTLISTS = "setHotlists";
export const SET_ACTIVE_HOTLIST = "setActiveHotlist";

//Hotlist Members
export const SET_HOTLIST_MEMBERS = "setHotlistMembers";

//Email Activity
export const SET_EMAIL_ACTIVITY = "setEmailActivity";

//WhatsApp Survey Users
export const SET_WHATSAPP_SURVEY_USERS = "setWhatsAppSurveyUsers";
