<template>
  <v-container fluid class="px-5">
    <v-card class="pa-4" elevation="4">
      <v-row>
        <v-col md="8">
          <v-row class="pa-3">
            <h2>{{ $t("navbar.vacancies") }}</h2>
            <v-divider
              vertical
              inset
              style="padding-left: 10px; padding-left: 10px"
            ></v-divider>
            <v-text-field
              style="padding-top: 0px; padding-left: 10px"
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        clickable
        :search="search"
        :headers="headers"
        :items="vacancy_flow_by_company_getter"
        :footer-props="{
          'items-per-page-options': [3, 5, 10],
        }"
        :items-per-page="3"
        class="pointerOnHover elevation-1"
        @click:row="openVacancyFlow"
      >
        <template v-slot:[`item.Vacancy_status`]="{ item }">
          <v-chip
            label
            v-if="item.Vacancy_status"
            class="ma-2"
            :color="item.Vacancy_status.color"
          >
            {{ item.Vacancy_status.name }}
          </v-chip>
        </template>
        <template v-slot:[`item.Recruiter`]="{ item }">
          <div v-if="item.Recruiter">
            {{ item.Recruiter.first_name + " " + item.Recruiter.last_name }}
          </div>
        </template>
        <template v-slot:[`item.Company`]="{ item }">
          <div v-if="item.Company">
            {{ item.Company.name }}
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    search: "",
  }),
  computed: {
    ...mapGetters("vacancy_flow", ["vacancy_flow_by_company_getter"]),
    headers() {
      let headers = [
        {
          text: "Status",
          value: "Vacancy_status",
          sortable: false,
        },
        {
          text: "Name",
          value: "name",
          sortable: false,
        },
        {
          text: "City",
          value: "city",
          sortable: false,
        },
        {
          text: "Work Hours",
          value: "workHours",
          sortable: false,
        },
        {
          text: "Recruiter",
          value: "Recruiter",
          sortable: false,
        },
        {
          text: "Company",
          value: "Company",
          sortable: false,
        },
        {
          text: "Start Date",
          value: "startDate",
          sortable: false,
        },
        {
          text: "Hiring Type",
          value: "hiringType",
          sortable: false,
        },
        {
          text: "Category",
          value: "category",
          sortable: false,
        },
      ];
      return headers;
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {},
    openVacancyFlow(item) {
      this.$router.push(`/vacature/${item.id}`);
    },
  },
};
</script>

<style scoped>
.pointerOnHover tr:hover {
  cursor: pointer;
}
</style>
