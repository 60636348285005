<template>
  <v-container fluid>
    <v-card elevation="0">
      <v-col md="3">
        <v-text-field v-model="search" label="Search" dense></v-text-field>
      </v-col>

      <v-data-table
        :search="search"
        :items="archived_vacancies_getter"
        :headers="headers"
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50],
        }"
        :items-per-page="30"
        class="elevation-1"
      >
        <template v-slot:[`item.action`]="{ item }">
          <td class="pr-0 pl-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="newItemBtn px-0"
                  @click="activate(item)"
                  color="primary"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-clipboard-arrow-up </v-icon>
                </v-btn>
              </template>
              <span>Activate Vacancy</span>
            </v-tooltip>
          </td>
        </template>
        <template v-slot:[`item.Vacancy_status`]="{ item }">
          <v-chip
            label
            class="ma-2 statusChip"
            :color="item.Vacancy_status.color"
            dark
            elevation="4"
          >
            {{ item.Vacancy_status.name }}
          </v-chip>
        </template>
        <template v-slot:[`item.Recruiter`]="{ item }">
          <div v-if="item.Recruiter">
            {{ item.Recruiter.first_name + " " + item.Recruiter.last_name }}
          </div>
        </template>
        <template v-slot:[`item.Company`]="{ item }">
          <div v-if="item.Company">
            {{ item.Company.name }}
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapState } from "vuex";
import {
  GET_VACANCIES_ALL,
  UPDATE_VACANCY_FLOW,
  SYNC_LISTS,
} from "../store/action.type";
export default {
  components: {},
  data: () => ({
    search: "",
  }),

  computed: {
    ...mapGetters("vacancy_flow", ["archived_vacancies_getter"]),
    headers() {
      //* Vacancies without category
      let headers = [
        {
          text: "",
          value: "action",
          sortable: false,
          width: "1%",
          padding: "0",
        },
        {
          text: "Status",
          value: "Vacancy_status",
          sortable: false,
          width: "10%",
        },
        {
          text: "Name",
          value: "name",
          sortable: false,
          width: "20%",
        },
        {
          text: "Recruiter",
          value: "Recruiter",
          sortable: false,
        },
        {
          text: "Company",
          value: "Company",
          sortable: false,
        },
      ];
      return headers;
    },
  },

  created() {
    this.init();
  },

  methods: {
    ...mapActions("vacancy_flow", {
      getVacanciesAll: GET_VACANCIES_ALL,
      updateVacancyFlow: UPDATE_VACANCY_FLOW,
    }),
    ...mapActions("filters", { syncLists: SYNC_LISTS }),

    async init() {
      await this.syncLists(true);
      await this.getVacanciesAll();
    },
    async activate(item) {
      let id = item.id;
      let vacancy = {
        archived: false,
      };
      await this.updateVacancyFlow({ vacancy, id });
      await this.getVacanciesAll();
    },
  },
};
</script>

<style scoped>
.newItemBtn:hover {
  transform: scale(1.1);
}
.statusChip {
  font-weight: 500;
}
</style>
