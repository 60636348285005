<template>
  <v-container>
    <v-form ref="form" v-model="valid" class="pa-3">
      <v-row>
        <h3>{{ $t("create_position.add_a_position") }}</h3>
      </v-row>
      <v-row align="center" justify="center">
        <v-text-field
          v-model="positionName"
          :label="$t('create_position.position_name')"
          required
        ></v-text-field>
        <v-col style="margin-top: 0px; padding-top: 0px" align="end">
          <v-btn
            style="margin-top: 0px; padding-top: 0px"
            :disabled="!valid && !adding"
            color="success"
            class="mr-4"
            @click="create"
          >
            {{ $t("create_position.add_position") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { permission } from "../../utils/permissions";
import { mapActions } from "vuex";
import { CREATE_POSITION, GET_POSITIONS } from "../../store/action.type";
export default {
  data: () => ({
    adding: false,
    valid: true,
    positionName: "",
  }),

  methods: {
    ...mapActions("positions", {
      createPosition: CREATE_POSITION,
      getPositions: GET_POSITIONS,
    }),
    async create() {
      const { dispatch } = this.$store;
      if (this.positionName != "") {
        if (permission("canCreatePositions") || permission("isAdmin")) {
          this.adding = true;
          let fields = {
            name: this.positionName,
          };
          await this.createPosition(fields).then(
            dispatch(
              "alerts/success",
              this.$t("create_position.position_added")
            )
          );
          this.getPositions();
          this.adding = false;
          this.clear();
          this.$emit("close-dialog");
        } else {
          dispatch(
            "alerts/warn",
            this.$t("create_position.not_authorized_msg")
          );
          this.adding = false;
        }
      } else {
        dispatch("alerts/warn", this.$t("create_position.name_the_position"));
        this.adding = false;
      }
    },
    clear() {
      this.positionName = "";
    },
  },
};
</script>
