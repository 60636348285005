<template>
  <v-container v-if="activeHotlist !== null">
    <div class="pa-0">
      <v-text-field
        background-color="cardFields"
        outlined
        dense
        required
        :rules="requiredRules"
        label="Hotlist Name"
        v-model="activeHotlist.name"
      ></v-text-field>
      <v-row class="align-center mb-2">
        <v-col class="mr-0" md="9">
          <v-autocomplete
            background-color="cardFields"
            dense
            outlined
            :items="customMembers"
            :item-text="fullName"
            item-value="id"
            return-object
            v-model="selectedMembers"
            hide-details
            hide-no-data
            multiple
            @input="search = ''"
            label="Add more members"
            :search-input.sync="search"
            ><template v-slot:selection="{ item, index }">
              <v-chip
                small
                label
                color="#E1F5FE"
                text-color="#0277BD"
                v-if="index < 2"
              >
                <span>{{ fullName(item) }}</span>
              </v-chip>
              <span v-if="index === 2" class="grey--text text-caption">
                (+{{ selectedMembers.length - 2 }} others)
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col class="" md="3">
          <v-btn
            class="formBtn"
            small
            text
            color="primary"
            @click="addMember()"
          >
            Add Candidates
          </v-btn></v-col
        >
      </v-row>
      <!-- search field -->
      <v-col class="mt-4 mb-2" md="5">
        <v-text-field
          v-model="searchTable"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          class="ma-0 pa-0"
          dense
        ></v-text-field>
      </v-col>
      <v-data-table
        :search="searchTable"
        :headers="headers"
        :items="hotlistMembers"
        :items-per-page="10"
        class="elevation-1"
      >
        <template v-slot:[`item.first_name`]="{ item }">
          {{ item.first_name }}</template
        >
        <template v-slot:[`item.last_name`]="{ item }">
          {{ item.last_name }}</template
        >
        <template v-slot:[`item.email`]="{ item }"> {{ item.email }}</template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="deleteMember(item)">
            mdi-trash-can
          </v-icon>
        </template></v-data-table
      >
      <v-row class="mt-4">
        <v-spacer></v-spacer>
        <v-btn color="primary" class="formBtn" @click="update()">Save</v-btn>
        <v-spacer />
        <v-icon
          @click="deleteActiveHotlist()"
          medium
          color="red"
          class="deleteIcon formBtn"
          >mdi-trash-can</v-icon
        ></v-row
      >
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  GET_ALL_HOTLISTS,
  GET_HOTLIST_BY_ID,
  ADD_HOTLIST_MEMBER,
  UPDATE_HOTLIST,
  REMOVE_HOTLIST_MEMBER,
  DELETE_HOTLIST,
  SEARCH_LEAD_USERS_BY_NAME,
  EMPTY_FOUND_LEAD_USERS,
} from "../../../store/action.type";
export default {
  props: { activeHotlist: Object, selectedCandidates: Array },
  data: () => ({
    requiredRules: [(v) => !!v || "Field is required"],
    searchTable: "",

    //* Fetching Recipients
    search: null,
    isLoading: false,
  }),

  computed: {
    ...mapGetters("hotlists", ["hotlist_members_getter"]),
    ...mapGetters("lead_users", ["found_lead_users_getter"]),
    headers() {
      return [
        {
          text: "First Name",
          value: "first_name",
        },
        {
          text: "Last Name",
          value: "last_name",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Actions",
          value: "actions",
        },
      ];
    },
    selectedMembers: {
      get() {
        if (this.selectedCandidates) {
          return this.selectedCandidates;
        } else {
          return [];
        }
      },
      set(val) {
        if (val.length > 0) {
          val.map((item) => {
            if (!this.selectedMembers.includes(item)) {
              this.selectedMembers.push(item);
            }
          });
        }
      },
    },
    hotlistMembers() {
      let temp = this.hotlist_members_getter(this.activeHotlist.id);
      // map first_name, last_name, email to new array
      let arr = [];
      temp.forEach((element) => {
        arr.push({
          first_name: element.lead_user.first_name,
          last_name: element.lead_user.last_name,
          email: element.lead_user.email,
        });
      });
      return arr;
    },
    customMembers() {
      let arr = [];
      arr = this.found_lead_users_getter;
      this.selectedMembers.forEach((element) => {
        arr.push(element);
      });
      return arr;
    },
  },
  created() {
    this.init();
  },
  watch: {
    search: function (val) {
      if (val !== null && val !== "") {
        this.fetchLeadUsersByName(val);
      } else {
        this.emptyFoundLeadUsers();
      }
    },
  },
  methods: {
    ...mapActions("lead_users", {
      searchLeadUsersByName: SEARCH_LEAD_USERS_BY_NAME,
      emptyFoundLeadUsers: EMPTY_FOUND_LEAD_USERS,
    }),
    ...mapActions("hotlists", {
      getAllHotlists: GET_ALL_HOTLISTS,
      getHotlistById: GET_HOTLIST_BY_ID,
      deleteHotlist: DELETE_HOTLIST,
      updateHotlist: UPDATE_HOTLIST,
    }),
    ...mapActions("hotlist_members", {
      addHotlistMember: ADD_HOTLIST_MEMBER,
      removeHotlistMember: REMOVE_HOTLIST_MEMBER,
    }),
    async init() {
      await this.getHotlistById(this.activeHotlist.id);
      this.hotlist_members_getter(this.activeHotlist.id);
    },
    async resetMembers() {
      await this.getHotlistById(this.activeHotlist.id);
      await this.getAllHotlists();
      this.hotlist_members_getter(this.activeHotlist.id);
    },
    async update() {
      const { dispatch } = this.$store;
      let id = this.activeHotlist.id;
      let hotlist = {
        name: this.activeHotlist.name,
      };
      await this.updateHotlist({
        hotlist,
        id,
      });

      this.$emit("close-dialog");
      this.$emit("reload-table");
      dispatch("alerts/success", "Updated successfully!");
    },
    async addMember() {
      this.selectedMembers.map((item) => {
        let hotlist_member = {
          hotlist_id: this.activeHotlist.id,
          lead_user_id: item.id,
        };
        this.addHotlistMember({
          hotlist_member,
        });
      });
      this.selectedMembers = [];
      this.$emit("reload-table");
      this.resetMembers();
    },
    async deleteActiveHotlist() {
      const { dispatch } = this.$store;
      let id = this.activeHotlist.id;
      await this.deleteHotlist({
        id,
      });
      this.$emit("close-dialog");
      this.$emit("reload-table");
      dispatch("alerts/success", "Deleted successfully!");
    },
    async deleteMember(item) {
      let leadUserId = item.lead_user_id;
      let hotlistId = item.hotlist_id;
      await this.removeHotlistMember({
        leadUserId,
        hotlistId,
      });
      this.$emit("reload-table");
      this.resetMembers();
    },
    async fetchLeadUsersByName(search) {
      this.isLoading = true;
      await this.searchLeadUsersByName(search);
      this.isLoading = false;
    },
    fullName(item) {
      return item.first_name + " " + item.last_name;
    },
  },
};
</script>
<style scoped></style>
