<template>
  <v-container fluid class="pa-0 ma-0">
    <v-row no-gutters>
      <v-col
        :md="orientation === 'conversation-container-portrait' ? '4' : '3'"
      >
        <div :class="darkTheme ? 'divDark' : 'divLight'" style="height: 10vh">
          <v-row class="pt-4 px-6">
            <v-text-field
              dense
              label="Search"
              filled
              single-line
              solo
              flat
              :background-color="darkTheme ? 'grey darken-3' : 'grey lighten-2'"
              color="#009EE3"
              v-model="search"
              append-icon="mdi-close"
              @click:append="search = ''"
            ></v-text-field>
          </v-row>
        </div>
        <v-divider color="#bdbdbd"></v-divider>
        <v-row class="pt-6 px-6">
          <v-autocomplete
            v-model="selectedVacancies"
            :items="vacancies_all_getter"
            :menu-props="{ maxHeight: '400' }"
            label="Vacancies"
            multiple
            dense
            clearable
            item-text="name"
            item-value="id"
            filled
            single-line
            solo
            flat
            :background-color="darkTheme ? 'grey darken-3' : 'grey lighten-2'"
            color="#009EE3"
            @change="selectedUser = null"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span>{{ item.name.substring(0, 10) }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ selectedVacancies.length - 1 }})
              </span>
            </template></v-autocomplete
          >
        </v-row>
        <v-card
          :color="darkTheme ? '#383838' : '#e9ecef'"
          style="height: 83vh; overflow-y: scroll"
          elevation="0"
          tile
        >
          <div v-if="usersUnreadMessages.length > 0" class="my-2 text-center">
            <h5>New</h5>
          </div>
          <v-list class="py-0">
            <v-list-item-group :key="userListKey">
              <template v-for="(item, index) in usersUnreadMessages">
                <v-list-item
                  :key="item.id"
                  @click="selectUser(item)"
                  :class="
                    selectedUser == null
                      ? ''
                      : selectedUser.id == item.id
                      ? 'selectedUserClass'
                      : ''
                  "
                >
                  <v-list-item-avatar color="indigo" class="ml-0">
                    <span class="white--text text-h7">{{
                      avatarName(item)
                    }}</span>
                  </v-list-item-avatar>
                  <v-list-item-content class="md-3">
                    <v-list-item-title>{{
                      item.first_name + " " + item.last_name
                    }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-action-text>
                      {{ timePassed(item) }}
                    </v-list-item-action-text>

                    <v-chip
                      v-if="item.latest_messages.length > 0"
                      x-small
                      color="primary"
                      text-color="white"
                    >
                      {{ item.latest_messages.length }} New
                    </v-chip>
                  </v-list-item-action>
                </v-list-item>
                <v-divider
                  v-if="index < usersUnreadMessages.length - 1"
                  :key="index"
                ></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
          <div v-if="usersResponded.length > 0" class="my-2 text-center">
            <h5>Open</h5>
          </div>
          <v-list class="py-0">
            <v-list-item-group :key="userListKey">
              <template v-for="(item, index) in usersResponded">
                <v-list-item
                  :key="item.id"
                  @click="selectUser(item)"
                  :class="
                    selectedUser == null
                      ? ''
                      : selectedUser.id == item.id
                      ? 'selectedUserClass'
                      : ''
                  "
                >
                  <v-list-item-avatar color="indigo" class="ml-0">
                    <span class="white--text text-h7">{{
                      avatarName(item)
                    }}</span>
                  </v-list-item-avatar>
                  <v-list-item-content class="md-3">
                    <v-list-item-title>{{
                      item.first_name + " " + item.last_name
                    }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-action-text>
                      {{ timePassed(item) }}
                    </v-list-item-action-text>
                  </v-list-item-action>
                </v-list-item>
                <v-divider
                  v-if="index < usersResponded.length - 1"
                  :key="index"
                ></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
          <!-- <div v-if="usersNoResponse.length > 0" class="my-2 text-center">
            <h5>No Response</h5>
          </div>
          <v-list class="py-0">
            <v-list-item-group :key="userListKey">
              <template v-for="(item, index) in usersNoResponse">
                <v-list-item
                  :key="item.id"
                  @click="selectUser(item)"
                  :class="
                    selectedUser == null
                      ? ''
                      : selectedUser.id == item.id
                      ? 'selectedUserClass'
                      : ''
                  "
                >
                  <v-list-item-avatar color="indigo" class="ml-0">
                    <span class="white--text text-h7">{{
                      avatarName(item)
                    }}</span>
                  </v-list-item-avatar>
                  <v-list-item-content class="md-3">
                    <v-list-item-title>{{
                      item.first_name + " " + item.last_name
                    }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-action-text>
                      {{ timePassed(item) }}
                    </v-list-item-action-text>
                  </v-list-item-action>
                </v-list-item>
                <v-divider
                  v-if="index < usersNoResponse.length - 1"
                  :key="index"
                ></v-divider>
              </template>
            </v-list-item-group>
          </v-list> -->
        </v-card>
      </v-col>
      <v-col
        :md="orientation === 'conversation-container-portrait' ? '8' : '6'"
      >
        <div
          v-if="selectedUser != null"
          :class="darkTheme ? 'divDark' : 'divLight'"
          style="height: 10vh"
        >
          <v-row class="pt-1 pl-6">
            <v-list-item class="grow">
              <v-list-item-avatar color="indigo">
                <span class="white--text text-h7">{{
                  avatarName(selectedUser)
                }}</span>
              </v-list-item-avatar>
              <div>
                <v-list-item-content class="md-3">
                  <v-list-item-title>{{
                    selectedUser.first_name + " " + selectedUser.last_name
                  }}</v-list-item-title>
                </v-list-item-content>
              </div>

              <a
                v-if="getVacancy(selectedUser)"
                class="mr-2 ml-4"
                @click="openVacancy(getVacancy(selectedUser)?.id)"
              >
                {{ getVacancy(selectedUser)?.name }}
              </a>

              <span v-else class="mr-2 ml-4 text-caption">No Vacancy</span>

              <v-btn plain color @click="markAsRead(selectedUser)"
                >Mark As Read</v-btn
              >
              <v-col align="end">
                <v-btn
                  icon
                  color="grey"
                  align="end"
                  size="15"
                  @click="openProfile(selectedUser)"
                >
                  <v-icon>mdi-open-in-new</v-icon>
                </v-btn>
              </v-col>
            </v-list-item>
          </v-row>
        </div>
        <v-divider color="#bdbdbd"></v-divider>
        <v-card
          style="
            border-left: 1px solid #bdbdbd;
            border-right: 1px solid #bdbdbd;
          "
          :color="darkTheme ? '#383838' : '#e9ecef'"
          elevation="0"
          tile
          v-if="selectedUser != null"
        >
          <v-col>
            <div class="ma-0 pa-0" align="center">
              <v-btn
                dense
                text
                small
                @click="fetchMessagesforUser(selectedUser)"
                >Fetch Older</v-btn
              >
            </div>
          </v-col>
          <div id="container" class="pa-4" :class="orientation">
            <div
              v-for="computedFeed in computedFeed"
              :key="computedFeed.id"
              class="row bubble-container"
              :class="{
                userMessage: computedFeed.direction === 'received',
                myMessageFailed:
                  computedFeed.status === 'failed' ||
                  computedFeed.status === 'rejected',
              }"
            >
              <div v-if="darkTheme" class="bubble_dark">
                <div class="name_dark">{{ computedFeed.date }}</div>
                <div class="message_dark">{{ computedFeed.text }}</div>
                <div class="status_dark">{{ computedFeed.status }}</div>
              </div>
              <div v-else-if="!darkTheme" class="bubble">
                <div class="name">{{ computedFeed.date }}</div>
                <div class="message">{{ computedFeed.text }}</div>
                <div class="status">{{ computedFeed.status }}</div>
              </div>
            </div>
          </div>
          <v-divider color="#bdbdbd"></v-divider>
          <div v-if="selectedUser != null">
            <div
              v-if="status !== 'active' || isExpired || isAwaitingResponse"
              :class="darkTheme ? 'divDark' : 'divLight'"
              class="d-flex align-center"
            >
              <v-row class="mx-3 d-flex align-center">
                <v-menu
                  v-model="emojiDialog"
                  top
                  :offset-y="true"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }"
                    ><v-btn icon dense v-bind="attrs" v-on="on">
                      <v-icon dark>mdi-emoticon</v-icon>
                    </v-btn>
                  </template>
                  <v-card><VEmojiPicker @select="selectEmoji" /></v-card
                ></v-menu>
                <v-text-field
                  clear-icon="mdi-close-circle"
                  filled
                  single-line
                  solo
                  flat
                  dense
                  :background-color="
                    darkTheme ? 'grey darken-3' : 'grey lighten-2'
                  "
                  append-outer-icon="mdi-send"
                  color="#009EE3"
                  v-model="messageText"
                  class="mt-6"
                  placeholder="Enter your message"
                  @click:append-outer="createNewConversation"
                  @keyup.enter="createNewConversation"
                />
              </v-row>
            </div>

            <div
              v-if="status === 'active' && !isExpired && !isAwaitingResponse"
              :class="darkTheme ? 'divDark' : 'divLight'"
              class="d-flex align-center"
            >
              <v-row class="mx-3 d-flex align-center">
                <v-menu
                  v-model="emojiDialog"
                  top
                  :offset-y="true"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }"
                    ><v-btn icon dense v-bind="attrs" v-on="on">
                      <v-icon dark>mdi-emoticon</v-icon>
                    </v-btn>
                  </template>
                  <v-card><VEmojiPicker @select="selectEmoji" /></v-card
                ></v-menu>
                <v-text-field
                  clear-icon="mdi-close-circle"
                  clearable
                  filled
                  single-line
                  solo
                  flat
                  dense
                  :background-color="
                    darkTheme ? 'grey darken-3' : 'grey lighten-2'
                  "
                  append-outer-icon="mdi-send"
                  color="#009EE3"
                  v-model="messageText"
                  class="mt-6"
                  placeholder="Enter your message"
                  @click:append-outer="onMessage"
                  @keyup.enter="onMessage"
                />
              </v-row>
            </div>
          </div>
        </v-card>
        <v-card
          v-else
          elevation="0"
          height="97.3vh"
          class="text-center"
          :color="darkTheme ? '#383838' : '#f8f9fa'"
        >
          <v-row class="pt-0 pl-6" style="height: 80px">
            <v-list-item class="grow justify-center" style="margin-top: 50px">
              <div class="justify-center">
                <v-list-item-content>
                  <h1 class="headline" style="color: #009ee3">
                    CLLBR Conversations
                  </h1>
                  <div>Welcome to the second version of CLLBR Conversation</div>
                </v-list-item-content>
              </div>
            </v-list-item>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { VEmojiPicker } from "v-emoji-picker";
import { mapActions, mapState, mapGetters } from "vuex";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import {
  SEND_MESSAGE,
  GET_ALL_MESSAGES_FOR_USER,
  GET_CONVERSATION_BY_ID,
  CREATE_CONVERSATION,
  GET_USERS_WITH_CONVERSATIONS,
  DELETE_ALL_NEW_MESSAGES,
  GET_SELF,
  UPDATE_UNREAD_MESSAGES,
  GET_VACANCIES_ALL,
} from "../store/action.type";
import { getTemplate } from "../utils/whats_app_templates";
export default {
  components: {
    VEmojiPicker,
  },
  data: () => ({
    search: "",
    messageText: "",
    emojiDialog: false,
    selectedUser: null,
    users: [],
    users_unread_messages: [],
    users_no_response: [],
    users_responded: [],
    userListKey: 0,
    isLoaded: false,

    selectedVacancies: [],

    //* Checks If fetchMessages is alredy in progress
    fetchingInProgress: false,

    //* Offset of the messages
    offset: 0,

    //*  Unorganized Array of messages
    arr: [],

    //* Feed of messages
    feed: [],

    status: "",
    params: [],
    languages: [
      {
        name: "English",
        value: "en",
      },
      {
        name: "Dutch",
        value: "nl",
      },
    ],
    temp_lang: "en",
    temp_name: "",
    templateDialog: false,
  }),

  created() {
    this.initialize();
  },
  mounted() {
    this.interval = setInterval(() => {
      this.getUsersWithConv();
    }, 20000);
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  computed: {
    ...mapGetters("vacancy_flow", ["vacancies_all_getter"]),
    ...mapGetters("users", ["getUser"]),
    ...mapGetters("notifications", ["getNotifCount"]),
    usersUnreadMessages() {
      let arr = [];
      let twoWeeksAgo = new Date();
      twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
      for (let i = 0; i < this.users_unread_messages.length; i++) {
        let full_name =
          this.users_unread_messages[i].first_name +
          " " +
          this.users_unread_messages[i].last_name;
        if (full_name.toLowerCase().includes(this.search.toLowerCase())) {
          let latest_message = new Date(
            this.users_unread_messages[i].latest_message
          );
          if (latest_message > twoWeeksAgo) {
            arr.push(this.users_unread_messages[i]);
          }
        }
      }
      arr.sort(function (a, b) {
        return new Date(b.latest_message) - new Date(a.latest_message);
      });

      if (this.selectedVacancies.length > 0) {
        let filtered = arr.filter((user) => {
          let vacancy = this.getVacancy(user);
          if (vacancy) {
            return this.selectedVacancies.includes(vacancy.id);
          }
          return false;
        });

        return filtered;
      }

      return arr;
    },
    // usersNoResponse() {
    //   let arr = [];
    //   for (let i = 0; i < this.users_no_response.length; i++) {
    //     let full_name =
    //       this.users_no_response[i].first_name +
    //       " " +
    //       this.users_no_response[i].last_name;
    //     if (full_name.toLowerCase().includes(this.search.toLowerCase())) {
    //       arr.push(this.users_no_response[i]);
    //     }
    //   }
    //   arr.sort(function (a, b) {
    //     return new Date(b.latest_message) - new Date(a.latest_message);
    //   });

    //   return arr;
    // },
    usersResponded() {
      let arr = [];
      let twoWeeksAgo = new Date();
      twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
      for (let i = 0; i < this.users_responded.length; i++) {
        let full_name =
          this.users_responded[i].first_name +
          " " +
          this.users_responded[i].last_name;
        if (full_name.toLowerCase().includes(this.search.toLowerCase())) {
          let latest_message = new Date(this.users_responded[i].latest_message);
          if (latest_message > twoWeeksAgo) {
            arr.push(this.users_responded[i]);
          }
        }
      }
      arr.sort(function (a, b) {
        return new Date(b.latest_message) - new Date(a.latest_message);
      });

      if (this.selectedVacancies.length > 0) {
        let filtered = arr.filter((user) => {
          let vacancy = this.getVacancy(user);
          if (vacancy) {
            return this.selectedVacancies.includes(vacancy.id);
          }
          return false;
        });

        return filtered;
      }

      return arr;
    },
    computedFeed: {
      get() {
        return this.feed;
      },
      set() {
        return null;
      },
    },
    darkTheme() {
      return this.$vuetify.theme.dark;
    },
    computedTemplateText() {
      if (this.temp_name !== "") {
        for (let i = 0; i < this.templates.length; i++) {
          if (this.templates[i].name == this.temp_name) {
            let text = this.templates[i].text_en;
            if (this.temp_lang == "nl") {
              text = this.templates[i].text_nl;
            }
            return text;
          }
        }
      }
      return "Example Here";
    },
    computedTemplateParams() {
      if (this.temp_name !== "") {
        for (let i = 0; i < this.templates.length; i++) {
          if (this.templates[i].name == this.temp_name) {
            return this.templates[i].param_count;
          }
        }
      }
      return 0;
    },
    computedTemplateParamsNames() {
      if (this.temp_name !== "") {
        for (let i = 0; i < this.templates.length; i++) {
          if (this.templates[i].name == this.temp_name) {
            return this.templates[i].param_names;
          }
        }
      }
      return [];
    },
    isExpired() {
      //* Checking if the 24 hour time window has passed
      if (this.feed.length > 0) {
        let last_message_date = new Date(
          this.feed[this.feed.length - 1].raw_date
        );
        let last_message_status = this.feed[this.feed.length - 1].status;
        let then = new Date(last_message_date);
        let now = new Date();
        let msBetweenDates = Math.abs(then.getTime() - now.getTime());
        // 👇️ convert ms to hours                  min  sec   ms
        let hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);

        if (hoursBetweenDates >= 24 || last_message_status == "failed") {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    isAwaitingResponse() {
      //* Checking if the chat is awaiting user response
      if (this.feed.length > 0) {
        let last_message_date = new Date(
          this.feed[this.feed.length - 1].raw_date
        );
        let last_message_type = this.feed[this.feed.length - 1].type;
        let last_message_direction = this.feed[this.feed.length - 1].direction;
        let then = new Date(last_message_date);
        let now = new Date();
        let msBetweenDates = Math.abs(then.getTime() - now.getTime());
        // 👇️ convert ms to hours                  min  sec   ms
        let hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);

        if (
          hoursBetweenDates < 24 &&
          last_message_type == "template" &&
          last_message_direction == "sent"
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    orientation() {
      if (window.innerHeight > window.innerWidth) {
        return "conversation-container-portrait";
      } else return "conversation-container-landscape";
    },
  },

  methods: {
    ...mapActions("whats_app", {
      sendMessage: SEND_MESSAGE,
      getAllMessagesForUser: GET_ALL_MESSAGES_FOR_USER,
      getConversationById: GET_CONVERSATION_BY_ID,
      createConversation: CREATE_CONVERSATION,
      getUsersWithConversations: GET_USERS_WITH_CONVERSATIONS,
      deleteAllNewMessages: DELETE_ALL_NEW_MESSAGES,
      updateUnreadMessages: UPDATE_UNREAD_MESSAGES,
    }),
    ...mapActions("users", {
      getSelf: GET_SELF,
    }),
    ...mapActions("vacancy_flow", {
      getVacanciesAll: GET_VACANCIES_ALL,
    }),
    async initialize() {
      dayjs.extend(relativeTime);
      await this.getSelf(this.$auth.user.sub);
      await this.getUsersWithConv();
      await this.getVacanciesAll();
    },
    async getUsersWithConv() {
      //* Fetching all users with converations
      await this.getUsersWithConversations().then((res) => {
        this.users = res.lead_users;
        // filter users with unread messages
        let new_messages_users = this.users.filter((user) => {
          return user.latest_messages.length > 0;
        });
        // user with unread messages in the last 2 weeks
        let twoWeeksAgo = new Date();
        twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
        let new_messages_last_two_weeks = new_messages_users.filter((user) => {
          let latest_message = new Date(user.latest_message);
          return latest_message > twoWeeksAgo;
        });
        this.updateUnreadMessages(new_messages_last_two_weeks.length);
        this.users_unread_messages = new_messages_users;
        // filter users that hasnt responded
        let no_response_users = this.users.filter((user) => {
          return user.latest_message === null;
        });
        this.users_no_response = no_response_users;
        // filter users that has responded
        let responded_users = this.users.filter((user) => {
          return (
            user.latest_message !== null && user.latest_messages.length === 0
          );
        });
        this.users_responded = responded_users;
        for (let i = 0; i < this.users.length; i++) {
          if (this.users[i].latest_messages.length > 0) {
            this.users[i].message =
              this.users[i].latest_messages[
                this.users[i].latest_messages.length - 1
              ];
          } else {
            this.users[i].message = "";
          }
        }
      });
    },
    timePassed(user) {
      if (user.latest_message !== null) {
        let date = new Date(user.latest_message);
        return dayjs(date).fromNow();
      }
      return "No Data";
    },
    avatarName(user) {
      let name =
        user.first_name.substring(0, 1).toUpperCase() +
        user.last_name.substring(0, 1).toUpperCase();
      return name;
    },
    async onMessage() {
      if (this.messageText == "") {
        return;
      }
      let temp = [];

      let body = {
        message: this.messageText,
        conversation_id: this.selectedUser.conversation_id,
        user_id: this.selectedUser.id,
      };
      await this.sendMessage(body);

      //* Delete the unseen Messages from the utable
      await this.deleteAllNewMessages(this.selectedUser.id);

      //* Refetch users
      await this.getUsersWithConv();

      this.offset += 1;

      let params = {
        con_id: this.selectedUser.conversation_id,
        offset: 0,
      };
      await this.getAllMessagesForUser({ params }).then((res) => {
        temp = res.response.items;
      });
      let arr = [];
      //* Breaking down the JSON to get desired data
      for (let i = 0; i < temp.length; i++) {
        let tempDate = new Date(temp[i].createdDatetime);
        let time = tempDate.toLocaleTimeString();
        let date = tempDate.toLocaleDateString();
        let text = "";
        let type = "text";
        if (temp[i].content.hsm) {
          type = "template";
          let params = [];
          if (temp[i].content.hsm.params) {
            params = temp[i].content.hsm.params;
          }
          text = getTemplate(temp[i].content.hsm.templateName, params);
        } else {
          text = temp[i].content.text;
        }
        let obj = {
          id: temp[i].id,
          direction: temp[i].direction,
          text: text,
          date: date + "   " + time.substring(0, 5),
          raw_date: temp[i].createdDatetime,
          type: type,
          status: temp[i].status,
        };
        arr.push(obj);
      }
      this.feed = arr.reverse();
      this.messageText = "";
    },
    scrollToBottom() {
      var container = this.$el.querySelector("#container");
      container.scrollTop = container.scrollHeight;
    },
    selectEmoji(emoji) {
      this.messageText += emoji.data;
    },
    async selectUser(item) {
      console.log(item);
      this.isLoaded = false;
      this.userListKey = item.id;
      this.selectedUser = item;

      //* Reset all fields on new user selection
      await this.resetFields();

      //* Get the status of the conversation
      await this.getConversation();

      //* Fetch Messages for the conversation
      await this.fetchMessagesforUser(this.selectedUser);

      this.messageText = "";
      this.isLoaded = true;
      this.scrollToBottom();
    },

    async fetchMessagesforUser(item) {
      if (this.fetchingInProgress) return;
      this.fetchingInProgress = true;

      let temp = [];
      let params = {
        con_id: item.conversation_id,
        offset: this.offset,
      };
      await this.getAllMessagesForUser({ params }).then((res) => {
        temp = res.response.items;
      });

      //* Breaking down the JSON to get desired data
      for (let i = 0; i < temp.length; i++) {
        let tempDate = new Date(temp[i].createdDatetime);
        let time = tempDate.toLocaleTimeString();
        let date = tempDate.toLocaleDateString();
        let text = "";
        let type = "text";
        if (temp[i].content.hsm) {
          type = "template";
          let params = [];
          if (temp[i].content.hsm.params) {
            params = temp[i].content.hsm.params;
          }
          text = getTemplate(
            temp[i].content.hsm.templateName,
            params,
            temp[i].content.hsm.language.code
          );
          if (text == "") {
            text = "Template not found, please contact Ivan";
          }
        } else {
          text = temp[i].content.text;
        }
        let obj = {
          id: temp[i].id,
          direction: temp[i].direction,
          text: text,
          date: date + "   " + time.substring(0, 5),
          raw_date: temp[i].createdDatetime,
          type: type,
          status: temp[i].status,
        };
        this.arr.push(obj);
      }
      let newArr = [...this.arr];
      this.feed = newArr.reverse();
      //* Updating the offset
      this.offset += 20;
      this.fetchingInProgress = false;
    },

    async createNewConversation() {
      if (this.messageText == "") {
        return;
      }
      let params = [];
      let obj = {
        default: this.messageText,
      };
      params.push(obj);
      let data = {
        message: {
          namespace: "6bf1cd4f_1084_4310_b5de_3c6533b9c158",
          templateName: "job_follow_up_2",
          language: {
            policy: "deterministic",
            code: "en",
          },
          params: params,
        },
        number: this.selectedUser.tel_number,
      };
      await this.createConversation(data);

      await this.getConversation();
      this.offset = 0;
      this.arr = [];
      await this.fetchMessagesforUser(this.selectedUser);
      this.scrollToBottom();
      this.messageText = "";
    },

    async getConversation() {
      let params = {
        con_id: this.selectedUser.conversation_id,
      };
      await this.getConversationById({ params }).then((res) => {
        this.status = res.response.status;
      });
    },
    resetFields() {
      this.offset = 0;
      this.arr = [];
      this.feed = [];
      this.status = "";
      this.params = [];
      this.temp_lang = "en";
      this.temp_name = "";
    },
    openProfile(user) {
      let route = this.$router.resolve({ path: `/profiel/${user.id}` });
      window.open(route.href);
    },
    async markAsRead(user) {
      //* Delete the unseen Messages from the utable
      await this.deleteAllNewMessages(user.id);

      //* Refetch users
      await this.getUsersWithConv();
    },
    getVacancy(item) {
      const activeLeads = item.leads.filter((lead) => lead.active);

      if (activeLeads.length > 0) {
        return activeLeads[0].vacancy;
      } else if (item.leads.length > 0) {
        return item.leads[0].vacancy;
      } else {
        return null;
      }
    },
    openVacancy(vacancy_id) {
      let route = this.$router.resolve({
        path: `/vacature/${vacancy_id}`,
      });
      window.open(route.href, "_blank");
    },
  },
};
</script>

<style scoped>
.selectedUserClass {
  color: #42a5f5 !important;
  background: #e3f2fd !important;
}

.divDark {
  background-color: #1e1e1e;
  width: 100%;
  height: 68px !important;
}

.divLight {
  background-color: white;
  width: 100%;
  height: 68px !important;
}

.conversation-container-portrait {
  margin: 0 auto;
  padding: 0 05px;
  border: 0px !important;
  overflow: scroll;
}

.conversation-container-landscape {
  margin: 0 auto;
  height: 77vh;
  padding: 0 05px;
  border: 0px !important;
  overflow: scroll;
}

.bubble-container {
  text-align: left;
  justify-content: end;
}

.bubble {
  background-color: #248277;
  border: 2px solid #248277;
  border-radius: 13px;
  border-top-right-radius: 0px;
  padding: 05px;
  margin: 05px 0;
  margin-left: 10px;
  min-width: 330px;
  max-width: 550px;
  float: right;
  color: white;
}

.bubble_dark {
  background-color: #006466;
  border: 2px solid #006466;
  border-radius: 13px;
  border-top-right-radius: 0px;
  padding: 05px;
  margin: 05px 0;
  min-width: 330px;
  max-width: 550px;
  float: right;
}

.userMessage {
  justify-content: start;
}

.userMessage .bubble_dark {
  border: 2px solid #616161;
  background-color: #616161;
  float: left;
  border-top-right-radius: 13px;
  border-top-left-radius: 0px;
}

.userMessage .bubble {
  border: 2px solid #ffffff;
  background-color: #ffffff;
  float: left;
  border-top-right-radius: 13px;
  border-top-left-radius: 0px;
  color: black;
}

.myMessageFailed .bubble {
  border: 2px solid #b23a48;
  background-color: #b23a48;
  justify-content: end;
}

.myMessageFailed .bubble_dark {
  border: 2px solid #7c162e;
  background-color: #7c162e;
}

.userMessage .bubble .status {
  color: black;
}

.status {
  float: right;
  font-size: 11px;
  color: white;
}

.name {
  padding-right: 8px;
  font-size: 11px;
}

.status_dark {
  float: right;
  font-size: 11px;
}

.name_dark {
  padding-right: 8px;
  font-size: 11px;
}

.usersDiv {
  height: 90%;
  flex-grow: 1;
  overflow: auto;
  scrollbar-color: red yellow;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background: rgb(187, 187, 187);
}

::-webkit-scrollbar-thumb:hover {
  background: #888;
}
</style>
