<template>
  <v-container fluid class="pl-2 pr-2 mb-2">
    <v-card min-height="380">
      <v-row :style="cardHeaders" class="ma-0">
        <v-row class="pa-5">
          <v-col md="10">
            <v-row>
              <v-icon medium>mdi-thumb-up</v-icon>
              <h3><strong>Interesses</strong></h3>
            </v-row>
          </v-col>
        </v-row>
      </v-row>
      <v-row>
        <v-col class="pa-0 pl-3 pr-3">
          <InterestsSection :leadUserId="activeLead.id" type="searchPage"/>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import InterestsSection from "@/components/Interests/InterestsSection.vue";
export default {
  components: {
    InterestsSection,
  },
  props: {
    activeLead: Object,
  },
  computed: {
    cardHeaders() {
      if (this.$vuetify.theme.dark) {
        return "background: #2a2a2a";
      } else {
        return "background: #b3b3b3";
      }
    },
  },
};
</script>