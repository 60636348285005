<template>
  <v-container class="pa-0 ma-0 mt-4">
    <v-card elevation="0" max-width="40%">
      <v-card elevation="0" color="grey lighten-2" class="pb-1">
        <v-row class="">
          <v-col class="ml-4 mt-0" md="6">
            <v-row align="center">
              <h3 class="ml-3 mt-2 mb-1" style="color: black">
                {{ $t("navbar.notifications") }}
              </h3>
              <v-chip
                label
                v-if="getNotifList.length > 0"
                class="ml-3 mt-2 mb-1"
                color="primary"
              >
                {{ getNotifList.length }} New</v-chip
              >
            </v-row>
          </v-col>

          <v-col class="mt-1 pt-0" style="text-align: right">
            <v-btn
              :disabled="getNotifList.length == 0"
              text
              icon
              @click="readAllNotifications()"
            >
              <v-icon color="red">mdi-delete-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="overflow-y-auto pa-0 ma-0" elevation="0" max-height="300">
        <v-card
          rounded="0"
          elevation="0"
          v-for="(item, i) in getNotifList.slice().reverse()"
          :key="i"
          :ripple="false"
          @click="openLink(item.linkTo)"
          class="messageCard overflow-x-hidden pl-2 pt-1 pr-2 pb-1"
        >
          <div class="textRow">
            <v-row>
              <v-col class="pb-0" md="6">
                <p class="ma-0">
                  <strong>{{ item.title }}</strong>
                </p>
              </v-col>
              <v-spacer />
              <v-col class="pb-0 pt-0" style="text-align: right">
                <v-btn
                  class="ma-0 mt-1"
                  text
                  icon
                  style="right: -10px"
                  v-on:click.stop="markNotificationRead(item.id)"
                >
                  <v-icon size="20" color="red">mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="mt-0 mb-0">
              <v-col class="pb-0">
                <p class="ma-0">{{ item.message }}</p>
              </v-col>
            </v-row>
          </div>

          <v-row class="mt-0 mb-1">
            <v-col class="pb-0" style="text-align: right">
              <div style="color: #757575; font-size: 1rem" class="ma-0">
                {{ formatTimeStamp(item.timestamp) }}
              </div>
            </v-col>
          </v-row>
          <v-divider class="pa-0 ma-0" color></v-divider>
        </v-card>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  GET_NOTIFICATIONS,
  MARK_NOTIFICATION_READ,
  READ_ALL_NOTIFICATIONS,
} from "@/store/action.type";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("notifications", ["getNotifList", "notifsFetched"]),
    darkTheme() {
      return this.$vuetify.theme.dark;
    },
  },
  created() {
    console.log(this.getNotifList);
  },
  methods: {
    ...mapActions("notifications", {
      getNotifications: GET_NOTIFICATIONS,
      markNotificationRead: MARK_NOTIFICATION_READ,
      readAllNotifications: READ_ALL_NOTIFICATIONS,
    }),
    // async callgetCall() {
    //   await this.getNotifications();
    // },
    formatTimeStamp(timestamp) {
      const date = timestamp.substring(5, 10);
      const time = timestamp.substring(11, 16);
      return date + " " + time;
    },
    openLink(url) {
      window.open(url);
      //this.$router.push(url).catch(() => {});
    },
  },
};
</script>

<style lang="css" scoped>
.messageCard:hover .textRow {
  font-weight: 600;
}
.messageCard::before {
  background: white;
  opacity: 0 !important;
}
.v-ripple__container {
  opacity: 0 !important;
}
</style>
