<template>
  <v-col xl="4" lg="3" md="6" sm="12" v-if="mainLead && mainLead.flow">
    <v-container fluid class="pl-0 pr-0">
      <v-card min-height="380">
        <v-row :style="cardHeaders" class="ma-0">
          <v-row class="pa-5">
            <v-col md="10">
              <v-row>
                <v-icon medium>mdi-text-box-check</v-icon>
                <h3>
                  <strong>{{ $t("profile_page.flow") }}</strong>
                </h3>
              </v-row>
            </v-col>
          </v-row>
        </v-row>
        <v-row class="mt-0">
          <v-col>
            <v-card
              class="pl-3 pr-3 overflow-y-auto"
              max-height="340"
              elevation="0"
            >
              <h4>{{ $t("profile_page.first_contact") }}</h4>
              <p
                style="display: inline"
                v-if="mainLead.flow.first_date"
                class="pl-3"
              >
                {{ toLocalDate(mainLead.flow.first_date) }}
              </p>
              <p class="pl-3" style="display: inline" v-else>
                {{ $t("profile_page.not_spoken_yet") }}
              </p>
              <h4>{{ $t("profile_page.intake") }}</h4>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        {{ $t("profile_page.date") }}
                      </th>
                      <th class="text-left">
                        {{ $t("profile_page.recruiter") }}
                      </th>
                      <th class="text-left">
                        {{ $t("profile_page.completed") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr key="1">
                      <td v-if="mainLead.flow.intake_date">
                        {{ toDate(mainLead.flow.intake_date) }}
                      </td>
                      <td v-else>-</td>
                      <td v-if="mainLead.flow.rec">
                        {{ fullName(mainLead.flow.rec) }}
                      </td>
                      <td v-else>-</td>
                      <td>
                        <v-checkbox
                          disabled
                          v-model="mainLead.flow.intake_completed"
                        ></v-checkbox>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <h4>{{ $t("profile_page.posted") }}</h4>
              <p
                style="display: inline"
                v-if="mainLead.flow.placed_date != undefined"
                class="pl-3"
              >
                {{ $t("profile_page.by") }}
                {{ fullName(mainLead.flow.cm) }}
                {{ $t("profile_page.on") }}
                {{ toLocalDate(mainLead.flow.placed_date).substring(0, 9) }}
              </p>
              <p class="pl-3" style="display: inline" v-else>
                {{ $t("profile_page.not_posted_yet") }}
              </p>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-col>
</template>
<script>
export default {
  props: ["mainLead", "cardHeaders"],
  methods: {
    toLocalDate(date) {
      var utcDate = new Date(date);
      const localeString = utcDate.toLocaleString("nl-NL");
      return localeString.substring(0, localeString.length - 3);
    },
    toDate(date) {
      let temp = new Date(date);
      // display date and time but with 1 hour less
      temp.setHours(temp.getHours() - 1);

      return temp.toDateString() + " " + temp.toLocaleTimeString();
    },

    fullName(emp) {
      return emp.first_name + " " + emp.last_name;
    },
  },
  created() {
    console.log("mainLead", this.mainLead);
  },
};
</script>
