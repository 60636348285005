import config from "../../../config";
import { authHeader } from "../../utils/auth-header";
import { handleResponse } from "../../utils/api_functions";
import { logger } from "../../utils/logger";

import {
  CREATE_VACANCY_FILTER,
  GET_VACANCY_FILTER_BY_ID,
  UPDATE_VACANCY_FILTER,
} from "../action.type";
import { SET_VACANCY_FILTERS } from "../mutation.type";

export const vacancy_filters = {
  namespaced: true,
  state: {
    filters: {},
  },
  getters: {
    getVacancyFilters: (state) => state.filters,
  },
  actions: {
    async [CREATE_VACANCY_FILTER]({ commit }, payload) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(payload),
        };
        await fetch(`${config.apiUrl}/createFilter`, request)
          .then(handleResponse)
          .then((res) => {
            commit(SET_VACANCY_FILTERS, res.filters);
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [GET_VACANCY_FILTER_BY_ID]({ commit }, filters_id) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        await fetch(`${config.apiUrl}/filters/${filters_id}`, request)
          .then(handleResponse)
          .then((res) => {
            commit(SET_VACANCY_FILTERS, res.filters);
          });
      } catch (error) {
        logger.error(error);
      }
    },

    async [UPDATE_VACANCY_FILTER]({ commit }, data) {
      try {
        const request = {
          method: "PUT",
          headers: authHeader(),
          body: JSON.stringify(data.filtersData),
        };
        await fetch(`${config.apiUrl}/filters/${data.id}`, request)
          .then(handleResponse)
          .then((res) => {
            commit(SET_VACANCY_FILTERS, res.filters);
          });
      } catch (error) {
        logger.error(error);
      }
    },
  },
  mutations: {
    [SET_VACANCY_FILTERS](state, filters) {
      state.filters = filters;
    },
  },
};
