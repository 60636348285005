<template>
  <v-container fluid class="pl-2 pr-2">
    <v-col class="pa-0">
      <v-card min-height="380">
        <v-row :style="cardHeaders" class="ma-0">
          <v-row class="pa-5">
            <v-col md="6">
              <v-row>
                <v-icon medium>mdi-account</v-icon>
                <h3>
                  <strong>{{
                    `${activeLead.first_name} ${activeLead.last_name}`
                  }}</strong>
                </h3>
              </v-row>
            </v-col>
          </v-row>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pt-0">
            <v-row class="pl-5 mt-0 pb-0">
              <v-col class="pb-0">
                <span>
                  <v-icon medium class="pr-1">mdi-phone</v-icon
                  >{{ activeLead.tel_number }}
                  <br />
                  <v-icon medium class="pr-1">mdi-email</v-icon
                  ><a :href="'mailto:' + activeLead.email">{{
                    activeLead.email
                  }}</a>
                  <br />

                  <p
                    class="mb-0"
                    v-if="
                      activeLead.address != undefined &&
                      activeLead.address.street != ''
                    "
                  >
                    <v-icon>mdi-home</v-icon>
                    {{ activeLead.address.street }},
                    {{ activeLead.address.postalcode }}
                    {{
                      activeLead.address.houseno +
                      (activeLead.address.addition
                        ? " " + activeLead.address.addition
                        : "")
                    }},
                    {{ activeLead.address.region }}
                  </p>
                  <p class="mb-0" v-else-if="activeLead.region != undefined">
                    <v-icon>mdi-home</v-icon> {{ activeLead.region }}
                  </p>
                  <p class="mb-0" v-else>
                    <v-icon>mdi-home</v-icon> Adres onbekend
                  </p>
                  <v-icon medium class="pr-0">mdi-calendar</v-icon>
                  <p style="display: inline" v-if="activeLead.dob != undefined">
                    {{ formatDate(activeLead.dob) }}
                  </p>
                  <p style="display: inline" v-else>Geb. onbekend</p>
                  <br />
                  <v-icon medium class="pr-0">mdi-account</v-icon>
                  <p
                    style="display: inline"
                    v-if="activeLead.gender != undefined"
                  >
                    {{ activeLead.gender }}
                  </p>
                  <p style="display: inline" v-else>Geslacht onbekend</p>
                  <br />
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="pr-1" v-bind="attrs" v-on="on" medium
                        >mdi-currency-eur</v-icon
                      >
                    </template>
                    <span>Salarisindicatie</span>
                  </v-tooltip>
                  <p
                    style="display: inline"
                    v-if="activeLead.sal_indication != undefined"
                  >
                    {{ activeLead.sal_indication }}
                  </p>
                  <p style="display: inline" v-else>Onbekend</p>
                  <br />
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="pr-1" v-bind="attrs" v-on="on" medium
                        >mdi-file-document-edit</v-icon
                      >
                    </template>
                    <span>Contracttermijn</span>
                  </v-tooltip>
                  <p
                    style="display: inline"
                    v-if="activeLead.contract_term != undefined"
                  >
                    {{ activeLead.contract_term }}
                  </p>
                  <p style="display: inline" v-else>Onbekend</p>
                  <br />
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="pr-1" v-bind="attrs" v-on="on" medium
                        >mdi-clock-time-nine</v-icon
                      >
                    </template>
                    <span>Betrekkingsomvang</span>
                  </v-tooltip>
                  <p
                    style="display: inline"
                    v-if="activeLead.contract_type != undefined"
                  >
                    {{ activeLead.contract_type }}
                  </p>
                  <p style="display: inline" v-else>Onbekend</p>
                </span>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-row class="mt-0">
              <v-col class="pt-0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="pr-3"
                      v-bind="attrs"
                      v-on="on"
                      :color="activeLead.car ? 'green' : 'red'"
                      medium
                      >mdi-car</v-icon
                    >
                  </template>
                  <span>{{
                    activeLead.car ? `Heeft een auto` : `Geen auto`
                  }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="pr-3"
                      v-bind="attrs"
                      v-on="on"
                      :color="activeLead.drivers_license ? 'green' : 'red'"
                      medium
                      >mdi-card-account-details</v-icon
                    >
                  </template>
                  <span>{{
                    activeLead.drivers_license
                      ? `Heeft rijbewijs`
                      : `Geen rijbewijs`
                  }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="pr-3"
                      v-bind="attrs"
                      v-on="on"
                      :color="activeLead.contract_signed ? 'green' : 'red'"
                      medium
                      >mdi-file-document-edit</v-icon
                    >
                  </template>
                  <span>{{
                    activeLead.contract_signed
                      ? `Contract getekend op ${formatDate(
                          activeLead.contract
                        )}`
                      : `Contract niet getekend`
                  }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="pr-1"
                      v-bind="attrs"
                      v-on="on"
                      :color="activeLead.bullhorn ? 'green' : 'red'"
                      medium
                      >mdi-bullhorn</v-icon
                    >
                  </template>
                  <span>{{
                    activeLead.bullhorn
                      ? `Staat in Bullhorn`
                      : `Staat niet in Bullhorn`
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <h4><v-icon class="pr-1">mdi-earth</v-icon>Talen</h4>
            <p
              v-if="
                activeLead.languages == undefined ||
                activeLead.languages[0] == undefined
              "
            >
              Geen talen bekend
            </p>
            <v-card
              class="overflow-y-auto"
              max-height="100"
              max-width="200"
              elevation="0"
            >
              <v-list disabled dense>
                <v-list-item
                  v-for="(item, i) in activeLead.languages"
                  :key="i"
                  class="pl-1 mb-1"
                  style="min-height: 0px"
                >
                  <v-list-item-icon class="ma-0 mr-1">
                    <CountryFlag :country="item.flag" />
                  </v-list-item-icon>
                  <v-list-item-content class="pt-0 pb-0 mt-0">
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="pl-3 pr-3 mt-0 pb-4" justify="center" align="center"
          ><v-col md="11"><v-divider></v-divider></v-col
        ></v-row>
        <v-row class="pl-5 pr-3 mt-0 pb-0">
          <v-col class="pt-0">
            <h4><v-icon>mdi-clipboard</v-icon>Notities</h4>
            <v-card class="overflow-y-auto pl-1" max-height="100" elevation="0">
              {{ activeLead.notes }}
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-container>
</template>

<script>
import CountryFlag from "vue-country-flag";
export default {
  components: {
    CountryFlag,
  },
  props: {
    activeLead: Object,
  },
  computed: {
    cardHeaders() {
      if (this.$vuetify.theme.dark) {
        return "background: #2a2a2a";
      } else {
        return "background: #b3b3b3";
      }
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
  },
};
</script>