<template>
  <v-container fluid>
    <v-dialog v-model="sendGridConfirmDialog" max-width="600">
      <v-card>
        <v-card-title
          style="background-color: #1976d2; color: white"
          class="text-h6 mb-3"
          >{{ $t("leads_page.add_to_sendgrid") }}</v-card-title
        >
        <v-card-text
          class="mt-4 pb-0"
          style="font-size: 16px; color: #52b69a; font-weight: 500"
          >{{
            $t("leads_page.upload_to", { sendGridUsers: sendGridUsers })
          }}:</v-card-text
        >
        <v-row class="mx-0 px-0 my-2" align="center">
          <v-col md="9">
            <v-select
              :items="getContactLists"
              v-model="selectedLists"
              class="pl-6"
              :label="$t('leads_page.select_list')"
              item-text="name"
              item-value="id"
              multiple
            ></v-select>
          </v-col>
          <v-col sm="3">
            <v-btn
              color="primary"
              tile
              class="ma-0"
              @click="createListDialog = true"
              >{{ $t("leads_page.new_list") }}</v-btn
            >
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="sendGridConfirmDialog = false">{{
            $t("leads_page.cancel")
          }}</v-btn>
          <v-btn
            :disabled="selectedLists.length === 0"
            color="primary"
            text
            @click="addToSendGrid"
            >{{ $t("leads_page.upload") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="createListDialog" max-width="290">
      <v-card class="pt-3 px-4">
        <v-text-field
          class=""
          :label="$t('leads_page.new_list_name')"
          v-model="newListName"
          :rules="[() => !!newListName || $t('leads_page.required_field')]"
        ></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="black" text @click="createListDialog = false">{{
            $t("leads_page.cancel")
          }}</v-btn>

          <v-btn
            :disabled="newListName === '' || newListName === null"
            color="primary"
            text
            @click="createNewList"
            >{{ $t("leads_page.create") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="sendGridUploadOverview" max-width="600">
      <v-card>
        <v-card-title
          style="background-color: #1976d2; color: white"
          class="text-h6 mb-3"
        >
          {{ $t("leads_page.overview") }}:
        </v-card-title>
        <v-card-text
          v-if="getInvalidUsers.length > 0"
          class="mt-4 pb-0"
          style="font-size: 16px; color: #ef233c; font-weight: 500"
          >{{
            $t("leads_page.failed_upload", {
              invalidUsers: getInvalidUsers.length,
            })
          }}</v-card-text
        >
        <v-card-text
          v-if="getInvalidUsers.length === 0"
          class="mt-4 pb-0"
          style="font-size: 16px; color: #52b69a; font-weight: 500"
          >{{ $t("leads_page.success_upload") }}.</v-card-text
        >
        <v-expansion-panels class="pa-2 px-4" v-if="getInvalidUsers.length > 0">
          <v-expansion-panel>
            <v-expansion-panel-header>{{
              $t("leads_page.invalid_users")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list>
                <template>
                  <v-list-item
                    v-for="users in getInvalidUsers"
                    :key="users.email"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="invalidUsersItem">
                        {{ users.first_name + " " + users.first_name }}:
                      </v-list-item-title>
                    </v-list-item-content>
                    {{ users.email }}
                  </v-list-item>
                </template>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="sendGridUploadOverview = false">{{
            $t("leads_page.close")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCreate" max-width="600px">
      <CreateLead
        v-on:close-dialog="dialogCreate = false"
        type="0"
        :period="this.filters.application_dates"
      />
    </v-dialog>
    <v-dialog v-model="dialogAddContactMoment" max-width="600px">
      <v-card>
        <CreateContactMoment
          v-if="selectedRow"
          v-on:close-dialog="dialogAddContactMoment = false"
          v-on:reload="reload"
          type="0"
          :parentID="selectedRow.user_id"
        ></CreateContactMoment>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogPlace" max-width="600px">
      <PlaceLead
        v-on:close-dialog="dialogPlace = false"
        :leadID="activeLeadId"
        :leadUserID="activeLeadUserId"
        :cmID="activeFlowCmId"
      />
    </v-dialog>
    <v-dialog v-model="dialogIntake" max-width="600px">
      <Intake
        v-on:close-dialog="dialogIntake = false"
        :leadID="activeLeadId"
        :leadUserID="activeLeadUserId"
      />
    </v-dialog>
    <v-dialog v-model="dialogColumns" max-width="600px">
      <SelectColumns
        :headers="headers"
        type="Leads"
        v-on:headers-changed="setHeaders($event)"
        v-on:close-dialog="dialogColumns = false"
      />
    </v-dialog>
    <v-dialog v-model="dialogUndoContactMoment" max-width="400px">
      <v-card>
        <v-card-title>
          <span class="text-h6">Undo contact moment for this user?</span>
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogUndoContactMoment = false"> No </v-btn>
          <v-btn color="red darken-1" text @click="undoContactMoment()">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-bottom-sheet v-model="dialogSpeak" inset>
      <v-sheet class="text-center">
        <SpokenDatePicker
          :type="5"
          v-on:close-dialog="dialogSpeak = false"
          :leadID="activeLeadId"
          :leadUserID="activeLeadUserId"
          :leadName="activeLeadName"
          :leadContactNo="activeLeadContactNo"
          :vmCount="activeLeadVmCount"
          :additional="leadsPeriodString"
        />
      </v-sheet>
    </v-bottom-sheet>
    <v-card class="pa-4" :loading="!loaded">
      <v-row>
        <v-col md="8">
          <v-row class="pa-3">
            <h2>{{ $t("navbar.leads") }}</h2>
            <v-divider
              vertical
              inset
              style="padding-left: 10px; padding-left: 10px"
            ></v-divider>
            <v-text-field
              style="padding-top: 0px; padding-left: 10px"
              v-model="filters.search"
              append-icon="mdi-magnify"
              :label="$t('leads_page.search')"
              single-line
              hide-details
              @change="storeFilterToState"
            ></v-text-field>
          </v-row>
        </v-col>
        <v-col align="end" md="4">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                class="mb-2"
                @click="sendGridInfo"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon dark>mdi-email</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("leads_page.export_to_sendgrid") }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                class="mb-2"
                @click="dialogColumns = true"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon dark>mdi-bookmark</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("common_phrases.columns") }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                class="mb-2"
                @click="excelExport()"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon dark>mdi-table-arrow-right</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("common_phrases.export") }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                class="mb-2"
                @click="reload"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon dark>mdi-reload</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("common_phrases.refresh") }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                class="mb-2"
                @click="dialogCreate = true"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon dark>mdi-plus-box</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("leads_page.new_lead") }}</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-menu
        v-model="showMenu"
        :position-x="x"
        :position-y="y"
        absolute
        offset-y
      >
        <v-list dense>
          <v-list-item @click="open" dense>
            <v-list-item-title>{{ $t("leads_page.open") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="openNewTab" dense>
            <v-list-item-title>{{
              $t("leads_page.new_tab")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="dialogAddContactMoment = true" dense>
            <v-list-item-title>{{
              $t("leads_page.add_contact_moment")
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-data-table
        v-if="loaded"
        :page.sync="pagination.page"
        :items-per-page="pagination.itemsPerPage"
        :footer-props="{ itemsPerPageOptions: [20, 35, 50, -1] }"
        :items="leadsAdjustedList"
        :headers="headers"
        :search="filters.search"
        :custom-sort="customSort"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        class="elevation-1"
        @contextmenu:row="show"
        @pagination="storePaginationToState"
        @current-items="getFiltered"
        @update:sort-by="updateSortBy"
        @update:sort-desc="updateSortDesc"
      >
        <template v-slot:top>
          <v-expansion-panels focusable flat :value="collapsed">
            <v-expansion-panel>
              <v-expansion-panel-header class="px-4 pt-4"
                ><h2>{{ $t("common_phrases.filter") }}</h2>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container fluid>
                  <v-row dense>
                    <v-col align="end" class="mr-2 mt-2 mb-0">
                      <v-btn icon color="red" @click="clearFilters()"
                        ><span class="mr-1">{{
                          $t("common_phrases.clear")
                        }}</span
                        ><v-icon dark>mdi-backspace</v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-autocomplete
                        dense
                        class="pt-1"
                        :items="lead_statuses"
                        v-model="filters.statuses"
                        chips
                        deletable-chips
                        multiple
                        item-text="name"
                        return-object
                        :label="$t('leads_page.status')"
                        @change="storeFilterToState"
                        ><template
                          v-slot:selection="{ attr, on, item, selected }"
                        >
                          <v-chip
                            label
                            v-bind="attr"
                            :input-value="selected"
                            v-on="on"
                            close
                            :color="item.color"
                            @click:close="remove(item)"
                            ><div style="color: black">
                              <strong
                                >{{ item.phase }} - {{ item.name }}</strong
                              >
                            </div></v-chip
                          >
                        </template>
                        <template v-slot:item="data">
                          <template v-if="typeof data.item == 'object'">
                            <v-list-item-content
                              >{{ data.item.phase }} -
                              {{ data.item.name }}</v-list-item-content
                            >
                          </template>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col>
                      <v-autocomplete
                        dense
                        class="pt-1"
                        :items="computedSubStatuses"
                        v-model="filters.sub_statuses"
                        chips
                        deletable-chips
                        multiple
                        item-text="name"
                        return-object
                        :label="$t('leads_page.sub_status')"
                        no-data-text="Kies eerst een status om op te filteren"
                        @change="storeFilterToState"
                        ><template
                          v-slot:selection="{ attr, on, item, selected }"
                        >
                          <v-chip
                            label
                            v-bind="attr"
                            :input-value="selected"
                            v-on="on"
                            close
                            :color="item.color"
                            @click:close="removeSubStatus(item)"
                            ><div style="color: black">
                              <strong>{{ item.name }}</strong>
                            </div></v-chip
                          >
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col>
                      <v-autocomplete
                        :items="companies"
                        v-model="filters.company"
                        clearable
                        item-text="name"
                        item-value="name"
                        :label="$t('leads_page.company')"
                        @change="storeFilterToState"
                      ></v-autocomplete>
                    </v-col>
                    <v-col>
                      <v-autocomplete
                        :items="vacancies_all_getter"
                        v-model="filters.vacancy"
                        clearable
                        item-text="name"
                        item-value="name"
                        :label="$t('leads_page.vacancy')"
                        @change="storeFilterToState"
                      ></v-autocomplete>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="filters.region"
                        append-icon="mdi-magnify"
                        :label="$t('leads_page.place')"
                        single-line
                        hide-details
                        @change="storeFilterToState"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="filters.campaign"
                        append-icon="mdi-magnify"
                        :label="$t('leads_page.campaign_id')"
                        single-line
                        hide-details
                        @change="storeFilterToState"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-menu
                        v-model="sollicitatieperiodeMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        content-class="hide-y-scroll"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="computedDateRangeApplication"
                            :label="$t('leads_page.application_period')"
                            prepend-icon="mdi-calendar"
                            readonly
                            clearable
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>

                        <EnhancedDatePicker
                          @setDates="setApplicationDates"
                          @menuToggle="
                            sollicitatieperiodeMenu = !sollicitatieperiodeMenu
                          "
                        />
                      </v-menu>
                    </v-col>
                    <v-col>
                      <v-menu
                        v-model="contactperiodeMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        content-class="hide-y-scroll"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="computedDateRangeContact"
                            :label="$t('leads_page.contact_period')"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            clearable
                          ></v-text-field>
                        </template>
                        <EnhancedDatePicker
                          @setDates="setContactDates"
                          @menuToggle="contactperiodeMenu = !contactperiodeMenu"
                        />
                      </v-menu>
                    </v-col>
                    <v-col>
                      <v-menu
                        v-model="intakeperiodeMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        content-class="hide-y-scroll"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="computedIntakeDateRangeApplication"
                            :label="$t('leads_page.intake_date_period')"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            clearable
                          ></v-text-field>
                        </template>

                        <EnhancedDatePicker
                          @setDates="setIntakeDates"
                          @menuToggle="intakeperiodeMenu = !intakeperiodeMenu"
                        />
                      </v-menu>
                    </v-col>
                    <v-col>
                      <v-autocomplete
                        :items="employees"
                        v-model="filters.leadRecruiterAss"
                        clearable
                        item-text="full_name"
                        item-value="full_name"
                        :label="$t('leads_page.recruitment_assistant')"
                      ></v-autocomplete>
                    </v-col>
                    <v-col>
                      <v-autocomplete
                        :items="employees"
                        v-model="filters.leadRecruiter"
                        clearable
                        item-text="full_name"
                        item-value="full_name"
                        :label="$t('leads_page.recruiter')"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0" dense>
                    <v-col md="2" class="mr-3">
                      <v-autocomplete
                        :items="channels"
                        v-model="filters.channels"
                        clearable
                        multiple
                        item-text="name"
                        item-value="name"
                        :label="$t('leads_page.channel')"
                        @change="storeFilterToState"
                      ></v-autocomplete>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="filters.phase"
                        append-icon="mdi-magnify"
                        :label="$t('leads_page.phase')"
                        single-line
                        hide-details
                        @change="storeFilterToState"
                      ></v-text-field>
                    </v-col>
                    <v-col md="6" class="mt-3">
                      <v-row>
                        <v-checkbox
                          class="mr-3"
                          @change="storeFilterToState"
                          :label="$t('leads_page.blacklist')"
                          v-model="filters.blacklisted"
                        ></v-checkbox
                        ><v-checkbox
                          class="mr-3"
                          @change="storeFilterToState"
                          :label="$t('leads_page.favorite')"
                          v-model="filters.favorite"
                        ></v-checkbox>
                        <v-checkbox
                          class="mr-3"
                          @change="storeFilterToState"
                          :label="$t('leads_page.liked')"
                          v-model="filters.liked"
                        ></v-checkbox>
                        <MultiCheckBox
                          @change="storeFilterToState"
                          class="mr-5 mt-6"
                          v-model="filters.whatsapp"
                          :label="$t('leads_page.whatsapp')"
                        />

                        <MultiCheckBox
                          @change="storeFilterToState"
                          class="mr-3 mt-6"
                          v-model="filters.intake_completed"
                          :label="$t('leads_page.intake_copleted')"
                        />
                      </v-row>
                    </v-col>
                    <v-spacer />
                    <v-col align="end" class="mr-0 pr-0">
                      <v-checkbox
                        left
                        class="mr-1"
                        @change="storeFilterToState"
                        :label="$t('leads_page.open_in_tab')"
                        v-model="filters.newTab"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="columnSelectOpen(item)">
            mdi-open-in-new
          </v-icon>

          <v-tooltip right color="black" max-width="27%">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                class="mx-2"
                small
                v-bind="attrs"
                v-on="on"
              >
                mdi-binoculars
              </v-icon>
            </template>
            <span>{{ item.notes || "Geen notities" }}</span>
          </v-tooltip>
          <v-chip
            label
            v-if="item.leads_count > 1"
            color="red"
            text-color="white"
            small
            class="mr-1"
            >{{ item.leads_count }}</v-chip
          >
          <v-icon v-if="item.blacklisted" color="red" small class="mr-2">
            mdi-close-thick
          </v-icon>
          <v-icon v-if="item.favorite" color="yellow" small class="mr-2">
            mdi-star
          </v-icon>
          <v-icon v-if="item.liked" color="red" small class="mr-2">
            mdi-cards-heart
          </v-icon>
          <v-chip
            label
            v-if="item.perma_status"
            :color="item.perma_status.color"
            ><div
              :style="`color: ${calculateTextColor(item.perma_status.color)}`"
            >
              <strong>{{ item.perma_status.name }}</strong>
            </div></v-chip
          >
        </template>
        <template v-slot:[`item.status_name`]="{ item }">
          <v-chip label :color="item.status_color"
            ><div :style="`color: ${calculateTextColor(item.status_color)}`">
              <strong>{{ item.status_name }}</strong>
            </div></v-chip
          >
        </template>
        <template v-slot:[`item.sub_statuses`]="{ item }">
          <div v-for="i in item.sub_statuses" :key="i.id">
            <v-chip label class="mt-1" :color="i.sub_status.color"
              ><strong>{{ i.sub_status.name }}</strong></v-chip
            >
          </div>
        </template>
        <template v-slot:[`item.first_date`]="{ item }">
          <span v-if="item.first_date != null" id="green-phone">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  elevation="0"
                  color="green"
                  small
                  @click="openUndoDialog(item)"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="green"> mdi-phone </v-icon>
                </v-btn>
              </template>
              <span>{{ item.first_date }}</span>
            </v-tooltip>
          </span>
          <v-badge
            v-else-if="item.vm_date != null"
            bordered
            color="error"
            :content="item.vm_count"
            overlap
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  elevation="0"
                  color="error"
                  small
                  @click="openFlowSpoken(item)"
                  class="pl-1 pr-3"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-phone-missed</v-icon>
                </v-btn>
              </template>
              <span>{{ item.vm_date }}</span>
            </v-tooltip>
          </v-badge>
          <v-btn
            v-else
            elevation="0"
            color="primary"
            small
            @click="openFlowSpoken(item)"
            icon
          >
            <v-icon color="red" dark> mdi-phone </v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.whatsapp`]="{ item }" style="padding-left: 0px">
          <div class="text-center" v-if="item.whatsapp == null">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon color="red">
                  <v-icon>mdi-whatsapp</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="setMessageLanguage(item, 'en')">
                  <v-list-item-title>English</v-list-item-title>
                </v-list-item>
                <v-list-item @click="setMessageLanguage(item, 'nl')">
                  <v-list-item-title>Dutch</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <v-btn
            v-else
            icon
            color="red"
            @contextmenu.prevent.stop="onRightClick(item)"
          >
            <v-icon color="green">mdi-whatsapp</v-icon>
          </v-btn>
        </template>
        <!-- custom column for phase of the lead -->
        <template v-slot:[`item.phase`]="{ item }">
          <p class="pr-4" v-if="item.phase != null">{{ item.phase }}</p>
          <p class="pr-4" v-else>-</p>
        </template>
        <template
          v-slot:[`item.intake_completed`]="{ item }"
          style="padding-left: 0px"
        >
          <v-icon v-if="item.intake_completed" color="green"
            >mdi-check-circle</v-icon
          >
          <v-icon v-if="item.intake_completed === false" color="red"
            >mdi-close-circle</v-icon
          >
          <v-chip
            label
            color="transparent"
            v-if="item.intake_completed === null"
          >
            No Intake
          </v-chip>
        </template>
        <template
          v-slot:[`item.intake_date`]="{ item }"
          style="padding-left: 0px"
        >
          <v-chip label color="transparent" v-if="item.intake_date">{{
            item.intake_date
          }}</v-chip>
          <v-chip label color="transparent" v-if="item.intake_date === null">
            No Intake
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import CreateLead from "../components/dialogs/CreateLead";
import CreateContactMoment from "../components/dialogs/CreateContactMoment";
import PlaceLead from "../components/dialogs/PlaceLead";
import Intake from "../components/dialogs/Intake";
import SpokenDatePicker from "../components/dialogs/SpokenDatePicker";
import SelectColumns from "../components/dialogs/SelectColumns.vue";
import { mapState, mapGetters } from "vuex";
import EnhancedDatePicker from "../components/dialogs/EnhancedDatePicker.vue";
import { permission } from "../utils/permissions";
import CALL_STATUSES from "../utils/call_statuses";
import MultiCheckBox from "@/components/Selectors/MultiCheckBox.vue";
import {
  CREATE_CONTACT_LIST,
  CREATE_CONTACT_MOMENT,
  GET_ALL_CONTACT_LISTS,
  GET_CHANNELS,
  GET_LEADS_PER_PERIOD,
  GET_LEAD_STATUSES,
  GET_USERS,
  SEND_CONTACTS,
  SYNC_LISTS,
  UPDATE_EMPLOYEE,
  UPDATE_FLOW,
  CREATE_CONVERSATION,
  UPDATE_LEAD_USER,
  DELETE_FIRST_CONTACT_MOMENT,
  GET_VACANCIES_ALL,
} from "../store/action.type";
import { mapActions, mapMutations } from "vuex";
import {
  SET_LEADS_PAGINATION,
  SET_LEAD_FILTER,
  SET_LEAD_SORT_BY,
  SET_LEAD_SORT_DESC,
} from "../store/mutation.type";
export default {
  components: {
    CreateLead,
    CreateContactMoment,
    PlaceLead,
    Intake,
    SpokenDatePicker,
    SelectColumns,
    EnhancedDatePicker,
    MultiCheckBox,
  },
  data: () => ({
    selectedLanguage: "nl",
    sendGridConfirmDialog: false,
    sendGridUploadOverview: false,
    createListDialog: false,
    newListName: null,
    selectedLists: [],
    sendGridUsers: 0,
    loaded: false,
    excelExporting: false,
    sortBy: "applied_date",
    sortDesc: true,
    dialogCreate: false,
    dialogSpeak: false,
    dialogIntake: false,
    dialogPlace: false,
    dialogAddContactMoment: false,
    dialogColumns: false,
    appliedMenu: false,
    collapsed: 0,
    activeLeadId: null,
    activeLeadUserId: null,
    activeLeadName: null,
    activeLeadContactNo: null,
    activeFlowCmId: null,
    activeLeadVmCount: null,
    leadsPeriodString: null,
    filters: {},
    selectedRow: null,
    showMenu: false,
    sollicitatieperiodeMenu: false,
    contactperiodeMenu: false,
    intakeperiodeMenu: false,
    x: 0,
    y: 0,
    filteredLeadUsers: [],
    selectedHeaders: [],
    //* Undo Contact Moment
    userToEdit: null,
    dialogUndoContactMoment: false,
  }),

  computed: {
    ...mapGetters("vacancy_flow", ["vacancies_all_getter"]),
    ...mapGetters("leads", ["getLeadsForPeriod"]),
    ...mapGetters("send_grid", ["getInvalidUsers", "getContactLists"]),

    computedDateRangeApplication: {
      get() {
        return this.splitDateRange(this.filters.application_dates);
      },
      set() {
        return null;
      },
    },
    computedDateRangeContact: {
      get() {
        return this.splitDateRange(this.filters.contact_dates);
      },
      set() {
        this.filters.contact_dates = [];
        this.fetchLeadsForPeriod();
      },
    },
    computedIntakeDateRangeApplication: {
      get() {
        return this.splitDateRange(this.filters.intake_dates);
      },
      set() {
        this.filters.intake_dates = [];
        this.fetchLeadsForPeriod();
      },
    },

    headers() {
      let headers = [
        {
          id: 1,
          text: this.$t("leads_page.action"),
          value: "actions",
          sortable: false,
          perma: true,
          width: "13%",
        },
        {
          id: 26,
          text: "",
          value: "leads_count",
          align: " d-none",
          hide: true,
        },
        {
          id: 2,
          text: this.$t("leads_page.blacklist"),
          value: "blacklisted",
          align: " d-none",
          hide: true,
          filter: (value) => {
            if (value == null) {
              value = false;
            }
            if (this.filters.blacklisted == null) {
              return true;
            }
            if (this.filters.blacklisted == value) {
              return true;
            }
            return value == true;
          },
        },
        {
          id: 3,
          text: this.$t("leads_page.favorite"),
          value: "favorite",
          align: " d-none",
          hide: true,
          filter: (value) => {
            if (value == null) {
              value = false;
            }
            if (this.filters.favorite == null) {
              return true;
            }
            if (this.filters.favorite == value) {
              return true;
            }
            return value == true;
          },
        },
        {
          id: 4,
          text: this.$t("leads_page.liked"),
          value: "liked",
          align: " d-none",
          hide: true,
          filter: (value) => {
            if (value == null) {
              value = false;
            }
            if (this.filters.liked == null) {
              return true;
            }
            if (this.filters.liked == value) {
              return true;
            }
            return value == true;
          },
        },
        {
          id: 5,
          text: this.$t("leads_page.application_date"),
          value: "applied_date",
          perma: true,
        },
        {
          id: 6,
          text: this.$t("leads_page.status"),
          value: "status_name",
          perma: true,
          filter: (value) => {
            if (!this.filters.statuses) {
              return true;
            }
            if (this.filters.statuses.length <= 0) {
              return true;
            }
            return this.filters.statuses.some((elem) => elem.name === value);
          },
        },
        {
          id: 7,
          text: this.$t("leads_page.sub_status"),
          value: "sub_statuses",
          align: " d-none",
          filter: (value) => {
            if (!this.filters.sub_statuses) {
              return true;
            }
            if (this.filters.sub_statuses.length <= 0) {
              return true;
            }
            for (let i = 0; i < this.filters.sub_statuses.length; i++) {
              for (let x = 0; x < value.length; x++) {
                if (
                  this.filters.sub_statuses[i].name === value[x].sub_status.name
                ) {
                  return true;
                }
              }
            }
          },
        },
        { id: 8, text: "Naam", value: "user_name", perma: true },
        {
          id: 9,
          text: this.$t("leads_page.place"),
          value: "region",
          align: " d-none",
          filter: (value) => {
            if (!this.filters.region) return true;
            if (!value) return false;
            return value
              .toLowerCase()
              .includes(this.filters.region.toLowerCase());
          },
        },
        {
          id: 10,
          text: this.$t("leads_page.spoke_to"),
          value: "rec_ass_full_name",
          align: " d-none",
          filter: (value) => {
            if (!this.filters.recruiter_assistent) return true;
            return value === this.filters.recruiter_assistent;
          },
        },
        {
          id: 11,
          text: this.$t("leads_page.intake_by"),
          value: "rec_full_name",
          align: " d-none",
          filter: (value) => {
            if (!this.filters.recruiter) return true;
            return value === this.filters.recruiter;
          },
        },
        {
          id: 12,
          text: this.$t("leads_page.recruitment_assistant"),
          value: "lead_ra",
          align: " d-none",
          filter: (value) => {
            if (!this.filters.leadRecruiterAss) return true;
            return value === this.filters.leadRecruiterAss;
          },
        },
        {
          id: 13,
          text: this.$t("leads_page.recruiter"),
          value: "lead_rec",
          align: " d-none",
          filter: (value) => {
            if (!this.filters.leadRecruiter) return true;
            return value === this.filters.leadRecruiter;
          },
        },
        {
          id: 14,
          text: this.$t("leads_page.last_contact_moment"),
          value: "contact_moment",
          align: " d-none",
        },
        {
          id: 15,
          text: this.$t("leads_page.campaign_id"),
          value: "campaign",
          align: " d-none",
          filter: (value) => {
            if (!value && this.filters.campaign) return false;
            if (!this.filters.campaign) return true;
            return (
              value.toString().toLowerCase() ===
              this.filters.campaign.toLowerCase()
            );
          },
        },
        {
          id: 27,
          text: this.$t("leads_page.phase"),
          value: "phase",
          align: "center",
          filter: (value) => {
            if (!value && this.filters.phase) return false;
            if (!this.filters.phase) return true;
            return (
              value.toString().toLowerCase() ===
              this.filters.phase.toLowerCase()
            );
          },
        },
        {
          id: 16,
          text: this.$t("leads_page.tel"),
          align: " d-none",
          value: "tel_number",
        },
        {
          id: 17,
          text: this.$t("leads_page.vacancy"),
          value: "vacancy",
          align: " d-none",
          filter: (value) => {
            if (!this.filters.vacancy) return true;
            return value === this.filters.vacancy;
          },
        },
        {
          id: 18,
          text: this.$t("leads_page.company"),
          value: "company",
          align: " d-none",
          filter: (value) => {
            if (!this.filters.company) return true;
            return value === this.filters.company;
          },
        },
        {
          id: 19,
          text: this.$t("leads_page.channel"),
          value: "channel",
          align: " d-none",
          filter: (value) => {
            if (!this.filters.channels) return true;
            if (this.filters.channels.length <= 0) return true;
            return this.filters.channels.some((elem) => elem === value);
          },
        },
        {
          id: 20,
          text: this.$t("leads_page.intake_date"),
          value: "intake_date",
          sortable: true,
          align: " d-none",
        },
        {
          id: 21,
          text: this.$t("leads_page.intake_completed"),
          value: "intake_completed",
          sortable: true,
          align: " d-none",
          filter: (value) => {
            if (this.filters.intake_completed == null) {
              return true;
            }
            switch (this.filters.intake_completed) {
              case -1:
                if (value == false) {
                  return true;
                }
                break;
              case 0:
                return true;
              case 1:
                if (value == true) {
                  return true;
                }
                break;
            }
            return false;
          },
        },
        {
          id: 22,
          text: this.$t("leads_page.user_id"),
          value: "lead_user_id",
          sortable: true,
          align: " d-none",
        },

        {
          id: 23,
          text: this.$t("leads_page.dial"),
          value: "first_date",
          sortable: true,
          perma: true,
          width: 85,
          align: "center",
        },
        {
          id: 24,
          text: this.$t("leads_page.app"),
          value: "whatsapp",
          sortable: false,
          perma: true,
          width: 10,
          align: "center",
          filter: (value) => {
            if (value == null) {
              value = false;
            }
            if (this.filters.whatsapp == null) {
              return true;
            }
            switch (this.filters.whatsapp) {
              case -1:
                if (value == false) {
                  return true;
                }
                break;
              case 0:
                return true;
              case 1:
                if (value == true) {
                  return true;
                }
                break;
            }
            return false;
          },
        },
      ];
      for (let i = 0; i < headers.length; i++) {
        if (this.selectedHeaders.some((elem) => headers[i].id === elem)) {
          headers[i].align = "";
        }
      }
      return headers;
    },

    leadsAdjustedList() {
      return this.leads.map((e) => {
        if (e.id === 16071) {
          console.log(e);
        }
        let mapped = {
          id: e.id,
          lead_rec: this.fullName(e.recruiter),
          lead_ra: this.fullName(e.recruitment_assistant),
          user_id: e.user.id,
          first_name: e.user.last_name.trim(),
          last_name: e.user.first_name.trim(),
          user_name: this.cleanUserName(e.user.first_name, e.user.last_name),
          tel_number: e.user.tel_number,
          mail: e.user.email,
          notes: e.user.notes,
          campaign: e.campaign_id,
          status_name: this.latestStatus(e).name,
          status_color: this.latestStatus(e).color,
          sub_statuses: this.subStatuses(e),
          contact_moment: this.latestContact(e.user),
          vacancy: e.vacancy.name,
          company: e.vacancy.Company?.name,
          channel: e.channel.name,
          applied_date: this.formatDate(e.applied_date),
          region: e.user.region,
          first_date: null,
          vm_date: null,
          intake_date: this.getFlowIntakeDate(e.flow),
          intake_completed: this.getFlowIntakeStatus(e.flow),
          lead_user_id: e.user.id,
          placed_date: null,
          rec: null,
          rec_full_name: null,
          rec_ass_full_name: null,
          flow: false,
          favorite: e.user.favorite,
          blacklisted: e.user.blacklisted,
          liked: e.user.liked,
          phase: e.phase,
          leads_count: e.user.leadCount,
          perma_status: e.user.perma_status,
          callState() {
            if (this.first_date != null) {
              return CALL_STATUSES.answeredCall;
            } else if (this.vm_date != null) {
              return CALL_STATUSES.voicemailCall;
            } else {
              return CALL_STATUSES.rejectedCall;
            }
          },
        };

        if (e.flow != null) {
          mapped.first_date = this.toLocalDate(e.flow.first_date);
          mapped.vm_date = this.toLocalDate(e.flow.vm_date);
          mapped.placed_date = this.toLocalDate(e.flow.placed_date);
          mapped.rec = e.flow.rec;
          mapped.rec_full_name = this.fullName(e.flow.rec);
          mapped.rec_ass_full_name = this.fullName(e.flow.rec_ass);
          mapped.placed_cm = e.flow.placed_cm;
          mapped.vm_count = e.flow.vm_count;
          mapped.flow = true;
          mapped.whatsapp = e.flow.app_sent;
        }

        return mapped;
      });
    },
    ...mapState({
      leadSortBy: (state) => state.filters.leadSortBy,

      leadSortDesc: (state) => state.filters.leadSortDesc,

      stored_filters: (state) => state.filters.lead_filter,

      leads: (state) => state.leads.leadsForPeriod,

      lead_statuses: (state) =>
        state.statuses.lead_statuses.sort((a, b) => {
          if (a.phase < b.phase) {
            return -1;
          }
          if (a.phase > b.phase) {
            return 1;
          }
          return 0;
        }),

      // // lead statuses ordered by id
      // lead_statuses_ordered: (state) =>
      //   state.statuses.lead_statuses.sort((a, b) => {
      //     if (a.id < b.id) {
      //       return -1;
      //     }
      //     if (a.id > b.id) {
      //       return 1;
      //     }
      //     return 0;
      //   }),

      companies: (state) =>
        state.filters.lists.companies.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        }),

      employees: (state) =>
        state.filters.lists.employees
          .filter((e) => e.first_name != null)
          .sort((a, b) => {
            if (a.first_name < b.first_name) {
              return -1;
            }
            if (a.first_name > b.first_name) {
              return 1;
            }
            return 0;
          })
          .map((e) => {
            return {
              id: e.id,
              full_name: e.first_name + " " + e.last_name,
            };
          }),

      channels: (state) => state.leads.channels,

      pagination: (state) => state.leads.pagination,

      currentUser: (state) => state.users.user,
    }),
    computedSubStatuses: {
      get() {
        let sub_statuses = [];
        if (this.filters.statuses) {
          for (let i = 0; i < this.filters.statuses.length; i++) {
            for (
              let x = 0;
              x < this.filters.statuses[i].sub_statuses.length;
              x++
            ) {
              sub_statuses.push(this.filters.statuses[i].sub_statuses[x]);
            }
          }
        }
        return sub_statuses;
      },
      set() {
        return null;
      },
    },
  },

  created() {
    this.syncLists(true);
    this.filters = this.stored_filters;
    this.initialize();
    this.sortBy = this.leadSortBy;
    if (this.leadSortDesc) {
      this.sortDesc = this.leadSortDesc;
    } else {
      this.sortDesc = true;
    }
  },

  methods: {
    ...mapActions("contact_moments", {
      createContactMoment: CREATE_CONTACT_MOMENT,
      deleteFirstContactMoment: DELETE_FIRST_CONTACT_MOMENT,
    }),
    ...mapActions("filters", {
      syncLists: SYNC_LISTS,
    }),
    ...mapActions("flow", { updateFlow: UPDATE_FLOW }),
    ...mapActions("leads", {
      getLeadsPerPeriod: GET_LEADS_PER_PERIOD,
      getChannels: GET_CHANNELS,
    }),
    ...mapActions("send_grid", {
      sendContacts: SEND_CONTACTS,
      getAllContactLists: GET_ALL_CONTACT_LISTS,
      createContactList: CREATE_CONTACT_LIST,
    }),
    ...mapActions("statuses", {
      getLeadStatuses: GET_LEAD_STATUSES,
    }),
    ...mapActions("users", {
      getUsers: GET_USERS,
      updateEmployee: UPDATE_EMPLOYEE,
    }),
    ...mapMutations("filters", {
      setLeadFilter: SET_LEAD_FILTER,
      setLeadSortBy: SET_LEAD_SORT_BY,
      setLeadSortDesc: SET_LEAD_SORT_DESC,
    }),
    ...mapActions("whats_app", {
      createConversation: CREATE_CONVERSATION,
    }),
    ...mapActions("lead_users", {
      updateLeadUser: UPDATE_LEAD_USER,
    }),
    ...mapActions("vacancy_flow", {
      getVacanciesAll: GET_VACANCIES_ALL,
    }),
    ...mapMutations("leads", { setLeadsPagination: SET_LEADS_PAGINATION }),

    async initialize() {
      let now = new Date();
      now = now.toISOString().substring(0, 10);
      let past = new Date();
      past.setDate(past.getDate() - 30);
      past = past.toISOString().substring(0, 10);

      let params = {
        applicationStartDate: past,
        applicationEndDate: now,
        contactStartDate: null,
        contactEndDate: null,
        intakeStartDate: null,
        intakeEndDate: null,
      };

      let temp = {
        application_dates: [past, now],
        contact_dates: [],
        intake_dates: [],
      };

      if (Object.keys(this.filters).length === 0) {
        this.filters = temp;
      } else {
        if (this.filters.application_dates.length !== 2) {
          this.filters.application_dates = temp.application_dates;
        } else {
          if (
            this.filters.application_dates[0] >
            this.filters.application_dates[1]
          ) {
            params = {
              applicationStartDate: this.filters.application_dates[1],
              applicationEndDate: this.filters.application_dates[0],
            };
          } else {
            params = {
              applicationStartDate: this.filters.application_dates[0],
              applicationEndDate: this.filters.application_dates[1],
            };
          }
        }
      }

      if (Object.keys(this.getLeadsForPeriod).length === 0) {
        await this.getLeadsPerPeriod({ params });
      }
      this.storeFilterToState();

      await this.getLeadStatuses();
      await this.getChannels();
      await this.getVacanciesAll();
      // await this.getUsers();
      if (this.currentUser.lead_columns != null) {
        this.selectedHeaders = JSON.parse(this.currentUser.lead_columns);
      } else {
        let employee = this.currentUser;
        let empId = this.currentUser.id;
        employee.lead_columns = JSON.stringify(this.selectedHeaders);
        await this.updateEmployee({ employee, empId });
      }
      this.loaded = true;
    },

    async reload() {
      this.loaded = false;
      this.syncLists(true);
      let now = new Date();
      now = now.toISOString().substring(0, 10);
      let past = new Date();
      past.setDate(past.getDate() - 30);
      past = past.toISOString().substring(0, 10);

      let params = {
        applicationStartDate: past,
        applicationEndDate: now,
        contactStartDate: null,
        contactEndDate: null,
        intakeStartDate: null,
        intakeEndDate: null,
      };

      let temp = {
        application_dates: [past, now],
        contact_dates: [],
        intake_dates: [],
      };

      if (Object.keys(this.filters).length === 0) {
        this.filters = temp;
      } else {
        if (this.filters.application_dates.length !== 2) {
          this.filters.application_dates = temp.application_dates;
        } else {
          if (
            this.filters.application_dates[0] >
            this.filters.application_dates[1]
          ) {
            params = {
              applicationStartDate: this.filters.application_dates[1],
              applicationEndDate: this.filters.application_dates[0],
            };
          } else {
            params = {
              applicationStartDate: this.filters.application_dates[0],
              applicationEndDate: this.filters.application_dates[1],
            };
          }
        }
      }

      await this.getLeadsPerPeriod({ params });

      this.storeFilterToState();

      await this.getLeadStatuses();

      //await this.getUsers();
      this.loaded = true;
    },

    async addToSendGrid() {
      let pag = {
        itemsPerPage: -1,
      };
      this.storePaginationToState(pag);
      let lists = this.selectedLists;
      let users = [];
      for (let i = 0; i < this.filteredLeadUsers.length; i++) {
        let user = {
          email: this.filteredLeadUsers[i].mail.trim(),
          first_name: this.filteredLeadUsers[i].first_name,
          last_name: this.filteredLeadUsers[i].last_name,
          custom_fields: {
            e1_T: this.filteredLeadUsers[i].status_name,
          },
        };
        users.push(user);
      }
      let body = {
        users: users,
        lists: lists,
      };
      await this.sendContacts(body).then(() => {
        this.sendGridUploadOverview = true;
        let temp = {
          itemsPerPage: 20,
        };
        this.storePaginationToState(temp);
      });
      this.sendGridConfirmDialog = false;
    },

    async sendGridInfo() {
      let pag = {
        itemsPerPage: -1,
      };
      await this.storePaginationToState(pag);
      this.sendGridUsers = this.filteredLeadUsers.length;
      let temp = {
        itemsPerPage: 20,
      };
      this.storePaginationToState(temp);
      await this.getAllContactLists();
      this.sendGridConfirmDialog = true;
    },

    async createNewList() {
      let data = {
        name: this.newListName,
      };
      await this.createContactList(data);
      await this.getAllContactLists();
      this.createListDialog = false;
    },

    async fetchLeadsForPeriod() {
      let params = {
        applicationStartDate: null,
        applicationEndDate: null,
        contactStartDate: null,
        contactEndDate: null,
        intakeStartDate: null,
        intakeEndDate: null,
      };

      params.applicationStartDate =
        this.filters.application_dates[0] > this.filters.application_dates[1]
          ? this.filters.application_dates[1]
          : this.filters.application_dates[0];
      params.applicationEndDate =
        this.filters.application_dates[0] > this.filters.application_dates[1]
          ? this.filters.application_dates[0]
          : this.filters.application_dates[1];

      if (
        this.filters.contact_dates &&
        this.filters.contact_dates.length === 2
      ) {
        params.contactStartDate =
          this.filters.contact_dates[0] > this.filters.contact_dates[1]
            ? this.filters.contact_dates[1]
            : this.filters.contact_dates[0];
        params.contactEndDate =
          this.filters.contact_dates[0] > this.filters.contact_dates[1]
            ? this.filters.contact_dates[0]
            : this.filters.contact_dates[1];
      }

      if (this.filters.intake_dates && this.filters.intake_dates.length === 2) {
        params.intakeStartDate =
          this.filters.intake_dates[0] > this.filters.intake_dates[1]
            ? this.filters.intake_dates[1]
            : this.filters.intake_dates[0];
        params.intakeEndDate =
          this.filters.intake_dates[0] > this.filters.intake_dates[1]
            ? this.filters.intake_dates[0]
            : this.filters.intake_dates[1];
      }

      await this.getLeadsPerPeriod({ params });
    },

    storeFilterToState() {
      this.setLeadFilter(this.filters);
    },

    storePaginationToState(val) {
      this.setLeadsPagination(val);
    },

    async updateSortBy(sortBy) {
      this.setLeadSortBy(sortBy);
    },

    async updateSortDesc(sortDesc) {
      this.setLeadSortDesc(sortDesc);
    },

    setHeaders(e) {
      this.selectedHeaders = e;
    },

    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] === "applied_date") {
          const dataA = this.dbFormat(a.applied_date);
          const dataB = this.dbFormat(b.applied_date);
          if (!isDesc[0]) {
            return this.compare(dataA, dataB);
          } else {
            return this.compare(dataB, dataA);
          }
        } else if (
          index[0] === "intake_date" ||
          index[0] === "intake_completed" ||
          index[0] === "placed_date"
        ) {
          if (a[index] === null) {
            return !isDesc[0] ? -1 : 1;
          }
          if (b[index] === null) {
            return !isDesc[0] ? 1 : -1;
          }
          if (!isDesc[0]) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
          }
        } else if (index[0] === "sub_statuses") {
          let dataA = "zzz";
          let dataB = "zzz";
          if (a.sub_statuses.length > 0) {
            dataA = a.sub_statuses[0].sub_status.name;
          }
          if (b.sub_statuses.length > 0) {
            dataB = b.sub_statuses[0].sub_status.name;
          }
          if (!isDesc[0]) {
            return dataA.localeCompare(dataB);
          } else {
            if (dataA === "zzz") {
              return 1;
            } else if (dataB === "zzz") {
              return -1;
            }
            return dataB.localeCompare(dataA);
          }
        } else if (index[0] === "first_date") {
          return this.sortCallStatus(a, b, isDesc[0]);
        } else {
          if (!isDesc[0]) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
          }
        }
      });
      return items;
    },

    sortCallStatus(a, b, descending) {
      if (a === null || b === null) {
        return 0;
      }

      if (descending) {
        let test = a.callState() > b.callState() ? -1 : 1;
        return test;
      } else {
        let test = a.callState() < b.callState() ? -1 : 1;
        return test;
      }
    },

    compare(item1, item2) {
      if (item1 < item2) return -1;
      if (item1 > item2) return 1;
      return 0;
    },

    async clearFilters() {
      let now = new Date();
      now = now.toISOString().substring(0, 10);
      let past = new Date();
      past.setDate(past.getDate() - 30);
      past = past.toISOString().substring(0, 10);
      let params = {
        applicationStartDate: past,
        applicationEndDate: now,
        contactStartDate: null,
        contactEndDate: null,
        intakeStartDate: null,
        intakeEndDate: null,
      };

      let temp = {
        application_dates: [past, now],
      };
      this.filters = {};

      this.filters = temp;
      await this.getLeadsPerPeriod({ params });
      this.storeFilterToState();
    },

    fullName(item) {
      if (item != undefined) {
        return item.first_name + " " + item.last_name;
      }
      return "Niet toegewezen";
    },

    cleanUserName(first, last) {
      return first.trim() + " " + last.trim();
    },

    getFlowIntakeStatus(flow) {
      if (flow === null) {
        return null;
      } else if (flow.intake_completed === null) {
        return null;
      } else {
        return flow.intake_completed;
      }
    },

    getFlowIntakeDate(flow) {
      if (flow === null) {
        return null;
      } else if (flow.intake_date === null) {
        return null;
      } else {
        let date = this.formatDate(flow.intake_date.substring(0, 10));
        return date;
      }
    },

    calculateTextColor(bgColor) {
      let color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
      let r = parseInt(color.substring(0, 2), 16); // hexToR
      let g = parseInt(color.substring(2, 4), 16); // hexToG
      let b = parseInt(color.substring(4, 6), 16); // hexToB
      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#ffffff";
    },

    remove(item) {
      let index = -1;
      this.filters.statuses.find(function (status, i) {
        if (status.name === item.name) {
          index = i;
        }
      });
      if (index >= 0) {
        this.filters.statuses.splice(index, 1);
      }
      this.filters.sub_statuses = [];
      this.storeFilterToState();
    },
    removeSubStatus(item) {
      let index = -1;
      this.filters.sub_statuses.find(function (status, i) {
        if (status.name === item.name) {
          index = i;
        }
      });
      if (index >= 0) {
        this.filters.sub_statuses.splice(index, 1);
      }
      this.storeFilterToState();
    },

    dbFormat(date) {
      if (!date) return null;

      const [day, month, year] = date.split("-");
      return `${year}-${month}-${day}`;
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },

    toLocalDate(date) {
      if (!date) return null;
      let utcDate = new Date(date);
      const localeString = utcDate.toLocaleString("nl-NL");
      return localeString.substring(0, localeString.length - 3);
    },

    latestLead(item) {
      if (item.leads.length > 0) {
        return item.leads[item.leads.length - 1];
      } else {
        return undefined;
      }
    },

    splitDateRange(date) {
      if (!date) return null;
      if (date.length === 0) return [];
      const date1 = this.formatDate(date[0]);
      const date2 = this.formatDate(date[1]);
      return `${date1}~${date2}`;
    },

    latestStatus(item) {
      if (item.user.lead_statuses.length > 0) {
        item.user.lead_statuses.sort(function (a, b) {
          return a.id - b.id;
        });
        for (let i = 1; i <= item.user.lead_statuses.length; i++) {
          if (
            item.user.lead_statuses[item.user.lead_statuses.length - i]
              .status != null
          ) {
            return item.user.lead_statuses[item.user.lead_statuses.length - i]
              .status;
          }
        }
      } else {
        return { name: "Geen", color: "#FFD700" };
      }
    },

    subStatuses(item) {
      let temp = [];
      if (item.user.lead_statuses.length > 0) {
        item.user.lead_statuses.sort(function (a, b) {
          return a.id - b.id;
        });
        for (let i = 1; i <= item.user.lead_statuses.length; i++) {
          if (
            item.user.lead_statuses[item.user.lead_statuses.length - i]
              .lead_status_has_sub_statuses.length > 0
          ) {
            temp =
              item.user.lead_statuses[item.user.lead_statuses.length - i]
                .lead_status_has_sub_statuses;
          }
          return temp;
        }
      } else {
        return [];
      }
    },

    itemExists(item) {
      if (item == null || item == "") return "Onbekend";
      return item;
    },
    latestContact(item) {
      if (item.contact_moments.length > 0) {
        return this.formatDate(
          item.contact_moments[
            item.contact_moments.length - 1
          ].createdAt.substring(0, 10)
        );
      } else {
        return "Geen";
      }
    },

    positions() {
      this.activeCompanyPositions = [];
      for (let position in this.activeCompany.company.positions) {
        this.activeCompanyPositions.push(
          this.activeCompany.company.positions[position].position
        );
      }
    },

    columnSelectOpen(item) {
      let route = this.$router.resolve({ path: `/profiel/${item.user_id}` });
      window.open(route.href);
    },
    setMessageLanguage(item, lang) {
      this.selectedLanguage = lang;
      this.createWhatsappContactMoment(item);
    },
    async createWhatsappContactMoment(item) {
      const { dispatch } = this.$store;
      const contact_moment = {
        lead_user_id: item.user_id,
        type: 2,
        comunicator_id: this.$store.state.users.user.id,
        notes: `Een Whatsapp bericht is verstuurd door ${this.$store.state.users.user.first_name} ${this.$store.state.users.user.last_name}`,
      };
      if (this.$store.state.users.user.role > 4)
        return dispatch("alerts/warn", "Je bent niet bevoegd om dit te doen");
      await this.createContactMoment(contact_moment).then(() => {
        dispatch("alerts/success", "Contact moment aangemaakt");
        //Set whatsapp boolean to true
        const leadId = item.id;
        let flow = {
          lead_id: leadId,
        };
        flow.app_sent = true;
        this.updateFlow({ flow, leadId }).then(() => {
          this.fetchLeadsForPeriod();
        });
      });
      //* Veryfying tel number format
      const pattern = /^\+(?:[0-9]●?){6,14}[0-9]$/;
      let isValid = pattern.test(item.tel_number);
      if (
        item.tel_number == "" ||
        item.tel_number == undefined ||
        item.tel_number == null ||
        !isValid
      ) {
        dispatch("alerts/error", "Invalid number format || Template not sent!");
        return;
      }

      //* Start a conversation with template
      let data = {
        message: {
          namespace: "6bf1cd4f_1084_4310_b5de_3c6533b9c158",
          templateName: "general_application_3",
          language: {
            policy: "deterministic",
            code: this.selectedLanguage,
          },
        },
        number: item.tel_number,
      };
      await this.createConversation(data).then((res) => {
        try {
          let lead_user = {
            conversation_id: res.response.id,
          };
          let userId = item.lead_user_id;
          this.updateLeadUser({
            lead_user: lead_user,
            userId: userId,
          }).then(() => {
            dispatch("alerts/success", "Conversation Created");
          });
        } catch {
          dispatch("alerts/error", "Couldn't create a conversation");
        }
      });
    },

    open() {
      this.$router.push(`/profiel/${this.selectedRow.user_id}`);
    },

    openNewTab() {
      let routeData = this.$router.resolve({
        path: `/profiel/${this.selectedRow.user_id}`,
      });
      window.open(routeData.href, "_blank");
    },

    show(event, row) {
      event.preventDefault();
      this.showMenu = false;
      this.x = event.clientX;
      this.y = event.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
      this.selectedRow = row.item;
    },

    openFlowSpoken(item) {
      this.activeLeadId = item.id;
      this.activeLeadUserId = item.user_id;
      this.activeLeadName = item.user_name;
      this.activeLeadContactNo = item.tel_number;
      this.activeLeadVmCount = item.vm_count;
      this.leadsPeriodString = JSON.stringify(this.filters.application_dates);
      this.dialogSpeak = true;
    },

    openFlowIntake(item) {
      this.activeLeadId = item.id;
      this.activeLeadUserId = item.user_id;
      this.dialogIntake = true;
    },

    openFlowPlace(item) {
      this.activeLeadId = item.id;
      this.activeLeadUserId = item.user_id;
      this.activeFlowCmId = item.placed_cm;
      this.dialogPlace = true;
    },

    // getting selected by the user columns
    customExport(getType) {
      let text = ``;
      let row = [];
      // creating string from selected columns
      this.headers.forEach((header) => {
        this.selectedHeaders.forEach((selectedHeader) => {
          if (selectedHeader == header.id) {
            if (getType === "header") {
              text += `,` + header.text;
            } else if (getType === "row") {
              row.push(header.value);
            }
          }
        });
      });
      if (getType === "row") {
        return row;
      } else return text;
    },

    async excelExport() {
      let pag = {
        itemsPerPage: -1,
      };
      this.storePaginationToState(pag);
      if (permission("canExportLeadsFlow") || permission("isAdmin")) {
        this.excelExporting = true;
        let csvContent = "data:text/csv;charset=utf-8,";
        let headings =
          `id,current_status,first_name,last_name,recruiter,tel_number,email,campaign,kanaal` +
          this.customExport("header") +
          `,substatuses`;
        csvContent += headings + "\r\n";
        this.filteredLeadUsers.forEach((lead) => {
          let row = `${lead.id},${lead.status_name},${lead.first_name},${lead.last_name},${lead.lead_rec},tel:${lead.tel_number},${lead.mail},${lead.campaign},${lead.channel}`;

          //loop that goes through the list of values and addes
          let tempArray = this.customExport("row");
          for (let i = 0; i < tempArray.length; i++) {
            row += `,${lead[tempArray[i]]}`;
          }

          let sub_status = ",";
          lead.sub_statuses.forEach((subStatus) => {
            sub_status += `${subStatus.sub_status.name}`;
          });
          if (sub_status.length > 1) row += sub_status;
          csvContent += row + "\r\n";
        });
        let encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "cllbr_export.csv");
        document.body.appendChild(link);
        link.click();
        this.excelExporting = false;
      }
      let temp = {
        itemsPerPage: 20,
      };
      this.storePaginationToState(temp);
    },
    getFiltered(e) {
      this.filteredLeadUsers = e;
    },
    setApplicationDates(dates) {
      this.filters.application_dates = dates;
      this.storeFilterToState();
      if (this.filters.application_dates.length === 2)
        this.fetchLeadsForPeriod();
    },
    setContactDates(dates) {
      this.filters.contact_dates = dates;
      this.storeFilterToState();
      if (this.filters.contact_dates.length === 2) this.fetchLeadsForPeriod();
    },
    setIntakeDates(dates) {
      this.filters.intake_dates = dates;
      this.storeFilterToState();
      if (this.filters.intake_dates.length === 2) this.fetchLeadsForPeriod();
    },
    async undoContactMoment() {
      let lead_user_id = this.userToEdit.lead_user_id;

      //* Deletes the "first contact" contact moment
      await this.deleteFirstContactMoment({
        lead_user_id,
      });
      let leadId = this.userToEdit.id;
      let flow = {
        first_date: null,
      };
      //* Sets flow first_date to null
      await this.updateFlow({ flow, leadId }).then(() => {
        this.fetchLeadsForPeriod();
      });
      this.dialogUndoContactMoment = false;
    },
    openUndoDialog(item) {
      this.userToEdit = item;
      this.dialogUndoContactMoment = true;
    },
    onRightClick(item) {
      const leadId = item.id;
      let flow = {
        lead_id: leadId,
      };
      flow.app_sent = null;
      this.updateFlow({ flow, leadId }).then(() => {
        this.fetchLeadsForPeriod();
      });
    },
  },
};
</script>

<style lang="scss">
.sub {
  background-color: #f0f0f0;
}
.invalidUsersItem {
  font-weight: 500;
}

.hide-y-scroll {
  overflow-y: hidden;
}

td.text-start {
  padding-bottom: 4px !important;
  padding-top: 4px !important;
}
</style>
