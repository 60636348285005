<template>
  <v-container class="pa-4">
    <h3 class="mb-3">Add to Hotlist</h3>
    <v-col>
      <v-row align="center">
        <v-autocomplete
          background-color="cardFields"
          dense
          outlined
          :items="hotlists_getter"
          item-text="name"
          id="id"
          return-object
          v-model="selectedHotlist"
          label="Hotlist"
      /></v-row>
      <v-row align="center" justify="center">
        <v-btn class="animatedBtn" color="primary" @click="addMember">
          Select
        </v-btn></v-row
      >
    </v-col>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import {
  ADD_HOTLIST_MEMBER,
  BULK_ADD_HOTLIST_MEMBERS,
  GET_ALL_HOTLISTS,
} from "../../../store/action.type";
export default {
  props: { selectedCandidates: Array },
  data: () => ({
    selectedHotlist: null,
  }),
  computed: {
    ...mapGetters("hotlists", ["hotlists_getter"]),
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions("hotlist_members", {
      addHotlistMember: ADD_HOTLIST_MEMBER,
      bulkAddHotlistMembers: BULK_ADD_HOTLIST_MEMBERS,
    }),
    ...mapActions("hotlists", {
      getAllHotlists: GET_ALL_HOTLISTS,
    }),
    async init() {
      await this.getAllHotlists();
    },
    async addMember() {
      let hotlist_members = [];
      this.selectedCandidates.map((item) => {
        let hotlist_member = {
          hotlist_id: this.selectedHotlist.id,
          lead_user_id: item.id,
        };
        hotlist_members.push(hotlist_member);
      });
      this.bulkAddHotlistMembers({
        hotlist_members,
      });
      this.$emit("close-dialog");
      this.$emit("reload-table");
    },
  },
};
</script>
<style scoped>
.animatedBtn:hover {
  transform: scale(1.1);
}
</style>
