import Vue from "vue";
import Vuex from "vuex";
//import { authentication } from './modules/authentication';
import { users } from "./modules/users";
import { alerts } from "./modules/alerts";
import { company } from "./modules/company";
import { positions } from "./modules/positions";
import { contact } from "./modules/contact";
import { statuses } from "./modules/statuses";
import { leads } from "./modules/leads";
import { lead_users } from "./modules/lead_users";
import { vacancy } from "./modules/vacancy";
import { dashboard } from "./modules/dashboard";
import { file_upload } from "./modules/file_upload";
import { utility } from "./modules/utility";
import { clients } from "./modules/analytical/clients";
import { contact_moments } from "./modules/contact_moments";
import { filters } from "./modules/filters";
import { flow } from "./modules/flow";
import { teamup } from "./modules/teamup";
import { notifications } from "./modules/notifications";
import { interests } from "./modules/interests";
import { intake_data } from "./modules/intake_data";
import { marketing_spending } from "./modules/marketing_spending";
import { vacancy_filters } from "./modules/vacancy_filters";
import { send_grid } from "./modules/send_grid";
import { external_api_keys } from "./modules/external_api_keys";
import { whats_app } from "./modules/whats_app";
import { lead_pipes } from "./modules/lead_pipes";
import { vacancy_flow } from "./modules/vacancy_flow";
import { vacancy_template } from "./modules/vacancy_template";
import { email_template } from "./modules/email_template";
import { hotlists } from "./modules/hotlists";
import { hotlist_members } from "./modules/hotlist_members";
import { email_activity } from "./modules/email_activity";
import { whatsapp_survey_users } from "./modules/whatsapp_survey_users";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    //authentication,
    users,
    alerts,
    company,
    positions,
    contact,
    statuses,
    leads,
    lead_users,
    vacancy,
    dashboard,
    file_upload,
    utility,
    clients,
    contact_moments,
    filters,
    flow,
    interests,
    teamup,
    notifications,
    intake_data,
    marketing_spending,
    vacancy_filters,
    send_grid,
    external_api_keys,
    whats_app,
    lead_pipes,
    vacancy_flow,
    vacancy_template,
    email_template,
    hotlists,
    hotlist_members,
    email_activity,
    whatsapp_survey_users,
  },
});
