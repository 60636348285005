import config from "../../../config";
import { authHeader } from "../../utils/auth-header";
import { handleResponse } from "../../utils/api_functions";
import { logger } from "../../utils/logger";

import {
  GET_ALL_WHATSAPP_SURVEY_USERS,
  DELETE_WHATSAPP_SURVEY_USER,
} from "../action.type";
import { SET_WHATSAPP_SURVEY_USERS } from "../mutation.type";
export const whatsapp_survey_users = {
  namespaced: true,
  state: { whatsapp_survey_users: [] },
  getters: {
    whatsapp_survey_users_getter: (state) => {
      return state.whatsapp_survey_users.map((e) => {
        return {
          lead_user: e.lead_user,
        };
      });
    },
  },
  actions: {
    async [GET_ALL_WHATSAPP_SURVEY_USERS]({ commit }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/getWhatsappSurveyUsers`, request)
          .then(handleResponse)
          .then((res) => {
            commit(SET_WHATSAPP_SURVEY_USERS, res);
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [DELETE_WHATSAPP_SURVEY_USER]({ commit }, id) {
      try {
        const request = {
          method: "DELETE",
          headers: authHeader(),
        };
        await fetch(`${config.apiUrl}/deleteWhatsappSurveyUser/${id}`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
  },
  mutations: {
    [SET_WHATSAPP_SURVEY_USERS](state, whatsapp_survey_users) {
      state.whatsapp_survey_users = whatsapp_survey_users;
    },
  },
};
