import config from "../../config";
import { authHeader } from "./auth-header";

export const logger = {
  log,
  info,
  error,
  warn,
};

function log(data) {
  var logData = {
    data: data,
    type: "log",
    owner: authHeader()["request_user"],
  };
  sendLog(logData);
}

function info(data) {
  if (typeof data === "object") {
    data = data.stack;
  }
  var logData = {
    data: data,
    type: "info",
    owner: authHeader()["request_user"],
  };
  sendLog(logData);
}

function error(data) {
  if (typeof data === "object") {
    data = data.stack;
  }
  var logData = {
    data: data,
    type: "error",
    owner: authHeader()["request_user"],
  };
  sendLog(logData);
}

function warn(data) {
  if (typeof data === "object") {
    data = data.stack;
  }
  var logData = {
    data: data,
    type: "warn",
    owner: authHeader()["request_user"],
  };
  sendLog(logData);
}

async function sendLog(logData) {
  try {
    const request = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify(logData),
    };
    await fetch(`${config.apiUrl}/client-logger`, request)
      .then(handleResponse)
      .catch((error) => {
        //Dont handle this error as it would then likely recursively keep erroring
      });
  } catch (error) {
    //Dont handle this error as it would then likely recursively keep erroring
  }
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
