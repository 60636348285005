<template>
  <div v-if="lead.id">
    <!--Dialogs-->

    <!--Header Section-->
    <v-container fluid>
      <Header :lead="lead" :latestStatus="latestStatus" />
    </v-container>

    <!-- New layout -->
    <v-container fluid class="pt-0">
      <v-row>
        <!-- Person -->
        <Person
          :lead="lead"
          :mainLead="activeMainLead"
          :cardHeaders="cardHeaders"
        />

        <!-- Contact Momenten -->
        <ContactMoments
          :lead="lead"
          :mainLead="activeMainLead"
          :latestStatus="latestStatus"
          :cardHeaders="cardHeaders"
          :user="user"
        />

        <!-- Lead -->
        <Leads
          :lead="lead"
          :mainLead="activeMainLead"
          :latestStatus="latestStatus"
          :cardHeaders="cardHeaders"
        />

        <!-- Intake -->
        <!-- <ViewIntake
          v-if="intake"
          :user="lead"
          :lead="activeMainLead"
        ></ViewIntake> -->

        <!-- Documents -->
        <v-col xl="4" lg="5" md="6" sm="12">
          <v-container fluid class="pl-0 pr-0">
            <v-col>
              <v-row>
                <DocumentsSection
                  :documents="lead.documents"
                  :parentID="lead.id"
                  :type="1"
                  :parsedCv="lead.parsedCv"
                  :textCv="lead.textCv"
                />
              </v-row>
            </v-col>
          </v-container>
        </v-col>
        <!-- Status -->
        <StatusHistory :cardHeaders="cardHeaders" :mainLead="activeMainLead" />

        <!-- Flow -->
        <Flow :cardHeaders="cardHeaders" :mainLead="activeMainLead" />

        <!-- Location -->
        <!-- <Location
          :cardHeaders="cardHeaders"
          :lead="lead"
          :mainLead="activeMainLead"
        /> -->

        <!-- Email Activity -->
        <EmailActivity
          :cardHeaders="cardHeaders"
          :mainLead="lead"
        ></EmailActivity>

        <!-- Chat -->
        <Chat :cardHeaders="cardHeaders" :lead="lead" :loaded="loaded" />
      </v-row>
    </v-container>
  </div>
</template>

<script>
//import ViewIntake from "../components/dialogs/ViewIntake.vue";

import DocumentsSection from "../components/profile/components/DocumentsSection.vue";
import Header from "../components/profile/components/Header.vue";
import Person from "../components/profile/components/Person.vue";
import ContactMoments from "../components/profile/components/ContactMoments.vue";
import Leads from "../components/profile/components/Leads.vue";
import StatusHistory from "../components/profile/components/StatusHistory.vue";
import Flow from "../components/profile/components/Flow.vue";
// import Location from "../components/profile/components/Location.vue";
import Chat from "../components/profile/components/Chat.vue";
import EmailActivity from "../components/emails/EmailActivity.vue";

import { mapActions, mapGetters } from "vuex";
import {
  DOWNLOAD_DOCUMENT,
  GET_INTAKE_BY_USER,
  GET_INTERESTS_FOR_LEAD_USER,
  GET_LEAD_USER_BY_ID,
  GET_USERS,
  GET_VACANCIES_WITH_SEARCH_TERMS,
  UPDATE_LEAD_USER,
  ACTIVATE_LEAD,
  GET_VACANCIES_ALL,
} from "../store/action.type";

export default {
  components: {
    DocumentsSection,
    Header,
    Person,
    ContactMoments,
    Leads,
    StatusHistory,
    Flow,
    // Location,
    Chat,
    EmailActivity,
  },
  data: () => ({
    panel: [0],
    company: null,
    showVacancies: false,
    leadId: null,
    intake: null,
    params: [],
    loaded: false,
  }),
  computed: {
    ...mapGetters("interests", ["interests", "getLoadingStates"]),
    lead() {
      return this.$store.state.lead_users.active_lead_user;
    },

    activeMainLead() {
      let leadIndex = this.lead.leads.length - 1;
      let leadFound = false;
      for (let index = 0; index < this.lead.leads.length; index++) {
        if (this.lead.leads[index].active) {
          leadIndex = index;
          leadFound = true;
          break;
        }
      }
      // if (leadFound) {
      //   this.changeLeadForlead(this.lead.leads[leadIndex]);
      // }

      return this.lead?.leads[leadIndex] ?? null;
    },
    latestStatus() {
      if (this.lead.lead_statuses.length > 0) {
        return this.lead.lead_statuses[this.lead.lead_statuses.length - 1]
          .status;
      } else {
        return { name: "Geen", color: "#FFD700" };
      }
    },

    cardHeaders() {
      if (this.$vuetify.theme.dark) {
        return "background: #2a2a2a";
      } else {
        return "background: #b3b3b3";
      }
    },
    user() {
      return this.$store.state.users.user;
    },
  },
  async created() {
    await this.init();
    await this.getUser();
    await this.getIntake();
  },

  methods: {
    ...mapActions("intake_data", {
      getIntakeByUser: GET_INTAKE_BY_USER,
    }),

    ...mapActions("interests", {
      getInterestsForLeadUser: GET_INTERESTS_FOR_LEAD_USER,
    }),
    ...mapActions("lead_users", {
      getLeadUserById: GET_LEAD_USER_BY_ID,
      updateLeadUser: UPDATE_LEAD_USER,
    }),
    ...mapActions("users", { getUsers: GET_USERS }),
    ...mapActions("vacancy", {
      getVacanciesWithSearchTerms: GET_VACANCIES_WITH_SEARCH_TERMS,
    }),
    ...mapActions("leads", {
      activateLead: ACTIVATE_LEAD,
    }),
    ...mapActions("vacancy_flow", {
      getVacanciesAll: GET_VACANCIES_ALL,
    }),

    async init() {
      await this.getVacanciesAll();
    },

    async getUser() {
      await this.getLeadUserById(this.$route.params.id);

      await this.getUsers();
      this.loaded = true;
    },

    async getIntake() {
      if (this.activeMainLead) {
        await this.getIntakeByUser(this.activeMainLead.lead_user).then(
          (res) => {
            if (res.intake !== null) {
              this.intake = res.intake;
            } else this.intake = res.intake;
          }
        );
      }
      await this.loadVacancies();
    },

    async loadVacancies() {
      const { dispatch } = this.$store;
      await this.getInterestsForLeadUser(this.lead.id);
      let industryArray = [];
      let rolesArray = [];
      for (let i = 0; i < this.interests.length; i++) {
        industryArray.push(this.interests[i].job.industry_id);
        rolesArray.push(this.interests[i].job.role_id);
      }
      let status = this.latestStatus.name;
      let params = {
        industries: JSON.stringify(industryArray),
        roles: JSON.stringify(rolesArray),
        status: status,
        car: this.getCar(this.lead.car),
        license: this.getLicense(this.lead.drivers_license),
        gender: this.lead.gender,
        age: this.getAge(this.lead.dob),
        id: this.lead.id,
      };
      await this.getVacanciesWithSearchTerms({ params });
      this.showVacancies = true;
    },

    getCar(car) {
      if (car === null) {
        return "geen";
      }
      return car;
    },
    getAge(dateString) {
      var today = new Date();
      var birthDate;
      if (dateString !== "0000-00-00") {
        birthDate = new Date(dateString);
      } else {
        birthDate = new Date();
      }
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    getLicense(license) {
      if (license === null) {
        return "geen";
      }
      return license;
    },

    async changeLeadForlead(item) {
      await this.activateLead({ leadId: item.id, lead_user: item.lead_user });
      //await this.getUser();
    },
  },
};
</script>
<style lang="scss"></style>
