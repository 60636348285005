<template>
  <v-card>
    <v-card-title> {{ $t("delete_user.permanently_delete") }}</v-card-title>
    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn color="red darken-1" text @click="deleteUserDialog = false">
        {{ $t("delete_user.delete") }}
      </v-btn>

      <v-btn color="green darken-1" text @click="deleteUser()">
        {{ $t("delete_user.cancel") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import {
  DELETE_LEAD_USER,
  GET_LEADS_PER_PERIOD,
} from "../../../store/action.type";

export default {
  props: ["lead"],
  methods: {
    ...mapActions("lead_users", {
      deleteLeadUser: DELETE_LEAD_USER,
    }),
    ...mapActions("leads", {
      getLeadsPerPeriod: GET_LEADS_PER_PERIOD,
    }),

    async deleteUser() {
      let userId = this.lead.id;
      await this.deleteLeadUser(userId);
      this.deleteUserDialog = false;

      // * Fetching lead since all leads for the deleted user will also be deleted
      let now = new Date();
      now = now.toISOString().substring(0, 10);
      let past = new Date();
      past.setDate(past.getDate() - 30);
      past = past.toISOString().substring(0, 10);
      let params = {
        applicationStartDate: past,
        applicationEndDate: now,
        contactStartDate: null,
        contactEndDate: null,
        intakeStartDate: null,
        intakeEndDate: null,
      };

      await this.getLeadsPerPeriod({ params });

      this.$router.push(`/`);
    },
  },
};
</script>
