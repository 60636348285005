<template>
  <div v-if="activeVacancy">
    <v-dialog v-model="dialogShowVacancy" max-width="600px">
      <v-card>
        <CreateVacancy
          v-on:close-dialog="dialogShowVacancy = false"
          type="1"
          :formData="activeVacancy.vacancy"
        ></CreateVacancy>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogInactive" max-width="600px">
      <v-card class="pb-1" color="primary">
        <v-alert class="pb-0" prominent type="info">
          <v-row align="center">
            <v-col class="grow"
              >{{ $t("vancancy_page.inactive_vacancy") }}
            </v-col>
            <v-col class="shrink">
              <v-btn color="green" @click="makeInactive">{{
                $t("vancancy_page.confirm")
              }}</v-btn>
            </v-col>
            <v-col class="shrink">
              <v-btn color="grey" @click="dialogInactive = false">{{
                $t("vancancy_page.cancel")
              }}</v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-card>
    </v-dialog>

    <div class="pl-5 pr-5">
      <v-row>
        <v-col md="10">
          <h1 class="pb-1">
            <v-btn class="mb-1" icon color="primary" @click="$router.go(-1)">
              <v-icon x-large dark>mdi-chevron-left-box</v-icon>
            </v-btn>
            {{ activeVacancy.vacancy.id }} - {{ activeVacancy.vacancy.name }}
            <v-chip label :color="latestVacancyStatus.color"
              ><strong>{{ latestVacancyStatus.name }}</strong></v-chip
            >
            <v-btn
              v-if="!activeVacancy.vacancy.inactive"
              class="ml-2"
              color="red"
              @click="dialogInactive = true"
              >{{ $t("vancancy_page.make_inactive") }}</v-btn
            >
          </h1>
        </v-col>
        <v-col align="end" md="2">
          <v-btn icon color="primary" @click="print">
            <v-icon large dark>mdi-printer</v-icon>
          </v-btn>
          <v-btn icon color="primary" @click="dialogShowVacancy = true">
            <v-icon large dark>mdi-pencil-box</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-card class="pa-4 mb-5 ml-5 mr-5" elevation="4" id="printMe">
      <h1 v-show="toPrint">
        {{ activeVacancy.vacancy.id }} - {{ activeVacancy.vacancy.name }}
      </h1>
      <v-divider v-show="toPrint" class="my-2 mb-4"></v-divider>
      <v-row>
        <v-col>
          <v-col>
            <h3>{{ $t("vancancy_page.details") }}</h3>
            <p>
              <a
                v-if="!!activeVacancy.vacancy.vacancy_link"
                :href="activeVacancy.vacancy.vacancy_link"
                >{{ activeVacancy.vacancy.vacancy_link }}<br
              /></a>
              {{ $t("vancancy_page.vac_duration") }}:
              {{ formatDate(activeVacancy.vacancy.start_date) }} -
              {{ formatDate(activeVacancy.vacancy.vacancy_close_date) }}
              <br />
              {{ activeVacancy.vacancy.employment_type }},
              {{ activeVacancy.vacancy.employment_length }}
              <br />
              {{ $t("vancancy_page.people_needed") }}:
              {{ activeVacancy.vacancy.people_needed }}
              <br />
              {{ $t("vancancy_page.salary_indication") }}: €{{
                activeVacancy.vacancy.sal_indication
              }}
              <br />
              {{ $t("vancancy_page.rate") }}: €{{
                activeVacancy.vacancy.tariff
              }}
              <span v-if="activeVacancy.vacancy.position"
                ><br />
                {{ $t("vancancy_page.old_position") }}:
                {{ activeVacancy.vacancy.position.name }}
              </span>
              <span v-if="activeVacancy.vacancy.job">
                <br />
                {{ $t("vancancy_page.role") }}:
                {{ activeVacancy.vacancy.job.role.name }}
                <br />
                {{ $t("vancancy_page.industrie") }}:
                {{ activeVacancy.vacancy.job.industry.name }}
              </span>
            </p>
            <h3>{{ $t("vancancy_page.description") }}</h3>
            <p>{{ activeVacancy.vacancy.desc }}</p>
            <div v-if="!!activeVacancy.vacancy.other_info">
              <h3>{{ $t("vancancy_page.add_info") }}</h3>
              <p>{{ activeVacancy.vacancy.other_info }}</p>
            </div>
            <h3>{{ $t("vancancy_page.community_manager") }}</h3>
            <p>
              {{ activeVacancy.vacancy.community_manager.first_name }}
              {{ activeVacancy.vacancy.community_manager.last_name }}
            </p>
            <h3>{{ $t("vancancy_page.recruiter") }}</h3>
            <div v-if="activeVacancy.vacancy.recruiter">
              <p>
                {{ fullName(activeVacancy.vacancy.recruiter) }}
              </p>
            </div>
            <div v-else class="mt-2 mb-2">
              <v-btn @click="selfAssignRecruiter()" color="primary">{{
                $t("vancancy_page.assign_yourself")
              }}</v-btn>
            </div>
            <h3>{{ $t("vancancy_page.contact_person") }}</h3>
            <p v-if="activeVacancy.vacancy.contact_person">
              <span v-if="activeVacancy.vacancy.contact_person.first_name">
                {{ activeVacancy.vacancy.contact_person.first_name }}</span
              >
              {{ " " }}
              <span v-if="activeVacancy.vacancy.contact_person.last_name">
                {{ activeVacancy.vacancy.contact_person.last_name }}</span
              >
              <br />
              <span v-if="activeVacancy.vacancy.contact_person.department">{{
                activeVacancy.vacancy.contact_person.department
              }}</span>
              <br />
              <span v-if="activeVacancy.vacancy.contact_person.mail">{{
                activeVacancy.vacancy.contact_person.mail
              }}</span>
              <br />
              <span v-if="activeVacancy.vacancy.contact_person.phone">{{
                activeVacancy.vacancy.contact_person.phone
              }}</span>
              <br />
            </p>
            <p v-else>{{ $t("vancancy_page.not_found") }}</p>
            <div v-if="hasTerms()">
              <h3>{{ $t("vancancy_page.conditions") }}</h3>
              <div v-if="!!activeVacancy.vacancy.terms.min_days">
                {{ $t("vancancy_page.minimum_days") }}:
                {{ activeVacancy.vacancy.terms.min_days }}
                <br />
              </div>
              <div v-if="!!activeVacancy.vacancy.terms.extra_remarks">
                {{ $t("vancancy_page.other_comments") }}:
                {{ activeVacancy.vacancy.terms.extra_remarks }}<br />
              </div>
              <div v-if="!!activeVacancy.vacancy.terms.dresscode">
                {{ $t("vancancy_page.dress_code") }}:
                {{ activeVacancy.vacancy.terms.dresscode }} <br />
              </div>
              <div v-if="!!activeVacancy.vacancy.terms.extra_requirements">
                {{ $t("vancancy_page.other_reqs") }}:
                {{ activeVacancy.vacancy.terms.extra_requirements }} <br />
              </div>
              <div v-if="!!activeVacancy.vacancy.terms.cao">
                {{ $t("vancancy_page.cao") }}:
                {{ activeVacancy.vacancy.terms.cao }}<br />
              </div>
              <div v-if="!!activeVacancy.vacancy.terms.initial_scale">
                {{ $t("vancancy_page.start_scale") }}:
                {{ activeVacancy.vacancy.terms.initial_scale }}<br />
              </div>
            </div>
          </v-col>
        </v-col>
        <v-col>
          <v-col>
            <a @click="openCompany(activeVacancy.vacancy.company_id)"
              ><h3>{{ activeVacancy.vacancy.company.name }}</h3></a
            >
            <a :href="activeVacancy.vacancy.company.site_url">{{
              activeVacancy.vacancy.company.site_url
            }}</a>
            <p>
              {{ $t("vancancy_page.tel") }}:
              {{ activeVacancy.vacancy.company.phone }}
            </p>

            <h3>{{ $t("vancancy_page.address") }}</h3>
            <p>
              {{ activeVacancy.vacancy.address.street }},
              {{ activeVacancy.vacancy.address.houseno
              }}{{ activeVacancy.vacancy.address.addition }}
              <br />
              {{ activeVacancy.vacancy.address.postalcode }}
              <br />
              {{ activeVacancy.vacancy.address.region }}
            </p>
            <GoogleMap
              style="overflow: hidden; height: 336px"
              :address="activeVacancy.vacancy.address"
              @latlng-calculated="onLatlngCalculated"
            ></GoogleMap>
            <h3>
              {{ $t("vancancy_page.application_forms") }}
              <v-btn
                icon
                large
                color="green"
                class="white--text mb-1"
                @click="addApplicationForm()"
                style="width: auto"
              >
                <v-icon class="">mdi-plus-box</v-icon>
              </v-btn>
            </h3>
            <div v-if="showNewApplicationFormID">
              <v-card class="pa-3">
                <v-card-title class="ma-0 pa-0">
                  <p>{{ $t("vancancy_page.application_forms_new") }}</p>
                </v-card-title>
                <v-row>
                  <v-col class="pt-0">
                    <v-select
                      :items="applicationChannels"
                      item-text="name"
                      item-value="key"
                      label="Platform"
                      :rules="rules"
                      v-model="newAppChannel"
                    ></v-select>
                  </v-col>
                  <v-col class="pt-0">
                    <v-text-field
                      :rules="rules"
                      label="Form ID"
                      v-model="newAppID"
                    ></v-text-field>
                  </v-col>
                  <v-col md="1" align-self="end">
                    <v-btn
                      icon
                      large
                      color="green"
                      class="white--text mb-3"
                      @click="submitAppForm()"
                      style="width: auto"
                    >
                      <v-icon class="">mdi-checkbox-marked</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </div>
            <v-list dense class="mt-0 pt-0">
              <v-list-item
                v-for="item in activeVacancy.vacancy.application_forms"
                :key="item.id"
                >{{ item.id }}</v-list-item
              >
            </v-list>
          </v-col>
        </v-col>
      </v-row>
    </v-card>
    <v-divider></v-divider>
    <SubTable
      v-if="leads && loaded"
      class="pa-5"
      type="5"
      v-bind:data="leadsAdjustedList"
      :parentID="activeVacancy.vacancy.id"
    ></SubTable>
    <v-divider></v-divider>
    <VacancyRecommend
      v-if="loaded"
      :parentID="activeVacancy.vacancy.id"
      :vacancy_filters="vacancyTerms"
      :vacancy_address="vac_address"
      :vacancy_job="vac_job"
    ></VacancyRecommend>
    <v-divider></v-divider>
    <VacancyLeadUsers
      v-if="loaded"
      :parentID="activeVacancy.vacancy.id"
      :data="vac_lead_users"
    ></VacancyLeadUsers>
  </div>
</template>

<script>
import VacancyRecommend from "@/components/dialogs/VacancyRecommend.vue";
import VacancyLeadUsers from "@/components/dialogs/VacancyLeadUsers.vue";
import CreateVacancy from "@/components/dialogs/CreateVacancy.vue";
import GoogleMap from "@/components/Google/GoogleMap.vue";
import SubTable from "@/components/Tables/SubTables.vue";
import { mapGetters, mapState, mapActions } from "vuex";
import {
  CREATE_VACANCY_FILTER,
  GET_LEADS_BY_VACANCY_ID,
  GET_VACANCY_BY_ID,
  GET_VACANCY_FILTER_BY_ID,
  UPDATE_VACANCY,
  ADD_APPLICATION_FORM_ID,
} from "@/store/action.type";
import { permission } from "@/utils/permissions";
export default {
  components: {
    CreateVacancy,
    GoogleMap,
    SubTable,
    VacancyRecommend,
    VacancyLeadUsers,
  },
  data: () => ({
    dialogShowVacancy: false,
    dialogInactive: false,
    leads_list: [],
    vac_lead_users: [],
    vacancyTerms: {},
    vac_address: {},
    vac_job: {},
    loaded: false,
    toPrint: false,
    showNewApplicationFormID: false,
    rules: [(value) => !!value || "Required."],
    applicationChannels: [{ name: "Facebook", key: "F_" }],
    newAppChannel: null,
    newAppID: null,
  }),
  computed: {
    leadsAdjustedList() {
      return this.leads.map((e) => {
        var mapped = {
          id: e.id,
          lead_user: e.lead_user,
          campaign_id: e.campaign_id,
          channel_id: e.channel_id,
          channel_name: e.channel.name,
          lead_status_name: this.latestStatus(e.user).name,
          lead_status_color: this.latestStatus(e.user).color,
          user_name: this.fullName(e.user),
          user_dob: this.itemExists(this.formatDate(e.user.dob)),
          gender: this.itemExists(e.user.gender),
          has_driverslicense: e.user.drivers_license,
          has_car: e.user.car,
          rec_id: e.rec_id,
          recruiter_name: this.fullName(e.recruiter),
          ra_id: e.ra_id,
          recruitment_assistant_name: this.fullName(e.recruitment_assistant),
          applied_date: this.formatDate(e.applied_date),
          user_region: this.itemExists(e.user.region),
          vacancy_id: e.vacancy_id,
          exit_vacancy_id: e.exit_vacancy_id,
          company_name: this.itemExists(e.vacancy.company.name),

          tel_number: e.user.tel_number,
          flow: false,
        };

        if (e.flow != null) {
          mapped.first_date = this.toLocalDate(e.flow.first_date);
          mapped.vm_date = this.toLocalDate(e.flow.vm_date);
          mapped.intake_date = this.toLocalDate(e.flow.intake_date);
          mapped.intake_completed = e.flow.intake_completed;
          mapped.placed_date = this.toLocalDate(e.flow.placed_date);
          mapped.rec = e.flow.rec;
          mapped.rec_full_name = this.fullName(e.flow.rec);
          mapped.rec_ass_full_name = this.fullName(e.flow.rec_ass);
          mapped.placed_cm = e.flow.placed_cm;
          mapped.vm_count = e.flow.vm_count;
          mapped.flow = true;
        }

        return mapped;
      });
    },
    latestVacancyStatus() {
      if (this.activeVacancy.vacancy.vacancy_status) {
        return this.activeVacancy.vacancy.vacancy_status;
      } else {
        return { name: "Geen", color: "#FFD700" };
      }
    },
    ...mapState({
      activeVacancy: (state) => state.vacancy.activeVacancy,
      leads: (state) => state.leads.leadsByVac,
    }),
    ...mapGetters("users", ["getLoggedIn"]),
    ...mapGetters("users", ["getUser"]),
    ...mapGetters("leads", ["getLeadFlow"]),
    ...mapGetters("vacancy_filters", ["getVacancyFilters"]),
  },
  async created() {
    this.getVacancy();
  },
  methods: {
    ...mapActions("leads", { getLeadsByVacancyId: GET_LEADS_BY_VACANCY_ID }),
    ...mapActions("vacancy_filters", {
      getVacancyFilterById: GET_VACANCY_FILTER_BY_ID,
      createVacancyFilter: CREATE_VACANCY_FILTER,
    }),
    ...mapActions("vacancy", {
      getVacancyById: GET_VACANCY_BY_ID,
      updateVacancy: UPDATE_VACANCY,
      addAppID: ADD_APPLICATION_FORM_ID,
    }),
    async getVacancy() {
      let filters_id = null;
      await this.getVacancyById(this.$route.params.id).then((res) => {
        filters_id = res.vacancy.filters_id;
        this.vac_address = res.vacancy.address;
        this.vac_lead_users = this.getUsersArray(res.vacancy.lead_users);
        if (res.vacancy.job !== null) {
          this.vac_job = res.vacancy.job;
          this.vac_job = {
            department_id: res.vacancy.job.department_id,
            industry_id: res.vacancy.job.industry_id,
            role_id: res.vacancy.job.role_id,
          };
        } else {
          this.vac_job = {
            isNull: true,
          };
        }
      });

      await this.getLeadsByVacancyId(this.$route.params.id);

      if (filters_id === null) {
        let statuses = [];
        let genders = [];

        await this.createVacancyFilter({
          search_term: "",
          statuses: JSON.stringify(statuses),
          has_car: null,
          has_license: null,
          genders: JSON.stringify(genders),
          min_age: 10,
          max_age: 70,
          radius: 500000,
        });
        this.vacancyTerms = this.getVacancyFilters;
        let vacancy = {
          filters_id: this.vacancyTerms.id,
        };
        let id = this.$route.params.id;
        await this.updateVacancy({ vacancy, id });
        await this.getVacancyById(id);
      } else {
        await this.getVacancyFilterById(filters_id);
        this.vacancyTerms = this.getVacancyFilters;
      }

      this.loaded = true;
    },

    toLocalDate(date) {
      if (!date) return null;
      var utcDate = new Date(date);
      const localeString = utcDate.toLocaleString("nl-NL");
      return localeString.substring(0, localeString.length - 3);
    },

    async onLatlngCalculated(event) {
      let vacancy = JSON.parse(JSON.stringify(this.activeVacancy.vacancy)); // Make copy of object
      vacancy.address.lat = event.lat;
      vacancy.address.lng = event.lng;
      vacancy.address = JSON.stringify(vacancy.address);
      vacancy.terms = JSON.stringify(vacancy.terms);

      const id = vacancy.id;
      await this.updateVacancy({ vacancy, id });
      await this.getVacancyById(id);
    },

    async selfAssignRecruiter() {
      if (!this.activeVacancy.vacancy.recruiter) {
        const user = this.$store.state.users.user;
        const { dispatch } = this.$store;
        if (permission("canAsignSelfVacancy")) {
          const id = this.activeVacancy.vacancy.id;
          let vacancy = {
            rec_id: user.id,
          };
          if (this.latestVacancyStatus.name === "Nieuw") {
            vacancy.status = 2; // Open
          }
          await this.updateVacancy({ vacancy, id }).then(() => {
            this.getVacancyById(id);
            dispatch("alerts/success", "Recruiter toegewezen");
          });
        } else {
          dispatch("alerts/warn", this.$t("vancancy_page.not_authorized"));
        }
      }
    },

    async makeInactive() {
      if (this.getUser.role <= 1) {
        const { dispatch } = this.$store;
        let vacancy = { inactive: true };

        const id = this.activeVacancy.vacancy.id;
        await this.updateVacancy({ vacancy, id }).then(() => {
          dispatch(
            "alerts/success",
            this.$t("vancancy_page.vac_made_inactive")
          );
          setTimeout(() => {
            this.$router.go(-1);
          }, 3000);
        });
      }
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },

    fullName(item) {
      if (item == null || item == "") return "Niet toegewezen";
      return item.first_name + " " + item.last_name;
    },

    latestStatus(item) {
      if (item.lead_statuses.length > 0) {
        return item.lead_statuses[item.lead_statuses.length - 1].status;
      } else {
        return { name: "Geen", color: "#FFD700" };
      }
    },
    latestLead(item) {
      if (item.leads.length > 0) {
        return item.leads[item.leads.length - 1];
      } else {
        return undefined;
      }
    },
    itemExists(item) {
      if (item == null || item == "") return "Onbekend";
      return item;
    },
    hasTerms() {
      if (
        !!this.activeVacancy.vacancy.terms.min_days ||
        !!this.activeVacancy.vacancy.terms.extra_remarks ||
        !!this.activeVacancy.vacancy.terms.dresscode ||
        !!this.activeVacancy.vacancy.terms.extra_requirements ||
        !!this.activeVacancy.vacancy.terms.cao ||
        !!this.activeVacancy.vacancy.terms.initial_scale
      )
        return true;
      return false;
    },
    openCompany(id) {
      this.$router.push(`/bedrijf/${id}`);
    },
    async print() {
      this.toPrint = true;
      const htmlToPaperOptions = {
        name: "_blank",
        specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
        styles: [
          "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
          "https://unpkg.com/kidlat-css/css/kidlat.css",
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: false, // if false, the window will not close after printing
        windowTitle: window.document.title, // override the window title
      };
      setTimeout(() => {
        this.$htmlToPaper("printMe", htmlToPaperOptions, () => {
          this.toPrint = false;
        });
      }, 100);
    },
    getUsersArray(users) {
      let arr = [];
      for (let i = 0; i < users.length; i++) {
        let userObj = users[i].lead_user;
        userObj.latest_status_color = this.latestStatus(
          users[i].lead_user
        ).color;
        userObj.latest_status_name = this.latestStatus(users[i].lead_user).name;
        userObj.fullName = userObj.first_name + " " + userObj.last_name;
        userObj.vac_has_user_id = users[i].id;
        arr.push(userObj);
      }
      return arr;
    },
    addApplicationForm() {
      this.showNewApplicationFormID = true;
    },
    async submitAppForm() {
      const newApp = {
        id: this.newAppChannel + this.newAppID,
        vacancy_id: this.activeVacancy.vacancy.id,
      };
      await this.addAppID(newApp);
      this.newAppChannel = null;
      this.newAppID = null;
      this.showNewApplicationFormID = false;
      this.getVacancyById(this.activeVacancy.vacancy.id);
    },
  },
};
</script>
