<template>
  <v-container fluid class="pa-5">
    <v-card class="pa-1" elevation="0">
      <v-row>
        <v-col md="7">
          <v-row class="pa-3">
            <h2>{{ $t("vacancy_search_terms.recommended") }}</h2>
            <v-divider
              vertical
              inset
              style="padding-left: 10px; padding-left: 10px"
            ></v-divider>
            <v-text-field
              style="padding-top: 0px; padding-left: 10px"
              v-model="filters.search"
              append-icon="mdi-magnify"
              :label="$t('vacancy_search_terms.search_term_matching')"
              single-line
              hide-details
              :loading="!getLoading"
            ></v-text-field>
          </v-row>
        </v-col>
        <v-col sm="3">
          <v-autocomplete
            class="pt-0 mt-1"
            :items="this.radius"
            v-model="filters.selectedRadius"
            :label="$t('vacancy_search_terms.radius_from_vac')"
            item-text="distance"
            item-value="value"
            :disabled="!getLoading"
          ></v-autocomplete>
        </v-col>

        <v-col align="end" md="2">
          <v-btn
            icon
            color="primary"
            class="mb-2"
            @click="clear()"
            :disabled="!getLoading"
          >
            <v-icon dark>mdi-autorenew</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="pb-0 px-2">
        <v-col class="d-flex" cols="12" sm="6">
          <v-autocomplete
            dense
            class="pt-1"
            :items="this.lead_statuses"
            v-model="filters.statuses"
            chips
            deletable-chips
            multiple
            item-text="name"
            item-value="name"
            return-object
            :label="$t('vacancy_search_terms.status')"
            :disabled="!getLoading"
            ><template v-slot:selection="{ attr, on, item, selected }">
              <v-chip
                label
                v-bind="attr"
                :input-value="selected"
                v-on="on"
                close
                :color="item.color"
                @click:close="remove(item)"
                ><div style="color: black">
                  <strong>{{ item.name }}</strong>
                </div></v-chip
              >
            </template></v-autocomplete
          >
        </v-col>
        <v-col class="d-flex" cols="12" sm="6">
          <v-autocomplete
            :items="this.booleans"
            v-model="filters.has_car"
            clearable
            item-text="text"
            item-value="value"
            :label="$t('vacancy_search_terms.car')"
            :disabled="!getLoading"
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-row class="pb-0 px-2">
        <v-col class="d-flex" cols="12" sm="6">
          <v-autocomplete
            :items="this.booleans"
            v-model="filters.has_license"
            clearable
            item-text="text"
            item-value="value"
            :label="$t('vacancy_search_terms.drivers_license')"
            :disabled="!getLoading"
          ></v-autocomplete>
        </v-col>
        <v-col class="d-flex" cols="12" sm="6">
          <v-autocomplete
            dense
            class="pt-1"
            :items="this.genderSelect"
            v-model="filters.gender"
            chips
            deletable-chips
            multiple
            return-object
            :disabled="!getLoading"
            :label="$t('vacancy_search_terms.gender')"
            ><template v-slot:selection="{ attr, on, item, selected }">
              <v-chip
                label
                v-bind="attr"
                :input-value="selected"
                v-on="on"
                close
                @click:close="removeGender(item)"
                ><div style="color: black">
                  <strong>{{ item }}</strong>
                </div></v-chip
              >
            </template></v-autocomplete
          >
        </v-col>
      </v-row>

      <v-row class="pb-0 px-2">
        <v-col class="d-flex" cols="12" sm="2">
          <v-subheader style=""
            >{{ $t("vacancy_search_terms.age_range") }}:</v-subheader
          >
        </v-col>
        <v-col class="d-flex" cols="12" sm="10">
          <v-range-slider
            v-model="filters.range"
            thumb-label
            style="margin-bottom: 10px !important"
            :max="max"
            :min="min"
            hide-details
            :disabled="!getLoading"
          >
            <template v-slot:prepend>
              <v-text-field
                :value="filters.range[0]"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
                @change="$set(filters.range, 0, $event)"
                :disabled="!getLoading"
              ></v-text-field>
            </template>
            <template v-slot:append>
              <v-text-field
                :value="filters.range[1]"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
                @change="$set(filters.range, 1, $event)"
                :disabled="!getLoading"
              ></v-text-field>
              <v-btn
                class="ml-3"
                style="margin-top: -5px !important"
                depressed
                color="primary"
                @click="save"
                :loading="!getLoading"
                >{{ $t("vacancy_search_terms.save") }}</v-btn
              >
            </template>
          </v-range-slider>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { UPDATE_VACANCY_FILTER } from "../../store/action.type";
import {
  SET_LEAD_USERS_DISPLAY_DATA,
  SET_LEAD_USERS_LOADING,
  SET_LEAD_USERS_RELOAD,
} from "../../store/mutation.type";
export default {
  components: {},
  props: {
    parentID: Number,
    searchFilters: Object,
    leadStatuses: Array,
    vacancy_job: Object,
  },
  data: () => ({
    min: 10,
    max: 70,
    radius: [
      {
        distance: "2 km",
        value: 2000,
      },
      {
        distance: "5 km",
        value: 5000,
      },
      {
        distance: "10 km",
        value: 10000,
      },
      {
        distance: "20 km",
        value: 20000,
      },
      {
        distance: "50 km",
        value: 50000,
      },
      {
        distance: "100 km",
        value: 100000,
      },
      {
        distance: "200 km",
        value: 200000,
      },
      {
        distance: "500 km",
        value: 500000,
      },
    ],

    filters: {
      statuses: [],
      // channels: [],
      has_car: null,
      has_license: null,
      gender: [],
      range: [10, 70],
      search: "",
      selectedRadius: 500000,
      // recruiter: "",
      // recruiter_ass: "",
    },
    lead_statuses: [],
    booleans: [
      {
        text: "Yes",
        value: true,
      },
      {
        text: "No",
        value: false,
      },
    ],

    genderSelect: ["Man", "Vrouw", "Transgender", "Non-binair"],
  }),

  computed: {
    ...mapGetters("lead_users", [
      "getLoading",
      "getDisplayData",
      "getFilteredLeadUser",
    ]),
    ...mapGetters("statuses", ["getleadStatuses"]),
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions("vacancy_filters", {
      updateVacancyFilter: UPDATE_VACANCY_FILTER,
    }),
    ...mapMutations("lead_users", {
      setLeadUsersLoading: SET_LEAD_USERS_LOADING,
      setLeadUsersDisplayData: SET_LEAD_USERS_DISPLAY_DATA,
      setLeadUsersReload: SET_LEAD_USERS_RELOAD,
    }),
    async initialize() {
      this.filters = this.searchFilters;
      this.lead_statuses = this.leadStatuses;
    },
    setLoading(bool) {
      this.setLeadUsersLoading(bool);
    },
    setDisplayData(bool) {
      this.setLeadUsersDisplayData(bool);
    },
    setReloadFilter() {
      this.setLeadUsersReload();
    },
    remove(item) {
      let index = -1;
      this.filters.statuses.find(function (status, i) {
        if (status.name === item.name) {
          index = i;
        }
      });
      if (index >= 0) {
        this.filters.statuses.splice(index, 1);
      }
    },
    removeGender(item) {
      let index = -1;
      this.filters.gender.find(function (gender, i) {
        if (gender === item) {
          index = i;
        }
      });
      if (index >= 0) {
        this.filters.gender.splice(index, 1);
      }
    },
    save() {
      this.setDisplayData(false);
      this.setLoading(false);

      this.saveFilter();
    },
    async saveFilter() {
      let id = this.filters.id;
      let filters = {
        search_term: this.filters.search,
        statuses: JSON.stringify(this.filters.statuses),
        has_car: this.filters.has_car,
        has_license: this.filters.has_license,
        genders: JSON.stringify(this.filters.gender),
        min_age: this.filters.range[0],
        max_age: this.filters.range[1],
        radius: this.filters.selectedRadius,
      };
      let data = {
        filtersData: filters,
        id: id,
      };
      await this.updateVacancyFilter(data);
      this.fetchUsers();
    },
    async fetchUsers() {
      let search = this.filters.search;
      let status = [];
      for (let i = 0; i < this.filters.statuses.length; i++) {
        status.push(this.filters.statuses[i].name);
      }

      let params = {
        searchData: search,
        status: JSON.stringify(status),
        car: this.getCar(this.filters.has_car),
        license: this.getLicense(this.filters.has_license),
        gender: JSON.stringify(this.filters.gender),
        age: JSON.stringify(this.filters.range),
        job: JSON.stringify(this.vacancy_job),
      };
      this.setReloadFilter();
    },
    getCar(car) {
      if (car === null) {
        return 2;
      }
      if (car) {
        return 1;
      } else {
        return 0;
      }
    },
    getLicense(license) {
      if (license === null) {
        return 2;
      }
      if (license) {
        return 1;
      } else {
        return 0;
      }
    },
    async clear() {
      const { dispatch } = this.$store;

      this.filters.statuses = [];
      this.filters.has_car = null;
      this.filters.has_license = null;
      this.filters.gender = [];
      this.filters.range = [10, 70];
      this.filters.search = "";
      this.filters.selectedRadius = 500000;

      let id = this.filters.id;
      let statuses = [];
      let genders = [];
      let filters = {
        search_term: "",
        statuses: JSON.stringify(statuses),
        has_car: null,
        has_license: null,
        genders: JSON.stringify(genders),
        min_age: 10,
        max_age: 70,
        radius: 500000,
      };
      let data = {
        filtersData: filters,
        id: id,
      };
      await this.updateVacancyFilter(data);

      this.fetchUsers();
    },
  },
};
</script>
