<template>
  <v-card :loading="!loaded">
    <v-container class="pa-5">
      <v-form ref="form" v-model="valid" lazy-validation v-if="loaded">
        <v-row>
          <v-col md="6">
            <v-autocomplete
              :items="contactMomentStatusesList"
              item-text="name"
              item-value="id"
              required
              :rules="[(v) => !!v || $t('create_contact_moment.required_type')]"
              v-model="formDataFields.type"
              :label="$t('create_contact_moment.contact_moment_type')"
              persistent-hint
              :hint="$t('create_contact_moment.type_hint')"
            ></v-autocomplete>
          </v-col>
          <v-col md="6">
            <v-autocomplete
              :items="vacancies_all_getter"
              item-text="name"
              item-value="id"
              v-model="formDataFields.vacancy_id"
              :label="$t('create_contact_moment.vacancy')"
              persistent-hint
              :hint="$t('create_contact_moment.vacancy_hint')"
            ></v-autocomplete>
          </v-col>
          <v-col md="6" v-if="noStatus || type === '0'">
            <v-autocomplete
              :items="computedStatusesByPhase"
              item-text="name"
              item-value="id"
              v-model="data_fields_status.lead_status_id"
              :label="$t('create_contact_moment.person_status')"
              persistent-hint
              @change="newStatusSelected()"
              :hint="$t('create_contact_moment.person_status_hint')"
            >
              <template slot="item" slot-scope="{ item }">
                {{ item.name }}
                <v-icon
                  v-if="
                    !showAllStatuses &&
                    lead.phase !== null &&
                    item.to_phase > lead.phase &&
                    lead.phase !== 1
                  "
                  color="green"
                  >mdi-arrow-up</v-icon
                >
                <span
                  v-if="
                    !showAllStatuses &&
                    lead.phase !== null &&
                    (item.id === 37 || item.id === 38)
                  "
                  style="color: green"
                  >(phase 7)</span
                >
                <span
                  v-if="
                    !showAllStatuses &&
                    lead.phase !== null &&
                    (item.id === 39 || item.id === 40)
                  "
                  style="color: red"
                  >(phase 8)</span
                >
                <v-icon
                  v-if="
                    !showAllStatuses &&
                    lead.phase !== null &&
                    lead.phase === 1 &&
                    item.to_phase === 3
                  "
                  color="green"
                  >mdi-arrow-up</v-icon
                >
              </template>
            </v-autocomplete>
          </v-col>
          <v-col md="2"
            ><v-checkbox
              v-if="type == 0"
              v-model="showAllStatuses"
              label="Show All Statuses"
            ></v-checkbox
          ></v-col>
          <v-col md="4"
            ><v-checkbox
              v-model="flowChanges.oldLead"
              :label="$t('create_contact_moment.old_leads_flow')"
            ></v-checkbox
          ></v-col>
        </v-row>
        <v-row
          class="px-4"
          v-if="data_fields_status.lead_status_id != null && type === '0'"
        >
          <v-select
            v-model="data_fields_status.sub_statuses_ids"
            :items="computedSubStatuses"
            v-on:input="limiter"
            :rules="[subStatus]"
            item-text="name"
            item-value="id"
            :label="$t('create_contact_moment.sub_status')"
            multiple
            :hint="$t('create_contact_moment.sub_status_hint')"
            persistent-hint
          ></v-select>
        </v-row>
        <div v-if="this.type == 0 && !flowChanges.oldLead">
          <v-card v-if="data_fields_status.lead_status_id == 3" class="ma-4">
            <h4 class="pl-4 pt-4">
              {{ $t("create_contact_moment.plan_intake") }}
            </h4>
            <v-card-text>
              <v-row class="pt-0">
                <v-col class="pt-0">
                  <v-select
                    :items="employees"
                    v-model="flowChanges.intakeRecruiter"
                    :label="$t('create_contact_moment.recruiter')"
                    :rules="[
                      (v) =>
                        !!v || $t('create_contact_moment.required_recruiter'),
                    ]"
                    required
                  >
                    <template v-slot:item="{ item }">
                      {{ item.full_name }}
                    </template>
                    <template v-slot:selection="{ item }">
                      {{ item.full_name }}
                    </template></v-select
                  >
                </v-col>
                <v-col class="pt-0">
                  <DateTimePicker v-model="flowChanges.intakeDateTime" />
                </v-col>
              </v-row>
              <v-row class="pt-0 mt-0">
                <v-col class="pt-0">
                  <v-select
                    :items="channelItems"
                    v-model="selectedChannel"
                    label="Channel"
                    required
                  ></v-select>
                </v-col>
                <v-col class="pt-0">
                  <v-checkbox
                    v-model="flowChanges.addToTeamUp"
                    label="Add to calendar"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <!-- <v-row v-if="selectedChannel === 'Zoom'" class="pt-0 mt-0 mx-1">
                <v-text-field
                  v-model="zoomLink"
                  dense
                  label="Zoom Meeting Link"
                  :rules="[(v) => !!v || 'Zoom Meeting Link is Required']"
                  required
                ></v-text-field>
              </v-row> -->
            </v-card-text>
          </v-card>
          <v-card
            class="ma-4"
            v-if="!flowHasIntake && data_fields_status.lead_status_id > 3"
          >
            <v-banner elevation="3" icon="mdi-alert" color="warning">{{
              $t("create_contact_moment.no_intake_msg")
            }}</v-banner>
          </v-card>
          <v-card
            v-if="
              data_fields_status.lead_status_id > 3 &&
              flowHasIntake &&
              !flowIntaked
            "
            class="ma-4"
          >
            <h4 class="pl-4 pt-4">
              {{ $t("create_contact_moment.handle_intake") }}
            </h4>
            <v-card-text>
              <v-row class="pt-0">
                <v-col class="pt-0">
                  <v-checkbox
                    v-model="flowChanges.intaked"
                    :label="$t('create_contact_moment.intake_completed')"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card
            v-if="
              data_fields_status.lead_status_id == 5 ||
              data_fields_status.lead_status_id == 16 ||
              data_fields_status.lead_status_id == 17
            "
            class="ma-4"
          >
            <v-banner
              elevation="3"
              icon="mdi-alert"
              color="error"
              v-if="!flowIntaked"
              >{{
                $t("create_contact_moment.intake_not_completed_msg")
              }}</v-banner
            >
            <h4 class="pl-4 pt-4">{{ $t("create_contact_moment.place") }}</h4>
            <v-card-text>
              <v-row class="pt-0">
                <v-col class="pt-0">
                  <v-select
                    :items="communityManagers"
                    v-model="flowChanges.placedCm"
                    item-text="full_name"
                    item-value="id"
                    :label="$t('create_contact_moment.com_manager')"
                    :rules="[
                      (v) =>
                        !!v || $t('create_contact_moment.required_com_manager'),
                    ]"
                    required
                  ></v-select>
                </v-col>
                <v-col class="pt-0">
                  <DatePicker v-model="flowChanges.placedDate" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
        <v-row>
          <v-col md="12">
            <v-textarea
              v-model="data_fields.notes"
              filled
              :label="$t('create_contact_moment.notes')"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-btn
            :disabled="!valid || adding"
            color="success"
            class="mr-4"
            @click="buttonClick"
          >
            {{ types[type].buttonText }}
          </v-btn>
        </v-row>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import DateTimePicker from "./DateTimePicker";
import DatePicker from "./DatePicker";
import { permission } from "../../utils/permissions";
import {
  ADD_STATUS_TO_LEAD_USER,
  CREATE_CONTACT_MOMENT,
  CREATE_EVENT,
  END_OF_CONTACT_EMAIL,
  GET_CONTACT_MOMENT_STATUSES,
  GET_LEAD_STATUSES,
  GET_LEAD_USER_BY_ID,
  GET_LEAD_VACANCIES,
  GET_USERS,
  GET_VACANCIES,
  SEND_ACQUISITION_MAIL,
  SEND_RESCHEDULE_MAIL,
  UPDATE_CONTACT_MOMENT,
  UPDATE_FLOW,
  GET_LEADS_PER_PERIOD,
  UPDATE_LEAD,
  NO_CONTACT_EMAIL,
  CREATE_CONVERSATION,
  UPDATE_LEAD_USER,
} from "../../store/action.type";
export default {
  components: { DateTimePicker, DatePicker },
  props: {
    type: String,
    formData: Object,
    parentID: Number,
    lead: Object,
    leadUserName: String,
    email: String,
    phone: String,
    latestStatus: Object,
  },
  data: () => ({
    showAllStatuses: false,
    loaded: false,
    contactMomentStatusesList: [],
    leadStatusesList: [],
    vacanciesList: [],
    channelItems: ["WhatsApp", "Videobellen", "Zoom", "Kantoor"],
    selectedChannel: "WhatsApp",
    zoomLink: "",
    adding: false,
    valid: false,
    data_fields_status: {
      lead_status_id: 0,
      sub_statuses_ids: [],
      selected_status: null,
      subs: 0,
    },
    data_fields: {
      lead_user_id: null,
      lead_user: [],
      type: null,
      comunicator_id: null,
      user_status_id: null,
      notes: null,
      vacancy_id: null,
    },
    flowChanges: {
      intakeRecruiter: null,
      intakeDateTime: null,

      intaked: false,
      placedDate: null,
      placedCm: null,
      oldLead: false,
      addToTeamUp: true,
    },
    filters: {},
    noStatus: false,
  }),

  computed: {
    ...mapGetters("vacancy_flow", ["vacancies_all_getter"]),
    formDataFields() {
      this.updateFormFields();
      return this.data_fields;
    },

    types() {
      return [
        {
          buttonText: this.$t("create_contact_moment.create_contact_moment"),
        },
        {
          buttonText: this.$t("create_contact_moment.customize_contact_moment"),
        },
      ];
    },
    user() {
      return this.$store.state.users.user;
    },

    ...mapState({
      stored_filters: (state) => state.filters.lead_filter,
      employees: (state) =>
        state.users.all.items.employees
          .filter((e) => e.role !== null && e.deactivated == false) //Removed this as roles are a bit odd at the momment
          .sort((a, b) => {
            if (a.first_name < b.first_name) {
              return -1;
            }
            if (a.first_name > b.first_name) {
              return 1;
            }
            return 0;
          })
          .map((e) => {
            return {
              id: e.id,
              full_name: e.first_name + " " + e.last_name,
              cal_id: e.cal_id,
              phone: e.phone,
            };
          }),
      communityManagers: (state) =>
        state.users.all.items.employees
          .filter((e) => e.role !== null) //Removed this as roles are a bit odd at the momment
          .sort((a, b) => {
            if (a.first_name < b.first_name) {
              return -1;
            }
            if (a.first_name > b.first_name) {
              return 1;
            }
            return 0;
          })
          .map((e) => {
            return {
              id: e.id,
              full_name: e.first_name + " " + e.last_name,
            };
          }),
    }),

    leadHasFlow() {
      if (this.lead != null && this.lead.flow != null) {
        return true;
      }
      return false;
    },

    flowHasIntake() {
      if (this.leadHasFlow) {
        if (this.lead.flow.intake_date != null) {
          return true;
        }
      }
      return false;
    },

    flowIntaked() {
      if (this.leadHasFlow) {
        if (this.lead.flow.intake_completed != null) {
          return true;
        }
      }
      return false;
    },

    computedSubStatuses: {
      get() {
        let temp = [];
        for (let i = 0; i < this.leadStatusesList.length; i++) {
          if (this.data_fields_status.lead_status_id != null) {
            if (
              this.leadStatusesList[i].id ==
              this.data_fields_status.lead_status_id
            ) {
              temp = this.leadStatusesList[i].sub_statuses;
            }
          }
        }
        return temp;
      },
      set() {
        return null;
      },
    },
    computedStatusesByPhase: {
      get() {
        let temp = [];
        if (this.lead.phase === null || this.showAllStatuses) {
          temp = this.leadStatusesList;
        } else {
          switch (this.lead.phase) {
            case 1:
              for (let i = 0; i < this.leadStatusesList.length; i++) {
                if (this.leadStatusesList[i].phase === 2) {
                  temp.push(this.leadStatusesList[i]);
                }
              }
              break;
            case 2:
              for (let i = 0; i < this.leadStatusesList.length; i++) {
                if (this.leadStatusesList[i].phase === 2) {
                  temp.push(this.leadStatusesList[i]);
                }
              }
              break;
            case 3:
              for (let i = 0; i < this.leadStatusesList.length; i++) {
                //* Add Doen Poule to the list
                if (this.leadStatusesList[i].id === 16) {
                  temp.push(this.leadStatusesList[i]);
                }
                if (this.leadStatusesList[i].phase === 3) {
                  temp.push(this.leadStatusesList[i]);
                }
              }
              break;
            case 4:
              for (let i = 0; i < this.leadStatusesList.length; i++) {
                if (this.leadStatusesList[i].phase === 4) {
                  temp.push(this.leadStatusesList[i]);
                }
                if (this.leadStatusesList[i].to_phase === 4) {
                  temp.push(this.leadStatusesList[i]);
                }
              }
              break;
            case 5:
              for (let i = 0; i < this.leadStatusesList.length; i++) {
                if (this.leadStatusesList[i].phase === 5) {
                  temp.push(this.leadStatusesList[i]);
                }
                if (this.leadStatusesList[i].to_phase === 5) {
                  temp.push(this.leadStatusesList[i]);
                }
              }

              break;
            case 6:
              for (let i = 0; i < this.leadStatusesList.length; i++) {
                if (this.leadStatusesList[i].phase === 6) {
                  temp.push(this.leadStatusesList[i]);
                }
              }
              break;
            default:
              break;
          }
        }
        return temp;
      },
      set() {
        return null;
      },
    },
  },
  created() {
    this.filters = this.stored_filters;
    if (this.type == 1) {
      this.data_fields = this.formData;
      if (this.formData.user_status) {
        this.data_fields_status.lead_status_id =
          this.formData.user_status.lead_status_id;
        this.noStatus = false;
      } else {
        this.data_fields_status.lead_status_id = null;
        this.noStatus = true;
      }
      this.userSwitch = true;
      this.editMode = true;
    }
    this.initialize();
  },

  methods: {
    ...mapActions("contact_moments", {
      createContactMoment: CREATE_CONTACT_MOMENT,
      updateContactMoment: UPDATE_CONTACT_MOMENT,
    }),
    ...mapActions("flow", { updateFlow: UPDATE_FLOW }),
    ...mapActions("lead_users", {
      getLeadUserById: GET_LEAD_USER_BY_ID,
      addStatusToLeadUser: ADD_STATUS_TO_LEAD_USER,
    }),
    ...mapActions("leads", {
      getLeadVacancies: GET_LEAD_VACANCIES,
      getLeadsPerPeriod: GET_LEADS_PER_PERIOD,
      updateLead: UPDATE_LEAD,
    }),
    ...mapActions("statuses", {
      getContactMomentStatuses: GET_CONTACT_MOMENT_STATUSES,
      getLeadStatuses: GET_LEAD_STATUSES,
    }),
    ...mapActions("teamup", {
      createEvent: CREATE_EVENT,
    }),
    ...mapActions("users", { getUsers: GET_USERS }),
    ...mapActions("vacancy", { getVacancies: GET_VACANCIES }),
    ...mapActions("send_grid", {
      sendAcquisitionMail: SEND_ACQUISITION_MAIL,
      endOfContactEmail: END_OF_CONTACT_EMAIL,
      sendRescheduleMail: SEND_RESCHEDULE_MAIL,
    }),
    ...mapActions("send_grid", { noContactEmail: NO_CONTACT_EMAIL }),
    fullName(item) {
      return item.first_name + " " + item.last_name;
    },
    ...mapActions("whats_app", {
      createConversation: CREATE_CONVERSATION,
    }),
    ...mapActions("lead_users", {
      updateLeadUser: UPDATE_LEAD_USER,
    }),

    async initialize() {
      await this.getLeadVacancies().then((res) => {
        this.vacanciesList = res.vacancies.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
      });
      await this.getContactMomentStatuses().then((res) => {
        this.contactMomentStatusesList = res.contact_moment_statuses;
      });
      await this.getLeadStatuses().then((res) => {
        let activeLeads = [];
        for (let i = 0; i < res.lead_statuses.length; i++) {
          if (
            !res.lead_statuses[i].inactive &&
            res.lead_statuses[i].to_phase !== null
          ) {
            activeLeads.push(res.lead_statuses[i]);
          }
        }
        this.leadStatusesList = activeLeads;
      });

      await this.getUsers().then(() => {});
      this.loaded = true;
    },

    updateFormFields() {
      if (this.type == "1") {
        this.data_fields = this.formData;
        if (this.formData.user_status) {
          this.data_fields_status.lead_status_id =
            this.formData.user_status.lead_status_id;
          this.noStatus = false;
        } else {
          this.data_fields_status.lead_status_id = null;
          this.noStatus = true;
        }
      } else {
        this.data_fields_status = {
          lead_status_id: null,
        };
        this.data_fields = {
          lead_user_id: null,
          type: null,
          comunicator_id: null,
          user_status_id: null,
          notes: null,
          vacancy_id: null,
        };

        if (
          this.lead &&
          this.lead.vacancy_id &&
          this.lead.vacancy &&
          this.lead.vacancy.cm_id
        ) {
          this.data_fields.vacancy_id = this.lead.vacancy_id;
          this.flowChanges.placedCm = this.lead.vacancy.cm_id;
        }
        this.flowChanges.intakeRecruiter = this.$store.state.users.user;
      }
    },

    limiter(e) {
      switch (this.data_fields_status.selected_status.subs) {
        case 0:
          if (e.length > 0) {
            e.pop();
          }
          break;
        case 1:
          if (e.length > 1) {
            e.pop();
          }
          break;
        case 2:
          if (e.length > 1) {
            e.pop();
          }
          break;
        default:
          break;
      }
    },

    subStatus(value) {
      if (
        this.data_fields_status.selected_status.subs === 2 ||
        this.data_fields_status.selected_status.subs === 4
      ) {
        if (value.length == 0) {
          return this.$t("create_contact_moment.one_sub_status_req");
        }
      }
      return true;
    },

    newStatusSelected() {
      this.data_fields_status.sub_statuses_ids = [];
      for (let i = 0; i < this.leadStatusesList.length; i++) {
        if (
          this.leadStatusesList[i].id == this.data_fields_status.lead_status_id
        ) {
          this.data_fields_status.selected_status = this.leadStatusesList[i];
        }
      }
    },

    buttonClick() {
      if (this.type == 0) {
        this.addContactMoment();
      } else if (this.type == 1) {
        this.editContactMoment();
      }
    },

    async editContactMoment() {
      const { dispatch } = this.$store;
      if (this.$refs.form.validate()) {
        if (permission("canCreateLead") || permission("isAdmin")) {
          this.adding = true;

          let contact_moment = {
            lead_user_id: this.parentID,
            type: this.data_fields.type,
            notes: this.data_fields.notes,
            vacancy_id: this.data_fields.vacancy_id,
          };

          const contact_momentId = this.formData.id;

          await this.updateContactMoment({
            contact_moment,
            contact_momentId,
          }).then(() => {
            dispatch(
              "alerts/success",
              this.$t("create_contact_moment.contact_moment_saved")
            );
            this.getLeadUserById(this.parentID);
          });
          this.$emit("close-dialog");
        } else {
          dispatch(
            "alerts/warn",
            this.$t("create_contact_moment.not_authorized_msg")
          );
        }
      } else {
        dispatch(
          "alerts/warn",
          this.$t("create_contact_moment.not_all_fields_completed")
        );
      }
      this.adding = false;
    },

    async addContactMoment() {
      const { dispatch } = this.$store;
      if (this.$refs.form.validate()) {
        if (permission("canCreateLead") || permission("isAdmin")) {
          this.adding = true;
          let tempLeadPhase = null;
          for (let i = 0; i < this.leadStatusesList.length; i++) {
            if (
              this.leadStatusesList[i].id ==
              this.data_fields_status.lead_status_id
            ) {
              tempLeadPhase = this.leadStatusesList[i].to_phase;
              let leadId = this.lead.id;
              let flow = this.lead.flow;

              //check if the current lead exists
              if (this.lead.flow) {
                //check if the is already assigned date
                if (this.lead.flow.first_date === null) {
                  //check if the person status is 'no contact'
                  if (this.data_fields_status.lead_status_id != 11)
                    this.lead.flow.first_date = new Date(Date.now());
                  //updates db
                  await dispatch("flow/updateFlow", { flow, leadId });
                }
              }
            }
          }

          let newContactMoment = {
            lead_user_id: this.parentID,
            type: this.data_fields.type,
            comunicator_id: this.$store.state.users.user.id,
            user_status_id: null,
            notes: this.data_fields.notes,
            vacancy_id: this.data_fields.vacancy_id,
          };

          if (this.data_fields_status.lead_status_id) {
            let leadUserStatus = {};
            if (this.lead && this.lead.flow) {
              leadUserStatus = {
                lead_user_id: newContactMoment.lead_user_id,
                lead_status_id: this.data_fields_status.lead_status_id,
                lead_id: this.lead.flow.lead_id,
                sub_statuses: JSON.stringify(
                  this.data_fields_status.sub_statuses_ids
                ),
              };
            } else {
              leadUserStatus = {
                lead_user_id: newContactMoment.lead_user_id,
                lead_status_id: this.data_fields_status.lead_status_id,
                sub_statuses: JSON.stringify(
                  this.data_fields_status.sub_statuses_ids
                ),
              };
            }

            await this.addStatusToLeadUser(leadUserStatus).then((res) => {
              newContactMoment.user_status_id = res.newLeadUserStatus.id;
            });
            let flowChanged = false;

            let newFlow = {};
            if (this.lead) {
              if (this.lead.flow !== null) {
                newFlow = this.lead.flow;
              }
            }

            if (this.data_fields_status.lead_status_id == 3) {
              // If status is plan intake, the fields for intake planning will be required

              if (this.flowChanges.addToTeamUp) {
                let tempStartDate = new Date(Date.now());

                if (this.flowChanges.intakeDateTime != null) {
                  tempStartDate = new Date(this.flowChanges.intakeDateTime);
                }
                let startDate = tempStartDate.toISOString();
                let tempEndDate = new Date(
                  tempStartDate.setHours(tempStartDate.getHours() + 1)
                );
                let endDate = tempEndDate.toISOString();
                let fullName = this.leadUserName;
                await this.createEvent({
                  sub_calendar_id: this.flowChanges.intakeRecruiter.cal_id,
                  startTime: startDate.slice(0, -5),
                  endTime: endDate.slice(0, -5),
                  title: fullName,
                });
              }

              //* Assign Recruiter to Active Lead
              let lead = {
                rec_id: this.flowChanges.intakeRecruiter.id,
              };
              if (tempLeadPhase != null) {
                lead.phase = tempLeadPhase;
              }
              let leadId = this.lead.id;
              await this.updateLead({ lead, leadId });

              if (this.latestStatus.id === 29) {
                await this.sendRescheduleMail({
                  userId: this.parentID,
                  intakeDateTime: this.flowChanges.intakeDateTime,
                  recruiter: this.flowChanges.intakeRecruiter.full_name,
                  phone: this.flowChanges.intakeRecruiter.phone,
                  recruiter_mail: this.user.email,
                });
              }

              await this.sendAcquisitionMail({
                userId: this.parentID,
                intakeDateTime: this.flowChanges.intakeDateTime,
                recruiter: this.flowChanges.intakeRecruiter.full_name,
                phone: this.flowChanges.intakeRecruiter.phone,
                recruiter_mail: this.user.email,
              });

              newFlow.intake_date = this.flowChanges.intakeDateTime;
              newFlow.intake_plan_ass = this.$store.state.users.user.id;
              newFlow.intake_rec = this.flowChanges.intakeRecruiter.id;

              flowChanged = true;
            } else if (this.data_fields_status.lead_status_id > 3) {
              // The other entry fields
              if (this.flowHasIntake && !this.flowIntaked) {
                // Finish intake
                if (this.flowChanges.intaked == true) {
                  newFlow.intake_completed = this.flowChanges.intaked;
                  flowChanged = true;
                }
              }
              if (
                this.data_fields_status.lead_status_id == 5 ||
                this.data_fields_status.lead_status_id == 16 ||
                this.data_fields_status.lead_status_id == 17
              ) {
                // Geplaatst, DOEN Poule, Zoemer Poule
                let temp = null;
                if (this.flowChanges.placedDate === null) {
                  this.flowChanges.placedDate = new Date(
                    Date.now()
                  ).toISOString();
                  temp = this.flowChanges.placedDate.slice(0, 10);
                } else {
                  temp = this.flowChanges.placedDate;
                }
                newFlow.placed_cm = this.flowChanges.placedCm;
                newFlow.placed_date = temp;

                flowChanged = true;
              }
              if (this.data_fields_status.lead_status_id == 11) {
                await this.endOfContactEmail({
                  userId: this.parentID,
                  recruiter: this.user.first_name + " " + this.user.last_name,
                  recruiter_tel: this.user.phone,
                  recruiter_mail: this.user.email,
                });
              }
            }
            if (flowChanged) {
              const leadId = newFlow.lead_id;
              const flow = newFlow;
              await this.updateFlow({ flow, leadId });
            }
          }

          //* Veryfying tel number format
          const pattern = /^\+(?:[0-9]●?){6,14}[0-9]$/;
          let isValid = pattern.test(this.phone);
          if (this.data_fields_status.lead_status_id == 11) {
            if (this.email != null && this.email != "") {
              this.noContactEmail({
                userId: this.parentID,
                recruiter: this.user.first_name + " " + this.user.last_name,
                recruiter_tel: this.user.phone,
                recruiter_mail: this.user.email,
              });
            } else if (this.phone != null && this.phone != "" && isValid) {
              //* Start a conversation with template
              let data = {
                message: {
                  namespace: "6bf1cd4f_1084_4310_b5de_3c6533b9c158",
                  templateName: "no_contact_3",
                  language: {
                    policy: "deterministic",
                    code: "nl",
                  },
                },
                number: this.phone,
              };
              await this.createConversation(data).then((res) => {
                try {
                  let lead_user = {
                    conversation_id: res.response.id,
                  };
                  let userId = this.parentID;
                  this.updateLeadUser({
                    lead_user: lead_user,
                    userId: userId,
                  }).then(() => {
                    dispatch("alerts/success", "Conversation Created");
                  });
                } catch {
                  dispatch("alerts/error", "Couldn't create a conversation");
                }
              });
            }
          }

          let now = new Date();
          now = now.toISOString().substring(0, 10);
          let past = new Date();
          past.setDate(past.getDate() - 30);
          past = past.toISOString().substring(0, 10);

          var params = {
            applicationStartDate: past,
            applicationEndDate: now,
            contactStartDate: null,
            contactEndDate: null,
            intakeStartDate: null,
            intakeEndDate: null,
          };
          if (this.filters) {
            if (this.filters.application_dates) {
              if (this.filters.application_dates.length > 0) {
                if (
                  this.filters.application_dates[0] >
                  this.filters.application_dates[1]
                ) {
                  params.applicationStartDate =
                    this.filters.application_dates[1];
                  params.applicationEndDate = this.filters.application_dates[0];
                } else {
                  params.applicationStartDate =
                    this.filters.application_dates[0];
                  params.applicationEndDate = this.filters.application_dates[1];
                }
              }
            }
            if (this.filters.contact_dates) {
              if (this.filters.contact_dates.length > 0) {
                if (
                  this.filters.contact_dates[0] > this.filters.contact_dates[1]
                ) {
                  params.contactStartDate = this.filters.contact_dates[1];
                  params.contactEndDate = this.filters.contact_dates[0];
                } else {
                  params.contactStartDate = this.filters.contact_dates[0];
                  params.contactEndDate = this.filters.contact_dates[1];
                }
              }
            }
            if (this.filters.intake_dates) {
              if (this.filters.intake_dates.length > 0) {
                if (
                  this.filters.intake_dates[0] > this.filters.intake_dates[1]
                ) {
                  params.intakeStartDate = this.filters.intake_dates[1];
                  params.intakeEndDate = this.filters.intake_dates[0];
                } else {
                  params.intakeStartDate = this.filters.intake_dates[0];
                  params.intakeEndDate = this.filters.intake_dates[1];
                }
              }
            }
          }

          await this.createContactMoment(newContactMoment).then(() => {
            dispatch(
              "alerts/success",
              this.$t("create_contact_moment.contact_moment_created")
            );
            this.getLeadUserById(newContactMoment.lead_user_id);
            this.clear();
            this.$emit("close-dialog");
            this.$emit("reload");
          });
          await this.getLeadsPerPeriod({ params });
        } else {
          dispatch(
            "alerts/warn",
            this.$t("create_contact_moment.not_authorized_msg")
          );
        }
      } else {
        dispatch(
          "alerts/warn",
          this.$t("create_contact_moment.not_all_fields_completed")
        );
      }
      this.adding = false;
    },
    clear() {
      this.$refs.form.reset();
    },
  },
};
</script>
