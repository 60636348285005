import config from "../../../../config";
import { authHeader } from "../../../utils/auth-header";
import { handleResponse } from "../../../utils/api_functions";
import { logger } from "../../../utils/logger";

import { GET_CLIENTS, GET_CLIENT_DATA } from "../../action.type";
import { SET_CLIENTS } from "../../mutation.type";

export const clients = {
  namespaced: true,
  state: {
    clients: [],
  },
  actions: {
    async [GET_CLIENTS]({ commit }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.analyticApi}/clients`, request)
          .then(handleResponse)
          .then((res) => {
            commit(SET_CLIENTS, res);
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [GET_CLIENT_DATA](_, { clientId, startDate, endDate }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(
          `${config.analyticApi}/clients/${clientId}/${startDate}/${endDate}`,
          request
        )
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
  },
  mutations: {
    [SET_CLIENTS](state, clients) {
      state.clients = clients;
    },
  },
};
