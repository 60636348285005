<template>
  <v-container fluid v-if="vacancy !== null">
    <v-row class="pa-3" align="center">
      <v-col>
        <v-card>
          <VacancyCard :activeVacancy="vacancy" />
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogColumns" max-width="600px">
      <SelectColumns
        :headers="headers"
        type="Candidates"
        v-on:headers-changed="setHeaders($event)"
        v-on:close-dialog="dialogColumns = false"
      />
    </v-dialog>
    <v-dialog v-model="sendEmailDialogueSuitable" width="750px" height="100%">
      <v-card color="card">
        <SendEmail
          v-on:close-dialog="reset()"
          :selectedCandidates="mapCandidates(selectedSuitableCandidates)"
        >
        </SendEmail>
      </v-card>
    </v-dialog>
    <v-dialog v-model="sendEmailDialogueUnsuitable" width="750px" height="100%">
      <v-card color="card">
        <SendEmail
          v-on:close-dialog="reset()"
          :selectedCandidates="mapCandidates(selectedUnSuitableCandidates)"
        >
        </SendEmail>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addToHotlistDialogueSuitable" width="750px">
      <v-card>
        <v-card-title class="ml-2" style="font-size: 1.5rem">
          Create New Hotlist
        </v-card-title>
        <CreateHotlist
          :selectedCandidates="mapCandidates(selectedSuitableCandidates)"
          v-on:close-dialog="reset()"
        />
      </v-card>
    </v-dialog>
    <v-dialog v-model="addToHotlistDialogueUnsuitable" width="750px">
      <v-card>
        <v-card-title class="ml-2" style="font-size: 1.5rem">
          Create New Hotlist
        </v-card-title>
        <CreateHotlist
          :selectedCandidates="mapCandidates(selectedUnSuitableCandidates)"
          v-on:close-dialog="reset()"
        />
      </v-card>
    </v-dialog>
    <v-dialog v-model="selectHotlistDialogueSuitable" width="450px">
      <v-card>
        <AddMembers
          :selectedCandidates="mapCandidates(selectedSuitableCandidates)"
          v-on:close-dialog="reset()"
        ></AddMembers>
      </v-card>
    </v-dialog>
    <v-dialog v-model="selectHotlistDialogueUnsuitable" width="450px">
      <v-card>
        <AddMembers
          :selectedCandidates="mapCandidates(selectedUnSuitableCandidates)"
          v-on:close-dialog="reset()"
        ></AddMembers>
      </v-card>
    </v-dialog>

    <v-card>
      <v-row class="mx-4 mt-4 pt-6" align="center">
        <h2>Applicants</h2>
      </v-row>
      <v-row class="ma-2" align="center">
        <v-col md="8">
          <v-text-field v-model="search" label="Search" dense></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="text-right" align-self="end">
          <v-btn
            class="animatedBtn ml-3 ma-2"
            color="primary"
            @click="dialogColumns = true"
          >
            <v-icon>mdi-bookmark-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card elevation="0" class="px-2">
            <v-card-title class="ml-2" style="font-size: 1.5rem">
              Suitable Applicants
              <v-spacer></v-spacer>
              <v-btn
                :disabled="selectedSuitableCandidates.length == 0"
                class="mr-4 animatedBtn"
                color="primary"
                @click="sendEmailDialogueSuitable = true"
                >Send Email
              </v-btn>
              <v-btn
                :disabled="selectedSuitableCandidates.length == 0"
                class="mr-4 animatedBtn"
                color="primary"
                @click="addToHotlistDialogueSuitable = true"
                >Create New Hotlist
              </v-btn>
              <v-btn
                :disabled="selectedSuitableCandidates.length == 0"
                class="mr-4 animatedBtn"
                color="primary"
                @click="selectHotlistDialogueSuitable = true"
                >Add to Hotlist
              </v-btn>
            </v-card-title>
            <v-data-table
              v-model="selectedSuitableCandidates"
              show-select
              @toggle-select-all="toggleSelectAllSuitable"
              :search="search"
              :headers="headers"
              :items="this.suitableCandidates"
              :footer-props="{
                'items-per-page-options': [5, 10, 15, 20],
              }"
              :items-per-page="5"
              class="elevation-1"
            >
              <template v-slot:[`item.favorite`]="{ item }">
                <v-icon
                  v-if="item.favorite == true"
                  color="yellow"
                  class="mr-2"
                >
                  mdi-star
                </v-icon>
                <v-icon v-else class="mr-2"> mdi-star-outline </v-icon>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  label
                  class="ma-2 statusChip"
                  :color="item.status.color"
                  elevation="4"
                >
                  {{ item.status.name }}
                </v-chip>
              </template>
              <template v-slot:[`item.car`]="{ item }">
                <v-icon v-if="item.car" color="green" class="mr-2">
                  mdi-check
                </v-icon>
                <v-icon v-else class="mr-2" color="red"> mdi-close </v-icon>
              </template>
              <template v-slot:[`item.drivers_license`]="{ item }">
                <v-icon v-if="item.drivers_license" color="green" class="mr-2">
                  mdi-check
                </v-icon>
                <v-icon v-else class="mr-2" color="red"> mdi-close </v-icon>
              </template>
              <template v-slot:[`item.cv`]="{ item }">
                <v-icon :color="item.cv">mdi-file-document</v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card elevation="0" class="px-2">
            <v-card-title class="ml-2" style="font-size: 1.5rem">
              Unsuitable Applicants
              <v-spacer></v-spacer>
              <v-btn
                :disabled="selectedUnSuitableCandidates.length == 0"
                class="mr-4 animatedBtn"
                color="primary"
                @click="sendEmailDialogueUnsuitable = true"
                >Send Email
              </v-btn>
              <v-btn
                :disabled="selectedUnSuitableCandidates.length == 0"
                class="mr-4 animatedBtn"
                color="primary"
                @click="addToHotlistDialogueUnsuitable = true"
                >Create New Hotlist
              </v-btn>
              <v-btn
                :disabled="selectedUnSuitableCandidates.length == 0"
                class="mr-4 animatedBtn"
                color="primary"
                @click="selectHotlistDialogueUnsuitable = true"
                >Add to Hotlist
              </v-btn>
            </v-card-title>

            <v-data-table
              v-model="selectedUnSuitableCandidates"
              show-select
              @toggle-select-all="toggleSelectAllUnSuitable"
              :search="search"
              :headers="headers"
              :items="this.unsuitableCandidates"
              :footer-props="{
                'items-per-page-options': [5, 10, 15, 20],
              }"
              :items-per-page="5"
              class="elevation-1"
            >
              <template v-slot:[`item.favorite`]="{ item }">
                <v-icon v-if="item.favorite" color="yellow" class="mr-2">
                  mdi-star
                </v-icon>
                <v-icon v-else class="mr-2"> mdi-star-outline </v-icon>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  label
                  class="ma-2 statusChip"
                  :color="item.status.color"
                  elevation="4"
                >
                  {{ item.status.name }}
                </v-chip>
              </template>
              <template v-slot:[`item.car`]="{ item }">
                <v-icon v-if="item.car" color="green" class="mr-2">
                  mdi-check
                </v-icon>
                <v-icon v-else class="mr-2" color="red"> mdi-close </v-icon>
              </template>
              <template v-slot:[`item.drivers_license`]="{ item }">
                <v-icon v-if="item.drivers_license" color="green" class="mr-2">
                  mdi-check
                </v-icon>
                <v-icon v-else class="mr-2" color="red"> mdi-close </v-icon>
              </template>
              <template v-slot:[`item.cv`]="{ item }">
                <v-icon :color="item.cv">mdi-file-document</v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import {
  GET_VACANCY_FLOW_BY_ID,
  GET_LEADS_BY_VACANCY_ID,
  UPDATE_EMPLOYEE,
  GET_ALL_HOTLISTS,
  GET_HOTLIST_BY_ID,
} from "../store/action.type";
import VacancyCard from "../components/vacancy_flow/components/VacancyCard.vue";
import SelectColumns from "../components/dialogs/SelectColumns.vue";
import SendEmail from "../components/emails/dialogs/SendEmail.vue";
import CreateHotlist from "../components/hotlists/dialogs/CreateHotlist.vue";
import AddMembers from "../components/hotlists/dialogs/AddMembers.vue";
export default {
  components: {
    VacancyCard,
    SelectColumns,
    SendEmail,
    CreateHotlist,
    AddMembers,
  },
  data: () => ({
    search: "",
    vacancy: null,
    dialogColumns: false,
    allCandidates: [],
    suitableStatuses: [
      "Lead",
      "Sollicitant",
      "Geplaatst",
      "Kansparel",
      "Voorgesteld",
      "Doen Poule",
      "Zoomer Poule",
      "Intake Gepland",
    ],
    suitableCandidates: [],
    unsuitableCandidates: [],
    selectedHeaders: [],
    selectedSuitableCandidates: [],
    selectedUnSuitableCandidates: [],
    sendEmailDialogueSuitable: false,
    sendEmailDialogueUnsuitable: false,
    addToHotlistDialogueSuitable: false,
    addToHotlistDialogueUnsuitable: false,
    selectedHotlist: null,
    selectHotlistDialogueSuitable: false,
    selectHotlistDialogueUnsuitable: false,
  }),
  computed: {
    ...mapState({ currentUser: (state) => state.users.user }),
    ...mapGetters("vacancy_flow", ["active_vacancy_flow_getter"]),
    ...mapGetters("hotlists", ["hotlists_getter", "hotlist_members_getter"]),
    headers() {
      let headers = [
        {
          id: 1,
          text: this.$t("candidates_page.favorite"),
          value: "favorite",
          sortable: false,
          perma: true,
        },
        {
          id: 2,
          text: this.$t("candidates_page.status"),
          value: "status",
          sortable: false,
          perma: true,
        },
        {
          id: 3,
          text: this.$t("candidates_page.name"),
          value: "name",
          sortable: false,
          perma: true,
        },
        {
          id: 4,
          text: "Email",
          value: "email",
          sortable: false,
          perma: true,
        },
        {
          id: 5,
          text: this.$t("candidates_page.place"),
          value: "region",
          sortable: false,
          perma: true,
        },
        {
          id: 6,
          text: this.$t("candidates_page.tel"),
          value: "number",
          sortable: false,
          perma: true,
        },
        {
          id: 7,
          text: this.$t("candidates_page.gender"),
          value: "gender",
          sortable: false,
          align: " d-none",
        },
        {
          id: 8,
          text: "Date of Birth",
          value: "dob",
          align: " d-none",
        },
        {
          id: 9,
          text: this.$t("candidates_page.contract_term"),
          sortable: false,
          value: "contract_term",
          align: " d-none",
        },
        {
          id: 10,
          text: this.$t("candidates_page.job_scope"),
          sortable: false,
          value: "contract_type",
          align: " d-none",
        },
        {
          id: 11,
          text: this.$t("candidates_page.salary_indication"),
          sortable: false,
          value: "sal_indication",
          align: " d-none",
        },
        {
          id: 12,
          text: this.$t("candidates_page.application_date"),
          value: "applied_date",
          align: " d-none",
        },
        {
          id: 13,
          text: this.$t("candidates_page.car"),
          value: "car",
          align: " d-none",
        },
        {
          id: 14,
          text: this.$t("candidates_page.drivers_license"),
          value: "drivers_license",
          align: " d-none",
        },
        {
          id: 15,
          text: this.$t("candidates_page.platform"),
          sortable: false,
          value: "platform",
          align: " d-none",
        },
        {
          id: 16,
          text: "CV",
          value: "cv",
          sortable: false,
          align: " d-none",
        },
      ];
      for (let i = 0; i < headers.length; i++) {
        if (this.selectedHeaders.some((elem) => headers[i].id === elem)) {
          headers[i].align = "";
        }
      }
      return headers;
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions("vacancy_flow", {
      getVacancyFlowById: GET_VACANCY_FLOW_BY_ID,
    }),
    ...mapActions("leads", {
      getLeadsByVacancyId: GET_LEADS_BY_VACANCY_ID,
    }),
    ...mapActions("users", {
      updateEmployee: UPDATE_EMPLOYEE,
    }),
    ...mapActions("hotlists", {
      getAllHotlists: GET_ALL_HOTLISTS,
      getHotlistById: GET_HOTLIST_BY_ID,
    }),

    async init() {
      await this.getAllHotlists();
      await this.getVacancyFlowById(this.$route.params.id);
      await this.getallCandidates();
      await this.filterCandidates();
      this.vacancy = this.active_vacancy_flow_getter;
      //* Checks if the candidate columns in db is null
      if (this.currentUser.candidate_columns != null) {
        // if not assigns the columns from the db to the selected headers
        this.selectedHeaders = JSON.parse(this.currentUser.candidate_columns);
      } else {
        //if its null it saves the selected headers to the DB, in this case probably an empty array = []
        let employee = this.currentUser;
        let empId = this.currentUser.id;
        employee.candidate_columns = JSON.stringify(this.selectedHeaders);
        await this.updateEmployee({ employee, empId });
      }
    },
    setHeaders(e) {
      this.selectedHeaders = e;
    },
    latestStatus(item) {
      if (item.lead_statuses.length > 0) {
        return item.lead_statuses[item.lead_statuses.length - 1].status;
      } else {
        return { name: "Geen", color: "#FFD700" };
      }
    },
    latestLead(item) {
      if (item.leads.length > 0) {
        return item.leads[item.leads.length - 1];
      }
    },
    cvParsed(item) {
      if (item.parsedCv == null || item.parsedCv == "") return "red";
      if (item.textCv == null || item.textCv == "") return "yellow";
      return "green";
    },
    async getallCandidates() {
      await this.getLeadsByVacancyId(this.$route.params.id).then((res) =>
        res.leads.forEach((element) => {
          this.allCandidates.push({
            id: element.id,
            userId: element.lead_user,
            first_name: element.user.first_name,
            last_name: element.user.last_name,
            name: element.user.first_name + " " + element.user.last_name,
            email: element.user.email,
            status: {
              name: this.latestStatus(element.user).name,
              color: this.latestStatus(element.user).color,
            },
            region: element.user.region,
            number: element.user.tel_number,
            gender: element.user.gender,
            dob: element.user.dob,
            contract_term: element.user.contract_term,
            contract_type: element.user.contract_type,
            sal_indication: element.user.sal_indication,
            applied_date: element.applied_date,
            cv: this.cvParsed(element.user),
            favorite: element.user.favorite,
            car: element.user.car,
            drivers_license: element.user.drivers_license,
            platform: element.user.platform,
            createdAt: element.createdAt,
          });
        })
      );
      return this.allCandidates;
    },
    filterCandidates() {
      //Filters the candidates based on the current status and sorts them by created at date
      let filteredCandidates = [];
      let sortedCandidates = this.allCandidates.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      sortedCandidates.forEach((element) => {
        let existingUser = filteredCandidates.some(
          (item) => item.userId === element.userId
        );
        if (!existingUser) {
          filteredCandidates.push(element);
        }
      });
      this.allCandidates = filteredCandidates;

      this.allCandidates.forEach((element) => {
        let isSuitable = this.suitableStatuses.includes(element.status.name);

        if (isSuitable) {
          this.suitableCandidates.push(element);
        } else {
          this.unsuitableCandidates.push(element);
        }
      });
    },
    toggleSelectAllSuitable() {
      if (
        this.selectedSuitableCandidates.length ===
        this.suitableCandidates.length
      ) {
        this.selectedSuitableCandidates = [];
      } else {
        this.selectedSuitableCandidates = this.suitableCandidates;
      }
    },
    toggleSelectAllUnSuitable() {
      if (
        this.selectedUnSuitableCandidates.length ===
        this.unsuitableCandidates.length
      ) {
        this.selectedUnSuitableCandidates = [];
      } else {
        this.selectedUnSuitableCandidates = this.unsuitableCandidates;
      }
    },
    reset() {
      this.addToHotlistDialogueSuitable = false;
      this.addToHotlistDialogueUnsuitable = false;
      this.updateHotlistDialogue = false;
      this.selectedSuitableCandidates = [];
      this.selectedUnSuitableCandidates = [];
      this.sendEmailDialogueSuitable = false;
      this.sendEmailDialogueUnsuitable = false;
      this.addToHotlistDialogueSuitable = false;
      this.addToHotlistDialogueUnsuitable = false;
      this.selectedHotlist = null;
      this.selectHotlistDialogueSuitable = false;
      this.selectHotlistDialogueUnsuitable = false;
      this.getAllHotlists();
    },

    mapCandidates(candidates) {
      //Maps the candidates to have first name, last name, id, email
      let mappedCandidates = [];
      candidates.forEach((element) => {
        mappedCandidates.push({
          id: element.userId,
          first_name: element.first_name,
          last_name: element.last_name,
          email: element.email,
        });
      });
      return mappedCandidates;
    },
  },
};
</script>
<style scoped>
.animatedBtn:hover {
  transform: scale(1.1);
}
</style>
