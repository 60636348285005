<template>
  <v-card rounded="s" ml="800pxz" mr="auto">
    <img
      :src="
        'https://chart.googleapis.com/chart?cht=qr&chs=' +
        300 +
        'x' +
        300 +
        '&chl=' +
        generateQRString
      "
    />
  </v-card>
</template>

<script>
export default {
  props: ["lead"],
  data: () => ({
    qrData: "",
  }),
  methods: {},
  computed: {
    generateQRString: function () {
      let QRString = "";

      //Marking the beginning of the Virtual Contact Card
      QRString += "BEGIN:VCARD" + "\n";

      //array of elements to display on QR code
      let qrElements = [
        "N:" + this.lead.first_name + ";" + "CLLBR " + this.lead.last_name,
        "EMAIL:" + this.lead.email,
        "TEL:" + this.lead.tel_number,
      ];

      qrElements.forEach((element) => {
        QRString += element + "\n";
      });
      //Marking the end of the Virtual Contact Card
      QRString += "End:VCARD" + "\n";

      let QRCode = encodeURIComponent(QRString);
      this.$emit("QRCode", QRCode);

      return QRCode;
    },
  },
};
</script>
