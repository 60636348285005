<template>
  <v-container fluid>
    <v-dialog v-model="createVacancyFlowDialog" max-width="600px">
      <CreateVacancyFlow
        v-on:close-dialog="createVacancyFlowDialog = false"
        @reloadTables="refresh()"
      ></CreateVacancyFlow>
    </v-dialog>
    <v-dialog v-model="dialogColumns" max-width="600px">
      <SelectColumns
        :headers="headers"
        type="VacancyFlow"
        v-on:headers-changed="setHeaders($event)"
        v-on:close-dialog="dialogColumns = false"
      />
    </v-dialog>
    <v-dialog v-model="editVacancy" max-width="650px">
      <QuickEditVacancy
        size="small"
        :activeVacancy="activeVacancy"
        v-on:close-dialog="closeVacancy"
      />
    </v-dialog>

    <v-row class="pa-3" align="center" justify="space-between">
      <v-col md="3">
        <v-text-field v-model="search" label="Search" dense></v-text-field>
        <v-spacer> </v-spacer>
      </v-col>
      <v-col md="auto" align-self="end">
        <div class="mb-2">
          <v-btn
            class="refreshBtn ml-3 ma-2"
            color="primary"
            outlined
            @click="refresh()"
          >
            <v-icon> mdi-refresh </v-icon>
          </v-btn>
          <v-btn
            class="newItemBtn ml-3 ma-2"
            color="primary"
            @click="dialogColumns = true"
          >
            <v-icon>mdi-bookmark-outline</v-icon>
          </v-btn>
          <v-btn
            class="newItemBtn ml-3 ma-2"
            color="primary"
            @click="createVacancy()"
          >
            Add new
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-tabs v-if="vacancy_flow_list.length">
      <v-tab elevation="2">
        No Category
        <v-chip
          label
          align="center"
          class="ma-3 pa-2"
          color="primary"
          outlined
          small
          >{{ vacancy_no_flow_list.length }}
        </v-chip>
      </v-tab>
      <draggable
        class="d-flex"
        v-model="vacancy_flow_list"
        @change="updateVacancyFlowOrder"
      >
        <v-tab
          elevation="2"
          v-for="category in vacancy_flow_list"
          :key="category.id"
        >
          {{ category.name }}
          <v-chip
            label
            align="center"
            class="ma-3 pa-2"
            color="primary"
            outlined
            small
            >{{ category.Vacancies_flow.length }}
          </v-chip>
        </v-tab>
      </draggable>
      <v-tab-item>
        <v-card elevation="0">
          <v-data-table
            :search="search"
            :headers="other_headers"
            :items="vacancy_no_flow_list"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50],
            }"
            :items-per-page="30"
            class="elevation-1"
            no-data-text="No Vacancies"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-spacer></v-spacer>
                <v-chip label outlined color="primary" class="float-right">
                  TOTAL HOURS:
                  {{ calculateAverageHours(vacancy_no_flow_list) }}</v-chip
                >
              </v-toolbar>
            </template>
            <template v-slot:[`item.open`]="{ item }">
              <td class="pointerOnHover pr-0 pl-2">
                <v-btn
                  class="newItemBtn"
                  @click="openVacancyFlowNewTab(item)"
                  color="primary"
                  icon
                  dense
                >
                  <v-icon size="20"> mdi-open-in-new </v-icon>
                </v-btn>
              </td>
            </template>
            <template v-slot:[`item.isHot`]="{ item }">
              <td class="pointerOnHover px-0">
                <v-btn
                  class="newItemBtn"
                  @click="
                    inTableEdit(
                      item.isHot == null ? true : !item.isHot,
                      item.id,
                      'isHot'
                    )
                  "
                  :color="item.isHot ? 'red lighten-1' : ''"
                  icon
                  dense
                >
                  <v-icon size="20"> mdi-fire </v-icon>
                </v-btn>
              </td>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <td class="px-0">
                <v-btn
                  class="newItemBtn px-0"
                  @click="viewVacancy(item)"
                  color="primary"
                  icon
                >
                  <v-icon> mdi-eye </v-icon>
                </v-btn>
              </td>
            </template>
            <template v-slot:[`item.edit`]="{ item }">
              <td class="px-0">
                <v-btn
                  class="newItemBtn px-0"
                  :color="
                    isEditing && isEditingItem == item.id
                      ? 'green lighten-1'
                      : 'primary'
                  "
                  @click="editItem(item)"
                  icon
                >
                  <v-icon v-if="isEditing && isEditingItem == item.id" size="20"
                    >mdi-check</v-icon
                  >
                  <v-icon v-else size=" 20"> mdi-pencil </v-icon>
                </v-btn>
              </td>
            </template>
            <template v-slot:[`item.Vacancy_status`]="{ item }">
              <v-select
                v-model="item.Vacancy_status"
                v-if="isEditing && isEditingItem == item.id"
                :items="vacancy_statuses_getter"
                item-text="name"
                item-value="id"
                class="inlineSelect"
                @change="inTableEdit($event, item.id, 'status')"
                dense
              ></v-select>
              <v-chip
                label
                v-else
                class="ma-2 statusChip"
                :color="item.Vacancy_status.color"
                dark
                elevation="4"
              >
                {{ item.Vacancy_status.name }}
              </v-chip>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <v-text-field
                v-model="item.name"
                v-if="isEditing && isEditingItem == item.id"
                class="inlineSelect"
                @change="inTableEdit($event, item.id, 'name')"
                dense
              >
              </v-text-field>
              <div v-else>{{ item.name }}</div>
            </template>
            <template v-slot:[`item.city`]="{ item }">
              <v-autocomplete
                :items="cities"
                v-model="item.city"
                v-if="isEditing && isEditingItem == item.id"
                item-text="city"
                item-value="city"
                class="inlineSelect"
                @change="inTableEdit($event, item.id, 'city')"
                dense
              >
              </v-autocomplete>
              <div v-else>{{ item.city }}</div>
            </template>
            <template v-slot:[`item.workHours`]="{ item }">
              <v-text-field
                v-model="item.minimumHours"
                v-if="isEditing && isEditingItem == item.id"
                class="inlineSelect"
                @change="inTableEdit($event, item.id, 'minimumHours')"
                type="number"
                required
                min="0"
                label="Min"
              >
              </v-text-field>
              <v-text-field
                v-model="item.maximumHours"
                v-if="isEditing && isEditingItem == item.id"
                class="inlineSelect"
                @change="inTableEdit($event, item.id, 'maximumHours')"
                type="number"
                required
                min="0"
                label="Max"
              >
              </v-text-field>
              <div v-else>
                {{ getHours(item.minimumHours, item.maximumHours) }}
              </div>
            </template>
            <template v-slot:[`item.category`]="{ item }">
              <v-select
                :items="categories_getter"
                item-text="name"
                item-value="id"
                class="inlineSelect"
                @change="inTableEdit($event, item.id, 'category')"
                dense
                clearable
              ></v-select>
            </template>
            <template v-slot:[`item.Recruiter`]="{ item }">
              <v-select
                v-model="item.Recruiter"
                v-if="isEditing && isEditingItem == item.id"
                :items="employees_getter"
                item-text="name"
                item-value="id"
                class="inlineSelect"
                @change="inTableEdit($event, item.id, 'recruiter')"
                dense
                clearable
              >
              </v-select>

              <div v-else-if="item.Recruiter">
                {{ item.Recruiter.first_name + " " + item.Recruiter.last_name }}
              </div>
            </template>
            <template v-slot:[`item.Company`]="{ item }">
              <v-autocomplete
                v-if="isEditing && isEditingItem == item.id"
                v-model="item.Company"
                :items="companies_getter"
                item-text="name"
                item-value="id"
                class="inlineSelect"
                @change="inTableEdit($event, item.id, 'company')"
                dense
                clearable
              ></v-autocomplete>

              <div v-else-if="item.Company">
                {{ item.Company.name }}
              </div>
            </template>
            <template v-slot:[`item.startDate`]="{ item }">
              <div>
                {{ item.startDate }}
              </div>
            </template>
            <template v-slot:[`item.hiringType`]="{ item }">
              <v-select
                v-model="item.hiringType"
                v-if="isEditing && isEditingItem == item.id"
                :items="availableTypes"
                class="inlineSelect"
                @change="inTableEdit($event, item.id, 'hiringType')"
                dense
                clearable
              ></v-select>

              <div v-else>
                {{ item.hiringType }}
              </div>
            </template>
            <template v-slot:[`item.language`]="{ item }">
              <v-autocomplete
                v-if="isEditing && isEditingItem == item.id"
                background-color="cardFields"
                dense
                v-model="item.language"
                :items="availableLanguages"
                @change="inTableEdit($event, item.id, 'language')"
                multiple
              />
              <div v-else>
                <p v-for="(lang, i) in item.language" :key="i">
                  {{ lang }}
                </p>
              </div>
            </template>
            <template v-slot:[`item.requiredExperience`]="{ item }">
              <v-select
                v-model="item.requiredExperience"
                v-if="isEditing && isEditingItem == item.id"
                :items="requiredExperienceOptions"
                class="inlineSelect"
                @change="inTableEdit($event, item.id, 'requiredExperience')"
                dense
              ></v-select>

              <div v-else>
                {{
                  item.requiredExperience !== null
                    ? item.requiredExperience === true
                      ? "Yes"
                      : "No"
                    : "No Data"
                }}
              </div>
            </template>
            <template v-slot:[`item.hourlyRate`]="{ item }">
              <v-text-field
                v-model="item.hourlyRate"
                v-if="isEditing && isEditingItem == item.id"
                class="inlineSelect"
                @change="inTableEdit($event, item.id, 'hourlyRate')"
                dense
                clearable
              ></v-text-field>
              <div v-else>
                {{ "€" + item.hourlyRate }}
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
      <v-tab-item v-for="category in vacancy_flow_list" :key="category.id">
        <v-card elevation="0">
          <v-menu
            v-model="showMenu"
            :position-x="x"
            :position-y="y"
            absolute
            offset-y
          >
            <v-list dense>
              <v-list-item @click="sendToTop()" dense>
                <v-list-item-title>Send to top</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-data-table
            :headers="headers"
            :items="category.Vacancies_flow"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50],
            }"
            :items-per-page="30"
            class="elevation-1"
            :custom-sort="customSort"
            no-data-text="No Vacancies"
          >
            <template v-slot:top="props">
              <v-toolbar flat>
                <v-spacer></v-spacer>
                <v-chip label outlined color="primary" class="float-right">
                  TOTAL HOURS: {{ calculateAverageHours(props.items) }}</v-chip
                >
              </v-toolbar>
            </template>
            <template v-slot:body="props">
              <draggable
                :list="props.items"
                tag="tbody"
                @end="endDrag(props.items, category.Vacancies_flow)"
              >
                <tr v-for="vacancy in props.items" :key="vacancy.id">
                  <td class="pr-0 pl-2" @contextmenu="show($event, vacancy)">
                    <v-icon color="grey lighten-1">
                      mdi-drag-horizontal
                    </v-icon>
                  </td>
                  <td class="pointerOnHover pr-0 pl-2">
                    <v-btn
                      class="newItemBtn"
                      @click="openVacancyFlowNewTab(vacancy)"
                      color="primary"
                      icon
                      dense
                    >
                      <v-icon size="20"> mdi-open-in-new </v-icon>
                    </v-btn>
                  </td>
                  <td class="pointerOnHover px-0">
                    <v-btn
                      class="newItemBtn"
                      @click="
                        inTableEdit(
                          vacancy.isHot == null ? true : !vacancy.isHot,
                          vacancy.id,
                          'isHot'
                        )
                      "
                      :color="vacancy.isHot ? 'red lighten-1' : ''"
                      icon
                      dense
                    >
                      <v-icon size="20"> mdi-fire </v-icon>
                    </v-btn>
                  </td>
                  <td class="pointerOnHover px-0">
                    <v-btn
                      class="newItemBtn"
                      @click="viewVacancy(vacancy)"
                      color="primary"
                      icon
                      dense
                    >
                      <v-icon> mdi-eye </v-icon>
                    </v-btn>
                  </td>
                  <td class="px-0">
                    <v-btn
                      class="newItemBtn"
                      :color="
                        isEditing && isEditingItem == vacancy.id
                          ? 'green lighten-1'
                          : 'primary'
                      "
                      @click="editItem(vacancy)"
                      icon
                    >
                      <v-icon
                        v-if="isEditing && isEditingItem == vacancy.id"
                        size=" 20"
                        class=""
                        >mdi-check</v-icon
                      >
                      <v-icon v-else size=" 20" class=""> mdi-pencil </v-icon>
                    </v-btn>
                  </td>
                  <td>
                    <v-select
                      v-model="vacancy.Vacancy_status"
                      v-if="isEditing && isEditingItem == vacancy.id"
                      :items="vacancy_statuses_getter"
                      item-text="name"
                      item-value="id"
                      class="inlineSelect"
                      @change="inTableEdit($event, vacancy.id, 'status')"
                      dense
                    ></v-select>
                    <v-chip
                      label
                      v-else
                      class="ma-2 statusChip"
                      :color="vacancy.Vacancy_status.color"
                      dark
                    >
                      {{ vacancy.Vacancy_status.name }}
                    </v-chip>
                  </td>
                  <td>
                    <v-text-field
                      v-model="vacancy.name"
                      v-if="isEditing && isEditingItem == vacancy.id"
                      class="inlineSelect"
                      @change="inTableEdit($event, vacancy.id, 'name')"
                      dense
                    >
                    </v-text-field>
                    <div v-else>{{ vacancy.name }}</div>
                  </td>
                  <td>
                    <v-autocomplete
                      :items="cities"
                      v-model="vacancy.city"
                      v-if="isEditing && isEditingItem == vacancy.id"
                      item-text="city"
                      item-value="city"
                      class="inlineSelect"
                      @change="inTableEdit($event, vacancy.id, 'city')"
                      dense
                    >
                    </v-autocomplete>
                    <div v-else>{{ vacancy.city }}</div>
                  </td>
                  <td>
                    <v-text-field
                      v-model="vacancy.minimumHours"
                      v-if="isEditing && isEditingItem == vacancy.id"
                      class="inlineSelect"
                      @change="inTableEdit($event, vacancy.id, 'minimumHours')"
                      type="number"
                      required
                      min="0"
                      label="Min"
                    >
                    </v-text-field>
                    <v-text-field
                      v-model="vacancy.maximumHours"
                      v-if="isEditing && isEditingItem == vacancy.id"
                      class="inlineSelect"
                      @change="inTableEdit($event, vacancy.id, 'maximumHours')"
                      type="number"
                      required
                      min="0"
                      label="Max"
                    >
                    </v-text-field>
                    <div v-else>
                      {{ getHours(vacancy.minimumHours, vacancy.maximumHours) }}
                    </div>
                  </td>
                  <td v-if="selectedHeaders.includes(7)">
                    <v-select
                      v-model="vacancy.Recruiter"
                      v-if="isEditing && isEditingItem == vacancy.id"
                      :items="employees_getter"
                      item-text="name"
                      item-value="id"
                      class="inlineSelect"
                      @change="inTableEdit($event, vacancy.id, 'recruiter')"
                      dense
                      clearable
                    >
                    </v-select>

                    <div v-else-if="vacancy.Recruiter">
                      {{
                        vacancy.Recruiter.first_name +
                        " " +
                        vacancy.Recruiter.last_name
                      }}
                    </div>
                  </td>
                  <td v-if="selectedHeaders.includes(8)">
                    <v-autocomplete
                      v-model="vacancy.Company"
                      v-if="isEditing && isEditingItem == vacancy.id"
                      :items="companies_getter"
                      item-text="name"
                      item-value="id"
                      class="inlineSelect"
                      @change="inTableEdit($event, vacancy.id, 'company')"
                      dense
                      clearable
                    >
                    </v-autocomplete>

                    <div v-else-if="vacancy.Company">
                      {{ vacancy.Company.name }}
                    </div>
                  </td>
                  <td v-if="selectedHeaders.includes(9)">
                    <div>
                      {{ vacancy.startDate }}
                    </div>
                  </td>
                  <td v-if="selectedHeaders.includes(10)">
                    <v-select
                      v-model="vacancy.hiringType"
                      v-if="isEditing && isEditingItem == vacancy.id"
                      :items="availableTypes"
                      class="inlineSelect"
                      @change="inTableEdit($event, vacancy.id, 'hiringType')"
                      dense
                      clearable
                    ></v-select>

                    <div v-else>
                      {{ vacancy.hiringType }}
                    </div>
                  </td>
                  <td v-if="selectedHeaders.includes(11)">
                    <v-select
                      v-model="vacancy.category"
                      v-if="isEditing && isEditingItem == vacancy.id"
                      :items="categories_getter"
                      item-text="name"
                      item-value="id"
                      class="inlineSelect"
                      @change="inTableEdit($event, vacancy.id, 'category')"
                      dense
                      clearable
                    >
                    </v-select>
                    <div v-else>{{ category.name }}</div>
                  </td>

                  <td v-if="selectedHeaders.includes(12)">
                    <v-autocomplete
                      v-if="isEditing && isEditingItem == vacancy.id"
                      background-color="cardFields"
                      dense
                      v-model="vacancy.language"
                      :items="availableLanguages"
                      @change="inTableEdit($event, vacancy.id, 'language')"
                      multiple
                    />
                    <div v-else>
                      <p v-for="(lang, i) in vacancy.language" :key="i">
                        {{ lang }}
                      </p>
                    </div>
                  </td>
                  <td v-if="selectedHeaders.includes(13)">
                    <v-select
                      v-model="vacancy.requiredExperience"
                      v-if="isEditing && isEditingItem == vacancy.id"
                      :items="requiredExperienceOptions"
                      class="inlineSelect"
                      @change="
                        inTableEdit($event, vacancy.id, 'requiredExperience')
                      "
                      dense
                    ></v-select>

                    <div v-else>
                      {{
                        vacancy.requiredExperience !== null
                          ? vacancy.requiredExperience === true
                            ? "Yes"
                            : "No"
                          : "No Data"
                      }}
                    </div>
                  </td>
                  <td v-if="selectedHeaders.includes(14)">
                    <v-text-field
                      v-model="vacancy.hourlyRate"
                      v-if="isEditing && isEditingItem == vacancy.id"
                      class="inlineSelect"
                      @change="inTableEdit($event, vacancy.id, 'hourlyRate')"
                      dense
                      clearable
                    ></v-text-field>

                    <div v-else>
                      {{ "€" + vacancy.hourlyRate }}
                    </div>
                  </td>

                  <td></td>
                </tr>
              </draggable>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapState } from "vuex";
import SelectColumns from "../components/dialogs/SelectColumns.vue";
import draggable from "vuedraggable";
import { cities } from "../utils/cities_nl";
import {
  GET_VACANCY_FLOW,
  GET_VACANCY_NO_FLOW,
  UPDATE_VACANCY_FLOW,
  BULK_UPDATE_VACANCY_FLOW,
  SYNC_LISTS,
  GET_VACANCY_STATUSES,
  UPDATE_EMPLOYEE,
} from "../store/action.type";
import CreateVacancyFlow from "../components/vacancy_flow/dialogs/CreateVacancyFlow";
import QuickEditVacancy from "../components/vacancy_flow/dialogs/QuickEditVacancy";
export default {
  components: {
    draggable,
    CreateVacancyFlow,
    SelectColumns,
    QuickEditVacancy,
  },
  data: () => ({
    search: "",
    createVacancyFlowDialog: false,
    activeVacancy: null,
    editVacancy: false,
    dialogColumns: false,
    selectedHeaders: [],
    isEditing: false,
    isEditingItem: null,
    availableTypes: ["Direct Hiring", "Outsourcing"],
    availableLanguages: ["English", "Dutch"],
    vacancy_flow_list: [],
    vacancy_no_flow_list: [],
    requiredExperienceOptions: [
      {
        text: "Yes",
        value: true,
      },
      {
        text: "No",
        value: false,
      },
      {
        text: "N/A",
        value: null,
      },
    ],
    x: 0,
    y: 0,
    showMenu: false,
    selectedRow: null,
  }),

  computed: {
    ...mapState({ currentUser: (state) => state.users.user }),
    ...mapGetters("vacancy_flow", [
      "vacancy_flow_getter",
      "vacancy_no_flow_getter",
      "categories_getter",
    ]),
    ...mapGetters("filters", ["employees_getter", "companies_getter"]),
    ...mapGetters("statuses", ["vacancy_statuses_getter"]),
    ...mapGetters("users", ["getUser"]),
    cities() {
      return cities;
    },
    headers() {
      let headers = [
        {
          id: 1,
          text: "",
          sortable: false,
          perma: true,
          width: "1%",
        },
        {
          text: "",
          sortable: false,
          perma: true,
          width: "1%",
        },
        {
          text: "",
          value: "isHot",
          sortable: false,
          perma: true,
          width: "1%",
        },
        {
          id: 2,
          text: "",
          sortable: false,
          perma: true,
          width: "1%",
        },
        {
          text: "",
          value: "edit",
          sortable: false,
          perma: true,
          width: "1%",
        },
        {
          id: 3,
          text: "Status",
          value: "Vacancy_status",
          sortable: false,
          perma: true,
        },
        {
          id: 4,
          text: "Name",
          value: "name",
          sortable: false,
          perma: true,
        },
        {
          id: 5,
          text: "City",
          value: "city",
          sortable: false,
          perma: true,
        },

        {
          id: 6,
          text: "Work Hours",
          value: "workHours",
          sortable: false,
          perma: true,
        },
        {
          id: 7,
          text: "Recruiter",
          value: "Recruiter",
          sortable: false,
          align: " d-none",
        },
        {
          id: 8,
          text: "Company",
          value: "Company",
          sortable: false,
          align: " d-none",
        },
        {
          id: 9,
          text: "Start Date",
          value: "startDate",
          sortable: false,
          align: " d-none",
        },
        {
          id: 10,
          text: "Hiring Type",
          value: "hiringType",
          sortable: false,
          align: " d-none",
        },
        {
          id: 11,
          text: "Category",
          value: "category",
          sortable: false,
          align: " d-none",
        },
        {
          id: 12,
          text: "Language",
          value: "language",
          sortable: false,
          align: " d-none",
        },
        {
          id: 13,
          text: "Required Experience",
          value: "requiredExperience",
          sortable: false,
          align: " d-none",
        },
        {
          id: 14,
          text: "Hourly Rate",
          value: "hourlyRate",
          sortable: false,
          align: " d-none",
        },
      ];
      for (let i = 0; i < headers.length; i++) {
        if (this.selectedHeaders.some((elem) => headers[i].id === elem)) {
          headers[i].align = "";
        }
      }
      return headers;
    },
    other_headers() {
      //* Vacancies without category
      let other_headers = [
        {
          text: "",
          value: "open",
          sortable: false,
          perma: true,
          width: "1%",
        },
        {
          text: "",
          value: "isHot",
          sortable: false,
          perma: true,
          width: "1%",
        },
        {
          id: 2,
          text: "",
          value: "action",
          sortable: false,
          width: "1%",
          perma: true,
          padding: "0",
        },
        {
          text: "",
          value: "edit",
          sortable: false,
          perma: true,
          width: "1%",
        },
        {
          id: 3,
          text: "Status",
          value: "Vacancy_status",
          sortable: false,
          width: "10%",
          perma: true,
        },
        {
          id: 4,
          text: "Name",
          value: "name",
          sortable: false,
          perma: true,
        },
        {
          id: 5,
          text: "City",
          value: "city",
          sortable: false,
          perma: true,
        },
        {
          id: 6,
          text: "Work Hours",
          value: "workHours",
          sortable: false,
          perma: true,
        },
        {
          id: 7,
          text: "Recruiter",
          value: "Recruiter",
          sortable: false,
          align: " d-none",
        },
        {
          id: 8,
          text: "Company",
          value: "Company",
          sortable: false,
          width: "20%",
          align: " d-none",
        },
        {
          id: 9,
          text: "Start Date",
          value: "startDate",
          sortable: false,
          width: "15%",
          align: " d-none",
        },
        {
          id: 10,
          text: "Hiring Type",
          value: "hiringType",
          sortable: false,
          align: " d-none",
        },
        {
          id: 11,
          text: "Category",
          value: "category",
          sortable: false,
          perma: true,
          width: "15%",
        },
        {
          id: 12,
          text: "Language",
          value: "language",
          sortable: false,
          align: " d-none",
        },
        {
          id: 13,
          text: "Required Experience",
          value: "requiredExperience",
          sortable: false,
          align: " d-none",
        },
        {
          id: 14,
          text: "Hourly Rate",
          value: "hourlyRate",
          sortable: false,
          align: " d-none",
        },
      ];
      for (let i = 0; i < other_headers.length; i++) {
        if (this.selectedHeaders.some((elem) => other_headers[i].id === elem)) {
          other_headers[i].align = "";
        }
      }
      return other_headers;
    },
  },

  created() {
    this.init();
  },

  methods: {
    ...mapActions("vacancy_flow", {
      getVacancyFlow: GET_VACANCY_FLOW,
      getVacancyNoFlow: GET_VACANCY_NO_FLOW,
      updateVacancyFlow: UPDATE_VACANCY_FLOW,
      bulkUpdateVacancyFlow: BULK_UPDATE_VACANCY_FLOW,
    }),
    ...mapActions("filters", { syncLists: SYNC_LISTS }),
    ...mapActions("statuses", {
      getVacancyStatuses: GET_VACANCY_STATUSES,
    }),
    ...mapActions("users", {
      updateEmployee: UPDATE_EMPLOYEE,
    }),
    async init() {
      await this.syncLists(true);
      await this.getVacancyStatuses();
      await this.getVacancyFlow();
      await this.getVacancyNoFlow();

      this.vacancy_flow_list = JSON.parse(
        JSON.stringify(this.vacancy_flow_getter)
      );
      this.vacancy_no_flow_list = JSON.parse(
        JSON.stringify(this.vacancy_no_flow_getter)
      );

      let userCategoriesOrder = JSON.parse(this.currentUser.categories_order);
      if (userCategoriesOrder) {
        //sort by order
        this.vacancy_flow_list = this.vacancy_flow_list.sort(
          (a, b) =>
            userCategoriesOrder.indexOf(a.id) -
            userCategoriesOrder.indexOf(b.id)
        );

        // if new category added move it to the end of the tabs
        if (userCategoriesOrder.length < this.vacancy_flow_list.length) {
          let index = 0;
          let fixedCategoriesArray = this.vacancy_flow_list;
          for (const category of this.vacancy_flow_list) {
            for (const orderId of userCategoriesOrder) {
              if (category.id === orderId) {
                // Splice/remove category out of array
                fixedCategoriesArray.splice(index, 1);
                // Add categorty to top of array
                fixedCategoriesArray.unshift(category);
              }
            }
            index = index + 1;
          }
          this.vacancy_flow_list = fixedCategoriesArray;
        }
      }

      //* Checks if the lead columns in db is null
      if (this.currentUser.vacancy_flow_columns != null) {
        // if not assigns the columns from the db to the selected headers
        this.selectedHeaders = JSON.parse(
          this.currentUser.vacancy_flow_columns
        );
      } else {
        //if its null it saves the selected headers to the DB, in this case probably an empty array = []
        let employee = this.currentUser;
        let empId = this.currentUser.id;
        employee.vacancy_flow_columns = JSON.stringify(this.selectedHeaders);
        await this.updateEmployee({ employee, empId });
        // Vacancy flow list
      }
    },
    setHeaders(e) {
      this.selectedHeaders = e;
    },
    async inTableEdit(event, id, field) {
      let vacancy = {
        [field]: event,
      };
      vacancy.language = JSON.stringify(vacancy.language);
      await this.updateVacancyFlow({ vacancy, id });
      if (field === "category" || field === "isHot") {
        this.refresh();
      }
    },
    async refresh() {
      await this.getVacancyFlow();
      await this.getVacancyNoFlow();
      this.vacancy_flow_list = JSON.parse(
        JSON.stringify(this.vacancy_flow_getter)
      );
      this.vacancy_no_flow_list = JSON.parse(
        JSON.stringify(this.vacancy_no_flow_getter)
      );
    },
    async openVacancyFlow(item) {
      this.$router.push({ path: `/vacature/${item.id}` });
    },
    async openVacancyFlowNewTab(item) {
      let route = this.$router.resolve({ path: `/vacature/${item.id}` });
      window.open(route.href);
    },
    createVacancy() {
      this.createVacancyFlowDialog = true;
    },

    viewVacancy(item) {
      this.activeVacancy = item;
      this.editVacancy = true;
    },
    closeVacancy() {
      this.refresh();
      this.editVacancy = false;
    },
    editItem(item) {
      if (this.isEditing) {
        if (this.isEditingItem === item.id) {
          this.isEditing = false;
          this.isEditingItem = null;
          this.refresh();
        } else {
          this.isEditingItem = item.id;
        }
      } else {
        this.isEditing = true;
        this.isEditingItem = item.id;
      }
    },
    show(event, row) {
      event.preventDefault();
      this.showMenu = false;
      this.x = event.clientX;
      this.y = event.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
      this.selectedRow = row;
    },
    async endDrag(items, oldItems) {
      let arr = [];
      let currentUser = this.getUser.id;
      for (let index = 0; index < items.length; index++) {
        //* In the vacancy
        let itemPosition = items[index].position;
        if (
          itemPosition.some(function (el) {
            return el.emp_id == currentUser;
          })
        ) {
          for (let employee = 0; employee < itemPosition.length; employee++) {
            //* If the employee is in the position array
            if (itemPosition[employee].emp_id == currentUser) {
              itemPosition[employee].position = index + 1;
            }
          }
        } else {
          //* If the employee is not in the position array
          let obj = {
            emp_id: currentUser,
            position: index + 1,
          };
          itemPosition.push(obj);
        }

        let body = {
          id: items[index].id,
          position: itemPosition,
        };
        //* Stringifies the array
        body.position = JSON.stringify(body.position);
        arr.push(body);
      }
      if (arr.length > 0) {
        await this.bulkUpdateVacancyFlow({ arr });
      }
    },
    async sendToTop() {
      let position = this.selectedRow.position;
      let currentUser = this.getUser.id;
      if (
        position.some(function (el) {
          return el.emp_id == currentUser;
        })
      ) {
        for (let employee = 0; employee < position.length; employee++) {
          //* If the employee is in the position array
          if (position[employee].emp_id == currentUser) {
            position[employee].position = 1;
          }
        }
      } else {
        //* If the employee is not in the position array
        let obj = {
          emp_id: currentUser,
          position: 1,
        };
        position.push(obj);
      }
      let vacancy = {
        position: position,
      };
      vacancy.position = JSON.stringify(vacancy.position);
      let id = this.selectedRow.id;
      await this.updateVacancyFlow({ vacancy, id });
      this.refresh();
    },
    customSort(items) {
      items.sort((a, b) => {
        if (a.position.length == 0 || b.position.length == 0) {
          return 1;
        }
        for (let index = 0; index < a.position.length; index++) {
          if (a.position[index].emp_id == this.getUser.id) {
            for (let i = 0; i < b.position.length; i++) {
              if (b.position[i].emp_id == this.getUser.id) {
                return a.position[index].position - b.position[i].position;
              }
            }
          }
        }
      });
      return items;
    },
    async updateVacancyFlowOrder() {
      let categoriesOrder = [];
      this.vacancy_flow_list.forEach((vacancy) =>
        categoriesOrder.push(vacancy.id)
      );
      let employee = this.currentUser;
      let empId = this.currentUser.id;

      await this.updateEmployee({
        employee: {
          ...employee,
          categories_order: JSON.stringify(categoriesOrder),
        },
        empId,
      });
    },
    calculateAverageHours(vacancies) {
      let sum = 0;
      vacancies.forEach((element) => {
        if (element.minimumHours == null && element.maximumHours == null) {
          sum += 0;
        } else if (element.minimumHours == null) {
          sum += element.maximumHours;
        } else if (element.maximumHours == null) {
          sum += element.minimumHours;
        } else {
          sum += (element.minimumHours + element.maximumHours) / 2;
        }
      });
      return sum;
    },
    getHours(min, max) {
      if (min == null && max == null) {
        return "-";
      } else if (min == null) {
        return max;
      } else if (max == null) {
        return min;
      } else {
        return min + " - " + max;
      }
    },
    markAsHot(item) {
      console.log(item);
    },
  },
};
</script>

<style scoped>
.inlineSelect {
  margin-bottom: -15px !important;
  padding-bottom: 0px !important;
}

.refreshBtn:hover {
  transform: scale(1.1);
}

.newItemBtn:hover {
  transform: scale(1.1);
}

.pointerOnHover:hover {
  cursor: pointer;
}
.statusChip {
  font-weight: 500;
}
.v-application p {
  margin-bottom: 0;
}
</style>
