<template>
  <v-container class="mx-0 px-0">
    <v-dialog v-model="createEmailTemplateDialog" width="650px">
      <v-card>
        <CreateEmailTemplate
          v-on:close-dialog="createEmailTemplateDialog = false"
          @reload-table="init()"
        ></CreateEmailTemplate>
      </v-card>
    </v-dialog>
    <v-col cols="10">
      <v-col cols="4">
        <v-row class="pa-1 justify-space-between">
          <h2 class="">Email Templates</h2>
          <v-btn
            class="btnHover"
            color="primary"
            @click="openCreateDialog(selectedTemplate)"
            ><v-icon>mdi-plus</v-icon>Add</v-btn
          >
        </v-row>
      </v-col>

      <v-row>
        <v-col cols="4">
          <v-list class="scrollableList px-1">
            <v-list-item
              :key="item.id"
              v-for="item in email_templates_getter"
              @click="setActiveTemplate(item)"
              :class="
                activeItem == null
                  ? ''
                  : activeItem.id == item.id
                  ? 'activeItemClass'
                  : ''
              "
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
                <v-list-item-subtitle class="mt-1">
                  {{ timePassed(item) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="7">
          <v-card elevation="0" v-if="activeItem != null">
            <UpdateEmailTemplate
              v-on:close-dialog="updateEmailTemplateDialog = false"
              @reload-table="init()"
              :activeTemplate="activeItem"
            ></UpdateEmailTemplate>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import {
  GET_ALL_EMAIL_TEMPLATES,
  CREATE_EMAIL_TEMPLATE,
} from "../../store/action.type";
import { mapActions, mapGetters } from "vuex";
import CreateEmailTemplate from "../emails/dialogs/CreateEmailTemplate.vue";
import UpdateEmailTemplate from "../emails/dialogs/UpdateEmailTemplate.vue";

export default {
  components: { CreateEmailTemplate, UpdateEmailTemplate },
  data: () => ({
    createEmailTemplateDialog: false,
    updateEmailTemplateDialog: false,
    selectedTemplate: null,
    tab: null,
    activeItem: null,
  }),
  computed: {
    ...mapGetters("email_template", ["email_templates_getter"]),
    headers() {
      return [
        {
          text: "Email Template Name",
          value: "title",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions("email_template", {
      getAllEmailTemplates: GET_ALL_EMAIL_TEMPLATES,
      createEmailTemplate: CREATE_EMAIL_TEMPLATE,
    }),
    async init() {
      dayjs.extend(relativeTime);
      await this.getAllEmailTemplates();
      this.activeItem = this.email_templates_getter[0];
    },
    openCreateDialog() {
      this.createEmailTemplateDialog = true;
      this.selectedTemplate = {
        name: "",
        description: "",
      };
    },
    setActiveTemplate(item) {
      this.activeItem = item;
    },
    timePassed(item) {
      let date = new Date(item.createdAt);
      return dayjs(date).fromNow();
    },
  },
};
</script>

<style>
.activeItemClass {
  color: #42a5f5 !important;
  background: #e3f2fd !important;
}

.btnHover:hover {
  transform: scale(1.1);
}
.scrollableList {
  max-height: 700px;
  overflow-y: auto;
}
</style>
