<template>
  <v-col cols="4">
    <v-container fluid class="pl-0 pr-0">
      <v-card min-height="380">
        <v-row :style="cardHeaders" class="ma-0">
          <v-row class="pa-5">
            <v-col md="10">
              <v-row>
                <v-icon medium>mdi-email</v-icon>
                <h3>
                  <strong>Email Activity</strong>
                </h3>
              </v-row>
            </v-col>
          </v-row>
        </v-row>
        <v-row
          style="overflow-y: auto; overflow-x: hidden; height: 450px"
          class="pa-0 ma-0"
        >
          <v-timeline style="width: 90%">
            <v-timeline-item
              v-for="item in emailActivity"
              :key="item.id"
              name="status"
              :color="getStatus(item.status).color"
              fill-dot
              right
              style="align-items: center"
            >
              <template v-slot:icon>
                <v-icon color="white" medium>{{
                  getStatus(item.status).icon
                }}</v-icon>
              </template>
              <template v-slot:opposite>
                <v-row justify="end">
                  <v-col cols="10">
                    <h5>
                      {{ getTime(item) }}
                    </h5>
                  </v-col>
                </v-row>
              </template>
              <h5>
                {{ item.template_name }} was {{ getStatus(item.status).label }}
              </h5>
            </v-timeline-item>
          </v-timeline>
        </v-row>
      </v-card>
    </v-container>
  </v-col>
</template>
<script>
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { mapActions } from "vuex";
import { GET_EMAIL_ACTIVITY } from "../../store/action.type";
export default {
  props: ["cardHeaders", "mainLead"],
  data: () => ({
    emailActivity: [],
  }),
  created() {
    this.init();
  },
  methods: {
    ...mapActions("email_activity", {
      getEmailActivity: GET_EMAIL_ACTIVITY,
    }),
    async init() {
      dayjs.extend(relativeTime);
      await this.getAllEmailActivity();
    },
    async getAllEmailActivity() {
      let id = this.mainLead.id;
      await this.getEmailActivity(id).then((response) => {
        this.emailActivity = response;
        this.emailActivity.sort(function (a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
      });
    },
    getTime(item) {
      let date = new Date(item.createdAt);
      return dayjs(date).fromNow();
    },
    getStatus(status) {
      // delivered, open, click, bounce; blue, green, purple, red
      let stat = { color: "blue", icon: "mdi-email-fast", label: "Delivered" };
      switch (status) {
        case "delivered":
          stat.color = "blue";
          stat.icon = "mdi-email-fast";
          stat.label = "Delivered";
          break;
        case "open":
          stat.color = "green";
          stat.icon = "mdi-email-open";
          stat.label = "Opened";
          break;
        case "click":
          stat.color = "purple";
          stat.icon = "mdi-cursor-default-click";
          stat.label = "Clicked";
          break;
        case "bounce":
          stat.color = "red";
          stat.icon = "mdi-email-remove";
          stat.label = "Bounced";
          break;
        default:
          stat.color = "blue";
          stat.icon = "mdi-email-fast";
          stat.label = "Delivered";
          break;
      }
      return stat;
    },
  },
};
</script>
