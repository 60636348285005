<template>
  <v-container>
    <v-dialog v-model="createTemplateDialog" width="650px">
      <v-card>
        <CreateTemplate
          :type="templateType"
          :activeTemplate="selectedTemplate"
          v-on:close-dialog="createTemplateDialog = false"
          @reload-table="init()"
        ></CreateTemplate>
      </v-card>
    </v-dialog>
    <v-card elevation="4" class="pa-3">
      <v-row class="ma-0">
        <v-card-title class="pt-1">Vacancy template</v-card-title>
        <v-spacer />
        <v-btn
          color="primary"
          @click="openCreateDialog(selectedTemplate, 'create')"
          ><v-icon> mdi-plus</v-icon></v-btn
        >
      </v-row>
      <v-data-table
        :headers="headers"
        :items="templates_getter"
        :items-per-page="5"
        class="elevation-1"
      >
        <template v-slot:[`item.name`]="{ item }">
          {{
            item.name !== null && item.name.length >= 10
              ? item.name.slice(0, 10) + "..."
              : item.name
          }}</template
        >
        <template v-slot:[`item.description`]="{ item }">
          {{
            item.description !== null && item.description.length >= 20
              ? item.description.slice(0, 20) + "..."
              : item.description
          }}</template
        >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="openCreateDialog(item, 'update')">
            mdi-pencil
          </v-icon>
        </template></v-data-table
      >
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { GET_ALL_TEMPLATES } from "../../../store/action.type";
import CreateTemplate from "../dialogs/CreateTemplate.vue";
export default {
  data: () => ({
    createTemplateDialog: false,
    selectedTemplate: null,
    templateType: null,
  }),
  computed: {
    ...mapGetters("vacancy_template", ["templates_getter"]),
    headers() {
      return [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Description",
          value: "description",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions("vacancy_template", {
      getAllTemplates: GET_ALL_TEMPLATES,
    }),
    async init() {
      await this.getAllTemplates();
    },
    openCreateDialog(item, type) {
      this.templateType = type;
      this.createTemplateDialog = true;
      if (type == "update") {
        this.selectedTemplate = item;
      } else {
        this.selectedTemplate = {
          name: "",
          description: "",
        };
      }
    },
  },
  components: { CreateTemplate },
};
</script>
