import config from "../../../config";
import { authHeader } from "../../utils/auth-header";
import { handleResponse } from "../../utils/api_functions";
import { logger } from "../../utils/logger";

import {
  CREATE_CONTACT_MOMENT,
  GET_CONTACT_MOMENT_FOR_LEAD_USER,
  UPDATE_CONTACT_MOMENT,
  GET_CONTACT_MOMENTS_PER_PERIOD,
  DELETE_FIRST_CONTACT_MOMENT,
} from "../action.type";
import { SET_CONTACT_MOMENTS_PERIOD } from "../mutation.type";

export const contact_moments = {
  namespaced: true,
  state: {
    contactMomentsPerPeriod: [],
  },
  getters: {
    getContactMomentsPerPeriod: (state) => state.contactMomentsPerPeriod,
  },
  actions: {
    async [CREATE_CONTACT_MOMENT](_, contact_moment) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(contact_moment),
        };
        return await fetch(`${config.apiUrl}/contact-moments`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },

    async [UPDATE_CONTACT_MOMENT](_, { contact_moment, contact_momentId }) {
      try {
        const request = {
          method: "PUT",
          headers: authHeader(),
          body: JSON.stringify(contact_moment),
        };
        return await fetch(
          `${config.apiUrl}/contact-moments/${contact_momentId}`,
          request
        )
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },

    async [GET_CONTACT_MOMENT_FOR_LEAD_USER](_, leadUserId) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(
          `${config.apiUrl}/contact-moments/leadUser/${leadUserId}`,
          request
        )
          .then(handleResponse)
          .then((contact_moments) => {
            return contact_moments;
          });
      } catch (error) {
        logger.error(error);
      }
    },

    async [GET_CONTACT_MOMENTS_PER_PERIOD]({ commit }, { params }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(
          `${config.apiUrl}/contact-moments/perPeriod/?contactStartDate=${params.contactStartDate}&contactEndDate=${params.contactEndDate}`,
          request
        )
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [DELETE_FIRST_CONTACT_MOMENT](_, { lead_user_id }) {
      try {
        const request = {
          method: "DELETE",
          headers: authHeader(),
        };
        return await fetch(
          `${config.apiUrl}/delete-contact-moment/${lead_user_id}`,
          request
        )
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
  },
  mutations: {
    [SET_CONTACT_MOMENTS_PERIOD](state, items) {
      state.contactMomentsPerPeriod = items;
    },
  },
};
