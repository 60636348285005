import config from "../../../config";
import { authHeader } from "../../utils/auth-header";
import { handleResponse } from "../../utils/api_functions";
import { logger } from "../../utils/logger";

import {
  ADD_NOTIFICATION,
  CLEAR_NOTIF_COUNT,
  DECREASE_NOTIF_COUNT,
  GET_ALL_NOTIFICATION_GROUPS,
  GET_NOTIFICATIONS,
  INCREMENT_NOTIF_COUNT,
  MARK_NOTIFICATION_READ,
  READ_ALL_NOTIFICATIONS,
  SOCKET_AUTHENTICATE,
  UPDATE_EMPLOYEE_GROUPS,
} from "../action.type";
import { SET_NOTIFICATIONS, SET_NOTIFICATION_GROUPS } from "../mutation.type";

export const notifications = {
  namespaced: true,
  state: {
    count: 0,
    list: [],
    groups: [],
    fetched: false,
  },
  getters: {
    getNotifCount: (state) => state.count,
    getNotifList: (state) => state.list,
    notifsFetched: (state) => state.fetched,
    notifGroups: (state) => state.groups,
  },

  actions: {
    [INCREMENT_NOTIF_COUNT]({ state }) {
      state.count++;
    },
    [DECREASE_NOTIF_COUNT]({ state }) {
      state.count--;
    },
    [CLEAR_NOTIF_COUNT]({ state }) {
      state.count = 0;
    },
    [ADD_NOTIFICATION]({ state }, notification) {
      state.list.push(notification);
    },
    async [SOCKET_AUTHENTICATE](_, { groups, socketId }) {
      const data = {
        groups: groups,
        socketId: socketId,
      };
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(data),
        };
        await fetch(`${config.socketUrl}/api/notification/join`, request).then(
          handleResponse
        );
      } catch (error) {
        logger.error(error);
      }
    },

    async [GET_NOTIFICATIONS]({ commit }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        await fetch(
          `${config.apiUrl}/notification/${request.headers.request_user}`,
          request
        )
          .then(handleResponse)
          .then((data) => {
            commit(SET_NOTIFICATIONS, data);
          });
        // axios.defaults.headers.common["Authorization"] = `${
        //   authHeader().Authorization
        // }`;
        // const { data } = await axios.get(`${config.apiUrl}/notification`);
        // commit(SET_NOTIFICATIONS, data);
      } catch (error) {
        logger.error(error);
      }
      //axios.defaults.headers.common = { Accept: "application/json, text/plain, */*" }
    },
    async [MARK_NOTIFICATION_READ]({ state }, notifId) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        await fetch(
          `${config.apiUrl}/notification/read/${notifId}/${request.headers.request_user}`,
          request
        ).then(handleResponse);
        // axios.defaults.headers.common["Authorization"] = `${authHeader().Authorization
        //   }`;
        // await axios.get(`${config.apiUrl}/notification/read/${notifId}`);
        for (const notif in state.list) {
          if (notif.id == notifId) {
            state.list.indexOf(notif);
          }
        }
        let index = -1;
        state.list.find(function (notif, i) {
          if (notif.id == notifId) {
            index = i;
          }
        });
        if (index >= 0) {
          state.list.splice(index, 1);
        }
      } catch (error) {
        logger.error(error);
      }
    },
    async [READ_ALL_NOTIFICATIONS]({ commit, state }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        await fetch(
          `${config.apiUrl}/notification/readAll/${request.headers.request_user}`,
          request
        ).then(handleResponse);
        // axios.defaults.headers.common["Authorization"] = `${authHeader().Authorization
        //   }`;
        // await axios.get(`${config.apiUrl}/notification/readAll`);
        state.list = [];
      } catch (error) {
        logger.error(error);
      }
    },
    async [GET_ALL_NOTIFICATION_GROUPS]({ commit }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        await fetch(`${config.apiUrl}/notification/groups`, request)
          .then(handleResponse)
          .then((data) => {
            commit(SET_NOTIFICATION_GROUPS, data);
          });
        // axios.defaults.headers.common["Authorization"] = `${authHeader().Authorization
        //   }`;
        // const { data } = await axios.get(`${config.apiUrl}/notification/groups`);
        // commit(SET_NOTIFICATION_GROUPS, data);
      } catch (error) {
        logger.error(error);
      }
    },
    async [UPDATE_EMPLOYEE_GROUPS](_, { empId, groups }) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(groups),
        };
        await fetch(
          `${config.apiUrl}/notification/groups/${empId}`,
          request
        ).then(handleResponse);
        // axios.defaults.headers.common["Authorization"] = `${authHeader().Authorization
        //   }`;
        // await axios.post(`${config.apiUrl}/notification/groups/${empId}`, groups);
      } catch (error) {
        logger.error(error);
      }
    },
  },
  mutations: {
    [SET_NOTIFICATIONS](state, notifs) {
      state.fetched = true;
      state.list = notifs;
      state.count = notifs.length;
    },
    [SET_NOTIFICATION_GROUPS](state, groups) {
      state.groups = groups;
    },
  },
};
