<template>
  <v-container fluid>
    <v-dialog v-model="editVacancy" max-width="600px">
      <QuickEditVacancy
        :activeVacancy="activeVacancy"
        v-on:close-dialog="editVacancy = false"
      />
    </v-dialog>
    <v-dialog v-model="sendEmailDialogue" width="750px" height="100%">
      <v-card color="card">
        <SendEmail
          v-on:close-dialog="reset()"
          :selectedCandidates="mapCandidates(selectedCandidates)"
        >
        </SendEmail>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addToHotlistDialogue" width="750px">
      <v-card>
        <v-card-title class="ml-2" style="font-size: 1.5rem">
          Create New Hotlist
        </v-card-title>
        <CreateHotlist
          :selectedCandidates="mapCandidates(selectedCandidates)"
          v-on:close-dialog="reset()"
        />
      </v-card>
    </v-dialog>
    <v-dialog v-model="selectHotlistDialogue" width="450px">
      <v-card>
        <AddMembers
          :selectedCandidates="mapCandidates(selectedCandidates)"
          v-on:close-dialog="reset()"
        ></AddMembers>
      </v-card>
    </v-dialog>

    <v-row class="pa-3">
      <v-col md="3">
        <v-menu max-height="300px" max-width="50%" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <h2 color="primary" dark v-bind="attrs" v-on="on">
              {{ recruiter ? recruiter.name : "" }}
            </h2>
          </template>
          <v-list class="recItem">
            <v-list-item
              class="recItem"
              v-for="item in employees_getter"
              :key="item.id"
              @click="switchRecruiter(item)"
            >
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="selectedCandidates.length == 0"
        class="mr-4 animatedBtn"
        color="primary"
        @click="sendEmailDialogue = true"
        >Send Email
      </v-btn>
      <v-btn
        :disabled="selectedCandidates.length == 0"
        class="mr-4 animatedBtn"
        color="primary"
        @click="addToHotlistDialogue = true"
        >Create New Hotlist
      </v-btn>
      <v-btn
        :disabled="selectedCandidates.length == 0"
        class="mr-4 animatedBtn"
        color="primary"
        @click="selectHotlistDialogue = true"
        >Add to Hotlist
      </v-btn>
    </v-row>

    <v-tabs @change="selectedCandidates = []">
      <v-tab>My Top Candidates</v-tab>
      <v-tab>My Candidates </v-tab>
      <v-tab>My Vacancies</v-tab>
      <v-tab-item>
        <v-card v-if="topCandidates" elevation="0">
          <v-data-table
            v-model="selectedCandidates"
            show-select
            :headers="headers"
            :items="topCandidates"
            :loading="loadingCandidates"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50],
            }"
            @click:row="openCandidate"
            :items-per-page="30"
            class="elevation-1 row-pointer"
            :custom-sort="sortByDate"
          >
            <template v-slot:[`item.favorite`]="{ item }">
              <v-icon
                v-if="item.favorite == true"
                color="yellow"
                class="mr-2"
                @click.stop="updateUser(item, 'favorite', false)"
              >
                mdi-star
              </v-icon>
              <v-icon v-else class="mr-2"> mdi-star-outline </v-icon>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                label
                class="ma-2 statusChip"
                :color="latestStatus(item).color"
                elevation="4"
              >
                {{ latestStatus(item).name }}
              </v-chip>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              {{ item.first_name + " " + item.last_name }}
            </template>
            <template v-slot:[`item.gender`]="{ item }">
              {{ item.gender }}
            </template>
            <template v-slot:[`item.tel_number`]="{ item }">
              {{ item.tel_number }}
            </template>
            <template v-slot:[`item.region`]="{ item }">
              {{ getCity(item.address) }}
            </template>
            <template v-slot:[`item.contract_term`]="{ item }">
              {{ item.contract_term }}
            </template>
            <template v-slot:[`item.contract_type`]="{ item }">
              {{ item.contract_type }}
            </template>
            <template v-slot:[`item.sal_indication`]="{ item }">
              {{ item.sal_indication ? "€" + item.sal_indication : "" }}
            </template>
            <template v-slot:[`item.applied_date`]="{ item }">
              {{ formatDate(latestLead(item).applied_date) }}
            </template>
            <template v-slot:[`item.contact_moment`]="{ item }">
              {{ latestContact(item) }}
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card v-if="allCandidates" elevation="0">
          <v-data-table
            v-model="selectedCandidates"
            show-select
            :headers="headers"
            @click:row="openCandidate"
            :items="allCandidates"
            :loading="loadingCandidates"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50],
            }"
            :items-per-page="30"
            class="elevation-1 row-pointer"
            :custom-sort="sortByDate"
          >
            <template v-slot:[`item.favorite`]="{ item }">
              <v-icon v-if="item.favorite == true" color="yellow" class="mr-2">
                mdi-star
              </v-icon>
              <v-icon
                v-else
                class="mr-2"
                @click.stop="updateUser(item, 'favorite', true)"
              >
                mdi-star-outline
              </v-icon>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                label
                class="ma-2 statusChip"
                :color="latestStatus(item).color"
                elevation="4"
              >
                {{ latestStatus(item).name }}
              </v-chip>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              {{ item.first_name + " " + item.last_name }}
            </template>
            <template v-slot:[`item.gender`]="{ item }">
              {{ item.gender }}
            </template>
            <template v-slot:[`item.tel_number`]="{ item }">
              {{ item.tel_number }}
            </template>
            <template v-slot:[`item.region`]="{ item }">
              {{ getCity(item.address) }}
            </template>
            <template v-slot:[`item.contract_term`]="{ item }">
              {{ item.contract_term }}
            </template>
            <template v-slot:[`item.contract_type`]="{ item }">
              {{ item.contract_type }}
            </template>
            <template v-slot:[`item.sal_indication`]="{ item }">
              {{ item.sal_indication ? "€" + item.sal_indication : "" }}
            </template>
            <template v-slot:[`item.applied_date`]="{ item }">
              {{ formatDate(latestLead(item).applied_date) }}
            </template>
            <template v-slot:[`item.contact_moment`]="{ item }">
              {{ latestContact(item) }}
            </template></v-data-table
          >
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card elevation="0">
          <v-data-table
            :headers="headersVacancy"
            :items="vacancies"
            @click:row="openVacancyFlow"
            class="elevation-1 row-pointer"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50],
            }"
            :items-per-page="30"
          >
            <template v-slot:[`item.action`]="{ item }">
              <td class="pr-0 pl-2">
                <v-btn
                  class="newItemBtn px-0"
                  @click.stop="viewVacancy(item)"
                  color="primary"
                  icon
                >
                  <v-icon> mdi-eye </v-icon>
                </v-btn>
              </td>
            </template>
            <template v-slot:[`item.Vacancy_status`]="{ item }">
              <v-chip
                label
                class="ma-2 statusChip"
                :color="item.Vacancy_status.color"
                dark
                elevation="4"
              >
                {{ item.Vacancy_status.name }}
              </v-chip>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              {{ item.name }}
            </template>
            <template v-slot:[`item.city`]="{ item }">
              {{ item.city }}
            </template>
            <template v-slot:[`item.workHours`]="{ item }">
              {{
                item.minimumHours == item.maximumHours || item.maximumHours == 0
                  ? item.minimumHours
                  : item.minimumHours + " - " + item.maximumHours
              }}
            </template>
            <template v-slot:[`item.company`]="{ item }">
              {{ item.Company ? item.Company.name : "" }}
            </template>
            <template v-slot:[`item.startDate`]="{ item }">
              {{ item.startDate }}
            </template>
            <template v-slot:[`item.hiringType`]="{ item }">
              {{ item.hiringType }}
            </template>
            <template v-slot:[`item.category`]="{ item }">
              {{ item.Category ? item.Category.name : "" }}
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import QuickEditVacancy from "../components/vacancy_flow/dialogs/QuickEditVacancy";
import SendEmail from "../components/emails/dialogs/SendEmail";
import CreateHotlist from "../components/hotlists/dialogs/CreateHotlist";
import AddMembers from "../components/hotlists/dialogs/AddMembers";
import {
  GET_SELF,
  GET_LEAD_USERS_BY_RECRUITER,
  GET_VACANCIES_ALL,
  UPDATE_LEAD_USER,
  GET_ALL_HOTLISTS,
  GET_HOTLIST_BY_ID,
} from "../store/action.type";
export default {
  components: { QuickEditVacancy, SendEmail, CreateHotlist, AddMembers },
  data: () => ({
    recruiter: null,
    recruiterVacancies: [],
    activeVacancy: null,
    editVacancy: false,
    filters: {},
    suitableStatuses: [
      "Geplaatst",
      "Geen geschikte vac.",
      "Kansparel",
      "Geschikt profiel maar,",
      "Contract Aangeboden",
      "Voorgesteld",
      "In behandeling",
      "Aanbod geweigerd",
      "Doen Poule",
      "Zoomer Poule",
      "Vaste Poule",
      "Werving & Selectie",
      "Gestopt",
      "Gestopte Doener",
    ],
    loadingCandidates: false,
    selectedCandidates: [],
    sendEmailDialogue: false,
    addToHotlistDialogue: false,
    selectedHotlist: null,
    updateHotlistDialogue: false,
    selectHotlistDialogue: false,
  }),
  computed: {
    ...mapGetters("vacancy_flow", ["vacancies_all_getter"]),
    ...mapGetters("filters", ["employees_getter"]),
    ...mapGetters("users", ["getUser"]),
    ...mapGetters("lead_users", ["lead_users_by_recruiter_getter"]),
    ...mapGetters("hotlists", ["hotlists_getter", "hotlist_members_getter"]),

    topCandidates() {
      return this.lead_users_by_recruiter_getter.filter((candidate) => {
        return (
          candidate.favorite &&
          this.suitableStatuses.includes(this.latestStatus(candidate).name)
        );
      });
    },
    allCandidates() {
      return this.lead_users_by_recruiter_getter.filter((candidate) => {
        return (
          (candidate.favorite === false || candidate.favorite === null) &&
          this.suitableStatuses.includes(this.latestStatus(candidate).name)
        );
      });
    },
    vacancies() {
      return this.vacancies_all_getter.filter((vacancy) => {
        return vacancy.recruiter === this.recruiter?.id;
      });
    },
    headersVacancy() {
      let headers = [
        {
          text: "",
          value: "action",
          sortable: false,
          width: "1%",
        },
        {
          text: "Status",
          value: "Vacancy_status",
          sortable: false,
        },
        {
          text: "Name",
          value: "name",
          sortable: false,
        },
        {
          text: "City",
          value: "city",
          sortable: false,
        },
        {
          text: "Work Hours",
          value: "workHours",
          sortable: false,
        },
        {
          text: "Company",
          value: "company",
          sortable: false,
        },
        {
          text: "Start Date",
          value: "startDate",
          sortable: false,
        },
        {
          text: "Hiring Type",
          value: "hiringType",
          sortable: false,
        },
        {
          text: "Category",
          value: "category",
          sortable: false,
        },
      ];
      return headers;
    },
    headers() {
      return [
        {
          text: this.$t("candidates_page.favorite"),
          value: "favorite",
          sortable: false,
        },
        {
          text: this.$t("candidates_page.status"),
          value: "status",
          sortable: false,
          width: "1%",
        },
        {
          text: this.$t("candidates_page.name"),
          sortable: false,
          value: "name",
        },
        {
          text: this.$t("candidates_page.gender"),
          sortable: false,
          value: "gender",
        },
        {
          text: this.$t("candidates_page.tel"),
          sortable: false,
          value: "tel_number",
        },
        {
          text: this.$t("candidates_page.place"),
          sortable: false,
          value: "region",
        },
        {
          text: this.$t("candidates_page.contract_term"),
          sortable: false,
          value: "contract_term",
        },
        {
          text: this.$t("candidates_page.job_scope"),
          sortable: false,
          value: "contract_type",
        },
        {
          text: this.$t("candidates_page.salary_indication"),
          sortable: false,
          value: "sal_indication",
        },
        {
          text: this.$t("candidates_page.application_date"),
          value: "applied_date",
        },
        {
          text: this.$t("leads_page.last_contact_moment"),
          value: "contact_moment",
        },
      ];
    },
  },
  async created() {
    await this.init();
  },
  methods: {
    ...mapActions("vacancy_flow", {
      getVacanciesAll: GET_VACANCIES_ALL,
    }),

    ...mapActions("users", {
      getSelf: GET_SELF,
    }),
    ...mapActions("lead_users", {
      getLeadUsersByRecruiter: GET_LEAD_USERS_BY_RECRUITER,
      updateLeadUser: UPDATE_LEAD_USER,
    }),
    ...mapActions("hotlists", {
      getAllHotlists: GET_ALL_HOTLISTS,
      getHotlistById: GET_HOTLIST_BY_ID,
    }),
    async init() {
      await this.getSelf(this.$auth.user.sub);
      this.recruiter = {
        id: this.getUser.id,
        name: this.getUser.first_name + " " + this.getUser.last_name,
      };
      await this.getVacanciesAll();
      let id = this.recruiter.id;
      await this.getLeadUsersByRecruiter(id);
    },
    async switchRecruiter(recruiter) {
      this.loadingCandidates = true;
      this.recruiter = recruiter;
      let id = this.recruiter.id;
      await this.getLeadUsersByRecruiter(id);
      this.loadingCandidates = false;
    },
    latestStatus(item) {
      if (item.lead_statuses.length > 0) {
        return item.lead_statuses[item.lead_statuses.length - 1].status;
      } else {
        return { name: "Geen", color: "#FFD700" };
      }
    },
    latestLead(item) {
      if (item.leads.length > 0) {
        return item.leads[item.leads.length - 1];
      }
    },
    latestContact(item) {
      if (item.contact_moments.length > 0) {
        return this.formatDate(
          item.contact_moments[
            item.contact_moments.length - 1
          ].createdAt.substring(0, 10)
        );
      } else {
        return "Geen";
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    sortByDate(array) {
      let newArray = array.sort(
        (a, b) =>
          new Date(this.latestLead(b).applied_date) -
          new Date(this.latestLead(a).applied_date)
      );
      return newArray;
    },
    getCity(address) {
      if (address !== null && address !== "") {
        return JSON.parse(address).region;
      } else {
        return "No Data";
      }
    },
    itemExists(item) {
      if (item == null || item == "") return "Onbekend";
      return item;
    },
    viewVacancy(item) {
      this.activeVacancy = item;
      this.editVacancy = true;
    },
    openVacancyFlow(item) {
      this.$router.push(`/vacature/${item.id}`);
    },
    openCandidate(item) {
      this.$router.push(`/profiel/${item.id}`);
    },
    async updateUser(item, field, value) {
      let userId = item.id;
      let lead_user = {
        favorite: value,
      };
      await this.updateLeadUser({ lead_user, userId });
      this.loadingCandidates = true;
      let id = this.recruiter.id;
      await this.getLeadUsersByRecruiter(id);
      this.loadingCandidates = false;
    },

    reset() {
      this.addToHotlistDialogue = false;
      this.updateHotlistDialogue = false;
      this.selectedCandidates = [];
      this.sendEmailDialogue = false;
      this.addToHotlistDialogue = false;
      this.selectedHotlist = null;
      this.selectHotlistDialogue = false;
      this.getAllHotlists();
    },
    mapCandidates(candidates) {
      //Maps the candidates to have first name, last name, id, email
      let mappedCandidates = [];
      candidates.forEach((element) => {
        mappedCandidates.push({
          id: element.id,
          first_name: element.first_name,
          last_name: element.last_name,
          email: element.email,
        });
      });
      return mappedCandidates;
    },
  },
};
</script>

<style scoped>
.animatedBtn:hover {
  transform: scale(1.1);
}
.recItem :hover {
  cursor: pointer;
  scale: 1.02;
}
.row-pointer:hover {
  cursor: pointer;
}
</style>
