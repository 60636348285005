<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="mini"
      permanent
      app
      :style="{ background: $vuetify.theme.themes[theme].navBackground }"
    >
      <v-list-item class="px-2">
        <v-list-item-avatar
          @click="openFileInput()"
          v-on:mouseover="isActive = true"
          v-on:mouseleave="isActive = false"
        >
          <v-file-input
            id="fileUpload"
            @change="selectFile"
            :key="componentKey"
            style="display: none"
            accept="image/x-png,image/jpeg"
          ></v-file-input>
          <v-img
            :class="{ active: isActive }"
            :src="
              computedImage != ''
                ? computedImage
                : require('@/assets/defaultProfileXmas.png')
            "
          ></v-img>
        </v-list-item-avatar>
        <v-list-item-title v-if="user">{{
          user.first_name + " " + user.last_name
        }}</v-list-item-title>
        <v-list-item-title v-else>Username</v-list-item-title>

        <v-btn icon @click.stop="mini = !mini">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>
      <v-list nav dense class="pt-0">
        <!-- <v-list-item v-on:click="openNotifications" link>
          <v-list-item-icon>
            <v-badge
              :content="getNotifCount"
              :value="getNotifCount"
              color="blue"
              overlap
            >
              <v-icon>mdi-bell</v-icon>
            </v-badge>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{
              $t("navbar.notifications")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

        <v-divider class="my-1"></v-divider>

        <v-list-item-group
          v-model="selectedItem"
          color="$vuetify.theme.dark ? 'white' : 'primary'"
          class="mt-1"
        >
          <v-list-item
            class="myRec"
            color="#F7FFF7"
            v-on:click="gotoPage('/my-recruitment')"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{
                $t("navbar.my_recruitment")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-for="item in allowedItemsByPermission"
            :key="item.title"
            v-on:click="gotoPage(item.link)"
            color="#F7FFF7"
            link
          >
            <v-list-item-icon v-if="item.title != $t('navbar.conversations')">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-icon v-if="item.title == $t('navbar.conversations')">
              <v-badge
                color="red"
                :content="unread_messages_getter"
                :value="unread_messages_getter"
                overlap
              >
                <v-icon>{{ item.icon }}</v-icon>
              </v-badge>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-divider></v-divider>
      </v-list>

      <v-spacer></v-spacer>

      <!-- 2nd part of Nav drawer -->
      <v-list dense style="!important; bottom: 0 !important; width: 100%">
        <v-divider></v-divider>

        <v-list-item v-on:click="changeLocale()" link>
          <v-list-item-icon>
            <v-icon v-if="$i18n.locale === 'en'" color="primary"
              >mdi-toggle-switch</v-icon
            >
            <v-icon v-if="$i18n.locale === 'nl'">mdi-toggle-switch-off</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-if="$i18n.locale === 'en'"
              >English</v-list-item-title
            >
            <v-list-item-title v-if="$i18n.locale === 'nl'"
              >Dutch</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-on:click="darkSwitch()" link>
          <v-list-item-icon>
            <v-icon v-if="$vuetify.theme.dark">mdi-toggle-switch</v-icon>
            <v-icon v-else>mdi-toggle-switch-off</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("navbar.theme") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-on:click="bugDialog = true" link>
          <v-list-item-icon>
            <v-icon>mdi-bug</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("navbar.bugs") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-on:click="logout()" link>
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("navbar.logOut") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-on:click="versionDialog = true" link>
          <v-list-item-icon>
            <v-icon>mdi-clipboard-text-clock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("navbar.updateLog") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- Bug Dialog -->
    <v-dialog v-model="bugDialog" max-width="600px">
      <v-card>
        <BugAndFeatureReport v-on:close-dialog="bugDialog = false" />
      </v-card>
    </v-dialog>

    <!-- Version Dialog -->
    <v-dialog v-model="versionDialog" scrollable max-width="600px">
      <VersionHistory v-on:close-dialog="versionDialog = false" />
    </v-dialog>
  </div>
</template>
<script>
import BugAndFeatureReport from "@/components/Report/BugAndFeatureReport";

import VersionHistory from "@/components/Report/VersionHistory";
import { mapActions, mapGetters } from "vuex";

import { permission } from "@/utils/permissions";

import {
  CLEAR_NOTIF_COUNT,
  GET_NOTIFICATIONS,
  GET_USERS_WITH_CONVERSATIONS,
  UPDATE_UNREAD_MESSAGES,
} from "@/store/action.type";
export default {
  name: "NavDrawer",
  components: {
    BugAndFeatureReport,
    VersionHistory,
  },
  data() {
    return {
      bugDialog: false,
      versionDialog: false,
      drawer: true,
      currentNavItem: null,
      mini: true,
      componentKey: 0,
      currentFile: undefined,
      image: "",
      isActive: false,
    };
  },
  created() {
    this.loadProfileImage();
    this.loadUnreadMessages();
  },

  computed: {
    ...mapGetters("notifications", ["getNotifCount", "notifsFetched"]),
    ...mapGetters("users", ["getUser"]),
    ...mapGetters("whats_app", ["unread_messages_getter"]),
    items() {
      return [
        {
          title: this.$t("navbar.home"),
          icon: "mdi-home",
          link: "/home",
          role: 3,
          permission: ["everyone"],
        },
        {
          title: this.$t("navbar.vacancies"),
          icon: "mdi-clipboard-text",
          link: "/vacatures",

          permission: ["everyone"],
        }, //Vacancies
        {
          title: this.$t("navbar.archived_vacancies"),
          icon: "mdi-clipboard-text-clock",
          link: "/archived-vacancies",

          permission: ["everyone"],
        }, //Vacancies Old
        {
          title: "New Leads",
          icon: "mdi-account-arrow-up",
          link: "/new-leads",
          permission: ["everyone"],
        },
        {
          title: this.$t("navbar.leads"),
          icon: "mdi-clipboard-account",
          link: "/leads",
          permission: ["everyone"],
        }, //Leads
        {
          title: this.$t("navbar.whatsApp"),
          icon: "mdi-whatsapp",
          link: "/whatsapp",
          permission: ["everyone"],
        },
        {
          title: this.$t("navbar.candidates"),
          icon: "mdi-account-multiple",
          link: "/kandidaten",
          permission: ["everyone"],
        }, //Lead_users
        {
          title: this.$t("navbar.companies"),
          icon: "mdi-office-building",
          link: "/bedrijven",
          permission: ["everyone"],
        }, //Companies
        {
          title: this.$t("navbar.search"),
          icon: "mdi-account-search",
          link: "/search",
          permission: ["everyone"],
        },
        {
          title: this.$t("navbar.send_email"),
          icon: "mdi-email",
          link: "/send-email",
          permission: ["isAdmin"],
        },
        {
          title: this.$t("navbar.conversations"),
          icon: "mdi-chat",
          link: "/conversations",
          permission: ["everyone"],
        },
        // {
        //   title: this.$t("navbar.analysis"),
        //   icon: "mdi-chart-line",
        //   link: "/analytiek",
        //   permission: ["hasAnalytics", "isAdmin"],
        // }, //Analytics page
        // {
        //   title: this.$t("navbar.dashboard"),
        //   icon: "mdi-monitor-dashboard",
        //   link: "/dashboard",
        //   permission: ["hasDashboard", "isAdmin"],
        // },
        // {
        //   title: this.$t("navbar.marketing"),
        //   icon: "mdi-account-cash",
        //   link: "/marketing",
        //   permission: ["hasMarketing", "isAdmin"],
        // },
        {
          title: this.$t("navbar.admin"),
          icon: "mdi-briefcase-account",
          link: "/admin",
          permission: ["isAdmin"],
        }, //Admin panel for managing employees, positions and statuses
        // {
        //   title: this.$t("navbar.errors"),
        //   icon: "mdi-alert-circle",
        //   link: "/errorpanel",
        //   permission: ["isAdmin"],
        // },
      ];
    },
    selectedItem: {
      get: function () {
        if (this.currentNavItem) {
          return this.currentNavItem;
        } else {
          var newIndex = 0;
          this.items.forEach((listItem) => {
            if (this.currentRouteName == listItem.link) {
              newIndex = this.items.indexOf(listItem);
            }
          });
          return newIndex;
        }
      },
      set: function (newValue) {
        this.currentNavItem = newValue;
      },
    },
    user() {
      return this.$store.state.users.user;
    },
    allowedItemsByPermission() {
      var allowedItems = [];
      for (var item in this.items) {
        for (var i in this.items[item].permission) {
          if (this.items[item].permission[i] === "everyone") {
            allowedItems.push(this.items[item]);
          } else if (permission(this.items[item].permission[i])) {
            allowedItems.push(this.items[item]);
          }
        }
      }
      return allowedItems;
    },

    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    currentRouteName() {
      return this.$route.path;
    },
    computedImage() {
      if (this.image != "") {
        let url = "";
        if (this.image.picture != "") {
          url = "data:image/jpeg;base64," + this.image.picture;
        }
        return url;
      } else {
        return "";
      }
    },
  },
  methods: {
    ...mapActions("notifications", {
      clearNotifCount: CLEAR_NOTIF_COUNT,
      getNotifications: GET_NOTIFICATIONS,
    }),
    ...mapActions("whats_app", {
      getUsersWithConversations: GET_USERS_WITH_CONVERSATIONS,
      updateUnreadMessages: UPDATE_UNREAD_MESSAGES,
    }),
    async loadUnreadMessages() {
      await this.getUsersWithConversations().then((res) => {
        this.users = res.lead_users;

        // filter users with unread messages
        let new_messages_users = this.users.filter((user) => {
          return user.latest_messages.length > 0;
        });
        let twoWeeksAgo = new Date();
        twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
        let new_messages_last_two_weeks = new_messages_users.filter((user) => {
          let latest_message = new Date(user.latest_message);
          return latest_message > twoWeeksAgo;
        });
        this.updateUnreadMessages(new_messages_last_two_weeks.length);
      });
    },
    gotoPage: function (url) {
      this.$router.push(url).catch(() => {});
    },
    // async intialize(){
    //   await this.getNotifications();
    // },
    logout() {
      localStorage.removeItem("userPerm");
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
    darkSwitch() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark", this.$vuetify.theme.dark);
      localStorage.setItem("theme_selected_color", "dark:blueolympic");
    },
    changeLocale() {
      if (this.$i18n.locale === "nl") {
        this.$i18n.locale = "en";
      } else {
        this.$i18n.locale = "nl";
      }
    },
    activePage(path) {
      if (path == this.currentRouteName) {
        return;
      }
    },
    openNotifications() {
      this.clearNotifCount();
      this.gotoPage("/");
    },
    openFileInput() {
      document.getElementById("fileUpload").click();
    },
    async selectFile(file) {
      const { dispatch } = this.$store;
      this.currentFile = file;

      if (!this.currentFile) {
        dispatch("alerts/warn", this.$t("file_uploader.choose_a_file_warn"));
        return;
      }
      var fileToUpload = this.currentFile;

      let uploadData = {
        id: this.user.id,
      };

      await dispatch("users/uploadProfilePic", {
        fileToUpload,
        uploadData,
      });

      await dispatch("users/getEmployeeProfilePic", this.user.id).then(
        (res) => {
          this.image = res;
        }
      );
      this.componentKey++;
    },
    async loadProfileImage() {
      const { dispatch } = this.$store;
      await dispatch("users/getEmployeeProfilePic", this.user.id).then(
        (res) => {
          this.image = res;
        }
      );
    },
  },
};
</script>

<style scoped>
.active {
  filter: blur(4px);
  cursor: pointer;
}

.v-list-item__title {
  color: white;
}

.v-icon.v-icon {
  color: white;
}

.v-navigation-drawer .v-navigation-drawer__content .v-list-item {
  flex: 0;
}

.v-sheet.v-list {
  flex: 0;
}

.myRec {
  background-color: #e85d04;
  color: white;
}
</style>
