import config from "../../../config";
import { authHeader } from "../../utils/auth-header";
import { handleResponse } from "../../utils/api_functions";
import { logger } from "../../utils/logger";

import {
  ADD_STATUS_TO_LEAD_USER,
  CREATE_LEAD_USER,
  GET_LEAD_USERS,
  GET_LEAD_USERS_WITH_LEAD,
  GET_LEAD_USER_BY_ID,
  SEARCH_LEAD_USERS_WITH_LEADS,
  UPDATE_LEAD_USER,
  CHECK_IF_USER_EXISTS,
  DELETE_LEAD_USER,
  GET_LEAD_USERS_BY_RECRUITER,
  SEARCH_LEAD_USERS_BY_NAME,
  EMPTY_FOUND_LEAD_USERS,
} from "../action.type";
import {
  SET_FILTERED_LEAD_USER,
  SET_LEAD_USER,
  SET_LEAD_USERS,
  SET_LEAD_USERS_DISPLAY_DATA,
  SET_LEAD_USERS_LOADING,
  SET_LEAD_USERS_PAGINATION,
  SET_LEAD_USERS_RELOAD,
  SET_LEAD_USERS_WITH_LEAD,
  SET_LEAD_USERS_BY_RECRUITER,
  SET_FOUND_LEAD_USERS,
} from "../mutation.type";

export const lead_users /* candidates */ = {
  namespaced: true,
  state: {
    lead_users: {},
    filtered_lead_user: {},
    active_lead_user: {},
    lead_users_with_lead: [],
    reload_distance_filter: false,
    filter: {},
    pagination: {},
    loading: false,
    displayData: true,
    lead_users_by_recruiter: [],
    found_lead_users: [],
  },
  getters: {
    getLoading: (state) => state.loading,
    getDisplayData: (state) => state.displayData,
    getFilteredLeadUser: (state) => state.filtered_lead_user,
    getReloadDistanceFilter: (state) => state.reload_distance_filter,
    lead_users_by_recruiter_getter: (state) => state.lead_users_by_recruiter,
    found_lead_users_getter: (state) => state.found_lead_users,
  },
  actions: {
    async [GET_LEAD_USERS]({ commit }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/lead-users`, request)
          .then(handleResponse)
          .then((res) => {
            commit(SET_LEAD_USERS, res);
            return res;
          });
      } catch (error) {
        logger.log(error);
      }
    },

    async [GET_LEAD_USERS_WITH_LEAD]({ commit }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/lead-users/leads`, request)
          .then(handleResponse)
          .then((res) => {
            commit(SET_LEAD_USERS_WITH_LEAD, res);
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },

    async [SEARCH_LEAD_USERS_WITH_LEADS]({ commit }, searchData) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        await fetch(`${config.apiUrl}/lead-users/search/${searchData}`, request)
          .then(handleResponse)
          .then((res) => {
            commit(SET_FILTERED_LEAD_USER, res);
          });
      } catch (error) {
        logger.error(error);
      }
    },

    async [SEARCH_LEAD_USERS_BY_NAME]({ commit }, searchData) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        await fetch(
          `${config.apiUrl}/lead-users/searchByName/${searchData}`,
          request
        )
          .then(handleResponse)
          .then((res) => {
            commit(SET_FOUND_LEAD_USERS, res.leadUsers);
          });
      } catch (error) {
        logger.error(error);
      }
    },

    [EMPTY_FOUND_LEAD_USERS]({ commit }) {
      commit(SET_FOUND_LEAD_USERS, []);
    },

    async [GET_LEAD_USER_BY_ID]({ commit }, userId) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/lead-users/${userId}`, request)
          .then(handleResponse)
          .then((res) => {
            res.lead_user.languages = JSON.parse(res.lead_user.languages);
            res.lead_user.address = JSON.parse(res.lead_user.address);
            res.lead_user.parsedCv = JSON.parse(res.lead_user.parsedCv);
            commit(SET_LEAD_USER, res);
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },

    async [CREATE_LEAD_USER](_, lead_user) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(lead_user),
        };
        return await fetch(`${config.apiUrl}/lead-users`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [UPDATE_LEAD_USER](_, { lead_user, userId }) {
      try {
        const request = {
          method: "PUT",
          headers: authHeader(),
          body: JSON.stringify(lead_user),
        };
        return await fetch(`${config.apiUrl}/lead-users/${userId}`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },

    async [ADD_STATUS_TO_LEAD_USER](_, leadUserStatus) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(leadUserStatus),
        };
        return await fetch(`${config.apiUrl}/lead-users/status`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },

    async [CHECK_IF_USER_EXISTS](_, { email }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(
          `${config.apiUrl}/lead-users/exists/${email}`,
          request
        )
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.log(error);
      }
    },

    async [DELETE_LEAD_USER]({ dispatch }, userId) {
      try {
        const request = {
          method: "DELETE",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/lead-users/${userId}`, request)
          .then(handleResponse)
          .then((res) => {});
      } catch (error) {
        logger.error(error);
      }
    },

    async [GET_LEAD_USERS_BY_RECRUITER]({ commit }, id) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(
          `${config.apiUrl}/lead-user/recruiter/${id}`,
          request
        )
          .then(handleResponse)
          .then((res) => {
            commit(SET_LEAD_USERS_BY_RECRUITER, res);
            return res;
          });
      } catch (error) {
        logger.log(error);
      }
    },
  },
  mutations: {
    [SET_LEAD_USERS](state, items) {
      state.lead_users = items.lead_users;
    },
    [SET_LEAD_USER](state, items) {
      state.active_lead_user = items.lead_user;
    },
    [SET_LEAD_USERS_WITH_LEAD](state, items) {
      state.lead_users_with_lead = items.lead_users;
    },
    [SET_FILTERED_LEAD_USER](state, data) {
      state.filtered_lead_user = data.lead_users;
    },
    [SET_LEAD_USERS_PAGINATION](state, pagination) {
      state.pagination = pagination;
    },
    [SET_LEAD_USERS_LOADING](state, bool) {
      state.loading = bool;
    },
    [SET_LEAD_USERS_DISPLAY_DATA](state, bool) {
      state.displayData = bool;
    },
    [SET_LEAD_USERS_RELOAD](state) {
      state.reload_distance_filter = !state.reload_distance_filter;
    },
    [SET_LEAD_USERS_BY_RECRUITER](state, items) {
      state.lead_users_by_recruiter = items.leadUsers;
    },
    [SET_FOUND_LEAD_USERS](state, items) {
      state.found_lead_users = items;
    },
  },
};
