<template>
  <v-card :loading="!loaded">
    <v-container class="pa-4">
      <v-form ref="form" v-if="loaded">
        <v-card-title>
          <span class="text-h5">{{ $t("delete_lead.delete_lead") }}</span>
        </v-card-title>
        <v-card-text>
          <p>{{ $t("delete_lead.perma_deleted") }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="error" @click="deleteLead">{{
            $t("delete_lead.Delete")
          }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import { permission } from "../../utils/permissions";
import { mapState, mapActions } from "vuex";
import { GET_LEAD_USER_BY_ID } from "../../store/action.type";
export default {
  props: {
    type: String,
    formData: Object,
    parentID: Number,
  },
  data: () => ({
    loaded: false,
    data_fields_user: {
      first_name: "",
      last_name: "",
      email: "",
      tel_number: "",
    },
    data_fields: {
      lead_user: 0,
    },
  }),

  computed: {
    ...mapState({
      vacancies: (state) =>
        state.leads.vacancies.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        }),

      channels: (state) => state.leads.channels,

      lead_users: (state) =>
        state.lead_users.lead_users.sort((a, b) => {
          if (a.first_name < b.first_name) {
            return -1;
          }
          if (a.first_name > b.first_name) {
            return 1;
          }
          return 0;
        }),
    }),
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions("lead_users", {
      getLeadUserById: GET_LEAD_USER_BY_ID,
    }),
    async initialize() {
      this.loaded = true;
    },

    clear() {
      this.$refs.form.reset();
    },

    async deleteLead() {
      const { dispatch } = this.$store;
      if (permission("canDeleteLead") || permission("isAdmin")) {
        await dispatch("leads/deleteLeadById", this.formData.id).then(() => {
          this.getLeadUserById(this.$route.params.id);
        });
      } else {
        dispatch("alerts/warn", this.$t("delete_lead.only_admins_can_delete"));
      }

      // this.clear();
      this.$emit("close-dialog");
    },
  },
};
</script>
