<template>
  <v-col xl="4" lg="3" md="6" sm="12">
    <v-dialog v-model="dialogEditLead" max-width="600px">
      <v-card>
        <CreateLead
          v-on:close-dialog="dialogEditLead = false"
          type="1"
          :formData="leadToEdit"
        ></CreateLead>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDeleteLead" max-width="450px">
      <v-card>
        <DeleteLead
          v-on:close-dialog="dialogDeleteLead = false"
          type="1"
          :formData="leadToDelete"
        ></DeleteLead>
      </v-card>
    </v-dialog>
    <v-container fluid class="pl-0 pr-0">
      <v-card min-height="380">
        <v-row :style="cardHeaders" class="ma-0">
          <v-row class="pa-5">
            <v-col md="10">
              <v-row>
                <v-icon medium>mdi-text-box-check</v-icon>
                <h3>
                  <strong>{{ $t("profile_page.leads") }}</strong>
                </h3>
              </v-row>
            </v-col>
          </v-row>
        </v-row>
        <v-row>
          <v-col>
            <v-card class="pa-3 overflow-y-auto" max-height="340" elevation="0">
              <v-container class="pl-6 pt-0">
                <v-row
                  class="pa-0"
                  v-for="item in lead.leads
                    .slice()
                    .sort((a, b) => {
                      return a.active - b.active;
                    })
                    .reverse()"
                  :key="item.id"
                >
                  <v-container class="mb-2 pb-0">
                    <v-row class="mb-2">
                      <v-col md="5" class="pa-0">
                        <p style="margin-bottom: 0">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                medium
                                class="pr-1"
                                v-bind="attrs"
                                v-on="on"
                                >mdi-calendar</v-icon
                              >
                            </template>
                            <span>{{
                              $t("profile_page.application_date")
                            }}</span>
                          </v-tooltip>
                          {{ formatDate(item.applied_date) }}
                        </p>
                        <p style="margin-bottom: 0">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                medium
                                class="pr-1"
                                v-bind="attrs"
                                v-on="on"
                                >mdi-home-assistant</v-icon
                              >
                            </template>
                            <span>{{ $t("profile_page.channel") }}</span>
                          </v-tooltip>
                          {{ item.channel.name }}
                        </p>
                        <p style="margin-bottom: 0">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                medium
                                class="pr-1"
                                v-bind="attrs"
                                v-on="on"
                                >mdi-badge-account</v-icon
                              >
                            </template>
                            <span>{{ $t("profile_page.recruiter") }}</span>
                          </v-tooltip>
                          <span v-if="item.rec_id">
                            {{ item.recruiter.first_name }}
                            {{ item.recruiter.last_name }}</span
                          ><span v-else>{{
                            $t("profile_page.unassigned")
                          }}</span>
                        </p>
                        <p style="margin-bottom: 0">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                medium
                                class="pr-1"
                                v-bind="attrs"
                                v-on="on"
                                >mdi-badge-account-outline</v-icon
                              >
                            </template>
                            <span>{{
                              $t("profile_page.recruiter_assistant")
                            }}</span>
                          </v-tooltip>
                          <span v-if="item.ra_id">
                            {{ item.recruitment_assistant.first_name }}
                            {{ item.recruitment_assistant.last_name }}</span
                          ><span v-else>{{
                            $t("profile_page.unassigned")
                          }}</span>
                        </p>
                        <!-- Displaying the Campagne ID -->
                        <p style="margin-bottom: 0">
                          <span v-if="item.campaign_id"
                            >Campagne ID: {{ item.campaign_id }}</span
                          >
                        </p>
                      </v-col>
                      <v-col md="5" class="pa-0">
                        <p style="margin-bottom: 0">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                medium
                                class="pr-1"
                                v-bind="attrs"
                                v-on="on"
                                >mdi-text-box-multiple</v-icon
                              >
                            </template>
                            <span>{{
                              $t("profile_page.application_vacancy")
                            }}</span>
                          </v-tooltip>
                          <a @click="openVacancy(item.vacancy.id)">{{
                            item.vacancy.name
                          }}</a>
                        </p>
                        <p style="margin-bottom: 0" v-if="item.exit_vacancy">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                medium
                                class="pr-1"
                                v-bind="attrs"
                                v-on="on"
                                >mdi-text-box-multiple-outline</v-icon
                              >
                            </template>
                            <span>{{ $t("profile_page.exit_vacancy") }}</span>
                          </v-tooltip>
                          <a @click="openVacancy(item.exit_vacancy.id)">{{
                            item.exit_vacancy.name
                          }}</a>
                        </p>
                      </v-col>
                      <v-col class="">
                        <v-row justify="end">
                          <v-tooltip bottom v-if="item.id == mainLead.id">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                medium
                                color="green"
                                >mdi-check-bold</v-icon
                              >
                            </template>
                            <span>{{ $t("profile_page.active_lead") }}</span>
                          </v-tooltip>
                          <!-- Make lead active lead -->
                          <v-tooltip bottom v-if="item.id != mainLead.id">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                medium
                                color="grey"
                                @click="changeLeadForActiveLead(item)"
                                >mdi-check-bold</v-icon
                              >
                            </template>
                            <span>{{ $t("profile_page.activate_lead") }}</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                medium
                                color="primary"
                                @click="editLead(item)"
                                >mdi-pencil-box</v-icon
                              >
                            </template>
                            <span
                              >{{ $t("profile_page.lead_id") }}:
                              {{ item.id }}</span
                            >
                          </v-tooltip>
                        </v-row>
                        <v-row justify="end">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                medium
                                color="red"
                                @click="deleteLead(item)"
                                >mdi-delete-forever</v-icon
                              >
                            </template>
                            <span>{{ $t("profile_page.delete") }}</span>
                          </v-tooltip>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                  </v-container>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-col>
</template>

<script>
import { mapActions } from "vuex";
import { permission } from "../../../utils/permissions";
import CreateLead from "../../../components/dialogs/CreateLead.vue";
import DeleteLead from "../../../components/dialogs/DeleteLead.vue";
import {
  ACTIVATE_LEAD,
  GET_USERS,
  GET_LEAD_USER_BY_ID,
} from "../../../store/action.type";
export default {
  props: ["cardHeaders", "user", "lead", "mainLead", "latestStatus"],
  components: {
    CreateLead,
    DeleteLead,
  },
  data() {
    return {
      dialogEditLead: false,
      leadToEdit: {},
      dialogDeleteLead: false,
      leadToDelete: {},
    };
  },
  methods: {
    ...mapActions("leads", {
      activateLead: ACTIVATE_LEAD,
    }),
    ...mapActions("users", { getUsers: GET_USERS }),
    ...mapActions("lead_users", {
      getLeadUserById: GET_LEAD_USER_BY_ID,
    }),
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    async changeLeadForActiveLead(item) {
      await this.activateLead({ leadId: item.id, lead_user: item.lead_user });
      await this.getUser();
    },
    async getUser() {
      await this.getLeadUserById(this.$route.params.id);

      await this.getUsers();
      this.loaded = true;
    },

    editLead(item) {
      this.leadToEdit = item;
      this.dialogEditLead = true;
    },
    async deleteLead(item) {
      const { dispatch } = this.$store;
      if (permission("isAdmin") || permission("canDeleteLead")) {
        this.leadToDelete = item;
        this.dialogDeleteLead = true;
      } else {
        dispatch("alerts/warn", this.$t("profile_page.alert_admin_text"));
      }
    },
    openVacancy(id) {
      this.$router.push(`/vacature/${id}`);
    },
  },
};
</script>
