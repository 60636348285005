<template>
  <v-container class="pa-5">
    <v-form ref="form">
      <v-alert v-model="showAlert" type="warning">
        {{ warningText }}
      </v-alert>
      <span class="text-h5">{{ leadName }}: {{ leadContactNo }}</span>
      <v-container>
        <v-row justify="center">
          <v-col md="2">
            <v-btn color="green" :disabled="saving" @click="answered()">
              <v-icon>mdi-phone</v-icon>
              {{ $t("date_picker.included") }}
            </v-btn>
          </v-col>
          <v-col md="2">
            <v-btn color="red" :disabled="saving" @click="voicemail()">
              <v-icon>mdi-phone-missed</v-icon>
              {{ $t("date_picker.voicemail") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import {
  CREATE_CONTACT_MOMENT,
  GET_LEADS_BY_VACANCY_ID,
  GET_LEADS_PER_PERIOD,
  GET_LEAD_USER_BY_ID,
  UPDATE_FLOW,
} from "../../store/action.type";
export default {
  props: {
    type: Number,
    leadID: Number,
    leadUserID: Number,
    vmCount: Number,
    leadName: String,
    leadContactNo: String,
    additional: String,
  },
  data: () => ({
    planIntake: "yes",
    warningText: "",
    spokenTo: "no",
    showAlert: false,
    saving: false,
    data_fields: {
      time: null,
      selectedRecruiterId: null,
      contactMomentText: null,
      contactMomentStatus: null,
    },
    filters: {},
  }),
  computed: {
    ...mapState({
      stored_filters: (state) => state.filters.lead_filter,
    }),
    user() {
      return this.$store.state.users.user;
    },
  },
  created() {
    this.filters = this.stored_filters;
  },

  methods: {
    ...mapActions("contact_moments", {
      createContactMoment: CREATE_CONTACT_MOMENT,
    }),
    ...mapActions("flow", { updateFlow: UPDATE_FLOW }),

    ...mapActions("lead_users", {
      getLeadUserById: GET_LEAD_USER_BY_ID,
    }),
    ...mapActions("leads", {
      getLeadsByVacancyId: GET_LEADS_BY_VACANCY_ID,
      getLeadsPerPeriod: GET_LEADS_PER_PERIOD,
    }),

    async voicemail() {
      let now = new Date();
      now = now.toISOString().substring(0, 10);
      let past = new Date();
      past.setDate(past.getDate() - 30);
      past = past.toISOString().substring(0, 10);
      this.saving = true;
      const leadId = this.leadID;
      const { dispatch } = this.$store;
      var flow = {
        lead_id: leadId,
      };
      flow.vm_date = new Date();
      if (typeof this.vmCount == "undefined") {
        flow.vm_count = 1;
      } else {
        flow.vm_count = this.vmCount + 1;
      }
      await this.updateFlow({ flow, leadId }).then(() => {
        dispatch("alerts/success", this.$t("date_picker.success_text"));
      });
      await this.addStatusToUser(this.$t("date_picker.calling_failed"), 3);
      // await dispatch("leads/getAllLeadsWithFlow");
      switch (this.type) {
        case 1:
          await this.getLeadsByVacancyId(parseInt(this.additional));
          break;
        case 2:
          await this.getLeadUserById(this.leadUserID);
          break;
        case 3:
          //! OLD VACANCY LOGIC
          break;
        case 5:
          var params = {
            applicationStartDate: past,
            applicationEndDate: now,
            contactStartDate: null,
            contactEndDate: null,
            intakeStartDate: null,
            intakeEndDate: null,
          };
          if (this.filters) {
            if (this.filters.application_dates) {
              if (this.filters.application_dates.length > 0) {
                if (
                  this.filters.application_dates[0] >
                  this.filters.application_dates[1]
                ) {
                  params.applicationStartDate =
                    this.filters.application_dates[1];
                  params.applicationEndDate = this.filters.application_dates[0];
                } else {
                  params.applicationStartDate =
                    this.filters.application_dates[0];
                  params.applicationEndDate = this.filters.application_dates[1];
                }
              }
            }
            if (this.filters.contact_dates) {
              if (this.filters.contact_dates.length > 0) {
                if (
                  this.filters.contact_dates[0] > this.filters.contact_dates[1]
                ) {
                  params.contactStartDate = this.filters.contact_dates[1];
                  params.contactEndDate = this.filters.contact_dates[0];
                } else {
                  params.contactStartDate = this.filters.contact_dates[0];
                  params.contactEndDate = this.filters.contact_dates[1];
                }
              }
            }
            if (this.filters.intake_dates) {
              if (this.filters.intake_dates.length > 0) {
                if (
                  this.filters.intake_dates[0] > this.filters.intake_dates[1]
                ) {
                  params.intakeStartDate = this.filters.intake_dates[1];
                  params.intakeEndDate = this.filters.intake_dates[0];
                } else {
                  params.intakeStartDate = this.filters.intake_dates[0];
                  params.intakeEndDate = this.filters.intake_dates[1];
                }
              }
            }
          }

          await this.getLeadsPerPeriod({ params });
          break;
        default:
          break;
      }
      this.saving = false;
      this.$emit("close-dialog");
    },
    async answered() {
      let now = new Date();
      now = now.toISOString().substring(0, 10);
      let past = new Date();
      past.setDate(past.getDate() - 30);
      past = past.toISOString().substring(0, 10);
      this.saving = true;
      const leadId = this.leadID;
      const { dispatch } = this.$store;
      var flow = {
        lead_id: leadId,
      };
      flow.first_date = new Date();
      await this.updateFlow({ flow, leadId }).then(() => {
        dispatch("alerts/success", this.$t("date_picker.contact_saved"));
      });
      await this.addStatusToUser(this.$t("date_picker.first_contact"), 1);
      //await dispatch("leads/getAllLeadsWithFlow");
      switch (this.type) {
        case 1:
          await this.getLeadsByVacancyId(parseInt(this.additional));
          break;
        case 2:
          await this.getLeadUserById(this.leadUserID);
          break;
        case 3:
          //! OLD VACANCY LOGIC
          break;
        case 5:
          var params = {
            applicationStartDate: past,
            applicationEndDate: now,
            contactStartDate: null,
            contactEndDate: null,
            intakeStartDate: null,
            intakeEndDate: null,
          };
          if (this.filters) {
            if (this.filters.application_dates) {
              if (this.filters.application_dates.length > 0) {
                if (
                  this.filters.application_dates[0] >
                  this.filters.application_dates[1]
                ) {
                  params.applicationStartDate =
                    this.filters.application_dates[1];
                  params.applicationEndDate = this.filters.application_dates[0];
                } else {
                  params.applicationStartDate =
                    this.filters.application_dates[0];
                  params.applicationEndDate = this.filters.application_dates[1];
                }
              }
            }
            if (this.filters.contact_dates) {
              if (this.filters.contact_dates.length > 0) {
                if (
                  this.filters.contact_dates[0] > this.filters.contact_dates[1]
                ) {
                  params.contactStartDate = this.filters.contact_dates[1];
                  params.contactEndDate = this.filters.contact_dates[0];
                } else {
                  params.contactStartDate = this.filters.contact_dates[0];
                  params.contactEndDate = this.filters.contact_dates[1];
                }
              }
            }
            if (this.filters.intake_dates) {
              if (this.filters.intake_dates.length > 0) {
                if (
                  this.filters.intake_dates[0] > this.filters.intake_dates[1]
                ) {
                  params.intakeStartDate = this.filters.intake_dates[1];
                  params.intakeEndDate = this.filters.intake_dates[0];
                } else {
                  params.intakeStartDate = this.filters.intake_dates[0];
                  params.intakeEndDate = this.filters.intake_dates[1];
                }
              }
            }
          }

          await this.getLeadsPerPeriod({ params });
          break;
        default:
          break;
      }
      this.saving = false;
      this.$emit("close-dialog");
      const path = `/profiel/${this.leadUserID}`;
      if (this.$route.path !== path) this.$router.push(path);
    },

    async addStatusToUser(cm_notes, cm_type) {
      const leadUserId = this.leadUserID;
      let newContactMoment = {
        lead_user_id: leadUserId,
        type: cm_type,
        comunicator_id: this.$store.state.users.user.id,
        user_status_id: null,
        notes: cm_notes,
      };
      await this.createContactMoment(newContactMoment);

      return;
    },
  },
};
</script>

<style scoped></style>
