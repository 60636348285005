import config from "../../../config";
import { authHeader } from "../../utils/auth-header";
import { handleResponse } from "../../utils/api_functions";
import { logger } from "../../utils/logger";

import { GET_EMAIL_ACTIVITY } from "../action.type";
import { SET_EMAIL_ACTIVITY } from "../mutation.type";
export const email_activity = {
  namespaced: true,
  state: { email_activity: [] },
  actions: {
    async [GET_EMAIL_ACTIVITY]({ commit }, id) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/get_email_activity/${id}`, request)
          .then(handleResponse)
          .then((res) => {
            commit(SET_EMAIL_ACTIVITY, res.activity);
            return res.activity;
          });
      } catch (error) {
        logger.error(error);
      }
    },
  },
  mutations: {
    [SET_EMAIL_ACTIVITY](state, email_activity) {
      state.email_activity = email_activity;
    },
  },
};
