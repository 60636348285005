<template>
  <v-container>
    <v-form ref="form" v-model="valid" class="pa-3">
      <v-row>
        <h3>{{ $t("create_role.add_a_role") }}</h3>
      </v-row>
      <v-row align="center" justify="center">
        <v-text-field
          v-model="role_name"
          :label="$t('create_role.role_name')"
          required
        ></v-text-field>
        <v-col style="margin-top: 0px; padding-top: 0px" align="end">
          <v-btn
            style="margin-top: 0px; padding-top: 0px"
            :disabled="!valid && !adding"
            color="success"
            class="mr-4"
            @click="create"
          >
            {{ $t("create_role.add_role") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { CREATE_ROLE, GET_ROLES } from "../../store/action.type";
export default {
  data: () => ({
    adding: false,
    valid: true,
    role_name: "",
  }),

  methods: {
    ...mapActions("interests", {
      getRoles: GET_ROLES,
      createRole: CREATE_ROLE,
    }),
    async create() {
      const { dispatch } = this.$store;
      if (this.role_name != "") {
        this.adding = true;
        let role = {
          name: this.role_name,
        };
        await this.createRole(role).then(() => {
          dispatch("alerts/success", this.$t("create_role.role_added"));
          this.getRoles();
          this.adding = false;
          this.clear();
          this.$emit("close-dialog");
        });
      } else {
        this.adding = false;
        dispatch("alerts/warn", this.$t("create_role.name_the_role"));
      }
    },
    clear() {
      this.role_name = "";
    },
  },
};
</script>
