import Vue from "vue";
import SocketIO from "socket.io-client";
import VueSocketIO from "vue-socket.io";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";
import Axios from "./plugins/axios";
// import VueGmaps from "vue-gmaps";
// import * as GmapVue from "gmap-vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { Auth0Plugin } from "./auth";
import { domain, clientId, audience } from "../auth_config.json";
import config from "../config";
import { socketAuth } from "./utils/auth-header";
//For Printing
import VueHtmlToPaper from "vue-html-to-paper";
import "core-js/actual/array";
import i18n from "./i18n";
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

import VEmojiPicker from "v-emoji-picker";
Vue.use(VEmojiPicker);

Vue.component("apexchart", VueApexCharts);
// Vue.use(new VueSocketIO({
//   connection: SocketIO(`${config.socketUrl}?token=${authHeader().Authorization}`),//
// }));

Vue.config.productionTip = false;
Vue.component("vue-google-autocomplete", VueGoogleAutocomplete);

// Vue.use(GmapVue, {
//   load: {
//     key: "AIzaSyCj31I-m2pnviT3M26F7tETzOi_0StjHdw",
//     libraries: "places",
//     region: "NL",
//     language: "nl",
//   },
//   installComponents: true,
// });

// Vue.use(VueGmaps, {
//   key: "AIzaSyCj31I-m2pnviT3M26F7tETzOi_0StjHdw",
// });
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
});

Vue.use(Axios);

Vue.use(VueHtmlToPaper);

const options = {
  auth: {
    token: socketAuth().token,
    user: socketAuth().user,
    groups: socketAuth().groups,
  },
};
//console.log("OPTIONS", options);
Vue.use(
  new VueSocketIO({
    connection: SocketIO(config.socketUrl, options),
  })
);

new Vue({
  store,
  router,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
