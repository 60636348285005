<template>
  <v-container fluid>
    <v-dialog v-model="dialogCreate" max-width="600px">
      <v-card>
        <CreateLeadUser
          v-on:close-dialog="dialogCreate = false"
          type="0"
        ></CreateLeadUser>
      </v-card>
    </v-dialog>
    <v-dialog v-model="sendEmailDialogue" width="750px" height="100%">
      <v-card color="card">
        <SendEmail v-on:close-dialog="sendEmailDialogue = false"> </SendEmail>
      </v-card>
    </v-dialog>
    <v-card class="pa-4" :loading="!leadUsersLoaded">
      <v-row>
        <v-col md="8">
          <v-row class="pa-3">
            <h2>{{ $t("navbar.candidates") }}</h2>
            <v-divider
              vertical
              inset
              style="padding-left: 10px; padding-left: 10px"
            ></v-divider>
            <v-text-field
              style="padding-top: 0px; padding-left: 10px"
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('common_phrases.search')"
              single-line
              hide-details
            ></v-text-field>
          </v-row>
        </v-col>
        <v-col align="end" md="4">
          <v-btn icon color="primary" class="mb-2" @click="reload()">
            <v-icon dark>mdi-reload</v-icon>
          </v-btn>
          <v-btn icon color="primary" class="mb-2" @click="dialogCreate = true">
            <v-icon dark> mdi-plus-box </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-menu
        v-model="showMenu"
        :position-x="x"
        :position-y="y"
        absolute
        offset-y
      >
        <v-list dense>
          <v-list-item @click="open" dense>
            <v-list-item-title>{{
              $t("candidates_page.open")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="openNewTab" dense>
            <v-list-item-title>{{
              $t("candidates_page.new_tab")
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-col>
        <v-btn class="mr-2" color="primary" @click="sendEmail()">
          Send email</v-btn
        >
      </v-col>
      <v-data-table
        v-if="leadUsersLoaded"
        :page.sync="pagination.page"
        :items-per-page="pagination.itemsPerPage"
        :footer-props="{ itemsPerPageOptions: [20, 35, 50, -1] }"
        :headers="headers"
        :items="leadsUserAdjustedList"
        :search="search"
        sort-by="name"
        class="elevation-1 pointerOnHover"
        @click:row="editItem"
        @contextmenu:row="show"
        @pagination="storePaginationToState"
      >
        <template v-slot:top>
          <v-expansion-panels focusable flat :value="collapsed">
            <v-expansion-panel>
              <v-expansion-panel-header class="px-4 pt-4"
                ><h2>{{ $t("common_phrases.filter") }}</h2>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container fluid>
                  <v-row dense>
                    <v-col align="end" class="mr-2 mt-2 mb-0">
                      <v-btn icon color="red" @click="clearFilters()"
                        ><span class="mr-1">{{
                          $t("common_phrases.clear")
                        }}</span
                        ><v-icon dark>mdi-backspace</v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-select
                        :items="genders"
                        v-model="filters.gender"
                        clearable
                        :label="$t('candidates_page.gender')"
                        @change="storeFilterToState"
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="filters.region"
                        append-icon="mdi-magnify"
                        :label="$t('candidates_page.place')"
                        single-line
                        hide-details
                        @change="storeFilterToState"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-select
                        :items="car"
                        v-model="filters.has_car"
                        clearable
                        :label="$t('candidates_page.car')"
                        @change="storeFilterToState"
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-select
                        :items="contract_terms"
                        v-model="filters.contract_term"
                        clearable
                        :label="$t('candidates_page.contract_term')"
                        @change="storeFilterToState"
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-select
                        :items="contract_types"
                        v-model="filters.contract_type"
                        clearable
                        :label="$t('candidates_page.job_scope')"
                        @change="storeFilterToState"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-checkbox
                      class="mr-3"
                      @change="storeFilterToState"
                      :label="$t('candidates_page.blacklist')"
                      v-model="filters.blacklisted"
                    ></v-checkbox>
                    <v-checkbox
                      class="mr-3"
                      @change="storeFilterToState"
                      :label="$t('candidates_page.favorite')"
                      v-model="filters.favorite"
                    ></v-checkbox>
                    <v-checkbox
                      @change="storeFilterToState"
                      :label="$t('candidates_page.liked')"
                      v-model="filters.liked"
                    ></v-checkbox>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
        <template v-slot:[`item.blacklisted`]="{ item }">
          <v-icon v-if="item.blacklisted == true" color="red" class="mr-2">
            mdi-close-thick
          </v-icon>
          <v-icon v-else class="mr-2"> mdi-close-outline </v-icon>
        </template>
        <template v-slot:[`item.favorite`]="{ item }">
          <v-icon v-if="item.favorite == true" color="yellow" class="mr-2">
            mdi-star
          </v-icon>
          <v-icon v-else class="mr-2"> mdi-star-outline </v-icon>
        </template>
        <template v-slot:[`item.liked`]="{ item }">
          <v-icon v-if="item.liked == true" color="red" class="mr-2">
            mdi-cards-heart
          </v-icon>
          <v-icon v-else class="mr-2"> mdi-cards-heart-outline </v-icon>
        </template>
        <template v-slot:[`item.has_car`]="{ item }">
          <v-checkbox :input-value="item.has_car" value disabled></v-checkbox>
        </template>
        <template v-slot:[`item.cv`]="{ item }">
          <v-icon :color="item.cv">mdi-file-document</v-icon>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import CreateLeadUser from "../components/dialogs/CreateLeadUser.vue";
import { mapState, mapMutations, mapActions } from "vuex";
import SendEmail from "../components/emails/dialogs/SendEmail.vue";
import {
  SET_CANDIDATE_FILTER,
  SET_LEAD_USERS_PAGINATION,
} from "../store/mutation.type";
import { GET_LEAD_USERS } from "../store/action.type";
export default {
  components: { CreateLeadUser, SendEmail },
  data: () => ({
    sendEmailDialogue: false,
    leadUsersLoaded: false,
    dialogCreate: false,
    search: "",
    collapsed: 0,
    car: ["Ja", "Nee"],
    genders: ["Man", "Vrouw", "Transgender", "Non-binair"],
    contract_terms: ["Flexibel", "Vast"],
    contract_types: ["Fulltime", "Parttime", "Fulltime/Parttime", "Bijbaan"],
    filters: {},
    selectedRow: null,
    showMenu: false,
    x: 0,
    y: 0,
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$t("candidates_page.blacklist"),
          value: "blacklisted",
          filter: (value) => {
            if (value == null) {
              value = false;
            }
            if (this.filters.blacklisted == null) {
              this.filters.blacklisted = false;
            }
            if (this.filters.blacklisted == value) {
              return true;
            }
            return value == true;
          },
        },
        {
          text: this.$t("candidates_page.favorite"),
          value: "favorite",
          filter: (value) => {
            if (value == null) {
              value = false;
            }
            if (this.filters.favorite == null) {
              this.filters.favorite = false;
            }
            if (this.filters.favorite == value) {
              return true;
            }
            return value == true;
          },
        },
        {
          text: this.$t("candidates_page.liked"),
          value: "liked",
          filter: (value) => {
            if (value == null) {
              value = false;
            }
            if (this.filters.liked == null) {
              this.filters.liked = false;
            }
            this.filters.liked = false;
            if (this.filters.liked == value) {
              return true;
            }
            return value == true;
          },
        },
        {
          text: this.$t("candidates_page.name"),
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: this.$t("candidates_page.gender"),
          sortable: true,
          value: "gender",
          filter: (value) => {
            if (!this.filters.gender) return true;
            return value === this.filters.gender;
          },
        },
        {
          text: this.$t("candidates_page.tel"),
          sortable: true,
          value: "tel_number",
        },
        {
          text: this.$t("candidates_page.place"),
          sortable: true,
          value: "region",
          filter: (value) => {
            if (!this.filters.region) return true;
            return value
              .toLowerCase()
              .includes(this.filters.region.toLowerCase());
          },
        },
        {
          text: this.$t("candidates_page.car"),
          sortable: true,
          value: "has_car",
          filter: (value) => {
            if (!this.filters.has_car) {
              return true;
            }

            const has_car = this.filters.has_car === "Ja" ? true : false;

            return value === has_car;
          },
        },
        { text: "CV Parsed", sortable: true, value: "cv" },
        {
          text: this.$t("candidates_page.contract_term"),
          sortable: true,
          value: "contract_term",
          filter: (value) => {
            if (!this.filters.contract_term) return true;
            return value === this.filters.contract_term;
          },
        },
        {
          text: this.$t("candidates_page.job_scope"),
          sortable: true,
          value: "contract_type",
          filter: (value) => {
            if (!this.filters.contract_type) return true;
            return value === this.filters.contract_type;
          },
        },
        {
          text: this.$t("candidates_page.salary_indication"),
          sortable: true,
          value: "sal_indication",
        },
      ];
    },
    leadsUserAdjustedList() {
      var candidates = this.lead_users.map((e) => {
        return {
          id: e.id,
          name: `${e.first_name.trim()} ${e.last_name.trim()}`,
          gender: this.itemExists(e.gender),
          tel_number: this.itemExists(e.tel_number),
          region: this.itemExists(e.region),
          has_car: e.car,
          contract_term: this.itemExists(e.contract_term),
          contract_type: this.itemExists(e.contract_type),
          sal_indication: this.itemExists(e.sal_indication),
          cv: this.cvParsed(e),
          favorite: e.favorite,
          blacklisted: e.blacklisted,
          liked: e.liked,
        };
      });
      return candidates;
    },
    ...mapState({
      lead_users: (state) => state.lead_users.lead_users,

      stored_filters: (state) => state.filters.candidate_filter,

      pagination: (state) => state.lead_users.pagination,
    }),
  },
  created() {
    this.initialize();
    this.filters = this.stored_filters;
  },
  methods: {
    ...mapActions("lead_users", {
      getLeadUsers: GET_LEAD_USERS,
    }),
    ...mapMutations("filters", { setCandidateFilter: SET_CANDIDATE_FILTER }),
    ...mapMutations("lead_users", {
      setLeadUsersPagination: SET_LEAD_USERS_PAGINATION,
    }),
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },

    itemExists(item) {
      if (item == null || item == "") return "Onbekend";
      return item;
    },

    cvParsed(item) {
      if (item.parsedCv == null || item.parsedCv == "") return "red";
      if (item.textCv == null || item.textCv == "") return "yellow";
      return "green";
    },

    clearFilters() {
      this.filters = {};
      this.storeFilterToState();
    },

    async initialize() {
      if (Object.keys(this.lead_users).length === 0) {
        await this.getLeadUsers();
      }
      this.leadUsersLoaded = true;
    },

    async reload() {
      this.leadUsersLoaded = false;
      await this.getLeadUsers();
      this.leadUsersLoaded = true;
    },

    storeFilterToState() {
      this.setCandidateFilter(this.filters);
    },

    storePaginationToState(val) {
      this.setLeadUsersPagination(val);
    },

    async editItem(item) {
      this.$router.push(`/profiel/${item.id}`);
    },

    open() {
      this.$router.push(`/profiel/${this.selectedRow.id}`);
    },

    openNewTab() {
      let routeData = this.$router.resolve({
        path: `/profiel/${this.selectedRow.id}`,
      });
      window.open(routeData.href, "_blank");
    },

    show(event, row) {
      event.preventDefault();
      this.showMenu = false;
      this.x = event.clientX;
      this.y = event.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
      this.selectedRow = row.item;
    },
    sendEmail() {
      this.sendEmailDialogue = true;
    },
  },
};
</script>
