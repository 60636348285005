<template>
  <v-card>
    <v-card-title class="blue lighten-1 white--text">{{
      $t("version_history.notes")
    }}</v-card-title>
    <v-divider></v-divider>
    <v-card-text class="pa-0 pt-6" style="height: 300px">
      <v-col class="pb-6" v-for="(item, index) in releases" :key="index">
        <v-row class="pb-2">
          <v-chip label class="ma-2" color="purple lighten-3">
            <span class="font-weight-bold">{{ item.version }}</span>
          </v-chip>
          <h1 class="ma-3 font-weight-regular">{{ item.date }}</h1>
        </v-row>

        <v-row
          class="pl-14 ml-2 mt-0 pb-2"
          v-for="(changeItem, index) in item.changes"
          :key="index"
        >
          <v-col class="flex-grow-0 pa-0">
            <v-chip
              label
              class="ma-2 chip-min-width center-text"
              x-small
              :color="changesTypeColor(changeItem.type)"
            >
              <span class="black--text">{{ changeItem.type }}</span>
            </v-chip></v-col
          >
          <v-col class="pa-0">
            <h1
              class="ma-2 ml-1 text-caption font-weight-medium caption-text-color"
            >
              {{ changeItem.description }}
            </h1></v-col
          >
        </v-row>
      </v-col>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {},
  data: () => ({
    releases: [
      {
        version: "2.2.0",
        date: "24 April 2022",
        changes: [
          {
            type: "NEW",
            description: "Automatisch inladen Facebook leads",
          },
          {
            type: "NEW",
            description: "Perma status (profiel > persoon bijwerken)",
          },
          {
            type: "FIX",
            description: "Algemene bug fixes",
          },
        ],
      },
      {
        version: "2.1.0",
        date: "29 Maart 2022",
        changes: [
          {
            type: "NEW",
            description: "Contact moment statastieken",
          },
          {
            type: "NEW",
            description: "EN / NL taal opties",
          },
          {
            type: "NEW",
            description: "Vacture pagina printbaar",
          },
          {
            type: "NEW",
            description: "Leads op halen met intake datum of contact datum",
          },
          {
            type: "NEW",
            description: "Leads tabel laat aantal leads zien",
          },
          {
            type: "FIX",
            description: "Algemene bug fixes",
          },
        ],
      },
      {
        version: "2.0.6",
        date: "15 Februari 2022",
        changes: [
          {
            type: "NEW",
            description:
              "Whatsapp Icoontje voor snel contactmoment aanmaken met filtering",
          },
          {
            type: "NEW",
            description: "Error paneel voor sneller problemen fixen",
          },
          {
            type: "NEW",
            description: "Search kan nu filteren op status en substatus",
          },
          {
            type: "NEW",
            description: "Hartje voor leuke kandidaten die geen ster zijn",
          },
          {
            type: "NEW",
            description: "Lead fases, beperkte status selectie",
          },
          {
            type: "NEW",
            description: "Profiel info op search",
          },
          {
            type: "IMPROVED",
            description: "Lead matching score bepaald door afstand",
          },
          {
            type: "IMPROVED",
            description: "Leads openen in nieuw tabblad",
          },
          {
            type: "IMPROVED",
            description: "Sub status sorteren werkt nu",
          },
          {
            type: "IMPROVED",
            description: "Leads pagina sorteren op Bellen",
          },
        ],
      },
      {
        version: "2.0.5",
        date: "25 Januari 2022",
        changes: [
          {
            type: "IMPROVED",
            description:
              "Afstand filtering op search pagina (wordt met meer gebruik sneller)",
          },
          {
            type: "IMPROVED",
            description: "Verbeteringen aan matching",
          },
          {
            type: "IMPROVED",
            description:
              "Custom kolommen verbeterd (hier door zijn de wel even ge reset)",
          },
          {
            type: "FIX",
            description: "Bug fixes",
          },
          {
            type: "FIX",
            description: "Sub status sorting",
          },
        ],
      },
      {
        version: "2.0.4",
        date: "13 Januari 2022",
        changes: [
          {
            type: "NEW",
            description: "Blacklist filters en labels in profielen",
          },
          {
            type: "NEW",
            description: "Status en sub statusses update",
          },
          {
            type: "IMPROVED",
            description: "Stabiliteit en error preventie",
          },
          {
            type: "IMPROVED",
            description: "Notities hover beter lees baar",
          },
          {
            type: "FIX",
            description: "Probleem met geen rechte opgelost (Auth0 User)",
          },
        ],
      },
      {
        version: "2.0.3",
        date: "4 Januari 2022",
        changes: [
          {
            type: "GELUKKIG NIEUWJAAR",
            description: "Success met het komende jaar",
          },
          {
            type: "NEW",
            description: "Favorite filters en labels in profielen",
          },
          {
            type: "NEW",
            description: "Verbeterde error logging van client errors",
          },
          {
            type: "IMPROVED",
            description:
              "Veranderingen aan matching, kommen binnenkort nog meer aanpassingen",
          },
          {
            type: "FIX",
            description: "Contact momenten aanmaken voor lege profielen",
          },
          {
            type: "FIX",
            description: "Lead campagne ID aanpassen",
          },
        ],
      },
      {
        version: "2.0.2",
        date: "8 December 2021",
        changes: [
          {
            type: "FIX",
            description: "Probleem met vacatures aanmaken",
          },
          {
            type: "FIX",
            description: "Probleem met profielen aanpassen",
          },
          {
            type: "IMPROVED",
            description: "Betere datum selectie voor de leads pagina",
          },
          {
            type: "IMPROVED",
            description: "Bij vacature aanmaken is positie verwijderd",
          },
        ],
      },
      {
        version: "2.0.1",
        date: "29 November 2021",
        changes: [
          {
            type: "IMPROVED",
            description: "Meer rollen beschikbaar voor interesses",
          },
          {
            type: "IMPROVED",
            description: "Navigatie menu",
          },
          {
            type: "IMPROVED",
            description:
              "Verbeteringen aan Search, er zijn soms nog bugs, meld deze aub",
          },
          {
            type: "FIX",
            description: "Geen contact moment kunnen maken voor oude leads",
          },
        ],
      },
      {
        version: "2.0.0",
        date: "22 November 2021",
        changes: [
          {
            type: "NEW",
            description: "Nieuwe server voor CLLBR",
          },
        ],
      },
    ],
  }),

  methods: {
    changesTypeColor(type) {
      if (type == "NEW") return "green lighten-3";
      if (type == "IMPROVED") return "blue lighten-3";
      if (type == "FIX") return "deep-orange lighten-3";
      else return "deep-orange darken-4";
    },
  },
};
</script>

<style scoped>
.chip-min-width {
  min-width: 75px;
  min-height: 20px;
}
.center-text {
  display: flex;
  justify-content: center;
}
.caption-text-color {
  color: var(--v-text);
}
</style>
