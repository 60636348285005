<template>
  <v-container fill-height class="pa-0">
    <v-dialog v-model="dialogInterest" max-width="600px">
      <v-card>
        <CreateInterest
          v-on:close-dialog="dialogInterest = false"
          :type="dialogInterestType"
          :leadUserId="user.id"
        ></CreateInterest>
      </v-card>
    </v-dialog>
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="closeDialog">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-progress-linear
        color="white"
        class="progressBar"
        height="17"
        :value="progress"
      >
        <strong>{{ Math.ceil(progress) }}%</strong>
      </v-progress-linear>
      <v-spacer></v-spacer>
      <v-btn dark text @click="save"> Save </v-btn>
    </v-toolbar>
    <v-row class="d-flex justify-between ma-2"> </v-row>

    <v-card
      class="ma-2 overflow-y-auto justify-center"
      min-height="700"
      width="100%"
      elevation="0"
    >
      <v-tabs
        v-model="tab"
        class=""
        style="border-radius: 0px"
        next-icon="mdi-arrow-right"
        prev-icon="mdi-arrow-left"
        show-arrows
      >
        <v-tab href="#tab-1" @click="updateProgress(1)">{{
          $t("intake_form.info")
        }}</v-tab>
        <v-tab href="#tab-2" @click="updateProgress(2)">{{
          $t("intake_form.education")
        }}</v-tab>
        <v-tab href="#tab-3" @click="updateProgress(3)">{{
          $t("intake_form.work_experience")
        }}</v-tab>
        <v-tab href="#tab-4" @click="updateProgress(4)">{{
          $t("intake_form.swot_analysis")
        }}</v-tab>
        <v-tab href="#tab-5" @click="updateProgress(5)">{{
          $t("intake_form.expectations")
        }}</v-tab>
        <v-tab href="#tab-6" @click="updateProgress(6)">{{
          $t("intake_form.practical_issues")
        }}</v-tab>
        <v-tab href="#tab-7" @click="updateProgress(7)">{{
          $t("intake_form.resume")
        }}</v-tab>

        <v-tab-item value="tab-1">
          <v-card class="pa-2 ma-1 pt-4" max-height="650" elevation="0">
            <v-row>
              <v-col class="py-1">
                <v-text-field
                  v-model="formDataFields.first_name"
                  :label="$t('intake_form.name')"
                ></v-text-field>
              </v-col>
              <v-col class="py-1">
                <v-text-field
                  v-model="formDataFields.last_name"
                  :label="$t('intake_form.last_name')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-1">
                <v-text-field
                  v-model="formDataFields.email"
                  :label="$t('intake_form.email')"
                ></v-text-field>
              </v-col>
              <v-col class="py-1">
                <v-text-field
                  v-model="formDataFields.tel_number"
                  :label="$t('intake_form.phone')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-1">
                <v-menu
                  v-model="dobMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="computedDOB"
                      :label="$t('intake_form.birth_date')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="nl-NL"
                    v-model="formDataFields.dob"
                    @input="dobMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-1">
                <v-select
                  :items="employees"
                  v-model="intakeInfo.recruiterId"
                  item-text="full_name"
                  item-value="id"
                  :label="$t('intake_form.recruiter')"
                ></v-select>
              </v-col>
              <v-col class="py-1">
                <v-text-field
                  v-model="intakeInfo.placeOfIntake"
                  :label="$t('intake_form.medium')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-1">
                <v-menu
                  v-model="doiMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="computedDOI"
                      label="Datum Intake"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="nl-NL"
                    v-model="intakeInfo.dateOfIntake"
                    @input="doiMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-1">
                <v-menu
                  v-model="doaMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="computedDOA"
                      label="Datum Sollicitatie"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="nl-NL"
                    v-model="intakeInfo.dateOfApplication"
                    @input="doaMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row align="end" justify="end">
              <v-btn text color="green" @click="nextTab('tab-2')">
                {{ $t("intake_form.continue") }}
              </v-btn>
            </v-row>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-2">
          <v-card class="pa-2 ma-1 pt-4" elevation="0">
            <v-expansion-panels>
              <v-expansion-panel
                class="mb-0"
                v-for="(component, i) in education.trainingComponents"
                :key="i"
              >
                <v-expansion-panel-header class="panelHeaders">
                  <v-row class="mt-0 pa-0" align="center">
                    <v-btn
                      class="ma-0 pa-0"
                      icon
                      color="red"
                      @click.stop="removeTraining(i)"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    {{ $t("intake_form.education") }} {{ i + 1 }}
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-text-field
                        v-model="component.trainingName"
                        :label="$t('intake_form.school_name')"
                      ></v-text-field>
                    </v-col>
                    <v-col class="py-0">
                      <v-text-field
                        v-model="component.institute"
                        :label="$t('intake_form.institute')"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0">
                      <v-text-field
                        v-model="component.city"
                        :label="$t('intake_form.city')"
                      ></v-text-field>
                    </v-col>
                    <v-col class="py-0">
                      <v-text-field
                        v-model="component.level"
                        :label="$t('intake_form.level')"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-1">
                      <v-menu
                        v-model="enrolmentMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="component.enrolmentDate"
                            :label="$t('intake_form.enrolment_date')"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          locale="nl-NL"
                          v-model="component.enrolmentDate"
                          @input="enrolmentMenu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0">
                      <v-select
                        :items="booleans"
                        v-model="component.working"
                        :label="$t('intake_form.working')"
                      ></v-select>
                    </v-col>
                    <v-col class="py-0">
                      <v-text-field
                        :disabled="!component.working"
                        v-model="component.weekWorkHrs"
                        :label="$t('intake_form.week_hours')"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0">
                      <v-text-field
                        v-model="component.weekSchoolHrs"
                        :label="$t('intake_form.week_school_hours')"
                      ></v-text-field>
                    </v-col>
                    <v-col class="py-0">
                      <v-select
                        :items="booleans"
                        v-model="component.diploma"
                        :label="$t('intake_form.diploma')"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row v-if="component.diploma">
                    <v-col class="py-1">
                      <v-menu
                        v-model="gradMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="component.schoolGradDate"
                            :label="$t('intake_form.school_graduation_date')"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          locale="nl-NL"
                          v-model="component.schoolGradDate"
                          @input="gradMenu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row v-if="!component.diploma">
                    <v-col class="py-1">
                      <v-menu
                        v-model="exGradMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="component.expectedGradDate"
                            :label="$t('intake_form.expected_graduation_date')"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          locale="nl-NL"
                          v-model="component.expectedGradDate"
                          @input="exGradMenu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <v-row align="center" justify="center">
              <v-btn
                class="my-4"
                style="width: 95%"
                dark
                color="light-blue darken-4"
                @click="addTraining"
              >
                {{ $t("intake_form.add_training") }}
              </v-btn>
            </v-row>
            <v-divider class="my-3"></v-divider>
            <v-expansion-panels>
              <v-expansion-panel
                class="mb-0"
                v-for="(component, i) in education.activities"
                :key="i"
              >
                <v-expansion-panel-header class="panelHeaders">
                  <v-row class="mt-0 pa-0" align="center">
                    <v-btn
                      class="ma-0 pa-0"
                      icon
                      color="red"
                      @click.stop="removeActivity(i)"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    {{ $t("intake_form.activity") }} {{ i + 1 }}
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-textarea
                    solo
                    rows="2"
                    :label="$t('intake_form.interests')"
                    v-model="component.additionalActivities"
                  ></v-textarea>

                  <v-row>
                    <v-col class="py-0">
                      <v-select
                        :items="booleans"
                        v-model="component.studentAssociation"
                        :label="$t('intake_form.association')"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row v-if="component.studentAssociation">
                    <v-col class="py-0">
                      <v-text-field
                        v-model="component.nameOfAssociation"
                        :label="$t('intake_form.association_name')"
                      ></v-text-field>
                    </v-col>
                    <v-col class="py-0">
                      <v-text-field
                        v-model="component.weekHrsAss"
                        :label="$t('intake_form.association_hours')"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-row align="center" justify="center">
              <v-btn
                class="my-4"
                style="width: 95%"
                dark
                color="grey darken-2"
                @click="addActivity"
              >
                {{ $t("intake_form.add_activity") }}
              </v-btn>
            </v-row>

            <v-row align="end" justify="end">
              <v-btn text color="black" @click="previousTab('tab-1')">
                {{ $t("intake_form.back") }}
              </v-btn>
              <v-btn text color="green" @click="nextTab('tab-3')">
                {{ $t("intake_form.continue") }}
              </v-btn>
            </v-row>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-3">
          <v-card class="pa-2 ma-1 pt-4" elevation="0">
            <v-expansion-panels>
              <v-expansion-panel
                class="mb-0"
                v-for="(component, i) in workExpComponents"
                :key="i"
              >
                <v-expansion-panel-header class="panelHeaders">
                  <v-row class="mt-0 pa-0" align="center">
                    <v-btn
                      class="ma-0 pa-0"
                      icon
                      color="red"
                      @click.stop="removeWorkExp(i)"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    {{ $t("intake_form.work_experience") }} {{ i + 1 }}
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-text-field
                        v-model="component.company"
                        :label="$t('intake_form.company_name')"
                      ></v-text-field>
                    </v-col>
                    <v-col class="py-0">
                      <v-select
                        :items="industries"
                        item-text="name"
                        item-value="id"
                        v-model="component.industry"
                        :label="$t('intake_form.industry')"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0">
                      <v-text-field
                        v-model="component.location"
                        :label="$t('intake_form.city')"
                      ></v-text-field>
                    </v-col>
                    <v-col class="py-0">
                      <v-text-field
                        v-model="component.jobTitle"
                        :label="$t('intake_form.function')"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-textarea
                      solo
                      rows="2"
                      :label="$t('intake_form.job_description')"
                      v-model="component.jobDescription"
                    ></v-textarea>
                  </v-row>
                  <v-row>
                    <v-col class="py-1">
                      <v-menu
                        v-model="edMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="component.employmentDate"
                            l:label="$t('intake_form.start_date')"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          locale="nl-NL"
                          v-model="component.employmentDate"
                          @input="edMenu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0">
                      <v-select
                        :items="booleans"
                        v-model="component.currentlyEmployed"
                        :label="$t('intake_form.currently_employed')"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row v-if="!component.currentlyEmployed">
                    <v-col class="py-1">
                      <v-menu
                        v-model="eEndMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="component.employmentEndDate"
                            :label="$t('intake_form.employment_end_date')"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          locale="nl-NL"
                          v-model="component.employmentEndDate"
                          @input="eEndMenu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row v-if="!component.currentlyEmployed">
                    <v-textarea
                      solo
                      rows="2"
                      :label="$t('intake_form.leaving_reason')"
                      v-model="component.leavingReason"
                    ></v-textarea>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <v-row align="center" justify="center">
              <v-btn
                class="my-4"
                style="width: 95%"
                dark
                color="light-blue darken-4"
                @click="addWorkExp"
              >
                {{ $t("intake_form.add_work_experience") }}
              </v-btn>
            </v-row>
            <v-row align="end" justify="end">
              <v-btn text color="black" @click="previousTab('tab-2')">
                {{ $t("intake_form.back") }}
              </v-btn>
              <v-btn text color="green" @click="nextTab('tab-4')">
                {{ $t("intake_form.continue") }}
              </v-btn>
            </v-row>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-4">
          <v-card class="pa-2 ma-1 pt-4" elevation="0">
            <v-expansion-panels>
              <v-expansion-panel
                class="mb-0"
                v-for="(component, i) in swot.strengths"
                :key="i"
              >
                <v-expansion-panel-header class="panelHeaders">
                  <v-row class="mt-0 pa-0" align="center">
                    <v-btn
                      class="ma-0 pa-0"
                      icon
                      color="red"
                      @click.stop="removeStrength(i)"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    {{ $t("intake_form.good_feature") }} {{ i + 1 }}
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-text-field
                        v-model="component.name"
                        :label="$t('intake_form.good_feature')"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-textarea
                      solo
                      rows="2"
                      :label="$t('intake_form.explanation')"
                      v-model="component.explanation"
                    ></v-textarea>
                  </v-row>
                  <v-row>
                    <v-textarea
                      solo
                      rows="2"
                      :label="$t('intake_form.improvement')"
                      v-model="component.improvement"
                    ></v-textarea>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <v-row align="center" justify="center">
              <v-btn
                class="my-4"
                style="width: 95%"
                dark
                color="light-blue darken-4"
                @click="addStrength"
              >
                {{ $t("intake_form.add_good_feature") }}
              </v-btn>
            </v-row>
            <v-divider class="my-3"></v-divider>
            <v-expansion-panels>
              <v-expansion-panel
                class="mb-0"
                v-for="(component, i) in swot.weaknesses"
                :key="i"
              >
                <v-expansion-panel-header class="panelHeaders">
                  <v-row class="mt-0 pa-0" align="center">
                    <v-btn
                      class="ma-0 pa-0"
                      icon
                      color="red"
                      @click.stop="removeWeakness(i)"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    {{ $t("intake_form.development_point") }} {{ i + 1 }}
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col class="py-0">
                      <v-text-field
                        v-model="component.name"
                        :label="$t('intake_form.development_point')"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-textarea
                      solo
                      rows="2"
                      :label="$t('intake_form.explanation')"
                      v-model="component.explanation"
                    ></v-textarea>
                  </v-row>
                  <v-row>
                    <v-textarea
                      solo
                      rows="2"
                      :label="$t('intake_form.improvement')"
                      v-model="component.improvement"
                    ></v-textarea>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <v-row align="center" justify="center">
              <v-btn
                class="my-4"
                style="width: 95%"
                dark
                color="red lighten-1
"
                @click="addWeakness"
              >
                {{ $t("intake_form.add_development_point") }}
              </v-btn>
            </v-row>
            <v-row>
              <v-textarea
                solo
                rows="3"
                :label="$t('intake_form.group_role')"
                v-model="swot.roleInAGroup"
              ></v-textarea>
            </v-row>
            <v-row>
              <v-textarea
                solo
                rows="3"
                :label="$t('intake_form.ambitions')"
                v-model="swot.futurePlans"
              ></v-textarea>
            </v-row>
            <v-row align="end" justify="end">
              <v-btn text color="black" @click="previousTab('tab-3')">
                {{ $t("intake_form.back") }}
              </v-btn>
              <v-btn text color="green" @click="nextTab('tab-5')">
                {{ $t("intake_form.continue") }}
              </v-btn>
            </v-row>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-5">
          <v-card class="pa-2 ma-1 pt-4" elevation="0">
            <v-row class="pb-2">
              <v-subheader>{{
                $t("intake_form.your_expectations")
              }}</v-subheader>
            </v-row>

            <v-textarea
              solo
              rows="3"
              :label="$t('intake_form.example')"
              v-model="expectations.fromEmployer"
            ></v-textarea>

            <!-- <v-row>
              <v-subheader>Prefered work days</v-subheader>
            </v-row>

            <v-sheet elevation="0" class="pa-1">
               <v-chip
            label-group
                v-model="expectations.preferedDays"
                active-class="primary--text"
                column
                multiple
              >
                 <v-chip
            label class="dayChips" filter outlined>
                  Monday
                </v-chip>
                 <v-chip
            label class="dayChips" filter outlined>
                  Tuesday
                </v-chip>
                 <v-chip
            label class="dayChips" filter outlined>
                  Wednesday
                </v-chip>
                 <v-chip
            label class="dayChips" filter outlined>
                  Thursday
                </v-chip>
                 <v-chip
            label class="dayChips" filter outlined>
                  Friday
                </v-chip>
                 <v-chip
            label class="dayChips" filter outlined>
                  Saturday
                </v-chip>
                 <v-chip
            label class="dayChips" filter outlined>
                  Sunday
                </v-chip>
              </v-chip-group>
            </v-sheet> -->

            <v-row>
              <v-subheader>{{ $t("intake_form.working_days") }}</v-subheader>
            </v-row>
            <!-- <v-row justify="space-around" class="pa-0 ma-0">
              <v-switch
                v-model="expectations.preferedTimeOfDay"
                color="primary"
                label="Morning"
                value="morning"
              ></v-switch>
              <v-switch
                v-model="expectations.preferedTimeOfDay"
                color="primary"
                label="Afternoon"
                value="afternoon"
              ></v-switch>
              <v-switch
                v-model="expectations.preferedTimeOfDay"
                color="primary"
                label="Evening"
                value="evening"
              ></v-switch>
              <v-switch
                v-model="expectations.preferedTimeOfDay"
                color="primary"
                label="Night"
                value="night"
              ></v-switch>
            </v-row> -->
            <v-row>
              <v-card
                color="blue-grey darken-4"
                dark
                width="100%"
                class="mx-2 mt-1"
              >
                <v-row class="py-1 px-3" align="center" justify="center">
                  <v-col> {{ $t("intake_form.day") }} </v-col>
                  <v-col><h5>06:00 - 12:00</h5></v-col>
                  <v-col><h5>12:00 - 17:00</h5></v-col>
                  <v-col><h5>17:00 - 23:00</h5></v-col>
                  <v-col><h5>23:00 - 04:00</h5></v-col>
                </v-row>
              </v-card>
            </v-row>
            <v-row v-for="(component, i) in expectations.preferedDays" :key="i">
              <v-card
                color="blue-grey darken-4"
                dark
                width="100%"
                height="100%"
                elevation="0"
                class="mx-2 mt-1 pr-3"
              >
                <v-row class="px-1" align="center">
                  <v-col cols="4" sm="1" md="2"> {{ component.name }} </v-col>
                  <v-col>
                    <v-chip-group
                      dark
                      v-model="component.timesOfDay"
                      active-class="primary--text"
                      multiple
                    >
                      <v-chip
                        label
                        active-class="blue darken-1"
                        class="chipEl mx-9"
                      >
                        {{ $t("intake_form.morning") }}
                      </v-chip>
                      <v-chip
                        label
                        active-class="blue darken-1"
                        class="chipEl mx-9"
                      >
                        {{ $t("intake_form.mid_day") }}
                      </v-chip>
                      <v-chip
                        label
                        active-class="blue darken-1"
                        class="chipEl mx-9"
                      >
                        {{ $t("intake_form.evening") }}
                      </v-chip>
                      <v-chip
                        label
                        active-class="blue darken-1"
                        class="chipEl mx-9"
                      >
                        {{ $t("intake_form.night") }}
                      </v-chip>
                    </v-chip-group>
                  </v-col>
                </v-row>
              </v-card>
            </v-row>
            <v-row>
              <v-subheader>{{ $t("intake_form.work_per_week") }}</v-subheader>
            </v-row>

            <v-sheet elevation="0" class="pa-1">
              <v-range-slider
                v-model="expectations.workPerWeek"
                max="40"
                min="1"
                thumb-label
                hide-details
                class="align-center"
              >
                <template v-slot:prepend>
                  <v-text-field
                    :value="expectations.workPerWeek[0]"
                    class="mt-0 pt-0"
                    hide-details
                    single-line
                    type="number"
                    style="width: 60px"
                    @change="$set(expectations.workPerWeek, 0, $event)"
                  ></v-text-field>
                </template>
                <template v-slot:append>
                  <v-text-field
                    :value="expectations.workPerWeek[1]"
                    class="mt-0 pt-0"
                    hide-details
                    single-line
                    type="number"
                    style="width: 60px"
                    @change="$set(expectations.workPerWeek, 1, $event)"
                  ></v-text-field>
                </template>
              </v-range-slider>
            </v-sheet>

            <v-row class="pb-2">
              <v-subheader>{{ $t("intake_form.hours_notes") }}</v-subheader>
            </v-row>

            <v-textarea
              solo
              rows="3"
              :label="$t('intake_form.example_notes')"
              v-model="expectations.hoursNotes"
            ></v-textarea>

            <v-row class="pb-2">
              <v-subheader>
                {{ $t("intake_form.expectations_learning_outcome") }}
              </v-subheader>
            </v-row>

            <v-textarea
              solo
              rows="3"
              :label="$t('intake_form.example_learning_outcome')"
              v-model="expectations.learningGoal"
            ></v-textarea>
            <v-row align="end" justify="end">
              <v-btn text color="black" @click="previousTab('tab-4')">
                {{ $t("intake_form.back") }}
              </v-btn>
              <v-btn text color="green" @click="nextTab('tab-6')">
                {{ $t("intake_form.continue") }}
              </v-btn>
            </v-row>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-6">
          <v-card class="pa-2 ma-1 pt-4" elevation="0">
            <v-row>
              <v-col class="py-0">
                <v-text-field
                  v-model="practicalMatters.height"
                  :label="$t('intake_form.length')"
                ></v-text-field>
              </v-col>
              <v-col class="py-0">
                <v-text-field
                  v-model="practicalMatters.shoeSize"
                  :label="$t('intake_form.shoe_size')"
                ></v-text-field>
              </v-col>
              <v-col class="py-0">
                <v-text-field
                  v-model="practicalMatters.blouseSize"
                  :label="$t('intake_form.shirt_size')"
                ></v-text-field>
              </v-col>
              <v-col class="py-0">
                <v-text-field
                  v-model="practicalMatters.pantsSize"
                  :label="$t('intake_form.pants_size')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="space-around">
              <v-checkbox
                v-model="practicalMatters.beard"
                :label="$t('intake_form.beard')"
              ></v-checkbox>
              <v-checkbox
                v-model="practicalMatters.piercings"
                :label="$t('intake_form.piercings')"
              ></v-checkbox>
              <v-checkbox
                v-model="practicalMatters.tattoos"
                :label="$t('intake_form.tattoos')"
              ></v-checkbox>
              <v-checkbox
                v-model="data_fields.drivers_license"
                :label="$t('intake_form.driver_license')"
              ></v-checkbox>
              <v-checkbox v-model="data_fields.car" label="Car"></v-checkbox>
            </v-row>

            <v-row class="mt-0 mb-2">
              <LanguageSelector v-model="data_fields.languages" />
            </v-row>

            <v-card elevation="0" class="pa-2 mb-3">
              <v-row>
                <v-subheader>
                  {{ $t("intake_form.preferred_features") }}
                </v-subheader>

                <v-row class="pa-5">
                  <v-col md="10"> </v-col>
                  <v-col md="2" align="end" class="pa-0">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          medium
                          @click="createNewInterest"
                          >mdi-plus-box</v-icon
                        >
                      </template>
                      <span>{{ $t("intake_form.new_interests") }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-row>
              <v-row align="center" justify="center">
                <v-col>
                  <InterestsSection :leadUserId="user.id"></InterestsSection>
                </v-col>
              </v-row>
            </v-card>

            <v-row align="end" justify="end">
              <v-btn text color="black" @click="previousTab('tab-5')">
                {{ $t("intake_form.back") }}
              </v-btn>
              <v-btn text color="green" @click="nextTab('tab-7')">
                {{ $t("intake_form.continue") }}
              </v-btn>
            </v-row>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-7">
          <v-card class="pa-2 ma-1 pt-4" elevation="0">
            <v-row class="pb-2">
              <v-subheader>{{
                $t("intake_form.candidate_summary")
              }}</v-subheader>
            </v-row>

            <v-textarea
              solo
              rows="3"
              label="Ex. A good candidate overall"
              v-model="summary.text"
            ></v-textarea>
            <v-row class="pb-0">
              <v-subheader>{{
                $t("intake_form.candidate_summary")
              }}</v-subheader>
            </v-row>
            <v-sheet elevation="0" class="pa-1">
              <v-chip-group
                v-model="summary.suitableJobs"
                active-class="primary--text"
                column
                multiple
              >
                <v-chip label large class="chipComponent" filter outlined>
                  {{ $t("intake_form.barista") }}
                </v-chip>
                <v-chip label large class="chipComponent" filter outlined>
                  {{ $t("intake_form.waiter") }}
                </v-chip>
                <v-chip label large class="chipComponent" filter outlined>
                  {{ $t("intake_form.bartender") }}
                </v-chip>
                <v-chip label large class="chipComponent" filter outlined>
                  {{ $t("intake_form.kitchen") }}
                </v-chip>
                <v-chip label large class="chipComponent" filter outlined>
                  {{ $t("intake_form.dishwashing") }}
                </v-chip>
              </v-chip-group>
            </v-sheet>

            <v-row class="pb-0">
              <v-subheader>{{
                $t("intake_form.suitable_work_place")
              }}</v-subheader>
            </v-row>
            <v-sheet elevation="0" class="pa-1">
              <v-chip-group
                v-model="summary.suitableWorkPlace"
                active-class="primary--text"
                column
                multiple
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      label
                      v-bind="attrs"
                      v-on="on"
                      large
                      class="chipComponent"
                      filter
                      outlined
                    >
                      <v-icon>mdi-silverware-fork-knife</v-icon>
                    </v-chip>
                  </template>
                  <span>{{ $t("intake_form.restaurant") }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      label
                      v-bind="attrs"
                      v-on="on"
                      large
                      class="chipComponent"
                      filter
                      outlined
                    >
                      <v-icon>mdi-bed</v-icon>
                    </v-chip>
                  </template>
                  <span>{{ $t("intake_form.hotel") }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      label
                      v-bind="attrs"
                      v-on="on"
                      large
                      class="chipComponent"
                      filter
                      outlined
                    >
                      <v-icon>mdi-basketball</v-icon>
                    </v-chip>
                  </template>
                  <span>{{ $t("intake_form.sport") }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      label
                      v-bind="attrs"
                      v-on="on"
                      large
                      class="chipComponent"
                      filter
                      outlined
                    >
                      <v-icon>mdi-calendar-star</v-icon>
                    </v-chip>
                  </template>
                  <span>{{ $t("intake_form.catering") }}</span>
                </v-tooltip>
              </v-chip-group>
            </v-sheet>

            <v-row class="pb-0">
              <v-subheader>{{ $t("intake_form.rating") }}</v-subheader>
            </v-row>

            <v-rating
              v-model="summary.rating"
              color="yellow darken-3"
              background-color="grey darken-1"
              empty-icon="$ratingFull"
              half-increments
              hover
              large
            ></v-rating>

            <v-row align="end" justify="end">
              <v-btn text color="black" @click="previousTab('tab-6')">
                {{ $t("intake_form.back") }}
              </v-btn>
            </v-row>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
import LanguageSelector from "../Selectors/LanguageSelector";
import InterestsSection from "../Interests/InterestsSection.vue";
import CreateInterest from "../dialogs/CreateInterest";
import { mapState, mapGetters, mapActions } from "vuex";
import {
  CREATE_INTAKE,
  GET_INDUSTRIES,
  GET_INTAKE_BY_USER,
  GET_LEAD_USER_BY_ID,
  UPDATE_LEAD_USER,
} from "../../store/action.type";
export default {
  components: { LanguageSelector, InterestsSection, CreateInterest },
  props: {
    user: Object,
    lead: Object,
  },
  data() {
    return {
      loading: false,
      progress: 0,
      dobMenu: false,
      doiMenu: false,
      doaMenu: false,
      edMenu: false,
      eEndMenu: false,
      enrolmentMenu: false,
      gradMenu: false,
      exGradMenu: false,
      tab: "tab-1",
      dialogInterestType: "0",
      dialogInterest: false,

      workExpComponents: [],
      education: {
        trainingComponents: [],
        activities: [],
      },
      swot: {
        strengths: [],
        weaknesses: [],
        roleInAGroup: "",
        futurePlans: "",
      },
      expectations: {
        fromEmployer: "",
        workPerWeek: [1, 40],
        hoursNotes: "",
        preferedDays: [
          {
            value: 0,
            name: "Mon",
            timesOfDay: [],
          },
          {
            value: 1,
            name: "Tue",
            timesOfDay: [],
          },
          {
            value: 2,
            name: "Wed",
            timesOfDay: [],
          },
          {
            value: 3,
            name: "Thu",
            timesOfDay: [],
          },
          {
            value: 4,
            name: "Fri",
            timesOfDay: [],
          },
          {
            value: 5,
            name: "Sat",
            timesOfDay: [],
          },
          {
            value: 6,
            name: "Sun",
            timesOfDay: [],
          },
        ],
        preferedTimeOfDay: [],
        learningGoal: "",
      },

      timesOfDay: ["Ochtend", "Middag", "Avond", "Nacht"],
      practicalMatters: {
        shoeSize: "",
        blouseSize: "",
        pantsSize: "",
        beard: false,
        piercings: false,
        tattoos: false,
        height: "",
      },
      summary: {
        text: "",
        suitableJobs: [],
        suitableWorkPlace: [],
        rating: 0,
      },
      intakeInfo: {
        recruiterId: null,
        dateOfIntake: "",
        dateOfApplication: "",
        placeOfIntake: "",
      },
      data_fields: {
        first_name: "",
        last_name: "",
        email: "",
        tel_number: "",
        address: {
          street: "",
          postalcode: "",
          houseno: "",
          addition: "",
          region: "",
        },
        region: "",
        languages: [],
        platform: "",
        bullhorn: false,
        drivers_license: false,
        car: false,
        contract_signed: false,
        contract: "",
        dob: "",
        notes: "",
        contract_term: "",
        contract_type: "",
      },
      booleans: [
        {
          text: "Yes",
          value: true,
        },
        {
          text: "No",
          value: false,
        },
      ],
    };
  },

  computed: {
    ...mapGetters("interests", ["industries"]),
    ...mapState({
      employees: (state) =>
        state.users.all.items.employees
          .filter((e) => e.role !== null) //Removed this as roles are a bit odd at the momment
          .sort((a, b) => {
            if (a.first_name < b.first_name) {
              return -1;
            }
            if (a.first_name > b.first_name) {
              return 1;
            }
            return 0;
          })
          .map((e) => {
            return {
              id: e.id,
              full_name: e.first_name + " " + e.last_name,
            };
          }),
    }),
    formDataFields() {
      this.updateFormFields();
      return this.data_fields;
    },
    computedDOB: {
      get() {
        return this.formatDate(this.data_fields.dob);
      },
      set() {
        return null;
      },
    },
    computedDOA: {
      get() {
        return this.formatDate(this.intakeInfo.dateOfApplication);
      },
      set() {
        return null;
      },
    },
    computedDOI: {
      get() {
        return this.formatDate(this.intakeInfo.dateOfIntake);
      },
      set() {
        return null;
      },
    },
    // orderedDays: {
    //   get() {
    //     return this.sortByDays(this.expectations.preferedDays);
    //   },
    //   set() {
    //     return null;
    //   },
    // },
  },

  created() {
    this.initialize();
    this.data_fields = this.user;
  },

  methods: {
    ...mapActions("intake_data", {
      createIntake: CREATE_INTAKE,
      getIntakeByUser: GET_INTAKE_BY_USER,
    }),
    ...mapActions("interests", {
      getIndustries: GET_INDUSTRIES,
    }),
    ...mapActions("lead_users", {
      getLeadUserById: GET_LEAD_USER_BY_ID,
      updateLeadUser: UPDATE_LEAD_USER,
    }),
    async initialize() {
      await this.getIndustries();

      await this.getIntakeByUser(this.lead.lead_user).then((res) => {
        if (res.intake !== null) {
          this.intakeInfo.dateOfIntake = res.intake.intake_date.slice(0, 10);
          this.intakeInfo.placeOfIntake = res.intake.intake_place;
          this.education = res.intake.education;
          this.workExpComponents = res.intake.workExperience;
          this.swot = res.intake.swot;
          this.expectations = res.intake.workExpectations;
          this.practicalMatters = res.intake.practicalMatters;
          this.summary = res.intake.summary;
        }
      });
    },

    updateFormFields() {
      this.data_fields = this.user;
      if (this.lead) {
        this.intakeInfo.recruiterId = this.lead.rec_id;
        this.intakeInfo.dateOfApplication = this.lead.applied_date;
      }
      if (this.data_fields.address == null) {
        this.data_fields.address = {
          street: "",
          postalcode: "",
          houseno: "",
          addition: "",
          region: "",
        };
      }
    },
    createNewInterest() {
      this.dialogInterestType = "0";
      this.dialogInterest = true;
    },

    closeDialog() {
      this.$emit("close-dialog");
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },

    nextTab(tabId) {
      this.tab = tabId;
      this.updateProgress(parseInt(tabId.charAt(tabId.length - 1)));
    },

    previousTab(tabId) {
      this.tab = tabId;
      this.updateProgress(parseInt(tabId.charAt(tabId.length - 1)));
    },

    addTraining() {
      this.education.trainingComponents.push({
        trainingName: "",
        institute: "",
        level: "",
        city: "",
        enrolmentDate: "",
        working: false,
        weekSchoolHrs: "",
        weekWorkHrs: "",
        diploma: false,
        expectedGradDate: "",
        schoolGradDate: "",
      });
    },

    removeTraining(index) {
      this.education.trainingComponents.splice(index, 1);
    },

    addActivity() {
      this.education.activities.push({
        additionalActivities: "",
        studentAssociation: false,
        nameOfAssociation: "",
        weekHrsAss: "",
      });
    },

    removeActivity(index) {
      this.education.activities.splice(index, 1);
    },

    addWorkExp() {
      this.workExpComponents.push({
        company: "",
        industry: "",
        location: "",
        jobTitle: "",
        jobDescription: "",
        employmentDate: "",
        currentlyEmployed: false,
        employmentEndDate: "",
        leavingReason: "",
      });
    },

    removeWorkExp(index) {
      this.workExpComponents.splice(index, 1);
    },
    addStrength() {
      this.swot.strengths.push({
        name: "",
        explanation: "",
        improvement: "",
      });
    },

    removeStrength(index) {
      this.swot.strengths.splice(index, 1);
    },

    addWeakness() {
      this.swot.weaknesses.push({
        name: "",
        explanation: "",
        improvement: "",
      });
    },

    removeWeakness(index) {
      this.swot.weaknesses.splice(index, 1);
    },

    updateProgress(id) {
      switch (id) {
        case 1:
          this.progress = 0;
          break;
        case 2:
          this.progress = 17;
          break;
        case 3:
          this.progress = 34;
          break;
        case 4:
          this.progress = 51;
          break;
        case 5:
          this.progress = 68;
          break;
        case 6:
          this.progress = 85;
          break;
        case 7:
          this.progress = 100;
          break;

        default:
          break;
      }
    },
    async save() {
      //TODO: save all
      const { dispatch } = this.$store;
      this.loading = true;
      let lead_user = {
        first_name: this.data_fields.first_name,
        last_name: this.data_fields.last_name,
        dob: this.data_fields.dob,
        gender: this.data_fields.gender,
        address: this.data_fields.address,
        tel_number: this.data_fields.tel_number,
        languages: this.data_fields.languages,
        region: this.data_fields.region,
        bullhorn: this.data_fields.bullhorn,
        email: this.data_fields.email,
        drivers_license: this.data_fields.drivers_license,
        car: this.data_fields.car,
        platform: this.data_fields.platform,
        sal_indication: this.data_fields.sal_indication,
        contract: this.data_fields.contract,
        contract_signed: this.data_fields.contract_signed,
        notes: this.data_fields.notes,
        contract_term: this.data_fields.contract_term,
        contract_type: this.data_fields.contract_type,
      };
      if (!lead_user.contract_signed) lead_user.contract = null;

      lead_user.address = JSON.stringify(lead_user.address);
      lead_user.languages = JSON.stringify(lead_user.languages);
      let userId = this.user.id;
      await this.updateLeadUser({
        lead_user,
        userId,
      });
      let lead = {
        lead_user: this.lead.lead_user,
        vacancy_id: this.lead.vacancy_id,
        channel_id: this.lead.channel_id,
        //campaign_id: this.data_fields.campaign_id,        --Not currently in use
        applied_date: this.intakeInfo.dateOfApplication,
        //notes: this.data_fields.notes,
        ra_id: this.lead.ra_id,
        rec_id: this.intakeInfo.recruiterId,
        exit_vacancy_id: this.lead.exit_vacancy_id,
      };
      let leadId = this.lead.id;
      await dispatch("leads/updateLead", { lead, leadId });
      //TODO: Save date of intake somewhere - maybe flow?
      let temp = null;
      if (
        this.intakeInfo.dateOfIntake === null ||
        this.intakeInfo.dateOfIntake === ""
      ) {
        this.intakeInfo.dateOfIntake = new Date(Date.now()).toISOString();
        temp = this.intakeInfo.dateOfIntake.slice(0, 10);
      } else {
        temp = this.intakeInfo.dateOfIntake;
      }
      let intakeData = {
        lead_user: this.lead.lead_user,
        intake_date: temp,
        intake_place: this.intakeInfo.placeOfIntake,
        education: this.education,
        workExperience: this.workExpComponents,
        swot: this.swot,
        workExpectations: this.expectations,
        practicalMatters: this.practicalMatters,
        summary: this.summary,
      };
      intakeData.education = JSON.stringify(intakeData.education);
      intakeData.workExperience = JSON.stringify(intakeData.workExperience);
      intakeData.swot = JSON.stringify(intakeData.swot);
      intakeData.workExpectations = JSON.stringify(intakeData.workExpectations);
      intakeData.practicalMatters = JSON.stringify(intakeData.practicalMatters);
      intakeData.summary = JSON.stringify(intakeData.summary);
      await this.createIntake(intakeData).then(() => {
        dispatch("alerts/success", "Intake Saved");
        this.getLeadUserById(userId);
      });
      this.$emit("close-dialog");

      //TODO: If save is successfull:
      this.loading = false;
      //TODO Close tab
    },
  },
};
</script>

<style lang="scss">
.progressBar {
  border-radius: 25px;
  width: 60%;
  font-size: 14px;
  color: black !important;
}
.panelHeaders {
  background-color: white;
  color: black;
  font-size: 1rem;
  font-weight: 500;
}
.chipEl {
  border-radius: 15px !important;
  font-weight: 500;
}
.chipComponent {
  border-radius: 15px !important;
}
.dayChips {
  border-radius: 15px !important;
}
</style>
