<template>
  <v-card>
    <v-container>
      <v-text-field
        background-color="cardFields"
        outlined
        dense
        required
        :rules="requiredRules"
        label="Email Template Name"
        v-model="activeTemplate.title"
        class="mt-4"
      ></v-text-field>
      <tiptap-vuetify v-model="activeTemplate.body" :extensions="extensions" />

      <v-row class="ma-0 mt-4">
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="update()">Save</v-btn>
        <v-spacer />
        <v-icon @click="deleteTemplate()" medium color="red" class="deleteIcon"
          >mdi-trash-can</v-icon
        >
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import {
  UPDATE_EMAIL_TEMPLATE,
  DELETE_EMAIL_TEMPLATE,
} from "../../../store/action.type";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";
export default {
  props: { activeTemplate: Object },
  components: { TiptapVuetify },
  data: () => ({
    name: "",
    description: "",
    requiredRules: [(v) => !!v || "Field is required"],
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
    ],
    content: "",
  }),
  created() {
    this.init();
  },
  methods: {
    ...mapActions("email_template", {
      updateEmailTemplate: UPDATE_EMAIL_TEMPLATE,
      deleteEmailTemplate: DELETE_EMAIL_TEMPLATE,
    }),
    async init() {
      this.name = this.activeTemplate.title;
      this.description = this.activeTemplate.body;
    },
    async update() {
      const { dispatch } = this.$store;
      let id = this.activeTemplate.id;
      let email_template = {
        title: this.activeTemplate.title,
        body: this.activeTemplate.body,
      };
      await this.updateEmailTemplate({
        email_template,
        id,
      });
      this.$emit("close-dialog");
      this.$emit("reload-table");
      dispatch("alerts/success", "Updated successfully!");
    },
    async deleteTemplate() {
      const { dispatch } = this.$store;
      let id = this.activeTemplate.id;
      await this.deleteEmailTemplate({
        id,
      });
      this.$emit("close-dialog");
      this.$emit("reload-table");
      dispatch("alerts/success", "Deleted successfully!");
    },
  },
};
</script>
<style scoped></style>
