import config from "../../../config";
import { authHeader } from "../../utils/auth-header";
import { handleResponse } from "../../utils/api_functions";
import { logger } from "../../utils/logger";

import {
  GET_ALL_TEMPLATES,
  CREATE_TEMPLATE,
  UPDATE_TEMPLATE,
  DELETE_TEMPLATE,
} from "../action.type";
import { SET_TEMPLATES } from "../mutation.type";

export const vacancy_template = {
  namespaced: true,
  state: { templates: [] },
  getters: { templates_getter: (state) => state.templates },
  actions: {
    async [GET_ALL_TEMPLATES]({ commit }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/get_templates`, request)
          .then(handleResponse)
          .then((res) => {
            commit(SET_TEMPLATES, res.templates);
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [UPDATE_TEMPLATE]({ commit }, { template, id }) {
      try {
        const request = {
          method: "PUT",
          headers: authHeader(),
          body: JSON.stringify(template),
        };
        return await fetch(
          `${config.apiUrl}/update_template/${id}`,
          request
        ).then(handleResponse);
      } catch (error) {
        logger.error(error);
      }
    },
    async [CREATE_TEMPLATE]({ commit }, { template }) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(template),
        };
        return await fetch(`${config.apiUrl}/create_template/`, request).then(
          handleResponse
        );
      } catch (error) {
        logger.error(error);
      }
    },
    async [DELETE_TEMPLATE]({ commit }, { id }) {
      try {
        const request = {
          method: "DELETE",
          headers: authHeader(),
        };
        return await fetch(
          `${config.apiUrl}/delete_template/${id}`,
          request
        ).then(handleResponse);
      } catch (error) {
        logger.error(error);
      }
    },
  },

  mutations: {
    [SET_TEMPLATES](state, templates) {
      state.templates = templates;
    },
  },
};
