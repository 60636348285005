<template>
  <v-container class="pa-5">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-text-field
          v-model="formDataFields.name"
          :label="$t('create_cm_status.name_cm_status')"
          required
          :loading="adding"
        ></v-text-field>
      </v-row>
      <v-row justify="center">
        <v-btn
          :disabled="!valid || adding"
          color="success"
          class="mr-4"
          @click="buttonClick"
        >
          {{ types[type].buttonText }}
        </v-btn>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { permission } from "../../utils/permissions";
import { mapActions } from "vuex";
import {
  CREATE_CONTACT_MOMENT_STATUS,
  GET_CONTACT_MOMENT_STATUSES,
  UPDATE_CONTACT_MOMENT_STATUS,
} from "../../store/action.type";
export default {
  props: {
    type: String,
    formData: Object,
    parentID: Number,
  },
  data: () => ({
    adding: false,
    valid: false,
    data_fields: {
      name: "",
    },
  }),

  computed: {
    formDataFields() {
      this.updateFormFields();
      return this.data_fields;
    },
    types() {
      return [
        {
          buttonText: this.$t("create_cm_status.create_cm_status"),
        },
        {
          buttonText: this.$t("create_cm_status.customize_cm_status"),
        },
      ];
    },
  },

  created() {
    if (this.type == 1) {
      this.data_fields = this.formData;
    }
  },

  methods: {
    ...mapActions("statuses", {
      createContactMomentStatus: CREATE_CONTACT_MOMENT_STATUS,
      updateContactMomentStatus: UPDATE_CONTACT_MOMENT_STATUS,
    }),
    ...mapActions("statuses", {
      getContactMomentStatuses: GET_CONTACT_MOMENT_STATUSES,
    }),
    updateFormFields() {
      if (this.type == 1) {
        this.data_fields = this.formData;
      }
    },

    buttonClick() {
      if (this.type == 0) {
        this.create();
      } else if (this.type == 1) {
        this.editContactMomentStatus();
      }
    },

    async editContactMomentStatus() {
      const { dispatch } = this.$store;
      if (this.$refs.form.validate()) {
        this.adding = true;
        let contact_moment_status = {
          name: this.data_fields.name,
        };
        let contact_moment_status_id = this.formData.id;
        if (permission("isAdmin")) {
          await this.updateContactMomentStatus({
            contact_moment_status,
            contact_moment_status_id,
          }).then(() => {
            dispatch(
              "alerts/success",
              this.$t("create_cm_status.cm_status_saved")
            );
            this.getContactMomentStatuses();
            this.$emit("close-dialog");
          });
        } else {
          dispatch(
            "alerts/warn",
            this.$t("create_cm_status.not_authorized_msg")
          );
        }
      } else {
        dispatch(
          "alerts/warn",
          this.$t("create_cm_status.not_all_fields_completed")
        );
      }
      this.adding = false;
    },

    async create() {
      const { dispatch } = this.$store;
      if (this.$refs.form.validate()) {
        this.adding = true;
        let contact_moment_status = {
          name: this.data_fields.name,
        };
        if (permission("isAdmin")) {
          await this.createContactMomentStatus(contact_moment_status).then(
            () => {
              dispatch(
                "alerts/success",
                this.$t("create_cm_status.cm_status_created")
              );
              this.getContactMomentStatuses();
              this.clear();
              this.$emit("close-dialog");
            }
          );
        } else {
          dispatch(
            "alerts/warn",
            this.$t("create_cm_status.not_authorized_msg")
          );
        }
      } else {
        dispatch(
          "alerts/warn",
          this.$t("create_cm_status.not_all_fields_completed")
        );
      }
      this.adding = false;
    },
    clear() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style scoped></style>
