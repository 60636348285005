<template>
  <v-container fluid>
    <v-dialog v-model="dialogColumns" max-width="600px">
      <SelectColumns
        :headers="headers"
        type="Search"
        v-on:headers-changed="setHeaders($event)"
        v-on:close-dialog="dialogColumns = false"
      />
    </v-dialog>
    <v-dialog v-model="sendEmailDialogue" width="750px" height="100%">
      <v-card color="card">
        <SendEmail
          v-on:close-dialog="reset()"
          :selectedCandidates="mapCandidates(selected)"
        >
        </SendEmail>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addToHotlistDialogue" width="750px">
      <v-card>
        <v-card-title class="ml-2" style="font-size: 1.5rem">
          Create New Hotlist
        </v-card-title>
        <CreateHotlist
          :selectedCandidates="mapCandidates(selected)"
          v-on:close-dialog="reset()"
        />
      </v-card>
    </v-dialog>
    <v-dialog v-model="selectHotlistDialogue" width="450px">
      <v-card>
        <v-card-title style="font-size: 1.5rem"> Add to Hotlist </v-card-title>
        <AddMembers
          :selectedCandidates="mapCandidates(selected)"
          v-on:close-dialog="reset()"
        ></AddMembers>
      </v-card>
    </v-dialog>
    <v-dialog v-model="sendGridConfirmDialog" max-width="600">
      <v-card>
        <v-card-title
          style="background-color: #1976d2; color: white"
          class="text-h6 mb-3"
        >
          {{ $t("leads_page.add_to_sendgrid") }}
        </v-card-title>
        <v-card-text
          class="mt-4 pb-0"
          style="font-size: 16px; color: #52b69a; font-weight: 500"
        >
          {{ $t("leads_page.upload_to", { sendGridUsers: sendGridUsers }) }}:
        </v-card-text>
        <v-row class="mx-0 px-0 my-2" align="center">
          <v-col md="9">
            <v-select
              :items="getContactLists"
              v-model="selectedLists"
              class="pl-6"
              :label="$t('leads_page.select_list')"
              item-text="name"
              item-value="id"
              multiple
            ></v-select>
          </v-col>
          <v-col sm="3">
            <v-btn
              color="primary"
              tile
              class="ma-0"
              @click="createListDialog = true"
              >{{ $t("leads_page.new_list") }}</v-btn
            >
          </v-col>
        </v-row>
        <v-card-actions>
          <p v-if="sendGridUsers === 0">
            {{ $t("profile_page.no_users") }}
          </p>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="sendGridConfirmDialog = false">
            {{ $t("leads_page.cancel") }}</v-btn
          >
          <v-btn
            :disabled="selectedLists.length === 0"
            color="primary"
            text
            @click="addToSendGrid"
            >{{ $t("leads_page.upload") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- // ! No longer used for the new vacancies -->
    <!-- <v-dialog v-model="dialogVacancy" max-width="600px">
      <SelectVacancy
        :selectedUsers="selectedIds"
        v-on:close-dialog="dialogVacancy = false"
      />
    </v-dialog> -->
    <v-navigation-drawer :width="550" v-model="drawer" absolute right>
      <v-list-item class="my-2">
        <v-list-item-content>
          <v-list-item-title style="font-size: 1.5em">
            <v-row align="center">
              <v-col>
                {{
                  "ID: " +
                  this.selectedLeadUser.id +
                  " " +
                  this.selectedLeadUser.first_name +
                  " " +
                  this.selectedLeadUser.last_name
                }}
              </v-col>
              <v-col align="end">
                <v-btn
                  icon
                  color="red"
                  align="end"
                  size="15"
                  @click="drawer = false"
                >
                  <v-icon large>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="mb-5"></v-divider>
      <v-tabs>
        <v-tab>{{ $t("search_page.text_cv") }}</v-tab>
        <v-tab>{{ $t("search_page.parsed_cv") }}</v-tab>
        <v-tab>{{ $t("search_page.profile") }}</v-tab>

        <v-tab-item>
          <div
            style="background-color: #d64550; color: white; text-align: center"
            v-if="this.selectedLeadUser.textCv == null"
          >
            {{ $t("search_page.no_cv_found") }}
          </div>
          <v-card v-else class="pa-4 ma-2 elevation-0" rounded>
            <text-highlight :queries="this.search">{{
              this.selectedLeadUser.textCv
            }}</text-highlight>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <div
            style="background-color: #d64550; color: white; text-align: center"
            v-if="this.selectedLeadUser.parsedCv == null"
          >
            {{ $t("search_page.no_cv_found") }}
          </div>
          <v-card v-else style="width: 700px; min-height: 700px">
            <ParsedCvViewer
              :parsedCv="this.selectedLeadUser.parsedCv"
            ></ParsedCvViewer>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <LeadUserInfo :activeLead="this.selectedLeadUser" />
          <ContactMomentsInfo :activeLead="this.selectedLeadUser" />
          <LeadUserInterests :activeLead="this.selectedLeadUser" />
        </v-tab-item>
      </v-tabs>
    </v-navigation-drawer>

    <v-card class="pa-4" @mousedown="drawer = false">
      <v-row class="" style="justify-content: space-between">
        <v-col md="6">
          <v-row class="pa-3">
            <h2>{{ $t("common_phrases.search") }}</h2>
            <v-divider
              vertical
              inset
              style="padding-left: 10px; padding-left: 10px"
            ></v-divider>
            <v-text-field
              style="padding-top: 0px; padding-left: 10px"
              v-model="search"
              :label="$t('common_phrases.search')"
              single-line
              hide-details
              @keyup.enter="deepSearch(search)"
              :loading="disableSearchBtn"
            ></v-text-field>
            <v-btn
              :disabled="disableSearchBtn"
              @click="deepSearch(search)"
              color="primary"
              class="ml-4 animatedBtn"
            >
              {{ $t("search_page.search") }}
            </v-btn>
            <v-btn
              :disabled="!filteredLeadUsers.length != 0 || excelExporting"
              @click="excelExport()"
              color="primary"
              class="ml-4 animatedBtn"
            >
              {{ $t("search_page.export_excel") }}
            </v-btn>
          </v-row>
        </v-col>

        <v-col align="end" md="2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                class="mb-2"
                @click="sendGridInfo"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon dark>mdi-email</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("leads_page.export_to_sendgrid") }}</span>
          </v-tooltip>
          <!-- // ! No longer used for the new vacancies -->
          <!-- <v-btn
            :disabled="selected.length == 0"
            icon
            color="primary"
            class="mb-2"
            @click="openVacancySelect()"
          >
            <v-icon dark>mdi-briefcase</v-icon>
          </v-btn> -->
          <v-btn
            icon
            color="primary"
            class="mb-2"
            @click="dialogColumns = true"
          >
            <v-icon dark>mdi-bookmark</v-icon>
          </v-btn>
          <v-btn icon color="primary" class="mb-2" @click="clear()">
            <v-icon dark>mdi-autorenew</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="justify-end">
        <v-btn
          :disabled="selected.length == 0"
          @click="sendEmailDialogue = true"
          class="mr-4 animatedBtn"
          >Send Email
        </v-btn>
        <v-btn
          :disabled="selected.length == 0"
          @click="addToHotlistDialogue = true"
          class="mr-4 animatedBtn"
          >Create New Hotlist
        </v-btn>
        <v-btn
          :disabled="selected.length == 0"
          @click="selectHotlistDialogue = true"
          class="mr-4 animatedBtn"
          >Add to Hotlist
        </v-btn>
      </v-row>
      <v-row class="pb-5" style="justify-content: start">
        <v-checkbox
          class="ml-3"
          v-model="filterDistance"
          @change="distanceSearch(search)"
        ></v-checkbox>
        <v-col class="d-flex" cols="6" sm="3">
          <!-- <gmap-autocomplete
            disable
            :class="
              this.$vuetify.theme.dark ? 'gmapField-dark' : 'gmapField-light'
            "
            :v-model="description"
            placeholder="Input address"
            @place_changed="setPlace"
            :select-first-on-enter="true"
            :disabled="!filterDistance"
          >
          </gmap-autocomplete> -->
          <vue-google-autocomplete
            ref="address"
            id="map"
            :class="
              this.$vuetify.theme.dark ? 'gmapField-dark' : 'gmapField-light'
            "
            :placeholder="$t('search_page.city_input')"
            v-on:placechanged="setPlace"
            country="nl"
            :disabled="!filterDistance"
            types="(cities)"
          >
          </vue-google-autocomplete>
        </v-col>
        <v-col class="d-flex" cols="6" sm="2">
          <v-select
            dense
            v-model="selectedRadius"
            :items="radius"
            item-text="distance"
            item-value="value"
            :label="$t('search_page.radius')"
            persistent-hint
            :disabled="!filterDistance"
            solo
          ></v-select>
        </v-col>
      </v-row>
      <div class="pb-0" style="justify-content: start; margin-top: -35px">
        <div class="mb-3" v-for="(component, i) in components" :key="i">
          <v-row class="mt-0" style="width: 50%" justify="start">
            <v-btn class="mr-3 ml-2" icon color="red" @click="remove(i)">
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-select
              dense
              v-model="component.selectedFilter"
              :items="component.filterBy"
              item-text="desc"
              item-value="id"
              :label="$t('search_page.custom_filter')"
              :hint="component.selectedFilter != null ? 'Filter By' : ''"
              persistent-hint
              solo
              class="mr-7"
              style="width: 20px"
              @change="filterSelect(i)"
            ></v-select>

            <v-select
              v-if="component.selectedFilter === 2"
              dense
              v-model="genderFieldValue"
              :items="genderSelect"
              solo
              class="ml-0"
              style="width: 20px"
              @input="inputChanged"
            ></v-select>

            <v-select
              v-if="component.selectedFilter === 6"
              dense
              v-model="languageFieldValue"
              :items="languageSelect"
              item-text="name"
              item-value="value"
              solo
              class="ml-0"
              style="width: 20px"
              @input="inputChanged"
            ></v-select>

            <v-select
              v-if="component.selectedFilter === 3"
              dense
              v-model="recruiterFieldValue"
              :items="employees"
              item-text="full_name"
              item-value="id"
              solo
              class="ml-0"
              style="width: 20px"
              @input="inputChanged"
            ></v-select>

            <v-select
              v-if="component.selectedFilter === 4"
              dense
              v-model="recAssFieldValue"
              :items="employees"
              item-text="full_name"
              item-value="id"
              solo
              class="ml-0"
              style="width: 20px"
              @input="inputChanged"
            ></v-select>

            <v-col>
              <v-autocomplete
                v-if="component.selectedFilter === 5"
                dense
                style="50%"
                :items="getleadStatuses"
                v-model="statusesFieldValues"
                chips
                deletable-chips
                multiple
                item-text="name"
                return-object
                :label="$t('search_page.status')"
                @input="inputChanged"
                ><template v-slot:selection="{ attr, on, item, selected }">
                  <v-chip
                    label
                    v-bind="attr"
                    :input-value="selected"
                    v-on="on"
                    close
                    :color="item.color"
                    @click:close="removeStatus(item)"
                    ><div style="color: black">
                      <strong>{{ item.name }}</strong>
                    </div></v-chip
                  >
                </template>
              </v-autocomplete>

              <v-autocomplete
                v-if="component.selectedFilter === 5"
                :items="computedSubStatuses"
                v-model="substatusesFieldValues"
                style="25px"
                chips
                deletable-chips
                multiple
                item-text="name"
                return-object
                :label="$t('search_page.sub_status')"
                :no-data-text="noDataText"
                @input="inputChanged"
                ><template v-slot:selection="{ attr, on, item, selected }">
                  <v-chip
                    label
                    v-bind="attr"
                    :input-value="selected"
                    v-on="on"
                    close
                    :color="item.color"
                    @click:close="removeSubStatus(item)"
                    ><div style="color: black">
                      <strong>{{ item.name }}</strong>
                    </div></v-chip
                  >
                </template>
              </v-autocomplete>
            </v-col>

            <v-range-slider
              v-if="component.selectedFilter === 1"
              v-model="range"
              :max="max"
              :min="min"
              hide-details
            >
              <template v-slot:prepend>
                <v-text-field
                  :value="range[0]"
                  class="mt-0 pt-0"
                  hide-details
                  single-line
                  type="number"
                  style="width: 60px"
                  @change="$set(range, 0, $event)"
                ></v-text-field>
              </template>
              <template v-slot:append>
                <v-text-field
                  :value="range[1]"
                  class="mt-0 pt-0"
                  hide-details
                  single-line
                  type="number"
                  style="width: 60px"
                  @change="$set(range, 1, $event)"
                ></v-text-field>
              </template>
            </v-range-slider>
          </v-row>
          <v-row class="mt-0" style="width: 37%" justify="start"> </v-row>
        </div>
      </div>

      <v-row class="pb-6 mt-2" style="justify-content: start">
        <v-btn
          class="mx-2 elevation-0"
          @click="add"
          small
          fab
          dark
          color="primary"
        >
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </v-row>
      <v-data-table
        v-if="leadUsersLoaded"
        v-model="selected"
        show-select
        :page.sync="pagination.page"
        :items-per-page="pagination.itemsPerPage"
        :footer-props="{ itemsPerPageOptions: [20, 35, 50, -1] }"
        :headers="headers"
        :items="leadsuserAdjustedList"
        item-key="id"
        :sort-by="this.filterDistance ? 'distanceFromCity' : 'name'"
        class="elevation-1 pointerOnHover"
        @pagination="storePaginationToState"
        @current-items="getFiltered"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon class="mr-2" color="blue" v-on:click.stop="openNewTab(item)">
            mdi-open-in-new
          </v-icon>
          <v-icon class="mr-2" color="blue" v-on:click.stop="openCv(item)">
            mdi-binoculars
          </v-icon>
          <v-tooltip right color="black" max-width="27%">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="blue" class="mr-2" v-bind="attrs" v-on="on">
                mdi-note-text
              </v-icon>
            </template>
            <span>{{ item.notes || $t("search_page.no_notes") }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.status_name`]="{ item }">
          <v-chip label :color="item.status_color"
            ><div :style="`color: ${calculateTextColor(item.status_color)}`">
              <strong>{{ item.status_name }}</strong>
            </div></v-chip
          >
        </template>
        <template v-slot:[`item.sub_statuses`]="{ item }">
          <div v-for="i in item.sub_statuses" :key="i.id">
            <v-chip label class="mt-1" :color="i.sub_status.color"
              ><strong>{{ i.sub_status.name }}</strong></v-chip
            >
          </div>
        </template>
        <template v-slot:[`item.distanceFromCity`]="{ item }">
          <v-chip
            label
            class="ma-2"
            :color="
              item.distanceFromCity == 199999 || !filterDistance
                ? 'transparent'
                : item.distanceFromCity == 0 || item.distanceFromCity == 1
                ? 'orange'
                : 'secondary'
            "
          >
            {{ convertMetersToKm(item.distanceFromCity) }}
          </v-chip>
        </template>
        <template v-slot:[`item.has_car`]="{ item }">
          <v-icon :color="item.has_car ? 'green' : 'red'" medium
            >mdi-car</v-icon
          >
        </template>
        <template v-slot:[`item.rec_ass_id`]="{ item }">
          {{ employeeName(item.rec_ass_id) }}
        </template>
        <template v-slot:[`item.rec_id`]="{ item }">
          {{ employeeName(item.rec_id) }}
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import ParsedCvViewer from "../components/dialogs/ParsedCvViewer.vue";
import TextHighlight from "vue-text-highlight";
import SelectColumns from "../components/dialogs/SelectColumns.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import {
  GET_DISTANCE_BY_CITIES_IN_BULK,
  GET_LEAD_STATUSES,
  GET_LEAD_USER_BY_ID,
  GET_USERS,
  SEARCH_LEAD_USERS_WITH_LEADS,
  UPDATE_EMPLOYEE,
  SEND_CONTACTS,
  GET_ALL_CONTACT_LISTS,
  CREATE_CONTACT_LIST,
  GET_ALL_HOTLISTS,
} from "../store/action.type";
import { SET_LEAD_USERS_PAGINATION } from "../store/mutation.type";
import LeadUserInfo from "../components/LeadUser/LeadUserInfo.vue";
import ContactMomentsInfo from "../components/Contact/ContactMomentsInfo.vue";
import LeadUserInterests from "../components/LeadUser/LeadUserInterests.vue";
import SendEmail from "../components/emails/dialogs/SendEmail";
import CreateHotlist from "../components/hotlists/dialogs/CreateHotlist";
import AddMembers from "../components/hotlists/dialogs/AddMembers";
export default {
  components: {
    TextHighlight,
    ParsedCvViewer,
    SelectColumns,
    VueGoogleAutocomplete,
    LeadUserInfo,
    ContactMomentsInfo,
    LeadUserInterests,
    SendEmail,
    CreateHotlist,
    AddMembers,
  },
  data: () => ({
    sendGridConfirmDialog: false,
    sendGridUsers: 0,
    selectedLists: [],
    leadUsersLoaded: false,
    filteredLeadUsers: [],
    selected: [],
    selectedIds: [],
    excelExporting: false,
    search: "",
    disableSearchBtn: false,
    description: "",
    vicinity: "",
    components: [],
    ageSelect: [...Array(100).keys()],
    genderSelect: ["Man", "Vrouw", "Transgender", "Non-binair"],
    languageSelect: [
      { name: "Dutch", value: "nl" },
      { name: "English", value: "en" },
    ],
    languageFieldValue: null,

    genderFieldValue: "",
    recruiterFieldValue: "",
    recAssFieldValue: "",
    statusesFieldValues: [],
    substatusesFieldValues: [],
    min: 10,
    max: 70,
    range: [10, 70],

    filterDistance: false,

    selectedRadius: 200000,
    radius: [
      {
        distance: "2 km",
        value: 2000,
      },
      {
        distance: "5 km",
        value: 5000,
      },
      {
        distance: "10 km",
        value: 10000,
      },
      {
        distance: "20 km",
        value: 20000,
      },
      {
        distance: "50 km",
        value: 50000,
      },
      {
        distance: "100 km",
        value: 100000,
      },
      {
        distance: "200 km",
        value: 200000,
      },
    ],

    selectedUserId: null,
    selectedLeadUser: {},
    drawer: false,
    dialogColumns: false,
    selectedHeaders: [],
    sendEmailDialogue: false,
    addToHotlistDialogue: false,
    selectedHotlist: null,
    updateHotlistDialogue: false,
    selectHotlistDialogue: false,
  }),

  computed: {
    headers() {
      let headers = [
        {
          id: 1,
          text: this.$t("search_page.action"),
          value: "actions",
          perma: true,
        },
        {
          id: 2,
          text: this.$t("search_page.status"),
          value: "status_name",
          perma: true,
          filter: this.statusFilter,
        },
        {
          id: 3,
          text: this.$t("search_page.sub_status"),
          value: "sub_statuses",
          align: " d-none",
          filter: this.subStatusFilter,
        },
        {
          id: 4,
          text: this.$t("search_page.name"),
          sortable: true,
          value: "name",
          perma: true,
        },
        {
          id: 5,
          text: this.$t("search_page.distance"),
          value: "distanceFromCity",
          align: " d-none",
          filter: (value) => {
            if (!this.filterDistance) return true;
            return value < this.selectedRadius;
          },
        },
        {
          id: 6,
          text: this.$t("search_page.age"),
          value: "age",
          align: " d-none",
          filter: this.ageFilter,
        },
        {
          id: 7,
          text: this.$t("search_page.recruiter"),
          value: "rec_id",
          align: " d-none",
          filter: this.recruiterFilter,
        },
        {
          id: 8,
          text: this.$t("search_page.recruiter_ass"),
          value: "rec_ass_id",
          align: " d-none",
          filter: this.recAssFilter,
        },
        {
          id: 9,
          text: this.$t("search_page.gender"),
          sortable: true,
          value: "gender",
          align: " d-none",
          filter: this.genderFilter,
        },
        {
          id: 10,
          text: this.$t("search_page.tel"),
          sortable: true,
          value: "tel_number",
          align: " d-none",
        },
        {
          id: 11,
          text: this.$t("search_page.place"),
          sortable: true,
          value: "region",
          align: " d-none",
        },
        {
          id: 12,
          text: this.$t("search_page.car"),
          sortable: true,
          value: "has_car",
          align: " d-none",
        },
        {
          id: 13,
          text: this.$t("search_page.contract_term"),
          sortable: true,
          value: "contract_term",
          align: " d-none",
        },
        {
          id: 14,
          text: this.$t("search_page.job_scope"),
          sortable: true,
          value: "contract_type",
          align: " d-none",
        },
        {
          id: 15,
          text: this.$t("search_page.salary_indication"),
          sortable: true,
          value: "sal_indication",
          align: " d-none",
        },
        {
          id: 16,
          text: this.$t("search_page.contact_moments"),
          sortable: true,
          value: "contact_moments",
          align: " d-none",
        },
        {
          id: 17,
          text: this.$t("search_page.last_contact"),
          sortable: true,
          value: "latest_contact",
          align: " d-none",
        },
        {
          id: 18,
          text: this.$t("search_page.applied_date"),
          sortable: true,
          value: "applied_date",
          align: " d-none",
        },
        {
          id: 19,
          text: this.$t("search_page.applied_vac"),
          sortable: true,
          value: "applied_vac",
          align: " d-none",
        },
        {
          id: 20,
          text: this.$t("search_page.applied_channel"),
          sortable: true,
          value: "applied_channel",
          align: " d-none",
        },
        {
          text: "Language",
          sortable: true,
          value: "languages",
          align: " d-none",
          filter: this.languageFilter,
        },
      ];
      for (let i = 0; i < headers.length; i++) {
        if (this.selectedHeaders.some((elem) => headers[i].id === elem)) {
          headers[i].align = "";
        }
      }
      return headers;
    },
    leadsuserAdjustedList() {
      return this.lead_users.map((e) => {
        return {
          id: e.id,
          address: e.address,
          distanceFromCity: 199999,
          status_name: this.latestStatus(e).name,
          status_color: this.latestStatus(e).color,
          name: `${e.first_name} ${e.last_name}`,
          first_name: e.first_name,
          last_name: e.last_name,
          age: this.getAge(this.itemExists(e.dob)),
          gender: this.itemExists(e.gender),
          tel_number: this.itemExists(e.tel_number),
          region: this.itemExists(e.region),
          has_car: e.car,
          rec_id: this.itemExists(e.leads[e.leads.length - 1].recruiter).id,
          rec_ass_id: this.itemExists(
            e.leads[e.leads.length - 1].recruitment_assistant
          ).id,
          contract_term: this.itemExists(e.contract_term),
          contract_type: this.itemExists(e.contract_type),
          sal_indication: this.itemExists(e.sal_indication),
          email: this.itemExists(e.email),
          contact_moments: e.contact_moments.length,
          latest_contact: this.latestContactMomentDate(e.contact_moments),
          applied_date: this.latestLead(e.leads).applied_date,
          applied_vac: this.latestLead(e.leads).vacancy.name,
          applied_channel: this.latestLead(e.leads).channel.name,
          sub_statuses: this.subStatuses(e),
          notes: e.notes,
          languages: JSON.parse(e.languages),
        };
      });
    },
    computedSubStatuses: {
      get() {
        let sub_statuses = [];
        if (this.statusesFieldValues) {
          for (let i = 0; i < this.statusesFieldValues.length; i++) {
            for (
              let x = 0;
              x < this.statusesFieldValues[i].sub_statuses.length;
              x++
            ) {
              sub_statuses.push(this.statusesFieldValues[i].sub_statuses[x]);
            }
          }
        }
        return sub_statuses;
      },
      set() {
        return null;
      },
    },
    noDataText() {
      if (this.statusesFieldValues.length === 0)
        return this.$t("search_page.choose_a_filter_status");
      let counter = 0;
      for (let i = 0; i < this.statusesFieldValues; i++) {
        if (this.statusesFieldValues[i].sub_statuses) counter++;
      }
      if (counter === 0)
        return this.$t("search_page.choose_a_filter_substatus");
      return "";
    },
    ...mapState({
      lead_users: (state) => state.lead_users.filtered_lead_user,
      pagination: (state) => state.lead_users.pagination,

      employees: (state) =>
        state.users.all.items.employees
          .filter((e) => e.role <= 4)
          .sort((a, b) => {
            if (a.first_name < b.first_name) {
              return -1;
            }
            if (a.first_name > b.first_name) {
              return 1;
            }
            return 0;
          })
          .map((e) => {
            return {
              id: e.id,
              full_name: e.first_name + " " + e.last_name,
            };
          }),

      currentUser: (state) => state.users.user,
    }),
    ...mapGetters("utility", ["getDistanceBetweenCitiesBulk"]),
    ...mapGetters("statuses", ["getleadStatuses"]),
    ...mapGetters("send_grid", ["getInvalidUsers", "getContactLists"]),
  },

  async created() {
    this.initialize();
  },

  methods: {
    ...mapActions("lead_users", {
      searchLeadUsersWithLeads: SEARCH_LEAD_USERS_WITH_LEADS,
      getLeadUserById: GET_LEAD_USER_BY_ID,
    }),
    ...mapActions("statuses", {
      getLeadStatuses: GET_LEAD_STATUSES,
    }),
    ...mapActions("users", { getUsers: GET_USERS }),
    ...mapMutations("lead_users", {
      setLeadUsersPagination: SET_LEAD_USERS_PAGINATION,
    }),
    ...mapActions("users", {
      updateEmployee: UPDATE_EMPLOYEE,
    }),
    ...mapActions("utility", {
      getDistanceByCitiesInBulk: GET_DISTANCE_BY_CITIES_IN_BULK,
    }),
    ...mapActions("send_grid", {
      sendContacts: SEND_CONTACTS,
      getAllContactLists: GET_ALL_CONTACT_LISTS,
      createContactList: CREATE_CONTACT_LIST,
    }),
    ...mapActions("hotlists", {
      getAllHotlists: GET_ALL_HOTLISTS,
    }),
    async initialize() {
      await this.getUsers();
      await this.getLeadStatuses();

      setTimeout(() => {
        if (this.currentUser.search_columns !== null) {
          this.selectedHeaders = JSON.parse(this.currentUser.search_columns);
        } else {
          let employee = this.currentUser;
          let empId = this.currentUser.id;
          employee.search_columns = JSON.stringify(this.selectedHeaders);
          this.updateEmployee({ employee, empId });
        }
      }, 100);
      this.drawer = false;
    },

    getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },

    inputChanged() {
      this.leadUsersLoaded = false;
      if (this.search !== "" && Object.keys(this.lead_users).length > 0) {
        this.leadUsersLoaded = true;
      }
    },

    ageFilter(value) {
      const foundAge = this.components.some((el) => el.selectedFilter === 1);

      if (this.components.length === 0) return true;
      if (foundAge) {
        if (value >= this.range[0] && value <= this.range[1]) {
          return value;
        }
      } else {
        return true;
      }
    },

    async addToSendGrid() {
      let pag = {
        itemsPerPage: -1,
      };
      this.storePaginationToState(pag);
      let lists = this.selectedLists;
      let users = [];

      for (let i = 0; i < this.filteredLeadUsers.length; i++) {
        let user = {
          first_name: this.filteredLeadUsers[i].first_name,
          last_name: this.filteredLeadUsers[i].last_name,
          email: this.filteredLeadUsers[i].email.trim(),
        };
        users.push(user);
      }
      let body = {
        users: users,
        lists: lists,
      };
      await this.sendContacts(body).then(() => {
        this.sendGridUploadOverview = true;
        let temp = {
          itemsPerPage: 20,
        };
        this.storePaginationToState(temp);
      });
      this.sendGridConfirmDialog = false;
    },

    async sendGridInfo() {
      let pag = {
        itemsPerPage: -1,
      };
      await this.storePaginationToState(pag);
      this.sendGridUsers = this.filteredLeadUsers.length;
      let temp = {
        itemsPerPage: 20,
      };
      this.storePaginationToState(temp);
      await this.getAllContactLists();
      this.sendGridConfirmDialog = true;
    },

    latestContactMomentDate(contactMoments) {
      if (contactMoments && contactMoments.length !== 0) {
        var date = contactMoments[contactMoments.length - 1].createdAt;
        return date.substring(0, 10);
      }
      return "Onbekend";
    },

    latestLead(leads) {
      if (leads) {
        return leads[leads.length - 1];
      }
      return {
        applied_date: "Onbekend",
        vacancy: { name: "Onbekend" },
        channel: { name: "Onbekend" },
      };
    },

    employeeName(employeeId) {
      for (var key in this.employees) {
        var obj = this.employees[key];
        if (obj.id == employeeId) {
          return obj.full_name;
        }
      }
      return "Onbekend";
    },

    recruiterFilter(value) {
      const foundRecruiter = this.components.some(
        (el) => el.selectedFilter === 3
      );

      if (this.components.length === 0) return true;
      if (foundRecruiter) {
        return value === parseInt(this.recruiterFieldValue);
      } else {
        return true;
      }
    },
    recAssFilter(value) {
      const foundRecruiterAss = this.components.some(
        (el) => el.selectedFilter === 4
      );

      if (this.components.length === 0) return true;
      if (foundRecruiterAss) {
        return value === parseInt(this.recAssFieldValue);
      } else {
        return true;
      }
    },

    genderFilter(value) {
      const found = this.components.some((el) => el.selectedFilter === 2);

      if (this.components.length === 0) return true;
      if (found) {
        return value.toLowerCase() === this.genderFieldValue.toLowerCase();
      } else {
        return true;
      }
    },

    languageFilter(value) {
      const found = this.components.some((el) => el.selectedFilter === 6);
      if (this.components.length === 0) return true;
      if (found) {
        if (this.languageFieldValue === "nl") {
          return value.some((elem) => elem.code === "nl");
        } else if (this.languageFieldValue === "en")
          return value.some((elem) => elem.code !== "nl");
      } else {
        return true;
      }
    },

    statusFilter(value) {
      const foundStatus = this.components.some((el) => el.selectedFilter === 5);

      if (this.components.length === 0) return true;

      if (!this.statusesFieldValues) {
        return true;
      }
      if (foundStatus) {
        return this.statusesFieldValues.some((elem) => elem.name === value);
      } else {
        return true;
      }
    },

    subStatusFilter(value) {
      const foundSubstatus = this.components.some(
        (el) => el.selectedFilter === 5
      );

      if (this.components.length === 0) return true;

      if (!foundSubstatus) {
        return true;
      }
      if (this.substatusesFieldValues.length <= 0) {
        return true;
      }
      for (let i = 0; i < this.substatusesFieldValues.length; i++) {
        for (let x = 0; x < value.length; x++) {
          if (
            this.substatusesFieldValues[i].name === value[x].sub_status.name
          ) {
            return true;
          }
        }
      }
    },

    setDescription(description) {
      this.description = description;
    },

    setPlace(addressData) {
      if (!addressData) return;
      this.vicinity = addressData.locality;
      this.distanceSearch(this.search);
    },

    setHeaders(e) {
      this.selectedHeaders = e;
    },

    clear() {
      this.leadUsersLoaded = false;
      this.search = "";
      this.vicinity = "";
      this.selectedRadius = 200000;
      this.filterDistance = false;
      this.components = [];
      this.genderFieldValue = "";
      this.recruiterFieldValue = "";
      this.recAssFieldValue = "";
    },

    change() {
      this.leadUsersLoaded = false;
      this.search = "";
    },

    add() {
      this.components.push({
        selectedFilter: null,
        filterBy: [
          {
            id: 1,
            desc: "Age",
          },
          {
            id: 2,
            desc: "Gender",
          },
          {
            id: 3,
            desc: "Recruiter",
          },
          {
            id: 4,
            desc: "Recruiter Assistant",
          },
          {
            id: 5,
            desc: "Statuses",
          },
          {
            id: 6,
            desc: "Language",
          },
        ],
      });
    },

    remove(index) {
      this.components.splice(index, 1);
    },

    removeStatus(item) {
      let index = -1;
      this.statusesFieldValues.find((status, i) => {
        if (status.name === item.name) {
          index = i;
        }
      });
      if (index >= 0) {
        this.statusesFieldValues.splice(index, 1);
      }
      this.substatusesFieldValues = [];
    },
    removeSubStatus(item) {
      let index = -1;
      this.substatusesFieldValues.find((status, i) => {
        if (status.name === item.name) {
          index = i;
        }
      });
      if (index >= 0) {
        this.substatusesFieldValues.splice(index, 1);
      }
    },

    filterSelect(index) {
      this.leadUsersLoaded = false;
      const { dispatch } = this.$store;
      for (let i = 0; i < this.components.length; i++) {
        if (this.components[i] !== this.components[index]) {
          if (
            this.components[i].selectedFilter ===
            this.components[index].selectedFilter
          ) {
            this.remove(index);
            dispatch("alerts/warn", "This filter is already selected");
          }
        }
      }
      if (this.search !== "" && Object.keys(this.lead_users).length > 0) {
        this.leadUsersLoaded = true;
      }
    },

    storePaginationToState(val) {
      this.setLeadUsersPagination(val);
    },

    itemExists(item) {
      if (item == null || item == "") return "Onbekend";
      return item;
    },

    openCv(item) {
      this.selectedUserId = item.id;
      this.getLeadUserById(item.id).then((res) => {
        this.selectedLeadUser = res.lead_user;
      });
      this.drawer = true;
    },

    async deepSearch(value) {
      this.disableSearchBtn = true;
      this.leadUsersLoaded = false;
      if (this.search !== "") {
        await this.searchLeadUsersWithLeads(value);
        //Calculating distance
        await this.distanceSearch(this.search);
        this.leadUsersLoaded = true;
      }
      this.disableSearchBtn = false;
    },

    async distanceSearch(value) {
      this.disableSearchBtn = true;
      this.leadUsersLoaded = false;

      if (this.search !== "") {
        //Calculating distance
        if (this.filterDistance && this.vicinity !== "") {
          let payload = [];
          for (let i = 0; i < this.leadsuserAdjustedList.length; i++) {
            let temp = {
              id: this.leadsuserAdjustedList[i].id,
              origin: this.vicinity,
              destination: this.getRegion(
                this.leadsuserAdjustedList[i].address
              ),
              distanceFromCity: this.leadsuserAdjustedList[i].distanceFromCity,
            };
            payload.push(temp);
          }

          // Split the payload into chunks
          const chunkSize = 200; // Adjust this to the maximum amount that your server can handle
          for (let i = 0; i < payload.length; i += chunkSize) {
            const chunk = payload.slice(i, i + chunkSize);

            console.log(chunk);
            // Send each chunk to the server separately
            await this.getDistanceByCitiesInBulk(chunk);

            // After getting the response, update the leadsuserAdjustedList
            for (let i = 0; i < this.leadsuserAdjustedList.length; i++) {
              for (
                let x = 0;
                x < this.getDistanceBetweenCitiesBulk.length;
                x++
              ) {
                if (
                  this.leadsuserAdjustedList[i].id ==
                  this.getDistanceBetweenCitiesBulk[x].id
                ) {
                  this.leadsuserAdjustedList[i].distanceFromCity =
                    this.getDistanceBetweenCitiesBulk[x].distanceFromCity;
                }
              }
            }
          }
        }
        this.leadUsersLoaded = true;
      }
      this.disableSearchBtn = false;
    },

    getRegion(e) {
      if (e !== null) {
        if (
          typeof JSON.parse(e).region !== "undefined" &&
          JSON.parse(e).region !== null
        ) {
          return JSON.parse(e).region.trim();
        }
      }
      return "";
    },

    convertMetersToKm(meters) {
      if (!this.filterDistance) {
        return "-";
      }
      if (meters == 0) {
        return "Same City";
      } else if (meters == 1) {
        return "Same City";
      } else if (meters == 199999) {
        return "-";
      }
      var km = meters / 1000;
      return km.toFixed(1) + " km";
    },

    excelExport() {
      this.excelExporting = true;
      let csvContent = "data:text/csv;charset=utf-8,";
      let headings = `id,first_name,last_name,tel_number,email,gender`;
      csvContent += headings + "\r\n";
      this.filteredLeadUsers.forEach((leadUser) => {
        let row = `${leadUser.id},${leadUser.first_name},${leadUser.last_name},tel:${leadUser.tel_number},${leadUser.email},${leadUser.gender}`;
        csvContent += row + "\r\n";
      });
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "cllbr_export.csv");
      document.body.appendChild(link);
      link.click();
      this.excelExporting = false;
    },

    getFiltered(e) {
      this.filteredLeadUsers = e;
    },

    subStatuses(item) {
      let temp = [];
      if (item.lead_statuses.length > 0) {
        item.lead_statuses.sort(function (a, b) {
          return a.id - b.id;
        });
        for (let i = 1; i <= item.lead_statuses.length; i++) {
          if (
            item.lead_statuses[item.lead_statuses.length - i]
              .lead_status_has_sub_statuses.length > 0
          ) {
            temp =
              item.lead_statuses[item.lead_statuses.length - i]
                .lead_status_has_sub_statuses;
          }
          return temp;
        }
      } else {
        return [];
      }
    },

    latestStatus(item) {
      if (item.lead_statuses.length > 0) {
        item.lead_statuses.sort(function (a, b) {
          return a.id - b.id;
        });
        return item.lead_statuses[item.lead_statuses.length - 1].status;
      } else {
        return { name: "Geen", color: "#FFD700" };
      }
    },

    calculateTextColor(bgColor) {
      var color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
      var r = parseInt(color.substring(0, 2), 16); // hexToR
      var g = parseInt(color.substring(2, 4), 16); // hexToG
      var b = parseInt(color.substring(4, 6), 16); // hexToB
      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#ffffff";
    },

    openNewTab(item) {
      let routeData = this.$router.resolve({
        path: `/profiel/${item.id}`,
      });
      window.open(routeData.href, "_blank");
    },
    reset() {
      this.addToHotlistDialogue = false;
      this.updateHotlistDialogue = false;
      this.selected = [];
      this.sendEmailDialogue = false;
      this.addToHotlistDialogue = false;
      this.selectedHotlist = null;
      this.selectHotlistDialogue = false;
      this.getAllHotlists();
    },
    mapCandidates(candidates) {
      //Maps the candidates to have first name, last name, id, email
      let mappedCandidates = [];
      candidates.forEach((element) => {
        mappedCandidates.push({
          id: element.id,
          first_name: element.first_name,
          last_name: element.last_name,
          email: element.email,
        });
      });
      return mappedCandidates;
    },
    // ! No longer needed
    // openVacancySelect() {
    //   //* Get only the id's of the selected lead_users
    //   for (let i = 0; i < this.selected.length; i++) {
    //     this.selectedIds.push(this.selected[i].id);
    //   }
    //   this.dialogVacancy = true;
    // },
  },
};
</script>

<style lang="scss">
.gmapField-light {
  width: 100%;
  border: 2px solid #aaa;
  border-radius: 5px;
  height: 38px;
  margin-top: 1px;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  transition: 0.3s;
}

.gmapField-light:focus {
  border-color: dodgerBlue;
  box-shadow: 0 0 2px 0 dodgerBlue;
}

.gmapField-dark {
  width: 100%;
  border: 2px solid rgb(245, 245, 245);
  color: white;
  border-radius: 5px;
  height: 38px;
  margin-top: 1px;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  transition: 0.3s;
}

.gmapField-dark:focus {
  border-color: dodgerBlue;
  box-shadow: 0 0 2px 0 dodgerBlue;
}

.pac-container {
  color: var(--v-text-base);
  background-color: var(--v-background-base);
  opacity: 100;
}
.pac-item {
  background: var(--v-background-base);
  color: var(--v-text-darken1);
  opacity: 100;
}

.pac-item:hover {
  background: var(--v-background-darken1);
}

.pac-item-query {
  color: var(--v-text-base);
}
.animatedBtn:hover {
  transform: scale(1.1);
}
</style>
