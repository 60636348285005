import config from "../../../config";
import { authHeader } from "../../utils/auth-header";
import { handleResponse } from "../../utils/api_functions";
import { logger } from "../../utils/logger";

import {
  CREATE_VACANCY,
  GET_VACANCIES,
  GET_VACANCIES_BY_COMPANY_ID,
  GET_VACANCIES_WITH_SEARCH_TERMS,
  GET_VACANCY_BY_ID,
  GET_VACANCY_STATUSES,
  UPDATE_VACANCY,
  ADD_APPLICATION_FORM_ID,
  CREATE_VACANCY_LEAD_USER,
  REMOVE_VACANCY_LEAD_USER,
} from "../action.type";
import {
  GET_VACANCIES_FAILED,
  GET_VACANCIES_REQUEST,
  GET_VACANCIES_SUCCESS,
  GET_VACANCIES_WITH_SEARCH_TERMS_SUCCESS,
  GET_VACANCY_STATUSES_SUCCESS,
  GET_VACANCY_SUCCESS,
  SET_VACANCY_PAGINATION,
} from "../mutation.type";

export const vacancy = {
  namespaced: true,
  state: {
    status: {},
    activeVacancy: null,
    vacancies: {},
    vacanciesWithSearchTerms: [],
    vacanciesWithTermsStatus: false,
    statuses: {},
    filter: {},
    pagination: {},
  },
  getters: {
    getVacanciesWithTermsStatus: (state) => state.vacanciesWithTermsStatus,
  },
  actions: {
    async [GET_VACANCIES]({ commit }) {
      try {
        commit(GET_VACANCIES_REQUEST);
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/vacancies`, request)
          .then(handleResponse)
          .then((res) => {
            commit(GET_VACANCIES_SUCCESS, res);
            return res;
          });
      } catch (error) {
        commit(GET_VACANCIES_FAILED, error);
        logger.error(error);
      }
    },
    async [CREATE_VACANCY](_, vacancy) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(vacancy),
        };
        return await fetch(`${config.apiUrl}/vacancies`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [GET_VACANCY_BY_ID]({ commit }, id) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/vacancies/${id}`, request)
          .then(handleResponse)
          .then((res) => {
            res.vacancy.address = JSON.parse(res.vacancy.address);
            res.vacancy.terms = JSON.parse(res.vacancy.terms);
            commit(GET_VACANCY_SUCCESS, res);
            return res;
          });
      } catch (error) {
        commit(GET_VACANCIES_FAILED, error);
        logger.error(error);
      }
    },

    async [GET_VACANCY_STATUSES]({ commit }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/vacancy-status`, request)
          .then(handleResponse)
          .then((res) => {
            commit(GET_VACANCY_STATUSES_SUCCESS, res);
            return res;
          });
      } catch (error) {
        commit(GET_VACANCIES_FAILED, error);
        logger.error(error);
      }
    },

    async [GET_VACANCIES_BY_COMPANY_ID]({ commit }, id) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return fetch(`${config.apiUrl}/vacancies/company/${id}`, request)
          .then(handleResponse)
          .then((res) => {
            commit(GET_VACANCIES_SUCCESS, res);
            return res;
          });
      } catch (error) {
        commit(GET_VACANCIES_FAILED, error);
        logger.error(error);
      }
    },
    async [GET_VACANCIES_WITH_SEARCH_TERMS]({ commit }, { params }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        await fetch(
          `${config.apiUrl}/vacanciesWithSearchTerms/?industries=${params.industries}&roles=${params.roles}&status=${params.status}&car=${params.car}&license=${params.license}&gender=${params.license}&age=${params.age}&id=${params.id}`,
          request
        )
          .then(handleResponse)
          .then((data) => {
            commit(GET_VACANCIES_WITH_SEARCH_TERMS_SUCCESS, data);
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [UPDATE_VACANCY](_, { vacancy, id }) {
      try {
        const request = {
          method: "PUT",
          headers: authHeader(),
          body: JSON.stringify(vacancy),
        };
        return fetch(`${config.apiUrl}/vacancies/${id}`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [ADD_APPLICATION_FORM_ID](_, appForm) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(appForm),
        };
        return await fetch(`${config.apiUrl}/vacancies/appForm`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [CREATE_VACANCY_LEAD_USER](_, data) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(data),
        };
        return await fetch(`${config.apiUrl}/vacancies-users`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [REMOVE_VACANCY_LEAD_USER](_, id) {
      try {
        const request = {
          method: "DELETE",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/vacancies-users/${id}`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
  },
  mutations: {
    [GET_VACANCIES_REQUEST](state) {
      state.status = { loading: true };
    },
    [GET_VACANCIES_SUCCESS](state, vacancies) {
      state.status = { loading: false };
      state.vacancies = vacancies;
    },
    [GET_VACANCIES_FAILED](state, error) {
      state.status = { loading: false };
      state.all = { error };
    },
    [GET_VACANCY_SUCCESS](state, vacancy) {
      state.status = { loading: false };
      state.activeVacancy = vacancy;
    },
    [GET_VACANCY_STATUSES_SUCCESS](state, statuses) {
      state.status = { loading: false };
      state.statuses = statuses;
    },
    [GET_VACANCIES_WITH_SEARCH_TERMS_SUCCESS](state, vacancies) {
      state.vacanciesWithSearchTerms = vacancies;
      state.vacanciesWithTermsStatus = true;
    },
    [SET_VACANCY_PAGINATION](state, pagination) {
      state.pagination = pagination;
    },
  },
};
