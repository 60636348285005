import config from "../../../config";
import { authHeader } from "../../utils/auth-header";
import { handleResponse } from "../../utils/api_functions";
import { logger } from "../../utils/logger";

import {
  CREATE_EMPLOYEE,
  GET_EMPLOYEE_BY_ID,
  GET_PERMISSIONS,
  GET_USER_PERMISSIONS,
  UPDATE_PERMISSIONS,
  GET_SELF,
  GET_USERS,
  UPDATE_EMPLOYEE,
  UPLOAD_PROFILE_PIC,
  GET_EMPLOYEE_PROFILE_PIC,
} from "../action.type";
import {
  GET_USERS_FAILED,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  SET_EMPLOYEE,
  SET_SELF,
} from "../mutation.type";

export const users = {
  namespaced: true,
  state: {
    employee: {},
    all: {},
    status: {
      loggedIn: false,
    },
  },
  getters: {
    getLoggedIn: (state) => state.status.loggedIn,
    getUser: (state) => state.user,
  },
  actions: {
    async [GET_USERS]({ commit }) {
      try {
        const requestOptions = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/employees`, requestOptions)
          .then(handleResponse)
          .then((res) => {
            commit(GET_USERS_SUCCESS, res);
            return res;
          });
      } catch (error) {
        commit(GET_USERS_FAILED, error);
        logger.error(error);
      }
    },
    async [GET_SELF]({ dispatch, commit }, oAuthID) {
      try {
        const requestOptions = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(
          `${config.apiUrl}/employees/self/` + oAuthID,
          requestOptions
        )
          .then(handleResponse)
          .then((res) => {
            addGroupsToStorage(res.employee.Notification_Groups);
            localStorage.setItem("userPerm", JSON.stringify(res));
            commit(SET_SELF, res.employee);
            return res.employee;
          });
      } catch (error) {
        commit(GET_USERS_FAILED, error);
        dispatch("alerts/error", "Authenticatie fout, aub even refreshen", {
          root: true,
        });
        logger.error(error);
      }
    },
    async [CREATE_EMPLOYEE](_, employee) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(employee),
        };
        return await fetch(`${config.apiUrl}/employees`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [UPDATE_EMPLOYEE](_, { employee, empId }) {
      try {
        const request = {
          method: "PUT",
          headers: authHeader(),
          body: JSON.stringify(employee),
        };
        return await fetch(`${config.apiUrl}/employees/${empId}`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [GET_EMPLOYEE_BY_ID]({ commit }, empId) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/employees/${empId}`, request)
          .then(handleResponse)
          .then((res) => {
            commit(SET_EMPLOYEE, res);
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [GET_PERMISSIONS](_) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/permissions`, request).then(
          handleResponse
        );
      } catch (error) {
        logger.error(error);
      }
    },
    async [GET_USER_PERMISSIONS](_, { empId }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(
          `${config.apiUrl}/permissions/${empId}`,
          request
        ).then(handleResponse);
      } catch (error) {
        logger.error(error);
      }
    },
    async [UPDATE_PERMISSIONS](_, { permArr, empId }) {
      try {
        console.log(permArr);
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(permArr),
        };
        return await fetch(
          `${config.apiUrl}/updatePermissions/${empId}`,
          request
        ).then(handleResponse);
      } catch (error) {
        logger.error(error);
      }
    },

    async [UPLOAD_PROFILE_PIC](_, { fileToUpload, uploadData }) {
      try {
        let alteredHeader = authHeader();
        delete alteredHeader["Content-Type"];
        const formData = new FormData();
        formData.append("file", fileToUpload);
        formData.append("data", JSON.stringify(uploadData));
        const request = {
          method: "PUT",
          headers: alteredHeader,
          body: formData,
        };
        return await fetch(`${config.apiUrl}/employees-file-upload`, request)
          .then(handleResponse)
          .then((response) => {
            return response;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [GET_EMPLOYEE_PROFILE_PIC]({ commit }, empId) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(
          `${config.apiUrl}/employees-profile-pic/${empId}`,
          request
        )
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
  },
  mutations: {
    [GET_USERS_REQUEST](state) {
      state.all = { loading: true };
    },
    [GET_USERS_SUCCESS](state, users) {
      state.all = { items: users };
    },
    [GET_USERS_FAILED](state, error) {
      state.all = { error };
    },
    [SET_SELF](state, user) {
      state.status = { loggedIn: true };
      state.user = user;
    },
    [SET_EMPLOYEE](state, employee) {
      state.employee = employee;
    },
  },
};

function addGroupsToStorage(groups) {
  var groupArr = [];
  if (groups) {
    for (const group of groups) {
      groupArr.push(group.name);
    }
  }
  localStorage.setItem("groups", JSON.stringify(groupArr));
}
