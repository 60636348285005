import config from "../../../config";
import { authHeader } from "../../utils/auth-header";
import { handleResponse } from "../../utils/api_functions";
import { logger } from "../../utils/logger";

import { GET_LEAD_PIPES, DELETE_LEAD_PIPE } from "../action.type";
import { SET_LEAD_PIPES, REMOVE_PIPE } from "../mutation.type";

export const lead_pipes = {
  namespaced: true,
  state: {
    lead_pipes: [],
  },
  getters: {},
  actions: {
    async [GET_LEAD_PIPES]({ commit }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(
          `${config.apiUrl}/leadpipes/getAllLeadPipes`,
          request
        )
          .then(handleResponse)
          .then((res) => {
            commit(SET_LEAD_PIPES, res);
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [DELETE_LEAD_PIPE]({ commit }, leadPipeId) {
      try {
        const request = {
          method: "DELETE",
          headers: authHeader(),
        };
        await fetch(
          `${config.apiUrl}/leadpipes/deleteLeadPipe/${leadPipeId}`,
          request
        ).then(handleResponse);
      } catch (error) {
        logger.error(error);
      }
    },
  },
  mutations: {
    [SET_LEAD_PIPES](state, items) {
      state.lead_pipes = items.leadPipes;
    },
  },
};
