<template>
  <v-card>
    <v-card-title class="text-h5"
      >Leads for {{ new Date().getFullYear() }}</v-card-title
    >
    <v-card-text>
      <apexchart
        v-if="series[0].data.length > 0"
        type="bar"
        :options="options"
        :series="series"
        height="235"
      ></apexchart>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
import { GET_DASHBOARD_FLOW_LEADS_PER_YEAR } from "../../store/action.type";
export default {
  name: "BarChartYearOverview",
  data() {
    return {
      leadsPerYear: [],

      options: {
        chart: {
          id: "basic-bar",
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
      },
      series: [
        {
          name: "Leads",
          data: [],
        },
      ],
    };
  },
  methods: {
    ...mapActions("flow", {
      getDashboardFlowLeads: GET_DASHBOARD_FLOW_LEADS_PER_YEAR,
    }),
    async init() {
      const currentYear = new Date().getFullYear();
      const year = currentYear;

      await this.getDashboardFlowLeads({ year }).then((res) => {
        //Instead of getting data for week I'm using this function to retrieve the data for the whole year, month by month
        this.leadsPerYear = res;
      });

      this.series[0].data = Object.values(this.leadsPerYear).map(
        (obj) => obj.leads.length
      );
      console.log(this.series[0].data);
    },
  },
  created() {
    this.init();
  },
};
</script>
