import config from "../../../config";
import { authHeader } from "../../utils/auth-header";
import { handleResponse } from "../../utils/api_functions";
import { logger } from "../../utils/logger";

import {
  CREATE_DEPARTMENT,
  CREATE_INDUSTRY,
  CREATE_INTEREST,
  CREATE_JOB,
  CREATE_ROLE,
  GET_DEPARTMENTS,
  GET_DEPARTMENT_BY_ID,
  GET_INDUSTRIES,
  GET_INDUSTRY_BY_ID,
  GET_INTERESTS,
  GET_INTERESTS_FOR_JOB,
  GET_INTERESTS_FOR_LEAD_USER,
  GET_JOBS,
  GET_JOB_BY_ID,
  GET_ROLES,
  GET_ROLE_BY_ID,
  UPDATE_DEPARTMENT,
  UPDATE_INDUSTRY,
  UPDATE_INTEREST,
  UPDATE_JOB,
  UPDATE_ROLE,
} from "../action.type";

export const interests = {
  namespaced: true,
  state: {
    status: {
      interests: false,
      industries: false,
      roles: false,
      jobs: false,
      departments: false,
    },
    interests: {},
    industries: {},
    roles: {},
    jobs: {},
    departments: {},
  },
  getters: {
    getLoadingStates: (state) => state.status,
    interests: (state) => state.interests,
    industries: (state) => state.industries,
    roles: (state) => state.roles,
    jobs: (state) => state.jobs,
    departments: (state) => state.departments,
  },
  actions: {
    async [GET_INTERESTS]({ state }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/interests`, request)
          .then(handleResponse)
          .then((res) => {
            state.status.interests = true;
            state.interests = res.interests;
            return res.interests;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [GET_INTERESTS_FOR_LEAD_USER]({ state }, leadUserId) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(
          `${config.apiUrl}/interests/leaduser/${leadUserId}`,
          request
        )
          .then(handleResponse)
          .then((res) => {
            state.status.interests = true;
            state.interests = res.interests;
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [GET_INTERESTS_FOR_JOB]({ state }, jobId) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/interests/job/${jobId}`, request)
          .then(handleResponse)
          .then((res) => {
            state.status.interests = true;
            state.interests = res.interests;
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [CREATE_INTEREST](_, interest) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(interest),
        };
        return await fetch(`${config.apiUrl}/interests`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [UPDATE_INTEREST](_, { interest, interestId }) {
      try {
        const request = {
          method: "PUT",
          headers: authHeader(),
          body: JSON.stringify(interest),
        };
        return fetch(`${config.apiUrl}/interests/${interestId}`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },

    // Departments
    async [GET_DEPARTMENTS]({ state }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/departments`, request)
          .then(handleResponse)
          .then((res) => {
            state.status.departments = true;
            state.departments = res.departments;
            return res.departments;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [GET_DEPARTMENT_BY_ID](_, departmentId) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(
          `${config.apiUrl}/departments/${departmentId}`,
          request
        )
          .then(handleResponse)
          .then((res) => {
            return res.department;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [CREATE_DEPARTMENT](_, department) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(department),
        };
        return fetch(`${config.apiUrl}/departments`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [UPDATE_DEPARTMENT](_, { department, departmentId }) {
      try {
        const request = {
          method: "PUT",
          headers: authHeader(),
          body: JSON.stringify(department),
        };
        return fetch(`${config.apiUrl}/departments/${departmentId}`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    // Roles
    async [GET_ROLES]({ state }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/roles`, request)
          .then(handleResponse)
          .then((res) => {
            state.roles = res.roles;
            state.status.roles = true;
            return res.roles;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [GET_ROLE_BY_ID](_, roleId) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/roles/${roleId}`, request)
          .then(handleResponse)
          .then((res) => {
            return res.role;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [CREATE_ROLE](_, role) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(role),
        };
        return await fetch(`${config.apiUrl}/roles`, request)
          .then(handleResponse)
          .then((res) => {
            return res.role;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [UPDATE_ROLE](_, { role, roleId }) {
      try {
        const request = {
          method: "PUT",
          headers: authHeader(),
          body: JSON.stringify(role),
        };
        return await fetch(`${config.apiUrl}/roles/${roleId}`, request)
          .then(handleResponse)
          .then((res) => {
            return res.role;
          });
      } catch (error) {
        logger.error(error);
      }
    },

    //Industries
    async [GET_INDUSTRIES]({ state }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/industries`, request)
          .then(handleResponse)
          .then((res) => {
            state.status.industries = true;
            state.industries = res.industries;
            return res.industries;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [GET_INDUSTRY_BY_ID](_, industryId) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/industries/${industryId}`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [CREATE_INDUSTRY](_, industry) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(industry),
        };
        return await fetch(`${config.apiUrl}/industries`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [UPDATE_INDUSTRY](_, { industry, industryId }) {
      try {
        const request = {
          method: "PUT",
          headers: authHeader(),
          body: JSON.stringify(industry),
        };
        return await fetch(`${config.apiUrl}/interests/${industryId}`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    //Jobs
    async [GET_JOBS]({ state }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/jobs`, request)
          .then(handleResponse)
          .then((res) => {
            state.status.jobs = true;
            state.jobs = res.jobs;
            return res.jobs;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [GET_JOB_BY_ID](_, jobId) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/jobs/${jobId}`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.log(error);
      }
    },
    async [CREATE_JOB](_, job) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(job),
        };
        return await fetch(`${config.apiUrl}/jobs`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [UPDATE_JOB](_, { job, jobId }) {
      try {
        const request = {
          method: "PUT",
          headers: authHeader(),
          body: JSON.stringify(job),
        };
        return await fetch(`${config.apiUrl}/jobs/${jobId}`, request)
          .then(handleResponse)
          .then((job) => {
            return job;
          });
      } catch (error) {
        logger.error(error);
      }
    },
  },
  mutations: {},
};
