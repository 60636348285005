import config from "../../../config";
import { authHeader } from "../../utils/auth-header";
import { handleResponse } from "../../utils/api_functions";
import { logger } from "../../utils/logger";

import {
  GET_DASHBOARD_FLOW_LEADS,
  GET_DASHBOARD_FLOW_LEADS_PER_YEAR,
  UPDATE_FLOW,
} from "../action.type";

export const flow = {
  namespaced: true,
  state: {},
  actions: {
    async [UPDATE_FLOW](_, { flow, leadId }) {
      try {
        const request = {
          method: "PUT",
          headers: authHeader(),
          body: JSON.stringify(flow),
        };
        return await fetch(`${config.apiUrl}/flow/${leadId}`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },

    async [GET_DASHBOARD_FLOW_LEADS](_, { week, year }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return fetch(`${config.apiUrl}/flow-dashboard/${week}/${year}`, request)
          .then(handleResponse)
          .then((weeks) => {
            return weeks;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [GET_DASHBOARD_FLOW_LEADS_PER_YEAR](_, { year }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return fetch(`${config.apiUrl}/flow-dashboard/${year}`, request)
          .then(handleResponse)
          .then((months) => {
            return months;
          });
      } catch (error) {
        logger.error(error);
      }
    },
  },
};
