import config from "../../../config";
import { authHeader } from "../../utils/auth-header";
import { handleResponse } from "../../utils/api_functions";
import { logger } from "../../utils/logger";

import { GENERATE_API_KEY, GET_API_USAGE } from "../action.type";
import { SET_API_KEY, SET_API_USAGE } from "../mutation.type";

export const external_api_keys = {
  namespaced: true,
  state: {
    api_key: null,
    api_usage: null,
  },
  getters: {
    getApiKey: (state) => state.api_key,
    getApiUsageData: (state) => state.api_usage,
  },

  actions: {
    async [GENERATE_API_KEY]({ dispatch, commit }, payload) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(payload),
        };
        await fetch(`${config.apiUrl}/createKey`, request)
          .then(handleResponse)
          .then((data) => {
            commit(SET_API_KEY, data);
          });
      } catch (error) {
        dispatch("alerts/error", "Generating Key Failed", { root: true });
        logger.error(error);
      }
    },
    async [GET_API_USAGE]({ dispatch, commit }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        await fetch(`${config.apiUrl}/getApiKeyUsage`, request)
          .then(handleResponse)
          .then((data) => {
            commit(SET_API_USAGE, data);
          });
      } catch (error) {
        dispatch("alerts/error", "Couldn't retrieve usage", { root: true });
        logger.error(error);
      }
    },
  },

  mutations: {
    [SET_API_KEY](state, payload) {
      state.api_key = payload;
    },
    [SET_API_USAGE](state, payload) {
      state.api_usage = payload.usage;
    },
  },
};
