<template>
  <v-card color="card">
    <v-dialog v-model="createTemplateDialog" width="650px">
      <v-card color="card">
        <CreateTemplate
          type="create"
          v-on:close-dialog="refreshTemplates()"
          :activeTemplate="template"
        >
        </CreateTemplate>
      </v-card>
    </v-dialog>
    <v-card-title class="justify-center">{{
      $t("create_vacancy_flow.title")
    }}</v-card-title>
    <v-form ref="vacCreateForm" v-model="formValidity">
      <v-container>
        <div class="requiredDiv px-3 pt-2">
          <h5>Verplicht</h5>
          <v-row class="py-0 my-0 mt-2">
            <v-col class="py-0 my-0">
              <v-text-field
                background-color="cardFields"
                outlined
                dense
                v-model="data_fields.name"
                :rules="fieldRules"
                :label="$t('create_vacancy_flow.name')"
                required
              ></v-text-field>
            </v-col>
            <v-col class="py-0 my-0">
              <v-autocomplete
                background-color="cardFields"
                outlined
                dense
                :rules="fieldRules"
                :label="$t('create_vacancy_flow.city')"
                required
                :items="cities"
                item-text="city"
                item-value="city"
                v-model="data_fields.city"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="py-0 my-0 mt-1">
            <v-col class="py-0 my-0">
              <v-select
                background-color="cardFields"
                outlined
                dense
                v-model="data_fields.status"
                :label="$t('create_vacancy_flow.status')"
                :rules="fieldRules"
                required
                :items="vacancy_statuses_getter"
                item-text="name"
                item-value="id"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0 my-0">
              <v-text-field
                background-color="cardFields"
                outlined
                dense
                :rules="workHoursRules"
                v-model="data_fields.minimumHours"
                :label="$t('create_vacancy_flow.min_work_hours')"
                type="number"
                required
                min="0"
              ></v-text-field>
            </v-col>
            <v-col class="py-0 my-0">
              <v-text-field
                background-color="cardFields"
                outlined
                dense
                :rules="workHoursRules"
                v-model="data_fields.maximumHours"
                :label="$t('create_vacancy_flow.max_work_hours')"
                type="number"
                required
                min="0"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
        <div class="optionalDiv px-3">
          <h5>Optioneel</h5>
          <v-row class="py-0 my-0 mt-2">
            <v-col class="py-0 my-0">
              <v-select
                background-color="cardFields"
                outlined
                dense
                v-model="data_fields.recruiter"
                :label="$t('create_vacancy_flow.recruiter')"
                :items="employees_getter"
                item-text="name"
                item-value="id"
              >
              </v-select>
            </v-col>
            <v-col class="py-0 my-0">
              <v-autocomplete
                background-color="cardFields"
                outlined
                dense
                v-model="data_fields.company"
                :label="$t('create_vacancy_flow.company')"
                :items="companies_getter"
                item-text="name"
                item-value="id"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="py-0 my-0 mt-1">
            <v-col class="py-0 my-0">
              <v-select
                background-color="cardFields"
                outlined
                dense
                v-model="data_fields.category"
                :label="$t('create_vacancy_flow.category')"
                :items="categories_getter"
                item-text="name"
                item-value="id"
              >
              </v-select>
            </v-col>
            <v-col class="py-0 my-0">
              <v-select
                background-color="cardFields"
                outlined
                dense
                v-model="data_fields.hiringType"
                :items="availableTypes"
                label="Hiring type"
                item-text="name"
                item-value="id"
              ></v-select>
            </v-col>
          </v-row>

          <v-row class="py-0 my-0 mt-1">
            <v-col class="py-0 my-0" md="10">
              <v-select
                background-color="cardFields"
                outlined
                dense
                label="Choose a Template"
                :items="templates_getter"
                @change="selectTemplate"
                v-model="selectedTemplate"
                item-text="name"
                item-value="id"
                return-object
              >
              </v-select>
            </v-col>
            <v-col class="pt-2">
              <v-btn
                class="formBtn mr-2"
                color="primary"
                icon
                small
                @click="resetSelection()"
              >
                <v-icon @click="resetSelection()">
                  mdi-selection-remove
                </v-icon>
              </v-btn>

              <v-btn
                class="formBtn"
                color="primary"
                icon
                small
                @click="createTemplate()"
              >
                <v-icon dark> mdi-plus </v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-textarea
            background-color="cardFields"
            outlined
            dense
            class="overflow-y-auto py-0 my-0"
            v-model="data_fields.jobDescription"
            label="Job Description"
            rows="4"
          ></v-textarea>
          <v-menu
            v-model="datepickerMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="data_fields.startDate"
                label="Start Date"
                prepend-icon="mdi-calendar"
                readonly
                background-color="cardFields"
                outlined
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="data_fields.startDate"
              @input="datepickerMenu = false"
            ></v-date-picker>
          </v-menu>
        </div>

        <v-row class="pa-3">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-fade-transition leave-absolute>
                <v-expansion-panel-header>
                  {{ $t("create_vacancy_flow.hoursPerDay") }}
                </v-expansion-panel-header>
              </v-fade-transition>
              <v-expansion-panel-content>
                <v-row
                  v-for="(value, key) in data_fields.hoursPerDay"
                  :key="key"
                >
                  <v-col> {{ key }}</v-col>
                  <v-text-field
                    background-color="cardFields"
                    outlined
                    dense
                    v-model="data_fields.hoursPerDay[key]"
                  ></v-text-field>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>

        <div>
          <v-row>
            <v-autocomplete
              background-color="cardFields"
              outlined
              dense
              class="pa-3"
              v-model="data_fields.language"
              :items="availableLanguages"
              :label="$t('create_vacancy_flow.language')"
              multiple
            />
          </v-row>
          <v-select
            background-color="cardFields"
            outlined
            dense
            v-model="data_fields.requiredExperience"
            :items="requiredExperienceOptions"
            :label="$t('create_vacancy_flow.requiredExperience')"
          />
          <v-text-field
            background-color="cardFields"
            outlined
            dense
            v-model="data_fields.hourlyRate"
            :label="$t('create_vacancy_flow.hourlyRate')"
          />
        </div>
        <v-row class="text-center pa-3">
          <v-col>
            <v-btn
              class="mr-4 formBtn"
              color="primary"
              @click="addVacancyFlow"
              :disabled="!formValidity"
            >
              {{ $t("create_vacancy_flow.submit") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  CREATE_VACANCY_FLOW,
  GET_ALL_TEMPLATES,
} from "../../../store/action.type";
import { cities } from "../../../utils/cities_nl";
import CreateTemplate from "./CreateTemplate.vue";
export default {
  data: () => ({
    createTemplateDialog: false,
    selectedTemplate: null,
    template: null,
    availableTypes: ["Direct Hiring", "Outsourcing"],
    availableLanguages: ["English", "Dutch"],
    datepickerMenu: false,
    requiredExperienceOptions: [
      {
        text: "Yes",
        value: true,
      },
      {
        text: "No",
        value: false,
      },
      {
        text: "N/A",
        value: null,
      },
    ],
    data_fields: {
      name: null,
      status: 2,
      company: null,
      city: null,
      recruiter: null,
      minimumHours: 0,
      maximumHours: 0,
      category: null,
      hiringType: null,
      jobDescription: null,
      startDate: null,
      hoursPerDay: {
        Monday: "",
        Tuesday: "",
        Wednesday: "",
        Thursday: "",
        Friday: "",
        Saturday: "",
        Sunday: "",
      },
      language: [],
      requiredExperience: null,
      hourlyRate: 0,
    },
    fieldRules: [(v) => !!v || "Field is required"],
    workHoursRules: [
      (v) => !!v || "Field is required",
      (v) => !Number.isInteger(v) || "Input needs to be a number",
    ],

    formValidity: false,
  }),
  computed: {
    ...mapGetters("vacancy_flow", ["categories_getter"]),
    ...mapGetters("filters", ["employees_getter", "companies_getter"]),
    ...mapGetters("statuses", ["vacancy_statuses_getter"]),
    ...mapGetters("vacancy_template", ["templates_getter"]),
    cities() {
      return cities;
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions("vacancy_flow", {
      createVacancyFlow: CREATE_VACANCY_FLOW,
    }),
    ...mapActions("vacancy_template", {
      getAllTemplates: GET_ALL_TEMPLATES,
    }),

    async init() {
      await this.getAllTemplates();
    },
    async addVacancyFlow() {
      const { dispatch } = this.$store;
      let vacancy_flow = {
        position: [],
        name: this.data_fields.name,
        status: this.data_fields.status,
        company: this.data_fields.company,
        city: this.data_fields.city,
        recruiter: this.data_fields.recruiter,
        minimumHours: this.data_fields.minimumHours,
        maximumHours: this.data_fields.maximumHours,
        category: this.data_fields.category,
        hiringType: this.data_fields.hiringType,
        jobDescription: this.data_fields.jobDescription,
        startDate: this.data_fields.startDate,
        hoursPerDay: [],
        language: [],
        requiredExperience: this.data_fields.requiredExperience,
        hourlyRate: this.data_fields.hourlyRate,
      };

      vacancy_flow.position = JSON.stringify(vacancy_flow.position);
      vacancy_flow.hoursPerDay = JSON.stringify(this.data_fields.hoursPerDay);
      vacancy_flow.language = JSON.stringify(this.data_fields.language);
      if (
        vacancy_flow.name &&
        vacancy_flow.status &&
        vacancy_flow.city &&
        vacancy_flow.minimumHours
      ) {
        await this.createVacancyFlow({ vacancy_flow }).then(() => {
          this.$emit("reloadTables");
          this.$emit("close-dialog");
          dispatch("alerts/success", "Vacancy flow created");
        });
        await this.$refs.vacCreateForm.reset();
        this.data_fields.status = 2;
        this.data_fields.minimumHours = 0;
        this.data_fields.maximumHours = 0;
      } else {
        dispatch(
          "alerts/error",
          "Not all of the necessary fields are filled properly"
        );
      }
    },
    selectTemplate(item) {
      this.data_fields.jobDescription = item.description;
    },
    resetSelection() {
      this.data_fields.jobDescription = null;
      this.selectedTemplate = null;
    },
    createTemplate() {
      this.template = {
        name: "",
        description: "",
      };
      this.createTemplateDialog = true;
    },
    refreshTemplates() {
      this.createTemplateDialog = false;
      this.getAllTemplates();
    },
  },
  components: { CreateTemplate },
};
</script>

<style scoped>
.formBtn:hover {
  transform: scale(1.1);
}
</style>
