/**
 * Extracts weekly statistics data for leads from an object of weekly data.
 *
 * @param {Object} weeks - An object of weekly data
 * @returns {Array} An array of weekly statistics data for leads
 */
export function extractWeeklyStats(weeks) {
  let weekData = [];
  // Array of day names
  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // Iterate over each week in the `weeks` object to calculate various statistics for the leads in each week.
  for (const [_, week] of Object.entries(weeks)) {
    // Create an object to hold the weekly statistics we will calculate.
    let weeklyStats = {
      weekNo: week.weekNo, // The number of the current week.
      leadsCount: week.leads.length, // The total number of leads in the current week.
      leadsCountPerDay: Array.from({ length: 5 }, (_, i) => ({
        day: dayNames[i + 1], // Get the day name using the day number as index
        count: 0,
      })),
      contactCount: 0, // The number of leads in the current week that have been contacted.
      intakeCount: 0, // The number of leads in the current week that have an intake appointment scheduled.
      intakedCount: 0, // The number of leads in the current week that have completed the intake process.
      placedCount: 0, // The number of leads in the current week that have been placed in a job.
      exitCount: 0, // The number of leads in the current week that have both been placed and have exited their job.
    };

    week.leads.forEach((lead) => {
      const createdAt = new Date(lead.createdAt);
      const dayOfWeek = createdAt.getDay(); // get the day of the week (0-6)
      if (dayOfWeek >= 1 && dayOfWeek <= 5) {
        const dayName = dayNames[dayOfWeek];
        const dayIndex = dayOfWeek - 1;
        weeklyStats.leadsCountPerDay[dayIndex].day = dayName;
        weeklyStats.leadsCountPerDay[dayIndex].count++;
      }
    });
    // Iterate over the leads in the current week to calculate the statistics for each lead.
    week.leads.forEach((lead) => {
      // Check the lead's flow status to determine which statistics to update.
      if (lead.flow?.placed_date != null && lead.flow?.exit_date != null) {
        // If the lead has been placed and has exited, increase the exitCount.
        return weeklyStats.exitCount++;
      }
      if (lead.flow?.placed_date != null && lead.flow?.intake_date != null) {
        // If the lead has been placed and has an intake appointment scheduled, increase the placedCount.
        return weeklyStats.placedCount++;
      }
      if (
        lead.flow?.intake_completed &&
        lead.flow?.intake_date != null &&
        lead.flow?.first_date != null
      ) {
        // If the lead has completed the intake process, increase the intakedCount.
        return weeklyStats.intakedCount++;
      }
      if (lead.flow?.intake_date != null) {
        // If the lead has an intake appointment scheduled, increase the intakeCount.
        return weeklyStats.intakeCount++;
      }
      if (lead.flow?.first_date != null) {
        // If the lead has been contacted, increase the contactCount.
        return weeklyStats.contactCount++;
      }
    });

    weekData.push(weeklyStats);
  }

  return weekData.reverse();
}
/**
 * Returns an array of the previous four weeks based on a given week number.
 *
 * @param {number} weekNum - The current week number
 * @returns {Array} An array of the previous four weeks
 */
export function getSocialMediaStats(socialMediaChannels, weeks) {
  let socialMediaStats = new Map();
  for (const [_, week] of Object.entries(weeks)) {
    for (let i = 0; i < socialMediaChannels.length; i++) {
      const channel = socialMediaChannels[i];
      let leadCounter = 0;
      week.leads.forEach((lead) => {
        if (lead.channel_id == channel.id) {
          leadCounter++;
        }
      });
      if (leadCounter > 0) {
        socialMediaStats.set(channel.name, leadCounter);
      }
    }
  }
  return socialMediaStats;
}

/**
 * Returns an array of the previous four weeks based on a given week number.
 *
 * @param {number} weekNum - The current week number
 * @returns {Array} An array of the previous four weeks
 */
export function getPreviousWeeks(weekNum) {
  let weeks = [];
  for (let i = weekNum; i > weekNum - 4; i--) {
    let j = i;
    if (i < 0) j = 52 - i;

    weeks.push("Week " + j);
  }
  return weeks.reverse();
}

export function getCurrentWeekNum(date = new Date()) {
  let firstJanuary = new Date(date.getFullYear(), 0, 1);
  let dayNr = Math.ceil((date - firstJanuary) / (24 * 60 * 60 * 1000));
  let weekNr = Math.ceil((dayNr + firstJanuary.getDay()) / 7);
  return weekNr;
}

/**
 * Returns an array of the number of new leads per week.
 *
 * @param {Array} weekData - An array of weekly statistics data for leads
 * @returns {Array} An array of the number of new leads per week
 */
export function getNewLeadsPerWeek(weekData) {
  let newLeads = [];
  for (let i = 0; i < weekData.length; i++) {
    newLeads.push(weekData[i].leadsCount);
  }

  return newLeads;
}
/**
 * Returns an array of the number of new leads per week + day.
 *
 * @param {Array} weekData - An array of weekly statistics data for leads
 * @returns {Array} An array of the number of new leads per week
 */
export function getDetailedNewLeadsPerWeek(weekData) {
  let newLeads = [];
  weekData.forEach((week) => {
    let temp = {
      leadsCount: week.leadsCount,
      leadsCountPerDay: week.leadsCountPerDay,
    };
    newLeads.push(temp);
  });
  return newLeads;
}
/**
 * Returns an array of the number of leads contacted per week.
 *
 * @param {Array} weekData - An array of weekly statistics data for leads
 * @returns {Array} An array of the number of leads contacted per week
 */
export function getContactedPerWeek(weekData) {
  let contacted = [];
  for (let i = 0; i < weekData.length; i++) {
    contacted.push(weekData[i].contactCount);
  }
  return contacted;
}
/**
 * Returns an array of the number of leads that went through intake per week.
 *
 * @param {Array} weekData - An array of weekly statistics data for leads
 * @returns {Array} An array of the number of leads that went through intake per week
 */
export function getIntakePerWeek(weekData) {
  let intake = [];
  weekData.forEach((week) => {
    intake.push(week.intakeCount);
  });
  return intake;
}
/**
 * Returns an array of the number of leads that have been intaked per week.
 *
 * @param {Array} weekData - An array of weekly statistics data for leads
 * @returns {Array} An array of the number of leads that have been intaked per week
 */
export function getIntakedPerWeek(weekData) {
  let intaked = [];
  for (let i = 0; i < weekData.length; i++) {
    intaked.push(weekData[i].intakedCount);
  }
  return intaked;
}

/**
 * Returns an array of the number of leads that have been placed per week.
 *
 * @param {Array} weekData - An array of weekly statistics data for leads
 * @returns {Array} An array of the number of leads that have been placed per week
 */
export function getPlacedPerWeek(weekData) {
  let placed = [];
  for (let i = 0; i < weekData.length; i++) {
    placed.push(weekData[i].placedCount);
  }
  return placed;
}

/**
 * Returns an array of the number of leads that have exited per week.
 *
 * @param {Array} weekData - An array of weekly statistics data for leads
 * @returns {Array} An array of the number of leads that have exited per week
 */
export function getExitedPerWeek(weekData) {
  let exited = [];
  weekData.forEach((week) => {
    exited.push(week.exitCount);
  });
  return exited;
}
/**
 * Returns two dates, one for the start of the current period and one for the end of the current period. Period is 4 weeks, which is 28 days.
 *
 * @returns {Object} An object containing the start and end dates of the current period
 */
export function getCurrentPeriodDates() {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const day = today.getDate();

  const contactEndDate = `${year}-${month}-${day}`;

  // Subtract 28 days from the current date
  const startDate = new Date(today.getTime() - 28 * 24 * 60 * 60 * 1000);
  const startYear = startDate.getFullYear();
  const startMonth = startDate.getMonth() + 1;
  const startDay = startDate.getDate();

  const contactStartDate = `${startYear}-${startMonth}-${startDay}`;

  return { contactStartDate, contactEndDate };
}
