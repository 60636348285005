<template>
  <v-card class="pa-8 d-flex justify-end align-center">
    <v-select
      class="pr-4"
      v-model="selectedHeaders"
      :items="this.headers.filter(nonPerma)"
      :label="$t('select_columns.select_column')"
      multiple
      item-text="text"
      item-value="id"
      @change="headersChanged($event)"
    >
      <template v-slot:selection="{ item, index }">
        <v-chip label v-if="index === 0">
          <span>{{ item.text }}</span>
        </v-chip>
        <span v-if="index === 1" class="grey--text caption"
          >(+{{ selectedHeaders.length - 1 }}
          {{ $t("select_columns.others") }})</span
        >
      </template>
    </v-select>
    <v-btn class="ml-2 mr-2" color="primary" @click="saveColumnPreferences">{{
      $t("select_columns.save")
    }}</v-btn>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { UPDATE_EMPLOYEE } from "../../store/action.type";
export default {
  props: {
    headers: Array,
    type: String,
  },
  data: () => ({
    selectedHeaders: [],
  }),
  computed: {
    ...mapState({
      currentUser: (state) => state.users.user,
    }),
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions("users", {
      updateEmployee: UPDATE_EMPLOYEE,
    }),
    initialize() {
      switch (this.type) {
        case "Leads":
          {
            if (this.currentUser.lead_columns !== null) {
              this.selectedHeaders = JSON.parse(this.currentUser.lead_columns);
            }
          }
          break;
        case "Search":
          {
            if (this.currentUser.search_columns !== null) {
              this.selectedHeaders = JSON.parse(
                this.currentUser.search_columns
              );
            }
          }
          break;
        case "Vacancy":
          {
            if (this.currentUser.vacancy_columns !== null) {
              this.selectedHeaders = JSON.parse(
                this.currentUser.vacancy_columns
              );
            }
          }
          break;
        case "VacancyFlow":
          {
            if (this.currentUser.vacancy_flow_columns !== null) {
              this.selectedHeaders = JSON.parse(
                this.currentUser.vacancy_flow_columns
              );
            }
          }
          break;
        case "ProfileRecommend":
          {
            if (this.currentUser.profile_columns !== null) {
              this.selectedHeaders = JSON.parse(
                this.currentUser.profile_columns
              );
            }
          }
          break;
        case "VacancyShortList":
          {
            if (this.currentUser.vacancy_shortlist_columns !== null) {
              this.selectedHeaders = JSON.parse(
                this.currentUser.vacancy_shortlist_columns
              );
            }
          }
          break;
        case "Candidates":
          {
            if (this.currentUser.candidate_columns !== null) {
              this.selectedHeaders = JSON.parse(
                this.currentUser.candidate_columns
              );
            }
          }
          break;
      }
    },

    async saveColumnPreferences() {
      let employee = this.currentUser;
      const empId = this.currentUser.id;
      const { dispatch } = this.$store;
      switch (this.type) {
        case "Leads":
          {
            employee.lead_columns = JSON.stringify(this.selectedHeaders);
          }
          break;
        case "Search":
          {
            employee.search_columns = JSON.stringify(this.selectedHeaders);
          }
          break;
        case "Vacancy":
          {
            employee.vacancy_columns = JSON.stringify(this.selectedHeaders);
          }
          break;
        case "VacancyFlow":
          {
            employee.vacancy_flow_columns = JSON.stringify(
              this.selectedHeaders
            );
          }
          break;
        case "ProfileRecommend":
          {
            employee.profile_columns = JSON.stringify(this.selectedHeaders);
          }
          break;
        case "VacancyShortList":
          {
            employee.vacancy_shortlist_columns = JSON.stringify(
              this.selectedHeaders
            );
          }
          break;
        case "Candidates":
          {
            employee.candidate_columns = JSON.stringify(this.selectedHeaders);
          }
          break;
      }
      await this.updateEmployee({ employee, empId }).then(() => {
        dispatch("alerts/success", this.$t("select_columns.saved"));
        this.$emit("close-dialog");
        this.dialogColumns = false;
      });
    },

    nonPerma(header) {
      if (header.perma) {
        return false;
      } else if (header.hide) {
        return false;
      }
      return true;
    },

    headersChanged(e) {
      this.$emit("headers-changed", e);
    },
  },
};
</script>
