import config from "../../../config";
import { authHeader } from "../../utils/auth-header";
import { handleResponse } from "../../utils/api_functions";
import { logger } from "../../utils/logger";

import {
  GET_ALL_EMAIL_TEMPLATES,
  CREATE_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE,
  DELETE_EMAIL_TEMPLATE,
} from "../action.type";
import { SET_EMAIL_TEMPLATES } from "../mutation.type";

export const email_template = {
  namespaced: true,
  state: { email_templates: [] },
  getters: {
    email_templates_getter: (state) => state.email_templates,
  },
  actions: {
    async [GET_ALL_EMAIL_TEMPLATES]({ commit }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/get_all_email_templates`, request)
          .then(handleResponse)
          .then((res) => {
            commit(SET_EMAIL_TEMPLATES, res.templates);
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [UPDATE_EMAIL_TEMPLATE]({ commit }, { email_template, id }) {
      try {
        const request = {
          method: "PUT",
          headers: authHeader(),
          body: JSON.stringify(email_template),
        };
        return await fetch(
          `${config.apiUrl}/update_email_template/${id}`,
          request
        ).then(handleResponse);
      } catch (error) {
        logger.error(error);
      }
    },
    async [CREATE_EMAIL_TEMPLATE]({ commit }, { email_template }) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(email_template),
        };
        return await fetch(
          `${config.apiUrl}/create_email_template/`,
          request
        ).then(handleResponse);
      } catch (error) {
        logger.error(error);
      }
    },
    async [DELETE_EMAIL_TEMPLATE]({ commit }, { id }) {
      try {
        const request = {
          method: "DELETE",
          headers: authHeader(),
        };
        return await fetch(
          `${config.apiUrl}/delete_email_template/${id}`,
          request
        ).then(handleResponse);
      } catch (error) {
        logger.error(error);
      }
    },
  },

  mutations: {
    [SET_EMAIL_TEMPLATES](state, email_templates) {
      state.email_templates = email_templates;
    },
  },
};
