import config from "../../../config";
import { authHeader } from "../../utils/auth-header";
import { handleResponse } from "../../utils/api_functions";
import { logger } from "../../utils/logger";

import {
  GET_DISTANCE_BY_CITIES_IN_BULK,
  GET_DOCUMENT_BY_ID,
  GET_LOGS,
  DELETE_LOG,
  PARSE_RESUME,
  REPORT_BUG,
  REQUEST_FEATURE,
  DELETE_ALL_LOGS,
  UPDATE_LEAD_USER_FROM_CV,
} from "../action.type";
import {
  SAVE_DOCUMENT,
  SET_DISTANCE_BETWEEN_CITIES,
  SET_ERROR_LOGS,
  RESET_ERROR_LOGS,
} from "../mutation.type";

export const utility = {
  namespaced: true,
  state: {
    document: null,
    distanceBetweenCitiesBulk: [],
    errorLogs: [],
  },
  getters: {
    getDocument: (state) => state.document,
    getDistanceBetweenCitiesBulk: (state) => state.distanceBetweenCitiesBulk,
    getAllErrorLogs: (state) => state.errorLogs,
  },

  actions: {
    async [REQUEST_FEATURE](_, data) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(data),
        };
        return await fetch(`${config.apiUrl}/utility/featurereq`, request)
          .then(handleResponse)
          .then((response) => {
            return response;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [REPORT_BUG](_, data) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(data),
        };
        return await fetch(`${config.apiUrl}/utility/bugreq`, request)
          .then(handleResponse)
          .then((response) => {
            return response;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [PARSE_RESUME]({ dispatch }, payload) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(payload),
        };
        await fetch(`${config.apiUrl}/utility/parseRes`, request).then(
          handleResponse
        );
        dispatch("alerts/success", "CV Parsed", { root: true });
      } catch (error) {
        dispatch("alerts/error", "The file is invalid", { root: true });
        logger.error(error);
      }
    },
    async [UPDATE_LEAD_USER_FROM_CV](_, userId) {
      try {
        const request = {
          method: "PUT",
          headers: authHeader(),
        };
        await fetch(`${config.apiUrl}/utility/fillInData/${userId}`, request)
          .then(handleResponse)
          .then((res) => {
            return res;
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [GET_DOCUMENT_BY_ID]({ commit }, docId) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        await fetch(`${config.apiUrl}/utility/${docId}`, request)
          .then(handleResponse)
          .then((data) => {
            commit(SAVE_DOCUMENT, data);
          });
        // axios.defaults.headers.common["Authorization"] = `${
        //   authHeader().Authorization
        // }`;
        // const { data } = await axios.get(`${config.apiUrl}/utility/${docId}`);
        // commit(SAVE_DOCUMENT, data);
      } catch (error) {
        logger.error(error);
      }
    },

    async [GET_DISTANCE_BY_CITIES_IN_BULK]({ commit }, payload) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(payload),
        };
        await fetch(
          `${config.apiUrl}/utility/cities/getDistanceInBulk`,
          request
        )
          .then(handleResponse)
          .then((res) => {
            commit(SET_DISTANCE_BETWEEN_CITIES, res.data);
          });
      } catch (error) {
        logger.error(error);
      }
    },

    async [GET_LOGS]({ commit }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        await fetch(`${config.apiUrl}/client-logger/getAll`, request)
          .then(handleResponse)
          .then((res) => {
            commit(SET_ERROR_LOGS, res.logs);
          });
      } catch (error) {
        logger.error(error);
      }
    },
    // Delete Client Log
    async [DELETE_LOG]({ commit }, logID) {
      try {
        const request = {
          method: "DELETE",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/client-logger/${logID}`, request)
          .then(handleResponse)
          .then((res) => {
            commit(DELETE_LOG, logID);
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [DELETE_ALL_LOGS]({ commit }) {
      try {
        const request = {
          method: "DELETE",
          headers: authHeader(),
        };
        return await fetch(`${config.apiUrl}/client-logger/delete/all`, request)
          .then(handleResponse)
          .then((res) => {
            commit(RESET_ERROR_LOGS);
          });
      } catch (error) {
        logger.error(error);
      }
    },
  },
  mutations: {
    [SAVE_DOCUMENT](state, payload) {
      state.document = payload;
    },
    [SET_DISTANCE_BETWEEN_CITIES](state, payload) {
      state.distanceBetweenCitiesBulk = payload;
    },
    [SET_ERROR_LOGS](state, payload) {
      state.errorLogs = payload;
    },
    [DELETE_LOG](state, id) {
      const index = state.errorLogs.findIndex((log) => log.id == id);
      state.errorLogs.splice(index, 1);
    },
    [RESET_ERROR_LOGS](state) {
      state.errorLogs = [];
    },
  },
};
