<template>
  <v-container fluid>
    <v-card class="pa-4">
      <v-row>
        <v-col md="8">
          <v-row class="pa-3">
            <h2>Open To Work Candidates</h2>
            <v-divider
              vertical
              inset
              style="padding-left: 10px; padding-left: 10px"
            ></v-divider>
            <v-text-field
              style="padding-top: 0px; padding-left: 10px"
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('common_phrases.search')"
              single-line
              hide-details
            ></v-text-field>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        ><v-col>
          <v-data-table
            :footer-props="{ itemsPerPageOptions: [20, 35, 50, -1] }"
            :headers="headers"
            class="elevation-1 pointerOnHover"
            :items="whatsapp_survey_users_getter"
            :search="search"
            @click:row="openCandidate"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                @click.stop="remove(item.lead_user.id)"
                icon
                large
                color="red"
                class="white--text"
              >
                <v-icon>mdi-delete</v-icon></v-btn
              >
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  GET_ALL_WHATSAPP_SURVEY_USERS,
  DELETE_WHATSAPP_SURVEY_USER,
} from "../store/action.type";

export default {
  data: () => ({
    sendEmailDialogue: false,
    leadUsersLoaded: false,
    dialogCreate: false,
    search: "",
    collapsed: 0,
    car: ["Ja", "Nee"],
    genders: ["Man", "Vrouw", "Transgender", "Non-binair"],
    contract_terms: ["Flexibel", "Vast"],
    contract_types: ["Fulltime", "Parttime", "Fulltime/Parttime", "Bijbaan"],
    filters: {},
    selectedRow: null,
    showMenu: false,
    x: 0,
    y: 0,
  }),
  computed: {
    ...mapGetters("whatsapp_survey_users", ["whatsapp_survey_users_getter"]),
    headers() {
      return [
        {
          text: this.$t("candidates_page.name"),
          align: "start",
          sortable: true,
          value: "lead_user.first_name",
        },
        {
          text: this.$t("candidates_page.gender"),
          sortable: true,
          value: "lead_user.gender",
        },
        {
          text: this.$t("candidates_page.tel"),
          sortable: true,
          value: "lead_user.tel_number",
        },
        {
          text: this.$t("candidates_page.place"),
          sortable: true,
          value: "lead_user.region",
        },
        {
          text: this.$t("candidates_page.contract_term"),
          sortable: true,
          value: "lead_user.contract_term",
        },
        {
          text: this.$t("candidates_page.job_scope"),
          sortable: true,
          value: "lead_user.contract_type",
        },
        {
          text: this.$t("candidates_page.salary_indication"),
          sortable: true,
          value: "lead_user.sal_indication",
        },
        { title: "Actions", value: "actions", sortable: false },
      ];
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions("whatsapp_survey_users", {
      getWhatsAppSurveyUsers: GET_ALL_WHATSAPP_SURVEY_USERS,
      deleteWhatsAppSurveyUsers: DELETE_WHATSAPP_SURVEY_USER,
    }),
    async init() {
      await this.getWhatsAppSurveyUsers();
    },
    async remove(id) {
      await this.deleteWhatsAppSurveyUsers(id);
      await this.getWhatsAppSurveyUsers();
    },
    openCandidate(item) {
      this.$router.push(`/profiel/${item.lead_user.id}`);
    },
  },
};
</script>
