<template>
  <v-col xl="3" lg="4" md="6" sm="12">
    <v-container fluid class="pl-0 pr-0">
      <v-card min-height="380" max-width="475">
        <v-row :style="cardHeaders" class="ma-0 py-0">
          <v-row class="pa-5">
            <v-col md="9">
              <v-row>
                <v-icon medium>mdi-chat</v-icon>
                <h3>
                  <strong>CLLBR-Chat</strong>
                </h3>
              </v-row>
            </v-col>
            <v-col md="3" align="end" class="pa-0">
              <v-btn
                height="20px"
                icon
                dense
                class="mr-2"
                @click="reloadMessages()"
              >
                <v-icon>mdi-cached</v-icon>
              </v-btn>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    medium
                    color="primary"
                    @click="selectTemplate()"
                    >mdi-plus-box</v-icon
                  >
                </template>
                <span>Start New Conversation</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-row>
        <v-row v-if="loaded" class="my-0 py-0">
          <v-col
            v-if="lead_user.conversation_id != null"
            class="pa-0 pl-3 pr-3"
          >
            <WhatsAppSection
              :conversation_id="this.lead.conversation_id"
              :phone="this.lead.tel_number"
            ></WhatsAppSection>
          </v-col>
          <v-col v-else>
            <v-card elevation="0" class="text-center">
              <v-row style="height: 80px">
                <v-list-item
                  class="grow justify-center"
                  style="margin-top: 50px"
                >
                  <div class="justify-center">
                    <v-list-item-content>
                      <h1 class="headline" style="color: #009ee3">
                        No Messages
                      </h1>
                      <div>Send a message to start a conversations</div>
                    </v-list-item-content>
                  </div>
                </v-list-item>
              </v-row>
            </v-card>
            <div
              style="position: absolute; bottom: 0; width: 100%; height: 50px"
              :class="darkTheme ? 'divDark' : 'divLight'"
              class="d-flex align-center"
            >
              <v-row class="mx-2 d-flex align-center">
                <v-menu
                  v-model="emojiDialog"
                  top
                  :offset-y="true"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }"
                    ><v-btn icon dense v-bind="attrs" v-on="on">
                      <v-icon dark>mdi-emoticon</v-icon>
                    </v-btn>
                  </template>
                  <v-card><VEmojiPicker @select="selectEmoji" /></v-card
                ></v-menu>
                <v-text-field
                  clear-icon="mdi-close-circle"
                  filled
                  hide-details
                  single-line
                  solo
                  flat
                  dense
                  :background-color="
                    darkTheme ? 'grey darken-3' : 'grey lighten-2'
                  "
                  append-outer-icon="mdi-send"
                  color="#009EE3"
                  v-model="messageText"
                  class="my-3"
                  placeholder="Enter your message"
                  @click:append-outer="createNewConversation"
                  @keyup.enter="createNewConversation"
                />
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-col>
</template>

<script>
import WhatsAppSection from "../../WhatsApp/WhatsAppSection.vue";
import { VEmojiPicker } from "v-emoji-picker";
import { mapActions } from "vuex";
import {
  CREATE_CONVERSATION,
  REFETCH_MESSAGES,
  GET_LEAD_USER_BY_ID,
} from "../../../store/action.type";
export default {
  props: ["lead", "cardHeaders", "loaded"],
  components: {
    WhatsAppSection,
  },
  data() {
    return {
      lead_user: this.lead,
      emojiDialog: false,
      messageText: "",
      params: [],
      languages: [
        {
          name: "English",
          value: "en",
        },
        {
          name: "Dutch",
          value: "nl",
        },
      ],
      temp_lang: "en",
    };
  },
  computed: {
    darkTheme() {
      return this.$vuetify.theme.dark;
    },
  },
  methods: {
    ...mapActions("whats_app", {
      createConversation: CREATE_CONVERSATION,
      refetchMessages: REFETCH_MESSAGES,
    }),
    ...mapActions("lead_users", {
      getLeadUserById: GET_LEAD_USER_BY_ID,
    }),
    async createNewConversation() {
      if (this.messageText == "") {
        return;
      }
      const pattern = /^\+(?:[0-9]●?){6,14}[0-9]$/;
      let isValid = pattern.test(this.lead.tel_number);
      if (
        this.lead.tel_number == "" ||
        this.lead.tel_number == undefined ||
        this.lead.tel_number == null ||
        !isValid
      ) {
        dispatch("alerts/error", "Phone number is not valid");
        return;
      }
      const { dispatch } = this.$store;
      let params = [];
      let obj = {
        default: this.messageText,
      };
      params.push(obj);
      let data = {
        message: {
          namespace: "6bf1cd4f_1084_4310_b5de_3c6533b9c158",
          templateName: "job_follow_up_2",
          language: {
            policy: "deterministic",
            code: "en",
          },
          params: params,
        },
        number: this.lead.tel_number,
      };
      await this.createConversation(data);
      let userId = this.$route.params.id;
      await this.getLeadUserById(userId).then((res) => {
        this.lead_user = res.lead_user;
      });
      dispatch("alerts/success", "Conversation Created");
      this.reloadMessages();
      this.messageText = "";
    },
    reloadMessages() {
      this.refetchMessages();
    },
    selectEmoji(emoji) {
      this.messageText += emoji.data;
    },
  },
};
</script>
