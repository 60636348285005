<template>
  <v-container fluid>
    <v-dialog v-model="dialogCreate" max-width="600px">
      <v-card>
        <CreateCompany
          v-on:close-dialog="dialogCreate = false"
          type="0"
        ></CreateCompany>
      </v-card>
    </v-dialog>
    <v-card class="pa-4" :loading="!companiesLoaded">
      <v-row>
        <v-col md="8">
          <v-row class="pa-3">
            <h2>{{ $t("companies_page.companies") }}</h2>
            <v-divider
              vertical
              inset
              style="padding-left: 10px; padding-left: 10px"
            ></v-divider>
            <v-text-field
              style="padding-top: 0px; padding-left: 10px"
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('common_phrases.search')"
              single-line
              hide-details
            ></v-text-field>
          </v-row>
        </v-col>
        <v-col align="end" md="4">
          <v-btn icon color="primary" class="mb-2" @click="dialogCreate = true">
            <v-icon dark> mdi-plus-box </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="pl-3 pb-3 mb-4">
        <h2>{{ $t("common_phrases.filter") }}</h2>
        <v-divider
          vertical
          inset
          style="padding-left: 10px; padding-left: 10px"
        ></v-divider>
        <v-btn class="ml-2" color="primary" @click="filterAgency('DOEN')">{{
          $t("companies_page.doen")
        }}</v-btn>
        <v-btn class="ml-2" color="primary" @click="filterAgency('Zoomer')">{{
          $t("companies_page.zoomer")
        }}</v-btn>
      </v-row>

      <v-menu
        v-model="showMenu"
        :position-x="x"
        :position-y="y"
        absolute
        offset-y
      >
        <v-list dense>
          <v-list-item @click="open" dense>
            <v-list-item-title>{{
              $t("companies_page.open")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="openNewTab" dense>
            <v-list-item-title>{{
              $t("companies_page.new_tab")
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-data-table
        v-if="companiesLoaded"
        :page.sync="pagination.page"
        :items-per-page="pagination.itemsPerPage"
        :footer-props="{ itemsPerPageOptions: [20, 35, 50, -1] }"
        :headers="headers"
        :items="companies"
        :search="search"
        sort-by="name"
        class="elevation-1 pointerOnHover"
        @click:row="editItem"
        @contextmenu:row="show"
        @pagination="storePaginationToState"
      >
        <template v-slot:[`item.platform_name`]="{ item }">
          <v-img
            v-if="item.platform_name === 'DOEN'"
            max-width="50"
            src="../assets/doen.png"
          ></v-img>
          <v-img
            class="mb-1"
            v-if="item.platform_name === 'Zoomer'"
            max-width="50"
            src="../assets/zoomer.png"
          ></v-img>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import {
  GET_COMPANIES,
  GET_COMPANIES_WITH_PLATFORM_NAME,
} from "../store/action.type";
import CreateCompany from "../components/dialogs/CreateCompany";
import { mapState, mapActions } from "vuex";
import { SET_COMPANY_PAGINATION } from "../store/mutation.type";

export default {
  components: { CreateCompany },
  data: () => ({
    companiesLoaded: false,
    activeCompanyPositions: [],
    dialog: false,
    dialogCreate: false,
    search: "",
    editedIndex: -1,
    selectedRow: null,
    showMenu: false,
    x: 0,
    y: 0,
  }),

  computed: {
    headers() {
      return [
        {
          text: this.$t("companies_page.platform"),
          align: "start",
          value: "platform_name",
        },
        {
          text: this.$t("companies_page.name"),
          align: "start",
          value: "name",
        },
        { text: this.$t("companies_page.kvk"), value: "kvk" },
        { text: this.$t("companies_page.tel"), value: "phone" },
        { text: this.$t("companies_page.region"), value: "region" },
        { text: this.$t("companies_page.website"), value: "site_url" },
      ];
    },
    ...mapState({
      companies: (state) => state.company.companies,

      pagination: (state) => state.company.pagination,
    }),
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions("company", {
      getCompanies: GET_COMPANIES,
      getCompaniesWithPlatformName: GET_COMPANIES_WITH_PLATFORM_NAME,
    }),
    ...mapState("company", { setCompanyPagination: SET_COMPANY_PAGINATION }),
    async initialize() {
      await this.getCompanies().then(() => {
        this.companiesLoaded = true;
      });
    },

    async editItem(item) {
      this.$router.push(`/bedrijf/${item.id}`);
    },

    open() {
      this.$router.push(`/bedrijf/${this.selectedRow.id}`);
    },

    openNewTab() {
      let routeData = this.$router.resolve({
        path: `/bedrijf/${this.selectedRow.id}`,
      });
      window.open(routeData.href, "_blank");
    },

    show(event, row) {
      event.preventDefault();
      this.showMenu = false;
      this.x = event.clientX;
      this.y = event.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
      this.selectedRow = row.item;
    },

    async filterAgency(name) {
      this.companiesLoaded = false;
      await this.getCompaniesWithPlatformName(name).then(() => {
        this.companiesLoaded = true;
      });
    },

    storePaginationToState(val) {
      this.setCompanyPagination(val);
    },
  },
};
</script>
