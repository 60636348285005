<template>
  <v-container class="pa-5">
    <v-dialog v-model="subStatusDialog" width="550px">
      <v-card>
        <SubStatuses
          v-on:close-dialog="subStatusDialog = false"
          type="1"
          :formData="subStatusItem"
        ></SubStatuses>
      </v-card>
    </v-dialog>
    <v-dialog v-model="createSubStatusDialog" width="550px">
      <v-card>
        <SubStatuses
          v-on:close-dialog="createSubStatusDialog = false"
          type="0"
          :parentID="this.formDataFields.id"
        ></SubStatuses>
      </v-card>
    </v-dialog>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col :md="type == 1 ? 3 : 5">
          <v-text-field
            v-model="formDataFields.name"
            :label="$t('create_lead_status.ls_name')"
            required
            :loading="adding"
          ></v-text-field>
          <v-select
            item-text="text"
            item-value="value"
            v-model="formDataFields.type"
            :hint="$t('create_lead_status.type_hint')"
            persistent-hint
            :items="statusTypes"
          >
          </v-select>
          <v-select
            v-model="formDataFields.phase"
            :items="statusPhases"
            :hint="$t('create_lead_status.phase_hint')"
            disabled
            persistent-hint
          >
          </v-select>
          <v-select
            item-text="text"
            item-value="value"
            v-model="formDataFields.subs"
            :items="statusSubs"
            :hint="$t('create_lead_status.subs_hint')"
            persistent-hint
          >
          </v-select>
          <v-select
            item-text="text"
            item-value="value"
            v-model="formDataFields.inactive"
            :items="statusInactive"
            :hint="$t('create_lead_status.active_hint')"
            persistent-hint
          >
          </v-select>
        </v-col>
        <v-col :md="type == 1 ? 3 : 0" class="mt-0 pt-0">
          <v-color-picker
            dot-size="25"
            hide-canvas
            hide-mode-switch
            hide-sliders
            mode="hexa"
            v-model="formDataFields.color"
            show-swatches
            swatches-max-height="250"
            class="mt-0"
          ></v-color-picker>
        </v-col>
        <v-col md="6" v-if="type == 1" class="mt-10 pt-0 mb-0">
          <v-data-table
            :headers="headers"
            :items="activeLeadStatus.sub_statuses"
            :items-per-page="4"
            @click:row="itemAction"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>{{
                  $t("create_lead_status.sub_statuses")
                }}</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>

                <v-btn
                  color="primary"
                  @click="createSubStatusDialog = true"
                  dark
                  dense
                  >{{ $t("create_lead_status.new_sub_status") }}</v-btn
                >
              </v-toolbar>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-btn
          :disabled="!valid || adding"
          color="success"
          class="mr-4"
          @click="buttonClick"
        >
          {{ types[type].buttonText }}
        </v-btn>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import SubStatuses from "../../components/dialogs/SubStatuses";
import { permission } from "../../utils/permissions";
import {
  CREATE_LEAD_STATUS,
  GET_LEAD_STATUSES,
  UPDATE_LEAD_STATUS,
} from "../../store/action.type";
export default {
  components: {
    SubStatuses,
  },
  props: {
    type: String,
    formData: Object,
    parentID: Number,
  },
  data: () => ({
    statusPhases: [1, 2, 3, 4, 5, 6, 7, 8],
    adding: false,
    valid: false,
    subStatusDialog: false,
    createSubStatusDialog: false,
    subStatusItem: {},
    data_fields: {
      id: 0,
      name: "",
      color: "",
      type: 0,
      phase: 0,
      subs: 0,
      inactive: false,
    },
  }),
  computed: {
    formDataFields() {
      this.updateFormFields();
      return this.data_fields;
    },
    activeLeadStatus() {
      return this.$store.getters["statuses/getLeadStatusById"](this.parentID);
    },
    statusTypes() {
      return [
        {
          value: 0,
          text: this.$t("create_lead_status.negative"),
        },
        {
          value: 1,
          text: this.$t("create_lead_status.neutral"),
        },
        {
          value: 2,
          text: this.$t("create_lead_status.positive"),
        },
      ];
    },
    statusSubs() {
      return [
        {
          value: 0,
          text: this.$t("create_lead_status.no_sub"),
        },
        {
          value: 1,
          text: this.$t("create_lead_status.one_sub"),
        },
        {
          value: 2,
          text: this.$t("create_lead_status.one_sub_required"),
        },
        {
          value: 3,
          text: this.$t("create_lead_status.multiple_sub"),
        },
        {
          value: 4,
          text: this.$t("create_lead_status.multiple_sub_one_req"),
        },
      ];
    },
    statusInactive() {
      return [
        {
          value: true,
          text: this.$t("create_lead_status.inactive"),
        },
        {
          value: false,
          text: this.$t("create_lead_status.active"),
        },
      ];
    },
    headers() {
      return [
        { text: this.$t("create_lead_status.name"), value: "name" },
        { text: this.$t("create_lead_status.color"), value: "color" },
      ];
    },
    types() {
      return [
        {
          buttonText: this.$t("create_lead_status.create_ls"),
        },
        {
          buttonText: this.$t("create_lead_status.customize_ls"),
        },
      ];
    },
  },

  created() {
    if (this.type == 1) {
      this.data_fields = this.formData;
    }
  },

  methods: {
    ...mapActions("statuses", {
      createLeadStatus: CREATE_LEAD_STATUS,
      updateLeadStatus: UPDATE_LEAD_STATUS,
      getLeadStatuses: GET_LEAD_STATUSES,
    }),
    updateFormFields() {
      if (this.type == 1) {
        this.data_fields = this.formData;
      }
    },

    buttonClick() {
      if (this.type == 0) {
        this.create();
      } else if (this.type == 1) {
        this.editLeadStatus();
      }
    },

    itemAction(item) {
      this.subStatusItem = item;
      this.subStatusDialog = true;
    },

    async editLeadStatus() {
      const { dispatch } = this.$store;
      if (this.$refs.form.validate()) {
        this.adding = true;
        let lead_status = {
          name: this.data_fields.name,
          color: this.data_fields.color,
          type: this.data_fields.type,
          phase: this.data_fields.phase,
          subs: this.data_fields.subs,
          inactive: this.data_fields.inactive,
        };
        let statusId = this.formData.id;
        if (permission("isAdmin")) {
          await this.updateLeadStatus({
            lead_status,
            statusId,
          }).then(() => {
            dispatch("alerts/success", this.$t("create_lead_status.ls_saved"));
            this.getLeadStatuses();
            this.$emit("close-dialog");
          });
        } else {
          dispatch(
            "alerts/warn",
            this.$t("create_lead_status.not_authorized_msg")
          );
        }
      } else {
        dispatch(
          "alerts/warn",
          this.$t("create_lead_status.not_all_fields_completed")
        );
      }
      this.adding = false;
    },

    async create() {
      const { dispatch } = this.$store;
      if (this.$refs.form.validate()) {
        this.adding = true;
        let lead_status = {
          name: this.data_fields.name,
          color: this.data_fields.color,
          type: this.data_fields.type,
          phase: this.data_fields.phase,
          subs: this.data_fields.subs,
          inactive: this.data_fields.inactive,
        };
        if (permission("isAdmin")) {
          await this.createLeadStatus(lead_status).then(() => {
            dispatch("alerts/success", this.$t("create_lead_status.ls_added"));
            this.getLeadStatuses();
            this.clear();
            this.$emit("close-dialog");
          });
        } else {
          dispatch(
            "alerts/warn",
            this.$t("create_lead_status.not_authorized_msg")
          );
        }
      } else {
        dispatch(
          "alerts/warn",
          this.$t("create_lead_status.not_all_fields_completed")
        );
      }
      this.adding = false;
    },
    clear() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style scoped></style>
