import config from "../../../config";
import { authHeader } from "../../utils/auth-header";
import { handleResponse } from "../../utils/api_functions";
import { logger } from "../../utils/logger";

import {
  CREATE_SPENDING,
  GET_SPENDINGS,
  GET_SPENDING_BY_MONTH,
  UPDATE_SPENDING,
} from "../action.type";
import { SET_SPENDING, SET_SPENDINGS } from "../mutation.type";

export const marketing_spending = {
  namespaced: true,
  state: {
    spending: {},
    allSpendings: {},
  },
  getters: {
    getSpending: (state) => state.spending,
    allSpendings: (state) => state.allSpendings,
  },

  actions: {
    async [CREATE_SPENDING]({ commit }, payload) {
      try {
        const request = {
          method: "POST",
          headers: authHeader(),
          body: JSON.stringify(payload),
        };
        await fetch(`${config.apiUrl}/spendings`, request)
          .then(handleResponse)
          .then((res) => {
            commit(SET_SPENDING, res);
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [UPDATE_SPENDING]({ commit }, data) {
      try {
        const request = {
          method: "PUT",
          headers: authHeader(),
          body: JSON.stringify(data.spendingData),
        };
        await fetch(`${config.apiUrl}/spendings/${data.tempDate}`, request)
          .then(handleResponse)
          .then((res) => {
            commit(SET_SPENDING, res.updatedSpending);
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [GET_SPENDING_BY_MONTH]({ commit }, date) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        await fetch(`${config.apiUrl}/spendings/${date}`, request)
          .then(handleResponse)
          .then((res) => {
            commit(SET_SPENDING, res.spending);
          });
      } catch (error) {
        logger.error(error);
      }
    },
    async [GET_SPENDINGS]({ commit }) {
      try {
        const request = {
          method: "GET",
          headers: authHeader(),
        };
        await fetch(`${config.apiUrl}/spendings`, request)
          .then(handleResponse)
          .then((res) => {
            commit(SET_SPENDINGS, res);
          });
      } catch (error) {
        logger.error(error);
      }
    },
  },
  mutations: {
    [SET_SPENDING](state, payload) {
      state.spending = payload;
    },
    [SET_SPENDINGS](state, payload) {
      state.allSpendings = payload;
    },
  },
};
