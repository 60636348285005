<template>
  <v-container fluid>
    <v-dialog v-model="dialogCreate" max-width="600px">
      <v-card>
        <CreateVacancy
          v-on:close-dialog="dialogCreate = false"
          type="0"
        ></CreateVacancy>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogCopyVacancy" max-width="600px">
      <v-card>
        <CreateVacancy
          v-if="selectedRow"
          v-on:close-dialog="dialogCopyVacancy = false"
          type="2"
          :formData="selectedRow"
        ></CreateVacancy>
      </v-card>
    </v-dialog>

    <v-card class="pa-4" :loading="!loaded">
      <v-row>
        <v-col md="8">
          <v-row class="pa-3">
            <h2>{{ $t("navbar.vacancies") }}</h2>
            <v-divider
              vertical
              inset
              style="padding-left: 10px; padding-left: 10px"
            ></v-divider>
            <v-text-field
              style="padding-top: 0px; padding-left: 10px"
              v-model="filters.search"
              append-icon="mdi-magnify"
              :label="$t('common_phrases.search')"
              single-line
              hide-details
            ></v-text-field>
          </v-row>
        </v-col>
        <v-col align="end" md="4">
          <v-btn icon color="primary" class="mb-2" @click="dialogCreate = true">
            <v-icon dark> mdi-plus-box </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-menu
        v-model="showMenu"
        :position-x="x"
        :position-y="y"
        absolute
        offset-y
      >
        <v-list dense>
          <v-list-item @click="open" dense>
            <v-list-item-title>{{ $t("vacancy_page.open") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="openNewTab" dense>
            <v-list-item-title>{{
              $t("vacancy_page.new_tab")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="dialogCopyVacancy = true" dense>
            <v-list-item-title>{{ $t("vacancy_page.copy") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-data-table
        v-if="loaded"
        :page.sync="pagination.page"
        :items-per-page="pagination.itemsPerPage"
        :footer-props="{ itemsPerPageOptions: [20, 35, 50, -1] }"
        :headers="headers"
        :items="vacanciesAdjustedList"
        :search="filters.search"
        :custom-sort="customSort"
        sort-by="status_name"
        class="elevation-1 pointerOnHover"
        @click:row="editItem"
        @contextmenu:row="show"
        @pagination="storePaginationToState"
      >
        <template v-slot:top>
          <v-expansion-panels focusable flat :value="collapsed">
            <v-expansion-panel>
              <v-expansion-panel-header class="px-4 pt-4">
                <h2>{{ $t("common_phrases.filter") }}</h2>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container fluid>
                  <v-row dense>
                    <v-col align="end" class="mr-2 mt-2 mb-0">
                      <v-btn icon color="red" @click="clearFilters()"
                        ><span class="mr-1">{{
                          $t("common_phrases.clear")
                        }}</span>
                        <v-icon dark>mdi-backspace</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-select
                        :items="platforms"
                        v-model="filters.platform_name"
                        clearable
                        item-text="name"
                        item-value="name"
                        :label="$t('vacancy_page.platform')"
                        @change="storeFilterToState"
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-autocomplete
                        dense
                        class="pt-1"
                        :items="vacancy_statuses"
                        v-model="filters.statuses"
                        chips
                        deletable-chips
                        multiple
                        item-text="name"
                        return-object
                        :label="$t('vacancy_page.function')"
                        @change="storeFilterToState"
                        ><template
                          v-slot:selection="{ attr, on, item, selected }"
                        >
                          <v-chip
                            label
                            v-bind="attr"
                            :input-value="selected"
                            v-on="on"
                            close
                            :color="item.color"
                            @click:close="remove(item)"
                          >
                            <div style="color: black">
                              <strong>{{ item.name }}</strong>
                            </div>
                          </v-chip>
                        </template></v-autocomplete
                      >
                    </v-col>

                    <v-col>
                      <v-autocomplete
                        :items="positions"
                        v-model="filters.position_name"
                        clearable
                        item-text="name"
                        item-value="name"
                        :label="$t('vacancy_page.function')"
                        @change="storeFilterToState"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col>
                      <v-autocomplete
                        :items="companies"
                        v-model="filters.company_name"
                        clearable
                        item-text="name"
                        item-value="name"
                        :label="$t('vacancy_page.company')"
                        @change="storeFilterToState"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="filters.region"
                        append-icon="mdi-magnify"
                        :label="$t('vacancy_page.region')"
                        single-line
                        hide-details
                        @change="storeFilterToState"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <v-autocomplete
                        :items="employees"
                        v-model="filters.cm"
                        clearable
                        item-text="full_name"
                        item-value="full_name"
                        :label="$t('vacancy_page.community_manager')"
                        @change="storeFilterToState"
                      ></v-autocomplete>
                    </v-col>
                    <v-col>
                      <v-autocomplete
                        :items="employees"
                        v-model="filters.recruiter"
                        clearable
                        item-text="full_name"
                        item-value="full_name"
                        :label="$t('vacancy_page.recruiter')"
                        @change="storeFilterToState"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col>
                      <v-autocomplete
                        :items="emp_types"
                        v-model="filters.emp_type"
                        clearable
                        :label="$t('vacancy_page.job_scope')"
                        @change="storeFilterToState"
                      ></v-autocomplete>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="filters.emp_length"
                        append-icon="mdi-magnify"
                        :label="$t('vacancy_page.working_hours')"
                        single-line
                        hide-details
                        @change="storeFilterToState"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
        <template v-slot:[`item.status_name`]="{ item }">
          <v-chip label :color="item.status_color">
            <div style="color: black">
              <strong>{{ item.status_name }}</strong>
            </div>
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import CreateVacancy from "../components/dialogs/CreateVacancy.vue";
import { mapState, mapMutations, mapActions } from "vuex";
import {
  SET_VACANCY_FILTER,
  SET_VACANCY_PAGINATION,
} from "../store/mutation.type";
import {
  GET_VACANCIES,
  GET_VACANCY_BY_ID,
  GET_VACANCY_STATUSES,
} from "../store/action.type";
export default {
  components: { CreateVacancy },
  data: () => ({
    loaded: false,
    dialogCreate: false,
    dialogCopyVacancy: false,
    collapsed: 0,
    statusSort: {
      Nieuw: 2,
      Open: 3,
      Pauze: 5,
      Gesloten: 7,
      Gevuld: 6,
      Urgent: 1,
      Voorgesteld: 4,
    },
    emp_types: ["Fulltime", "Parttime", "Fulltime/Parttime", "Bijbaan"],
    platforms: [
      { name: "DOEN", image: "../assets/doen.png" },
      { name: "Zoomer", image: "../assets/zoomer.png" },
    ],
    filters: {},
    selectedRow: null,
    showMenu: false,
    x: 0,
    y: 0,
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$t("vacancy_page.status"),
          value: "status_name",
          filter: (value) => {
            if (!this.filters.statuses) {
              return true;
            }
            if (this.filters.statuses.length <= 0) {
              return true;
            }
            return this.filters.statuses.some((elem) => elem.name === value);
          },
        },
        {
          text: this.$t("vacancy_page.platform"),
          value: "platform_name",
          align: " d-none",
          filter: (value) => {
            if (!this.filters.platform_name) return true;
            return value === this.filters.platform_name;
          },
        },
        {
          text: this.$t("vacancy_page.name"),
          value: "name",
        },
        {
          text: this.$t("vacancy_page.function"),
          value: "role_name",
          filter: (value) => {
            if (!this.filters.position_name) return true;
            return value === this.filters.position_name;
          },
        },
        {
          text: this.$t("vacancy_page.company"),
          value: "company.name",
          filter: (value) => {
            if (!this.filters.company_name) return true;
            return value === this.filters.company_name;
          },
        },
        {
          text: this.$t("vacancy_page.region"),
          value: "region",
          filter: (value) => {
            if (!this.filters.region) return true;
            return value
              .toLowerCase()
              .includes(this.filters.region.toLowerCase());
          },
        },
        {
          text: this.$t("vacancy_page.community_manager"),
          value: "cm",
          filter: (value) => {
            if (!this.filters.cm) return true;
            return value === this.filters.cm;
          },
        },
        {
          text: this.$t("vacancy_page.recruiter"),
          value: "recruiter",
          filter: (value) => {
            if (!this.filters.recruiter) return true;
            return value === this.filters.recruiter;
          },
        },
        {
          text: this.$t("vacancy_page.job_scope"),
          value: "emp_type",
          filter: (value) => {
            if (!this.filters.emp_type) return true;
            return value === this.filters.emp_type;
          },
        },
        {
          text: this.$t("vacancy_page.working_hours"),
          value: "emp_length",
          filter: (value) => {
            if (!this.filters.emp_length) return true;
            return value
              .toLowerCase()
              .includes(this.filters.emp_length.toLowerCase());
          },
        },
        { text: this.$t("vacancy_page.people_needed"), value: "people_needed" },
        {
          text: this.$t("vacancy_page.number_of_applicants"),
          value: "applicants",
        },
        { text: this.$t("vacancy_page.start_date"), value: "start_date" },
      ];
    },

    vacanciesAdjustedList() {
      return this.vacancies.map((e) => {
        return {
          id: e.id,
          name: e.name,
          company: e.company,
          region: e.region,
          status_name: e.vacancy_status.name,
          status_color: e.vacancy_status.color,
          platform_name: e.company.platform_name,
          recruiter: this.fullName(e.recruiter),
          cm: this.fullName(e.community_manager),
          emp_type: e.employment_type,
          emp_length: e.employment_length,
          people_needed: e.people_needed,
          applicants: e.leads.length,
          start_date: this.formatDate(e.start_date),
          role_name: this.hasRole(e.job),
        };
      });
    },
    ...mapState({
      stored_filters: (state) => state.filters.vacancy_filter,

      vacancies: (state) =>
        state.vacancy.vacancies.vacancies.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        }),

      vacancy_statuses: (state) => state.vacancy.statuses.vacancy_statuses,

      positions: (state) =>
        state.filters.lists.roles.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        }),

      companies: (state) =>
        state.filters.lists.companies.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        }),

      employees: (state) =>
        state.filters.lists.employees
          .filter((e) => e.first_name != null)
          .sort((a, b) => {
            if (a.first_name < b.first_name) {
              return -1;
            }
            if (a.first_name > b.first_name) {
              return 1;
            }
            return 0;
          })
          .map((e) => {
            return {
              id: e.id,
              full_name: e.first_name + " " + e.last_name,
            };
          }),

      pagination: (state) => state.vacancy.pagination,
    }),
  },
  created() {
    this.initialize();
    this.filters = this.stored_filters;
  },
  methods: {
    ...mapActions("vacancy", {
      getVacancies: GET_VACANCIES,
      getVacancyById: GET_VACANCY_BY_ID,
      getVacancyStatuses: GET_VACANCY_STATUSES,
    }),
    ...mapMutations("filters", { setVacancyFilter: SET_VACANCY_FILTER }),
    ...mapMutations("vacancy", {
      setVacancyPagination: SET_VACANCY_PAGINATION,
    }),
    async initialize() {
      if (Object.keys(this.filters).length === 0) {
        await this.getVacancies();
        await this.getVacancyStatuses();
      }
      this.loaded = true;
    },

    hasRole(item) {
      if (item) {
        if (item.role) {
          return item.role.name;
        }
      }
      return "Onbekend";
    },

    async editItem(item) {
      let route = this.$router.resolve({ path: `/vacature-oud/${item.id}` });
      window.open(route.href);
    },

    open() {
      this.$router.push(`/vacature-oud/${this.selectedRow.id}`);
    },

    openNewTab() {
      let routeData = this.$router.resolve({
        path: `/vacature-oud/${this.selectedRow.id}`,
      });
      window.open(routeData.href, "_blank");
    },

    async show(event, row) {
      event.preventDefault();
      this.showMenu = false;
      this.x = event.clientX;
      this.y = event.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
      await this.getVacancyById(row.item.id).then((res) => {
        this.selectedRow = res.vacancy;
      });
    },

    fullName(item) {
      if (item == null || item == "") return "Niet toegewezen";
      return item.first_name.trim() + " " + item.last_name.trim();
    },

    storeFilterToState() {
      this.setVacancyFilter(this.filters);
    },

    storePaginationToState(val) {
      this.setVacancyPagination(val);
    },

    clearFilters() {
      this.filters = {};
      this.storeFilterToState();
    },

    remove(item) {
      let index = -1;
      this.filters.statuses.find(function (status, i) {
        if (status.name === item.name) {
          index = i;
        }
      });
      if (index >= 0) {
        this.filters.statuses.splice(index, 1);
      }
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      if (day) return `${day}-${month}-${year}`;
    },

    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] == "status_name") {
          if (!isDesc[0]) {
            return this.statusSort[a.status_name] <
              this.statusSort[b.status_name]
              ? -1
              : 1;
          } else {
            return this.statusSort[a.status_name] >
              this.statusSort[b.status_name]
              ? -1
              : 1;
          }
        } else {
          if (!isDesc[0]) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
          }
        }
      });
      return items;
    },
  },
};
</script>
