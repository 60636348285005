<template>
  <v-container fluid class="ma-0 pa-0 mt-4">
    <v-card class="pa-4" elevation="4">
      <h1>{{ $t("lead_pipes.heading") }}</h1>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">First Name</th>
              <th class="text-left">Last Name</th>
              <th class="text-left">Email</th>
              <th class="text-left">Telefoon</th>
              <th class="text-left">Kanaal</th>
              <th class="text-left">Campaign</th>
              <th class="text-left">Vacature</th>
              <th class="text-left">Form ID</th>
              <th class="text-left">Recruiter</th>
              <th class="text-right">Actie</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in pipes" :key="item.id" class="customTable">
              <!-- First name -->
              <td class="pt-2 pb-2">
                <v-text-field
                  outlined
                  hide-details
                  dense
                  v-model="item.first_name"
                  required
                ></v-text-field>
              </td>
              <!-- Last name -->
              <td>
                <v-text-field
                  outlined
                  hide-details
                  dense
                  v-model="item.last_name"
                  required
                ></v-text-field>
              </td>
              <!-- Email -->
              <td>
                <v-text-field
                  outlined
                  hide-details
                  dense
                  v-model="item.email"
                  required
                ></v-text-field>
              </td>
              <!-- Phone -->
              <td>
                <v-text-field
                  hide-details
                  outlined
                  dense
                  v-model="item.tel_number"
                  required
                ></v-text-field>
              </td>
              <!-- Channel -->
              <td>
                <p class="pa-0 ma-0" v-if="item.channel">
                  {{ item.channel.name }}
                </p>
                <p class="pa-0 ma-0" v-else>Niet gevonden</p>
              </td>
              <!-- Campaign -->
              <td>
                <p class="pa-0 ma-0" v-if="item.campaign_id">
                  {{ item.campaign_id }}
                </p>
                <p class="pa-0 ma-0" v-else>Niet gevonden</p>
              </td>
              <!-- Vacancy -->
              <td>
                <v-autocomplete
                  :items="vacancies_all_getter"
                  :item-text="vacanyWithCompanyName"
                  item-value="id"
                  placeholder="Kies een vacature"
                  v-model="item.vacancy_id"
                ></v-autocomplete>
              </td>
              <!-- Form ID -->
              <td>
                <p class="pa-0 ma-0" v-if="item.form_id">
                  {{ item.form_id }}
                </p>
                <p class="pa-0 ma-0" v-else>Niet gevonden</p>
              </td>
              <!-- Recruiter -->
              <td>
                <v-autocomplete
                  :items="employees"
                  item-text="full_name"
                  item-value="id"
                  placeholder="Kies een recruiter"
                  v-model="item.rec_id"
                ></v-autocomplete>
              </td>
              <!-- Actions -->
              <td class="text-right">
                <div style="white-space: nowrap">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        large
                        color="red"
                        class="white--text"
                        @click="deletePipe(item)"
                        v-bind="attrs"
                        v-on="on"
                        style="width: auto"
                        :disabled="buttonDisable"
                      >
                        <v-icon class="">mdi-close-box</v-icon>
                      </v-btn>
                    </template>
                    <span>Verwijder</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        large
                        color="green"
                        class="white--text"
                        @click="createLead(item)"
                        v-bind="attrs"
                        v-on="on"
                        style="width: auto"
                        :disabled="buttonDisable"
                      >
                        <v-icon>mdi-checkbox-marked</v-icon>
                      </v-btn>
                    </template>
                    <span>Aanmaken</span>
                  </v-tooltip>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import {
  GET_LEAD_PIPES,
  DELETE_LEAD_PIPE,
  ADD_STATUS_TO_LEAD_USER,
  CREATE_LEAD_USER,
  GET_VACANCIES_ALL,
} from "@/store/action.type";

export default {
  data: () => ({
    buttonDisable: false,
    pipes: [],
  }),
  computed: {
    ...mapGetters("vacancy_flow", ["vacancies_all_getter"]),
    ...mapState({
      employees: (state) =>
        state.filters.lists.employees
          .filter((e) => e.first_name != null)
          .sort((a, b) => {
            if (a.first_name < b.first_name) {
              return -1;
            }
            if (a.first_name > b.first_name) {
              return 1;
            }
            return 0;
          })
          .map((e) => {
            return {
              id: e.id,
              full_name: e.first_name + " " + e.last_name,
            };
          }),
    }),
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions("lead_pipes", {
      getLeadPipes: GET_LEAD_PIPES,
      deleteLeadPipe: DELETE_LEAD_PIPE,
    }),
    ...mapActions("lead_users", {
      createLeadUser: CREATE_LEAD_USER,
      addStatusToLeadUser: ADD_STATUS_TO_LEAD_USER,
    }),
    ...mapActions("vacancy_flow", {
      getVacanciesAll: GET_VACANCIES_ALL,
    }),
    async initialize() {
      await this.getLeadPipes().then((res) => {
        this.pipes = res.leadPipes;
      });
      await this.getVacanciesAll();
    },
    async createLead(item) {
      this.buttonDisable = true;
      const { dispatch } = this.$store;
      const invalidFirstNameChars = this.showInvalidChars(item.first_name);
      const invalidLastNameChars = this.showInvalidChars(item.last_name);
      const validatePhone = this.validatePhone(item.tel_number);
      const validateEmail = this.validateEmail(item.email);
      if (
        invalidFirstNameChars === "" &&
        invalidLastNameChars === "" &&
        validatePhone &&
        validateEmail
      ) {
        const newLeadUser = {
          first_name: item.first_name,
          last_name: item.last_name,
          email: item.email,
          tel_number: item.tel_number,
        };
        const newLead = {
          vacancy_id: item.vacancy_id,
          channel_id: item.channel_id,
          campaign_id: item.campaign_id,
          applied_date: item.createdAt,
          phase: 1,
          rec_id: item.rec_id,
          createdAt: item.createdAt,
        };
        let complete = true;
        let errorMsg = "Missing the following fields: ";
        if (newLeadUser.email == "" || newLeadUser.email == null) {
          if (newLeadUser.tel_number == "" || newLeadUser.tel_number == null) {
            complete = false;
            errorMsg = errorMsg + " Mail of Telefoon,";
          }
        }
        if (newLead.vacancy_id == null) {
          complete = false;
          errorMsg = errorMsg + " Vacature,";
        }
        if (newLead.rec_id == null) {
          complete = false;
          errorMsg = errorMsg + " Recruiter,";
        }
        if (!complete) {
          dispatch("alerts/warn", errorMsg);
          this.buttonDisable = false;
          return;
        }

        // Create lead_user (backend checks for existing user)
        await this.createLeadUser(newLeadUser).then((res) => {
          newLead.lead_user = res.lead_user.id;
        });
        if (newLead.lead_user == null) {
          dispatch("alerts/error", "Het aanmaken van het profiel is mislukt");
          this.buttonDisable = false;
          return;
        }

        // Create status of lead for lead_user
        const leadUserStatus = {
          lead_user_id: newLead.lead_user,
          lead_status_id: 1,
          sub_statuses: JSON.stringify([]),
        };
        await this.addStatusToLeadUser(leadUserStatus);

        // Create Lead
        await dispatch("leads/createLead", newLead).then(() => {
          dispatch("alerts/success", this.$t("create_lead.lead_added"));
        });
        await this.deletePipe(item);
      } else if (invalidFirstNameChars !== "") {
        dispatch(
          "alerts/warn",
          `First Name: ${item.first_name} is ${this.$t(
            "lead_pipes.invalid"
          )}!\n ${this.$t(
            "lead_pipes.invalid_chars"
          )}: ${invalidFirstNameChars}`
        );
        this.buttonDisable = false;
        return;
      } else if (invalidLastNameChars !== "") {
        dispatch(
          "alerts/warn",
          `Last Name: ${item.last_name} is ${this.$t(
            "lead_pipes.invalid"
          )}!\n ${this.$t("lead_pipes.invalid_chars")}: ${invalidLastNameChars}`
        );
        this.buttonDisable = false;
        return;
      } else if (!validatePhone) {
        dispatch("alerts/warn", this.$t("lead_pipes.phone_start_error_msg"));
        this.buttonDisable = false;
        return;
      } else {
        dispatch(
          "alerts/warn",
          `${
            this.$t("lead_pipes.invalid").charAt(0).toUpperCase() +
            this.$t("lead_pipes.invalid").slice(1)
          } email`
        );
        this.buttonDisable = false;
        return;
      }
      this.buttonDisable = false;
    },
    async deletePipe(item) {
      this.buttonDisable = true;
      await this.deleteLeadPipe(item.id);
      var index = this.pipes.findIndex(function (x, i) {
        return x.id === item.id;
      });
      this.pipes.splice(index, 1);
      this.buttonDisable = false;
    },
    vacanyWithCompanyName(item) {
      if (item.Company) {
        return item.name + " - " + item.Company.name;
      } else {
        return item.name;
      }
    },
    vacancyFromAppForm(item) {
      if (item == null) {
        return null;
      }
      return item.vacancy.id;
    },
    recFromAppFormVacancy(item) {
      if (item == null) {
        return null;
      }
      return item.vacancy.rec_id;
    },
    // Show invalid characters from a string
    showInvalidChars(input) {
      let output = "";
      for (let i = 0; i < input.length; i++) {
        if (input.charCodeAt(i) > 127) {
          output += input.charAt(i);
        }
      }
      return output;
    },
    validatePhone(value) {
      if (value != "" && value != undefined && value != null) {
        const pattern = /^\+(?:[0-9]●?){6,14}[0-9]$/;
        if (pattern.test(value)) {
          return true;
        } else {
          return false;
        }
      } else return true;
    },
    validateEmail(value) {
      if (value != "" && value != undefined && value != null) {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (pattern.test(value)) {
          return true;
        } else {
          return false;
        }
      } else return true;
    },
  },
};
</script>
<style lang="scss" scoped>
.customTable {
  background: 0 !important;
  &:hover {
    background: 1;
    background-color: var(--v-tableHover) !important;
  }
}
</style>
