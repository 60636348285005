<template>
  <v-dialog v-model="display" :width="340">
    <template v-slot:activator="{ on }">
      <v-text-field :rules="required" :value="formattedDateTime" v-on="on" readonly>
        <template v-slot:progress>
          <slot name="progress">
            <v-progress-linear
              color="primary"
              indeterminate
              absolute
              height="2"
            ></v-progress-linear>
          </slot>
        </template>
      </v-text-field>
    </template>

    <v-card>
      <v-card-text class="px-0 py-0">
        <v-tabs fixed-tabs v-model="activeTab">
          <v-tab key="calendar">
            <slot name="dateIcon">
              <v-icon>mdi-calendar</v-icon>
            </slot>
          </v-tab>
          <v-tab key="timer" :disabled="dateSelected">
            <slot name="timeIcon">
              <v-icon>mdi-clock</v-icon>
            </slot>
          </v-tab>
          <v-tab-item key="calendar">
            <v-date-picker
              locale="nl-NL"
              v-model="date"
              @input="showTimePicker"
              full-width
            ></v-date-picker>
          </v-tab-item>
          <v-tab-item key="timer">
            <v-time-picker
              ref="timer"
              class="v-time-picker-custom"
              v-model="time"
              format="24hr"
              full-width
            ></v-time-picker>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="actions" :parent="this">
          <v-btn color="grey lighten-1" text @click.native="clearHandler">{{
            $t("common_phrases.clear_caps")
          }}</v-btn>
          <v-btn color="green darken-1" text @click="okHandler">{{
            $t("common_phrases.ok_caps")
          }}</v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      display: false,
      activeTab: 0,
      date: "",
      time: "",
      required: [
        v => !!v || 'Date is required',
      ],
    };
  },
  computed: {
    formattedDateTime() {
      return this.toLocalDate(this.selectedDatetime);
    },
    selectedDatetime() {
      if (this.date && this.time) {
        let datetimeString = this.date + " " + this.time;
        if (this.time.length === 5) {
          datetimeString += ":00";
        }
        datetimeString += " UTC";
        return new Date(datetimeString).toUTCString();
      }
      if (this.value) {
        return new Date(this.value).toUTCString();
      }
      return new Date().toUTCString();
    },
    dateSelected() {
      return !this.date;
    },
  },
  methods: {
    okHandler() {
      this.resetPicker();
      this.$emit("input", this.selectedDatetime);
    },
    clearHandler() {
      this.resetPicker();
      this.date = "";
      this.time = "";
      this.$emit("input", null);
    },
    resetPicker() {
      this.display = false;
      this.activeTab = 0;
      if (this.$refs.timer) {
        this.$refs.timer.selectingHour = true;
      }
    },
    showTimePicker() {
      this.activeTab = 1;
    },
    toLocalDate(date) {
      return date;
    },
  },
};
</script>
