export const alerts = {
    namespaced: true,
    state: {
        display: false,
        type: null,
        message: null
    },
    actions: {
        success({ commit }, message) {
            commit('success', message);
            setTimeout(function(){ commit('clear'); }, 10000);
        },
        info({ commit }, message) {
            commit('info', message);
            setTimeout(function(){ commit('clear'); }, 10000);
        },
        warn({ commit }, message) {
            commit('warn', message);
            setTimeout(function(){ commit('clear'); }, 10000);
        },
        error({ commit }, message) {
            commit('error', message);
            setTimeout(function(){ commit('clear'); }, 10000);
        },
        clear({ commit }) {
            commit('clear');
        }
    },
    mutations: {
        success(state, message) {
            state.type = 'success';
            state.message = message;
            state.display = true;
        },
        info(state, message) {
            state.type = 'info';
            state.message = message;
            state.display = true;
        },
        warn(state, message) {
            state.type = 'warning';
            state.message = message;
            state.display = true;
        },
        error(state, message) {
            state.type = 'error';
            state.message = message;
            state.display = true;
        },
        clear(state) {
            state.type = null;
            state.message = null;
            state.display = false;
        }
    }
}