<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,
  props: {
    data: Object,
  },
  mounted() {
    this.render();
  },
  watch: {
    data: function () {
      this._data._chart.destroy();
      this.render();
    },
  },
  methods: {
    render() {
      this.renderChart(
        {
          labels: this.data.labels,
          datasets: this.data.datasets,
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: this.data.text,
          },
        }
      );
    },
  },
};
</script>
