<template>
  <v-container fluid>
    <v-tabs color="primary">
      <v-tab>
        <v-icon start class="pa-1"> mdi-email-fast </v-icon>
        Send Email
      </v-tab>
      <v-tab>
        <v-icon start class="pa-1"> mdi-email-edit</v-icon>
        Email Templates
      </v-tab>
      <v-tab>
        <v-icon start class="pa-1"> mdi-format-list-checkbox </v-icon>
        Hotlists
      </v-tab>
      <v-tab-item
        ><v-card class="pa-8">
          <h2>{{ $t("navbar.send_email") }}</h2>
          <SendEmail></SendEmail
        ></v-card>
      </v-tab-item>
      <v-tab-item>
        <EmailTemplates></EmailTemplates>
      </v-tab-item>
      <v-tab-item>
        <Hotlists> </Hotlists>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>
<script>
import SendEmail from "../components/emails/dialogs/SendEmail.vue";
import EmailTemplates from "../components/emails/EmailTemplates.vue";
import Hotlists from "../components/hotlists/Hotlists.vue";

export default {
  components: { SendEmail, EmailTemplates, Hotlists },
};
</script>
